import { Button, Table } from 'components'
import {
	Icons,
	NetworkPartnerTableItem,
	NetworkPartnerTableResponse,
	ParentTableProps
} from 'interfaces'
import moment from 'moment'
import React, { useCallback, useMemo } from 'react'
import { Column, Row } from 'react-table'
import { renderCellCapitalize, renderCellDateTime } from 'utils'

export const PartnersNetworkTable: React.FC<
	ParentTableProps<NetworkPartnerTableResponse> & {
		onActionsClick: (partnerId: number) => void
	}
> = ({
	data,
	page,
	setPage,
	isLoading,
	onItemsShowingChange,
	onActionsClick,
	dataSelectedIds,
	setDataSelectedIds,
	itemsShowing
}) => {
	const renderCell = (row: Row<NetworkPartnerTableItem>): JSX.Element => {
		return (
			<Button
				iconLeft={Icons.pending}
				className="!w-max !min-w-[unset] !px-1 py-1 !border-none"
				color="Gray2"
				leftIconDivClassName="w-[20px] h-[20px]"
				onClick={() => onActionsClick(row.original.id)}
			/>
		)
	}
	const columns = useMemo(
		(): Column<NetworkPartnerTableItem>[] => [
			{
				Header: 'Action',
				accessor: 'id',
				disableSortBy: true,
				Cell: ({ row }) => renderCell(row)
			},
			{
				Header: 'Direction',
				accessor: 'direction',
				Cell: ({ value }) => renderCellCapitalize(value)
			},
			{
				Header: 'Status',
				accessor: 'status',
				Cell: ({ value }) => renderCellCapitalize(value)
			},
			{ Header: 'Organization Name', accessor: 'organization_name' },
			{ Header: 'Contact', accessor: 'email' },
			{
				Header: 'Request Date',
				accessor: 'request_date',
				Cell: ({ value }) => renderCellDateTime(value),
				sortType: (
					a: Row<NetworkPartnerTableItem>,
					b: Row<NetworkPartnerTableItem>
				) =>
					moment.utc(a.original.created_by).unix() <
					moment.utc(b.original.created_by).unix()
						? -1
						: 1
			},
			{ Header: 'Created By', accessor: 'created_by' }
		],
		[]
	)
	// se comento porque en diseno no estaba pero se deja por si mas adelante sea necesario
	// const handleSelect = useCallback(
	// 	(rows: Row<NetworkPartnerTableItem>[], selected: boolean) => {
	// 		const ids = rows.map(row => row.original.id)
	// 		if (selected) {
	// 			setDataSelected?.(prev => [...prev, ...ids])
	// 		} else {
	// 			setDataSelected?.(prev =>
	// 				prev.filter(partner => !ids.includes(partner))
	// 			)
	// 		}
	// 	},
	// 	[]
	// )

	const handleSelect = useCallback(
		(rows: Row<NetworkPartnerTableItem>[], selected: boolean) => {
			const selectedData = rows.map(row => ({
				id: row.original.id,
				direction: row.original.direction,
				status: row.original.status
			}))

			if (selected) {
				setDataSelectedIds?.(prev => [...prev, ...selectedData])
			} else {
				setDataSelectedIds?.(prev =>
					prev.filter(
						partner => !selectedData.some(dataId => dataId.id === partner.id)
					)
				)
			}
		},
		[]
	)

	return (
		<Table<NetworkPartnerTableItem>
			data={data?.items ?? []}
			page={page}
			isFetching={isLoading}
			onPageChange={setPage}
			columns={columns}
			noDataMessage="You don't have any partners"
			onRowSelect={handleSelect}
			bodyTableClassName="!h-[calc(100vh_-_28rem)]"
			customWidth={[50, 100, 100, 120, 80, 120, 80]}
			justify="left"
			showPagination
			pages={data?.size}
			total={data?.total}
			rowSelected={row =>
				dataSelectedIds?.some(dataids => dataids.id === row.original.id) ??
				false
			}
			itemsShowing={itemsShowing}
			onItemsShowingChange={onItemsShowingChange}
			hideSelectionHeader
		/>
	)
}
