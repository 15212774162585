/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * TODO:
 * Schedule appointment for review.
 */

import { CircularProgress } from '@mui/material'
import {
	GET_TAGS_APPROVAL_TABLE,
	getTagsApprovalTable,
	handleMultipleTags
} from 'api'
import clsx from 'clsx'
import {
	Button,
	Input,
	PeopleTagsApprovalTable,
	TailwindModal
} from 'components'
import { PaginationContext } from 'context'
import { useDebouncedSearch, useToast } from 'hooks'
import {
	GenericModalProps,
	Icons,
	TagsApprovalRequest,
	TagsApprovalResponse
} from 'interfaces'
import React, { useCallback, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'
import { configGenaral } from 'utils'

export const TagsApprovalModal: React.FC<GenericModalProps<any>> = ({
	open,
	setOpen,
	dataSelected,
	setDataSelected,
	refetch
}) => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const [page, setPage] = useState(1)
	const { tables } = useContext(PaginationContext)
	const { register, resetField, setValue, watch } = useForm<{ search: string }>(
		{
			mode: 'onChange'
		}
	)
	const watchSearch = watch('search')
	const { debouncedSearch, resetSearch } = useDebouncedSearch(watchSearch)
	const [requestIsLoading, setRequestIsLoading] = useState(false)
	const { showToast } = useToast()
	const {
		data,
		isLoading,
		refetch: dataRefetch,
		isRefetching
	} = useQuery({
		queryKey: [
			GET_TAGS_APPROVAL_TABLE,
			page,
			debouncedSearch,
			userId,
			organizationId,
			tables.tagsApproval.column,
			tables.tagsApproval.order
		],
		queryFn: () =>
			getTagsApprovalTable(
				organizationId,
				page,
				debouncedSearch,
				tables.tagsApproval.column,
				tables.tagsApproval.order
			),
		refetchOnWindowFocus: false
	})

	const handleMultipleTagsHandler = useCallback(
		async (body: TagsApprovalRequest): Promise<void> => {
			setRequestIsLoading(true)
			handleMultipleTags(organizationId, body).then(response => {
				if (response) {
					showToast(
						'Success',
						`Tags selected ${
							body.is_approval ? 'accepted' : 'declined'
						} successfully.`,
						'success'
					)
					dataRefetch()
					if (refetch) {
						refetch()
					}
					setOpen(false)
				}
				setDataSelected?.([])
			})
		},
		[]
	)

	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			title="Tag Approval"
			className="w-[400px] h-[500px] md:w-[69.2vw] md:h-[79.2vh] p-4 rounded-[16px]"
		>
			<div
				className={clsx(
					'flex flex-col gap-4 w-full h-[calc(100%_-_28px)] mt-4 overflow-y-auto',
					requestIsLoading && 'justify-center items-center'
				)}
			>
				{!requestIsLoading ? (
					<>
						<div className="flex justify-between items-center">
							<Input
								name="search"
								placeholder="Search Tags or Users"
								className="!w-full !p-[unset]"
								register={register}
								rightIcon={Icons.cancel}
								rightClick={() => {
									resetSearch()
									resetField('search')
								}}
								inputClassName="!p-[unset] !py-1 !px-2"
								inputFocus
								borderFull={false}
								search
								cantLimit={configGenaral.maxSearchValue}
								limit
								watchValue={watchSearch}
								setValueInput={setValue}
							/>
							{/* {setOpenFilter && (
						<>
							<Button
								label="Filter"
								iconLeft={Icons.filterAltFill}
								className="!min-w-[unset] !py-1 !px-7 !bg-white"
								color="Transparent"
								onClick={() => setOpenFilter(true)}
							/>
							{openFilter && (
								<FilterModal open={openFilter} setOpen={setOpenFilter} />
							)}
						</>
					)} */}
						</div>
						<PeopleTagsApprovalTable
							data={data as TagsApprovalResponse | undefined}
							isLoading={isLoading || isRefetching}
							page={page}
							setPage={setPage}
							className="mt-4"
							dataSelected={dataSelected}
							setDataSelected={setDataSelected}
							setOpen={setOpen}
							refetch={refetch}
						/>
						{data && data?.items.length > 0 && (
							<div className="flex justify-between w-full pb-[30px] pl-[15px] pr-[15px] gap-4 md:gap-0">
								<Button
									label="Decline Selected"
									color="Transparent"
									className="w-full !min-w-[unset] sm:!w-[168px] !p-[unset] !py-[13px]"
									disabled={dataSelected?.length === 0}
									onClick={() =>
										handleMultipleTagsHandler({
											is_approval: false,
											organization_follower_ids: dataSelected as number[]
										})
									}
								/>
								<Button
									label="Accept Selected"
									className="w-full !min-w-[unset] sm:!w-[168px] !p-[unset] !py-[13px]"
									disabled={dataSelected?.length === 0}
									onClick={() =>
										handleMultipleTagsHandler({
											is_approval: true,
											organization_follower_ids: dataSelected as number[]
										})
									}
								/>
							</div>
						)}
					</>
				) : (
					<CircularProgress disableShrink />
				)}
			</div>
		</TailwindModal>
	)
}
