import { API } from 'api/config'
import { UserRolesResponse, ValidateUserResponse } from 'interfaces'

export const getUserRoles = async (): Promise<UserRolesResponse | null> => {
	let res = null as UserRolesResponse | null
	await API()
		.get('/user/roles')
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const validateUser = async (
	email: string
): Promise<ValidateUserResponse | null> => {
	let res = null as ValidateUserResponse | null
	await API()
		.get(`/user/validate_user`, { params: { email } })
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}
