import { Disclosure } from '@headlessui/react'
import {
	Audience,
	BoxContentDelivery,
	Icon,
	Schedule,
	Slide,
	Typography
} from 'components'

import { Icons, NotificationPushDelivery, TypographyTypes } from 'interfaces'
import React, { useEffect, useState } from 'react'
import { RadiusDelivery } from '../slide'

export const PushDelivery: React.FC<NotificationPushDelivery> = ({
	totalData,
	setTotalData,
	deliveryActive,
	itemRecurrence,
	publishMyPeope,
	eventRecurrence,
	setDeliveryActive,
	setItemRecurrence,
	setPublishMyPeope,
	setEventRecurrence
}): JSX.Element => {
	const [slideSchedule, setSlideSchedule] = useState(false)
	const [openSlide, setOpenSlide] = useState(false)
	const [slideAudience, setSlideAudience] = useState(false)
	const [slideGoodLink, setSlideGoodLink] = useState(false)
	const [location, setLocation] = useState(false)

	const [, setAddFeed] = useState(false)
	const [, setSharePartner] = useState(false)
	const [, setShareFMPartner] = useState(false)

	useEffect(() => {
		if (!openSlide) {
			setAddFeed(false)
			setLocation(false)
			setSharePartner(false)
			setSlideGoodLink(false)
			setSlideSchedule(false)
			setSlideAudience(false)
			setShareFMPartner(false)
		}
	}, [openSlide])

	return (
		<Disclosure>
			<div className="relative">
				<Slide
					title={
						slideSchedule
							? 'Schedule'
							: slideAudience
							? 'Select Audience'
							: location
							? 'Delivery Radius'
							: ''
					}
					scroll={slideGoodLink}
					open={openSlide}
					setOpen={setOpenSlide}
					classNameTitle="!text-lavender !font-bold"
				>
					<>
						{slideSchedule && (
							<Schedule
								totalData={totalData}
								setOpenSlide={setOpenSlide}
								setTotalData={setTotalData}
								deliveryActive={deliveryActive}
								itemRecurrence={itemRecurrence}
								eventRecurrence={eventRecurrence}
								setDeliveryActive={setDeliveryActive}
								setItemRecurrence={setItemRecurrence}
								setEventRecurrence={setEventRecurrence}
								onClickClose={() => setOpenSlide(false)}
								isExpiratedView={false}
								locationComponent="Push Notification"
							/>
						)}
						{slideAudience && (
							<Audience
								totalData={totalData}
								setAddFeed={setAddFeed}
								setTotalData={setTotalData}
								setOpenSlide={setOpenSlide}
								publishMyPeope={publishMyPeope}
								setSharePartner={setSharePartner}
								setSlideAudience={setSlideAudience}
								setShareFMPartner={setShareFMPartner}
								setPublishMyPeope={setPublishMyPeope}
								onClickClose={() => setOpenSlide(false)}
							/>
						)}
						{location && (
							<RadiusDelivery
								totalData={totalData}
								setTotalData={setTotalData}
								setOpenSlide={setOpenSlide}
								deliveryActive={deliveryActive}
								setDeliveryActive={setDeliveryActive}
								onClickClose={() => setOpenSlide(false)}
							/>
						)}
					</>
				</Slide>

				<Disclosure.Button className="border-b border-gray-11 pb-3 w-full flex justify-between items-center">
					<div className="flex items-center gap-2">
						<Typography
							title="Push Delivery"
							className="text-gray-9"
							type={TypographyTypes.h2}
							fontLeading="22/27"
						/>
					</div>
					<div>
						<Icon src={Icons.arrowDown} className="w-4 text-gray-1" fillLine />
					</div>
				</Disclosure.Button>
				<Disclosure.Panel className="text-gray-500">
					<div>
						<div className="flex items-center gap-4">
							<BoxContentDelivery
								title="Schedule"
								onClick={() => {
									setSlideSchedule(true)
									setOpenSlide(true)
								}}
							/>
							<BoxContentDelivery
								title="Audience"
								onClick={() => {
									setSlideAudience(true)
									setOpenSlide(true)
								}}
							/>
						</div>
						<div className="flex items-center gap-4 max-w-[49%]">
							<BoxContentDelivery
								title="Delivery Radius"
								onClick={() => {
									setLocation(true)
									setOpenSlide(true)
								}}
							/>
						</div>
					</div>
				</Disclosure.Panel>
			</div>
		</Disclosure>
	)
}
