import { Listbox, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { Icon, Message, Typography } from 'components'
import { DropdownFullProps, Icons, TypographyTypes } from 'interfaces'
import React, { Fragment } from 'react'

export const DropdownFull: React.FC<DropdownFullProps> = ({
	list,
	item,
	setItem,
	disabled,
	setClean,
	iconClass,
	className,
	menuClassName,
	rounded = false,
	color = 'gray3',
	fillPath = false,
	icon = Icons.arrowDown,
	buttonClassName,
	small,
	error,
	placeholder
}): JSX.Element => {
	const getOptionClasses = (active: boolean): string =>
		clsx(
			active ? 'bg-blue-primary text-white' : 'text-lavender',
			'relative cursor-default select-none py-2',
			small ? 'px-2' : 'pl-3 pr-9'
		)

	return (
		<Listbox
			value={item}
			onChange={e => {
				setItem(e)

				if (setClean) {
					setClean(true)
				}
			}}
			disabled={disabled}
		>
			{({ open }) => {
				return (
					<div className={`relative ${className}`}>
						<Listbox.Button
							className={clsx(
								rounded && 'rounded-[100px]',
								'outline-none cursor-pointer',
								color === 'gray3' && 'border border-gray-3',
								color === 'green' && 'border border-blue-primary',
								'relative w-full rounded min-h-[40px] bg-white py-1.5 pl-3 pr-10 text-left text-gray-900',
								'outline-none cursor-pointer border border-gray-3',
								!small ? 'min-h-[40px] pl-3 pr-10' : 'pl-1 pr-7',
								buttonClassName
							)}
						>
							<Typography
								title={item?.name ?? placeholder}
								type={TypographyTypes.span}
								fontLeading={small ? '12/12' : '15/18'}
								className={clsx(
									'block truncate !font-normal',
									color === 'gray3' && 'text-lavender',
									color === 'green' && 'text-blue-primary'
								)}
							/>
							<span
								className={clsx(
									'pointer-events-none absolute inset-y-0 right-0 flex items-center',
									small ? 'pr-1' : 'pr-2'
								)}
							>
								<Icon
									src={icon}
									fillPath={fillPath}
									className={clsx('w-4', iconClass)}
								/>
							</span>
						</Listbox.Button>

						<Transition
							show={open}
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Listbox.Options
								className={clsx(
									menuClassName,
									'absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg outline-none border border-gray-3'
								)}
							>
								{list.map(itemList => (
									<Listbox.Option
										key={itemList.id ?? itemList.value}
										className={({ active }) => getOptionClasses(active)}
										value={itemList}
										disabled={
											itemList.value === 'Properties' ||
											itemList.value === 'Operator'
										}
									>
										{({ selected: selectedPerson }) => (
											<Typography
												title={itemList.name}
												type={TypographyTypes.span}
												fontLeading="15/18"
												className={clsx(
													selectedPerson ? '!font-semibold' : '!font-normal',
													'block truncate'
												)}
											/>
										)}
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
						{error && error.message && (
							<Message text={error.message} type="error" marginL="1.5%" />
						)}
					</div>
				)
			}}
		</Listbox>
	)
}
