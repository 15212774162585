import { CircularProgress } from '@mui/material'
import { GET_MY_FEEDS_TABLE, getMyFeeds } from 'api'
import { BoxPartners, Button, Input } from 'components'
import { useSearchDebounce } from 'hooks'
import {
	AddFeedInputs,
	AddFeedProps,
	Icons,
	NetworkMyFeedsTableItem
} from 'interfaces'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'

export const AddFeed: React.FC<AddFeedProps> = ({
	totalData,
	setAddFeed,
	onClickClose,
	setTotalData,
	setSlideAudience
}): JSX.Element => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const [feedsSelected, setFeedsSelected] = useState<number[]>([])
	const { watch, register, handleSubmit } = useForm<AddFeedInputs>({
		mode: 'all'
	})

	const search = watch('search')

	const { inputSearch, handleChangeDebounce } = useSearchDebounce(search)

	const { data, isLoading } = useQuery(
		[
			GET_MY_FEEDS_TABLE,
			{
				userId,
				organizationId
			}
		],
		() =>
			getMyFeeds(organizationId, {
				page: 1,
				search: inputSearch.trim(),
				pageSize: 100
			})
	)

	useEffect(() => {
		const aux = [] as number[]
		totalData.delivery_enhancements.audience.feeds.forEach(item => {
			aux.push(item)
		})
		setFeedsSelected(aux)
	}, [totalData])

	const handleClickFeed = (feed: NetworkMyFeedsTableItem): void => {
		let newFeed = feedsSelected
		if (feedsSelected.includes(feed.id)) {
			newFeed = feedsSelected.filter(item => item !== feed.id)
		} else {
			newFeed.push(feed.id)
		}
		setFeedsSelected([...newFeed])
	}

	const onSubmit: SubmitHandler<AddFeedInputs> = () => {
		setTotalData({
			...totalData,
			delivery_enhancements: {
				...totalData.delivery_enhancements,
				audience: {
					...totalData.delivery_enhancements.audience,
					feeds: feedsSelected
				}
			}
		})
		setAddFeed(false)
		setSlideAudience(true)
	}

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="mt-5 flex flex-col justify-between h-full"
		>
			<div>
				<Input
					name="search"
					className="mb-4"
					borderFull={false}
					register={register}
					leftIcon={Icons.search}
					inputClassName="rounded-[10px]"
					placeholder="Find Your Feeds"
					onChangeCustom={handleChangeDebounce}
				/>
				{isLoading ? (
					<div className="flex items-center justify-center w-full h-[400px] py-2 px-1 ">
						<CircularProgress disableShrink />
					</div>
				) : (
					data?.items.map(item => (
						<BoxPartners
							key={item.id}
							id={`${item.id}`}
							title={item.name}
							itemsSeleted={feedsSelected}
							onClick={() => handleClickFeed(item)}
						/>
					))
				)}
			</div>

			<div className="flex items-center justify-between">
				<Button
					type="button"
					label="Cancel"
					color="Transparent"
					className="!min-w-[130px]"
					onClick={onClickClose}
				/>
				<Button
					label="Save"
					type="submit"
					color="Primary"
					className="!min-w-[130px]"
				/>
			</div>
		</form>
	)
}
