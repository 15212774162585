/* eslint-disable react/no-unstable-nested-components */
import { Button, Table } from 'components'
import {
	Icons,
	ParentTableProps,
	PartnerFeedsTableItem,
	PartnerFeedsTableResponse
} from 'interfaces'
import moment from 'moment'
import React, { useMemo } from 'react'
import { Column } from 'react-table'
import { renderCellDateTime } from 'utils'

export const SubscriptionNetworkTable: React.FC<
	ParentTableProps<PartnerFeedsTableResponse> & {
		onActionsClick: (followerFeedId: number) => void
	}
> = ({
	data,
	page,
	setPage,
	isLoading,
	onActionsClick,
	dataSelected,
	onItemsShowingChange,
	itemsShowing
}) => {
	const renderCell = (value: number): JSX.Element => {
		return (
			<Button
				iconLeft={Icons.pending}
				className="!w-max !min-w-[unset] !px-1 py-1 !border-none"
				color="Gray2"
				leftIconDivClassName="w-[20px] h-[20px]"
				onClick={() => onActionsClick(value)}
			/>
		)
	}
	const columns = useMemo(
		(): Column<PartnerFeedsTableItem>[] => [
			{
				Header: 'Action',
				disableSortBy: true,
				accessor: 'id',
				Cell: ({ value }) => renderCell(value)
			},
			{ Header: 'Feed Name', accessor: 'feed_name' },
			{ Header: 'Partner', accessor: 'partner_name' },
			{ Header: 'Contact', accessor: 'contact' },
			{
				Header: 'Request Date',
				accessor: ({ request_date: date }) => moment.utc(date).toDate(),
				sortType: 'datetime',
				Cell: ({ value }: { value: Date }) => renderCellDateTime(value)
			}
		],
		[]
	)

	return (
		<Table<PartnerFeedsTableItem>
			data={data?.items ?? []}
			page={page}
			isFetching={isLoading}
			noDataMessage="You don't have any partner feeds"
			onPageChange={setPage}
			columns={columns}
			customWidth={[60, 100, 200, 200, 100]}
			bodyTableClassName="!h-[calc(100vh_-_28rem)]"
			justify="left"
			itemsShowing={itemsShowing}
			onItemsShowingChange={onItemsShowingChange}
			rowSelected={row => dataSelected?.includes(row.original.id) ?? false}
			pages={data?.size}
			total={data?.total}
			hideSelectionHeader // se coloco porque en diseno no estaba pero se deja por si mas adelante sea necesario
		/>
	)
}
