import axios, { AxiosError } from 'axios'
import { BranchDeepLink } from 'interfaces'

export type BranchUrlArg = {
	alias: string
	branch_key: string
	data?: {
		$deeplink_path?: string
	}
}
export type ErrorBranch = {
	error?: {
		code: number
		message?: string
	}
}

type TypeParamUpdate = 'share_url' | 'branch_url_migration' | 'branch_url_sso'

export const createDeepLink = async (
	body: BranchUrlArg,
	argKey?: TypeParamUpdate
): Promise<BranchDeepLink> => {
	try {
		const response = await axios.post<BranchDeepLink>(
			`https://api2.branch.io/v1/url`,
			body
		)
		if (response?.data) return { ...response.data, key: argKey }

		throw new Error('Unable to create a deep link')
	} catch (error) {
		const axiosError = error as AxiosError<ErrorBranch>
		throw new Error(
			axiosError?.response?.data?.error?.message ??
				'Unable to create a deep link'
		)
	}
}
