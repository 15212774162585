import clsx from 'clsx'
import { Typography } from 'components'
import { PeopleNamesLayoutProps, TypographyTypes } from 'interfaces'
import React from 'react'

export const PeopleNamesLayout: React.FC<PeopleNamesLayoutProps> = ({
	rol,
	roles,
	fullName,
	sidebarOpen
}): JSX.Element => {
	const isSuperAdmin = roles?.includes(5) ? 'Super Admin' : 'Admin'
	const hasRol = rol ?? '-'
	return (
		<div className="flex items-center gap-2 px-4">
			<div
				className={clsx(
					sidebarOpen
						? 'min-h-[36.67px]  min-w-[36.67px] max-h-[36.67px]  max-w-[36.67px]'
						: 'h-[55px] w-[55px]',
					'flex justify-center items-center bg-gray-3 border-[4px] border-blue-primary rounded-full '
				)}
			>
				<Typography
					title={fullName[0]}
					fontLeading={sidebarOpen ? '18/21' : '22/27'}
					type={TypographyTypes.p}
					className="text-white font-Inter"
				/>
			</div>
			{sidebarOpen && (
				<div>
					<Typography
						fontLeading="15/18"
						type={TypographyTypes.h3}
						title={fullName}
						className="text-gray-1 !font-bold max-w-[200px]"
					/>
					<Typography
						fontLeading="15/18"
						type={TypographyTypes.h3}
						title={roles ? isSuperAdmin : hasRol}
						className="text-gray-1 !font-normal"
					/>
				</div>
			)}
		</div>
	)
}
