import { SettingsGlobal, SuperAdminLayout } from 'components'
import React from 'react'

export const SuperAdminSettings = (): JSX.Element => {
	return (
		<SuperAdminLayout>
			<SettingsGlobal />
		</SuperAdminLayout>
	)
}
