import { Input, InputZipCode, handleValid } from 'components'
import React, { useEffect } from 'react'
import { configGenaral } from 'utils'
import { PropsComponentsMyOrganization } from './interfaceSlide.type'

export const SlideLocation: React.FC<PropsComponentsMyOrganization> = ({
	active,
	pos,
	errors,
	register,
	setValue,
	watch,
	isValid,
	setFieldError
}): JSX.Element => {
	const rules = {
		rule: {
			required: { value: true, message: 'This field is required' }
		}
	}

	const nameType = `location${active}-${pos}`

	useEffect(() => {
		handleValid(nameType, isValid as boolean, setFieldError)
	}, [isValid])

	return (
		<div className="flex flex-col justify-between h-[95%] p-2">
			<div className="flex flex-col gap-6">
				<div className="flex flex-col w-full">
					<Input
						name={`tabs.${active}.components.${pos}.component_value.title`}
						title="Title"
						register={register}
						rules={rules.rule}
						error={
							errors?.tabs?.[active]?.components?.[pos]?.component_value?.title
						}
						placeholder="Example: Office"
						borderFull
						limit
						cantLimit={configGenaral.maxTitleLength}
						watchValue={watch(
							`tabs.${active}.components.${pos}.component_value.title`
						)}
						setValueInput={setValue}
					/>
				</div>
				<div className="flex flex-col w-full">
					<Input
						name={`tabs.${active}.components.${pos}.component_value.address_line`}
						title="Address Line"
						register={register}
						rules={rules.rule}
						error={
							errors?.tabs?.[active]?.components?.[pos]?.component_value
								?.address_line
						}
						placeholder="Enter Address"
						borderFull
					/>
				</div>
				<div className="flex flex-col w-full">
					<InputZipCode
						name={`tabs.${active}.components.${pos}.component_value.zip_code`}
						title="Zip/Postal Code"
						register={register}
						rules={rules.rule}
						error={
							errors?.tabs?.[active]?.components?.[pos]?.component_value
								?.zip_code
						}
						placeholder="Set a Zip/Postal Code"
						borderFull
						setValueInput={setValue}
						maxLength={10}
					/>
				</div>
			</div>
		</div>
	)
}
