import {
	Input,
	InputAutoCompleteGoogleMaps,
	Message,
	Typography
} from 'components'
import {
	ContentSetUpFormInputs,
	ContentSetUpProps,
	TypographyTypes,
	locationState
} from 'interfaces'
import { RegexEspecial } from 'lib'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import ReactQuill from 'react-quill'
import { configGenaral } from 'utils'

export const ContentSetUp: React.FC<ContentSetUpProps> = ({
	totalData,
	setTotalData,
	titleComponent = 'Content Set Up',
	setItem,
	item
}): JSX.Element => {
	const [description, setDescription] = useState('')
	const {
		watch,
		register,
		setValue,
		formState: { errors }
	} = useForm<ContentSetUpFormInputs>({
		mode: 'all'
	})

	const maxQuantity =
		titleComponent === 'Create New Location'
			? configGenaral.maxTitleLengthLocationName
			: configGenaral.maxTitleLength

	const rules = {
		title: {
			required: { value: true, message: 'This field is required' },
			maxLength: {
				value: maxQuantity,
				message: 'Maximum characters exceeded'
			},
			pattern: {
				value: RegexEspecial,
				message: 'Invalid Field'
			}
		},
		template: {
			required: { value: false }
		}
	}

	const title = watch('title')

	useEffect(() => {
		if (title && title.length <= maxQuantity) {
			setTotalData({
				...totalData,
				set_up: {
					...totalData.set_up,
					title
				}
			})
		}
	}, [title])

	useEffect(() => {
		if (description !== '') {
			const aux = description?.replace(/color:\s?[^;]+;/gi, '')
			setDescription(aux)
			setTotalData({
				...totalData,
				set_up: {
					...totalData.set_up,
					description: aux
				}
			})
		}
	}, [description])

	useEffect(() => {
		if (totalData.set_up && totalData.set_up.title !== '') {
			setValue('title', totalData.set_up.title)
		}
		if (totalData.set_up.description || totalData.set_up.description === '') {
			setDescription(totalData.set_up.description as string)
		}
	}, [totalData])

	return (
		<div className="w-full">
			<Typography
				title={titleComponent}
				className="text-gray-9 border-b border-gray-11 pb-3"
				type={TypographyTypes.h2}
				fontLeading="22/27"
			/>
			<div className="mt-5">
				<div className="flex gap-4">
					<Input
						name="title"
						title={
							titleComponent === 'Create New Location'
								? 'Location’s Name'
								: 'Title'
						}
						type="text"
						register={register}
						rules={rules.title}
						placeholder={
							titleComponent === 'Create New Location' ? 'Name' : 'Title'
						}
						error={errors.title}
						limit
						watchValue={title}
						setValueInput={setValue}
						cantLimit={maxQuantity}
						borderFull={false}
						inputClassName="!p-0 rounded"
						titleClassName="!text-[12px] !leading-[12px]"
					/>

					{/* <Input
						name="template"
						title="Template"
						type="text"
						rightIcon={Icons.search}
						register={register}
						rules={rules.template}
						error={errors.template}
						borderFull={false}
						placeholder="Template"
						inputClassName="!p-0 rounded"
						titleClassName="!text-[10px] !leading-[12px]"
					/> */}
				</div>
				{titleComponent === 'Create New Location' && (
					<div className="flex gap-4 flex-col w-full">
						<InputAutoCompleteGoogleMaps
							title
							location={item as locationState}
							setLocation={setItem as Dispatch<SetStateAction<locationState>>}
							classNameInput="p-2 w-[100%]"
						/>
					</div>
				)}
				<div className="mt-4">
					<div className="mb-3">
						<Typography
							title="Description"
							type={TypographyTypes.span}
							fontLeading="12/12"
						/>
					</div>
					<ReactQuill
						theme="snow"
						value={description}
						onChange={setDescription}
						className="createdContent"
						placeholder={
							titleComponent === 'Create New Location'
								? 'Write your location details here'
								: 'Write your content here'
						}
					/>

					<div className="w-full flex justify-between items-center">
						<h1 className="text-xs leading-[9px] text-black-1/50 pt-2">
							{description ? description.length : 0}/
							{configGenaral.maxDescriptionLength}
						</h1>
						{description.length > configGenaral.maxDescriptionLength && (
							<Message
								text={`Maximum ${configGenaral.maxDescriptionLength} characters exceeded`}
								type="error"
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}
