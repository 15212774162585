import clsx from 'clsx'
import { Icon, SwitchCustom, Typography } from 'components'
import { BoxSwirchProps, TypographyTypes } from 'interfaces'
import React from 'react'

export const BoxSwitch: React.FC<BoxSwirchProps> = ({
	icon,
	title,
	border = false,
	enabled,
	className,
	bold = false,
	setEnabled
}): JSX.Element => {
	return (
		<div
			className={clsx(
				className,
				'flex items-center justify-between pb-1 mt-[10px]',
				border && 'border-b border-gray-1'
			)}
		>
			<div className="flex items-center gap-2">
				{icon && (
					<Icon src={icon} className="w-[12px] h-[16px] text-gray-9" fillPath />
				)}
				<Typography
					title={title}
					type={TypographyTypes.span}
					className={clsx('text-gray-9', bold ? '!font-bold' : '!font-normal')}
					fontLeading="15/18"
				/>
			</div>
			<SwitchCustom enabled={enabled} setEnabled={setEnabled} />
		</div>
	)
}
