import { Disclosure } from '@headlessui/react'
import {
	AddFeed,
	Audience,
	BoxContentDelivery,
	Documents,
	GoodLink,
	Helper,
	Icon,
	Location,
	PushNotification,
	Schedule,
	ShareFMPartner,
	SharePartner,
	Slide,
	Typography
} from 'components'
import { ContentDeliveryProps, Icons, TypographyTypes } from 'interfaces'
import React, { useEffect, useState } from 'react'

export const ContentDelivery: React.FC<ContentDeliveryProps> = ({
	totalData,
	setTotalData,
	documentsList,
	listImagePush,
	deliveryActive,
	itemRecurrence,
	publishMyPeope,
	eventRecurrence,
	setDocumentsList,
	setListImagePush,
	setDeliveryActive,
	setItemRecurrence,
	setPublishMyPeope,
	setEventRecurrence,
	isLoading,
	itemDatePushNotofication,
	setItemDatePushNotofication,
	mode,
	typeFunction = 'Content',
	defineTypeLocation,
	setDefineTypeLocation,
	openDocument,
	setOpenDocument
}): JSX.Element => {
	const [addFeed, setAddFeed] = useState(false)
	const [location, setLocation] = useState(false)
	const [openSlide, setOpenSlide] = useState(false)
	const [documents, setDocuments] = useState(false)
	const [sharePartner, setSharePartner] = useState(false)
	const [slideGoodLink, setSlideGoodLink] = useState(false)
	const [slideSchedule, setSlideSchedule] = useState(false)
	const [slideAudience, setSlideAudience] = useState(false)
	const [shareFMPartner, setShareFMPartner] = useState(false)
	const [slidePushNotification, setSlidePushNotification] = useState(false)
	const [titleSlide, setTitleSlide] = useState('')

	useEffect(() => {
		if (openDocument) {
			setTitleSlide('Documents')
			setDocuments(true)
			setOpenSlide(true)
		}
	}, [openDocument])

	useEffect(() => {
		if (!openSlide) {
			setTitleSlide('')
			setAddFeed(false)
			setLocation(false)
			setDocuments(false)
			setSharePartner(false)
			setSlideGoodLink(false)
			setSlideSchedule(false)
			setSlideAudience(false)
			setShareFMPartner(false)
			setSlidePushNotification(false)
			setOpenDocument?.(false)
		}
	}, [openSlide])

	return (
		<Disclosure defaultOpen>
			<div className="relative">
				<Slide
					title={titleSlide}
					scroll={sharePartner}
					helper={location}
					titleHelper="If you set a Delivery Radius, this content item will be delivered when your audience enters the Radius."
					open={openSlide}
					setOpen={setOpenSlide}
					classNameTitle="!text-lavender !font-bold"
				>
					<>
						{slideGoodLink && (
							<GoodLink
								totalData={totalData}
								setOpenSlide={setOpenSlide}
								setTotalData={setTotalData}
								deliveryActive={deliveryActive}
								setDeliveryActive={setDeliveryActive}
								onClickClose={() => setOpenSlide(false)}
								mode={mode}
							/>
						)}
						{slideSchedule && (
							<Schedule
								totalData={totalData}
								setOpenSlide={setOpenSlide}
								setTotalData={setTotalData}
								deliveryActive={deliveryActive}
								itemRecurrence={itemRecurrence}
								eventRecurrence={eventRecurrence}
								setDeliveryActive={setDeliveryActive}
								setItemRecurrence={setItemRecurrence}
								setEventRecurrence={setEventRecurrence}
								onClickClose={() => setOpenSlide(false)}
								mode={mode}
								isLoading={isLoading}
								isExpiratedView
								locationComponent="Content Item"
							/>
						)}
						{slideAudience && (
							<Audience
								totalData={totalData}
								setAddFeed={state => {
									setTitleSlide('Add to a Feed')
									setAddFeed(state)
								}}
								setTotalData={setTotalData}
								setOpenSlide={setOpenSlide}
								publishMyPeope={publishMyPeope}
								setSharePartner={state => {
									setTitleSlide('Share With a Partner')
									setSharePartner(state)
								}}
								setSlideAudience={setSlideAudience}
								setShareFMPartner={state => {
									setTitleSlide('Share to FM Partner List')
									setShareFMPartner(state)
								}}
								setPublishMyPeope={setPublishMyPeope}
								onClickClose={() => setOpenSlide(false)}
							/>
						)}
						{sharePartner && (
							<SharePartner
								totalData={totalData}
								setTotalData={setTotalData}
								setSharePartner={setSharePartner}
								setSlideAudience={setSlideAudience}
								onClickClose={() => setOpenSlide(false)}
							/>
						)}
						{shareFMPartner && (
							<ShareFMPartner
								totalData={totalData}
								setTotalData={setTotalData}
								setSlideAudience={setSlideAudience}
								setShareFMPartner={setShareFMPartner}
								onClickClose={() => setOpenSlide(false)}
							/>
						)}
						{addFeed && (
							<AddFeed
								totalData={totalData}
								setAddFeed={setAddFeed}
								setTotalData={setTotalData}
								setSlideAudience={setSlideAudience}
								onClickClose={() => setOpenSlide(false)}
							/>
						)}
						{documents && (
							<Documents
								totalData={totalData}
								documentsList={documentsList}
								setDocumentsList={setDocumentsList}
								onClickClose={() => setOpenSlide(false)}
								mode={mode}
								isLoading={isLoading}
							/>
						)}
						{location && (
							<Location
								totalData={totalData}
								setTotalData={setTotalData}
								setOpenSlide={setOpenSlide}
								deliveryActive={deliveryActive}
								setDeliveryActive={setDeliveryActive}
								onClickClose={() => setOpenSlide(false)}
								isLoading={isLoading}
								defineTypeLocation={defineTypeLocation}
								setDefineTypeLocation={setDefineTypeLocation}
								mode={mode}
							/>
						)}

						{slidePushNotification && (
							<PushNotification
								totalData={totalData}
								setTotalData={setTotalData}
								setOpenSlide={setOpenSlide}
								listImagePush={listImagePush}
								deliveryActive={deliveryActive}
								setListImagePush={setListImagePush}
								setDeliveryActive={setDeliveryActive}
								onClickClose={() => setOpenSlide(false)}
								itemDatePushNotofication={itemDatePushNotofication}
								setItemDatePushNotofication={setItemDatePushNotofication}
							/>
						)}
					</>
				</Slide>

				{typeFunction === 'Content' ? (
					<>
						<div className="absolute left-[21rem] top-1">
							<Helper
								text="Publish item now to ALL your followers or enhance your Content Delivery/Engagement."
								className="relative"
							/>
						</div>
						<Disclosure.Button className="border-b border-gray-11 pb-3 w-full flex justify-between items-center">
							<div className="flex items-center gap-2">
								<Typography
									title="Content Delivery/Engagement"
									className="text-gray-9"
									type={TypographyTypes.h2}
									fontLeading="22/27"
								/>
							</div>
							<div>
								<Icon
									src={Icons.arrowDown}
									className="w-4 text-gray-1"
									fillLine
								/>
							</div>
						</Disclosure.Button>
						<Disclosure.Panel className="text-gray-500">
							<div>
								<div className="flex items-center gap-4">
									<BoxContentDelivery
										title="Schedule"
										onClick={() => {
											setTitleSlide('Schedule')
											setSlideSchedule(true)
											setOpenSlide(true)
										}}
									/>
									<BoxContentDelivery
										title="Audience"
										onClick={() => {
											setTitleSlide('Select Audience')
											setSlideAudience(true)
											setOpenSlide(true)
										}}
									/>
								</div>
								<div className="flex items-center gap-4">
									<BoxContentDelivery
										title="GoodLink"
										onClick={() => {
											setTitleSlide('GoodLink')
											setSlideGoodLink(true)
											setOpenSlide(true)
										}}
									/>
									<BoxContentDelivery
										title="Push Notification"
										onClick={() => {
											setTitleSlide('Push Notification')
											setSlidePushNotification(true)
											setOpenSlide(true)
										}}
									/>
								</div>
								<div className="flex items-center gap-4">
									<BoxContentDelivery
										title="Location"
										onClick={() => {
											setTitleSlide('Location')
											setLocation(true)
											setOpenSlide(true)
										}}
									/>
									<BoxContentDelivery
										title="Documents"
										onClick={() => {
											setTitleSlide('Documents')
											setDocuments(true)
											setOpenSlide(true)
										}}
									/>
								</div>
							</div>
						</Disclosure.Panel>
					</>
				) : (
					<BoxContentDelivery
						title="Documents"
						onClick={() => {
							setDocuments(true)
							setTitleSlide('Documents')
							setOpenSlide(true)
						}}
					/>
				)}
			</div>
		</Disclosure>
	)
}
