import { CircularProgress } from '@mui/material'
import List from '@mui/material/List'
import clsx from 'clsx'
import { Typography } from 'components'
import { BatchesTagsProps, TypographyTypes } from 'interfaces'
import React, { UIEventHandler, useEffect, useState } from 'react'
import { PRIMARY_BLUE_COLOR } from 'utils'
import { TagBatchItem } from './TagBatchItem'

export const BatchesTags = (props: BatchesTagsProps): JSX.Element => {
	const {
		data,
		onClickParent,
		onClickChildren,
		selectedRowTags,
		onAdd,
		onRemove,
		isLoading,
		disabledActions,
		hasNextPage,
		fetchNextPage,
		isFetchingNextPage
	} = props

	const [endReached, setEndReached] = useState(false)

	const handleScroll: UIEventHandler<HTMLDivElement> = event => {
		const { target } = event
		if (target) {
			const { scrollHeight, scrollTop, clientHeight } =
				target as HTMLInputElement
			const limit = clientHeight + 10
			const hasReachedBottom = scrollHeight - scrollTop <= limit
			if (hasReachedBottom) {
				setTimeout(() => {
					setEndReached(hasReachedBottom)
				}, 400)
			} else {
				setEndReached(hasReachedBottom)
			}
		}
	}

	useEffect(() => {
		if (endReached && hasNextPage && !isFetchingNextPage) {
			fetchNextPage()
		}
	}, [endReached, isFetchingNextPage])

	return (
		<div className="px-1.5 flex justify-between flex-col pb-4 2lg:h-[91%]">
			<div className="pt-2 mb-2 pr-2 border border-blue-primary rounded-2xl 2lg:h-[85%]">
				{isLoading && !isFetchingNextPage ? (
					<div className="flex items-center justify-center w-full h-[97%] min-h-[42vh]">
						<CircularProgress
							style={{ color: PRIMARY_BLUE_COLOR }}
							disableShrink
						/>
					</div>
				) : (
					<div
						className="flex flex-col gap-4 h-full max-h-[45vh] overflow-y-scroll scrollbar-stable pb-3"
						onScroll={handleScroll}
					>
						<List>
							{data.map(tag => (
								<TagBatchItem
									key={tag.organization_tag_parent_id}
									tag={tag}
									onClickParent={onClickParent}
									onClickChildren={onClickChildren}
									selectedRowTags={selectedRowTags}
								/>
							))}
						</List>
						<div
							className={clsx('opacity-0 w-full flex justify-center', {
								'opacity-100': isFetchingNextPage
							})}
						>
							<CircularProgress
								style={{ color: PRIMARY_BLUE_COLOR }}
								disableShrink
								size={30}
							/>
						</div>
					</div>
				)}
			</div>
			<div className="w-full flex justify-between">
				<button
					className="py-2 rounded-full border-2 border-blue-primary w-1/3 disabled:border-gray-3 batches-tags-button"
					type="button"
					disabled={disabledActions || !selectedRowTags.length}
					onClick={() => onRemove()}
				>
					<Typography
						title="Remove tags"
						fontLeading="15/18"
						type={TypographyTypes.h3}
						className="text-blue-primary batches-tags-label"
					/>
				</button>
				<button
					className="bg-blue-primary py-2.5 rounded-full w-1/3 disabled:bg-gray-3"
					type="button"
					disabled={disabledActions || !selectedRowTags.length}
					onClick={() => onAdd()}
				>
					<Typography
						title="Add tags"
						fontLeading="15/18"
						type={TypographyTypes.h3}
						className="text-white"
					/>
				</button>
			</div>
		</div>
	)
}
