/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * TODO:
 * Schedule appointment for review.
 */

import { InputProps } from 'interfaces'
import * as React from 'react'
import { Input } from './input'

export const InputZipCode: React.FC<
	InputProps & React.InputHTMLAttributes<HTMLInputElement>
> = ({ name, setValueInput, rules, onChangeCustom, ...props }) => {
	const [isDelete, setIsDelete] = React.useState(false)
	const [isRemove, setIsRemoce] = React.useState(false)

	const onKeyDownHandler = (event: any): void => {
		const codigo = event.which || event.keyCode
		if (codigo === 8) {
			setIsDelete(true)
		} else {
			setIsDelete(false)
		}
		if (codigo === 32 || codigo === 190) {
			setIsRemoce(true)
		} else {
			setIsRemoce(false)
		}
	}

	const handleChange = (e: any): void => {
		const val = e.target.value
		let cadena = val
		let newFormat = false
		const expresion = /^[a-zA-Z0-9]+$/

		if (!isDelete) {
			if (expresion.test(cadena) && !isRemove) {
				let format = ''

				if (cadena.length === 3) {
					newFormat = true
					format = cadena.replace(/(\d{3})/g, '$1')
				} else if (cadena.length === 6) {
					newFormat = true
					format = cadena.replace(/(\d{3})(\d{3})/g, '$1$2')
				} else if (cadena.length === 10) {
					newFormat = true
					format = cadena.replace(/(\d{3})(\d{3})(\d{4})/g, '$1$2$3')
				}
				setValueInput?.(name, newFormat ? format : val)
			} else {
				cadena = val.substring(0, val.length - 1)
				setValueInput?.(name, cadena)
			}
		}
		setIsDelete(false)
		onChangeCustom?.(e)
	}

	const finalRules = React.useMemo(() => {
		return {
			...rules
		}
	}, [rules])

	return (
		<Input
			name={name}
			type="tel"
			onChangeCustom={handleChange}
			onKeyDown={onKeyDownHandler}
			rules={finalRules}
			{...props}
		/>
	)
}
