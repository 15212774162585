import {
	ChevronDoubleLeftIcon,
	ChevronDoubleRightIcon,
	ChevronLeftIcon,
	ChevronRightIcon
} from '@heroicons/react/solid'
import clsx from 'clsx'
import { DropdownFull } from 'components/dropdown'
import { Typography } from 'components/typography'
import { PaginateProps, TypographyTypes } from 'interfaces'
import React from 'react'

export const Pagination: React.FC<PaginateProps> = ({
	totalRecords,
	pageLimit = 10,
	pageNeighbours = 1, // 0 1 2
	onPageChanged,
	page = 1,
	itemsShowing,
	onShowingItemsChange,
	className = undefined,
	buttonClassName = undefined,
	arrowButtonClassName = undefined
}) => {
	const LEFT_PAGE = -1
	const RIGHT_PAGE = -2
	const [currentPage, setCurrentPage] = React.useState(page)
	const [pages, setPages] = React.useState<number[]>([])
	const totalPages = React.useMemo(() => {
		return Math.ceil(totalRecords / pageLimit)
	}, [totalRecords, pageLimit])

	const range = (from: number, to: number, step = 1): number[] => {
		let i = from
		const rangeAux = []
		while (i <= to) {
			rangeAux.push(i)
			i += step
		}
		return rangeAux
	}

	React.useEffect(() => {
		/**
		 * totalNumbers: the total page numbers to show on the control
		 * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
		 */
		const totalNumbers = pageNeighbours * 2 + 3
		const totalBlocks = totalNumbers + 2

		if (totalPages > totalBlocks) {
			const startPage = Math.max(2, currentPage - pageNeighbours)
			const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours)
			let pagesAux = range(startPage, endPage)

			/**
			 * hasLeftSpill: has hidden pages to the left
			 * hasRightSpill: has hidden pages to the right
			 * spillOffset: number of hidden pages either to the left or to the right
			 */
			const hasLeftSpill = startPage > 2
			const hasRightSpill = totalPages - endPage > 1
			const spillOffset = totalNumbers - (pagesAux.length + 1)

			switch (true) {
				// handle: (1) < {5 6} [7] {8 9} (10)
				case hasLeftSpill && !hasRightSpill: {
					const extraPages = range(startPage - spillOffset, startPage - 1)
					pagesAux = [LEFT_PAGE, ...extraPages, ...pagesAux]
					break
				}

				// handle: (1) {2 3} [4] {5 6} > (10)
				case !hasLeftSpill && hasRightSpill: {
					const extraPages = range(endPage + 1, endPage + spillOffset)
					pagesAux = [...pagesAux, ...extraPages, RIGHT_PAGE]
					break
				}

				// handle: (1) < {4 5} [6] {7 8} > (10)
				case hasLeftSpill && hasRightSpill:
				default: {
					pagesAux = [LEFT_PAGE, ...pagesAux, RIGHT_PAGE]
					break
				}
			}
			setPages([1, ...pagesAux, totalPages])
		} else {
			setPages(range(1, totalPages))
		}
	}, [totalPages, currentPage, pageNeighbours])

	const gotoPage = (pageAux: number): void => {
		const currentPage2 = Math.max(0, Math.min(pageAux, totalPages))
		const paginationData = {
			currentPage: currentPage2,
			totalPages,
			pageLimit,
			totalRecords
		}

		onPageChanged(paginationData)
		setCurrentPage(currentPage2)
	}

	const handleClick =
		(pageClick: number) =>
		(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			evt.preventDefault()
			gotoPage(pageClick)
		}

	const handleMoveLeft = (
		evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
	): void => {
		evt.preventDefault()
		gotoPage(currentPage - 1)
	}

	const handleMoveRight = (
		evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
	): void => {
		evt.preventDefault()
		gotoPage(currentPage + 1)
	}

	const handleMoveLeftDoble = (
		evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
	): void => {
		evt.preventDefault()
		gotoPage(currentPage - pageNeighbours * 2 - 1)
	}

	const handleMoveRightDoble = (
		evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
	): void => {
		evt.preventDefault()
		gotoPage(currentPage + pageNeighbours * 2 + 1)
	}

	React.useEffect(() => {
		gotoPage(page)
	}, [page])

	if (pages.length <= 1) return null
	return (
		<div
			className={clsx(
				'flex w-full',
				itemsShowing ? 'justify-between' : 'justify-end',
				className
			)}
		>
			{itemsShowing && (
				<span className="flex gap-2 items-center">
					<Typography
						className="text-gray-3 !font-normal"
						type={TypographyTypes.span}
						fontLeading="15/18"
						title="Showing"
					/>
					<DropdownFull
						buttonClassName="!bg-transparent-default"
						small
						setItem={value => onShowingItemsChange?.(+value.name)}
						item={{ id: itemsShowing, name: `${itemsShowing}` }}
						list={[
							{ id: 10, name: '10' },
							{ id: 50, name: '50' },
							{ id: 100, name: '100' }
						]}
					/>
					<Typography
						className="text-gray-3 !font-normal"
						type={TypographyTypes.span}
						fontLeading="15/18"
						title="items"
					/>
				</span>
			)}
			<nav
				className="my-2 relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
				aria-label="Pagination"
			>
				<button
					type="button"
					aria-label="Previous"
					disabled={page === 1}
					onClick={handleMoveLeft}
					className={clsx(
						'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 ',
						'bg-white text-sm font-medium text-gray-500 hover:bg-blue-primary hover:text-white hover:border-blue-primary',
						arrowButtonClassName
					)}
				>
					<span className="sr-only">Previous</span>
					<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
				</button>
				{pages.map(element => {
					if (element === LEFT_PAGE)
						return (
							<button
								type="button"
								key={`pagination-${element}`}
								aria-label="Previous"
								onClick={handleMoveLeftDoble}
								className={clsx(
									'z-10 relative inline-flex items-center px-4 py-2',
									'border text-sm font-medium bg-white border-gray-300 text-gray-500',
									'hover:bg-blue-primary hover:text-white hover:border-blue-primary',
									arrowButtonClassName
								)}
							>
								<span className="sr-only">Next</span>
								<ChevronDoubleLeftIcon className="h-3 w-3" aria-hidden="true" />
							</button>
						)

					if (element === RIGHT_PAGE)
						return (
							<button
								key={`pagination-${element}`}
								type="button"
								aria-label="Next"
								onClick={handleMoveRightDoble}
								className={clsx(
									'z-10 relative inline-flex items-center px-4 py-2 border text-sm font-medium',
									'bg-white border-gray-300 text-gray-500 ',
									'hover:bg-blue-primary hover:text-white hover:border-blue-primary',
									arrowButtonClassName
								)}
							>
								<span className="sr-only">Next</span>
								<ChevronDoubleRightIcon
									className="h-3 w-3"
									aria-hidden="true"
								/>
							</button>
						)
					return (
						<button
							key={`pagination-${element}`}
							type="button"
							aria-current="page"
							onClick={handleClick(element)}
							className={clsx(
								'z-10 relative inline-flex items-center px-4 py-2 border text-sm font-medium',
								{
									'bg-blue-primary border-blue-primary text-white':
										element === currentPage
								},
								{
									'bg-white border-gray-300 text-gray-500 hover:bg-blue-primary hover:text-white hover:border-blue-primary':
										element !== currentPage
								},
								buttonClassName
							)}
						>
							{element}
						</button>
					)
				})}
				<button
					type="button"
					aria-label="Next"
					onClick={handleMoveRight}
					className={clsx(
						'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300',
						'bg-white text-sm font-medium text-gray-500 hover:bg-blue-primary hover:text-white',
						arrowButtonClassName
					)}
				>
					<span className="sr-only">Next</span>
					<ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
				</button>
			</nav>
		</div>
	)
}

export default Pagination
