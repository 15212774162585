/* eslint-disable react/require-default-props */
import { Button } from 'components/buttons'
import { GenericTable } from 'components/table'
import { BuildAppsListProps, ButtonProps } from 'interfaces'
import React from 'react'

const SelectButton: React.FC<
	{
		value: number
		selected?: number
		onClick?: (id: number) => void
	} & Omit<ButtonProps, 'onClick'>
> = props => {
	const { selected, value, onClick } = props
	return (
		<Button
			className="w-full !py-[5px]"
			onClick={() => onClick?.(value)}
			color={selected === value ? 'Primary' : 'Transparent'}
			label={selected === value ? 'Selected' : 'Select'}
		/>
	)
}

export const BuildAppList: React.FC<BuildAppsListProps> = props => {
	const { data, onSelect, dataSelected, ...tableProps } = props

	return (
		<GenericTable
			{...tableProps}
			headerTableClassName="!px-8"
			bodyTableClassName="!pl-8 !pr-2"
			data={data}
			setPage={() => undefined}
			headers={['App Name']}
			rows={['name']}
			actionColumn="right"
			justify="left"
			hideActionsHeader
			customWidth={[70]}
			actionColumnWidth={30}
			actionsButton={
				<SelectButton
					onClick={onSelect}
					value={0}
					selected={dataSelected?.at(0)}
				/>
			}
		/>
	)
}
