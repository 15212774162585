import { Button } from 'components/buttons'
import { Icon } from 'components/icon'
import { TailwindModal } from 'components/modal'
import { Typography } from 'components/typography'
import { Icons, ModalProps, TypographyTypes } from 'interfaces'
import React, { useEffect, useState } from 'react'

export const DeleteTagModal: React.FC<
	Omit<ModalProps, 'type' | 'children'> & {
		onConfirm: () => void
		tags: string[]
	}
> = props => {
	const { onConfirm, open, setOpen, tags, ...modalProps } = props
	const [continueConfirmed, setContinueConfirmed] = useState(false)

	useEffect(() => {
		setContinueConfirmed(false)
	}, [open])

	const closeModal = (): void => {
		setOpen(false)
		setContinueConfirmed(false)
	}

	return (
		<TailwindModal
			showCloseButton
			{...modalProps}
			open={open}
			className="py-6 px-4 rounded-lg min-w-[474px]"
			setOpen={setOpen}
		>
			{!continueConfirmed ? (
				<div className="flex flex-col gap-6">
					<Typography
						fontLeading="14/24"
						title="Are you sure you want to delete the following Tag(s)?"
						type={TypographyTypes.span}
					/>
					<span className="text-lg text-gray-9">{tags.join(', ')}</span>
					<span className="text-gray-1">
						Deleting a tag is permanent and cannot be undone
					</span>
					<div className="flex justify-between">
						<Button
							className="min-w-[45%]"
							onClick={closeModal}
							color="Transparent"
							label="Cancel"
						/>
						<Button
							className="min-w-[45%]"
							onClick={() => setContinueConfirmed(true)}
							color="Primary"
							label="Continue"
						/>
					</div>
				</div>
			) : (
				<div className="flex flex-col text-center justify-center w-full gap-2">
					<Icon className="w-12 mx-auto h-12" src={Icons.delete} />
					<Typography
						className="text-gray-9"
						fontLeading="18/21"
						title="You're about to delete a Tag(s)"
						type={TypographyTypes.span}
					/>
					<Typography
						className="!font-normal"
						fontLeading="15/18"
						title="Are you sure?"
						type={TypographyTypes.span}
					/>
					<div className="mt-2 flex gap-4 justify-center">
						<Button onClick={onConfirm} color="Transparent" label="Delete" />
						<Button onClick={closeModal} color="Primary" label="Cancel" />
					</div>
				</div>
			)}
		</TailwindModal>
	)
}
