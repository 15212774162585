import clsx from 'clsx'
import { Helper, Icon, Typography } from 'components'
import { DateTimerProps, TypographyTypes } from 'interfaces'
import moment from 'moment'
import React from 'react'
import Datetime from 'react-datetime'

export const DateTimer: React.FC<DateTimerProps> = ({
	icon,
	title,
	setDate,
	validate,
	className,
	line = true,
	initialValue,
	disabled = false,
	placeholderInput,
	timeFormat = 'hh:mm A',
	helper,
	textHelper,
	hasDatesBefore = false,
	value: dateTimeValue
}): JSX.Element => {
	const isValidDate = (currentDate: Date): boolean => {
		const selectedDate = moment(currentDate)
		const now = validate
		return selectedDate.isSameOrAfter(now, 'day')
	}

	const handleOnchage = (value: string | moment.Moment): void => {
		setDate(value)
	}

	return (
		<div className={className}>
			{title && !helper && (
				<Typography
					title={title}
					type={TypographyTypes.span}
					fontLeading="12/12"
					className="text-black-1"
				/>
			)}
			{title && helper && textHelper && (
				<div className="w-full flex justify-between items-center mb-3 ">
					<Typography
						title={title}
						type={TypographyTypes.span}
						fontLeading="12/12"
						className="text-black-1"
					/>
					<Helper text={textHelper} />
				</div>
			)}
			<div className="relative w-full">
				<Datetime
					value={dateTimeValue}
					timeFormat={timeFormat}
					initialValue={initialValue}
					onChange={value => handleOnchage(value)}
					className={clsx(line ? 'line' : 'full')}
					inputProps={{
						disabled,
						readOnly: true,
						placeholder: placeholderInput || ''
					}}
					{...(!hasDatesBefore && { isValidDate })}
					renderInput={(props, openCalendar) => {
						return (
							<button
								type="button"
								onClick={() => openCalendar()}
								className={props.className}
							>
								<input
									value={props.value}
									placeholder={props.placeholder}
									className={clsx(
										'w-full',
										'outline-none',
										!line && 'rounded-lg'
									)}
								/>
								<Icon
									src={icon}
									className={clsx(
										'w-4 absolute top-0 mb-10',
										line ? 'right-0' : 'right-2 '
									)}
								/>
							</button>
						)
					}}
				/>
			</div>
		</div>
	)
}
