export const rules = {
	title: {
		required: { value: true, message: 'This field is required' },
		maxLength: {
			value: 30,
			message: 'Maximum characters exceeded'
		}
	},
	message: {
		required: { value: true, message: 'This field is required' },
		maxLength: {
			value: 150,
			message: 'Maximum characters exceeded'
		}
	},
	template: {
		required: { value: false }
	},
	url: {
		maxLength: { value: 150, message: 'Maximum characters exceeded' },
		pattern: {
			value: /^(http|https|ftp):\/\/[^\s/$.?#].[^\s]*$/,
			message: 'Invalid link'
		}
	},
	urlPush: {
		maxLength: { value: 250, message: 'Maximum characters exceeded' },
		pattern: {
			value: /^(http|https|ftp):\/\/[^\s/$.?#].[^\s]*$/,
			message: 'Invalid link'
		}
	}
}
