import { API } from 'api/config'
import { UserDataSettingResponsive } from 'interfaces'

export const getUserInfo = async (
	userId: number
): Promise<UserDataSettingResponsive | null> => {
	let res = null as UserDataSettingResponsive | null
	if (userId) {
		await API()
			.get(`/user/${userId}/main_contact`)
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.error(error)
				}
			)
	}
	return res
}
