import { CircularProgress } from '@mui/material'
import { GET_DASHBOARD_PROMOTION, getPromotion } from 'api'
import clsx from 'clsx'
import {
	Box,
	Button,
	Modal,
	SendNotificationModal,
	TitleBox,
	Typography
} from 'components'
import { useToast } from 'hooks'
import { BoxComponentProps, Icons, TypographyTypes } from 'interfaces'
import QRCode from 'qrcode.react'
import React, { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'

export const Promotion: React.FC<BoxComponentProps> = ({
	styles
}): JSX.Element => {
	const { showToast } = useToast()
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')

	const { data, isLoading } = useQuery(
		[GET_DASHBOARD_PROMOTION, userId, organizationId],
		() => getPromotion(organizationId)
	)
	const [open, setOpen] = useState(false)
	const [sendNotificationModal, setSendNotificationModal] = useState(false)

	const handleCopy = useCallback((): void => {
		if (data) {
			navigator.clipboard.writeText(data.share_url).then(() => {
				showToast('Success', 'Link copied to clipboard.', 'success')
			})
		}
	}, [data])

	const downloadQRCode = (): void => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const qrCode = document.getElementById('qrCode') as any
		if (qrCode) {
			const qrCodeURL = qrCode
				.toDataURL('image/png')
				.replace('image/png', 'image/octet-stream')
			const aEl = document.createElement('a')
			aEl.href = qrCodeURL
			aEl.download = 'QR_Code.png'
			document.body.appendChild(aEl)
			aEl.click()
			document.body.removeChild(aEl)
			setOpen(false)
		}
	}

	return (
		<div className={clsx('relative', styles)}>
			<TitleBox title="Promotion" icon={Icons.share} className="mb-4" />
			<Box className="flex flex-col gap-3 w-full h-[320px] py-6 px-6">
				<>
					<Typography
						fontLeading="15/15"
						type={TypographyTypes.h3}
						title="AllAboard!"
						className="mb-6"
					/>
					{isLoading ? (
						<div className="flex items-center justify-center w-full h-[200px] py-2 px-1 ">
							<CircularProgress disableShrink />
						</div>
					) : (
						<>
							<Typography
								fontLeading="12/15"
								type={TypographyTypes.p}
								title="Copy & paste your AllAboard Link where people can click. Post QR Code where they can scan."
								className="text-gray-1 !font-normal mb-2"
							/>
							<div className="flex justify-between w-full border border-neutral-400 rounded-md  break-all items-center py-0.5">
								<Typography
									className="p-2 !font-normal text-gray-8 truncate"
									fontLeading="12/15"
									type={TypographyTypes.p}
									title={data?.share_url as string}
								/>
								<Button
									label=""
									className="!min-w-[unset] !border-none"
									iconLeft={Icons.copy}
									// leftIconDivClassName="!w-4 !h-5"
									color="Gray2"
									onClick={handleCopy}
								/>
							</div>
							<Button
								label="Download QR"
								iconLeft={Icons.qrCode}
								className="!min-w-[unset] !py-[9px]"
								onClick={() => setOpen(true)}
							/>
							<Button
								label="Send E-mail"
								iconLeft={Icons.mail}
								color="Transparent"
								className="!min-w-[unset] !py-[9px]"
								onClick={() => setSendNotificationModal(true)}
							/>
							{sendNotificationModal && (
								<SendNotificationModal
									open={sendNotificationModal}
									setOpen={setSendNotificationModal}
								/>
							)}
						</>
					)}
					<Modal
						setOpen={setOpen}
						open={open}
						type="normal"
						closeButton
						title="Actions"
						colorIcon="text-[#17B3C5] w-[14px]"
						borderIcon="border-[#17B3C5] p-[2px]"
						sizeModal="w-[326px] h-[330px] p-2"
						marginL
					>
						<div className="flex flex-col items-center  ">
							<div className="my-3">
								<QRCode
									id="qrCode"
									size={256}
									style={{ height: '214px', maxWidth: '214px', width: '214px' }}
									viewBox="0 0 256 256"
									value={data ? data.share_url : ''}
								/>
							</div>
							<Button
								label="Confirm Download"
								className="!min-w-[unset] !py-[9px] w-[224px] mt-2"
								onClick={() => downloadQRCode()}
							/>
						</div>
					</Modal>
				</>
			</Box>
		</div>
	)
}
