import { Input, InputAutoCompleteGoogleMaps } from 'components'
import { LocationContentItemForm, LocationContentItemProps } from 'interfaces'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { configGenaral } from 'utils'

export const LocationContentItem: React.FC<LocationContentItemProps> = ({
	item,
	setItem,
	totalData,
	setItemTitle,
	setNameLocation,
	nameLocation,
	key
}): JSX.Element => {
	const {
		watch,
		register,
		setValue,
		formState: { errors }
	} = useForm<LocationContentItemForm>()

	const locationName = watch('name')

	const rules = {
		name: {
			required: { value: true, message: 'This field is required' },
			maxLength: {
				value: configGenaral.titleExtraLarge,
				message: 'Maximum characters exceeded'
			}
		}
	}

	useEffect(() => {
		setItemTitle(locationName)
	}, [locationName])

	const dataItem = totalData.delivery_enhancements.location.find(
		location => location.location_type === 'item'
	)
	useEffect(() => {
		if (dataItem?.title) {
			setValue('name', dataItem.title)
		} else {
			setValue('name', '')
		}
	}, [totalData, dataItem])

	useEffect(() => {
		if (nameLocation) {
			setValue('name', nameLocation)
		}
	}, [totalData])

	return (
		<form>
			<Input
				limit
				name="name"
				type="text"
				rules={rules.name}
				register={register}
				error={errors.name}
				title="Location Name"
				watchValue={locationName}
				placeholder="Add optional Location Name"
				cantLimit={configGenaral.titleExtraLarge}
				onChangeCustom={e => {
					if (setNameLocation) {
						setNameLocation(e.target.value)
					}
				}}
			/>
			<InputAutoCompleteGoogleMaps
				location={item}
				setLocation={setItem}
				key={key}
			/>
		</form>
	)
}
