/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * TODO:
 * Schedule appointment for review.
 */

import { CircularProgress } from '@mui/material'
import clsx from 'clsx'
import { HeaderTableItem, Pagination, Typography } from 'components'
import { useSelect } from 'hooks'
import { ChildrenTableProps, TypographyTypes } from 'interfaces'
import { onPageChanged } from 'lib'
import moment from 'moment'
import React from 'react'

export const GenericTable: React.FC<ChildrenTableProps<any[]>> = React.memo(
	({
		data,
		backendPages,
		headers,
		rows,
		customWidth,
		justify = 'center',
		bodyTableClassName,
		headerTableClassName,
		actionsButton,
		idName = 'id',
		actionColumn = 'left',
		actionColumnWidth = 5,
		page,
		setPage,
		dataSelected,
		setDataSelected,
		hideActionsHeader,
		onSort,
		loading,
		table
	}): JSX.Element => {
		const { selectAll, handleSelectAll, handleSelected } = useSelect(
			data ?? [],
			dataSelected,
			setDataSelected,
			idName
		)

		const transfText = (text: string): string => {
			if (text === 'ios') {
				return 'iOS'
			}
			return text
		}

		return (
			<div className="w-full">
				<div className="py-2 px-1 bg-white border-2 border-gray-7 rounded-lg">
					<table className="w-full overflow-y-hidden">
						<thead
							className={clsx(headerTableClassName, 'flex w-full h-5 mb-3')}
						>
							<tr className="w-full flex justify-between items-center">
								{dataSelected && setDataSelected && (
									<th
										scope="col"
										className="flex items-center justify-center"
										style={{ flexBasis: `3%` }}
									>
										<input
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-blue-primary focus:ring-transparent-default cursor-pointer"
											checked={selectAll}
											onChange={handleSelectAll}
										/>
									</th>
								)}
								{actionsButton && actionColumn === 'left' && (
									<th
										scope="col"
										className={clsx(
											'flex items-center justify-center text-[15px] leading-[18px] text-gray-9 font-bold',
											!hideActionsHeader && 'cursor-pointer'
										)}
										style={{ flexBasis: `${actionColumnWidth}%` }}
									>
										{!hideActionsHeader && 'Actions'}
									</th>
								)}
								{data &&
									headers?.map?.((header, index) => {
										return (
											<HeaderTableItem
												onSort={onSort}
												header={header}
												key={`partnerTable_header_${header}`}
												justify={justify}
												table={table ?? undefined}
												column={rows?.[index]}
												style={
													customWidth
														? { flexBasis: `${customWidth[index]}%` }
														: { flexBasis: `${100 / data.length}%` }
												}
											/>
										)
									})}
								{actionsButton && actionColumn === 'right' && (
									<th
										scope="col"
										className={clsx(
											'flex items-center justify-center text-[15px] leading-[18px] text-gray-9 font-bold',
											!hideActionsHeader && 'cursor-pointer'
										)}
										style={{ flexBasis: `12%` }}
									>
										{!hideActionsHeader && 'Actions'}
									</th>
								)}
							</tr>
						</thead>
						<tbody
							className={clsx(
								bodyTableClassName,
								'w-full h-[14rem] overflow-y-auto l flex flex-col gap-2 '
							)}
						>
							{loading ? (
								<tr className="flex w-full justify-center items-center h-full">
									<CircularProgress disableShrink />
								</tr>
							) : (
								data?.map(item => (
									<tr
										key={item[idName]}
										className="w-full flex justify-between"
									>
										{dataSelected && setDataSelected && (
											<td
												className="flex justify-center items-center h-10"
												style={{ flexBasis: `3%` }}
											>
												<input
													type="checkbox"
													className="h-4 w-4 rounded border-gray-300 text-blue-primary focus:ring-transparent-default cursor-pointer"
													checked={dataSelected.includes(item[idName])}
													onChange={() => handleSelected(item[idName])}
												/>
											</td>
										)}
										{actionsButton && actionColumn === 'left' && (
											<td
												className={clsx(
													'h-10 flex justify-center items-center text-[15px] leading-[18px] text-center text-gray-9'
												)}
												style={{ flexBasis: `${actionColumnWidth}%` }}
											>
												{React.cloneElement(actionsButton, {
													value: item[idName]
												})}
											</td>
										)}
										{headers &&
											rows &&
											rows.map((row, rowIndex) => {
												const text = item[row]

												return (
													<td
														className={clsx(
															'w-full h-10 flex items-center text-[15px] leading-[18px] text-gray-9 ',
															justify === 'center' &&
																'justify-center text-center',
															justify === 'left' && 'justify-left text-left',
															justify === 'right' && 'justify-right text-right'
														)}
														style={
															customWidth
																? { flexBasis: `${customWidth[rowIndex]}%` }
																: { flexBasis: `${100 / data.length}%` }
														}
														key={`data-${row}-${item[idName]}`}
													>
														<Typography
															title={
																Number.isNaN(Date.parse(text))
																	? transfText(text)
																	: text.includes('T')
																	? moment
																			.utc(text)
																			.local()
																			.format('MM/DD/YYYY hh:mm A')
																	: text
															}
															fontLeading="15/18"
															type={TypographyTypes.span}
															className={clsx(
																text !== 'ios' && 'capitalize',
																'text-gray-9 !font-normal'
															)}
														/>
													</td>
												)
											})}
										{actionsButton && actionColumn === 'right' && (
											<td
												className={clsx(
													'h-10 flex justify-center items-center text-[15px] leading-[18px] text-center text-gray-9'
												)}
												style={{ flexBasis: `12%` }}
											>
												{React.cloneElement(actionsButton, {
													value: item[idName]
												})}
											</td>
										)}
									</tr>
								))
							)}
						</tbody>
					</table>
				</div>
				<div className="w-full flex items-center justify-end">
					<Pagination
						totalRecords={backendPages || 1}
						onPageChanged={pages => onPageChanged(pages, setPage)}
						page={page}
						pageLimit={1}
					/>
				</div>
			</div>
		)
	}
)
