import { API } from 'api/config'
import {
	CreateContentListResponse,
	CreateOrganizationRequest,
	EditContentListResponse,
	UpdateOrganizationBodyRequest,
	UpdaterganizationRequest
} from 'interfaces'

export const createNewOrganization = async (
	body: CreateOrganizationRequest
): Promise<CreateContentListResponse | null> => {
	let res = null as CreateContentListResponse | null
	await API()
		.post(`/organization`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}
export const updateOrganizationInformation = async (
	id: number,
	body: Partial<UpdaterganizationRequest>
): Promise<EditContentListResponse | null> => {
	try {
		let res = null as EditContentListResponse | null
		await API()
			.patch(`/organization/${id}/super_admin`, body)
			.then(
				async response => {
					res = response.data
				},
				error => {
					res = error.response.data
				}
			)
		return res
	} catch {
		throw new Error('Unable to update organization')
	}
}

export const updateOrganization = async (
	id: number,
	body: UpdateOrganizationBodyRequest
): Promise<EditContentListResponse | null> => {
	try {
		const response = await API().patch(`/organization/${id}/super_admin`, body)
		if (response) return response.data
		return null
	} catch (err) {
		throw new Error('Unable to update organization')
	}
}
