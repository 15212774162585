import { Button, InputTextArea, Typography } from 'components'
import { OrderPositionModalProps, TypographyTypes } from 'interfaces'
import React, { useState } from 'react'

import { Popover } from '@headlessui/react'
import clsx from 'clsx'
import { usePopper } from 'react-popper'

export const OrderPositionModal: React.FC<OrderPositionModalProps> = ({
	className,
	classPanel,
	data,
	pinHandlerOrder
}): JSX.Element => {
	const [referenceElement, setReferenceElement] =
		useState<HTMLButtonElement | null>(null)
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
		null
	)
	const { styles, attributes } = usePopper(referenceElement, popperElement)
	const [textareaValue, setTextAreaValue] = useState('')

	const onClickConfirm = async (id: number): Promise<void> => {
		await pinHandlerOrder(id, true, Number(textareaValue))
		setReferenceElement(null)
	}

	const valuOrder = data?.order

	return (
		<Popover className={className}>
			<Popover.Button
				className="flex justify-center items-center bg-blue-primary rounded-2xl min-w-[1.4rem] h-[1.4rem] text-white px-2 "
				ref={setReferenceElement}
			>
				{valuOrder ?? '-'}
			</Popover.Button>
			<Popover.Panel
				className={clsx(
					'absolute  !left-[9em] z-10  py-2 min-w-[250px] rounded-2xl transform overflow-hidden bg-white border border-gray-1 shadow-xl text-left',
					classPanel
				)}
				ref={setPopperElement}
				style={styles.popper}
				{...attributes.popper}
			>
				<div className="flex flex-col w-full">
					{!valuOrder ? (
						<>
							<div className="flex flex-col gap-3 w-full p-3">
								<div>
									<Typography
										title="Content Order"
										fontLeading="18/21"
										type={TypographyTypes.h3}
										className="text-gray-9 text-start"
									/>
								</div>
								<div>
									<Typography
										title="Move To:"
										fontLeading="15/18"
										type={TypographyTypes.h3}
										className=" text-black-1 text-start font-bold"
									/>
								</div>
							</div>
							<div className="!w-full !flex !justify-center items-center pl-6">
								<InputTextArea
									name="textArea"
									textAreaClassName="!h-[55px]  !w-[90%] !resize-none text-center !text-xl"
									setTextValue={setTextAreaValue}
									color="Gray3"
									type="number"
								/>
							</div>
							<div className="flex justify-center items-center gap-4 w-full mt-2">
								<Button
									label="Save Order"
									onClick={() => onClickConfirm(data?.id_content as number)}
									className="!min-w-[225px] !py-3 !px-9"
								/>
							</div>
						</>
					) : (
						<div className="flex justify-center items-center gap-4 w-full mt-2">
							<Button
								color="white"
								label="Clear"
								onClick={() =>
									pinHandlerOrder(data?.id_content as number, true, null)
								}
								className="!min-w-[225px] !py-3 !px-9"
							/>
						</div>
					)}
				</div>
			</Popover.Panel>
		</Popover>
	)
}
