import { CircularProgress } from '@mui/material'
import { editTag } from 'api'
import { Button, Icon, TailwindModal, Typography } from 'components'
import { useToast } from 'hooks'
import {
	GenericModalProps,
	Icons,
	PeopleMyTagsChildrenItem,
	PeopleMyTagsItemProps,
	TypographyTypes
} from 'interfaces'
import React, { useState } from 'react'

export const TagsVisibilityModal: React.FC<
	GenericModalProps<PeopleMyTagsChildrenItem | PeopleMyTagsItemProps>
> = ({
	open,
	setOpen,
	data,
	value,
	refetch,
	myPeopleRefetch,
	setAnchorEl,
	updateTagsVisibilityChildrens,
	isChildren
}) => {
	const [isLoading, setIsLoading] = useState(false)
	const { showToast } = useToast()
	const requireApprovalHandler = async (): Promise<void> => {
		setIsLoading(true)
		try {
			if (updateTagsVisibilityChildrens) {
				if (isChildren) {
					await editTag(value as number, {
						is_visible: !data?.is_visible
					})
					setAnchorEl?.(null)
					if (refetch) {
						refetch()
					}
					if (myPeopleRefetch) {
						myPeopleRefetch()
					}
				} else {
					await updateTagsVisibilityChildrens(
						value as number,
						!data?.is_visible
					)
					showToast('Success', "Tag's info updated successfully.", 'success')
					setAnchorEl?.(null)
				}
			}
		} catch (error) {
			console.error('Error updating tags:', error)
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			showCloseButton
			className="h-[300px] w-[358px] sm:h-[160px] md:h-[160px] flex items-center justify-center px-2 py-4 rounded-[16px]"
		>
			{isLoading ? (
				<CircularProgress disableShrink />
			) : (
				<div className="flex flex-col gap-4 w-full">
					<div className="flex flex-col gap-3 w-full items-center">
						<div className="w-[30px] h-[30px]">
							<Icon
								src={Icons.warning}
								fillPath
								className="text-blue-primary"
							/>
						</div>
						<Typography
							title={`Are you sure you want to ${
								data?.is_visible ? 'hide' : 'show'
							} this tag?`}
							fontLeading="15/18"
							type={TypographyTypes.h3}
							className="!font-normal text-black-1 text-center"
						/>
					</div>
					<div className="w-full flex flex-col sm:flex-row justify-center gap-4 mt-4">
						<Button
							label="Cancel"
							color="Transparent"
							onClick={() => setOpen(false)}
							className="!min-w-[unset] !py-3 !px-9"
						/>
						<Button
							label="Confirm"
							onClick={() => requireApprovalHandler()}
							className="!min-w-[unset] !py-3 !px-9"
						/>
					</div>
				</div>
			)}
		</TailwindModal>
	)
}
