import clsx from 'clsx'
import { Icon, Typography } from 'components'
import {
	ContentAssetButtonProps,
	ContentAssetSlotProps,
	Icons,
	TypographyTypes
} from 'interfaces'
import React from 'react'
import VideoPlayer from './videoCard'

export const ContentAssetButton = (
	props: ContentAssetButtonProps
): JSX.Element => {
	const { onClick, label, className, direction, icon } = props

	if (direction === 'row') {
		return (
			<button
				type="button"
				className={clsx(
					'flex w-1/2 items-center h-full py-2.5 pl-4',
					className
				)}
				onClick={onClick}
			>
				<Icon src={icon} className=" w-10 h-10" />
				<div className="flex items-center justify-center py-2 bg-white rounded-full w-10/12  border-2 border-blue-primary ml-3 ">
					<Typography
						fontLeading="15/18"
						type={TypographyTypes.p}
						title={label}
						className=" text-blue-primary font-bold"
					/>
				</div>
			</button>
		)
	}
	return (
		<button
			type="button"
			className={clsx(
				'w-1/2 h-full bg-white flex items-center justify-center  flex-col ring-0 focus:ring-0 focus:outline-none',
				className
			)}
			onClick={onClick}
		>
			<Icon
				src={icon}
				className=" w-24 h-24 hover:scale-125 transition-all mb-4"
			/>
			<div className="flex items-center justify-center py-2 bg-white rounded-full w-10/12  border-2 border-blue-primary  ">
				<Typography
					fontLeading="15/18"
					type={TypographyTypes.p}
					title={label}
					className="font-bold text-blue-primary"
				/>
			</div>
		</button>
	)
}

export const ContentAssetSlot = (props: ContentAssetSlotProps): JSX.Element => {
	const {
		emptyAssets,
		hasImages,
		onClickPdf,
		onClickMedia,
		imageSrc,
		videoSrc,
		hasVideo,
		hasPdf,
		setOpenSlide,
		setSlideImages
	} = props

	return (
		<div className="mt-2 rounded-xl min-w-full shadow-md overflow-hidden">
			{Boolean(emptyAssets) && (
				<div className="bg-gray-11 w-full h-[528px] rounded-xl flex overflow-hidden ">
					<ContentAssetButton
						label="Add a PDF"
						icon={Icons.addPdf}
						onClick={() => {
							onClickPdf?.()
						}}
					/>
					<ContentAssetButton
						className="border-l shadow-md"
						label="Add an Image or video."
						icon={Icons.addAssets}
						onClick={() => {
							onClickMedia?.()
						}}
					/>
				</div>
			)}
			{Boolean(hasImages) && imageSrc && (
				<button
					type="button"
					className="flex w-full items-center justify-center bg-gray-11"
					onClick={() => {
						onClickMedia?.()
					}}
				>
					<img
						src={imageSrc}
						alt="preview"
						className="1lg:max-w-[544.28px] max-w-[330.28px] min-w-[204.73px] max-h-[23rem] py-3 px-7 !rounded-2xl "
					/>
				</button>
			)}
			{hasVideo && videoSrc && (
				<VideoPlayer
					videoUrl={videoSrc}
					setSlideImages={setSlideImages}
					setOpenSlide={setOpenSlide}
					type="content"
				/>
			)}
			{Boolean(hasPdf) && (
				<div className="w-full flex items-center justify-center h-[428px] bg-gray-11">
					<button
						type="button"
						onClick={() => {
							onClickPdf?.()
						}}
					>
						<Icon src={Icons.document} className="w-48 h-48" />
					</button>
				</div>
			)}
			{!emptyAssets && (
				<div className="w-full bg-white flex items-center">
					<ContentAssetButton
						direction="row"
						label="Add a PDF"
						icon={Icons.addPdf}
						onClick={() => {
							onClickPdf?.()
						}}
					/>

					<ContentAssetButton
						direction="row"
						className="border-l shadow-md"
						label="Add an Image or video."
						icon={Icons.addAssets}
						onClick={() => {
							onClickMedia?.()
						}}
					/>
				</div>
			)}
		</div>
	)
}
