export const getExtFromBase64 = (base64?: string): string => {
	if (!base64) return ''
	const matches = base64.match(/^data:([A-Za-z-+/]+);base64/)
	if (!matches || matches.length < 2) return ''

	const mimeType = matches[1]
	const mimeToExtension: { [key: string]: string } = {
		'image/jpeg': 'jpg',
		'image/png': 'png',
		'application/pdf': 'pdf'
	}
	return mimeToExtension?.[mimeType] ?? ''
}
