import { Button, Icon, TailwindModal, Typography } from 'components'
import { Icons, ModalPropsDecline, TypographyTypes } from 'interfaces'
import React from 'react'

export const DeclineModal: React.FC<ModalPropsDecline> = ({
	open,
	setOpen,
	onClickConfirm,
	idSharedConten,
	setOpenModalDecline,
	setOpenModalSendArray,
	openModalSendArray,
	onClickSubmit,
	dataSelected,
	setDataSelected
}) => {
	const handleConfirmClick = (): void => {
		if (idSharedConten && !openModalSendArray) {
			onClickConfirm({ status: 'declined', is_approved: true }, idSharedConten)
			if (setOpenModalDecline) {
				setOpenModalDecline(false)
			}
		} else if (onClickSubmit && openModalSendArray) {
			onClickSubmit({
				organization_partner_ids: dataSelected,
				status: 'declined',
				is_approved: true
			})
			setDataSelected?.([])
			setOpenModalDecline?.(false)
			setOpenModalSendArray?.(false)
		}
	}

	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			showCloseButton
			className="w-[360px] min-h-[168px] p-2 rounded-[16px]"
		>
			<div className="flex flex-col gap-4 w-full">
				<div className="flex flex-col gap-3 w-full items-center">
					<div className="w-[30px] h-[30px]">
						<Icon src={Icons.warning} fillPath className="text-blue-primary" />
					</div>

					<Typography
						title=" Are you sure you want to decline 
                        this content item?"
						fontLeading="15/18"
						type={TypographyTypes.h3}
						className="!font-normal text-black-1 text-center"
					/>
				</div>
				<div className="flex justify-center items-center gap-4 w-full">
					<Button
						label="Confirm"
						className="!min-w-[unset] !py-3 !px-9"
						onClick={() => handleConfirmClick()}
					/>
				</div>
			</div>
		</TailwindModal>
	)
}
