import clsx from 'clsx'
import { Icon, Pagination, Typography } from 'components'
import { useToast } from 'hooks'
import {
	AppNamesTableItem,
	ChildrenTableProps,
	Icons,
	PaginationType,
	TypographyTypes
} from 'interfaces'
import React from 'react'
import { getCustomWidth, justifyTextValues, justifyValues } from 'utils'

export const AppNamesTable: React.FC<
	ChildrenTableProps<AppNamesTableItem[]>
> = ({
	data,
	backendPages,
	page,
	setPage,
	customWidth,
	justify = 'center',
	bodyTableClassName,
	actionsButton,
	actionColumn = 'left',
	actionColumnWidth = 5
}): JSX.Element => {
	const { showToast } = useToast()
	const onPageChanged = (pages: PaginationType): void => {
		const newPage = pages.currentPage
		if (setPage) setPage(newPage)
	}

	const onCopyLink = (text: string): void => {
		navigator.clipboard.writeText(text.trim())
		showToast('Copied', 'AAC copied to the clipboard', 'success')
	}

	return (
		<div className={clsx('w-full mt-6')}>
			<div className="py-2 px-1 bg-white border-2 border-gray-7 rounded-lg">
				<table className="w-full overflow-y-hidden">
					<thead className="flex w-full h-11">
						<tr className="w-full flex justify-between items-start">
							{actionsButton && actionColumn === 'left' && (
								<th
									scope="col"
									className="flex items-center justify-center text-[15px] leading-[18px] text-gray-9 font-bold cursor-pointer"
									style={{ flexBasis: `${actionColumnWidth}%` }}
								>
									Actions
								</th>
							)}
							<th
								scope="col"
								className={clsx(
									'flex items-center justify-center gap-3 text-[15px] leading-[18px] text-gray-9 font-bold cursor-pointer select-none',
									justifyValues?.[justify]
								)}
								style={
									customWidth
										? { flexBasis: `${customWidth[0]}%` }
										: { flexBasis: `${100 / 3}%` }
								}
							>
								<Typography
									title="Name"
									fontLeading="15/18"
									type={TypographyTypes.span}
								/>
							</th>
							<th
								scope="col"
								className={clsx(
									'flex items-center justify-center gap-3 text-[15px] leading-[18px] text-gray-9 font-bold cursor-pointer select-none',
									justifyValues?.[justify]
								)}
								style={
									customWidth
										? { flexBasis: `${customWidth[1]}%` }
										: { flexBasis: `${100 / 2}%` }
								}
							>
								<div className="flex flex-col gap-1">
									<Typography
										title="Status"
										fontLeading="15/18"
										type={TypographyTypes.span}
										className="pl-1"
									/>
									<div className="flex items-center gap-4">
										<Typography
											title="iOS"
											fontLeading="15/18"
											type={TypographyTypes.span}
											className="!font-normal"
										/>
										<Typography
											title="Android"
											fontLeading="15/18"
											type={TypographyTypes.span}
											className="!font-normal"
										/>
									</div>
								</div>
							</th>
							<th
								scope="col"
								className={clsx(
									'flex items-center justify-center text-[15px] leading-[18px] text-gray-9 font-bold cursor-pointer select-none'
								)}
								style={
									customWidth
										? { flexBasis: `${customWidth[2]}%` }
										: { flexBasis: `${100 / 3}%` }
								}
							>
								<div className="flex flex-col gap-1">
									<div className="flex w-[120px] pr-1">
										<Typography
											title="Latest Version"
											fontLeading="15/18"
											type={TypographyTypes.p}
										/>
									</div>
									<div className="flex pl-1 items-center gap-4">
										<Typography
											title="iOS"
											fontLeading="15/18"
											type={TypographyTypes.span}
											className="!font-normal"
										/>
										<Typography
											title="Android"
											fontLeading="15/18"
											type={TypographyTypes.span}
											className="!font-normal"
										/>
									</div>
								</div>
							</th>
							<th
								scope="col"
								className={clsx('flex items-center justify-center text-gray-9')}
								style={
									customWidth
										? { flexBasis: `${customWidth[3]}%` }
										: { flexBasis: `${100 / 3}%` }
								}
							>
								<div className="flex">
									<Typography
										title="Link Branch"
										fontLeading="15/18"
										type={TypographyTypes.p}
									/>
								</div>
							</th>
							{actionsButton && actionColumn === 'right' && (
								<th
									scope="col"
									className="flex items-center justify-center text-[15px] leading-[18px] text-gray-9 font-bold cursor-pointer"
									style={{ flexBasis: `12%` }}
								>
									Actions
								</th>
							)}
						</tr>
					</thead>
					<tbody
						className={clsx(
							bodyTableClassName,
							'w-full h-[14rem] overflow-y-auto flex flex-col gap-2'
						)}
					>
						{data?.map(item => (
							<tr
								key={item.id}
								className="w-full flex justify-between border-b border-b-gray-11"
							>
								{actionsButton && actionColumn === 'left' && (
									<td
										className={clsx(
											'h-10 flex justify-center items-center text-[15px] leading-[18px] text-center text-gray-9'
										)}
										style={{ flexBasis: `${actionColumnWidth}%` }}
									>
										{React.cloneElement(actionsButton, {
											data: item
										})}
									</td>
								)}
								<td
									className={clsx(
										'h-10 flex items-center text-[15px] leading-[18px] text-gray-9',
										justifyTextValues?.[justify],
										justifyValues?.[justify]
									)}
									style={getCustomWidth({
										customWidth,
										index: 0,
										total: 3
									})}
								>
									{item.app_name}
								</td>
								<td
									className={clsx(
										'h-10 flex items-center text-[15px] leading-[18px] text-gray-9',
										justifyTextValues?.[justify],
										justifyValues?.[justify]
									)}
									style={getCustomWidth({
										customWidth,
										index: 1,
										total: 3
									})}
								>
									<div className="flex justify-center items-center gap-6">
										<div className="w-4 h-4">
											<Icon
												src={
													item.versions.ios === null ||
													item.versions.ios.status !== 'published'
														? Icons.cancel
														: Icons.checkCircle
												}
												fillPath
												className={clsx(
													item.versions.ios === null ||
														item.versions.ios.status !== 'published'
														? 'text-gray-3'
														: 'text-blue-primary'
												)}
											/>
										</div>
										<div className="w-4 h-4">
											<Icon
												src={
													item.versions.android === null ||
													item.versions.android.status !== 'published'
														? Icons.cancel
														: Icons.checkCircle
												}
												fillPath
												className={clsx(
													item.versions.android === null ||
														item.versions.android.status !== 'published'
														? 'text-gray-3'
														: 'text-blue-primary'
												)}
											/>
										</div>
									</div>
								</td>
								<td
									className={clsx(
										'h-10 flex justify-center text-center items-center text-[15px] leading-[18px] text-gray-9'
									)}
									style={getCustomWidth({
										customWidth,
										index: 2,
										total: 3
									})}
								>
									<div className="flex justify-center items-center gap-6 w-[120px]">
										<Typography
											title={item?.versions.ios?.version_build ?? 'N/A'}
											fontLeading="15/18"
											type={TypographyTypes.span}
											className="!font-normal"
										/>
										<Typography
											title={item?.versions.android?.version_build ?? 'N/A'}
											fontLeading="15/18"
											type={TypographyTypes.span}
											className="!font-normal"
										/>
									</div>
								</td>
								<td
									className={clsx(
										'h-10 flex items-center text-[15px] leading-[18px] text-gray-9',
										justifyTextValues?.[justify],
										justifyValues?.[justify]
									)}
									style={getCustomWidth({
										customWidth,
										index: 3,
										total: 4
									})}
								>
									{item?.branch_url ? (
										<>
											<Typography
												title={item?.branch_url}
												fontLeading="15/18"
												type={TypographyTypes.span}
												className="!font-normal"
											/>
											<button
												type="button"
												onClick={() => onCopyLink(item?.branch_url)}
												className="px-2"
											>
												<Icon
													src={Icons.copy}
													fillPath
													className="text-gray-1"
												/>
											</button>
										</>
									) : (
										<Icon
											src={Icons.cancel}
											fillPath
											className="text-gray-9 max-h-[20px] w-[20px]"
										/>
									)}
								</td>
								{actionsButton && actionColumn === 'right' && (
									<td
										className={clsx(
											'h-10 flex justify-center items-center text-[15px] leading-[18px] text-center text-gray-9'
										)}
										style={{ flexBasis: `12%` }}
									>
										{React.cloneElement(actionsButton, {
											value: item.id
										})}
									</td>
								)}
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<div className="w-full flex items-center justify-end">
				<Pagination
					totalRecords={backendPages || 1}
					onPageChanged={onPageChanged}
					page={page}
					pageLimit={1}
				/>
			</div>
		</div>
	)
}
