import { PaginationType } from 'interfaces'
import React from 'react'

export const onPageChanged = (
	pages: PaginationType,
	setPage?: (value: React.SetStateAction<number>) => void
): void => {
	const newPage = pages.currentPage
	if (setPage) setPage(newPage)
}
