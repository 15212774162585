import { getMyForgotPassword } from 'api/get/forgotPassword'
import { Button } from 'components/buttons'
import { Input } from 'components/input'
import { TailwindModal } from 'components/modal'
import { useToast } from 'hooks'
import {
	ForgotPasswordResponse,
	ModalForm,
	PropsModalPassword
} from 'interfaces'
import React, { useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

const ModalForgotPassword: React.FC<PropsModalPassword> = ({
	open,
	setOpen
}) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = useForm<ModalForm>({
		mode: 'all'
	})
	const { showToast } = useToast()

	const rules = useMemo(() => {
		return {
			email: {
				required: { value: true, message: 'This field is required' }
			}
		}
	}, [])

	const onSubmitData: SubmitHandler<ModalForm> = async data => {
		setOpen(!open)
		reset()
		const getEmailPassword = await getMyForgotPassword(data.email).then(
			(response: ForgotPasswordResponse | null) => {
				if (response?.detail) {
					showToast(response.detail, '', 'error')
				} else {
					showToast(
						'We have sent you a password recovery email. Please check your inbox',
						'',
						'success'
					)
				}
			}
		)

		return getEmailPassword
	}

	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			title="Recover your password"
			className="w-[425px] p-[16px] rounded-[16px]"
			id="RecoverYourPassword"
		>
			<form onSubmit={handleSubmit(onSubmitData)}>
				<div className="w-full flex flex-col items-center ">
					<div className="w-full mt-2">
						<Input
							name="email"
							title="Email"
							register={register}
							rules={rules.email}
							placeholder="Enter your verified email"
							error={errors.email}
							className="mb-4"
							id="emailRecoverYourPassword"
						/>
					</div>

					<div className="w-full flex justify-between items-center  ">
						<Button
							label="Cancel"
							className="!min-w-[unset] !py-[9px] w-[190px] mt-2"
							color="Bg-gray"
							onClick={() => setOpen(!open)}
							id="close"
						/>
						<Button
							label="Save"
							className="!min-w-[unset] !py-[9px] w-[190px] mt-2"
							type="submit"
							id="saveRecoverYourPassword"
						/>
					</div>
				</div>
			</form>
		</TailwindModal>
	)
}
export default ModalForgotPassword
