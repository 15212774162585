import { API } from 'api'
import {
	AppNamesTableResponse,
	AppVersionsTableResponse,
	BuildAppInfoResponse,
	EditContentListResponse,
	GetBuildAppOrganization,
	GetCommunityBuildAppsResponse,
	IdUpdateBuilApp,
	TypeOrder,
	UpdateImageResponse
} from 'interfaces'

export const getCommunityBuildApps = async (filters?: {
	search?: string
	columnOrder?: string
	orderBy?: string
	size?: number
	page?: number
}): Promise<GetCommunityBuildAppsResponse | null> => {
	let res = null as GetCommunityBuildAppsResponse | null
	await API()
		.get('/build_apps/', {
			params: {
				community_id: process.env.REACT_APP_COMMUNITY_ID,
				search: filters?.search,
				column_order: filters?.columnOrder,
				order_by: filters?.orderBy,
				size: filters?.size,
				page: filters?.page
			}
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getBuildApps = async (
	page: number,
	searchValue: string,
	column?: string,
	orderBy?: TypeOrder
): Promise<AppNamesTableResponse | null> => {
	let res = null as AppNamesTableResponse | null
	await API()
		.get(
			`/build_apps/
			?community_id=${process.env.REACT_APP_COMMUNITY_ID}
			&size=10
			&page=${page.toString()}
			${searchValue && searchValue.length > 0 ? `&search=${searchValue}` : ''}
			${column && column.length > 0 ? `&column_order=${column}` : ''}
			${orderBy && orderBy.length > 0 ? `&order_by=${orderBy}` : ''}
			`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getAppVersions = async (
	appVersion: string | undefined,
	page: number,
	searchValue: string,
	column?: string,
	orderBy?: TypeOrder
): Promise<AppVersionsTableResponse | null> => {
	let res = null as AppVersionsTableResponse | null
	if (appVersion) {
		await API()
			.get(
				`/build_apps/versions/${appVersion}
				?community_id=${process.env.REACT_APP_COMMUNITY_ID}
				&page=${page.toString()}
				${searchValue && searchValue.length > 0 ? `&search=${searchValue}` : ''}
				${column && column.length > 0 ? `&column_order=${column}` : ''}
				${orderBy && orderBy.length > 0 ? `&order_by=${orderBy}` : ''}
				`
			)
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.error(error)
				}
			)
	}
	return res
}

export const getUploadImage = async (
	imgName: string
): Promise<UpdateImageResponse | null> => {
	let res = null as UpdateImageResponse | null
	await API()
		.get(
			`/upload/image?file_name=${imgName}&slug=${process.env.REACT_APP_COMMUNITY_ID}`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)

	return res
}

export const getBuildApp = async (
	id: string | undefined
): Promise<BuildAppInfoResponse | null> => {
	let res = null as BuildAppInfoResponse | null
	if (id) {
		await API()
			.get(`/build_apps/${id}`)
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.error(error)
				}
			)
	}
	return res
}
export const getBuildAppOrganization = async (filters?: {
	id: number
	search?: string
	columnOrder?: string
	orderBy?: string
	size?: number
	page?: number
}): Promise<GetBuildAppOrganization | null> => {
	let res = null as GetBuildAppOrganization | null
	if (filters?.id) {
		await API()
			.get(
				`/super_admin/organization/${filters?.id}/build_apps/${process.env.REACT_APP_COMMUNITY_ID}`,
				{
					params: {
						community_id: process.env.REACT_APP_COMMUNITY_ID,
						search: filters?.search,
						column_order: filters?.columnOrder,
						order_by: filters?.orderBy,
						size: filters?.size,
						page: filters?.page
					}
				}
			)
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.error(error)
				}
			)
	}
	return res
}

export const updateAppBuild = async (
	idOrganization: number,
	body: IdUpdateBuilApp
): Promise<EditContentListResponse | null> => {
	let res = null as EditContentListResponse | null
	await API()
		.patch(
			`/super_admin/organization/${idOrganization}/build_apps/${process.env.REACT_APP_COMMUNITY_ID}`,
			body
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}
