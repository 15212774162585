import { Layout, SettingsGlobal } from 'components'
import React from 'react'

export const Settings = (): JSX.Element => {
	return (
		<Layout>
			<SettingsGlobal />
		</Layout>
	)
}
