import { GET_CONTENTS, getContentsListSelect } from 'api'
import clsx from 'clsx'
import { Input, InputTextArea, Message } from 'components'
import { Typography } from 'components/typography'
import { PaginationContext } from 'context'
import {
	ContentSetUpProps,
	DropdownFullItemProps,
	TypographyTypes
} from 'interfaces'
import { linkToUrl } from 'lib'
import React, { useContext, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { configGenaral } from 'utils'
import { rules } from './RulesSendDirectPush'

export const DirectPushSetUp: React.FC<ContentSetUpProps> = ({
	item,
	listImage,
	totalData,
	setDontSend,
	setTotalData,
	messageDashboard,
	setplaceHolderInput,
	radioFilter,
	setRadioFilter,
	datacontentType,
	setDataContentType,
	contentType,
	setContentType
}): JSX.Element => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const { id } = useParams()
	const organizationId = cookies.get('organization_id')
	const [messageInput, setMessageInput] = useState('')
	const {
		setValue,
		register,
		watch,
		formState: { isValid }
	} = useFormContext()
	const title = watch('title')
	const url = watch('url')
	const { tables } = useContext(PaginationContext)

	useEffect(() => {
		if (totalData.set_up.cta_Type && totalData.set_up.cta_Type !== '') {
			if (setRadioFilter) {
				setRadioFilter(totalData.set_up.cta_Type)
			}
		}

		if (totalData.set_up && totalData.set_up.title !== '') {
			setValue('title', totalData.set_up.title)
		}

		if (totalData.set_up.message && totalData.set_up.message !== '') {
			setMessageInput(totalData.set_up.message)
			setValue('message', totalData.set_up.message)
		}
	}, [totalData])

	useEffect(() => {
		if (
			messageDashboard &&
			messageDashboard.length <= configGenaral.maxMessagePush
		) {
			setMessageInput(messageDashboard)
			setValue('message', messageDashboard)
		}
	}, [])

	useEffect(() => {
		if (messageInput && messageInput.length <= configGenaral.maxMessagePush) {
			setTotalData({
				...totalData,
				set_up: {
					...totalData.set_up,
					message: messageInput
				}
			})
		} else {
			setTotalData({
				...totalData,
				set_up: {
					...totalData.set_up,
					message: ''
				}
			})
		}
	}, [messageInput])

	useEffect(() => {
		if (title && title.length < 28) {
			setTotalData({
				...totalData,
				set_up: {
					...totalData.set_up,
					title
				}
			})
		} else {
			setTotalData({
				...totalData,
				set_up: {
					...totalData.set_up,
					title: ''
				}
			})
		}
	}, [title])

	useEffect(() => {
		if (id && datacontentType && datacontentType.length > 0) {
			const findData = datacontentType.find(
				e => e.value === totalData.set_up.link_to
			)
			if (findData && setContentType) {
				setContentType(findData)
			} else if (radioFilter !== 'content') {
				setValue('url', totalData.set_up.link_to as string)
			}
		}
	}, [datacontentType])

	useEffect(() => {
		if (
			radioFilter !== 'none' &&
			datacontentType &&
			datacontentType.length > 0
		) {
			setTotalData({
				...totalData,
				set_up: {
					...totalData.set_up,
					title,
					link_to:
						radioFilter === 'content' && totalData.author_id
							? `${contentType?.id}`
							: url || `${contentType?.id}`
				}
			})
		}
	}, [url, radioFilter, datacontentType, contentType])

	useEffect(() => {
		if (title || messageInput) {
			if (
				title.length === 0 ||
				messageInput.length === 0 ||
				title === undefined ||
				(radioFilter === 'address' && !item?.address) ||
				(radioFilter === 'url' && !url && isValid) ||
				(radioFilter === 'content' && contentType && contentType.id === 0)
			) {
				setDontSend(false)
			} else {
				setDontSend(true)
			}
		} else {
			setDontSend(false)
		}
	}, [
		isValid,
		messageInput,
		title,
		listImage,
		radioFilter,
		contentType,
		url,
		item
	])

	useEffect(() => {
		const placeHolderUrl = linkToUrl.find(e => e.value === radioFilter)
		if (placeHolderUrl && setplaceHolderInput)
			setplaceHolderInput(placeHolderUrl.placeholder)
	}, [radioFilter])

	useEffect(() => {
		if (item) {
			setTotalData({
				...totalData,
				set_up: {
					...totalData.set_up,
					link_to: item.address,
					cta_latitude: item.latitude,
					cta_longitude: item.longitude
				}
			})
		}
	}, [item])

	const { data } = useQuery(
		[
			GET_CONTENTS,
			userId,
			organizationId,
			tables.myContentList.column,
			tables.myContentList.order
		],
		() =>
			getContentsListSelect(
				organizationId,
				tables.myContentList.column,
				tables.myContentList.order,
				{
					content_status: 'published'
				}
			)
	)

	useEffect(() => {
		if (data) {
			const infoData = data.items.map(e => {
				return {
					id: e.id_content,
					name: e.title,
					value: `${e.id_content}`
				}
			}) as DropdownFullItemProps[]
			if (setDataContentType) {
				setDataContentType(infoData)
			}
		}
	}, [data])

	const handleMessage = (key: string, value: string): void => {
		setMessageInput(value)
		setValue(key, value)
	}

	return (
		<div className="w-full">
			<Typography
				title="Direct Push Set Up"
				className="text-gray-9 border-b border-gray-11 pb-3"
				type={TypographyTypes.h2}
				fontLeading="22/27"
			/>
			<div className="mt-5">
				<div className="flex gap-4">
					<Input
						name="title"
						title="Title"
						type="text"
						register={register}
						rules={rules.title}
						placeholder="Title"
						limit
						className="!w-[calc(100%)] !p-[unset]"
						watchValue={title}
						setValueInput={setValue}
						cantLimit={configGenaral.maxTitlePush}
						borderFull={false}
						inputClassName="!p-0 rounded"
						titleClassName="!text-[14px] !leading-[24px]"
					/>
					{/* se comenta porque no va ahora */}
					{/* <Input
						name="template"
						title="Template"
						type="search"
						register={register}
						// rules={rules.title}
						placeholder="Search for content to set as template"
						// error={errors.title}
						// limit
						className="!w-[calc(100%_-_50%)] !p-[unset]"
						// watchValue={title}
						setValueInput={setValue}
						// cantLimit={30}
						borderFull={false}
						inputClassName="!p-0 rounded"
						titleClassName="!text-[10px] !leading-[12px]"
						rightIcon={Icons.search}
						
						// borderFull={false}
						// inputClassName="rounded-[10px]"
					/> */}
				</div>
				<div className="mt-4">
					<div>
						<Typography
							className="text-gray-14 font-semibold"
							type={TypographyTypes.h3}
							fontLeading="14/24"
							title="Message"
						/>
						<InputTextArea
							limit
							name="message"
							register={register}
							cantLimit={configGenaral.maxMessagePush}
							setValueInput={handleMessage}
							maxLength={configGenaral.maxMessagePush}
							textAreaClassName={clsx(
								!messageInput && 'font-[700] text-[10px] font-Inter',
								'2xl:h-[375px] xl:h-[180px] font-Inter'
							)}
							rules={rules.message}
							watchValue={messageInput}
							setTextValue={setMessageInput}
							color="Gray3"
							placeholder="Write your message here..."
						/>
						{messageInput.length > configGenaral.maxDescriptionLength && (
							<Message
								type="error"
								text={`The message must have a maximum of ${configGenaral.maxDescriptionLength} characters`}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}
