import { Popover } from '@mui/material'
import clsx from 'clsx'
import { Icon, TailwindPopOver, Typography } from 'components'
import {
	Icons,
	PeopleMyPeopleLoginListProps,
	TypographyTypes
} from 'interfaces'
import React, { useState } from 'react'
import { loginType } from './iconLoginType'

export const PeopleMyListLoginType: React.FC<PeopleMyPeopleLoginListProps> = ({
	data
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = (): void => {
		setAnchorEl(null)
	}

	const capitalizar = (str: string): string => {
		return str.charAt(0).toUpperCase() + str.slice(1)
	}

	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined
	return (
		<div className="flex gap-1">
			<button
				type="button"
				onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
					handleClick(event)
				}}
			>
				<span className="absolute top-0 right-0">
					<Icon src={Icons.ellipse} />
				</span>
			</button>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				PaperProps={{ style: { borderRadius: '16px' } }}
			>
				<TailwindPopOver handleClose={handleClose}>
					<div className="p-4">
						{data?.map((e, index) => (
							<div
								className={clsx(
									'flex items-center',
									index !== data.length - 1 && 'pb-2'
								)}
								key={`${e.signin_type}_${index}`}
							>
								<div className="w-5 h-5">
									<Icon
										src={loginType[e.signin_type]}
										fillPath={!(e.signin_type && e.signin_type === 'google')}
									/>
								</div>
								<Typography
									title={capitalizar(e.signin_type)}
									fontLeading="15/22"
									type={TypographyTypes.h3}
									className="px-2"
								/>
							</div>
						))}
					</div>
				</TailwindPopOver>
			</Popover>
		</div>
	)
}
