/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * TODO:
 * Schedule appointment for review.
 */

import { CircularProgress } from '@mui/material'
import { deleteDinamicList, deleteStaticList } from 'api'
import { Button, Icon, TailwindModal, Typography } from 'components'
import { useToast } from 'hooks'
import { GenericModalProps, Icons, TypographyTypes } from 'interfaces'
import React, { useCallback, useState } from 'react'

export const MyListsRemoveModal: React.FC<GenericModalProps<any>> = ({
	data: parentData,
	open,
	setOpen,
	refetch,
	type
}) => {
	const [isLoading, setIsLoading] = useState(false)
	const { showToast } = useToast()

	const deleteStaticListHandler = useCallback(
		async (id: number): Promise<void> => {
			setIsLoading(true)
			deleteStaticList(id).then(response => {
				if (response) {
					showToast('Success', 'Static list deleted successfully.', 'success')
					if (refetch) {
						refetch()
					}
					setOpen(false)
				}
			})
		},
		[]
	)

	const deleteDinamicListHandler = useCallback(
		async (id: number): Promise<void> => {
			setIsLoading(true)
			deleteDinamicList(id).then(response => {
				if (response) {
					showToast('Success', 'Static list deleted successfully.', 'success')
					if (refetch) {
						refetch()
					}
					setOpen(false)
				}
			})
		},
		[]
	)

	const handleDelete = (): void => {
		if (type === 'Dynamic') {
			deleteDinamicListHandler(parentData?.id as number).then()
		} else {
			deleteStaticListHandler(parentData?.id as number).then()
		}
	}

	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			showCloseButton
			className="w-[358px] h-[160px] flex items-center justify-center px-2 py-4 rounded-[16px]"
		>
			{!isLoading ? (
				<div className="flex flex-col gap-4 w-full rounded-[16px]">
					<div className="flex flex-col gap-3 w-full items-center">
						<div className="w-[30px] h-[30px]">
							<Icon
								src={Icons.warning}
								fillPath
								className="text-blue-primary"
							/>
						</div>
						<Typography
							title="Are you sure you want to delete a list?"
							fontLeading="15/18"
							type={TypographyTypes.h3}
							className="!font-normal text-black-1 text-center"
						/>
					</div>
					<div className="flex justify-center items-center gap-4 w-full">
						<Button
							label="Cancel"
							color="Transparent"
							onClick={() => setOpen(false)}
							className="!min-w-[unset] !py-3 !px-9"
						/>
						<Button
							label="Confirm"
							onClick={() => {
								handleDelete()
							}}
							className="!min-w-[unset] !py-3 !px-9"
						/>
					</div>
				</div>
			) : (
				<CircularProgress disableShrink />
			)}
		</TailwindModal>
	)
}
