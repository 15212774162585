import clsx from 'clsx'
import { Icon, Typography } from 'components'
import { PaginationContext } from 'context'
import {
	HeaderTableItemProps,
	Icons,
	TypeOrder,
	TypographyTypes
} from 'interfaces'
import React, { useContext } from 'react'

export const HeaderTableItem: React.FC<HeaderTableItemProps> = React.memo(
	({ header, style, justify = 'center', table, column }) => {
		const { tables, setOrder } = useContext(PaginationContext)

		const clickHandler = (): void => {
			if (table && column) {
				let returnValue: TypeOrder = tables[table].order

				if (tables[table].column !== '' && column !== tables[table].column) {
					setOrder(table, column, 'desc')
					return
				}
				if (tables[table].order === null) {
					returnValue = 'desc'
				} else if (returnValue === 'desc') {
					returnValue = 'asc'
				} else if (returnValue === 'asc') {
					returnValue = null
				}

				if (returnValue === null) {
					setOrder(table, '', returnValue)
				} else {
					setOrder(table, column, returnValue)
				}
			}
		}

		return (
			<th
				aria-hidden
				scope="col"
				className={clsx(
					'flex items-center gap-2 select-none',
					justify === 'center' && 'justify-center',
					justify === 'left' && 'justify-left',
					justify === 'right' && 'justify-right',
					{ 'cursor-pointer': table && column }
				)}
				style={style}
				onClick={() => clickHandler()}
			>
				<Typography
					title={header}
					fontLeading="15/18"
					type={TypographyTypes.span}
					className="text-gray-9"
				/>
				{table && column && (
					<>
						{column !== tables[table]?.column && (
							<div className="w-5 h-5">
								<Icon src={Icons.unfoldMore} fillPath className="text-gray-9" />
							</div>
						)}
						{column === tables[table]?.column &&
							tables[table]?.order === 'desc' && (
								<div className="w-5 h-5">
									<Icon
										src={Icons.expandMore}
										fillPath
										className="text-gray-9"
									/>
								</div>
							)}
						{column === tables[table]?.column &&
							tables[table]?.order === 'asc' && (
								<div className="w-5 h-5">
									<Icon
										src={Icons.expandLess}
										fillPath
										className="text-gray-9"
									/>
								</div>
							)}
					</>
				)}
			</th>
		)
	}
)
