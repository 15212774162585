import { sendEmail } from 'api'
import { Button, Input, TailwindModal } from 'components'
import { useToast } from 'hooks'
import { GenericModalProps, SendNotificationInputs } from 'interfaces'
import React, { useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Cookies from 'universal-cookie'

export const SendNotificationModal: React.FC<GenericModalProps<number[]>> = ({
	open,
	setOpen
}) => {
	const cookies = new Cookies()
	const organizationId = cookies.get('organization_id')
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<SendNotificationInputs>({ mode: 'all' })
	const [loading, setLoading] = useState(false)
	const { showToast } = useToast()
	const rules = useMemo(() => {
		return {
			email: {
				required: { value: true, message: 'This field is required' }
			}
		}
	}, [])
	const onSubmit: SubmitHandler<SendNotificationInputs> = async data => {
		setLoading(true)
		await sendEmail(organizationId, { send_to: data.email }).then(response => {
			if (response) {
				showToast('Success', response.message, 'success')
				setOpen(false)
			} else {
				showToast('Error', 'Error sending email', 'error')
				setOpen(false)
			}
		})
		setLoading(false)
	}

	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			title="Send AllAboard via E-mail"
			className="w-[514px] min-h-[204px] p-4 rounded-[16px]"
		>
			<form
				className="flex flex-col gap-4 w-full mt-4"
				onSubmit={handleSubmit(onSubmit)}
			>
				<Input
					name="email"
					title="User's email"
					placeholder="Enter mail here"
					type="email"
					register={register}
					rules={rules.email}
					error={errors.email}
					className="!w-full"
					inputClassName="!p-[unset] !pb-4"
					inputFieldClassName="p-0"
					inputFocus
				/>
				<div className="flex justify-between items-center w-full">
					<Button
						label="Cancel"
						color="Transparent"
						onClick={() => setOpen(false)}
						className="!min-w-[unset] !w-[48%] py-3"
						disabled={loading}
					/>
					<Button
						label={loading ? 'Loading...' : 'Send'}
						type="submit"
						className="!min-w-[unset] !w-[48%] py-3"
						disabled={loading}
					/>
				</div>
			</form>
		</TailwindModal>
	)
}
