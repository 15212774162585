/* eslint-disable react/require-default-props */
/* eslint-disable prefer-regex-literals */
/* eslint-disable no-control-regex */
import * as React from 'react'

import { InputProps } from 'interfaces'
import { Input } from './input'
// import { Icon } from 'components/icon';

export const InputPassword: React.FC<
	InputProps &
		React.InputHTMLAttributes<HTMLInputElement> & {
			validate?: boolean
		}
> = ({ rules, validate = true, ...props }) => {
	const [isVisible, setIsVisible] = React.useState(false)

	const handleClick = (): void => {
		setIsVisible(!isVisible)
	}

	// const img = isVisible ? (
	// 	<Icon src={Icons.showPassword} className="cursor-pointer w-6 h-6" />
	// ) : (
	// 	<Icon src={Icons.hidePassword} className="cursor-pointer w-6 h-6" />
	// )

	const finalRules = React.useMemo(() => {
		if (validate) {
			return {
				...rules,
				// validate: (value: string) =>
				// 	!value.match(
				// 		new RegExp('querty|password|admin|test|administrator|123456', 'i')
				// 	) ||
				// 	`querty | password | admin | test | administrator | 123456 is not valid`,
				pattern: {
					value:
						/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.	]{8,}$/,
					message:
						'Password not valid, Minimum 8 characters, a number, a capital letter, a lowercase letter and a symbol.'
				}
			}
		}
		return {
			...rules
		}
	}, [rules, validate])

	return (
		<Input
			// type={isVisible ? 'text' : 'password'}
			rules={finalRules}
			// rightImg={img}
			rightClick={() => handleClick()}
			{...props}
		/>
	)
}
