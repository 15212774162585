import { API } from 'api/config'
import {
	FMPartnerListFollowersResponse,
	FMPartnerListId,
	FMPartnerListPartnersResponse,
	NetworkMessagesResponse,
	NetworkMyFeedByIdResponse,
	NetworkMyFeedsTableResponse,
	NetworkPartnerTableResponse,
	NetworkSubscriptionTableResponse,
	NewOutboundPartnerResponse,
	NewOutboundSubscriptionResponse
} from 'interfaces'

export const getSubscriptionNetworkTable = async (
	organizationId: number,
	filters?: {
		page?: number
		search?: string
		status?: string[]
		pageSize?: number
	}
): Promise<NetworkSubscriptionTableResponse | null> => {
	let res = null as NetworkSubscriptionTableResponse | null
	await API()
		.get(`/network/suscription_list/${organizationId}`, {
			params: {
				size: filters?.pageSize,
				search: filters?.search,
				page: filters?.page,
				status: filters?.status?.join(',')
			}
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getPartnerFeeds = async (
	organizationId: number,
	filters?: {
		page?: number
		search?: string
		status?: string[]
		pageSize?: number
	}
): Promise<NetworkSubscriptionTableResponse | null> => {
	let res = null as NetworkSubscriptionTableResponse | null
	await API()
		.get(`/organization_partner/following_feeds/${organizationId}`, {
			params: {
				size: filters?.pageSize,
				search: filters?.search,
				page: filters?.page,
				status: filters?.status?.join(',')
			}
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getPartnerNetworkTable = async (
	organizationId: number,
	filters?: {
		page?: number
		search?: string
		status?: string[]
		pageSize?: number
	}
): Promise<NetworkPartnerTableResponse | null> => {
	let res = null as NetworkPartnerTableResponse | null
	await API()
		.get(`/organization_partner/${organizationId}`, {
			params: {
				size: filters?.pageSize,
				search: filters?.search,
				page: filters?.page,
				status: filters?.status?.join(',')
			}
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getNewOutboundPartnerTable = async (
	organizationId: number,
	page: number,
	searchValue: string
): Promise<NewOutboundPartnerResponse | null> => {
	let res = null as NewOutboundPartnerResponse | null
	await API()
		.get(
			`/organizations_partners/${organizationId}?size=10&page=${page.toString()}${
				searchValue && searchValue.length > 0 ? `&search=${searchValue}` : ''
			}`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getNewOutboundSubscriptionTable = async (
	organizationId: number,
	page: number,
	searchValue: string
): Promise<NewOutboundSubscriptionResponse | null> => {
	let res = null as NewOutboundSubscriptionResponse | null
	await API()
		.get(
			`/network/suscription/${organizationId}?size=10&page=${page.toString()}${
				searchValue && searchValue.length > 0 ? `&search=${searchValue}` : ''
			}`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getNewSubscribePartnerTable = async (
	organizationId: number,
	filters?: {
		page?: number
		search?: string
		pageSize?: number
	}
): Promise<NewOutboundSubscriptionResponse | null> => {
	let res = null as NewOutboundSubscriptionResponse | null
	await API()
		.get(`/organization_partner/feeds/${organizationId}`, {
			params: {
				size: filters?.pageSize,
				search: filters?.search,
				page: filters?.page
			}
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getMessages = async (
	organizationId: number,
	page: number,
	searchValue: string,
	filterOption: string
): Promise<NetworkMessagesResponse | null> => {
	let res = null as NetworkMessagesResponse | null
	const isFilterAll = filterOption === 'all' ? '' : `&filter=${filterOption}`
	await API()
		.get(
			`/partner_message_center/${organizationId}?size=10&page=${page.toString()}${
				searchValue && searchValue.length > 0 ? `&search=${searchValue}` : ''
			}
			${filterOption && filterOption.length > 0 ? isFilterAll : ''}`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getFMPartnerListPartners = async (
	organizationId: number,
	filters?: { page?: number; search?: string }
): Promise<FMPartnerListPartnersResponse | null> => {
	let res = null as FMPartnerListPartnersResponse | null
	await API()
		.get(`/partner_list/partner/${organizationId}`, {
			params: { page: filters?.page, search: filters?.search }
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getFMPartnerListFollowers = async (
	organizationId: number,
	page: number,
	searchValue?: string
): Promise<FMPartnerListFollowersResponse | null> => {
	let res = null as FMPartnerListFollowersResponse | null
	await API()
		.get(
			`/partner_list/saved/${organizationId}?size=10&page=${page.toString()}${
				searchValue && searchValue.length > 0 ? `&search=${searchValue}` : ''
			}`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getFMPartnerList = async (
	organizationId: number,
	idList: number
): Promise<FMPartnerListId | null> => {
	let res = null as FMPartnerListId | null
	await API()
		.get(`/organization/${organizationId}/partner_list/${idList}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getMyFeeds = async (
	organizationId: number,
	filters?: {
		page?: number
		search?: string
		pageSize?: number
	}
): Promise<NetworkMyFeedsTableResponse | null> => {
	let res = null as NetworkMyFeedsTableResponse | null
	await API()
		.get(`/organization/${organizationId}/feeds`, {
			params: {
				size: filters?.pageSize,
				search: filters?.search,
				page: filters?.page
			}
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getMyFeedById = async (
	organizationId: number,
	feedId: number
): Promise<NetworkMyFeedByIdResponse | null> => {
	let res = null as NetworkMyFeedByIdResponse | null
	if (feedId) {
		await API()
			.get(`/organization/${organizationId}/feeds/${feedId}`)
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.error(error)
				}
			)
	}
	return res
}
