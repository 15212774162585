/* eslint-disable no-useless-escape */
import {
	BarMultiSelect,
	Button,
	DateTimer,
	DropdownFull,
	Helper,
	Icon,
	Input,
	InputRadio,
	InputTextArea,
	Message,
	RadioGroups,
	Typography
} from 'components'
import { useToast } from 'hooks'
import {
	Icons,
	PushNotificationsFormInputs,
	PushNotificationsProps,
	TypographyTypes
} from 'interfaces'
import {
	daysTypeData,
	endShedules,
	itemPushNotification,
	listDays,
	periodTypeData,
	removeHTMLTags
} from 'lib'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { configGenaral, formatLocalTime } from 'utils'
import { BoxSwitch } from '../create'

export const PushNotification: React.FC<PushNotificationsProps> = ({
	totalData,
	onClickClose,
	setTotalData,
	setOpenSlide,
	listImagePush,
	deliveryActive,
	setListImagePush,
	setDeliveryActive,
	itemDatePushNotofication,
	setItemDatePushNotofication
}): JSX.Element => {
	const [edit, setEdit] = useState(0)
	const [title, setTitle] = useState(true)
	const [message, setMessage] = useState(true)
	const [publisDate, setPublisDate] = useState<string | Moment>('')
	const [dataCurrency, setDataCurrency] = useState<string | Moment>('')
	const [dateTypeNotification, setDateTypeNotification] = useState(
		daysTypeData[0]
	)
	const [periodTypeNotification, setPeriodTypeNotification] = useState(
		periodTypeData[0]
	)
	const [listDaysSeleted, setListDaysSeleted] = useState<string[]>([])
	const [endDateType, setEndDateType] = useState(daysTypeData[0])
	const { showToast } = useToast()
	const [endDate, setEndDate] = useState<string | Moment>('')
	const [endRadio, setEndRadio] = useState({
		id: '1',
		title: 'Never',
		value: 'never'
	})
	const [isSubmit, setIsSubmit] = useState(false)
	const [description, setDescription] = useState('')
	const MESSAGE_MAX_LENGTH = 140

	const {
		register,
		setValue,
		handleSubmit,
		watch,
		formState: { errors }
	} = useForm<PushNotificationsFormInputs>({
		mode: 'all'
	})
	const titleFiles = watch('title')

	const registerInputFile = register?.(`image`)

	const rules = {
		title: {
			required: { value: title, message: 'This field is required' }
		},
		message: {
			required: { value: message, message: 'This field is required' }
		}
	}

	const handleUploadImage = (file: File): void => {
		const fileSize = file.size
		const fileMb = fileSize / 1024 ** 2
		if (fileMb <= configGenaral.maxfileSizeMB) {
			const render = new FileReader()
			render.onload = e => {
				const uploadedObj = { file, url: e.target?.result as string }
				setListImagePush({
					asset_type: 'image',
					asset_url: uploadedObj.url,
					asset_name: uploadedObj.file.name
						.normalize('NFD')
						.replace(/[\u0300-\u036f]/g, '')
						.replace(/ /g, '-')
				})
			}
			render.readAsDataURL(file)
		} else {
			showToast(
				'Error',
				`Please select a file less than ${configGenaral.maxfileSizeMB}MB.`,
				'error'
			)
		}
	}

	const handleDeleteFileOne = (): void => {
		setListImagePush(undefined)
		setValue('image', '')
	}

	const onSubmit: SubmitHandler<PushNotificationsFormInputs> = values => {
		setDeliveryActive({
			...deliveryActive,
			push_notification: true
		})
		const getEndDate =
			endDate !== '' ? moment.utc(endDate).format('YYYY-MM-DDTHH:mm') : null
		setTotalData({
			...totalData,
			delivery_enhancements: {
				...totalData.delivery_enhancements,
				push_notification: {
					...totalData.delivery_enhancements.push_notification,
					title: values?.title ?? '',
					message: description,
					is_message: message,
					is_title: title,
					timing: {
						release_date:
							itemDatePushNotofication === '1'
								? moment.utc(publisDate).format('YYYY-MM-DDTHH:mm')
								: null
					},
					custom_recurrence: {
						repeating_schedule: itemDatePushNotofication === '2',
						repeat_start_date:
							itemDatePushNotofication === '2'
								? moment.utc(dataCurrency).format('YYYY-MM-DDTHH:mm')
								: null,
						repeat_every_type:
							itemDatePushNotofication === '2'
								? (periodTypeNotification.value as string)
								: null,
						repeat_every_value:
							itemDatePushNotofication === '2'
								? (dateTypeNotification.id as number)
								: 1,
						repeat_on:
							itemDatePushNotofication === '2' ? `[${listDaysSeleted}]` : '',
						ends: {
							repeat_end_type:
								itemDatePushNotofication === '2' ? endRadio.value : '',
							repeat_end_date:
								itemDatePushNotofication === '2' ? getEndDate : null,
							after_ocurrences:
								itemDatePushNotofication === '2'
									? (endDateType.id as number)
									: 1
						}
					}
				}
			}
		})
		setOpenSlide(false)
	}

	useEffect(() => {
		if (!title && !message) {
			if (itemDatePushNotofication === '1' && publisDate !== '') {
				setIsSubmit(true)
			} else if (itemDatePushNotofication === '3') {
				setIsSubmit(true)
			} else if (itemDatePushNotofication === '2') {
				if (dataCurrency !== '' && endRadio.value !== 'specific_day') {
					setIsSubmit(true)
				} else if (
					dataCurrency !== '' &&
					endRadio.value === 'specific_day' &&
					endDate !== ''
				) {
					setIsSubmit(true)
				} else {
					setIsSubmit(false)
				}
			} else {
				setIsSubmit(false)
			}
		} else if (title && !message) {
			if (itemDatePushNotofication === '1' && publisDate !== '') {
				setIsSubmit(true)
			} else if (itemDatePushNotofication === '3') {
				setIsSubmit(true)
			} else if (itemDatePushNotofication === '2') {
				if (dataCurrency !== '' && endRadio.value !== 'specific_day') {
					setIsSubmit(true)
				} else if (
					dataCurrency !== '' &&
					endRadio.value === 'specific_day' &&
					endDate !== ''
				) {
					setIsSubmit(true)
				} else {
					setIsSubmit(false)
				}
			} else {
				setIsSubmit(false)
			}
		} else if (
			message &&
			!title &&
			description !== '' &&
			description !== undefined
		) {
			if (itemDatePushNotofication === '1' && publisDate !== '') {
				setIsSubmit(true)
			} else if (itemDatePushNotofication === '3') {
				setIsSubmit(true)
			} else if (itemDatePushNotofication === '2') {
				if (dataCurrency !== '' && endRadio.value !== 'specific_day') {
					setIsSubmit(true)
				} else if (
					dataCurrency !== '' &&
					endRadio.value === 'specific_day' &&
					endDate !== ''
				) {
					setIsSubmit(true)
				} else {
					setIsSubmit(false)
				}
			} else {
				setIsSubmit(false)
			}
		} else if (
			message &&
			title &&
			description !== '' &&
			description !== undefined
		) {
			if (itemDatePushNotofication === '3') {
				setIsSubmit(true)
			} else if (itemDatePushNotofication === '1' && publisDate !== '') {
				setIsSubmit(true)
			} else if (itemDatePushNotofication === '2') {
				if (dataCurrency !== '' && endRadio.value !== 'specific_day') {
					setIsSubmit(true)
				} else if (
					dataCurrency !== '' &&
					endRadio.value === 'specific_day' &&
					endDate !== ''
				) {
					setIsSubmit(true)
				} else {
					setIsSubmit(false)
				}
			} else {
				setIsSubmit(false)
			}
		} else {
			setIsSubmit(false)
		}
	}, [
		title,
		message,
		description,
		dataCurrency,
		endRadio,
		endDate,
		itemDatePushNotofication,
		publisDate
	])

	useEffect(() => {
		setTitle(totalData.delivery_enhancements.push_notification.is_title)
		setMessage(totalData.delivery_enhancements.push_notification.is_message)
		if (totalData.delivery_enhancements.push_notification.is_title) {
			setValue('title', totalData.delivery_enhancements.push_notification.title)
		}
		if (totalData.delivery_enhancements.push_notification.is_message) {
			setDescription(
				removeHTMLTags(
					totalData.delivery_enhancements.push_notification.message
				)
			)
		}
		if (totalData.delivery_enhancements.push_notification.image) {
			setListImagePush({
				asset_type: 'image',
				asset_url: totalData.delivery_enhancements.push_notification.image
			})
		}
		if (
			totalData.delivery_enhancements.push_notification.custom_recurrence
				?.repeating_schedule === false ||
			!totalData.delivery_enhancements.push_notification.custom_recurrence
				?.repeating_schedule
		) {
			if (
				totalData.delivery_enhancements.push_notification.timing
					.release_date === ''
			) {
				setItemDatePushNotofication('3')
			}
			setPublisDate(
				totalData.delivery_enhancements.push_notification.timing
					?.release_date !== ''
					? formatLocalTime(
							totalData.delivery_enhancements.push_notification.timing
								?.release_date as string,
							'MM/DD/YYYY hh:mm A'
					  )
					: ''
			)
		} else {
			setItemDatePushNotofication('2')
			setDataCurrency(
				totalData.delivery_enhancements.push_notification.custom_recurrence
					?.repeat_start_date !== ''
					? formatLocalTime(
							totalData.delivery_enhancements.push_notification
								.custom_recurrence?.repeat_start_date as string,
							'MM/DD/YYYY hh:mm A'
					  )
					: ''
			)
			const auxDay = daysTypeData.find(
				item =>
					item.id ===
					totalData.delivery_enhancements.push_notification.custom_recurrence
						?.repeat_every_value
			)
			if (auxDay) {
				setDateTypeNotification(auxDay)
			}
			const auxPeriod = periodTypeData.find(
				item =>
					item.value ===
					totalData.delivery_enhancements.push_notification.custom_recurrence
						?.repeat_every_type
			)
			const repeatOn =
				totalData.delivery_enhancements.push_notification.custom_recurrence
					?.repeat_on
			if (repeatOn) {
				setListDaysSeleted(
					(
						totalData.delivery_enhancements.push_notification.custom_recurrence
							?.repeat_on as string
					)
						.replace(/[\[\]']/g, '')
						.split(',')
				)
			}
			if (auxPeriod) {
				setPeriodTypeNotification(auxPeriod)
			}
			const customRecurrence =
				totalData.delivery_enhancements.push_notification.custom_recurrence

			if (customRecurrence?.ends?.repeat_end_type) {
				const auxEndRadio = endShedules.find(
					item => item.value === customRecurrence.ends.repeat_end_type
				)

				if (auxEndRadio) {
					setEndRadio(auxEndRadio)
				}
			}

			setEndDate(
				totalData?.delivery_enhancements?.push_notification.custom_recurrence
					?.ends?.repeat_end_date !== ''
					? formatLocalTime(
							totalData.delivery_enhancements.push_notification
								.custom_recurrence.ends.repeat_end_date as string,
							'MM/DD/YYYY hh:mm A'
					  )
					: ''
			)
		}
		const aux = edit + 1
		setEdit(aux)
	}, [totalData])
	return (
		<form
			key={edit}
			onSubmit={handleSubmit(onSubmit)}
			className="mt-5 h-full flex flex-col justify-between"
		>
			<div>
				<div>
					<BoxSwitch title="Title" enabled={title} setEnabled={setTitle} bold />
					<Input
						limit
						name="title"
						className="mb-4"
						disabled={!title}
						register={register}
						rules={rules.title}
						error={errors.title}
						watchValue={titleFiles}
						setValueInput={setValue}
						placeholder="Enter Title"
						cantLimit={configGenaral.maxTitlePush}
					/>
				</div>

				<BoxSwitch
					bold
					title="Message"
					enabled={message}
					setEnabled={setMessage}
				/>

				<div>
					<InputTextArea
						name="textArea"
						textValue={description}
						maxLength={MESSAGE_MAX_LENGTH + 1}
						textAreaClassName="!h-[140px] !resize-none"
						setTextValue={setDescription}
						color="Gray3"
						disabled={!message}
					/>
					{description.length > MESSAGE_MAX_LENGTH && (
						<Message
							type="error"
							text={`The message must have a maximum of ${MESSAGE_MAX_LENGTH} characters`}
						/>
					)}
				</div>

				<input
					id="pdf"
					type="file"
					className="hidden"
					{...registerInputFile}
					onChange={event => {
						if (event.target.files) {
							handleUploadImage(event.target.files[0])
						}
					}}
					accept="image/*"
				/>
				<div
					className={
						listImagePush === undefined ? 'flex flex-row justify-between' : ''
					}
				>
					<Typography
						fontLeading="12/12"
						title="Custom Image"
						type={TypographyTypes.span}
					/>
					{listImagePush === undefined ? (
						<div className="flex items-center gap-2 mt-8">
							<Button
								type="button"
								label="Upload"
								color="Transparent"
								iconLeft={Icons.upload}
								className="!min-w-[130px] "
								onClick={() => {
									const input = document.getElementById(`pdf`)
									if (input) input.click()
								}}
							/>
							<Helper
								text="Supported formats (JPG, JPEG, PNG, PDF). Max image size 10 MB.
								Min. Resolution: 1080px by 1080px"
								className="relative "
							/>
						</div>
					) : (
						<div className="relative">
							<button
								type="button"
								onClick={handleDeleteFileOne}
								className="absolute -top-1 right-1 bg-gray-18 p-1 rounded-full border-2 border-gray-3"
							>
								<Icon src={Icons.close} className="w-3 h-3 " />
							</button>
							<img
								src={listImagePush.asset_url}
								alt="preview"
								className="rounded-lg w-full max-h-[300px] min-h-[300px]"
							/>
						</div>
					)}
				</div>
				<div className="mt-6">
					<div>
						{itemPushNotification.map(item => (
							<InputRadio
								item={item}
								key={item.id}
								defaultChecked={itemDatePushNotofication}
								setItemRecurrence={setItemDatePushNotofication}
							/>
						))}
					</div>
					{itemDatePushNotofication === '1' && (
						<div key={itemDatePushNotofication}>
							<DateTimer
								className="mt-2"
								validate={moment().format('MM/DD/YYYY')}
								icon={Icons.calendar}
								setDate={setPublisDate}
								initialValue={publisDate}
								title="Release date and time"
								placeholderInput="Pick date"
								helper
								textHelper="Time and date reflects your local time."
							/>
						</div>
					)}
					{itemDatePushNotofication === '2' && (
						<div key={itemDatePushNotofication}>
							<div className="w-full flex justify-between items-center mb-2">
								<Typography
									type={TypographyTypes.span}
									fontLeading="15/18"
									title="Specific Date"
								/>
								<Helper text="Time and date reflects your local time." />
							</div>
							<DateTimer
								line={false}
								className="mt-2"
								validate={moment().format('MM/DD/YYYY')}
								setDate={setDataCurrency}
								icon={Icons.calendarCheck}
								initialValue={dataCurrency}
							/>
							<div className="flex items-center gap-2 mt-2">
								<Typography
									title="Repeat every"
									type={TypographyTypes.span}
									fontLeading="15/18"
								/>
								<DropdownFull
									item={dateTypeNotification}
									list={daysTypeData}
									setItem={setDateTypeNotification}
									className="min-w-[140px]"
								/>
								<DropdownFull
									item={periodTypeNotification}
									list={periodTypeData}
									setItem={setPeriodTypeNotification}
									className="min-w-[140px]"
								/>
							</div>

							{periodTypeNotification.value === 'week' && (
								<div className="mt-2">
									<Typography
										title="Repeat On"
										type={TypographyTypes.span}
										fontLeading="15/18"
										className="mb-2"
									/>
									<BarMultiSelect
										list={listDays}
										listSeleted={listDaysSeleted}
										setListSeleted={setListDaysSeleted}
									/>
								</div>
							)}

							<RadioGroups
								name="ends"
								title="Ends"
								endDate={endDate}
								checked={endRadio}
								validate={moment().format('MM/DD/YYYY')}
								options={endShedules}
								setEndDate={setEndDate}
								setChecked={setEndRadio}
								endDdateType={endDateType}
								setEndDateType={setEndDateType}
							/>
						</div>
					)}
				</div>
			</div>

			<div className="w-full flex items-center justify-between mt-2">
				<Button
					type="button"
					label="Cancel"
					color="Transparent"
					className="!min-w-[130px]"
					onClick={onClickClose}
				/>
				<Button
					label="Save"
					type="submit"
					color="Primary"
					className="!min-w-[130px]"
					disabled={!isSubmit}
				/>
			</div>
		</form>
	)
}
