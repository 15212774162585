import { Typography } from 'components/typography'
import { TitleSendDirectPushProps, TypographyTypes } from 'interfaces'
import React from 'react'

export const TitleSendDirectPush: React.FC<TitleSendDirectPushProps> = ({
	title
}) => {
	return (
		<div className="bg-white shadow-lg h-14 w-full sm:w-full md:w-[95%] lg:w-[95%] 2xl:w-[95%] flex p-4 justify-center items-center rounded-lg ">
			<Typography
				className="text-gray-9 text-[1.2em] sm:text-2xl md:text-3xl lg:text-3xl"
				type={TypographyTypes.h2}
				title={title}
				fontLeading="31/37"
			/>
		</div>
	)
}
