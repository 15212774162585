import { CircularProgress } from '@mui/material'
import { Input, InputCheck, SubTags } from 'components'
import { Icons, PeopleMyTagsParentItem, TagsProps } from 'interfaces'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { configGenaral } from 'utils'

export const Tags: React.FC<TagsProps> = ({
	data,
	isLoading,
	totalList,
	resetInput,
	setTotalList,
	fetchNextPage,
	isFetchingNextPage,
	handleChangeDebounce,
	defaultOpenParent = false
}): JSX.Element => {
	const { ref, inView } = useInView()
	const [search, setSearch] = useState('')
	const [listTags, setListTags] = useState<number[]>(totalList.tags)
	const [listTagsParents, setListTagsParents] = useState<
		PeopleMyTagsParentItem[]
	>([])

	useEffect(() => {
		if (inView) {
			if (
				data &&
				data.pages[0] &&
				data.pages[0].pages > data.pageParams.length
			) {
				fetchNextPage()
			}
		}
	}, [inView])

	useEffect(() => {
		if (data?.pages) {
			let aux = [] as PeopleMyTagsParentItem[]
			data.pages.forEach(page => {
				if (page) {
					aux = [...aux, ...page.items]
				}
				setListTagsParents(aux)
			})
		}
	}, [data])

	useEffect(() => {
		setTotalList({
			...totalList,
			tags: listTags
		})
	}, [listTags])

	return (
		<div>
			<Input
				name="search"
				placeholder="Search Tags"
				className="w-full !p-[unset]"
				onChangeCustom={e => {
					setSearch(e.target.value)
					handleChangeDebounce(e)
				}}
				rightIcon={Icons.cancel}
				rightClick={() => {
					resetInput()
					setSearch('')
				}}
				value={search}
				maxLength={configGenaral.maxSearchValue}
				inputClassName="!p-[unset] !py-1 !px-2"
				inputFocus
				borderFull={false}
				search
			/>
			<div className="mt-2">
				{isLoading ? (
					<div className="flex items-center justify-center w-full h-[400px] py-2 px-1 ">
						<CircularProgress disableShrink />
					</div>
				) : (
					<div className="max-h-[370px] overflow-y-auto">
						{listTagsParents
							?.slice()
							.sort((a, b) =>
								a.name && b.name ? a.name.localeCompare(b.name) : 0
							)
							.map(item =>
								item.count_son > 0 ? (
									<SubTags
										tag={item}
										list={listTags}
										setList={setListTags}
										key={item.organization_tag_parent_id}
										defaultOpenParent={defaultOpenParent}
									/>
								) : (
									<InputCheck
										list={listTags}
										title={item.name}
										setList={setListTags}
										reach={item.num_users}
										className="mb-4 w-full"
										id={item.organization_tag_parent_id}
										key={item.organization_tag_parent_id}
										name={`${item.name}-${item.organization_tag_parent_id}`}
										isVisible={item.is_visible}
										isStrong
										hasCheckVisible
									/>
								)
							)}
						<div className="flex flex-col items-center" ref={ref}>
							{isFetchingNextPage && <CircularProgress disableShrink />}
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
