/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * TODO:
 * Schedule appointment for review.
 */

import {
	GET_NEW_OUTBOUND_PARTNER_TABLE,
	getNewOutboundPartnerTable,
	postNewOutboundPartnerTable
} from 'api'
import {
	Button,
	Input,
	InputTextArea,
	Message,
	NewPartnerRequestTable,
	TailwindModal,
	Typography
} from 'components/index'
import { useSearchDebounce, useToast } from 'hooks'
import {
	GenericModalProps,
	Icons,
	NewOutboundPartnerResponse,
	SendMessageToNewPartnersRequest,
	TypographyTypes
} from 'interfaces'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'
import { configGenaral } from 'utils'

const MESSAGE_MAX_LENGTH = 140

export const NewPartnerRequestModal: React.FC<
	Omit<GenericModalProps<any>, 'setOpen'> & {
		onCancel: (message: string) => void
	}
> = ({ open, onSubmit, onCancel }) => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const [textValue, setTextValue] = useState('')
	const [buttonDisabled, setButtonDisabled] = useState(false)
	const { register, resetField, setValue } = useForm<{
		search: string
	}>({
		mode: 'onChange'
	})

	const [search, setSearch] = useState('')
	const { inputSearch, handleChangeDebounce, resetInput } =
		useSearchDebounce('')
	const [page, setPage] = useState(1)
	const [dataSelected, setDataSelected] = useState<number[]>([])
	const { data, isLoading } = useQuery(
		[
			GET_NEW_OUTBOUND_PARTNER_TABLE,
			page,
			inputSearch.trim(),
			userId,
			organizationId
		],
		() => getNewOutboundPartnerTable(organizationId, page, inputSearch.trim())
	)
	const { showToast } = useToast()

	const submitHandler = useCallback(
		async (body: SendMessageToNewPartnersRequest): Promise<void> => {
			try {
				postNewOutboundPartnerTable(organizationId, body).then(response => {
					if (response) {
						showToast('Success', 'message sent successfully', 'success')
						if (onSubmit) {
							onSubmit()
						}
					}
				})
			} catch (error) {
				console.warn(error)
			}
		},
		[]
	)

	const onSubmitButton = (): void => {
		setButtonDisabled(true)
		submitHandler({
			partner_ids: dataSelected,
			message: textValue,
			created_by: userId
		})
	}
	return (
		<TailwindModal
			open={open}
			setOpen={() => onCancel(textValue)}
			title="New Outbound Partner Connection Request"
			className="md:w-[69.2vw] w-full p-4 rounded-[16px] md:h-[92vh] lg:h-auto overflow-y-auto"
		>
			<>
				<div className="flex flex-col gap-4 w-full mt-4 ">
					<Typography
						className="text-gray-14 font-semibold"
						type={TypographyTypes.h3}
						fontLeading="15/18"
						title="Force Multiplier Connection Request"
					/>
					<div>
						<Input
							name="search"
							placeholder="Search FM Feeds or Partners"
							className="!w-full !p-[unset] mb-2"
							onChange={e => {
								setSearch(e.target.value)
								handleChangeDebounce(e)
							}}
							register={register}
							rightIcon={Icons.cancel}
							rightClick={() => {
								resetInput()
								resetField('search')
							}}
							inputClassName="!p-[unset] !py-1 !px-2"
							inputFocus
							borderFull={false}
							value={search}
							search
							maxLength={configGenaral.maxSearchValue}
							setValueInput={setValue}
						/>
						{search.length >= configGenaral.maxSearchValue && (
							<Message
								type="error"
								text={`Maximum ${configGenaral.maxSearchValue} characters`}
							/>
						)}
					</div>
					<NewPartnerRequestTable
						data={data as NewOutboundPartnerResponse | undefined}
						isLoading={isLoading}
						page={page}
						setPage={setPage}
						dataSelected={dataSelected}
						setDataSelected={setDataSelected}
					/>
					<Typography
						className="text-gray-14 font-semibold"
						type={TypographyTypes.h3}
						fontLeading="15/18"
						title="Message to Partner"
					/>
					<div>
						<InputTextArea
							name="textArea"
							textValue={textValue}
							maxLength={MESSAGE_MAX_LENGTH + 1}
							textAreaClassName="!h-[70px] !resize-none"
							setTextValue={setTextValue}
							color="Gray3"
						/>
						{textValue.length > MESSAGE_MAX_LENGTH && (
							<Message
								type="error"
								text={`The message must have a maximum of ${MESSAGE_MAX_LENGTH} characters`}
							/>
						)}
					</div>
				</div>
				<div className="flex justify-center items-center gap-3 w-full px-4 py-4 md:flex-row flex-col-reverse">
					<Button
						label="Cancel"
						color="Transparent"
						onClick={() => onCancel(textValue)}
					/>
					<Button
						label="Submit"
						disabled={
							textValue === '' ||
							dataSelected.length === 0 ||
							textValue.length > MESSAGE_MAX_LENGTH ||
							buttonDisabled
						}
						onClick={onSubmitButton}
					/>
				</div>
			</>
		</TailwindModal>
	)
}
