import { Typography } from 'components'
import { ContentHighLightItemProps, TypographyTypes } from 'interfaces'
import React from 'react'
import { formatLocalTime } from 'utils'

export const ContentHighlightItem: React.FC<ContentHighLightItemProps> = ({
	name,
	type,
	createdAt
}): JSX.Element => {
	return (
		<div className="w-full pb-1 pt-2 border-b-2 border-b-gray-10/0.2 pr-5">
			<div className="w-full flex items-center justify-between mb-1">
				<Typography
					fontLeading="12/15"
					type={TypographyTypes.p}
					title={name}
					className="text-gray-9 basis-[53%] !font-normal"
				/>
				<div className="flex justify-between items-center basis-[43%]">
					<Typography
						fontLeading="12/15"
						type={TypographyTypes.p}
						title={type.replace('_', ' ')}
						className="text-gray-9 basis-[35%] capitalize"
					/>
					<div className="flex flex-col basis-[44%]">
						<Typography
							fontLeading="10/12"
							type={TypographyTypes.p}
							title={formatLocalTime(createdAt, 'DD/MM/YYYY')}
							className="text-gray-9"
						/>
						<Typography
							fontLeading="10/12"
							type={TypographyTypes.p}
							title={formatLocalTime(createdAt, 'hh:mm A')}
							className="text-gray-9"
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
