import clsx from 'clsx'
import { HeaderTableItem, Pagination } from 'components'
import { useToast } from 'hooks'
import { ChildrenTableProps, ItemLocations, PaginationType } from 'interfaces'

import { updateLocationList } from 'api/patch/location'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { formatLocalTime } from 'utils'
import { LocationActionCell } from './locationActionCell'

export const MyLocationsTable: React.FC<
	ChildrenTableProps<ItemLocations[]>
> = ({
	data,
	backendPages,
	headers,
	customWidth,
	justify = 'center',
	bodyTableClassName,
	actionsButton,
	actionColumn = 'left',
	actionColumnWidth = 5,
	page,
	setPage,
	refetch,
	setLoading,
	dataNotification,
	isOrder,
	dataTableLength,
	setListToDelete
}): JSX.Element => {
	const navigate = useNavigate()
	const cookies = new Cookies()
	const { showToast } = useToast()

	const [orderOpen, setOrderOpen] = useState<boolean>(false)
	const organizationId = cookies.get('organization_id')

	const onPageChanged = (pages: PaginationType): void => {
		const newPage = pages.currentPage
		if (setPage) {
			setPage(newPage)
		}
	}

	const editHandler = (id: number): void => {
		navigate(`/location/edit/${id}`)
	}

	const pinHandler = async (id: number, val: boolean): Promise<void> => {
		try {
			if (setLoading) setLoading(true)
			const body = {
				is_pinned: val
			}
			const response = await updateLocationList(organizationId, id, body)

			if (response?.message && refetch) {
				refetch()
			}
			showToast(
				`${response?.message ?? response?.detail}`,
				response?.detail ? 'Error' : 'Success',
				response?.detail ? 'error' : 'success'
			)
		} catch (error) {
			console.warn(error)
		} finally {
			if (setLoading) setLoading(false)
		}
	}

	return (
		<div className={clsx('w-full', backendPages === 1 && 'mb-[34px]')}>
			<div className="py-2 px-1 bg-white border-2 border-gray-7 rounded-lg w-full min-w-max">
				<table className="w-full min-h-max min-w-max">
					<thead className="flex w-full h-5 mb-3">
						<tr className="w-full flex justify-between items-center">
							{actionsButton && actionColumn === 'left' && (
								<th
									scope="col"
									className="flex items-center justify-center text-[15px] leading-[18px] text-gray-9 font-bold cursor-pointer"
									style={{ flexBasis: `${actionColumnWidth}%` }}
								>
									Action
								</th>
							)}

							<HeaderTableItem
								header="Name"
								justify={justify}
								table="myLocationsList"
								column="title"
								style={
									customWidth
										? { flexBasis: `${customWidth[0]}%` }
										: { flexBasis: `${100 / 9}%` }
								}
							/>
							<HeaderTableItem
								header="Address"
								justify={justify}
								table="myLocationsList"
								column="address"
								style={
									customWidth
										? { flexBasis: `${customWidth[1]}%` }
										: { flexBasis: `${100 / 9}%` }
								}
							/>

							<HeaderTableItem
								header="Published"
								justify={justify}
								table="myLocationsList"
								column="created_at"
								style={
									customWidth
										? { flexBasis: `${customWidth[2]}%` }
										: { flexBasis: `${100 / 9}%` }
								}
							/>
							<HeaderTableItem
								header="List"
								justify={justify}
								table="myLocationsList"
								column="location_list_name"
								style={
									customWidth
										? { flexBasis: `${customWidth[3]}%` }
										: { flexBasis: `${100 / 9}%` }
								}
							/>
							<HeaderTableItem
								header="Content Items"
								justify={justify}
								table="myLocationsList"
								column="total_content"
								style={
									customWidth
										? { flexBasis: `${customWidth[4]}%` }
										: { flexBasis: `${100 / 9}%` }
								}
							/>
							{actionsButton && actionColumn === 'right' && (
								<th
									scope="col"
									className="flex items-center justify-center text-[15px] leading-[18px] text-gray-9 font-bold cursor-pointer"
									style={{ flexBasis: `12%` }}
								>
									Actions
								</th>
							)}
						</tr>
					</thead>
					<tbody
						className={clsx(
							bodyTableClassName,
							'w-full h-[31rem] overflow-y-auto flex flex-col gap-2 '
						)}
					>
						{data?.map((item, index) => (
							<tr
								key={`${item.id}`}
								className="w-full flex justify-between h-max"
							>
								{actionsButton && actionColumn === 'left' && (
									<td
										className={clsx(
											' flex justify-center items-center text-[15px] leading-[18px] text-center text-gray-9'
										)}
										style={{ flexBasis: `${actionColumnWidth}%` }}
									>
										<LocationActionCell
											editHandler={editHandler}
											deleteHandler={() => setListToDelete?.(item?.id)}
											pinHandler={() => pinHandler(item.id, !item.is_pinned)}
											data={item}
											index={index}
											dataNotification={dataNotification}
											isOrder={isOrder}
											setOrderOpen={setOrderOpen}
											orderOpen={orderOpen}
											dataTableLength={dataTableLength}
										/>
									</td>
								)}
								{headers && (
									<>
										<td
											className={clsx(
												'font-Inter',
												'w-full  flex items-center text-[15px] leading-[18px] text-gray-9',
												justify === 'center' && 'justify-center text-center',
												justify === 'left' && 'justify-left text-left',
												justify === 'right' && 'justify-right text-right'
											)}
											style={
												customWidth
													? { flexBasis: `${customWidth[0]}%` }
													: { flexBasis: `${100 / headers.length}%` }
											}
										>
											{item.title}
										</td>

										<td
											className={clsx(
												'font-Inter',
												'w-full  flex items-center text-[15px] leading-[18px] text-gray-9 capitalize',
												justify === 'center' && 'justify-center text-center',
												justify === 'left' && 'justify-left text-left',
												justify === 'right' && 'justify-right text-right'
											)}
											style={
												customWidth
													? { flexBasis: `${customWidth[1]}%` }
													: { flexBasis: `${100 / headers.length}%` }
											}
										>
											{item.address}
										</td>

										<td
											className={clsx(
												'font-Inter',
												'w-full  flex items-center text-[15px] leading-[18px] text-gray-9',
												justify === 'center' && 'justify-center text-center',
												justify === 'left' && 'justify-left text-left',
												justify === 'right' && 'justify-right text-right'
											)}
											style={
												customWidth
													? { flexBasis: `${customWidth[2]}%` }
													: { flexBasis: `${100 / headers.length}%` }
											}
										>
											{`${
												item.created_at
													? formatLocalTime(
															item.created_at,
															'MM/DD/YYYY hh:mm A'
													  )
													: 'N/A'
											}`}
										</td>

										<td
											className={clsx(
												'font-Inter',
												'w-full  flex items-center text-[15px] leading-[18px] text-gray-9',
												justify === 'center' && 'justify-center text-center',
												justify === 'left' && 'justify-left text-left',
												justify === 'right' && 'justify-right text-right'
											)}
											style={
												customWidth
													? { flexBasis: `${customWidth[3]}%` }
													: { flexBasis: `${100 / headers.length}%` }
											}
										>
											{`${
												item.location_list_name
													? item.location_list_name
													: 'N/A'
											}`}
										</td>

										<td
											className={clsx(
												'font-Inter',
												'w-full  flex items-center text-[15px] leading-[18px] text-gray-9',
												justify === 'center' && 'justify-center text-center',
												justify === 'left' && 'justify-left text-left',
												justify === 'right' && 'justify-right text-right'
											)}
											style={
												customWidth
													? { flexBasis: `${customWidth[4]}%` }
													: { flexBasis: `${100 / headers.length}%` }
											}
										>
											{!item.total_content || item.total_content === null
												? 0
												: item.total_content}
										</td>
									</>
								)}
								{actionsButton && actionColumn === 'right' && (
									<td
										className={clsx(
											' flex justify-center items-center text-[15px] leading-[18px] text-center text-gray-9'
										)}
										style={{ flexBasis: `12%` }}
									>
										{React.cloneElement(actionsButton, {
											value: item.id,
											drag: item.is_pinned
										})}
									</td>
								)}
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<div className="w-full flex items-center justify-end">
				<Pagination
					totalRecords={backendPages ?? 1}
					onPageChanged={onPageChanged}
					page={page}
					pageLimit={1}
				/>
			</div>
		</div>
	)
}
