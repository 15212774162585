/* eslint-disable no-useless-escape */
import { CircularProgress } from '@mui/material'
import {
	BarMultiSelect,
	Button,
	DateTimer,
	DropdownFull,
	Helper,
	InputRadio,
	RadioGroups,
	Typography
} from 'components'
import {
	Icons,
	ScheduleFormInputs,
	ScheduleProps,
	TypographyTypes
} from 'interfaces'
import {
	ItemRadios,
	daysTypeData,
	endShedules,
	itemSchedule,
	itemScheduleEdit,
	itemScheduleEvent,
	periodTypeData
} from 'lib'
import { listDays } from 'lib/dataBarMultiSelect'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { formatLocalTime } from 'utils'
import { allValuesNonEmpty } from 'utils/allValuesNonEmpty'

export const Schedule: React.FC<ScheduleProps> = ({
	totalData,
	setTotalData,
	onClickClose,
	setOpenSlide,
	deliveryActive,
	itemRecurrence,
	eventRecurrence,
	setDeliveryActive,
	setItemRecurrence,
	mode,
	isLoading,
	setEventRecurrence,
	isExpiratedView = false,
	locationComponent = 'Content Item'
}): JSX.Element => {
	const [isSubmit, setIsSubmit] = useState(false)
	const [edit, setEdit] = useState(0)
	const [publisDate, setPublisDate] = useState<string | Moment>('')
	const [dataCurrency, setDataCurrency] = useState<string | Moment>('')
	const [publisDateEvent, setPublisDateEvent] = useState<string | Moment>('')
	const [expirationDateEvent, setExpirationDateEvent] = useState<
		string | Moment
	>('')
	const [untilDateEvent, setUntilDateEvent] = useState<string | Moment>('')
	const [expirationDate, setExpirationDate] = useState<string | Moment>('')
	const [endRadio, setEndRadio] = useState({
		id: '1',
		title: 'Never',
		value: 'never'
	})
	const [endDate, setEndDate] = useState<string | Moment>('')
	const [endDateType, setEndDateType] = useState(daysTypeData[0])
	const [dateType, setDateType] = useState(daysTypeData[0])
	const [periodType, setPeriodType] = useState(periodTypeData[0])
	const [listDaysSeleted, setListDaysSeleted] = useState<string[]>([])
	const [itemSelected, setItemSelected] = useState<ItemRadios[]>([])
	// Pendiente revicion con cliente
	// const [dataCurrencyEvent, setDataCurrencyEvent] = useState<string | Moment>(
	// 	''
	// )
	// const [endRadioEvent, setEndRadioEvent] = useState({
	// 	id: '1',
	// 	title: 'Never',
	// 	value: 'never'
	// })
	// const [dateTypeEvent, setDateTypeEvent] = useState(daysTypeData[0])
	// const [endDateEvent, setEndDateEvent] = useState<string | Moment>('')
	// const [endDdateTypeEvent, setEndDateTypeEvent] = useState(daysTypeData[0])
	// const [periodTypeEvent, setPeriodTypeEvent] = useState(periodTypeData[0])
	// const [listDaysSeletedEvent, setListDaysSeletedEvent] = useState<string[]>([])

	const { handleSubmit } = useForm<ScheduleFormInputs>({
		mode: 'all'
	})

	useEffect(() => {
		if (totalData.basic_setup.category === 'event') {
			if (itemRecurrence === '1' && eventRecurrence === '1') {
				if (
					publisDate === '' ||
					publisDateEvent === '' ||
					expirationDateEvent === '' ||
					(expirationDate === '' && isExpiratedView)
				) {
					setIsSubmit(false)
				} else {
					setIsSubmit(true)
				}
			} else if (itemRecurrence === '2' && eventRecurrence === '1') {
				if (periodType.value === 'week') {
					if (
						listDaysSeleted.length === 0 ||
						dataCurrency === '' ||
						publisDateEvent === '' ||
						expirationDateEvent === ''
					) {
						setIsSubmit(false)
					} else {
						setIsSubmit(true)
					}
				} else if (endRadio.value === 'specific_day') {
					if (
						endDate === '' ||
						dataCurrency === '' ||
						publisDateEvent === '' ||
						expirationDateEvent === ''
					) {
						setIsSubmit(false)
					} else {
						setIsSubmit(true)
					}
				} else if (
					dataCurrency === '' ||
					publisDateEvent === '' ||
					expirationDateEvent === ''
				) {
					setIsSubmit(false)
				} else {
					setIsSubmit(true)
				}
			} else if (itemRecurrence === '1' && eventRecurrence === '2') {
				if (
					publisDate === '' ||
					publisDateEvent === '' ||
					expirationDateEvent === '' ||
					untilDateEvent === '' ||
					(expirationDate === '' && isExpiratedView)
				) {
					setIsSubmit(false)
				} else {
					setIsSubmit(true)
				}
			} else if (itemRecurrence === '2' && eventRecurrence === '2') {
				if (periodType.value === 'week') {
					if (
						dataCurrency === '' ||
						listDaysSeleted.length === 0 ||
						publisDateEvent === '' ||
						expirationDateEvent === '' ||
						untilDateEvent === ''
					) {
						setIsSubmit(false)
					} else {
						setIsSubmit(true)
					}
				} else if (endRadio.value === 'specific_day') {
					if (
						endDate === '' ||
						dataCurrency === '' ||
						publisDateEvent === '' ||
						expirationDateEvent === '' ||
						untilDateEvent === ''
					) {
						setIsSubmit(false)
					} else {
						setIsSubmit(true)
					}
				} else if (dataCurrency === '') {
					setIsSubmit(false)
				} else {
					setIsSubmit(true)
				}
			} else if (itemRecurrence === '4' && eventRecurrence === '1') {
				if (
					!allValuesNonEmpty({
						publisDateEvent,
						expirationDateEvent
					}) &&
					isExpiratedView
				) {
					setIsSubmit(false)
				} else {
					setPublisDate('')
					setExpirationDate('')
					setUntilDateEvent('')
					setIsSubmit(true)
				}
			} else if (itemRecurrence === '4' && eventRecurrence === '2') {
				if (
					!allValuesNonEmpty({
						publisDateEvent,
						expirationDateEvent,
						untilDateEvent
					}) &&
					isExpiratedView
				) {
					setIsSubmit(false)
				} else {
					setPublisDate('')
					setExpirationDate('')
					setIsSubmit(true)
				}
			}
		} else if (itemRecurrence === '1') {
			if (publisDate === '' || (expirationDate === '' && isExpiratedView)) {
				setIsSubmit(false)
			} else {
				setIsSubmit(true)
			}
		} else if (itemRecurrence === '2') {
			if (periodType.value === 'week') {
				if (listDaysSeleted.length === 0 || dataCurrency === '') {
					setIsSubmit(false)
				} else {
					setIsSubmit(true)
				}
			} else if (endRadio.value === 'specific_day') {
				if (endDate === '' || dataCurrency === '') {
					setIsSubmit(false)
				} else {
					setIsSubmit(true)
				}
			} else if (dataCurrency === '') {
				setIsSubmit(false)
			} else {
				setIsSubmit(true)
			}
		} else if (itemRecurrence === '3') {
			setIsSubmit(true)
		}
	}, [
		endDate,
		endRadio,
		periodType,
		publisDate,
		dataCurrency,
		untilDateEvent,
		expirationDate,
		itemRecurrence,
		listDaysSeleted,
		publisDateEvent,
		eventRecurrence,
		expirationDateEvent
	])

	const onSubmit: SubmitHandler<ScheduleFormInputs> = () => {
		setDeliveryActive({
			...deliveryActive,
			shedule: true
		})
		if (totalData.basic_setup.category === 'event' && itemRecurrence !== '3') {
			setTotalData({
				...totalData,
				delivery_enhancements: {
					...totalData.delivery_enhancements,
					is_perpetual: itemRecurrence === '4' || false,
					schedule: {
						...totalData.delivery_enhancements.schedule,
						timing: {
							release_date:
								itemRecurrence === '1'
									? moment(publisDate).utc().format('YYYY-MM-DDTHH:mm')
									: '',
							expiration_date:
								itemRecurrence === '1'
									? moment(isExpiratedView ? expirationDate : publisDate)
											.utc()
											.format('YYYY-MM-DDTHH:mm')
									: ''
						},
						custom_recurrence: {
							repeating_schedule: itemRecurrence === '2',
							repeat_start_date:
								itemRecurrence === '2'
									? moment(dataCurrency).utc().format('YYYY-MM-DDTHH:mm')
									: '',
							repeat_every_type:
								itemRecurrence === '2' ? (periodType.value as string) : '',
							repeat_every_value:
								itemRecurrence === '2' ? (dateType.id as number) : 1,
							repeat_on:
								itemRecurrence === '2'
									? `['${listDaysSeleted.join("','")}']`
									: '',
							ends: {
								repeat_end_value:
									itemRecurrence === '2' ? (endDateType.id as number) : 1,
								repeat_end_type: itemRecurrence === '2' ? endRadio.value : '',
								repeat_end_date:
									itemRecurrence === '2'
										? moment(endDate).utc().format('YYYY-MM-DDTHH:mm')
										: '',
								after_ocurrences:
									itemRecurrence === '2' ? (endDateType.id as number) : 1
							}
						},
						event: {
							...totalData.delivery_enhancements.schedule.event,
							event_start_date: moment(publisDateEvent)
								.utc()
								.format('YYYY-MM-DDTHH:mm'),
							event_end_date: moment(expirationDateEvent)
								.utc()
								.format('YYYY-MM-DDTHH:mm'),
							event_repeat_until:
								eventRecurrence === '2'
									? moment.utc(untilDateEvent).format('YYYY-MM-DDTHH:mm')
									: ''

							// custom_recurrence: {
							// 		.custom_recurrence,
							// 	repeating_schedule: eventRecurrence === '2'
							// Pendiente revicion con cliente
							// repeat_start_date:
							// 	moment(dataCurrencyEvent).format('YYYY-MM-DDTHH:mm'),
							// repeat_every_type: periodTypeEvent.value as string,
							// repeat_every_value: dateTypeEvent.id as number,
							// repeat_on: `['${listDaysSeletedEvent.join("','")}']`,
							// ends: {
							// 	repeat_end_type: endRadioEvent.value,
							// 	repeat_end_date:
							// 		endDateEvent !== ''
							// 			? moment(endDateEvent).format('YYYY-MM-DDTHH:mm')
							// 			: '',
							// 	after_ocurrences: endDdateTypeEvent.id as number
							// }
							// }
						}
					}
				}
			})
			setOpenSlide(false)
		} else if (
			totalData.basic_setup.category !== 'event' &&
			itemRecurrence !== '3'
		) {
			setTotalData({
				...totalData,
				delivery_enhancements: {
					...totalData.delivery_enhancements,
					is_perpetual: false,
					schedule: {
						...totalData.delivery_enhancements.schedule,
						timing: {
							release_date:
								itemRecurrence === '1'
									? moment(publisDate).utc().format('YYYY-MM-DDTHH:mm')
									: '',
							expiration_date:
								itemRecurrence === '1'
									? moment(isExpiratedView ? expirationDate : publisDate)
											.utc()
											.format('YYYY-MM-DDTHH:mm')
									: ''
						},
						custom_recurrence: {
							repeating_schedule: itemRecurrence !== '1',
							repeat_start_date:
								itemRecurrence === '2'
									? moment(dataCurrency).utc().format('YYYY-MM-DDTHH:mm')
									: '',
							repeat_every_type:
								itemRecurrence === '2' ? (periodType.value as string) : '',
							repeat_every_value:
								itemRecurrence === '2' ? (dateType.id as number) : 0,
							repeat_on:
								itemRecurrence === '2'
									? `['${listDaysSeleted.join("','")}']`
									: '',
							ends: {
								// after_ocurrence , specific_day, never
								repeat_end_value:
									itemRecurrence === '2' ? (endDateType.id as number) : 0,
								repeat_end_type: itemRecurrence === '2' ? endRadio.value : '',
								repeat_end_date:
									itemRecurrence === '2'
										? endDate !== ''
											? moment(endDate).utc().format('YYYY-MM-DDTHH:mm')
											: ''
										: '',
								after_ocurrences:
									itemRecurrence === '2' ? (endDateType.id as number) : 0
							}
						}
					}
				}
			})
			setOpenSlide(false)
		} else if (itemRecurrence === '3') {
			setTotalData({
				...totalData,
				delivery_enhancements: {
					...totalData.delivery_enhancements,
					is_perpetual: true,
					schedule: {
						...totalData.delivery_enhancements.schedule,
						timing: {
							release_date: '',
							expiration_date: ''
						},
						custom_recurrence: {
							repeating_schedule: itemRecurrence !== '3',
							repeat_start_date: '',
							repeat_every_type: '',
							repeat_every_value: 0,
							repeat_on: '',
							ends: {
								// after_ocurrence , specific_day, never
								repeat_end_value: 0,
								repeat_end_type: '',
								repeat_end_date: '',
								after_ocurrences: 0
							}
						}
					}
				}
			})
			setOpenSlide(false)
		}
	}

	useEffect(() => {
		if (
			(totalData?.delivery_enhancements?.schedule?.custom_recurrence &&
				totalData.delivery_enhancements.schedule.custom_recurrence
					.repeating_schedule === false) ||
			totalData?.delivery_enhancements?.schedule?.timing?.release_date
		) {
			if (
				(totalData.delivery_enhancements.is_perpetual || mode === 'create') &&
				!totalData.delivery_enhancements?.schedule?.timing.release_date
			) {
				setItemRecurrence('4')
			} else {
				setItemRecurrence('1')
			}
			setPublisDate(
				totalData.delivery_enhancements.schedule.timing.release_date !== ''
					? formatLocalTime(
							totalData.delivery_enhancements.schedule.timing
								.release_date as string,
							'MM/DD/YYYY hh:mm A'
					  )
					: ''
			)
			if (isExpiratedView) {
				setExpirationDate(
					totalData.delivery_enhancements.schedule.timing.expiration_date !== ''
						? formatLocalTime(
								totalData.delivery_enhancements.schedule.timing
									.expiration_date as string,
								'MM/DD/YYYY hh:mm A'
						  )
						: ''
				)
			}
		} else {
			setItemRecurrence('2')
			setDataCurrency(
				totalData.delivery_enhancements.schedule.custom_recurrence &&
					totalData.delivery_enhancements.schedule.custom_recurrence
						.repeat_start_date !== ''
					? formatLocalTime(
							totalData.delivery_enhancements.schedule.custom_recurrence
								.repeat_start_date as string,

							'MM/DD/YYYY hh:mm A'
					  )
					: ''
			)
			const auxDay = daysTypeData?.find(item => {
				const schedule = totalData?.delivery_enhancements?.schedule
				const customRecurrence = schedule?.custom_recurrence

				return item.id === customRecurrence?.repeat_every_value
			})
			if (auxDay) {
				setDateType(auxDay)
			}
			const auxPeriod = periodTypeData.find(item => {
				const customRecurrence =
					totalData?.delivery_enhancements?.schedule?.custom_recurrence
				const repeatEveryType = customRecurrence?.repeat_every_type

				return item.value === repeatEveryType
			})
			if (auxPeriod) {
				setPeriodType(auxPeriod)
			}
			const repeatOn =
				totalData?.delivery_enhancements?.schedule?.custom_recurrence?.repeat_on

			if (repeatOn) {
				setListDaysSeleted(repeatOn.replace(/[\[\]']+/g, '').split(','))
			}
			const auxEndRadio = endShedules.find(item => {
				const customRecurrenceEnds =
					totalData?.delivery_enhancements?.schedule?.custom_recurrence?.ends
				const repeatEndType = customRecurrenceEnds?.repeat_end_type

				return item.value === repeatEndType
			})
			if (auxEndRadio) {
				setEndRadio(auxEndRadio)
			}
			const auxEndDateType = daysTypeData.find(item => {
				const customRecurrence =
					totalData?.delivery_enhancements?.schedule?.custom_recurrence
				const repeatEveryValue = customRecurrence?.repeat_every_value

				return item.id === repeatEveryValue
			})
			if (auxEndDateType) {
				setEndDateType(auxEndDateType)
			}
			const customRecurrenceEnds =
				totalData?.delivery_enhancements?.schedule?.custom_recurrence?.ends
			if (customRecurrenceEnds) {
				setEndDate(
					totalData?.delivery_enhancements?.schedule?.custom_recurrence?.ends
						?.repeat_end_date !== ''
						? moment
								.utc(
									totalData.delivery_enhancements.schedule.custom_recurrence
										.ends.repeat_end_date
								)
								.local()
								.format('MM/DD/YYYY hh:mm A')
						: ''
				)
			}
		}
		if (totalData.basic_setup.category === 'event') {
			if (
				totalData.delivery_enhancements.schedule.event.event_repeat_until === ''
			) {
				setEventRecurrence('1')
				setPublisDateEvent(
					totalData.delivery_enhancements.schedule.event.event_start_date !== ''
						? formatLocalTime(
								totalData.delivery_enhancements.schedule.event
									.event_start_date as string,
								'MM/DD/YYYY hh:mm A'
						  )
						: ''
				)
				setExpirationDateEvent(
					totalData.delivery_enhancements.schedule.event.event_end_date !== ''
						? formatLocalTime(
								totalData.delivery_enhancements.schedule.event
									.event_end_date as string,
								'MM/DD/YYYY hh:mm A'
						  )
						: ''
				)
			} else {
				setEventRecurrence('2')
				setPublisDateEvent(
					totalData.delivery_enhancements.schedule.event.event_start_date !== ''
						? formatLocalTime(
								totalData.delivery_enhancements.schedule.event
									.event_start_date as string,

								'MM/DD/YYYY hh:mm A'
						  )
						: ''
				)
				setExpirationDateEvent(
					totalData.delivery_enhancements.schedule.event.event_end_date !== ''
						? formatLocalTime(
								totalData.delivery_enhancements.schedule.event
									.event_end_date as string,
								'MM/DD/YYYY hh:mm A'
						  )
						: ''
				)
				setUntilDateEvent(
					totalData.delivery_enhancements.schedule.event.event_repeat_until !==
						''
						? formatLocalTime(
								totalData.delivery_enhancements.schedule.event
									.event_repeat_until as string,
								'MM/DD/YYYY hh:mm A'
						  )
						: ''
				)
				// setDataCurrencyEvent(
				// 	totalData.delivery_enhancements.schedule.event.custom_recurrence
				// 		.repeat_start_date !== ''
				// 		? moment(
				// 				totalData.delivery_enhancements.schedule.event.custom_recurrence
				// 					.repeat_start_date
				// 		  ).format('MM/DD/YYYY HH:mm')
				// 		: ''
				// )
				// const auxDayEvent = daysTypeData.find(
				// 	item =>
				// 		item.id ===
				// 		totalData.delivery_enhancements.schedule.event.custom_recurrence
				// 			.repeat_every_value
				// )
				// if (auxDayEvent) {
				// 	setDateType(auxDayEvent)
				// }
				// const auxPeriodEvnet = periodTypeData.find(
				// 	item =>
				// 		item.value ===
				// 		totalData.delivery_enhancements.schedule.event.custom_recurrence
				// 			.repeat_every_type
				// )
				// if (auxPeriodEvnet) {
				// 	setPeriodTypeEvent(auxPeriodEvnet)
				// }
				// const auxDateEvnet = daysTypeData.find(
				// 	item =>
				// 		item.id ===
				// 		totalData.delivery_enhancements.schedule.event.custom_recurrence
				// 			.repeat_every_value
				// )
				// if (auxDateEvnet) {
				// 	setEndDateTypeEvent(auxDateEvnet)
				// }
				// setListDaysSeletedEvent(
				// 	totalData.delivery_enhancements.schedule.event.custom_recurrence.repeat_on
				// 		.replace(/[\[\]']+/g, '')
				// 		.split(',')
				// )
			}
		}
		const aux = edit + 1
		setEdit(aux)
	}, [totalData])

	useEffect(() => {
		const repeatingSchedule =
			totalData?.delivery_enhancements?.schedule?.custom_recurrence
				?.repeating_schedule
		const releaseDate =
			totalData?.delivery_enhancements?.schedule?.timing?.release_date

		const itemsToRender =
			(mode === 'create' || mode === 'edit') &&
			(repeatingSchedule !== false || releaseDate !== '')
				? itemScheduleEdit
				: itemSchedule

		setItemSelected(itemsToRender)
	}, [totalData, mode, itemSchedule, itemScheduleEdit])

	return (
		<div>
			{isLoading ? (
				<div className="flex items-center justify-center w-full h-[400px] py-2 px-1 ">
					<CircularProgress disableShrink />
				</div>
			) : (
				<form
					key={edit}
					onSubmit={handleSubmit(onSubmit)}
					className="mt-5 flex flex-col justify-between h-full"
				>
					<div>
						<div className="mb-2">
							<div>
								{itemSelected.map(item => {
									const element = {
										...item,
										title: `${locationComponent} ${item.title}`
									}
									const typeSchedule = item.id === '1' || item.id === '2'
									return (
										<InputRadio
											item={typeSchedule ? element : item}
											key={item.id}
											defaultChecked={itemRecurrence}
											setItemRecurrence={setItemRecurrence}
										/>
									)
								})}
							</div>

							{itemRecurrence === '1' ? (
								<div key={itemRecurrence}>
									<DateTimer
										className="mt-2"
										validate={moment().format('MM/DD/YYYY HH:mm')}
										icon={Icons.calendar}
										setDate={setPublisDate}
										initialValue={publisDate}
										title="Publish date and time"
										helper
										textHelper="Time and date reflects your local time."
									/>
									{isExpiratedView && (
										<DateTimer
											className="mt-2"
											icon={Icons.calendar}
											setDate={setExpirationDate}
											validate={moment().format('MM/DD/YYYY HH:mm')}
											initialValue={expirationDate}
											title="Expiration date and time:"
										/>
									)}
								</div>
							) : itemRecurrence === '2' ? (
								<div key={itemRecurrence}>
									<div className="w-full flex justify-between items-center mb-2">
										<Typography
											className="text-lavender"
											type={TypographyTypes.span}
											fontLeading="10/12"
											title="Set to automatically refresh publish date and time."
										/>
										<Helper text="Time and date reflects your local time." />
									</div>
									<DateTimer
										line={false}
										className="mt-2"
										setDate={setDataCurrency}
										icon={Icons.calendarCheck}
										initialValue={dataCurrency}
										validate={moment().format('MM/DD/YYYY HH:mm')}
									/>
									<div className="flex items-center gap-2 mt-2">
										<Typography
											title="Repeat every"
											type={TypographyTypes.span}
											fontLeading="15/18"
										/>
										<DropdownFull
											item={dateType}
											list={daysTypeData}
											setItem={setDateType}
											className="w-[120px] sm:w-[140px]"
										/>
										<DropdownFull
											item={periodType}
											list={periodTypeData}
											setItem={setPeriodType}
											className="w-[120px] sm:w-[140px]"
										/>
									</div>
									{periodType.value === 'week' && (
										<div className="my-2">
											<Typography
												title="Repeat On"
												type={TypographyTypes.span}
												fontLeading="15/18"
												className="mb-3"
											/>
											<BarMultiSelect
												list={listDays}
												listSeleted={listDaysSeleted}
												setListSeleted={setListDaysSeleted}
											/>
										</div>
									)}
									<RadioGroups
										name="ends"
										title="Ends"
										checked={endRadio}
										options={endShedules}
										setEndDate={setEndDate}
										setChecked={setEndRadio}
										endDate={endDate}
										endDdateType={endDateType}
										setEndDateType={setEndDateType}
										validate={moment().format('MM/DD/YYYY HH:mm')}
									/>
								</div>
							) : (
								''
							)}
						</div>
						{totalData.basic_setup.category === 'event' &&
							itemRecurrence !== '3' && (
								<div>
									<div className="mt-2">
										<Typography
											title="Events"
											type={TypographyTypes.h3}
											className="text-lavender mb-2"
											fontLeading="18/21"
										/>
										<Typography
											type={TypographyTypes.h3}
											className="text-lavender mb-2"
											fontLeading="10/12"
											title="Is this an event with dates and times? "
										/>
									</div>
									<div className="mb-2">
										<div>
											{itemScheduleEvent.map(item => (
												<InputRadio
													item={item}
													key={item.id}
													defaultChecked={eventRecurrence}
													setItemRecurrence={setEventRecurrence}
												/>
											))}
										</div>
										<DateTimer
											className="mt-2"
											icon={Icons.calendar}
											title="Event date and time"
											setDate={setPublisDateEvent}
											initialValue={publisDateEvent}
											validate={moment().format('MM/DD/YYYY HH:mm')}
										/>
										<DateTimer
											className="mt-2"
											icon={Icons.calendar}
											title="Event Ends at:"
											setDate={setExpirationDateEvent}
											initialValue={expirationDateEvent}
											validate={moment(publisDateEvent).format(
												'MM/DD/YYYY HH:mm'
											)}
										/>
										{eventRecurrence === '2' && (
											<DateTimer
												className="mt-2"
												icon={Icons.calendar}
												setDate={setUntilDateEvent}
												initialValue={untilDateEvent}
												title="This event repeats every day until:"
												validate={moment(expirationDateEvent).format(
													'MM/DD/YYYY HH:mm'
												)}
											/>
										)}
										{/* 
							Pendiente revision con cliente
							(
								<div>
									<Typography
										className="text-lavender"
										type={TypographyTypes.span}
										fontLeading={'10/12'}
										title="Set to automatically refresh publish date and time."
									/>
									<DateTimer
										line={false}
										className="mt-2"
										icon={Icons.calendarCheck}
										setDate={setDataCurrencyEvent}
										initialValue={moment(dataCurrencyEvent)}
										validate={moment().format('MM/DD/YYYY HH:mm')}
									/>
									<div className="flex items-center gap-2 mt-2">
										<Typography
											title="Repeat every"
											type={TypographyTypes.span}
											fontLeading={'15/18'}
										/>
										<DropdownFull
											item={dateTypeEvent}
											list={daysTypeData}
											setItem={setDateTypeEvent}
											className="min-w-[140px]"
										/>
										<DropdownFull
											item={periodTypeEvent}
											list={periodTypeData}
											setItem={setPeriodTypeEvent}
											className="min-w-[140px]"
										/>
									</div>
									{periodTypeEvent.value === 'week' && (
										<div className="mt-2">
											<Typography
												title="Repeat On"
												type={TypographyTypes.span}
												fontLeading={'15/18'}
												className="mb-2"
											/>
											<BarMultiSelect
												list={listDays}
												listSeleted={listDaysSeletedEvent}
												setListSeleted={setListDaysSeletedEvent}
											/>
										</div>
									)}
									<RadioGroups
										title="Ends"
										name="endsEvents"
										validate={moment().format('MM/DD/YYYY HH:mm')}
										options={endShedules}
										endDate={moment(endDateEvent)}
										checked={endRadioEvent}
										setEndDate={setEndDateEvent}
										setChecked={setEndRadioEvent}
										endDdateType={endDdateTypeEvent}
										setEndDateType={setEndDateTypeEvent}
									/>
								</div>
							)} */}
									</div>
								</div>
							)}
					</div>

					<div className="flex items-center justify-between">
						<Button
							type="button"
							label="Cancel"
							color="Transparent"
							className="!min-w-[130px]"
							onClick={onClickClose}
						/>
						<Button
							label="Save"
							type="submit"
							color="Primary"
							className="!min-w-[130px]"
							disabled={!isSubmit}
						/>
					</div>
				</form>
			)}
		</div>
	)
}
