import { API } from 'api'
import {
	BodyCreateContent,
	BodySharedContent,
	BodySharedContentCopy,
	ContentListItem,
	ContentReachRequest,
	ContentReachResponse,
	CreateContentResponse
} from 'interfaces'

export const postContentReach = async (
	body: ContentReachRequest
): Promise<ContentReachResponse | null> => {
	let res = null as ContentReachResponse | null
	await API()
		.post(`/contents/reach`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const postCreateContent = async (
	organizationId: number,
	body: BodyCreateContent | null
): Promise<CreateContentResponse | null> => {
	let res = null as CreateContentResponse | null
	await API()
		.post(`/contents/organizations/${organizationId}`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				if (error.response.data.detail) {
					res = { detail: error.response.data.detail }
				}
			}
		)
	return res
}

export const postCopySharedContent = async (
	organizationId: number,
	body: BodySharedContentCopy,
	contentID: number
): Promise<CreateContentResponse | null> => {
	let res = null as CreateContentResponse | null
	await API()
		.post(
			`/contents/organization_partner/organizations/${organizationId}/partner_content/${contentID}/copy_content`,
			body
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				if (error.response.data.errors) {
					res = { detail: error.response.data.errors[0].msg }
				} else {
					res = { detail: error.response.data.detail.error }
				}
			}
		)
	return res
}

export const postCopySharedContentArray = async (
	organizationId: number,
	body: BodySharedContent
): Promise<CreateContentResponse | null> => {
	let res = null as CreateContentResponse | null
	await API()
		.post(
			`/contents/organization_partner/organizations/${organizationId}/bulk/copy_content`,
			body
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				if (error.response.data.errors) {
					res = { detail: error.response.data.errors[0].msg }
				} else {
					res = { detail: error.response.data.detail.error }
				}
			}
		)
	return res
}

export const duplicateContent = async (
	organizationId: number,
	contentID: number
): Promise<ContentListItem | null> => {
	let res = null as ContentListItem | null
	await API()
		.post(
			`/contents/duplicate_content/${contentID}/organizations/${organizationId}`,
			{}
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}
