import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import Cookies from 'universal-cookie'

export const MyPeopleManager = ({
	children
}: {
	children: JSX.Element
}): JSX.Element => {
	const location = useLocation()
	const cookies = new Cookies()
	const roles = cookies.get('roles')
	const isSuperAdmin = cookies.get('isSuperAdmin')

	if (!roles.find((item: number) => item === 3 || item === 5 || isSuperAdmin)) {
		return <Navigate to="/" state={{ from: location }} />
	}

	return children
}
