import { Dialog, Transition } from '@headlessui/react'
import clsx from 'clsx'
import {
	Bottons,
	Icon,
	Nav,
	OrganizationNamesLayout,
	PeopleNamesLayout
} from 'components'
import { UpdateAdminModal } from 'components/modal/updateAdminModal'
import { MenuContext } from 'context'
import { Icons, LayoutProps } from 'interfaces'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { cleanVersionString } from 'utils'
import packageJson from '../../../package.json'

export const SuperAdminLayout: React.FC<LayoutProps> = ({
	children,
	className
}): JSX.Element => {
	const navigate = useNavigate()
	const { id } = useParams()
	const cookies = new Cookies()
	const fullName = cookies.get('fullName') as string
	const { setSidebarOpen, sidebarOpen } = useContext(MenuContext)
	const [menuOpen, setMenuOpen] = React.useState(false)
	const packageVersionCookies = cookies.get('package_version')
	const modaShow = cookies.get('modalAlreadyShown')
	const packageVersionLocal = packageJson.version
	const [openModalUpdate, setOpenModalUpdate] = useState(false)
	const cleanedCookiesVersion = cleanVersionString(packageVersionCookies)
	const cleanedLocalVersion = cleanVersionString(packageVersionLocal)

	const navigation = [
		{
			name: 'Organizations',
			href: '/superAdmin/organization',
			active: ['/superAdmin/organization', '/superAdmin/organization/create'],
			icon: Icons.organization_super_admin,
			userView: true
		},
		{
			name: 'App Builds',
			href: '/superAdmin/appBuilds',
			active: [
				'/superAdmin/appBuilds',
				'/superAdmin/appBuilds/create-app',
				`/superAdmin/appBuilds/version/${id}`
			],
			icon: Icons.appShortcut,
			userView: true
		},
		{
			name: 'Settings',
			href: '/superAdmin/settings',
			active: ['/superAdmin/settings'],
			icon: Icons.settings,
			userView: true
		}
	]

	const handleLogout = (): void => {
		cookies.remove('fullName', { path: '/' })
		cookies.remove('organization_id', { path: '/' })
		cookies.remove('roles', { path: '/' })
		cookies.remove('userId', { path: '/' })
		cookies.remove('organization_slug', { path: '/' })
		cookies.remove('organization_name', { path: '/' })
		cookies.remove('token', { path: '/' })
		cookies.remove('isSuperAdmin', { path: '/' })
		cookies.remove('organization_logo', { path: '/' })
		navigate('/login')
	}

	useEffect(() => {
		if (
			Number(cleanedCookiesVersion) !== Number(cleanedLocalVersion) ||
			!cleanedLocalVersion
		) {
			setOpenModalUpdate(true)
		} else {
			setOpenModalUpdate(false)
		}
	}, [packageVersionCookies, packageVersionLocal])

	const handleCancel = (): void => {
		cookies.set('modalAlreadyShown', 'true')
		setOpenModalUpdate(false)
	}
	return (
		<>
			{openModalUpdate && !modaShow && (
				<UpdateAdminModal
					open={openModalUpdate}
					setOpen={setOpenModalUpdate}
					isUpdate
					handleCancel={handleCancel}
				/>
			)}

			<div className="bg-gray-6 min-h-screen pt-2 2lg:pt-0">
				<div
					className={clsx(
						'fixed inset-y-0 hidden z-[100]',
						sidebarOpen && 'max-w-[220px]',
						'2lg:flex 2lg:flex-col'
					)}
				>
					<div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
						<div className="flex flex-1 flex-col overflow-y-auto hide-scroll-bar pt-5 pb-4">
							<div className="flex flex-col justify-center items-center">
								<OrganizationNamesLayout
									sidebarOpen={sidebarOpen}
									organizationName="Patter"
								/>
								<PeopleNamesLayout
									rol="Super Admin"
									fullName={fullName}
									sidebarOpen={sidebarOpen}
								/>
							</div>
							<Nav navigation={navigation} sidebarOpen={sidebarOpen} />
						</div>
						<Bottons
							isSuperAdmin={false}
							sidebarOpen={sidebarOpen}
							handleLogout={handleLogout}
							setSidebarOpen={setSidebarOpen}
						/>
					</div>
				</div>
				{/* Responsive */}
				<div className="2lg:hidden flex justify-between items-center bg-white mx-2 p-4">
					<button type="button" onClick={() => setMenuOpen(!menuOpen)}>
						<Icon
							src={Icons.menuClose}
							fillPath
							className="w-7 !max-h-[1.75rem] text-gray-19"
						/>
					</button>
					{/* Menu Responsive */}
					<Icon src={Icons.logo_PATTER} className="h-[24px] w-[24px]" />
					<Transition.Root show={menuOpen} as={Fragment}>
						<Dialog
							as="div"
							className="relative z-50 2lg:hidden"
							onClose={setMenuOpen}
						>
							<div className="fixed inset-0 flex">
								<Transition.Child
									as={Fragment}
									enter="transition ease-in-out duration-300 transform"
									enterFrom="-translate-x-full"
									enterTo="translate-x-0"
									leave="transition ease-in-out duration-300 transform"
									leaveFrom="translate-x-0"
									leaveTo="-translate-x-full"
								>
									<Dialog.Panel className="relative flex w-full flex-1">
										{/* Sidebar component, swap this element with another sidebar if you like */}
										<div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-4 pt-4 ring-1 ring-white/10">
											<button
												type="button"
												className="pl-4"
												onClick={() => setMenuOpen(!menuOpen)}
											>
												<Icon
													src={Icons.closeCircle}
													fillPath
													className="w-7 !max-h-[1.75rem] text-gray-19"
												/>
											</button>
											<OrganizationNamesLayout
												responsive
												sidebarOpen={menuOpen}
												organizationName="Patter"
											/>
											<PeopleNamesLayout
												rol="Super Admin"
												fullName={fullName}
												sidebarOpen={menuOpen}
											/>
											<Nav
												responsive
												sidebarOpen={menuOpen}
												navigation={navigation}
											/>
											<Bottons
												responsive
												isSuperAdmin={false}
												sidebarOpen={menuOpen}
												handleLogout={handleLogout}
												setSidebarOpen={setMenuOpen}
											/>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</Dialog>
					</Transition.Root>
				</div>
				<main
					className={clsx(
						className,
						'2lg:pr-8 py-8 min-h-screen',
						!sidebarOpen && '2lg:pl-28 px-4',
						sidebarOpen && '2lg:pl-60 px-4'
					)}
				>
					{children}
				</main>
			</div>
		</>
	)
}
