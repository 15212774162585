import { Box } from 'components/boxs'
import { Typography } from 'components/typography'
import { Images, propsPreviewModal, TypographyTypes } from 'interfaces'
import { removeHTMLTags } from 'lib'
import React from 'react'

const PreviewDetails: React.FC<propsPreviewModal> = ({ data }) => {
	const dataImage = data?.content_info?.basic_setup.preview?.files[0]?.asset_url

	return (
		<Box>
			<div>
				<Typography
					title="Preview:"
					type={TypographyTypes.h3}
					className="text-lavender"
					fontLeading="18/21"
				/>
				<div className="flex w-full m-3 gap-6">
					<img
						src={dataImage || Images.noImageLogo}
						alt="preview"
						className="rounded-lg w-[50px] h-[50px]"
					/>
					<div className="flex flex-col ">
						<Typography
							type={TypographyTypes.h3}
							fontLeading="16/22"
							className="text-gray-9  border-gray-11"
							title={data?.title}
						/>
						<Typography
							type={TypographyTypes.p}
							fontLeading="15/18"
							className="!font-normal text-black-1"
							title={data?.organization}
						/>
					</div>
				</div>
				<img
					src={dataImage || Images.noImageLogo}
					alt="preview"
					className="rounded-lg  h-[22.375rem] w-full"
				/>

				<Typography
					type={TypographyTypes.p}
					fontLeading="15/18"
					className="!font-normal text-black-1 mt-4"
					title={removeHTMLTags(data?.content_info?.set_up?.description)}
				/>
			</div>
		</Box>
	)
}

export default PreviewDetails
