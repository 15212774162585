import clsx from 'clsx'
import { Button, Icon, Typography } from 'components'
import { useToast } from 'hooks'
import { InputFileProps2, TypographyTypes } from 'interfaces'
import React, { ChangeEvent, useRef } from 'react'
import { configGenaral } from 'utils'

export const InputFile2: React.FC<InputFileProps2> = ({
	className,
	setValue,
	icon,
	icon2,
	name,
	title,
	...props
}) => {
	const fileInput = useRef<HTMLInputElement>(null)
	const { showToast } = useToast()
	const onButtonClick = (): void => {
		fileInput.current?.click()
	}

	const onFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const file = event.target.files?.[0]
		if ((file as File).size / 1024 ** 2 >= configGenaral.maxfileSizeMB) {
			showToast(
				'Error',
				`Please select a file less than ${configGenaral.maxfileSizeMB}MB.`,
				'error'
			)
			return
		}
		const reader = new FileReader()
		reader.onloadend = () => {
			setValue({ name: (file as File).name, content: reader.result as string })
		}
		reader.readAsDataURL(file as File)
	}

	return (
		<div className={clsx(className, 'flex flex-col w-max')}>
			<input
				type="file"
				name={name}
				className="hidden"
				ref={fileInput}
				onChange={onFileChange}
				accept="image/png, image/jpeg, image/jpg"
				{...props}
			/>
			<Typography
				htmlFor={name}
				title={title}
				type={TypographyTypes.label}
				className={clsx('text-black-1 mb-4')}
				fontLeading="12/12"
			/>
			<div
				className="relative flex items-center justify-center w-[58px] h-[58px] border border-2 border-gray-3 rounded-[50%] bg-gray-11 cursor-pointer"
				aria-hidden
				onClick={onButtonClick}
			>
				<Button
					type="button"
					className={clsx('!w-max !min-w-[unset] !px-1 !py-1 !border-none')}
					iconRight={icon}
					rightIconDivClassName="w-9 h-9"
					color="Gray3"
				/>
				<div className="absolute top-[34px] left-[32px] flex items-center justify-center w-[26px] h-[26px] bg-blue-primary rounded-[50%]">
					<div className="w-4 h-4">
						<Icon src={icon2} fillPath className="text-white" />
					</div>
				</div>
			</div>
		</div>
	)
}
