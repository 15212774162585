import clsx from 'clsx'
import React from 'react'

export const RadioButton = React.forwardRef<
	HTMLInputElement,
	Omit<
		React.DetailedHTMLProps<
			React.InputHTMLAttributes<HTMLInputElement>,
			HTMLInputElement
		>,
		'type'
	>
>(({ className, ...props }, ref) => {
	return (
		<input
			type="radio"
			className={clsx(
				'focus:ring-0 focus:ring-offset-0 !border-2 border-gray-3 !bg-none',
				'checked:text-blue-primary checked:border-gray-3',
				'hover:border-gray-1 hover:checked:border-gray-1 focus:checked:border-gray-1',
				className
			)}
			ref={ref}
			{...props}
		/>
	)
})
