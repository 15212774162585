import { Icon, Typography } from 'components'
import { TitleBoxProps, TypographyTypes } from 'interfaces'
import React from 'react'

export const TitleBox: React.FC<TitleBoxProps> = ({
	icon,
	title,
	className
}): JSX.Element => {
	return (
		<div className={className && className}>
			<div className="flex items-center gap-2">
				<Typography
					title={title}
					fontLeading="15/15"
					type={TypographyTypes.h2}
					className="text-black-1"
				/>
				{icon && <Icon fillPath src={icon} className="w-5 text-black-1" />}
			</div>
		</div>
	)
}
