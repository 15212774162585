import { DateTimer, DropdownFull, Typography } from 'components'
import { Icons, RadioGroupsProps, TypographyTypes } from 'interfaces'
import { daysTypeData } from 'lib'
import React from 'react'

export const RadioGroups: React.FC<RadioGroupsProps> = ({
	name,
	title,
	endDate,
	checked,
	options,
	validate,
	setChecked,
	setEndDate,
	endDdateType,
	setEndDateType
}): JSX.Element => {
	return (
		<div>
			<Typography
				title={title}
				className="mb-2"
				type={TypographyTypes.span}
				fontLeading="15/18"
			/>
			<fieldset className="mt-4">
				<div className="space-y-4">
					{options.map(option => (
						<div key={option.id} className="flex items-center gap-2 w-full">
							<div className="flex items-center">
								<input
									id={`${name}-${option.id}`}
									name={name}
									type="radio"
									defaultChecked={option.id === checked.id}
									onClick={() => setChecked(option)}
									className="h-4 w-4 border-gray-300 text-blue-primary focus:ring-white !bg-none "
								/>
								<label
									htmlFor={`${name}-${option.id}`}
									className="ml-3 block text-sm font-medium leading-6 text-gray-900"
								>
									{option.title}
								</label>
							</div>
							{option.value === 'specific_day' && (
								<DateTimer
									line={false}
									validate={validate}
									setDate={setEndDate}
									initialValue={endDate}
									className="min-w-[252px]"
									icon={Icons.calendarCheck}
									disabled={checked.value !== 'specific_day'}
								/>
							)}
							{option.value === 'after_ocurrence' && (
								<div className="flex items-center gap-2">
									<DropdownFull
										item={endDdateType}
										list={daysTypeData}
										setItem={setEndDateType}
										className="min-w-[85px]"
										disabled={checked.value !== 'after_ocurrence'}
									/>
									<Typography
										title="Occurrences"
										type={TypographyTypes.span}
										fontLeading="18/21"
										className="text-gray-9 !font-normal"
									/>
								</div>
							)}
						</div>
					))}
				</div>
			</fieldset>
		</div>
	)
}
