import clsx from 'clsx'
import React from 'react'

export const Checkbox = React.forwardRef<
	HTMLInputElement,
	Omit<
		React.DetailedHTMLProps<
			React.InputHTMLAttributes<HTMLInputElement>,
			HTMLInputElement
		>,
		'type'
	>
>((props, ref) => {
	const { className, ...inputProps } = props
	return (
		<input
			className={clsx(
				'h-[18px] w-[18px] rounded-sm border-2 border-gray-3 text-blue-primary focus:ring-transparent-default disabled:bg-gray-6',
				className,
				!props.disabled ? 'cursor-pointer' : 'cursor-not-allowed'
			)}
			ref={ref}
			type="checkbox"
			{...inputProps}
		/>
	)
})
