import { Typography } from 'components/typography'
import { InputRadioImageProps, TypographyTypes } from 'interfaces'
import React from 'react'

export const InputRadioImage: React.FC<InputRadioImageProps> = ({
	option,
	defaultOptions,
	optionSelected
}): JSX.Element => {
	return (
		<div key={option.id} className="flex items-center">
			<input
				id={option.id}
				name="notification-method"
				type="radio"
				defaultChecked={option.id === defaultOptions}
				onClick={() => optionSelected(option)}
				className="h-4 w-4 border-gray-300 text-blue-primary focus:ring-white !bg-none"
			/>
			<label htmlFor={option.id}>
				<div className="ml-2 flex items-center gap-2 pb-2">
					<img
						src={option.icon_image_url}
						alt={option.name}
						className="w-9 h-9 rounded-full"
					/>
					<Typography
						title={option.name}
						type={TypographyTypes.p}
						fontLeading="12/15"
						className="text-gray-1 !font-normal"
					/>
				</div>
			</label>
		</div>
	)
}
