import { DropdownFullItemProps } from 'interfaces'

export const contentTypeData = [
	{
		id: 1,
		name: 'Info',
		value: 'info'
	},
	{
		id: 2,
		name: 'Event',
		value: 'event'
	},
	{
		id: 3,
		name: 'Take Action',
		value: 'take_action'
	}
] as DropdownFullItemProps[]

export const contentTypeDataSelect = [
	{
		id: 0,
		name: 'Seleccionar',
		value: 'seleccionar'
	}
] as DropdownFullItemProps[]

export const daysTypeData = [
	{
		id: 1,
		name: '1'
	},
	{
		id: 2,
		name: '2'
	},
	{
		id: 3,
		name: '3'
	},
	{
		id: 4,
		name: '4'
	},
	{
		id: 5,
		name: '5'
	},
	{
		id: 6,
		name: '6'
	},
	{
		id: 7,
		name: '7'
	},
	{
		id: 8,
		name: '8'
	},
	{
		id: 9,
		name: '9'
	},
	{
		id: 10,
		name: '10'
	},
	{
		id: 11,
		name: '11'
	},
	{
		id: 12,
		name: '12'
	},
	{
		id: 13,
		name: '13'
	}
] as DropdownFullItemProps[]

export const periodTypeData = [
	{
		id: 1,
		name: 'Days',
		value: 'day'
	},
	{
		id: 2,
		name: 'Weeks',
		value: 'week'
	},
	{
		id: 3,
		name: 'Months',
		value: 'month'
	},
	{
		id: 4,
		name: 'Year',
		value: 'year'
	}
] as DropdownFullItemProps[]

export const radioOptions = [
	{
		id: 0,
		label: 'My Content',
		value: 'myContent'
	}
	// ,
	// {
	// 	id: 1,
	// 	label: 'Partner Content',
	// 	value: 'partnerContent'
	// }
]

export const messageCenterOptions = [
	{
		id: 0,
		value: 'all',
		label: 'All'
	},
	{
		id: 1,
		value: 'my_messages',
		label: 'My Messages'
	},
	{
		id: 2,
		value: 'partner_messages',
		label: 'Partner Messages'
	}
]

export const allContentTypesData = [
	{
		id: 0,
		name: 'All Content Types',
		value: ''
	},
	{
		id: 1,
		name: 'Info',
		value: 'info'
	},
	{
		id: 2,
		name: 'Event',
		value: 'event'
	},
	{
		id: 3,
		name: 'Take Action',
		value: 'take_action'
	}
]

export const allSettingsTimeTypesData = [
	{
		id: 0,
		name: 'None',
		value: '0'
	},
	{
		id: 1,
		name: '1 hour',
		value: '1'
	},
	{
		id: 2,
		name: '5 hours',
		value: '5'
	},
	{
		id: 3,
		name: '10 hours',
		value: '10'
	},
	{
		id: 4,
		name: '15 hours',
		value: '15'
	},
	{
		id: 5,
		name: '20 hours',
		value: '20'
	},
	{
		id: 6,
		name: '1 day',
		value: '24'
	},
	{
		id: 7,
		name: 'Every 3 days',
		value: '72'
	},
	{
		id: 8,
		name: '1 Week',
		value: '168'
	},
	{
		id: 9,
		name: '2 Weeks',
		value: '336'
	}
]

export const allPartnerContentTypesData = [
	{
		id: 0,
		name: 'All Content Types',
		value: ''
	},
	{
		id: 1,
		name: 'Info',
		value: 'info'
	},
	{
		id: 2,
		name: 'Event',
		value: 'event'
	},
	{
		id: 3,
		name: 'Take Action',
		value: 'take_action'
	}
]

export const allStatusesData = [
	{
		id: 0,
		name: 'All Statuses',
		value: ''
	},
	{
		id: 1,
		name: 'Published',
		value: 'published'
	},
	{
		id: 3,
		name: 'Deleted',
		value: 'deleted'
	},
	{
		id: 4,
		name: 'Draft',
		value: 'draft'
	},
	{
		id: 5,
		name: 'Expired',
		value: 'finished'
	}
]

export const allStatusesDataNotification = [
	{
		id: 0,
		name: 'All Statuses',
		value: ''
	},
	{
		id: 1,
		name: 'Scheduled',
		value: 'scheduled'
	},
	{
		id: 3,
		name: 'processing',
		value: 'processing'
	},
	{
		id: 4,
		name: 'Draft',
		value: 'draft'
	},
	{
		id: 5,
		name: 'Rejected',
		value: 'rejected'
	},
	{
		id: 6,
		name: 'Finished',
		value: 'finished'
	}
]

export const allFMStatusesData = [
	{
		id: 0,
		name: 'All FM Statuses',
		value: ''
	},
	{
		id: 1,
		name: 'Not Amplified',
		value: 'not_amplified'
	},
	{
		id: 2,
		name: 'Inbound',
		value: 'inbound'
	},
	{
		id: 3,
		name: 'Outbound',
		value: 'outbound'
	},
	{
		id: 4,
		name: 'Accepted',
		value: 'accepted'
	},
	{
		id: 5,
		name: 'Ampified',
		value: 'ampified'
	}
]

export const typeOfLinkData = [
	{
		id: 0,
		name: 'Select an option',
		value: ''
	},
	{
		id: 1,
		name: 'URL',
		value: 'url'
	},
	{
		id: 2,
		name: 'AllAboard Link',
		value: 'allboard_link'
	},
	{
		id: 3,
		name: 'Email Address',
		value: 'email'
	},
	{
		id: 4,
		name: 'Phone Number',
		value: 'phone'
	}
	// {
	// 	id: 5,
	// 	name: 'PDF',
	// 	value: ''
	// }
]

export const linkToUrl = [
	{
		id: 0,
		label: 'None',
		value: 'none',
		placeholder: ''
	},
	{
		id: 1,
		label: 'Content Item',
		value: 'content',
		placeholder: 'Content'
	},
	{
		id: 2,
		label: 'Address',
		value: 'address',
		placeholder: 'Street address line'
	},
	{
		id: 3,
		label: 'URL',
		value: 'url',
		placeholder: 'https://www.example.com'
	}
]
