export const configGenaral = {
	maxTitleLengthLocationName: 30,
	maxTitleLength: 40,
	maxTagsLength: 12,
	maxfileSizeMB: 10,
	maxSearchValue: 100,
	maxDescriptionLength: 12000,
	titleExtraLarge: 140,
	maxTitlePush: 27,
	maxMessagePush: 140,
	sizeElementGet: 10
}

export const regexVimeoOrYoutube =
	// eslint-disable-next-line max-len
	/^(?!.*youtube\.com\/.*live)(?:(?:https?:\/\/)?(?:www\.)?)?(?:(?:youtube\.com\/watch\?(?:(?=.*v=([^&\s]+))(?:\S+)?|(?:(?=.*\/)(?:\S+)?))|(?:youtu\.be\/)([^&\s]+))|(?:https?:\/\/vimeo\.com\/\d+))(?:\S+)?$/

export const regexUrl = /^(http|https|ftp):\/\/[^\s/$.?#].[^\s]*$/
