import { Icon } from 'components/icon'
import { Typography } from 'components/typography'
import { Icons, TypographyTypes } from 'interfaces'
import React from 'react'
import { cardOptionsType } from './cardOptions.type'

export const CardOpstions: React.FC<cardOptionsType> = (props): JSX.Element => {
	const { data } = props
	const { title, descripcion, action, image } = data
	return (
		<button
			type="button"
			className="flex flex-row md:w-[33%] h-[120px] bg-white items-center rounded-[20px] mr-2 mb-3"
			onClick={() => action(true)}
		>
			<div className="w-[30%] pl-2 pt-2">
				{image && (
					<img src={image} alt={title} className="w-[20] max-h-[108px]" />
				)}
			</div>
			<div className="w-[60%]">
				<div className="pl-4 text-left">
					<Typography
						title={title}
						className="text-gray-9"
						fontLeading="16/22"
						type={TypographyTypes.h3}
					/>
					<Typography
						title={descripcion}
						className="text-gray-1"
						fontLeading="15/22"
						type={TypographyTypes.p}
					/>
				</div>
			</div>

			<div className="w-[12%]">
				<Icon
					src={Icons.expandMoreRight}
					className="w-[40px] h-[35px] text-black"
				/>
			</div>
		</button>
	)
}

export default CardOpstions
