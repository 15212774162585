/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Icon, Input, Typography, handleValid } from 'components'
import {
	GroupComponentsLinkProps,
	Icons,
	MailItem,
	TypographyTypes
} from 'interfaces'
import React, { useEffect } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { configGenaral } from 'utils'
import {
	PropsComponentsMyOrganization,
	onChangeFieldProps,
	typeFieldMails
} from './interfaceSlide.type'

const GroupComponents: React.FC<
	GroupComponentsLinkProps & onChangeFieldProps
> = ({
	register,
	index,
	deleteComponent,
	watch,
	setValue,
	onChangeField,
	errorCustom
}): JSX.Element => {
	const rules = {
		title: {
			required: { value: true, message: 'Is required' }
		},
		email: {
			required: { value: true, message: 'Is required' },
			pattern: {
				value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
				message: 'Invalid email address'
			}
		}
	}

	const watchTitle = watch?.(`mails.${index}.title`)
	const watchEmail = watch?.(`mails.${index}.email`)

	return (
		<div className="flex flex-col w-full gap-6 mb-6">
			<Input
				name={`mails.${index}.title`}
				title="Title"
				register={register}
				rules={rules.title}
				error={errorCustom?.title}
				borderFull
				onChangeCustom={e => {
					const { value } = e.target
					onChangeField({
						title: value,
						email: watchEmail
					})
				}}
				limit
				cantLimit={configGenaral.maxTitleLength}
				watchValue={watchTitle}
				setValueInput={setValue}
			/>
			<Input
				name={`mails.${index}.email`}
				title="Email"
				type="email"
				register={register}
				rules={rules.email}
				error={errorCustom?.email}
				borderFull
				onChangeCustom={e => {
					const { value } = e.target
					onChangeField({
						title: watchTitle,
						email: value
					})
				}}
			/>
			{index > 0 && (
				<div
					aria-hidden="true"
					className=" flex gap-2 cursor-pointer"
					onClick={() => deleteComponent(index)}
				>
					<Icon
						src={Icons.delete}
						className="w-[16px] h-[18px] text-gray-3"
						fillPath
					/>
					<Typography
						title="Remove Email"
						className="text-gray-3"
						fontLeading="15/18"
						type={TypographyTypes.p}
					/>
				</div>
			)}
		</div>
	)
}

export const SlideEmail: React.FC<PropsComponentsMyOrganization> = ({
	setErrorForm,
	active,
	pos,
	watch: watchParent,
	setValue: setValueForm,
	setFieldError
}): JSX.Element => {
	const data = watchParent().tabs?.[active]?.components?.[pos].component_value

	const {
		control,
		register,
		watch,
		setValue,
		formState: { errors, isValid }
	} = useForm<typeFieldMails>({
		mode: 'all',
		defaultValues: {
			mails: data?.mails ?? [
				{
					title: '',
					email: ''
				}
			]
		}
	})
	const { fields, remove, append } = useFieldArray({
		control,
		name: 'mails'
	})

	const nameType = `mail${active}-${pos}`

	useEffect(() => {
		handleValid(nameType, isValid, setFieldError)
		setErrorForm?.(!isValid)
	}, [isValid])

	return (
		<div className="flex flex-col justify-between overflow-hidden p-2">
			<div className="flex flex-col w-full overflow-y-auto max-h-[calc(100vh-130px)] pb-6">
				<div className="mr-2">
					{fields.map((component, index) => {
						return (
							<Controller
								key={`mails-${component.id}`}
								control={control}
								name={`mails.${index}`}
								render={() => {
									return (
										<GroupComponents
											register={register}
											index={index}
											errors={errors}
											deleteComponent={remove}
											watch={watch}
											setValue={setValue}
											onChangeField={() => {
												const resultData = watch().mails?.[index]
												setValueForm(
													`tabs.${active}.components.${pos}.component_value.mails.${index}`,
													resultData as MailItem
												)
											}}
											errorCustom={errors?.mails?.[index]}
										/>
									)
								}}
							/>
						)
					})}
				</div>
				<div className="w-full mt-6">
					<Button
						type="button"
						label="Add"
						small
						onClick={() =>
							append({
								title: '',
								email: ''
							})
						}
						iconLeft={Icons.plus}
					/>
				</div>
			</div>
		</div>
	)
}
