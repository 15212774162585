import { Disclosure } from '@headlessui/react'
import {
	GET_DINAMYC_LIST,
	GET_PEOPLE_MYPEOPLE_TABLE,
	GET_PEOPLE_TAGS_TABLE,
	GET_STATIC_LIST,
	getDinamycList,
	getPeopleMyPeopleTable,
	getPeopleTagsTable,
	getStaticList,
	postContentReach
} from 'api'
import {
	Button,
	Icon,
	Individuals,
	InputRadio,
	StaticList,
	Tags,
	Typography
} from 'components'
import { useSearchDebounce } from 'hooks'
import { AudienceProps, Icons, Reach, TypographyTypes } from 'interfaces'
import { itemAudience } from 'lib'
import React, { useEffect, useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { DinamycListContent } from '../audience/dynamicList'

export const Audience: React.FC<AudienceProps> = ({
	setAddFeed,
	totalData,
	onClickClose,
	setOpenSlide,
	setTotalData,
	publishMyPeope,
	setSharePartner,
	setSlideAudience,
	setShareFMPartner,
	setPublishMyPeope
}): JSX.Element => {
	const cookies = new Cookies()
	const { pathname } = useLocation()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const [possibleReach, setPossibleReach] = useState(0)
	const [totalList, setTotalList] = useState<Reach>({
		tags: totalData.delivery_enhancements.audience.tags,
		lists: totalData.delivery_enhancements.audience.lists,
		dynamic_lists: totalData.delivery_enhancements.audience.dynamic_lists,
		individuals: totalData.delivery_enhancements.audience.individuals,
		organizations: totalData.delivery_enhancements.audience.organizations,
		feeds: totalData.delivery_enhancements.audience.feeds,
		partner_lists: totalData.delivery_enhancements.audience.partner_lists,
		organization_id: organizationId
	})

	const calculateReach = async (): Promise<void> => {
		const response = await postContentReach(totalList)
		if (response) {
			setPossibleReach(response.reach)
		}
	}

	useEffect(() => {
		calculateReach()
		setTotalData({
			...totalData,
			delivery_enhancements: {
				...totalData.delivery_enhancements,
				audience: totalList
			}
		})
	}, [totalList])

	// Tags
	const {
		inputSearch: inputSearchTags,
		handleChangeDebounce: handleChangeDebounceTags,
		resetInput: resetInputTags
	} = useSearchDebounce('')
	const {
		data: dataTags,
		fetchNextPage: fetchNextPageTags,
		isFetchingNextPage: isFetchingNextPageTags,
		isLoading: isLoadingTags
	} = useInfiniteQuery(
		[GET_PEOPLE_TAGS_TABLE, userId, organizationId, inputSearchTags],
		({ pageParam = 1 }) =>
			getPeopleTagsTable(organizationId, pageParam, inputSearchTags),
		{
			getPreviousPageParam: firstPage =>
				firstPage?.page ? firstPage.page : undefined,
			getNextPageParam: lastPage =>
				lastPage?.page ? lastPage.page + 1 : undefined
		}
	)

	// static list
	const {
		inputSearch: inputSearchStaticList,
		handleChangeDebounce: handleChangeDebounceStaticList,
		resetInput: resetInputStaticList
	} = useSearchDebounce('')
	const {
		data: dataStaticList,
		fetchNextPage: fetchNextPageStaticList,
		isFetchingNextPage: isFetchingNextPageStaticList,
		isLoading: isLoadingStaticList
	} = useInfiniteQuery(
		[GET_STATIC_LIST, userId, organizationId, inputSearchStaticList],
		({ pageParam = 1 }) =>
			getStaticList(organizationId, pageParam, inputSearchStaticList),
		{
			getPreviousPageParam: firstPage =>
				firstPage?.page ? firstPage.page : undefined,
			getNextPageParam: lastPage =>
				lastPage?.page ? lastPage.page + 1 : undefined
		}
	)

	// individuals list
	const {
		inputSearch: inputSearchIndivuduals,
		handleChangeDebounce: handleChangeDebounceIndivuduals,
		resetInput: resetInputIndivuduals
	} = useSearchDebounce('')
	const {
		data: dataIndivuduals,
		fetchNextPage: fetchNextPageIndivuduals,
		isFetchingNextPage: isFetchingNextPageIndivuduals,
		isLoading: isLoadingIndivuduals
	} = useInfiniteQuery(
		[GET_PEOPLE_MYPEOPLE_TABLE, userId, organizationId, inputSearchIndivuduals],
		({ pageParam = 1 }) =>
			getPeopleMyPeopleTable(organizationId, pageParam, inputSearchIndivuduals),
		{
			getPreviousPageParam: firstPage =>
				firstPage?.page ? firstPage.page : undefined,
			getNextPageParam: lastPage =>
				lastPage?.page ? lastPage.page + 1 : undefined
		}
	)

	const {
		inputSearch: inputSearchDynamicList,
		handleChangeDebounce: handleChangeDebounceDynamicList,
		resetInput: resetInputDydamicList
	} = useSearchDebounce('')
	const {
		data: dataDinamycList,
		fetchNextPage: fetchNextPageDinamycList,
		isFetchingNextPage: isFetchingNextPageDinamycList,
		isLoading: isLoadingDinamycList
	} = useInfiniteQuery(
		[GET_DINAMYC_LIST, userId, organizationId, inputSearchDynamicList],
		({ pageParam = 1 }) =>
			getDinamycList(organizationId, pageParam, inputSearchDynamicList),
		{
			getPreviousPageParam: firstPage =>
				firstPage?.page ? firstPage.page : undefined,
			getNextPageParam: lastPage =>
				lastPage?.page ? lastPage.page + 1 : undefined
		}
	)

	const totalListInTotalData = (): void => {
		if (publishMyPeope !== '1') {
			setTotalData({
				...totalData,
				delivery_enhancements: {
					...totalData.delivery_enhancements,
					audience: totalList
				}
			})
		} else {
			setTotalData({
				...totalData,
				delivery_enhancements: {
					...totalData.delivery_enhancements,
					audience: {
						tags: [],
						lists: [],
						dynamic_lists: [],
						individuals: [],
						organizations: [],
						feeds: [],
						partner_lists: []
					}
				}
			})
		}
	}

	const handleSubmit = (): void => {
		totalListInTotalData()
		setOpenSlide(false)
	}

	return (
		<form className="mt-5 flex flex-col justify-between h-full">
			<div>
				{itemAudience.map(item => (
					<InputRadio
						item={item}
						key={item.id}
						defaultChecked={publishMyPeope}
						setItemRecurrence={setPublishMyPeope}
					/>
				))}
				{publishMyPeope === '2' && (
					<div className="mt-4">
						<Disclosure>
							<Disclosure.Button className="w-full flex justify-between items-center mb-4">
								<div className="flex items-center gap-2">
									<Typography
										title="Select Tags:"
										className="text-gray-9"
										type={TypographyTypes.h2}
										fontLeading="18/21"
									/>
								</div>
								<div>
									<Icon
										src={Icons.arrowDown}
										className="w-4 text-gray-1"
										fillLine
									/>
								</div>
							</Disclosure.Button>
							<Disclosure.Panel className="text-gray-500 mb-4">
								<Tags
									data={dataTags}
									isLoading={isLoadingTags}
									totalList={totalList}
									resetInput={resetInputTags}
									setTotalList={setTotalList}
									fetchNextPage={fetchNextPageTags}
									isFetchingNextPage={isFetchingNextPageTags}
									handleChangeDebounce={handleChangeDebounceTags}
								/>
							</Disclosure.Panel>
						</Disclosure>
						<Disclosure>
							<Disclosure.Button className="w-full flex justify-between items-center mb-4">
								<div className="flex items-center gap-2">
									<Typography
										title="Select Static Lists:"
										className="text-gray-9"
										type={TypographyTypes.h2}
										fontLeading="18/21"
									/>
								</div>
								<div>
									<Icon
										src={Icons.arrowDown}
										className="w-4 text-gray-1"
										fillLine
									/>
								</div>
							</Disclosure.Button>
							<Disclosure.Panel className="text-gray-500 mb-4">
								<StaticList
									totalList={totalList}
									data={dataStaticList}
									setTotalList={setTotalList}
									isLoading={isLoadingStaticList}
									resetInput={resetInputStaticList}
									fetchNextPage={fetchNextPageStaticList}
									isFetchingNextPage={isFetchingNextPageStaticList}
									handleChangeDebounce={handleChangeDebounceStaticList}
									type="static"
								/>
							</Disclosure.Panel>
						</Disclosure>
						<Disclosure>
							<Disclosure.Button className="w-full flex justify-between items-center mb-4">
								<div className="flex items-center gap-2">
									<Typography
										title="Select Dynamic Lists:"
										className="text-gray-9"
										type={TypographyTypes.h2}
										fontLeading="18/21"
									/>
								</div>
								<div>
									<Icon
										src={Icons.arrowDown}
										className="w-4 text-gray-1"
										fillLine
									/>
								</div>
							</Disclosure.Button>
							<Disclosure.Panel className="text-gray-500 mb-4">
								<DinamycListContent
									data={dataDinamycList}
									isLoading={isLoadingDinamycList}
									totalList={totalList}
									resetInput={resetInputDydamicList}
									setTotalList={setTotalList}
									fetchNextPage={fetchNextPageDinamycList}
									isFetchingNextPage={isFetchingNextPageDinamycList}
									handleChangeDebounce={handleChangeDebounceDynamicList}
									type="dynamic"
								/>
							</Disclosure.Panel>
						</Disclosure>
						<Disclosure>
							<Disclosure.Button className="w-full flex justify-between items-center mb-4">
								<div className="flex items-center gap-2">
									<Typography
										title="Select Individuals:"
										className="text-gray-9"
										type={TypographyTypes.h2}
										fontLeading="18/21"
									/>
								</div>
								<div>
									<Icon
										src={Icons.arrowDown}
										className="w-4 text-gray-1"
										fillLine
									/>
								</div>
							</Disclosure.Button>
							<Disclosure.Panel className="text-gray-500 mb-4">
								<Individuals
									totalList={totalList}
									data={dataIndivuduals}
									setTotalList={setTotalList}
									isLoading={isLoadingIndivuduals}
									resetInput={resetInputIndivuduals}
									fetchNextPage={fetchNextPageIndivuduals}
									isFetchingNextPage={isFetchingNextPageIndivuduals}
									handleChangeDebounce={handleChangeDebounceIndivuduals}
									type="individuals"
								/>
							</Disclosure.Panel>
						</Disclosure>
						<div className="border border-blue-primary rounded-lg flex justify-center items-center gap-1 py-2">
							<Icon src={Icons.myPeople} className="w-6" />
							<Typography
								title="Possible Reach:"
								type={TypographyTypes.span}
								fontLeading="12/15"
								className="!font-normal text-gray-9"
							/>
							<Typography
								title={`${possibleReach}`}
								type={TypographyTypes.span}
								fontLeading="24/29"
								className="!font-bold text-blue-primary"
							/>
						</div>
						{(pathname.includes('/content/edit/') ||
							pathname.includes('/content/create')) && (
							<div className="flex flex-col gap-[15px] mt-[15px]">
								<Button
									type="button"
									color="Primary"
									label="Share with a Partner"
									className="w-full !py-[5px]"
									onClick={() => {
										setSlideAudience(false)
										setSharePartner(true)
										totalListInTotalData()
									}}
								/>
								<Button
									type="button"
									color="Transparent"
									label="Add to a Feed"
									className="w-full !py-[5px]"
									onClick={() => {
										setSlideAudience(false)
										setAddFeed(true)
									}}
								/>
								<Button
									type="button"
									color="Transparent"
									label="Share to FM Partner List"
									className="w-full !py-[5px]"
									onClick={() => {
										setSlideAudience(false)
										setShareFMPartner(true)
									}}
								/>
							</div>
						)}
					</div>
				)}
			</div>
			<div>
				<div className="flex items-center justify-between mt-2">
					<Button
						type="button"
						label="Cancel"
						color="Transparent"
						className="!min-w-[130px]"
						onClick={onClickClose}
					/>
					<Button
						label="Save"
						type="submit"
						color="Primary"
						className="!min-w-[130px]"
						onClick={handleSubmit}
						// disabled={!isValid}
					/>
				</div>
			</div>
		</form>
	)
}
