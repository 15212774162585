/**
 * Returns an empty array if the input object is undefined or has no keys;
 * otherwise, it returns the input object unchanged.
 * Used for `audience` in create content
 */
export const getArrayFromObjectAudience = <T>(obj: T[] | undefined): T[] => {
	if (!obj) return []

	return Object.keys?.(obj)?.length === 0 ? [] : obj
}
