import {
	GET_NEW_OUTBOUND_SUBSCRIPTION_TABLE,
	getNewSubscribePartnerTable,
	postNewOutboundSubscriptionTable
} from 'api'
import {
	Button,
	Input,
	NewSubscriptionRequestTable,
	TailwindModal
} from 'components/index'
import { useSearchDebounce, useToast } from 'hooks'
import {
	AcceptOutboundSubscriptionsRequest,
	GenericModalProps,
	Icons,
	NewOutboundSubscriptionResponse
} from 'interfaces'
import React, { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'

const MAX_SEARCH_LENGHT = 100

export const NewSubscriptionRequestModal: React.FC<
	GenericModalProps<unknown>
> = ({ open, setOpen, onSubmit }) => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const [partnerSubscriptionTablePage, setPartnerSubscriptionTablePage] =
		useState(1)
	const [partnersTablePageSize, setPartnersTablePageSize] = useState(10)
	const organizationId = cookies.get('organization_id')
	const [search, setSearch] = useState('')
	const { inputSearch, handleChangeDebounce, resetInput } =
		useSearchDebounce('')

	const [dataSelected, setDataSelected] = useState<number[]>([])
	const validationInput =
		search.length >= MAX_SEARCH_LENGHT
			? {
					type: 'maxLength',
					message: `Maximum ${MAX_SEARCH_LENGHT} characters`
			  }
			: undefined

	const { data, isLoading } = useQuery(
		[
			GET_NEW_OUTBOUND_SUBSCRIPTION_TABLE,
			partnerSubscriptionTablePage,
			inputSearch.trim(),
			userId,
			organizationId
		],
		() =>
			getNewSubscribePartnerTable(organizationId, {
				page: partnerSubscriptionTablePage,
				search: inputSearch.trim(),
				pageSize: partnersTablePageSize
			})
	)
	const { showToast } = useToast()

	const submitHandler = useCallback(
		async (body: AcceptOutboundSubscriptionsRequest): Promise<void> => {
			postNewOutboundSubscriptionTable(organizationId, body).then(response => {
				if (response?.message) {
					showToast('Success', `${response?.message}`, 'success')
					if (onSubmit) {
						onSubmit()
					}
					setOpen(false)
				}
			})
		},
		[]
	)

	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			title="New Outbound Subscription Request"
			className="md:w-[69.2vw] p-4 rounded-[16px] h-[65vh]"
		>
			<>
				<div className="flex flex-col gap-4 w-full mt-4 md:h-[calc(100%_-_136px)] h-[calc(100%_-_140px)] overflow-y-auto">
					<div className="flex w-full items-center gap-4">
						<Input
							name="message"
							placeholder="Search FM Feeds or Partners"
							className="!w-full !p-[unset]"
							onChange={e => {
								setSearch(e.target.value)
								handleChangeDebounce(e)
							}}
							rightIcon={Icons.cancel}
							value={search}
							rightClick={() => {
								resetInput()
								setSearch('')
							}}
							maxLength={MAX_SEARCH_LENGHT}
							error={validationInput}
							borderFull={false}
							inputClassName="!p-[unset] !py-1 !px-2"
							inputFocus
						/>
						<Button
							color="Transparent"
							label="Reset"
							small
							disabled={search.length === 0 && dataSelected.length === 0}
							onClick={() => {
								resetInput()
								setSearch('')
								setDataSelected([])
							}}
						/>
					</div>
					<NewSubscriptionRequestTable
						data={data as NewOutboundSubscriptionResponse | undefined}
						isLoading={isLoading}
						page={partnerSubscriptionTablePage}
						setPage={setPartnerSubscriptionTablePage}
						dataSelected={dataSelected}
						setDataSelected={setDataSelected}
						onItemsShowingChange={setPartnersTablePageSize}
					/>
				</div>
				<div className="flex justify-center mt-6 items-center gap-4 w-full">
					<Button
						label="Submit"
						disabled={dataSelected.length === 0}
						onClick={() => submitHandler({ feed_ids: dataSelected })}
					/>
				</div>
			</>
		</TailwindModal>
	)
}
