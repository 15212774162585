import moment from 'moment'
import 'moment-timezone'

export const formatLocalTime = (
	date: string | number,
	formatData: string
): string => {
	const timeZone: string = moment.tz.guess()
	const formattedDate: string = moment.utc(date).tz(timeZone).format(formatData)
	return formattedDate
}
