import { locationState } from 'interfaces'

export const handlePlaceSelected = (
	result: google.maps.places.PlaceResult,
	location: locationState,
	setLocation: React.Dispatch<React.SetStateAction<locationState>>
): void => {
	if (result.geometry && result.formatted_address) {
		setLocation({
			...location,
			title: location.title,
			address: result.formatted_address,
			latitude: result.geometry.location.lat(),
			longitude: result.geometry.location.lng()
		})
	}
}
