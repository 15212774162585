import { singin } from 'api'
import { Button, Input, Typography } from 'components'
import { MenuContext } from 'context'
import { useToast } from 'hooks'
import {
	FormLoginProps,
	LoginForm,
	SingigResponse,
	TypographyTypes
} from 'interfaces'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import ModalForgotPassword from './ModalForgotPassword'

export const FormLogin: React.FC<FormLoginProps> = ({
	user,
	setUser,
	setStep,
	setLoading
}): JSX.Element => {
	const { setFullName } = useContext(MenuContext)
	const cookies = new Cookies()
	const rememberName = cookies.get('remember_name')
	const navigate = useNavigate()
	const [open, setOpen] = useState(false)
	const { showToast } = useToast()
	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors }
	} = useForm<LoginForm>({
		mode: 'all'
	})
	const rules = useMemo(() => {
		return {
			email: {
				required: { value: true, message: 'This field is required' }
			},
			password: {
				required: { value: true, message: 'This field is required' }
			}
		}
	}, [])

	useEffect(() => {
		if (rememberName) {
			setValue('email', rememberName)
			setValue('remember', true)
		}
	}, [])

	const onSubmit: SubmitHandler<LoginForm> = data => {
		setLoading(true)
		const body = {
			community_id: parseInt(process.env.REACT_APP_COMMUNITY_ID as string, 10),
			signin_type: 'email',
			signin_username: data.email,
			signin_value: data.password,
			device_type: 'web'
		}
		singin(body).then((response: SingigResponse | null) => {
			setLoading(false)
			if (response?.detail) {
				showToast(response.detail, '', 'error')
			} else {
				if (data.remember) {
					cookies.set('remember_name', data.email, { path: '/' })
				} else if (rememberName && !data.remember) {
					cookies.remove('remember_name', { path: '/' })
				}
				if (response) {
					if (response.is_community_admin) {
						setFullName(response.full_name as string)
						cookies.set('fullName', response?.full_name, { path: '/' })
						cookies.set('userId', response?.id, { path: '/' })
						cookies.set('isSuperAdmin', response?.is_community_admin, {
							path: '/'
						})
						if (response.organizations && response.organizations.length > 0) {
							cookies.set(
								'organization_id',
								response?.organizations && response?.organizations[0].id,
								{
									path: '/'
								}
							)
							cookies.set(
								'organization_name',
								response?.organizations && response?.organizations[0].name,
								{
									path: '/'
								}
							)
							cookies.set(
								'organization_logo',
								response?.organizations &&
									response?.organizations[0].icon_image_url,
								{
									path: '/'
								}
							)
							cookies.set(
								'organization_slug',
								response?.organizations && response?.organizations[0].slug,
								{
									path: '/'
								}
							)
							cookies.set(
								'roles',
								response?.organizations && response?.organizations[0].roles,
								{
									path: '/'
								}
							)
						}
						cookies.set('token', response?.access_token, { path: '/' })
						navigate('/superAdmin/organization')
					} else {
						let userApp = true
						if (response?.organizations) {
							response.organizations.forEach(element => {
								if (
									element.roles.includes(2) ||
									element.roles.includes(3) ||
									element.roles.includes(4) ||
									element.roles.includes(5)
								) {
									userApp = false
								}
							})
						}
						if (userApp) {
							showToast('The user does not have permissions', '', 'error')
						} else {
							setUser({
								...user,
								email: response.email as string,
								fullName: response.full_name as string,
								userId: response.id as number,
								token: response.access_token as string
							})
							setStep(1)
						}
					}
				}
			}
		})
	}
	return (
		<>
			<form
				className="w-[386px] h-[456px] bg-blue-primary p-6 rounded-[16px] flex flex-col justify-center items-center"
				onSubmit={handleSubmit(onSubmit)}
			>
				<Typography
					title="Login"
					fontLeading="22/27"
					type={TypographyTypes.h1}
					className="text-white mb-6"
				/>
				<Input
					name="email"
					placeholder="Email"
					type="email"
					register={register}
					rules={rules.email}
					error={errors.email}
					className="!w-full !p-[unset] mb-10"
					inputClassName="min-h-[54px] !border-none !bg-white rounded-[24px] mx-[12px]"
					borderFull={false}
				/>
				<Input
					name="password"
					placeholder="Password"
					type="password"
					register={register}
					rules={rules.password}
					error={errors.password}
					className="!w-full !p-[unset] mb-10"
					inputClassName="min-h-[54px] !border-none !bg-white rounded-[24px] mx-[12px]"
					borderFull={false}
					showEye
				/>
				<div className="flex justify-center items-center gap-2 w-full mb-4">
					<input
						type="checkbox"
						className="h-4 w-4 rounded border-white text-blue-primary bg-transparent-default focus:ring-transparent-default cursor-pointer"
						{...register('remember')}
					/>
					<Typography
						title="Remember me on this device"
						fontLeading="15/18"
						type={TypographyTypes.p}
						className="!font-medium text-white"
					/>
				</div>
				<Button
					label="Log in"
					type="submit"
					color="Transparent"
					className="!min-w-[unset] min-h-[54px] !w-[168px] !py-3 !px-6 !bg-white !border-none mb-2"
					id="logIn"
				/>
				<Button
					label="Forgot password?"
					className="!min-w-[unset] !w-max !border-none !py-4 !px-6 "
					color="Primary"
					onClick={() => setOpen(!open)}
					id="forgotPassword"
				/>
			</form>
			{open && <ModalForgotPassword setOpen={setOpen} open={open} />}
		</>
	)
}
