import { createTheme } from '@mui/material'

export const checkboxTheme = createTheme({
	components: {
		MuiCheckbox: {
			styleOverrides: {
				root: {
					'&.MuiCheckbox-root': {
						padding: '0 !important'
					},
					color: '#BCBCBC',
					'&.Mui-checked': {
						color: '#8CC63E'
					},
					'&.MuiCheckbox-indeterminate': {
						color: '#8CC63E'
					}
				}
			}
		}
	}
})

export const selectTheme = createTheme({
	components: {
		MuiMenuItem: {
			styleOverrides: {
				root: {
					'&.MuiMenuItem-root': {
						display: 'flex',
						alignItems: 'center',
						gap: '12px',
						padding: '0 16px !important',
						marginBottom: '16px !important'
					},
					'&.Mui-selected': {
						backgroundColor: 'transparent !important'
					}
				}
			}
		}
	}
})
