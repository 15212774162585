import { API } from 'api/config'
import { AxiosError, AxiosResponse } from 'axios'
import { BodyPostBuildApp } from './buildApp.type'

export const saveBranchKey = async (
	body: BodyPostBuildApp,
	appBuildId: number
): Promise<AxiosResponse | string> => {
	try {
		const response = await API().patch(`/build_apps/${appBuildId}`, body)
		if (response?.data) return response

		throw new Error('An error occurred while saving the keys')
	} catch (error) {
		const axiosError = error as AxiosError
		throw new Error(
			axiosError.message ?? 'An error occurred while saving the keys'
		)
	}
}
