import { CircularProgress } from '@mui/material'
import clsx from 'clsx'
import { TagsApprovalTable, Typography } from 'components'
import {
	ParentTableProps,
	TagsApprovalResponse,
	TypographyTypes
} from 'interfaces'
import React from 'react'

export const PeopleTagsApprovalTable: React.FC<
	ParentTableProps<TagsApprovalResponse>
> = ({
	data,
	page,
	setPage,
	className,
	isLoading,
	dataSelected,
	setDataSelected,
	setOpen,
	refetch
}) => {
	return (
		<div className={clsx(className, 'flex flex-col w-full overflow-auto')}>
			{isLoading && (
				<div className="flex items-center justify-center w-full h-[calc(100vh_-_340px)] py-2 px-1 bg-white border-2 border-gray-7 rounded-lg">
					<CircularProgress disableShrink />
				</div>
			)}
			{!isLoading && data && data?.items.length === 0 && (
				<div className="flex items-center justify-center w-full h-[calc(100vh_-_340px)] py-2 px-1 bg-white border-2 border-gray-7 rounded-lg">
					<Typography
						title="You don't have any tags to approve/deny."
						type={TypographyTypes.h3}
						fontLeading="18/21"
						className="text-gray-7"
					/>
				</div>
			)}
			{!isLoading && data && data?.items.length > 0 && setOpen && (
				<TagsApprovalTable
					data={data.items}
					backendPages={data.pages}
					page={page}
					customWidth={[30, 35, 17]}
					bodyTableClassName="!h-[calc(100vh_-_510px)]"
					justify="left"
					setPage={setPage}
					dataSelected={dataSelected}
					setDataSelected={setDataSelected}
					setOpen={setOpen}
					refetch={refetch}
				/>
			)}
		</div>
	)
}
