import { API } from 'api'
import {
	BuildAppRequest,
	BuildAppResponse,
	BuildAppVersionRequest,
	BuildAppVersionResponse
} from 'interfaces'

export const createApp = async (
	body: BuildAppRequest
): Promise<BuildAppResponse | null> => {
	let res = null as BuildAppResponse | null
	await API()
		.post(`/build_apps/${process.env.REACT_APP_COMMUNITY_ID}`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const createAppVersion = async (
	versionId: string | undefined,
	body: BuildAppVersionRequest
): Promise<BuildAppVersionResponse | null> => {
	let res = null as BuildAppVersionResponse | null
	if (versionId) {
		await API()
			.post(`/build_apps/versions/${versionId}`, body)
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.error(error)
				}
			)
	}
	return res
}
