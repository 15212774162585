import { Input, InputAutoCompleteGoogleMaps, Maps } from 'components'
import { InputRange } from 'components/input/inputRange'
import { DeliveryRadiusProps, LocationContentItemForm } from 'interfaces'
import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { configGenaral } from 'utils'

export const DeliveryRadius: React.FC<DeliveryRadiusProps> = ({
	delivery,
	totalData,
	setDelivery,
	setDeliveryTitle,
	setNameLocationRadius,
	nameLocationRadius
}): JSX.Element => {
	const addressInput = useRef<HTMLInputElement>(null)
	const {
		watch,
		register,
		setValue,
		formState: { errors }
	} = useForm<LocationContentItemForm>()

	const locationName = watch('name')

	const rules = {
		name: {
			required: { value: true, message: 'This field is required' },
			maxLength: {
				value: configGenaral.titleExtraLarge,
				message: 'Maximum characters exceeded'
			}
		}
	}

	useEffect(() => {
		setDeliveryTitle(locationName)
	}, [locationName])

	const dataDelivery = totalData.delivery_enhancements.location.find(
		location => location.location_type === 'delivery'
	)
	useEffect(() => {
		if (dataDelivery?.title) {
			setValue('name', dataDelivery.title)
		} else {
			setValue('name', '')
		}
	}, [totalData, dataDelivery])
	useEffect(() => {
		if (nameLocationRadius) {
			setValue('name', nameLocationRadius)
		}
	}, [])

	return (
		<form>
			<Input
				limit
				name="name"
				type="text"
				rules={rules.name}
				register={register}
				error={errors.name}
				title="Location Name"
				watchValue={locationName}
				placeholder="Add optional Location Name"
				cantLimit={configGenaral.titleExtraLarge}
				onChangeCustom={e => {
					if (setNameLocationRadius) {
						setNameLocationRadius(e.target.value)
					}
				}}
			/>
			<InputAutoCompleteGoogleMaps
				location={delivery}
				setLocation={setDelivery}
				addressInput={addressInput}
			/>
			<Maps
				location={delivery}
				setLocation={setDelivery}
				addressInput={addressInput}
			/>
			<div className="mt-4">
				<InputRange setValue={setDelivery} value={delivery} />
			</div>
		</form>
	)
}
