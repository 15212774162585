import clsx from 'clsx'
import { Typography, handleAddTag } from 'components'
import { InputCheckProps, TypographyTypes } from 'interfaces'
import React from 'react'

export const InputCheck: React.FC<InputCheckProps> = ({
	id,
	name,
	title,
	list,
	reach,
	setList,
	className,
	reachView = true,
	isVisible,
	type,
	isStrong = false,
	hasCheckVisible = false
}): JSX.Element => {
	return (
		<div className={clsx('relative flex items-center', className)}>
			<div className="flex items-center gap-3 w-full">
				{isVisible === false ? (
					<div className="flex justify-between items-center w-full">
						<div className="flex gap-2 items-center">
							{!!hasCheckVisible && (
								<input
									id={`${title}-${id}`}
									aria-describedby="comments-description"
									name={name}
									type="checkbox"
									checked={list.includes(id)}
									onChange={() => handleAddTag(list, id, setList)}
									className="h-4 w-4 rounded border-gray-300 text-blue-primary focus:ring-0"
								/>
							)}
							<Typography
								className={clsx(
									isStrong ? 'text-black-1' : '!font-normal text-gray-9'
								)}
								title={title}
								htmlFor={`${title}-${id}`}
								type={TypographyTypes.label}
								fontLeading="15/18"
							/>
							{type !== 'dynamic' &&
								type !== 'static' &&
								type !== 'individuals' && (
									<Typography
										className="text-gray-1"
										title="(Hidden)"
										fontLeading="10/12"
										type={TypographyTypes.p}
									/>
								)}
						</div>
						{reachView && (
							<div className="border border-blue-primary rounded-lg px-3 py-[3px] min-w-[60px] text-center">
								<Typography
									title={`${reach}`}
									type={TypographyTypes.h3}
									fontLeading="15/18"
								/>
							</div>
						)}
					</div>
				) : (
					<>
						<input
							id={`${title}-${id}`}
							aria-describedby="comments-description"
							name={name}
							type="checkbox"
							checked={list.includes(id)}
							onChange={() => handleAddTag(list, id, setList)}
							className="h-4 w-4 rounded border-gray-300 text-blue-primary focus:ring-0"
						/>
						<div className="flex justify-between items-center w-full">
							<Typography
								className={clsx(
									isStrong ? 'text-black-1' : '!font-normal text-gray-9'
								)}
								title={title}
								htmlFor={`${title}-${id}`}
								type={TypographyTypes.label}
								fontLeading="15/18"
							/>

							{reachView && (
								<div className="border border-blue-primary rounded-lg px-3 py-[3px] min-w-[60px] text-center">
									<Typography
										title={`${reach}`}
										type={TypographyTypes.h3}
										fontLeading="15/18"
									/>
								</div>
							)}
						</div>
					</>
				)}
			</div>
		</div>
	)
}
