import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import Cookies from 'universal-cookie'

export const SuperAdminUser = ({
	children
}: {
	children: JSX.Element
}): JSX.Element => {
	const location = useLocation()
	const cookies = new Cookies()
	const isSuperAdmin = cookies.get('isSuperAdmin')

	if (!isSuperAdmin) {
		return <Navigate to="/" state={{ from: location }} />
	}

	return children
}
