import { Button, Icon, TailwindModal, Typography } from 'components'
import { GenericModalProps, Icons, TypographyTypes } from 'interfaces'
import React from 'react'

export const OrganizationCreatedModal: React.FC<
	// eslint-disable-next-line react/require-default-props
	GenericModalProps<unknown> & { error?: boolean }
> = ({ open, setOpen, onClickConfirm, error }) => {
	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			showCloseButton
			className="w-[360px] p-4 rounded-[6px]"
		>
			<div className="flex flex-col gap-4 w-full">
				<div className="flex flex-col gap-3 w-full items-center">
					<div className="w-[30px] h-[30px]">
						<Icon
							src={error ? Icons.warning : Icons.checkCircle}
							fillPath
							className="text-blue-primary"
						/>
					</div>
					<Typography
						title={
							error
								? 'Error creating organization'
								: 'Your organization was created.'
						}
						fontLeading="15/18"
						type={TypographyTypes.h3}
						className="!font-normal text-black-1 text-center"
					/>
				</div>
				<div className="flex justify-center items-center gap-4 w-full">
					<Button
						label="Confirm"
						onClick={onClickConfirm}
						className="!min-w-[unset] !py-3 !px-9"
					/>
				</div>
			</div>
		</TailwindModal>
	)
}
