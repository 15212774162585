import { Popover } from '@headlessui/react'
import { CircularProgress } from '@mui/material'
import { handleTag } from 'api'
import clsx from 'clsx'
import { Button, Icon, TailwindPopOver, Typography } from 'components'
import { useToast } from 'hooks'
import {
	FollowerTagParent,
	FollowersTagCellProps,
	Icons,
	TagApprovalRequest,
	TagsChild,
	TypographyTypes
} from 'interfaces'
import React, { useCallback, useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import Cookies from 'universal-cookie'

export const FollowersTagCell: React.FC<FollowersTagCellProps> = ({
	followerTags,
	button,
	className,
	setOpen,
	refetch
}) => {
	const cookies = new Cookies()
	const organizationId = cookies.get('organization_id')
	const [referenceElement, setReferenceElement] =
		useState<HTMLButtonElement | null>(null)
	const [defaultTag, setDefaultTag] = useState<TagsChild[] | null>()
	const [countTag, setCountTag] = useState<number>(0)
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
		null
	)
	const [valueItemIndependent, setValueItemIndependent] = useState(0)
	const [isLoading, setIsLoading] = useState(false)
	const { styles, attributes } = usePopper(referenceElement, popperElement)
	const { showToast } = useToast()

	const tagHandler = useCallback(
		async (tagId: number, body: TagApprovalRequest) => {
			setIsLoading(true)
			handleTag(organizationId, tagId, body)
				.then(response => {
					if (response) {
						showToast(
							'Success',
							`Tag selected ${body.action} successfully.`,
							'success'
						)
						if (setOpen) {
							setOpen(false)
						}
						if (refetch) {
							refetch()
						}
					}
				})
				.finally(() => {
					setIsLoading(false)
				})
		},
		[]
	)

	useEffect(() => {
		if (followerTags) {
			const arrayParentTags = followerTags.organization_follower_parent_tags
			const arrayIndependentTags =
				followerTags.organization_follower_independent_tags
			if (arrayParentTags.length > 0) {
				const filteredTagsParent = arrayParentTags.filter(
					item => item.tag_name !== undefined && item.tag_name !== ''
				)
				if (filteredTagsParent.length > 0) {
					setDefaultTag(filteredTagsParent[0].tags_child)
					setValueItemIndependent(filteredTagsParent.length)
				}
			} else if (arrayIndependentTags) {
				const filteredTags = arrayIndependentTags.filter(
					item => item.tag_name !== undefined && item.tag_name !== ''
				)
				if (filteredTags.length > 0) {
					setDefaultTag(filteredTags)
					setValueItemIndependent(filteredTags.length)
				}
			}
			const counterTagChildsParents = arrayParentTags.reduce(
				(a, b) => a + b.tags_child.length,
				0
			)
			const totalQuantityTags =
				counterTagChildsParents + arrayIndependentTags.length
			setCountTag(totalQuantityTags - 1)
		}
	}, [followerTags])

	return (
		<div className="flex gap-2 items-center">
			<Popover className={className}>
				<div className="flex gap-1">
					{followerTags && valueItemIndependent > 0 && (
						<Popover.Button
							className={clsx(
								'flex items-center justify-center p-1 border border-blue-primary rounded-[8px] max-h-[25px] gap-1',
								!defaultTag?.[0].is_visible ? 'max-w-[100px]' : 'w-[64px]',
								!countTag && 'w-[100px]'
							)}
							ref={setReferenceElement}
						>
							<Typography
								key="0"
								title={defaultTag?.[0]?.tag_name ?? ''}
								fontLeading="12/15"
								type={TypographyTypes.span}
								className={clsx(
									'!font-normal text-blue-primary truncate',
									!defaultTag?.[0].is_visible && !countTag
										? 'max-w-[70px]'
										: !defaultTag?.[0].is_visible
										? 'max-w-[38px]'
										: 'max-w-[80px]'
								)}
							/>
							{!defaultTag?.[0].is_visible && (
								<Icon
									src={Icons.visibilityOff}
									fillPath
									className="w-4 h-4 text-gray-1"
								/>
							)}
						</Popover.Button>
					)}
					{countTag > 0 && (
						<Popover.Button
							className="flex items-center justify-center p-1 border border-blue-primary rounded-3xl w-[35px] h-[25px]"
							ref={setReferenceElement}
						>
							<Typography
								key="0"
								title={`+${countTag}`}
								fontLeading="12/15"
								type={TypographyTypes.span}
								className="!font-normal text-blue-primary "
							/>
						</Popover.Button>
					)}
				</div>
				<Popover.Panel
					className="absolute z-10 !-left-[35px]"
					ref={setPopperElement}
					style={styles.popper}
					{...attributes.popper}
				>
					{({ close }) => (
						<TailwindPopOver
							handleClose={close}
							title="Follower Tags"
							className={clsx(
								'w-[185px] h-max rounded-[16px] border-2 border-gray-3 gap-1 ',
								isLoading && 'flex items-center justify-center'
							)}
						>
							{!isLoading ? (
								<div className={clsx(button ? 'flex-col' : 'flex-row gap-1')}>
									{followerTags?.organization_follower_parent_tags?.map(
										(tagParent: FollowerTagParent) => {
											return (
												<div
													className="border-b-[1px] border-gray-3 pb-2"
													key={`${tagParent.organization_follower_tag_id}_${tagParent.tag_name}`}
												>
													<div className="flex pb-2 gap-1">
														<div className="truncate max-w-[140px] font-bold">
															{tagParent.tag_name}
														</div>
														<div>
															{!tagParent.is_visible && (
																<Icon
																	src={Icons.visibilityOff}
																	fillPath
																	className="w-4 h-4 text-gray-1"
																/>
															)}
														</div>
													</div>
													{tagParent.tags_child.map((tagChild: TagsChild) => {
														return (
															<div className="py-[3px] ">
																<div
																	className="flex items-center justify-center gap-1 p-1 border border-blue-primary rounded-[8px] w-max "
																	key={`${tagChild.organization_follower_tag_id}_${tagChild.tag_name}`}
																>
																	<div className="justify-between">
																		<Typography
																			title={tagChild.tag_name}
																			fontLeading="12/15"
																			type={TypographyTypes.p}
																			className={clsx(
																				'!font-normal  text-blue-primary truncate max-w-[115px]'
																			)}
																		/>
																	</div>
																	{!tagChild.is_visible && (
																		<Icon
																			src={Icons.visibilityOff}
																			fillPath
																			className="w-4 h-4 text-gray-1"
																		/>
																	)}
																	{button && (
																		<>
																			<Button
																				label=""
																				className="!min-w-[unset] !w-4 !h-4 !px-[unset] !py-[unset]"
																				iconLeft={Icons.check}
																				leftIconDivClassName="!w-2 !h-2"
																				onClick={() =>
																					tagHandler(
																						tagChild.organization_follower_tag_id,
																						{ action: 'approved' }
																					)
																				}
																			/>
																			<Button
																				label=""
																				className="!min-w-[unset] !w-4 !h-4 !px-[unset] !py-[unset]"
																				color="Lavender"
																				iconLeft={Icons.closeGeneric}
																				leftIconDivClassName="!w-2 !h-2"
																				onClick={() =>
																					tagHandler(
																						tagChild.organization_follower_tag_id,
																						{ action: 'declined' }
																					)
																				}
																			/>
																		</>
																	)}
																</div>
															</div>
														)
													})}
												</div>
											)
										}
									)}
									{followerTags?.organization_follower_independent_tags?.map(
										independentTag => {
											return (
												<div className="border-black-1 pt-2">
													<div
														className="flex items-center justify-center gap-1 p-1 border border-blue-primary rounded-[8px] w-max"
														key={`${independentTag.organization_follower_tag_id}_${independentTag.tag_name}`}
													>
														<div className="flex gap-1">
															<Typography
																title={independentTag.tag_name}
																fontLeading="12/15"
																type={TypographyTypes.p}
																className={clsx(
																	'!font-normal  text-blue-primary truncate max-w-[115px]'
																)}
															/>
															{!independentTag.is_visible && (
																<Icon
																	src={Icons.visibilityOff}
																	fillPath
																	className="w-4 h-4 text-gray-1"
																/>
															)}
														</div>
														{button && (
															<>
																<Button
																	label=""
																	className="!min-w-[unset] !w-4 !h-4 !px-[unset] !py-[unset]"
																	iconLeft={Icons.check}
																	leftIconDivClassName="!w-2 !h-2"
																	onClick={() =>
																		tagHandler(
																			independentTag.organization_follower_tag_id,
																			{ action: 'approved' }
																		)
																	}
																/>
																<Button
																	label=""
																	className="!min-w-[unset] !w-4 !h-4 !px-[unset] !py-[unset]"
																	color="Lavender"
																	iconLeft={Icons.closeGeneric}
																	leftIconDivClassName="!w-2 !h-2"
																	onClick={() =>
																		tagHandler(
																			independentTag.organization_follower_tag_id,
																			{ action: 'declined' }
																		)
																	}
																/>
															</>
														)}
													</div>
												</div>
											)
										}
									)}
								</div>
							) : (
								<div>
									<CircularProgress disableShrink size={16} />
								</div>
							)}
						</TailwindPopOver>
					)}
				</Popover.Panel>
			</Popover>
		</div>
	)
}
