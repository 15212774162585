export const typePropierty = [
	{
		name: 'Properties',
		value: 'Properties'
	},
	{
		name: 'Name',
		value: 'full_name'
	},
	{
		name: 'Status',
		value: 'status'
	},
	{
		name: 'Email',
		value: 'email'
	},
	{
		name: 'OS',
		value: 'device_type'
	},
	{
		name: 'ZIP/Postal',
		value: 'postal_code'
	},
	{
		name: 'Push On',
		value: 'notification_push'
	},
	{
		name: 'Loc On',
		value: 'active_location'
	},
	{
		name: 'Sign Up Date',
		value: 'created_at'
	},
	{
		name: 'Tag',
		value: 'tag'
	}
]

export const typeOperator = [
	{
		name: 'Operator',
		value: 'Operator'
	},
	{
		name: 'Is',
		value: '='
	},
	{
		name: 'Is Not',
		value: '!='
	}
]
export const typeOperatorDiffDate = [
	...typeOperator,
	{
		name: 'Contains',
		value: 'ilike'
	},
	{
		name: 'Does Not Contain',
		value: 'not like'
	}
]

export const typeOperatorSigupDate = [
	...typeOperator,
	{
		name: 'After',
		value: '>'
	},
	{
		name: 'Before',
		value: '<'
	}
]

export const allOptionsOperator = [
	...typeOperatorDiffDate,
	...typeOperatorSigupDate
]

export const typeOperatorPushAndLoc = [
	{
		name: 'Is',
		value: '='
	}
]

export const typePushAndLoc = [
	{
		name: 'Yes',
		value: 'yes'
	},
	{
		name: 'No',
		value: 'no'
	}
]

export const typeAndOR = [
	{
		name: 'And',
		value: 'and'
	},
	{
		name: 'OR',
		value: 'or'
	}
]

export const typeStatus = [
	{
		name: 'Active',
		value: 'active'
	},
	{
		name: 'Block',
		value: 'block'
	},
	{
		name: 'Inactive',
		value: 'inactive'
	}
]

export const typeOS = [
	{
		name: 'Android',
		value: 'android'
	},
	{
		name: 'iOS',
		value: 'ios'
	},
	{
		name: 'Web',
		value: 'web'
	},
	{
		name: 'Not logged in',
		value: 'not_loged_in'
	}
]
