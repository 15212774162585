import { API } from 'api'
import { locationCreate } from 'api/post/location'
import {
	LocationByIDResponse,
	LocationListResponse,
	LocationListsTableItem,
	LocationListsTableResponse,
	TypeOrder
} from 'interfaces'
import { configGenaral } from 'utils'

export const getLocationsList = async (
	organizationId: number,
	page: number,
	search: string,
	column: string,
	order: TypeOrder,
	size?: number
): Promise<LocationListResponse | undefined> => {
	let res = undefined as LocationListResponse | undefined
	const getSize = size ?? configGenaral.sizeElementGet
	const getSearch = search !== '' ? `&search=${search}` : ''
	const getColumn = column !== '' ? `&column_order=${column}` : ''
	const getOrder = order !== null ? `&order_by=${order}` : ''
	await API()
		.get(
			`${locationCreate.CREATE}/${organizationId}?size=${getSize}&page=${page}${getSearch}${getColumn}${getOrder}`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
				res = error.response.data
			}
		)
	return res
}

export const getLocationById = async (
	organizationId: number,
	LocationId: number | undefined
): Promise<LocationByIDResponse | undefined> => {
	let res = undefined as LocationByIDResponse | undefined
	if (LocationId) {
		await API()
			.get(`/locations/${LocationId}/organizations/${organizationId}`)
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.error(error)
					res = error.response.data
				}
			)
	}
	return res
}

export const getLocationsListToTable = async (
	organizationId: number,
	filters?: { page?: number; search?: string }
): Promise<LocationListsTableResponse | null> => {
	let res = null as LocationListsTableResponse | null
	await API()
		.get(`/location_list/${organizationId}`, {
			params: { page: filters?.page, search: filters?.search }
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getLocationsListFollowers = async (
	organizationId: number
): Promise<LocationListsTableResponse | null> => {
	let res = null as LocationListsTableResponse | null
	await API()
		.get(`/location_list/${organizationId}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getLocationsListById = async (
	organizationId: number,
	idList: number
): Promise<LocationListsTableItem | null> => {
	let res = null as LocationListsTableItem | null
	await API()
		.get(`/location_list/${idList}/organizations/${organizationId}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}
