import { Button, Icon, Typography } from 'components'
import { Icons, PixelCrop, TypographyTypes } from 'interfaces'
import React from 'react'

interface ImageSettingsProps {
	handleToggleAspectClick: (aspect: number, aspectString: string) => void
	rotate: number
	setRotate: React.Dispatch<React.SetStateAction<number>>
	scale: number
	setScale: React.Dispatch<React.SetStateAction<number>>
	handleControlZoom: () => void
	completedCrop: PixelCrop // You may need to adjust the type
	aspectString: string | undefined
}

export const ImageCropSettings: React.FC<ImageSettingsProps> = ({
	handleToggleAspectClick,
	rotate,
	setRotate,
	scale,
	setScale,
	handleControlZoom,
	completedCrop,
	aspectString
}) => {
	const square = 1 / 1
	const portrait = 1 / 1.2941
	const landscape = 16 / 9
	return (
		<div className="pb-2 border-b-2 border-gray-3">
			<Typography
				fontLeading="15/18"
				type={TypographyTypes.p}
				className="text-black-1 my-2 pl-2.5"
				title="Settings:"
			/>
			<div className="flex flex-row">
				<div className="flex flex-col border-blue-primary justify-center w-[50%] border-solid border-[3px] rounded-lg m-2 p-2">
					<Typography
						fontLeading="15/18"
						type={TypographyTypes.p}
						className="!font-normal text-black-1 pl-[12px]"
						title="Image ratio:"
					/>
					<div className="flex flex-col gap-2 justify-center items-center pt-2">
						<Button
							type="button"
							onClick={() => handleToggleAspectClick(square, '1/1')}
							className="min-w-[192px] items-center border-blue-primary border-solid border-[3px] !py-1"
							label="Square"
							color={aspectString === '1/1' ? 'Primary' : 'Transparent'}
							iconRight={Icons.square}
						/>

						<Button
							type="button"
							onClick={() => handleToggleAspectClick(portrait, '1/1.2941')}
							className="min-w-[192px] border-blue-primary border-solid border-[3px] !py-1"
							label="Portrait"
							color={aspectString === '1/1.2941' ? 'Primary' : 'Transparent'}
							iconRight={Icons.portrait}
						/>
						<Button
							type="button"
							onClick={() => handleToggleAspectClick(landscape, '16/9')}
							className="min-w-[192px] border-blue-primary border-solid border-[3px] !py-1"
							label="Landscape"
							color={aspectString === '16/9' ? 'Primary' : 'Transparent'}
							iconRight={Icons.landscape}
						/>
					</div>
				</div>
				<div className="flex flex-col border-blue-primary justify-center w-[50%] border-solid border-[3px] rounded-lg m-2 p-2">
					<div>
						<Typography
							fontLeading="15/18"
							type={TypographyTypes.p}
							className="!font-normal text-black-1 pl-[12px]"
							title="Image Options:"
						/>
					</div>
					<div className="flex flex-col justify-center items-center gap-2 pt-2">
						<div className="flex w-full items-center justify-evenly ">
							<Typography
								fontLeading="15/18"
								type={TypographyTypes.p}
								className="!font-normal text-black-1 w-[38px] h"
								title="Rotate"
							/>
							<Button
								type="button"
								value={rotate}
								onClick={() => {
									setRotate(prevRotate => prevRotate - 1)
								}}
								className="min-w-[56px] border-solid border-[3px] !py-1"
								color="Transparent"
								disabled={!completedCrop}
							>
								<Icon src={Icons.rotateLeft} className="w-[18px]	h-[18px]" />
							</Button>
							<Button
								type="button"
								value={rotate}
								onClick={() => setRotate(prevRotate => prevRotate + 1)}
								className="min-w-[56px] border-solid border-[3px] !py-1"
								color="Transparent"
								disabled={!completedCrop}
							>
								<Icon src={Icons.rotateRight} className="w-[18px]	h-[18px]" />
							</Button>
						</div>
						{/* Flip feature for future implementation */}
						{/* <div className="flex w-full items-center justify-evenly">
														<Typography
															fontLeading={'15/18'}
															type={TypographyTypes.p}
															className="!font-normal text-black-1 w-[38px] "
															title="Flip"
														/>
														<Button
															type="button"
															className="min-w-[56px] border-solid border-[3px] !py-1"
															color="Transparent"
															// onClick={handleFlipVertical}
															disabled={!completedCrop}
														>
															<Icon
																src={Icons.flipVertical}
																className="w-[18px]	h-[18px]"
															/>
														</Button>
														<Button
															type="button"
															className="min-w-[56px] border-solid border-[3px] !py-1"
															color="Transparent"
															// onClick={handleFlipHorizontal}
															disabled={!completedCrop}
														>
															<Icon
																src={Icons.flipHorizontal}
																className="w-[18px]	h-[18px]"
															/>
														</Button>
													</div> */}
						<div className="flex w-full items-center justify-evenly">
							<Typography
								fontLeading="15/18"
								type={TypographyTypes.p}
								className="!font-normal text-black-1 w-[38px]"
								title="Scale"
							/>
							<Button
								type="button"
								value={scale}
								onClick={() => setScale(prevScale => prevScale + 0.01)}
								className="min-w-[56px] border-solid border-[3px] min-h-[30px] !py-1"
								color="Transparent"
								disabled={!completedCrop}
							>
								<Icon src={Icons.zoomIn} className="w-[18px]	h-[18px]" />
							</Button>
							<Button
								type="button"
								value={scale}
								onClick={() => {
									setScale(prevScale => prevScale - 0.01)
									handleControlZoom()
								}}
								className="min-w-[56px] border-solid border-[3px] bottom-1 !py-1"
								color="Transparent"
								disabled={!completedCrop}
							>
								<Icon src={Icons.zoomOut} className="w-[18px]	h-[18px]" />
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
