import { CircularProgress } from '@mui/material'
import {
	GET_FM_PARTNER_LIST_FOLLOWERS_PARTNERS,
	getFMPartnerListPartners
} from 'api'
import { BoxPartners, Button, Input } from 'components'
import { useSearchDebounce } from 'hooks'
import {
	FMPartnerListPartnersItem,
	Icons,
	SharePartnerInputs,
	SharePartnerProps
} from 'interfaces'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useInView } from 'react-intersection-observer'
import { useInfiniteQuery } from 'react-query'
import Cookies from 'universal-cookie'

export const SharePartner: React.FC<SharePartnerProps> = ({
	totalData,
	setTotalData,
	onClickClose,
	setSharePartner,
	setSlideAudience
}): JSX.Element => {
	const cookies = new Cookies()
	const { ref, inView } = useInView()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const [listOrganizations, setListOrganizations] = useState<
		FMPartnerListPartnersItem[]
	>([])
	const [partnersSelected, setPartnersSelected] = useState<number[]>([])

	const { watch, register, handleSubmit } = useForm<SharePartnerInputs>({
		mode: 'all'
	})

	const watchSearch = watch('search')
	const { inputSearch, handleChangeDebounce } = useSearchDebounce(watchSearch)
	const { data, fetchNextPage, isFetchingNextPage, isLoading } =
		useInfiniteQuery(
			[
				GET_FM_PARTNER_LIST_FOLLOWERS_PARTNERS,
				userId,
				organizationId,
				inputSearch
			],
			({ pageParam = 1 }) =>
				getFMPartnerListPartners(organizationId, {
					page: pageParam,
					search: inputSearch
				}),
			{
				getPreviousPageParam: firstPage =>
					firstPage?.page ? firstPage.page : undefined,
				getNextPageParam: lastPage =>
					lastPage?.page ? lastPage.page + 1 : undefined
			}
		)

	useEffect(() => {
		if (inView) {
			if (
				data &&
				data.pages[0] &&
				data.pages[0].pages > data.pageParams.length
			) {
				fetchNextPage()
			}
		}
	}, [inView])

	useEffect(() => {
		if (data?.pages) {
			let aux = [] as FMPartnerListPartnersItem[]
			data.pages.forEach(page => {
				if (page) {
					aux = [...aux, ...page.items]
				}
				setListOrganizations(aux)
			})
		}
	}, [data])

	const handleClickPartner = (partner: FMPartnerListPartnersItem): void => {
		let newPartners = partnersSelected
		if (partnersSelected.includes(parseInt(partner.partner_id, 10))) {
			newPartners = partnersSelected.filter(
				item => item !== parseInt(partner.partner_id, 10)
			)
		} else {
			newPartners.push(parseInt(partner.partner_id, 10))
		}
		setPartnersSelected([...newPartners])
	}

	const onSubmit: SubmitHandler<SharePartnerInputs> = () => {
		setTotalData({
			...totalData,
			delivery_enhancements: {
				...totalData.delivery_enhancements,
				audience: {
					...totalData.delivery_enhancements.audience,
					organizations: partnersSelected
				}
			}
		})
		setSharePartner(false)
		setSlideAudience(true)
	}

	useEffect(() => {
		const aux = [] as number[]
		totalData.delivery_enhancements.audience.organizations.forEach(item => {
			aux.push(item)
		})
		setPartnersSelected(aux)
	}, [totalData])

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="pt-5 h-full">
			<div className="h-[93%]">
				<Input
					name="search"
					className="mb-4"
					borderFull={false}
					register={register}
					leftIcon={Icons.search}
					inputClassName="rounded-[10px]"
					placeholder="Search your partners"
					onChangeCustom={handleChangeDebounce}
				/>
				<div className="overflow-y-scroll  hide-scroll-bar max-h-[90%] pt-3">
					{isLoading ? (
						<div className="flex items-center justify-center w-full h-[400px] py-2 px-1 ">
							<CircularProgress disableShrink />
						</div>
					) : (
						listOrganizations?.map(item => (
							<BoxPartners
								key={item.partner_id}
								id={item.partner_id}
								image={item.icon_image_url}
								title={item.partner_name}
								itemsSeleted={partnersSelected}
								onClick={() => handleClickPartner(item)}
							/>
						))
					)}
					<div ref={ref}>
						{isFetchingNextPage && <CircularProgress disableShrink />}
					</div>
				</div>
			</div>

			<div className="flex items-center justify-between">
				<Button
					type="button"
					label="Cancel"
					color="Transparent"
					className="!min-w-[130px]"
					onClick={onClickClose}
				/>
				<Button
					label="Save"
					type="submit"
					color="Primary"
					className="!min-w-[130px]"
					disabled={partnersSelected.length === 0}
				/>
			</div>
		</form>
	)
}
