import { CircularProgress } from '@mui/material'
import { GET_ORGANIZATIONS } from 'api'
import { getOrganizations } from 'api/get/superAdminDashboard/superAdminDashboard'
import {
	Button,
	Input,
	LastOrganizationsList,
	OrganizationsTable,
	SuperAdminLayout,
	Title,
	Typography
} from 'components'
import { BuildAppsContext } from 'context'
import { useSearchDebounce } from 'hooks'
import {
	GetOrganizationsResponse,
	Icons,
	OrganizationType,
	TypographyTypes
} from 'interfaces'
import React, { useContext, useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useQuery } from 'react-query'

export const SuperAdminDashboard = (): JSX.Element => {
	const [tablePage, setTablePage] = useState(1)
	const [pageLimit, setPageLimit] = useState(10)
	const [pageSearch, setPageSearch] = useState(1)
	const { isChangeData } = useContext(BuildAppsContext)

	const { data: lastOrganizations, isLoading: isLastOrganizationsLoading } =
		useQuery(
			[
				GET_ORGANIZATIONS,
				{ orderBy: 'desc', columnOrder: 'last_content', page: 1 }
			],
			() =>
				getOrganizations({
					orderBy: 'desc',
					columnOrder: 'last_content',
					page: 1
				}),
			{ staleTime: Infinity }
		)

	const { register, control, resetField } = useForm<{ search: string }>({
		mode: 'onChange'
	})

	const watchSearch = useWatch({
		control,
		name: 'search'
	})

	const { inputSearch, handleChangeDebounce, resetInput } =
		useSearchDebounce(watchSearch)
	const isSearching = inputSearch.length > 1

	const {
		data: organizations,
		isLoading: isLoadingOrganizations,
		isRefetching,
		refetch
	} = useQuery(
		[
			GET_ORGANIZATIONS,
			isChangeData,
			pageSearch,
			{
				columnOrder: 'name',
				orderBy: 'desc',
				page: tablePage,
				search: inputSearch.trim(),
				size: pageLimit
			}
		],
		() =>
			getOrganizations({
				columnOrder: 'name',
				orderBy: 'desc',
				page: isSearching ? pageSearch : tablePage,
				search: inputSearch.trim(),
				size: pageLimit
			}),
		{
			refetchOnWindowFocus: false
		}
	)

	useEffect(() => {
		if (!isSearching) {
			setPageSearch(1)
		}
	}, [isSearching])

	const formatOrganzations = (
		data?: GetOrganizationsResponse | null
	): OrganizationType[] =>
		data?.items?.map(org => ({
			address: org.address,
			buildApp: `${org.build_app_id}-${org.build_app}`,
			contents: org.contents,
			id: org.id,
			email: org.admin_email || org.email || '',
			lastContent: org.last_content,
			logo: org.icon_image_url,
			name: org.name,
			phoneNumber: org.phone_number,
			users: org.users,
			shareUrl: org.share_url,
			slug: org.slug,
			organization_type: org?.organization_type ?? null
		})) || []

	return (
		<SuperAdminLayout>
			<div className="3xl:mx-auto 3xl:my-auto 3xl:w-3/4 mx-auto">
				<Title
					title="Organizations"
					subtitle="Manage Your Organizations"
					icon={Icons.dashboard}
				/>
				{isLoadingOrganizations && isLastOrganizationsLoading ? (
					<div className="flex justify-center items-center min-h-[60vh]">
						<CircularProgress disableShrink />
					</div>
				) : (
					<>
						{formatOrganzations(lastOrganizations).length === 0 ? (
							<div className="flex justify-center">
								<Typography
									fontLeading="12/15"
									title="You don't have any organizations."
									type={TypographyTypes.span}
									className="my-28 !text-gray-1 !font-normal"
								/>
							</div>
						) : (
							<LastOrganizationsList
								organizations={formatOrganzations(lastOrganizations)}
								styles="mt-6"
							/>
						)}
						<div className="flex items-center justify-between py-3 gap-8 w-full">
							<Input
								rightClick={() => {
									resetInput()
									resetField('search')
								}}
								name="search"
								onChange={handleChangeDebounce}
								search
								register={register}
								className="w-full"
								placeholder="Search an organization"
								rightIcon={Icons.cancel}
								inputClassName="!p-[unset] !py-1 !px-2"
								inputFocus
								borderFull={false}
								onChangeCustom={() => {
									if (tablePage !== 1) {
										setPageSearch(1)
									}
								}}
							/>
							<Button
								linkTo="/superAdmin/organization/create"
								label="New Organization"
								color="Primary"
								className="py-2"
							/>
						</div>
						<div className="overflow-x-auto">
							<OrganizationsTable
								key={tablePage}
								noDataMessage={"You don't have any organizations."}
								organizations={formatOrganzations(organizations)}
								isFetching={isLoadingOrganizations || isRefetching}
								showPagination
								onPageChange={isSearching ? setPageSearch : setTablePage}
								page={isSearching ? pageSearch : tablePage}
								total={organizations?.total}
								pages={organizations?.size}
								itemsShowing={pageLimit}
								onItemsShowingChange={setPageLimit}
								refetchOrganizations={refetch}
							/>
						</div>
					</>
				)}
			</div>
		</SuperAdminLayout>
	)
}
