import { Button } from 'components/buttons'
import { Icon } from 'components/icon'
import { Typography } from 'components/typography'
import { Icons, ModalExportSuccesProps, TypographyTypes } from 'interfaces'
import React from 'react'
import { TailwindModal } from '../tailwindModal'

const ExportDataSuccesModal: React.FC<ModalExportSuccesProps> = ({
	open,
	setOpen,
	setActivateExport,
	setMyPeopleDataSelected
}) => {
	const handleConfirmClick = (): void => {
		setOpen(!open)
		setActivateExport(false)
		setMyPeopleDataSelected([])
	}
	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			showCloseButton
			className="w-[22.380rem] p-4 rounded-[16px] flex flex-col"
		>
			<div className="flex flex-col gap-4 w-full">
				<div className="flex flex-col gap-3 w-full items-center">
					<div className="w-[30px] h-[30px]">
						<Icon
							src={Icons.checkCircle}
							fillPath
							className="text-blue-primary"
						/>
					</div>

					<Typography
						title="Your export request was successful! 
                         We will send the CSV file containing the data of your selected users to your email."
						fontLeading="15/18"
						type={TypographyTypes.h3}
						className="!font-normal text-black-1 text-center"
					/>
				</div>
				<div className="flex justify-center items-center gap-4 w-full">
					<Button
						label="OK"
						className="!min-w-[unset] !py-3 !px-9"
						onClick={() => handleConfirmClick()}
					/>
				</div>
			</div>
		</TailwindModal>
	)
}

export default ExportDataSuccesModal
