import { API } from 'api/config'
import {
	EditContentListResponse,
	ExistingUserEdit,
	GetAdminExisting,
	GetAdminOrganizationResponse,
	GetEmailUsing,
	GetOrganizationDataResponse,
	GetOrganizationsResponse,
	GetUserAdmin,
	UserAdmin
} from 'interfaces'

export const getOrganizations = async (filters?: {
	columnOrder?: string
	orderBy?: string
	page?: number
	search?: string
	size?: number
}): Promise<GetOrganizationsResponse | null> => {
	let res = null as GetOrganizationsResponse | null
	await API()
		.get('/organizations', {
			params: {
				column_order: filters?.columnOrder,
				order_by: filters?.orderBy,
				page: filters?.page,
				search: filters?.search,
				size: filters?.size
			}
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getOrganizationInfo = async (
	id: number
): Promise<GetOrganizationDataResponse | null> => {
	let res = null as GetOrganizationDataResponse | null
	await API()
		.get(`/organization/${id}/super_admin`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)

	return res
}

export const getAdminOrganization = async (
	id: number
): Promise<GetAdminOrganizationResponse | null> => {
	let res = null as GetAdminOrganizationResponse | null
	await API()
		.get(`/super_admin/organization/${id}/users`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)

	return res
}

export const getAdminExisting = async (
	email: string
): Promise<GetAdminExisting | null> => {
	let res = null as GetAdminExisting | null

	await API()
		.get(`/user/validate_user?email=${email}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)

	return res
}

export const createNewAdminOrganization = async (
	id: number,
	body: UserAdmin
): Promise<EditContentListResponse | null> => {
	let res = null as EditContentListResponse | null
	await API()
		.post(`/super_admin/organization/${id}/user`, body)
		.then(async response => {
			res = response.data
		})
		.catch(error => {
			res = { detail: error.response.data.detail, message: '' }
		})
	return res
}

export const deleteAdminService = async (
	id: number,
	userId: number
): Promise<EditContentListResponse | null> => {
	let res = null as EditContentListResponse | null
	await API()
		.delete(`/super_admin/organization/${id}/user/${userId}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getUserAdmin = async (
	idOrganization: number,
	idUser: number
): Promise<GetUserAdmin | null> => {
	let res = null as GetUserAdmin | null

	await API()
		.get(`/super_admin/organization/${idOrganization}/user/${idUser}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)

	return res
}

export const patchContentEditAdmin = async (
	organizationId: number,
	body: ExistingUserEdit,
	contentId?: number
): Promise<EditContentListResponse | null> => {
	let res = null as EditContentListResponse | null
	if (contentId) {
		await API()
			.patch(
				`/super_admin/organization/${organizationId}/user/${contentId}`,
				body
			)
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.error(error)
					res = error.response.data
				}
			)
	}
	return res
}
export const getEmailUsing = async (
	email: string,
	idUser: number
): Promise<GetEmailUsing | null> => {
	let res = null as GetEmailUsing | null
	if (idUser) {
		await API()
			.get(
				`/user/${idUser}/community/${process.env.REACT_APP_COMMUNITY_ID}?email=${email}`
			)
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.error(error)
				}
			)
	}
	return res
}
