import { Disclosure } from '@headlessui/react'
import { CircularProgress } from '@mui/material'
import {
	GET_EDIT_LOCATION,
	GET_LOCATIONS,
	getLocationById,
	getLocationsList
} from 'api'
import {
	Button,
	DeliveryRadius,
	Icon,
	InputRadio,
	LocationContentItem,
	Typography
} from 'components'
import {
	Icons,
	ItemSelect,
	LocationProps,
	TypographyTypes,
	locationState
} from 'interfaces'
import { ItemRadios, itemDefineLocation, itemDefineLocationCreate } from 'lib'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import Select from 'react-select'
import Cookies from 'universal-cookie'

export const Location: React.FC<LocationProps> = ({
	totalData,
	onClickClose,
	setTotalData,
	setOpenSlide,
	deliveryActive,
	setDeliveryActive,
	isLoading,
	defineTypeLocation,
	setDefineTypeLocation,
	mode
}): JSX.Element => {
	const [updateItem, setUpdateItem] = useState(0)
	const [updateDelivery, setUpdateDelivery] = useState(0)
	const [currentTypeLocation, setCurrentTypeLocation] = useState<
		string | undefined
	>(undefined)

	useEffect(() => {
		if (defineTypeLocation) setCurrentTypeLocation(defineTypeLocation)
	}, [])

	const initialState = {
		title: '',
		address: '',
		location_type: 'item',
		latitude: 0,
		longitude: 0
	}
	const [item, setItem] = useState<locationState>(initialState)

	const [delivery, setDelivery] = useState<locationState>({
		title: '',
		address: '',
		location_type: 'delivery',
		radius: 1,
		latitude: 0,
		longitude: 0
	})

	const [itemTitle, setItemTitle] = useState('')
	const [deliveryTitle, setDeliveryTitle] = useState('')
	const [nameLocation, setNameLocation] = useState<string | undefined>()
	const [nameLocationRadius, setNameLocationRadius] = useState<string>()
	const cookies = new Cookies()
	const [itemSelected, setItemSelected] = useState<ItemRadios[]>([])

	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const [isDisabled, setIsDisabled] = useState(false)
	const [locationActive, setLocationActive] = useState<ItemSelect | null>(null)
	const [inputSearch, setInputSearch] = useState<string>('')

	const {
		data,
		isLoading: isLoadingLocations,
		isFetching
	} = useQuery({
		queryKey: [GET_LOCATIONS, userId, organizationId, inputSearch],
		queryFn: () =>
			getLocationsList(organizationId, 1, inputSearch, '', null, 20),
		refetchOnWindowFocus: false
	})

	const dataActiveLocation = totalData.delivery_enhancements.location.find(
		location => location.location_id !== null
	)

	const { data: dataById, isLoading: isLoadingDataById } = useQuery({
		queryKey: [GET_EDIT_LOCATION, userId, organizationId, dataActiveLocation],
		queryFn: () =>
			getLocationById(
				organizationId,
				dataActiveLocation?.location_id
					? parseInt(dataActiveLocation?.location_id.toString(), 10)
					: undefined
			),
		refetchOnWindowFocus: false
	})

	useEffect(() => {
		setUpdateItem(updateItem + 1)
	}, [item])
	useEffect(() => {
		if (defineTypeLocation === '2') setLocationActive(null)
	}, [defineTypeLocation])

	useEffect(() => {
		setUpdateDelivery(updateDelivery + 1)
	}, [item])

	const handleSubmnit = (): void => {
		setDeliveryActive({
			...deliveryActive,
			location: true
		})
		const aux = [] as locationState[]
		const newItem = item
		newItem.title = itemTitle
		newItem.location_type = 'item'

		if (
			defineTypeLocation === '1' &&
			locationActive?.label !== ' ' &&
			locationActive?.value !== 0
		) {
			aux.push({ ...item, location_id: locationActive?.value })
		}

		if (newItem.title !== '') {
			aux.push(item)
		}

		if (
			(defineTypeLocation !== '0' &&
				(item.title !== '' || delivery.title !== '')) ||
			(defineTypeLocation === '1' &&
				locationActive?.label !== ' ' &&
				locationActive?.value !== 0)
		) {
			if (aux.length > 0) {
				setTotalData({
					...totalData,
					delivery_enhancements: {
						...totalData.delivery_enhancements,
						location: aux
					}
				})
			}
		} else {
			setTotalData({
				...totalData,
				delivery_enhancements: {
					...totalData.delivery_enhancements,
					location: []
				}
			})
		}
		setOpenSlide(false)
	}

	useEffect(() => {
		const dataItem = totalData.delivery_enhancements.location.find(
			location => location.location_type === 'item'
		)

		if (defineTypeLocation === '1' && !locationActive) {
			setIsDisabled(false)
		} else if (defineTypeLocation === '1' && locationActive) {
			setIsDisabled(true)
		} else if (
			defineTypeLocation === '2' &&
			itemTitle === '' &&
			item.address === ''
		) {
			setIsDisabled(false)
		} else if (
			defineTypeLocation === '2' &&
			itemTitle !== '' &&
			item.address !== ''
		) {
			setIsDisabled(true)
		} else if (dataItem && dataItem.title !== '' && dataItem.address !== '') {
			setIsDisabled(true)
		} else if (defineTypeLocation === '0') {
			setIsDisabled(true)
		} else {
			setIsDisabled(false)
		}
	}, [item, itemTitle, locationActive, defineTypeLocation])

	const dataItem = totalData?.delivery_enhancements.location.find(
		location => location.location_type === 'item'
	)
	const dataDelivery = totalData.delivery_enhancements.location.find(
		location => location.location_type === 'delivery'
	)

	useEffect(() => {
		if (
			dataActiveLocation &&
			dataActiveLocation !== undefined &&
			dataById &&
			defineTypeLocation === '1'
		) {
			setLocationActive({
				label: dataById.set_up.title,
				value: dataById.id
			})
		} else {
			setLocationActive(null)
		}
		if (dataItem && dataItem !== undefined) {
			setItem({
				...item,
				title: dataItem.title,
				address: dataItem.address,
				latitude: dataItem.latitude,
				location_type: 'item',
				longitude: dataItem.longitude
			})
		} else {
			setItem({
				title: '',
				address: '',
				latitude: 0,
				longitude: 0
			})
		}
	}, [totalData, dataItem, dataDelivery, dataActiveLocation, dataById])

	const options = data?.items.map(elementsLocation => {
		return {
			label: elementsLocation.title,
			value: elementsLocation.id
		}
	})

	useEffect(() => {
		const itemsToRender =
			(mode === 'create' || mode === 'edit') &&
			totalData.delivery_enhancements.location[0] &&
			(totalData.delivery_enhancements.location?.[0].longitude !== null ||
				totalData.delivery_enhancements.location?.[0].location_id !== null)
				? itemDefineLocation
				: itemDefineLocationCreate
		setItemSelected(itemsToRender)
	}, [totalData])

	return (
		<div>
			{isLoading || isLoadingDataById ? (
				<div className="flex items-center justify-center w-full h-[400px] py-2 px-1 ">
					<CircularProgress disableShrink />
				</div>
			) : (
				<div className="mt-5 flex flex-col justify-between h-full">
					<div>
						<Disclosure defaultOpen>
							<Disclosure.Button className="w-full flex justify-between items-center mb-4">
								<div className="flex items-center gap-2">
									<Typography
										title="Define a  Location to this Content Item"
										className="text-gray-9"
										type={TypographyTypes.h2}
										fontLeading="18/21"
									/>
								</div>
								<div>
									<Icon
										src={Icons.arrowDown}
										className="w-4 text-gray-1"
										fillLine
									/>
								</div>
							</Disclosure.Button>
							<Disclosure.Panel className="text-gray-500 mb-4">
								{setDefineTypeLocation &&
									defineTypeLocation &&
									itemSelected.map(element => (
										<InputRadio
											item={element}
											key={element.id}
											defaultChecked={defineTypeLocation}
											setItemRecurrence={e => {
												setLocationActive(null)
												setItem(initialState)
												setItemTitle('')
												setNameLocation('')
												setDefineTypeLocation(e)
											}}
										/>
									))}
								{defineTypeLocation === '1' && (
									<Select
										options={options}
										placeholder="Active Locations"
										onInputChange={e => setInputSearch(e)}
										onChange={e => {
											setLocationActive(e)
										}}
										value={locationActive}
										isLoading={isLoadingLocations ?? isFetching}
										isClearable
									/>
								)}
								{defineTypeLocation === '2' && (
									<LocationContentItem
										item={item}
										setItem={setItem}
										itemTitle={itemTitle}
										setItemTitle={setItemTitle}
										totalData={totalData}
										setNameLocation={setNameLocation}
										nameLocation={nameLocation}
										key={updateItem}
									/>
								)}
							</Disclosure.Panel>
						</Disclosure>
						<Disclosure>
							<Disclosure.Button className="w-full flex justify-between items-center mb-4">
								<div className="flex items-center gap-2">
									<Typography
										title="Set a Delivery Radius for this Content Item"
										className="text-gray-9"
										type={TypographyTypes.h2}
										fontLeading="18/21"
									/>
								</div>
								<div>
									<Icon
										src={Icons.arrowDown}
										className="w-4 text-gray-1"
										fillLine
									/>
								</div>
							</Disclosure.Button>
							<Disclosure.Panel className="text-gray-500 mb-4">
								<DeliveryRadius
									delivery={delivery}
									totalData={totalData}
									setDelivery={setDelivery}
									deliveryTitle={deliveryTitle}
									setDeliveryTitle={setDeliveryTitle}
									setNameLocationRadius={setNameLocationRadius}
									nameLocationRadius={nameLocationRadius}
									key={updateDelivery}
								/>
							</Disclosure.Panel>
						</Disclosure>
					</div>

					<div className="flex items-center justify-between">
						<Button
							type="button"
							label="Cancel"
							color="Transparent"
							className="!min-w-[130px]"
							onClick={() => {
								if (currentTypeLocation)
									setDefineTypeLocation?.(currentTypeLocation)
								onClickClose()
							}}
						/>
						<Button
							label="Save"
							type="submit"
							color="Primary"
							className="!min-w-[130px]"
							onClick={handleSubmnit}
							disabled={!isDisabled}
						/>
					</div>
				</div>
			)}
		</div>
	)
}
