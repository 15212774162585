import { CircularProgress } from '@mui/material'
import {
	GET_MY_ORGANIZATION,
	getMyOrganization,
	postMyOrganizationBanner,
	postMyOrganizationProfileImage,
	postMyOrganizationTab
} from 'api'
import {
	Button,
	DynamicTabContent,
	Helper,
	Layout,
	Modal,
	Slide,
	SlideBanner,
	Title,
	Typography
} from 'components'
import { CardOpstions } from 'components/cardOptions'
import { arrayOptionsCardType } from 'components/cardOptions/cardOptions.type'
import { SliderColors } from 'components/myOrganization/slideColors'
import { SlideProfileImages } from 'components/myOrganization/slideProfileImages'
import { useToast } from 'hooks'
import {
	CompoenentCreateType,
	Icons,
	Images,
	MyOrganizationResponse,
	ProfileImageMyOrganizationItem,
	TabMyOrganizationItem,
	TypographyTypes
} from 'interfaces'
import React, { useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'

export interface StateButtonRdio {
	value: string
	index: number
}

export const MyOrganization = (): JSX.Element => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const { showToast } = useToast()
	const [tabActive, setTabActive] = useState(0)
	const [data, setData] = useState<MyOrganizationResponse>()
	const [openModal, setOpenModal] = useState(false)
	const [deleteTab, setDeleteTab] = useState(-1)

	const [openSlideProfile, setOpenSlideProfile] = useState(false)
	const [openSlideBanner, setOpenSlideBanner] = useState(false)
	const [openSlideColors, setOpenSlideColors] = useState(false)
	const [defaultComponent] = useState<CompoenentCreateType>()
	const [loading, setLoading] = useState(false)
	const [errorForm, setErrorForm] = useState(false)
	const [fieldError, setFieldError] = useState<string[]>([])

	const {
		data: dataRes,
		isLoading,
		refetch
	} = useQuery({
		queryKey: [GET_MY_ORGANIZATION, userId, organizationId],
		queryFn: () => getMyOrganization(organizationId)
	})

	const {
		control,
		setValue,
		watch,
		reset,
		register,
		formState: { errors, isValid }
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			tabs: data?.tabs ?? []
		}
	})
	const { fields, append, remove } = useFieldArray({ control, name: 'tabs' })

	useEffect(() => {
		if (dataRes) {
			setData(dataRes)
			setValue('tabs', dataRes.tabs)
			if (dataRes.tabs.length === 0) {
				setTabActive(-1)
			}
		}
	}, [dataRes])

	const messageToast = (value: { message: string } | null): void => {
		if (value) {
			showToast('Success', value?.message, 'success')
			refetch()
		}
	}

	const sendDataMyOrganization = async (): Promise<void> => {
		if (data) {
			setLoading(true)
			const res = await postMyOrganizationTab(organizationId, watch().tabs)
			messageToast(res)
			setLoading(false)
		}
	}

	const arrayOptionsCard: arrayOptionsCardType[] = [
		{
			title: 'Banners',
			descripcion: 'Create impact',
			action: setOpenSlideBanner,
			image: Images.emtyBanner
		},
		{
			title: 'Colors',
			descripcion: 'Customize your space',
			action: setOpenSlideColors,
			image: Images.emtyColor
		},
		{
			title: 'Profile Images',
			descripcion: 'Display your brand',
			action: setOpenSlideProfile,
			image: Images.resorceOrg
		}
	]

	const onSaveProfile = async (
		aux: ProfileImageMyOrganizationItem
	): Promise<void> => {
		setLoading(true)
		const responseProfile = await postMyOrganizationProfileImage(
			organizationId,
			aux
		)
		setLoading(false)

		messageToast(responseProfile)
	}

	const saveData = async (): Promise<void> => {
		const formData = watch()
		formData.tabs.forEach((item, sectionIndex) => {
			const updatedItem = { ...item }
			updatedItem.section_order = sectionIndex
			updatedItem.components = item.components.map(
				(component, componentIndex) => {
					const updatedComponent = { ...component }
					updatedComponent.component_order = componentIndex
					return updatedComponent
				}
			)
			formData.tabs[sectionIndex] = updatedItem
		})

		setLoading(true)

		const responseProfile = await postMyOrganizationTab(
			organizationId,
			formData.tabs as unknown as TabMyOrganizationItem[]
		)

		setLoading(false)
		messageToast(responseProfile)
	}

	return (
		<Layout>
			{loading || isLoading ? (
				<div className="flex items-center justify-center w-full h-screen py-2 px-1 ">
					<CircularProgress disableShrink />
				</div>
			) : (
				<>
					<div className="md:w-4/12 p-2 ">
						<Title
							icon={Icons.myOrganization}
							title="My Organization"
							subtitle="Set up your resources"
						/>
					</div>

					<div className="flex flex-col md:flex-row">
						{arrayOptionsCard.map((element, index) => (
							<CardOpstions data={element} key={`${element}_${index}`} />
						))}
					</div>

					<div className="w-full py-3 flex justify-between items-center">
						<Typography
							title="Manage Tabs"
							fontLeading="22/27"
							type={TypographyTypes.h1}
							className="text-gray-16"
						/>
						<Helper
							text="Add as many Components as you like to display beneath each Tab. Select +New Component to see all options."
							className="relative"
						/>
					</div>

					{fields.map((component, index) => (
						<Controller
							control={control}
							key={`tabs-${component.id}`}
							name={`tabs.${index}`}
							render={({ field }) => {
								return (
									<DynamicTabContent
										active={index}
										data={field.value}
										deletedTab={value => {
											setDeleteTab(value)
											setOpenModal(true)
										}}
										saveData={saveData}
										control={control}
										reset={reset}
										setValue={setValue}
										register={register}
										watch={watch}
										errors={errors}
										isValid={isValid}
										errorForm={errorForm}
										setErrorForm={setErrorForm}
										fieldError={fieldError}
										setFieldError={setFieldError}
									/>
								)
							}}
						/>
					))}

					{fields && fields.length < 4 && (
						<div
							aria-hidden="true"
							className="min-w-[100px] justify-center items-center"
						>
							<Button
								label="Add tab"
								className="w-full md:w-auto my-2"
								iconRight={Icons.plus}
								color="white"
								onClick={() =>
									append({
										organization_id: organizationId,
										name: 'New Tab',
										section_order: fields.length,
										components: [],
										audience: {
											tags: []
										}
									})
								}
							/>
						</div>
					)}

					<Modal
						setOpen={state => {
							setDeleteTab(-1)
							setOpenModal(state)
						}}
						open={openModal}
						type="warning"
						handleClick={async () => {
							remove(deleteTab)
							setDeleteTab(-1)
							setOpenModal(false)
							await sendDataMyOrganization()
						}}
					>
						<Typography
							title="Changes have been made. Are you sure you want to delete this tab?"
							className="text-gray-9"
							fontLeading="15/18"
							type={TypographyTypes.p}
						/>
					</Modal>
					<Slide
						open={openSlideProfile}
						setOpen={setOpenSlideProfile}
						title="Profile Images"
					>
						<SlideProfileImages
							active={tabActive}
							dataForm={defaultComponent}
							setOpen={setOpenSlideProfile}
							data={data}
							setData={setData}
							setLoading={setLoading}
							onSave={async aux => {
								const responseProfile = await postMyOrganizationProfileImage(
									organizationId,
									aux
								)
								messageToast(responseProfile)
							}}
						/>
					</Slide>
					<Slide
						open={openSlideBanner}
						setOpen={setOpenSlideBanner}
						title="Banner"
					>
						<SlideBanner
							active={tabActive}
							dataForm={defaultComponent}
							setOpen={setOpenSlideBanner}
							data={data}
							setData={setData}
							setLoading={setLoading}
							onSave={async aux => {
								const response = await postMyOrganizationBanner(
									organizationId,
									aux
								)
								messageToast(response)
							}}
						/>
					</Slide>

					<Slide
						open={openSlideColors}
						setOpen={setOpenSlideColors}
						title="Color"
					>
						<SliderColors
							data={data}
							slideOpen={openSlideColors}
							setOpen={setOpenSlideColors}
							setData={setData}
							onSave={onSaveProfile}
						/>
					</Slide>
				</>
			)}
		</Layout>
	)
}
