import { Button, Icon, TailwindModal, Typography } from 'components'
import { Icons, ModalPropsCopy, TypographyTypes } from 'interfaces'
import React from 'react'

export const CopyModal: React.FC<ModalPropsCopy> = ({
	setOpen,
	open,
	onActionsCopy,
	idCopy,
	setOpenReview,
	textValue,
	organizationPartnerId,
	contentId,
	onActionsCopyArray,
	dataSelected,
	openModalCopyPrimary
}) => {
	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			showCloseButton
			className="w-[360px] min-h-[168px] p-2 rounded-[16px]"
		>
			<div className="flex flex-col gap-4 w-full">
				<div className="flex flex-col gap-3 w-full items-center">
					<div className="w-[30px] h-[30px]">
						<Icon
							src={Icons.checkCircle}
							fillPath
							className="text-blue-primary"
						/>
					</div>

					<Typography
						title=" You are copying this item to your Content Hub as a Draft.?"
						fontLeading="15/18"
						type={TypographyTypes.h3}
						className="!font-normal text-black-1 text-center"
					/>
				</div>
				<div className="flex justify-center items-center gap-4 w-full">
					<Button
						label="Confirm"
						className="!min-w-[unset] !py-3 !px-9"
						onClick={() => {
							setOpen(!open)
							if (setOpenReview) {
								setOpenReview(false)
							}
							if (
								onActionsCopy &&
								idCopy &&
								organizationPartnerId &&
								contentId &&
								!openModalCopyPrimary
							) {
								onActionsCopy(
									{
										is_approved: true,
										status: 'draft',
										message: textValue
									},
									idCopy
								)
							} else if (
								dataSelected &&
								openModalCopyPrimary &&
								onActionsCopyArray
							) {
								onActionsCopyArray({
									organization_partner_ids: dataSelected,
									is_approved: true,
									status: 'draft'
								})
							}
						}}
					/>
				</div>
			</div>
		</TailwindModal>
	)
}
