import clsx from 'clsx'
import { FontLeadingTypes, TypographyProps, TypographyTypes } from 'interfaces'
import React from 'react'

export const Typography: React.FC<TypographyProps> = React.memo(
	({ type, fontLeading, title, className, htmlFor }): JSX.Element => {
		const GetLabel = type
		return (
			<GetLabel
				className={clsx(
					className,
					fontLeading
						? FontLeadingTypes[fontLeading]
						: 'text-[10px] leading-[17px] font-medium',
					'font-Inter'
				)}
				htmlFor={type === TypographyTypes.label ? htmlFor : ''}
			>
				{title}
			</GetLabel>
		)
	}
)
