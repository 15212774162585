import { Popover } from '@mui/material'
import {
	Button,
	DeleteTagsModal,
	TagsEditModal,
	TagsVisibilityModal,
	TailwindPopOver
} from 'components/index'
import {
	Icons,
	PeopleMyTagsActionCellProps,
	PeopleMyTagsItemProps
} from 'interfaces'
import React, { useState } from 'react'

export const PeopleMyTagsActionCell: React.FC<PeopleMyTagsActionCellProps> = ({
	allData,
	value,
	parentValue,
	data,
	setData,
	mode,
	refetch,
	isChildren,
	deleteLoading,
	setDeleteLoading,
	myPeopleRefetch,
	updateTagsVisibilityChildrens,
	deleteTagsParents,
	deleteTagsChildrens
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	const [tagsEditModal, setTagsEditModal] = useState(false)
	const [tagsDeleteModal, setTagsDeleteModal] = useState(false)
	const [tagsHideModal, setTagsHideModal] = useState(false)

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = (): void => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined

	return (
		<div className="flex gap-1">
			<Button
				iconLeft={Icons.pending}
				className="!w-max !min-w-[unset] !px-1 py-1 !border-none"
				color="Gray2"
				leftIconDivClassName="w-[20px] h-[20px]"
				onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
					handleClick(event)
				}}
			/>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				PaperProps={{ style: { borderRadius: '16px' } }}
			>
				<TailwindPopOver
					handleClose={handleClose}
					className="gap-4 w-[170px] h-max"
				>
					<>
						<Button
							label="Edit"
							className="!min-w-[unset] !w-full !py-2"
							iconLeft={Icons.edit}
							onClick={() => setTagsEditModal(true)}
							color="Transparent"
						/>
						{tagsEditModal && value && (
							<TagsEditModal
								open={tagsEditModal}
								setOpen={setTagsEditModal}
								mode={mode}
								value={value}
								parentValue={parentValue}
								data={data}
								refetch={refetch}
								setData={setData}
								setAnchorEl={setAnchorEl}
							/>
						)}
						<Button
							label={data?.is_visible ? 'Hide' : 'Show'}
							className="!min-w-[unset] !w-full !py-2"
							onClick={() => setTagsHideModal(true)}
							iconLeft={
								data?.is_visible ? Icons.visibilityOff : Icons.visibility
							}
						/>
						{tagsHideModal && (
							<TagsVisibilityModal
								open={tagsHideModal}
								setOpen={setTagsHideModal}
								data={data}
								mode={mode}
								value={value}
								parentValue={parentValue}
								refetch={refetch}
								setData={setData}
								myPeopleRefetch={myPeopleRefetch}
								setAnchorEl={setAnchorEl}
								updateTagsVisibilityChildrens={updateTagsVisibilityChildrens}
								isChildren={isChildren}
							/>
						)}
						<Button
							label="Delete"
							className="!min-w-[unset] !w-full !py-2"
							iconLeft={Icons.delete}
							color="Transparent"
							onClick={() => setTagsDeleteModal(true)}
							disabled={
								mode === 'parent' &&
								(data as PeopleMyTagsItemProps).count_son > 0
							}
						/>
						{tagsDeleteModal && (
							<DeleteTagsModal
								open={tagsDeleteModal}
								setOpen={setTagsDeleteModal}
								data={allData}
								parentValue={parentValue}
								selected={[value as number]}
								mode={mode}
								isChildren={isChildren}
								refetch={refetch}
								setData={setData}
								setAnchorEl={setAnchorEl}
								setDeleteLoading={setDeleteLoading}
								deleteLoading={deleteLoading}
								deleteTagsParents={deleteTagsParents}
								deleteTagsChildrens={deleteTagsChildrens}
							/>
						)}
					</>
				</TailwindPopOver>
			</Popover>
		</div>
	)
}
