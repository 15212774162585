import { InputRangeProps } from 'interfaces'
import React from 'react'

export const InputRange: React.FC<InputRangeProps> = ({
	value,
	setValue
}): JSX.Element => {
	return (
		<div>
			<input
				min="1"
				max="100"
				step="0.1"
				type="range"
				value={value.radius}
				id="range-input"
				className="rounded-lg overflow-hidden appearance-none bg-gray-1 h-3 w-full"
				onChange={dataValue =>
					setValue({
						...value,
						radius: parseFloat(dataValue.target.value)
					})
				}
			/>
			<div className="bg-blue-primary max-w-max p-2 rounded-lg text-white font-Inter text-xs">
				{value.radius} KM
			</div>
		</div>
	)
}
