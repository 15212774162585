import {
	BoxSwitch,
	Button,
	DropdownFull,
	Input,
	InputPhoneDoble,
	Typography
} from 'components'
import {
	DropdownFullItemProps,
	GoodLinkFormInputs,
	GoodLinkProps,
	Icons,
	TypographyTypes
} from 'interfaces'
import { typeOfLinkData } from 'lib'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { allValuesNonEmpty } from 'utils/allValuesNonEmpty'

export const GoodLink: React.FC<GoodLinkProps> = ({
	totalData,
	setOpenSlide,
	setTotalData,
	onClickClose,
	deliveryActive,
	setDeliveryActive,
	mode
}): JSX.Element => {
	const [typeOfLink, setTypeOfLink] = useState<DropdownFullItemProps>(
		typeOfLinkData[0]
	)
	const [typeOfSubLink, setTypeOfSubLink] = useState<DropdownFullItemProps>(
		typeOfLinkData[0]
	)
	const possibleOptions = [1, 2, 3]
	const [subLink, setSubLink] = useState(false)
	const [isSubmit, setIsSubmit] = useState(false)
	const [clean, setClean] = useState(false)
	const [subClean, setSubClean] = useState(false)
	const {
		register,
		watch,
		setValue,
		handleSubmit,
		formState: { errors }
	} = useForm<GoodLinkFormInputs>({
		mode: 'all'
	})

	const label = watch('label')
	const url = watch('url')
	const phoneNumberCode = watch('phoneNumberCode')
	const phoneNumber = watch('phoneNumber')
	const subLabel = watch('subLabel')
	const subUrl = watch('subUrl')
	const subPhoneNumberCode = watch('subPhoneNumberCode')
	const subPhoneNumber = watch('subPhoneNumber')

	const rules = {
		url: {
			required: { value: true, message: 'This field is required' },
			maxLength: { value: 2000, message: 'Maximum characters exceeded' },
			pattern: {
				value:
					/^(http|https|ftp):\/\/[^\s/$.?#].[^\s]*$/ ||
					/^[a-zA-Z0-9_!@#$%^&*(),.?":{}|<> ]*$/,
				message: 'Invalid link'
			}
		},
		email: {
			required: { value: true, message: 'This field is required' },
			maxLength: { value: 60, message: 'Maximum characters exceeded' }
		},
		label: {
			required: { value: true, message: 'This field is required' },
			maxLength: { value: 25, message: 'Maximum characters exceeded' }
		},
		phoneNumber: {
			required: { value: true, message: 'This field is required' },
			maxLength: { value: 14, message: 'Maximum characters exceeded' }
		},
		phoneNumberCode: {
			required: { value: true, message: 'This field is required' },
			maxLength: { value: 3, message: 'Maximum characters exceeded' }
		},
		subUrl: {
			required: { value: subLink, message: 'This field is required' },
			maxLength: { value: 2000, message: 'Maximum characters exceeded' },
			pattern: {
				value:
					/^(http|https|ftp):\/\/[^\s/$.?#].[^\s]*$/ ||
					/^[a-zA-Z0-9_!@#$%^&*(),.?":{}|<> ]*$/,
				message: 'Invalid link'
			}
		},
		subEmail: {
			required: { value: subLink, message: 'This field is required' },
			maxLength: { value: 60, message: 'Maximum characters exceeded' }
		},
		subLabel: {
			required: { value: subLink, message: 'This field is required' },
			maxLength: { value: 25, message: 'Maximum characters exceeded' }
		},
		subPhoneNumber: {
			required: { value: subLink, message: 'This field is required' },
			maxLength: { value: 14, message: 'Maximum characters exceeded' }
		},
		subPhoneNumberCode: {
			required: { value: subLink, message: 'This field is required' },
			maxLength: { value: 3, message: 'Maximum characters exceeded' }
		}
	}

	const onSubmit: SubmitHandler<GoodLinkFormInputs> = values => {
		if (typeOfLink.id !== 0) {
			if (!subLink) {
				setTotalData({
					...totalData,
					delivery_enhancements: {
						...totalData.delivery_enhancements,
						good_link: {
							...totalData.delivery_enhancements.good_link,
							link_type: typeOfLink.value || '',
							link_value: values.label,
							link_call_action: values.phoneNumber
								? `${values.phoneNumberCode}-${values.phoneNumber}`
								: values.url,
							is_sublink: subLink,
							sublink_type: '',
							sublink_value: '',
							sublink_call_action: ''
						}
					}
				})
			} else {
				setTotalData({
					...totalData,
					delivery_enhancements: {
						...totalData.delivery_enhancements,
						good_link: {
							...totalData.delivery_enhancements.good_link,
							link_type: typeOfLink.value || '',
							link_value: values.label,
							link_call_action: values.phoneNumber
								? `${values.phoneNumberCode}-${values.phoneNumber}`
								: values.url,
							is_sublink: subLink,
							sublink_type: typeOfSubLink.value || '',
							sublink_value: values.subLabel,
							sublink_call_action: values.subPhoneNumber
								? `${values.subPhoneNumberCode}-${values.subPhoneNumber}`
								: values.subUrl
						}
					}
				})
			}
		} else {
			setTotalData({
				...totalData,
				delivery_enhancements: {
					...totalData.delivery_enhancements,
					no_good_link: true,
					good_link: {
						...totalData.delivery_enhancements.good_link,
						link_type: '',
						link_value: '',
						link_call_action: '',
						is_sublink: false,
						sublink_type: '',
						sublink_value: '',
						sublink_call_action: ''
					}
				}
			})
		}

		setOpenSlide(false)
		setDeliveryActive({
			...deliveryActive,
			goodLink: true
		})
	}

	useEffect(() => {
		if (clean) {
			setValue('url', '')
			setValue('label', '')
			setValue('phoneNumber', '')
			setValue('phoneNumberCode', '')
		}
	}, [typeOfLink])

	useEffect(() => {
		if (subClean) {
			setValue('subUrl', '')
			setValue('subLabel', '')
			setValue('subPhoneNumber', '')
			setValue('subPhoneNumberCode', '')
		}
	}, [typeOfSubLink])

	useEffect(() => {
		if (!subLink) {
			setValue('subUrl', '')
			setValue('subLabel', '')
			setValue('subPhoneNumber', '')
			setValue('subPhoneNumberCode', '')
		}
	}, [subLink])

	useEffect(() => {
		if (
			typeOfLink.id === 0 &&
			!allValuesNonEmpty({
				label,
				subLabel,
				phoneNumberCode,
				phoneNumber,
				subPhoneNumberCode,
				subPhoneNumber,
				url
			})
		) {
			if (
				mode === 'edit' &&
				(totalData.delivery_enhancements.good_link.is_sublink ||
					totalData.delivery_enhancements.good_link.link_call_action ||
					totalData.delivery_enhancements.good_link.link_value)
			) {
				setIsSubmit(true)
			} else {
				setIsSubmit(false)
			}
		} else if (!subLink) {
			if (possibleOptions.includes(typeOfLink.id as number) && label && url) {
				setIsSubmit(true)
			} else if (
				typeOfLink.id === 4 &&
				allValuesNonEmpty({ label, phoneNumberCode, phoneNumber })
			) {
				setIsSubmit(true)
			} else {
				setIsSubmit(false)
			}
		} else if (
			possibleOptions.includes(typeOfLink.id as number) &&
			possibleOptions.includes(typeOfSubLink.id as number) &&
			allValuesNonEmpty({ label, subLabel, url, subUrl })
		) {
			setIsSubmit(true)
		} else if (
			(possibleOptions.includes(typeOfLink.id as number) ||
				typeOfLink.id === 4) &&
			typeOfSubLink.id === 4 &&
			allValuesNonEmpty({
				label,
				subLabel,
				subPhoneNumberCode,
				subPhoneNumber
			})
		) {
			setIsSubmit(true)
		} else if (
			typeOfLink.id === 4 &&
			typeOfSubLink.id === 4 &&
			allValuesNonEmpty({
				label,
				subLabel,
				phoneNumberCode,
				phoneNumber,
				subPhoneNumberCode,
				subPhoneNumber
			})
		) {
			setIsSubmit(true)
		} else if (
			typeOfLink.id === 4 &&
			possibleOptions.includes(typeOfSubLink.id as number) &&
			allValuesNonEmpty({
				phoneNumberCode,
				phoneNumber,
				label,
				subUrl,
				subLabel
			})
		) {
			setIsSubmit(true)
		} else {
			setIsSubmit(false)
		}
	}, [
		subLink,
		label,
		url,
		phoneNumberCode,
		phoneNumber,
		typeOfLink,
		typeOfSubLink,
		subLabel,
		subUrl,
		subPhoneNumberCode,
		subPhoneNumber,
		totalData
	])

	useEffect(() => {
		const phoneNumberArray =
			totalData.delivery_enhancements.good_link.link_call_action.split('-')
		const firstNumbers = phoneNumberArray[1]

		const secondNumbers = phoneNumberArray[2]

		const subPhoneNumberArray =
			totalData.delivery_enhancements.good_link.sublink_call_action.split('-')
		const firstSubPhoneNumbers = subPhoneNumberArray[1]

		const seconSubPhonedNumbers = subPhoneNumberArray[2]

		const auxTypeLink = typeOfLinkData.find(
			item => item.value === totalData.delivery_enhancements.good_link.link_type
		)
		if (auxTypeLink) {
			setTypeOfLink(auxTypeLink)
		}
		setValue('label', totalData.delivery_enhancements.good_link.link_value)
		if (totalData.delivery_enhancements.good_link.link_type !== 'phone') {
			setValue(
				'url',
				totalData.delivery_enhancements.good_link.link_call_action
			)
		} else {
			setValue(
				'phoneNumberCode',
				totalData.delivery_enhancements.good_link.link_call_action.split('-')[0]
			)
			setValue('phoneNumber', `${firstNumbers}-${secondNumbers}`)
		}

		if (totalData.delivery_enhancements.good_link.is_sublink) {
			setSubLink(totalData.delivery_enhancements.good_link.is_sublink)
			const auxTypeSubLink = typeOfLinkData.find(
				item =>
					item.value === totalData.delivery_enhancements.good_link.sublink_type
			)
			if (auxTypeSubLink) {
				setTypeOfSubLink(auxTypeSubLink)
			}
			setValue(
				'subLabel',
				totalData.delivery_enhancements.good_link.sublink_value
			)
			if (totalData.delivery_enhancements.good_link.sublink_type !== 'phone') {
				setValue(
					'subUrl',
					totalData.delivery_enhancements.good_link.sublink_call_action
				)
			} else {
				setValue(
					'subPhoneNumberCode',
					totalData.delivery_enhancements.good_link.sublink_call_action.split(
						'-'
					)[0]
				)
				setValue(
					'subPhoneNumber',
					`${firstSubPhoneNumbers}-${seconSubPhonedNumbers}`
				)
			}
		}
	}, [totalData])

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="mt-5 flex flex-col justify-between h-full"
		>
			<div>
				<Typography
					title="Main Link"
					type={TypographyTypes.h3}
					className="text-gray-9 !font-bold"
					fontLeading="15/18"
				/>
				<div className="mt-2">
					<Typography
						title="Type of Link"
						type={TypographyTypes.h3}
						className="text-gray-9 !font-bold mb-2"
						fontLeading="12/12"
					/>
					<DropdownFull
						item={typeOfLink}
						setClean={setClean}
						list={typeOfLinkData}
						setItem={setTypeOfLink}
					/>
				</div>
				<div className="border-b-[0.5px] border-gray-3 mt-3">
					{typeOfLink.id === 1 && (
						<Input
							name="url"
							title="URL"
							register={register}
							rules={rules.url}
							placeholder="Insert Link"
							error={errors.url}
							className="my-4"
						/>
					)}
					{typeOfLink.id === 2 && (
						<Input
							name="url"
							title="AllAboard Link"
							register={register}
							rules={rules.url}
							placeholder="Insert Link"
							error={errors.url}
							className="my-4"
						/>
					)}
					{typeOfLink.id === 3 && (
						<Input
							name="url"
							title="Email Address"
							type="email"
							register={register}
							rules={rules.email}
							placeholder="example@gmail.com"
							error={errors.email}
							className="my-4"
						/>
					)}
					{typeOfLink.id === 4 && (
						<InputPhoneDoble
							title="Phone Number"
							name="phoneNumberCode"
							name2="phoneNumber"
							register={register}
							setValue={setValue}
							rules={rules.phoneNumberCode}
							rules2={rules.phoneNumber}
							error={errors.phoneNumberCode}
							error2={errors.phoneNumber}
						/>
					)}
					{typeOfLink.id === 5 && (
						<Button
							type="button"
							label="Upload"
							color="Gray"
							iconLeft={Icons.upload}
							className="w-full"
							// onClick={onClickClose}
						/>
					)}
					{typeOfLink.id !== 0 && (
						<Input
							name="label"
							title="GoodLink Label"
							register={register}
							rules={rules.label}
							placeholder="Type your call to action here"
							error={errors.label}
							className="my-4"
							limit
							cantLimit={25}
							watchValue={label}
							setValueInput={setValue}
						/>
					)}
				</div>
				<BoxSwitch title="Sub Link" enabled={subLink} setEnabled={setSubLink} />
				{subLink && (
					<div>
						<div className="mt-2">
							<Typography
								title="Type of Link"
								type={TypographyTypes.h3}
								className="text-gray-9 !font-bold mb-2"
								fontLeading="12/12"
							/>
							<DropdownFull
								item={typeOfSubLink}
								list={typeOfLinkData}
								setClean={setSubClean}
								setItem={setTypeOfSubLink}
							/>
						</div>
						{typeOfSubLink.id === 1 && (
							<Input
								name="subUrl"
								title="URL"
								register={register}
								rules={rules.subUrl}
								placeholder="Insert Link"
								error={errors.subUrl}
								className="my-4"
							/>
						)}
						{typeOfSubLink.id === 2 && (
							<Input
								name="subUrl"
								title="AllAboard Link"
								register={register}
								rules={rules.subUrl}
								placeholder="Insert Link"
								error={errors.subUrl}
								className="my-4"
							/>
						)}
						{typeOfSubLink.id === 3 && (
							<Input
								name="subUrl"
								title="Email Address"
								type="email"
								register={register}
								rules={rules.subEmail}
								placeholder="example@gmail.com"
								error={errors.subEmail}
								className="my-4"
							/>
						)}
						{typeOfSubLink.id === 4 && (
							<InputPhoneDoble
								title="Phone Number"
								name="subPhoneNumberCode"
								name2="subPhoneNumber"
								register={register}
								setValue={setValue}
								rules={rules.subPhoneNumberCode}
								rules2={rules.subPhoneNumber}
								error={errors.subPhoneNumberCode}
								error2={errors.subPhoneNumber}
							/>
						)}
						{typeOfSubLink.id === 5 && (
							<Button
								type="button"
								label="Upload"
								color="Gray"
								iconLeft={Icons.upload}
								className="w-full"
								// onClick={onClickClose}
							/>
						)}
						{typeOfSubLink.id === 5 && (
							<Button
								color="Gray"
								type="button"
								label="Upload"
								className="w-full"
								iconLeft={Icons.upload}
							/>
						)}
						{typeOfSubLink.id !== 0 && (
							<Input
								name="subLabel"
								title="GoodLink Label"
								register={register}
								rules={rules.subLabel}
								placeholder="Type your call to action here"
								error={errors.subLabel}
								className="my-4"
								limit
								cantLimit={25}
								watchValue={subLabel}
								setValueInput={setValue}
							/>
						)}
					</div>
				)}
			</div>
			<div className="flex items-center justify-between">
				<Button
					type="button"
					label="Cancel"
					color="Transparent"
					className="!min-w-[130px]"
					onClick={onClickClose}
				/>
				<Button
					label="Save"
					type="submit"
					color="Primary"
					className="!min-w-[130px]"
					disabled={!isSubmit}
				/>
			</div>
		</form>
	)
}
