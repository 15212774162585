import { API } from 'api/config'
import { PatchUserInfoData } from 'interfaces'
import { UserSettingResponse } from './user.type'

export const patchUserInfo = async (
	body: PatchUserInfoData
): Promise<UserSettingResponse | null> => {
	let res: UserSettingResponse | null = null
	await API()
		.patch(`/user/main_contact`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}
