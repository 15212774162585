import { Button, Icon, TailwindModal, Typography } from 'components'
import { GenericModalProps, Icons, TypographyTypes } from 'interfaces'
import React from 'react'

export const DeleteMyFeed: React.FC<GenericModalProps<unknown>> = ({
	open,
	setOpen,
	onClickConfirm
}) => {
	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			showCloseButton
			className="w-[360px] min-h-[168px] p-2 rounded-[16px]"
		>
			<div className="flex flex-col gap-4 w-full">
				<div className="flex flex-col gap-3 w-full items-center">
					<div className="w-[30px] h-[30px]">
						<Icon src={Icons.warning} fillPath className="text-blue-primary" />
					</div>
					<Typography
						title="Delete Feed"
						fontLeading="15/18"
						type={TypographyTypes.h3}
						className="text-blue-primary text-center"
					/>
					<Typography
						title="Are you sure you want to delete this Feed"
						fontLeading="15/18"
						type={TypographyTypes.h3}
						className="!font-normal text-black-1 text-center"
					/>
				</div>
				<div className="flex justify-center items-center gap-4 w-full">
					<Button
						label="Continue"
						onClick={onClickConfirm}
						className="!min-w-[unset] !py-3 !px-9"
					/>
				</div>
			</div>
		</TailwindModal>
	)
}
