import {
	GET_CONTENTS,
	GET_CONTENTS_PINNED,
	GET_CONTENTS_TABLET,
	GET_NOTIFICATION,
	getContentShared,
	getContentsList,
	getContentsListPinned,
	getNotificationList
} from 'api'
import {
	AprovalPendingModal,
	Button,
	ContentPerformance,
	ContentTable,
	// SendDirectPush,
	CreateContentItem,
	DropdownFull,
	GroupButton,
	Input,
	Layout,
	SendDirectPush,
	Title,
	Typography
} from 'components'
import { PaginationContext } from 'context'
import { useSearchDebounce, useToast } from 'hooks'
import {
	DropdownFullItemProps,
	Icons,
	MyContentProps,
	TableResponseContentApproval,
	TypographyTypes
} from 'interfaces'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'
import { configGenaral } from 'utils'
import {
	allContentTypesData,
	allPartnerContentTypesData,
	allStatusesData,
	allStatusesDataNotification
} from '../lib'

export const Content: React.FC<MyContentProps> = ({
	period,
	setPeriod,
	optionsToRender,
	optionsToRenderTwo,
	periodMyContent,
	setPeriodMyconten,
	optionsToRenderContent,
	setMessageDashboard
}): JSX.Element => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const [pageContent, setPageContent] = useState(1)
	const [pageContentSearch, setPageContentSearch] = useState(1)
	const [pageContentPinned, setPageContentPinned] = useState(1)
	const [pagePush, setPagePush] = useState(1)
	const [pagePushSearch, setPagePushSearch] = useState(1)
	const [pagePartnerShared, setPagePartnerShared] = useState(1)
	const [contentOrPartnet, setcontentOrPartnet] = useState(1)
	const [dataSelected, setDataSelected] = useState<number[]>([])
	const { showToast } = useToast()
	const [open, setOpen] = useState(false)
	const [numberAproval, setNumberAproval] = useState<number>(0)
	// const [radioFilter, setRadioFilter] = useState(radioOptions[0].value) se comenta porque ahora no va
	const [contentType, setContentType] = useState<DropdownFullItemProps>(
		allContentTypesData[0]
	)
	const [partnerContentType, setPartnerContentType] =
		useState<DropdownFullItemProps>(allPartnerContentTypesData[0])
	const [allStatuses, setAllStatuses] = useState<DropdownFullItemProps>(
		allStatusesData[0]
	)
	// const [allFMStatuses, _setAllFMStatuses] = useState<DropdownFullItemProps>( se comenta porque ahora no va
	// 	allFMStatusesData[0]
	// )
	const { tables } = useContext(PaginationContext)

	const { register, setValue, watch } = useForm()

	const watchSearch = watch('search')
	const { inputSearch, handleChangeDebounce, resetInput } =
		useSearchDebounce(watchSearch)

	const numberElement = 10
	const isSearching = inputSearch.length > 1
	const {
		data: dataPinned,
		isLoading: isLoadingPinned,
		refetch: refetchPinned,
		isRefetching: isRefetchingPinned
	} = useQuery({
		queryKey: [
			GET_CONTENTS_PINNED,
			userId,
			organizationId,
			pageContentPinned,
			allStatuses.value,
			contentType.value,
			inputSearch,
			tables.myContentList.column,
			tables.myContentList.order
		],
		queryFn: () =>
			getContentsListPinned(
				organizationId,
				pageContentPinned,
				allStatuses.value || '',
				contentType.value || '',
				'',
				inputSearch,
				numberElement
			),
		refetchOnWindowFocus: false
	})

	const {
		data,
		isLoading,
		refetch,
		isRefetching: isRefetchingContent
	} = useQuery({
		queryKey: [
			GET_CONTENTS,
			userId,
			organizationId,
			pageContent,
			allStatuses.value,
			contentType.value,
			// allFMStatuses.value,
			inputSearch,
			tables.myContentList.column,
			tables.myContentList.order,
			pageContentSearch
		],
		queryFn: () =>
			getContentsList(
				organizationId,
				isSearching ? pageContentSearch : pageContent,
				allStatuses.value ?? '',
				contentType.value ?? '',
				inputSearch,
				tables.myContentList.column,
				tables.myContentList.order,
				numberElement
			),
		refetchOnWindowFocus: false
	})

	const {
		data: dataNotification,
		isLoading: isLoadingNotification,
		refetch: refetchNotification,
		isRefetching
	} = useQuery(
		[
			GET_NOTIFICATION,
			userId,
			organizationId,
			pagePush,
			allStatuses.value,
			inputSearch,
			tables.myContentList.column,
			tables.myContentList.order,
			pagePushSearch
		],
		() =>
			getNotificationList(
				organizationId,
				isSearching ? pagePushSearch : pagePush,
				allStatuses.value || '',
				'',
				inputSearch,
				tables.myContentList.column || 'last_edit',
				tables.myContentList.order || 'desc'
			)
	)

	useEffect(() => {
		if (dataNotification?.detail && period === 'Direct Pushes') {
			showToast('Error', `${dataNotification.detail}`, 'error')
		}
	}, [dataNotification, period])

	const {
		data: dataSharedContent,
		isLoading: isLoadingShared,
		refetch: refetchSharedTrue,
		isRefetching: isRefetchingShared
	} = useQuery(
		[
			GET_CONTENTS_TABLET,
			organizationId,
			pagePartnerShared,
			inputSearch,
			partnerContentType.value
		],
		() =>
			getContentShared(organizationId, {
				page: pagePartnerShared,
				search: inputSearch.trim(),
				is_approved: true,
				type: partnerContentType.value
			})
	)

	useEffect(() => {
		setPeriodMyconten('My Content')
	}, [])

	useEffect(() => {
		if (!isSearching) {
			setPageContentSearch(1)
			setPagePushSearch(1)
		}
	}, [isSearching])

	return (
		<Layout>
			<div className=" 3xl:mx-auto 3xl:my-auto 3xl:w-3/4 mx-auto">
				<div className="flex items-center justify-between ">
					<Title icon={Icons.speakerNotes} title="Content Hub" />
				</div>
				<div className="flex  flex-col-reverse sm:flex-row md:flex-row lg:flex-row gap-5 mt-4 items-center ">
					<Input
						inputFocus
						name="search"
						className="w-full sm:!w-[80%] md:!w-[80%] !p-[unset]  "
						borderFull={false}
						register={register}
						leftIcon={Icons.search}
						inputClassName="p-[unset] py-1 px-2"
						placeholder="Search Content"
						onChangeCustom={handleChangeDebounce}
						rightIcon={Icons.cancel}
						rightClick={() => setValue('search', '')}
						maxLength={configGenaral.maxSearchValue}
					/>
					<GroupButton
						period={periodMyContent}
						numberAproval={numberAproval}
						setPeriod={value => {
							if (value === 'My Content') {
								setcontentOrPartnet(1)
							} else {
								setcontentOrPartnet(2)
								setPeriod('Content Items')
							}

							setPeriodMyconten(value)
						}}
						options={optionsToRenderTwo}
						className="!w-full sm:!w-[52%] md:!w-[33%] lg:!w-[37%] xl:!w-[30%] 2xl:!w-[24%]"
						barBotton
						buttomNormal="min-w-[145px]"
					/>
				</div>
				<div className="flex gap-5 mt-4 md:flex-row sm:flex-col lg:flex-rowflex flex-col lg:flex-row w-full ">
					<ContentPerformance styles="basis-[33%]" />
					<CreateContentItem styles="basis-[33%]" />
					<SendDirectPush
						styles="basis-[33%]"
						setMessageDashboard={setMessageDashboard}
						setPeriod={setPeriod}
					/>
				</div>
				<div
					className="w-full flex items-start justify-between py-3 gap-5 mt-4 md:flex-row sm:flex-row lg:flex-rowflex flex-col lg:flex-row   
				sm:flex-wrap md:flex-nowrap"
				>
					<div className="flex items-center justify-between w-full ">
						<GroupButton
							period={period}
							setPeriod={value => {
								resetInput()
								setValue('search', '')
								setPeriod(value)
							}}
							options={
								contentOrPartnet === 1 && periodMyContent === 'My Content'
									? optionsToRender
									: optionsToRenderContent
							}
							big
						/>
					</div>
					{periodMyContent === 'Partner Content' && (
						<Button
							type="button"
							label="Content Approval"
							color="white"
							className="!min-w-[191px]  w-full block sm:block lg:hidden md:hidden "
							onClick={() => setOpen(true)}
						/>
					)}
					<div className="w-full flex justify-center">
						<div
							className={`w-full flex justify-end gap-2  sm:w-full  ${
								period === 'Content Items' &&
								periodMyContent === 'Partner Content'
									? 'w-[100%]  '
									: 'w-full'
							}`}
						>
							{periodMyContent === 'Partner Content' && (
								<Button
									type="button"
									label="Content Approval"
									color="white"
									className="!min-w-[191px] ml-4 hidden  md:block lg:block"
									onClick={() => setOpen(true)}
								/>
							)}
							{period === 'Content Items' ? (
								<>
									<DropdownFull
										rounded
										color="green"
										item={
											contentOrPartnet === 1 ? contentType : partnerContentType
										}
										setItem={
											contentOrPartnet === 1
												? setContentType
												: setPartnerContentType
										}
										list={
											contentOrPartnet === 1
												? allContentTypesData
												: allPartnerContentTypesData
										}
										icon={Icons.arrowDownFull}
										className="min-w-[180px]"
										iconClass="w-[10px] text-blue-primary"
									/>
									<DropdownFull
										rounded
										color="green"
										item={allStatuses}
										setItem={setAllStatuses}
										list={
											period === 'Content Items'
												? allStatusesData
												: allStatusesDataNotification
										}
										icon={Icons.arrowDownFull}
										className="min-w-[138px]"
										iconClass="w-[10px] text-blue-primary"
									/>
								</>
							) : null}
						</div>
					</div>
				</div>
				{contentOrPartnet === 1 && period === 'Content Items' ? (
					<>
						<div>
							<Typography
								title="Pinned Content"
								type={TypographyTypes.h3}
								fontLeading="16/18"
								className="text-[#323232] mb-2"
							/>
							{dataPinned?.items.length === 0 && (
								<div className="flex items-center justify-center w-full  py-2 px-1 rounded-lg">
									<Typography
										title="You haven't pinned any Content item."
										type={TypographyTypes.h3}
										fontLeading="15/22"
										className="text-[#A3A3A3]"
									/>
								</div>
							)}
							{dataPinned?.items.length !== 0 && (
								<ContentTable
									data={dataPinned}
									isLoading={isLoadingPinned || isRefetchingPinned}
									page={pageContentPinned}
									setPage={setPageContentPinned}
									dataSelected={dataSelected}
									setDataSelected={setDataSelected}
									refetchPinned={refetchPinned}
									refetch={refetch}
									className="overflow-auto"
									isOrder
									classNameLoading="!max-h-[155px]"
								/>
							)}
						</div>

						<div className="mt-2">
							<Typography
								title="All Content"
								type={TypographyTypes.h3}
								fontLeading="16/18"
								className="text-[#323232] mb-2"
							/>
							<ContentTable
								data={data}
								isLoading={isLoading || isRefetchingContent}
								page={isSearching ? pageContentSearch : pageContent}
								setPage={isSearching ? setPageContentSearch : setPageContent}
								dataSelected={dataSelected}
								setDataSelected={setDataSelected}
								refetch={refetch}
								refetchPinned={refetchPinned}
								className="overflow-auto"
							/>
						</div>
					</>
				) : contentOrPartnet === 1 && period === 'Direct Pushes' ? (
					<ContentTable
						data={dataNotification}
						isLoading={isLoadingNotification || isRefetching}
						page={isSearching ? pagePushSearch : pagePush}
						setPage={isSearching ? setPagePushSearch : setPagePush}
						dataSelected={dataSelected}
						setDataSelected={setDataSelected}
						refetch={refetchNotification}
						dataNotification={period === 'Direct Pushes'}
						className="overflow-auto"
					/>
				) : (
					contentOrPartnet === 2 &&
					period === 'Content Items' && (
						<ContentTable
							data={dataSharedContent}
							isLoading={isLoadingShared || isRefetchingShared}
							page={pagePartnerShared}
							setPage={setPagePartnerShared}
							dataSelected={dataSelected}
							setDataSelected={setDataSelected}
							refetch={refetchSharedTrue}
							contentOrPartnet={contentOrPartnet}
							className="overflow-auto"
						/>
					)
				)}

				<AprovalPendingModal
					setOpen={setOpen}
					open={open}
					data={data as TableResponseContentApproval}
					setNumberAproval={setNumberAproval}
					refetchSharedTrue={refetchSharedTrue}
				/>
			</div>
		</Layout>
	)
}
