import { Icon } from 'components/icon'
import { Icons, MarkerCustomProps } from 'interfaces'
import React from 'react'

export const MarkerCustom: React.FC<MarkerCustomProps> = (): JSX.Element => {
	return (
		<div className="w-10 h-10">
			<Icon src={Icons.location} className="w-full h-full" />
		</div>
	)
}
