import clsx from 'clsx'
import { StepperProps } from 'interfaces'
import React, { Fragment } from 'react'

export const Stepper: React.FC<StepperProps> = ({ step, stepsCount }) => {
	return (
		<div className="bg-white rounded-[32px] flex items-center justify-between h-9 px-[10px] py-[5.5px]">
			{Array.from(Array(stepsCount).keys()).map((item, idx) => (
				<Fragment key={item}>
					<span
						className={clsx(
							'rounded-full text-center w-[25px] h-[25px] flex justify-center items-center',
							step >= idx + 1
								? 'bg-blue-primary text-white font-medium'
								: 'bg-transparent-default border border-gray-9 text-gray-9'
						)}
					>
						{idx + 1}
					</span>
					{idx + 1 < stepsCount && (
						<hr
							className={clsx(
								'border-t border-dashed w-[49px]',
								step >= idx + 1 ? 'border-t-blue-primary' : 'border-t-gray-9'
							)}
						/>
					)}
				</Fragment>
			))}
		</div>
	)
}
