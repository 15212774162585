import { Table } from 'components'
import {
	ContentListItem,
	ContentTableProps,
	ParentTableProps
} from 'interfaces'
import moment from 'moment'
import React, { useCallback, useMemo } from 'react'
import { Column, Row } from 'react-table'
import {
	renderCellCapitalize,
	renderCellDateTimeUTC,
	renderCellSimpleValue
} from 'utils'

export const ContentFeedsTable: React.FC<
	ParentTableProps<ContentTableProps>
> = ({
	data,
	page,
	setPage,
	isLoading,
	// onActionsClick,
	dataSelected,
	setDataSelected,
	onItemsShowingChange,
	itemsShowing
}) => {
	const columns = useMemo(
		(): Column<ContentListItem>[] => [
			{
				Header: 'Type',
				accessor: 'content_category',
				Cell: ({ value }) =>
					renderCellCapitalize(value as string, { replace: true })
			},
			{
				Header: 'Creator',
				sortType: 'creator',
				accessor: 'creator',
				Cell: ({ value }) => renderCellSimpleValue(value)
			},
			{ Header: 'Title', accessor: 'title' },

			{
				Header: 'Published',
				sortType: 'datetime',
				accessor: ({ created_at: date }) => moment.utc(date).toDate(),
				Cell: ({ value }: { value: Date }) => renderCellDateTimeUTC(value)
			}
		],
		[]
	)

	const handleRowSelect = useCallback<
		(rows: Row<ContentListItem>[], selected: boolean) => void
	>((rows, selected) => {
		const ids = rows.map(row => row.original.id_content)
		if (selected) {
			setDataSelected?.(prev => [...prev, ...ids])
		} else {
			setDataSelected?.(prev => prev.filter(subId => !ids.includes(subId)))
		}
	}, [])

	return (
		<Table<ContentListItem>
			data={data?.items ?? []}
			page={page}
			isFetching={isLoading}
			noDataMessage="You don't have any subscriptions"
			onPageChange={setPage}
			columns={columns}
			onRowSelect={handleRowSelect}
			customWidth={[30]}
			bodyTableClassName="!h-[calc(100vh_-_28rem)]"
			justify="left"
			itemsShowing={itemsShowing}
			onItemsShowingChange={onItemsShowingChange}
			rowSelected={row =>
				dataSelected?.includes(row.original.id_content) ?? false
			}
			pages={data?.size}
			total={data?.total}
			borderTable
			// hideSelectionHeader // se coloco porque en diseno no estaba pero se deja por si mas adelante sea necesario
		/>
	)
}
