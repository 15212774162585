/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-named-default
import { default as GoogleMapReact } from 'google-map-react'
import { useToast } from 'hooks'
import { MapProps } from 'interfaces'
import React from 'react'
import { MarkerCustom } from './markerCustom'

export const Maps: React.FC<MapProps> = ({
	location,
	setLocation,
	addressInput
}): JSX.Element => {
	const { showToast } = useToast()
	return (
		<div className="h-[200px] w-full mt-4">
			<GoogleMapReact
				bootstrapURLKeys={{
					key: process.env.REACT_APP_GOOGLE_MAPS as string,
					libraries: ['places']
				}}
				center={{
					lat: location.latitude as number,
					lng: location.longitude as number
				}}
				defaultZoom={11}
				onClick={restul => {
					const geocoder = new google.maps.Geocoder()
					geocoder.geocode(
						{ location: { lat: restul.lat, lng: restul.lng } },
						(results, status) => {
							if (status === 'OK') {
								setLocation({
									...location,
									address: results[0].formatted_address,
									latitude: restul.lat,
									longitude: restul.lng
								})
								if (addressInput.current) {
									addressInput.current.value = results[0].formatted_address
								}
							} else {
								showToast(
									'Error',
									`Geocode was not successful for the following reason: ${status}`,
									'error'
								)
							}
						}
					)
				}}
			>
				<MarkerCustom
					lat={location.latitude as number}
					lng={location.longitude as number}
				/>
			</GoogleMapReact>
		</div>
	)
}
