import clsx from 'clsx'
import { BoxsProps } from 'interfaces'
import React from 'react'

export const Box: React.FC<BoxsProps> = ({
	children,
	className
}): JSX.Element => {
	return (
		<div className={clsx(className, 'bg-white p-[19px] rounded-lg')}>
			{children}
		</div>
	)
}
