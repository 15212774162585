import { Button } from 'components/buttons'
import { Icon } from 'components/icon'
import { Typography } from 'components/typography'
import { Icons, ModalProps, TypographyTypes } from 'interfaces'
import React from 'react'
import { TailwindModal } from '../tailwindModal'

export const NewPartnerConfirmModal: React.FC<
	Omit<ModalProps, 'children'> & { onConfirm: () => void }
> = ({ open, setOpen, onConfirm }) => {
	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			className="w-[360px] p-4 rounded-[6px]"
		>
			<div className="flex flex-col gap-4 w-full">
				<div className="flex flex-col gap-3 w-full items-center">
					<div className="w-[30px] h-[30px]">
						<Icon src={Icons.warning} fillPath className="text-blue-primary" />
					</div>
					<Typography
						title="Are you sure that you want to delete the message?"
						fontLeading="15/18"
						type={TypographyTypes.h3}
						className="!font-normal text-black-1 text-center"
					/>
				</div>
				<div className="flex justify-between items-center gap-4 w-full">
					<Button
						label="Cancel"
						color="Transparent"
						onClick={() => setOpen(false)}
						className="!min-w-[unset] !py-3 !px-9"
					/>
					<Button
						label="Confirm"
						onClick={onConfirm}
						className="!min-w-[unset] !py-3 !px-9"
					/>
				</div>
			</div>
		</TailwindModal>
	)
}
