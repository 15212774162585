import { CircularProgress } from '@mui/material'
import { deleteLocation } from 'api/delete/location'
import clsx from 'clsx'
import { ModalConfirm, Typography } from 'components'
import {
	Icons,
	LocationListResponse,
	ParentTableProps,
	TypographyTypes
} from 'interfaces'
import React, { useState } from 'react'
import Cookies from 'universal-cookie'
import { MyLocationsTable } from './myLocationsTable'

export const LocationTable: React.FC<
	ParentTableProps<LocationListResponse>
> = ({
	data,
	page,
	setPage,
	className,
	isLoading,
	dataSelected,
	setDataSelected,
	refetch,
	dataNotification,
	isOrder,
	contentOrPartnet,
	setIsUserInitiated,
	classNameLoading,
	dataTableLength,
	refetchPinned
}) => {
	const [loading, setLoading] = useState(false)
	const spaceColums = [20, 20, 14, 15, 12]
	const [listToDelete, setListToDelete] = useState<number | null>(null)
	const cookies = new Cookies()
	const organizationId = cookies.get('organization_id')

	const deleteHandler = async (id: number): Promise<void> => {
		if (setLoading) setLoading(true)
		const res = await deleteLocation(id, organizationId)
		if (res?.message) {
			if (refetch) {
				refetch()
			}
			if (setLoading) setLoading(false)
		}
	}

	if (loading || isLoading)
		return (
			<div
				className={clsx(
					classNameLoading,
					'flex items-center justify-center w-full h-[calc(100vh-_446px)] py-2 px-1 bg-white border-2 border-gray-7 rounded-lg'
				)}
			>
				<CircularProgress disableShrink />
			</div>
		)

	return (
		<>
			<div className={clsx(className, 'flex flex-col w-full')}>
				{data?.items && data.items.length > 0 ? (
					<div className="overflow-x-auto">
						<MyLocationsTable
							data={data.items}
							backendPages={data.pages}
							page={page}
							headers={[
								'Name',
								'Address',
								'Published',
								'List',
								'Content items'
							]}
							customWidth={spaceColums}
							actionColumnWidth={4}
							actionsButton={<h1>Action</h1>}
							bodyTableClassName="h-[calc(100vh-_552px)]"
							justify="center"
							setPage={setPage}
							dataSelected={dataSelected}
							setDataSelected={setDataSelected}
							refetch={refetch}
							dataNotification={dataNotification}
							setLoading={setLoading}
							contentOrPartnet={contentOrPartnet}
							isOrder={isOrder}
							setIsUserInitiated={setIsUserInitiated}
							dataTableLength={dataTableLength}
							refetchPinned={refetchPinned}
							listToDelete={listToDelete}
							setListToDelete={setListToDelete}
						/>
					</div>
				) : (
					<div
						className={clsx(
							classNameLoading,
							'flex items-center justify-center w-full h-[calc(100vh_-_446px)] py-2 px-1 bg-white border-2 border-gray-7 rounded-lg'
						)}
					>
						<Typography
							title="You don't have any location."
							type={TypographyTypes.h3}
							fontLeading="18/21"
							className="text-gray-7"
						/>
					</div>
				)}
			</div>

			<ModalConfirm
				open={listToDelete !== null}
				setOpen={() => setListToDelete(null)}
				onClickConfirm={() => {
					if (listToDelete) deleteHandler(listToDelete)
					setListToDelete(null)
				}}
				icon={Icons.warning}
				title="Delete location"
				descripcion="Are you sure you want to delete this list?"
			/>
		</>
	)
}
