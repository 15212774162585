import {
	BoxSettings,
	Helper,
	ResetPasswordForm,
	Title,
	Typography,
	UserDataForm
} from 'components'
import { Icons, TypographyTypes } from 'interfaces'
import React from 'react'
import { UpdateInformationAdmin } from './UpdateInformationAdmin'

export const SettingsGlobal = (): JSX.Element => {
	return (
		<div>
			<div className="md:flex items-center justify-between rounded-lg w-full p-4">
				<Title
					icon={Icons.settings}
					title="Settings"
					subtitle="Contact Information and Passwords"
				/>
			</div>
			<div className="mt-[34px] flex justify-between gap-5 flex-col md:flex-row">
				<BoxSettings className="md:w-[90%] w-auto md:min-h-[435px] min-h-[420px]">
					<div className="h-full">
						<Typography
							type={TypographyTypes.h2}
							className="font-Inter"
							title="Update Your Contact Information"
							fontLeading="15/18"
						/>
						<UserDataForm />
					</div>
				</BoxSettings>
				<BoxSettings className="md:w-[90%] w-auto md:min-h-[435px] min-h-[420px]">
					<UpdateInformationAdmin />
				</BoxSettings>
				<BoxSettings className="md:w-[90%] w-auto md:min-h-[435px] min-h-[390px]">
					<div className="h-full">
						<div className="flex justify-between">
							<Typography
								className="font-Inter"
								title="Reset Your Password"
								fontLeading="15/18"
								type={TypographyTypes.h2}
							/>
							<Helper
								classPanel="!max-w-[291px] !min-w-[291px]"
								text="Your password should be at least 8 characters long and include a combination of upper and lower case letters, numbers, and symbols."
							/>
						</div>
						<ResetPasswordForm />
					</div>
				</BoxSettings>
			</div>
		</div>
	)
}
