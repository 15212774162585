import clsx from 'clsx'
import { Helper } from 'components/helper'
import { Icon } from 'components/icon'
import { Typography } from 'components/typography'
import { ButtonProps, TypographyTypes } from 'interfaces/index'
import React from 'react'
import { Link } from 'react-router-dom'
import { getColor, getColorTextIconLeft, getColorTextIconRight } from 'utils'

export const ButtonContent: React.FC<ButtonProps> = ({
	label = undefined,
	disabled = false,
	color = 'Primary',
	iconLeft,
	iconRight,
	textLeft,
	textRight,
	className,
	classNameText,
	leftIconDivClassName,
	rightIconDivClassName,
	buttonTextClassName,
	children,
	small = false,
	helper,
	fillpat,
	classPanelHelper,
	iconClassName,
	...props
}): JSX.Element => {
	const isTransparent = color === 'Transparent' && 'text-white bg-blue-primary'
	return (
		<button
			type="button"
			disabled={disabled}
			className={clsx(
				'py-[10px] px-[14px] rounded-[100px] min-w-[200px]',
				'focus-visible:none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-transparent-default',
				small ? 'min-w-[100px]' : 'min-w-[200px]',
				getColor(disabled, color),
				className
			)}
			{...props}
		>
			<div className="w-full flex items-center justify-center gap-2">
				{textLeft && (
					<span
						className={clsx(
							'text-[12px] leading-[15px] py-1 px-2 font-normal rounded-full',
							isTransparent,
							classNameText
						)}
					>
						{textLeft}
					</span>
				)}
				{iconLeft && (
					<div className={clsx(leftIconDivClassName, 'w-[18px] h-[18px]')}>
						<Icon
							src={iconLeft}
							fillPath
							className={clsx(
								getColorTextIconLeft(disabled, color),
								iconClassName
							)}
						/>
					</div>
				)}
				{children}
				{label && (
					<Typography
						className={clsx(buttonTextClassName, 'font-normal')}
						title={label}
						fontLeading="15/18"
						type={TypographyTypes.span}
					/>
				)}
				{iconRight && (
					<div className={clsx(rightIconDivClassName, 'w-[18px] h-[18px]')}>
						<Icon
							src={iconRight}
							fillPath
							className={clsx(
								getColorTextIconRight(disabled, color),
								iconClassName
							)}
						/>
					</div>
				)}

				{helper && (
					<Helper
						text={helper}
						className="relative border-none text-sm"
						filpath={fillpat}
						classPanel={clsx(classPanelHelper, '!top-[-43px] min-w-[620px]')}
					/>
				)}
				{textRight && (
					<span
						className={clsx(
							'text-[12px] leading-[15px] py-1 px-2 font-normal rounded-full',
							isTransparent,
							classNameText
						)}
					>
						{textRight}
					</span>
				)}
			</div>
		</button>
	)
}

export const Button: React.FC<ButtonProps> = React.memo(
	({
		label,
		disabled = false,
		color = 'Primary',
		iconLeft,
		iconRight,
		textLeft,
		textRight,
		linkTo = null,
		className,
		classNameText,
		leftIconDivClassName,
		rightIconDivClassName,
		...props
	}) => {
		return (
			<>
				{linkTo && (
					<Link to={linkTo} replace>
						<ButtonContent
							label={label}
							disabled={disabled}
							color={color}
							iconLeft={iconLeft}
							iconRight={iconRight}
							textLeft={textLeft}
							textRight={textRight}
							className={className}
							leftIconDivClassName={leftIconDivClassName}
							rightIconDivClassName={rightIconDivClassName}
							classNameText={classNameText}
							{...props}
						/>
					</Link>
				)}
				{!linkTo && (
					<ButtonContent
						label={label}
						disabled={disabled}
						color={color}
						iconLeft={iconLeft}
						iconRight={iconRight}
						textLeft={textLeft}
						textRight={textRight}
						className={className}
						classNameText={classNameText}
						leftIconDivClassName={leftIconDivClassName}
						rightIconDivClassName={rightIconDivClassName}
						{...props}
					/>
				)}
			</>
		)
	}
)

export default Button
