import tinycolor from 'tinycolor2'

export const removeHTMLTags = (str: string): string => {
	const temp = document.createElement('div')
	temp.innerHTML = str
	return temp.textContent || temp.innerText || ''
}

export const hexToRgb = (hex: string): string => {
	// Eliminar el carácter '#' del inicio, si está presente
	const newHex = hex.replace('#', '')

	// Extraer los componentes de color
	const r = parseInt(newHex.substring(0, 2), 16)
	const g = parseInt(newHex.substring(2, 4), 16)
	const b = parseInt(newHex.substring(4, 6), 16)

	// Crear la cadena RGB
	const rgb = `rgb(${r}, ${g}, ${b})`

	return rgb
}

export const changeTextColor = (color: string): string => {
	// Convertir el color de fondo a HSL
	const hslColor = tinycolor(color).toHsl()

	// Calcular el contraste
	const lightness = hslColor.l
	const threshold = 0.5 // Umbral para determinar si el fondo es claro u oscuro

	// Elegir un color de texto basado en el contraste
	const textColor = lightness > threshold ? '#000000' : '#ffffff'
	return textColor
}
