import { Button } from 'components/index'
import { FmPartnerListActionCellProps, Icons } from 'interfaces'
import React, { useState } from 'react'

export const FmPartnerListActionCell: React.FC<
	FmPartnerListActionCellProps
> = ({ deleteHandler, value, deleteEdit }) => {
	const [deleteDisabled, setDeleteDisabled] = useState(false)

	const handleDeleteClick = (): void => {
		deleteHandler(value as number)
	}

	const handleEditClick = (): void => {
		deleteEdit(value as number)
		setDeleteDisabled(true)
	}

	return (
		<div className="flex gap-1">
			<Button
				iconLeft={Icons.delete}
				className="!w-max !min-w-[unset] !px-1 py-1 !border-none"
				color={deleteDisabled ? 'Gray2' : 'Transparent'}
				leftIconDivClassName="w-[18px] h-[18px]"
				disabled={deleteDisabled}
				onClick={() => handleDeleteClick()}
			/>

			<Button
				iconLeft={Icons.edit}
				className="!w-max !min-w-[unset] !px-1 py-1 !border-none"
				color="Transparent"
				leftIconDivClassName="w-[18px] h-[18px]"
				onClick={() => handleEditClick()}
			/>
		</div>
	)
}
