import { CircularProgress } from '@mui/material'
import { GET_DASHBOARD_PERFORMANCE, getPerformance } from 'api'
import clsx from 'clsx'
import {
	AnimatedProgressBar,
	Box,
	Dropdown,
	TitleBox,
	Typography
} from 'components'
import { BoxComponentProps, Icons, TypographyTypes } from 'interfaces'
import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'

export const DashboardPerformance: React.FC<BoxComponentProps> = ({
	styles
}): JSX.Element => {
	const names = useMemo(
		() => ['Followers', 'Saved Posts', 'Shared Posts', 'Sends', 'Total Reach'],
		[]
	)
	const [period, setPeriod] = useState('last_seven_days')
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const { data, isLoading, refetch } = useQuery(
		[GET_DASHBOARD_PERFORMANCE, period, userId, organizationId],
		() => getPerformance(organizationId, period)
	)

	useEffect(() => {
		refetch()
	}, [period])

	return (
		<div className={clsx(styles)}>
			<TitleBox title="Performance" icon={Icons.performance} className="mb-4" />
			<Box className="w-full h-[320px]">
				<>
					<div className="flex items-center justify-between mb-4">
						<Typography
							title="My App"
							fontLeading="15/15"
							type={TypographyTypes.h2}
						/>
						<Dropdown setPeriod={setPeriod} />
					</div>
					{isLoading ? (
						<div className="flex items-center justify-center w-full h-[200px] py-2 px-1 ">
							<CircularProgress disableShrink />
						</div>
					) : (
						data && (
							<div className="flex flex-col gap-2 overflow-hidden">
								<AnimatedProgressBar
									name={names[0]}
									value={data?.followers?.new_value}
									maxValue={data?.followers?.old_value}
								/>
								<AnimatedProgressBar
									name={names[1]}
									value={data?.saved_post?.new_value}
									maxValue={data?.saved_post?.old_value}
								/>
								<AnimatedProgressBar
									name={names[2]}
									value={data?.shared_post?.new_value}
									maxValue={data?.shared_post?.old_value}
								/>
								<AnimatedProgressBar
									name={names[3]}
									value={data?.shared_post?.new_value}
									maxValue={data?.shared_post?.old_value}
								/>
								<AnimatedProgressBar
									name={names[4]}
									value={data?.reach?.new_value}
									maxValue={data?.reach?.old_value}
								/>
							</div>
						)
					)}
				</>
			</Box>
		</div>
	)
}
