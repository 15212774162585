import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, ExclamationIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { Button, Icon, Typography } from 'components/'
import { Icons, ModalType, TypographyTypes } from 'interfaces'
import React, { Fragment } from 'react'

export const Modal: React.FC<ModalType> = ({
	open,
	type,
	setOpen,
	children,
	closeButton,
	handleClick,
	title,
	colorIcon,
	borderIcon,
	sizeModal,
	marginL,
	closeOutside = true,
	className
}): JSX.Element => {
	return (
		<Transition appear show={open} as={Fragment}>
			<Dialog
				as="div"
				className={clsx('z-30 fixed inset-0', className)}
				open={open}
				onClose={() => setOpen(false)}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black-1 bg-opacity-25" />
				</Transition.Child>

				<div
					aria-hidden="true"
					className="fixed inset-0 overflow-y-auto"
					onClick={closeOutside ? () => setOpen(false) : undefined}
				/>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0 scale-95"
					enterTo="opacity-100 scale-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-95"
				>
					<div
						className={clsx(
							'absolute top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%]',
							'w-full max-w-xs transform overflow-hidden rounded-2xl bg-white  text-left z-50',
							'align-middle shadow-xl transition-all',
							'md:max-w-md',
							sizeModal || 'p-6'
						)}
					>
						<div className="flex justify-between align-center">
							{title && (
								<Typography
									title={title}
									type={TypographyTypes.h3}
									fontLeading="18/21"
									className="text-[#323232] "
								/>
							)}

							{closeButton && (
								<div className={clsx('flex justify-end', !title && 'w-full')}>
									<button
										type="button"
										onClick={() => setOpen(false)}
										className={clsx(
											borderIcon || 'border-gray-3 p-2',
											'border-2  max-w-max rounded-full '
										)}
									>
										<Icon
											fillPath
											src={Icons.close}
											className={clsx(colorIcon || 'text-gray-3 w-[10px] ')}
										/>
									</button>
								</div>
							)}
						</div>
						<div className="sm:flex sm:items-start">
							{type !== 'normal' && (
								<div
									className={clsx(
										'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full',
										'sm:mx-0 sm:h-10 sm:w-10',
										{ 'bg-red-100 ': type === 'danger' }
									)}
								>
									{type === 'danger' && (
										<ExclamationIcon
											className="h-6 w-6 text-red-600"
											aria-hidden="true"
										/>
									)}
									{type === 'warning' && (
										<ExclamationIcon
											className="h-6 w-6 text-yellow-400"
											aria-hidden="true"
										/>
									)}
									{type === 'success' && (
										<CheckIcon
											className="h-6 w-6 text-green-600"
											aria-hidden="true"
										/>
									)}
								</div>
							)}
							<div
								className={clsx(
									'mt-3 text-center sm:mt-0  sm:text-left',
									type === 'normal' && 'w-full',
									!marginL && 'sm:ml-4'
								)}
							>
								{children}
							</div>
						</div>
						{type !== 'normal' && (
							<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
								<Button
									label="Ok"
									onClick={() => (handleClick ? handleClick() : setOpen(false))}
									className="ml-3"
								/>
								<Button
									label="Cancel"
									onClick={() => setOpen(false)}
									color="Transparent"
								/>
							</div>
						)}
					</div>
				</Transition.Child>
			</Dialog>
		</Transition>
	)
}
