import clsx from 'clsx'
import { Icon, Typography } from 'components'
import {
	Icons,
	OrganizationNamesLayoutProps,
	TypographyTypes
} from 'interfaces'
import React from 'react'

export const OrganizationNamesLayout: React.FC<
	OrganizationNamesLayoutProps
> = ({
	sidebarOpen,
	organizationName,
	responsive = false,
	organizactionLogo
}): JSX.Element => {
	return (
		<div
			className={clsx(
				sidebarOpen && 'w-full flex items-center px-6 gap-2',
				!responsive && 'mb-4'
			)}
		>
			{organizactionLogo ? (
				<img src={organizactionLogo} alt="Logo" className="h-[24px] w-[24px]" />
			) : (
				<Icon src={Icons.logo_PATTER} className="h-[24px] w-[24px]" />
			)}

			{sidebarOpen && (
				<Typography
					fontLeading="12/15"
					type={TypographyTypes.h3}
					title={organizationName || '-'}
					className="w-28 pl-1 text-gray-1"
				/>
			)}
		</div>
	)
}
