import { GET_COMMUNITY_BUILD_APPS, getBuildApps } from 'api'
import {
	AppBuildsFollowers,
	BuildAppsAppNamesTable,
	Button,
	Input,
	SuperAdminLayout,
	Title
} from 'components'
import { useDebouncedSearch } from 'hooks'
import { AppNamesTableResponse, Icons } from 'interfaces'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { configGenaral } from 'utils'

export const AppBuilds = (): JSX.Element => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const navigate = useNavigate()
	const [page, setPage] = useState(1)
	const { register, resetField, setValue, watch } = useForm<{
		search: string
	}>({
		mode: 'onChange'
	})
	const watchSearch = watch('search')
	const { debouncedSearch, resetSearch } = useDebouncedSearch(watch('search'))

	useEffect(() => {
		if (debouncedSearch && debouncedSearch.length > 0) {
			setPage(1)
		}
	}, [debouncedSearch])

	const { isLoading, data, refetch, isRefetching } = useQuery({
		queryKey: [GET_COMMUNITY_BUILD_APPS, userId, page, debouncedSearch],
		queryFn: () => getBuildApps(page, debouncedSearch),
		refetchOnWindowFocus: false
	})

	return (
		<SuperAdminLayout>
			<>
				<div className="flex items-center justify-between">
					<Title
						icon={Icons.accountTree}
						title="App Builds"
						subtitle="Overall performance"
					/>
				</div>
				<div className="flex justify-between items-center">
					<div className="flex justify-center items-center w-full">
						<AppBuildsFollowers styles="basis-[100%] mt-4" />
					</div>
				</div>
				<div className="flex justify-between items-center mt-4">
					<Input
						name="search"
						placeholder="Search an App"
						className="!w-[calc(100%_-_192px)] !p-[unset]"
						inputClassName="!p-[unset] !py-1 !px-2"
						inputFieldClassName="!font-normal"
						register={register}
						rightIcon={Icons.cancel}
						rightClick={() => {
							resetSearch()
							resetField('search')
						}}
						inputFocus
						borderFull={false}
						search
						limit
						cantLimit={configGenaral.maxSearchValue}
						watchValue={watchSearch}
						setValueInput={setValue}
					/>
					<Button
						label="Create App"
						className="!min-w-[unset] !w-[160px]"
						onClick={() => navigate('/superAdmin/appBuilds/create-app')}
					/>
				</div>
				<BuildAppsAppNamesTable
					data={data as AppNamesTableResponse | undefined}
					isLoading={isLoading || isRefetching}
					page={page}
					setPage={setPage}
					className="mt-4"
					refetch={refetch}
				/>
			</>
		</SuperAdminLayout>
	)
}
