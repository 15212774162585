/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * TODO:
 * Schedule appointment for review.
 */

import { DependencyList, useEffect } from 'react'

export function useDebounceEffect(
	fn: (...args: any[]) => void,
	waitTime: number,
	deps?: DependencyList
): void {
	useEffect(() => {
		const t = setTimeout(() => {
			fn(...(deps || []))
		}, waitTime)

		return () => {
			clearTimeout(t)
		}
	}, deps)
}
