import { Disclosure } from '@headlessui/react'
import clsx from 'clsx'
import { Button } from 'components/buttons'
import { Icon } from 'components/icon'
import { Typography } from 'components/typography'
import { useToast } from 'hooks'
import {
	CreateNewPushNotification,
	Icons,
	Images,
	TypographyTypes
} from 'interfaces'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Cookies from 'universal-cookie'
import { configGenaral } from 'utils'

export const CreatePushNotification: React.FC<CreateNewPushNotification> = ({
	setListImage,
	listImage,
	totalData
}) => {
	const { showToast } = useToast()
	const cookies = new Cookies()

	const organizationName = cookies.get('organization_name')
	const { register } = useForm({
		mode: 'all'
	})

	const registerInputFile = register('image')

	const handleUploadImage = (file: File): void => {
		const fileSize = file.size
		const fileMb = fileSize / 1024 ** 2
		if (fileMb <= configGenaral.maxfileSizeMB) {
			const render = new FileReader()
			render.onload = e => {
				const uploadedObj = { file, url: e.target?.result as string }
				setListImage([
					...listImage,
					{
						asset_type: 'image',
						asset_url: uploadedObj.url,
						asset_name: uploadedObj.file.name
							.normalize('NFD')
							.replace(/[\u0300-\u036f]/g, '')
							.replace(/ /g, '-')
					}
				])
			}
			render.readAsDataURL(file)
		} else {
			showToast(
				'Error',
				`Please select a file less than ${configGenaral.maxfileSizeMB}MB.`,
				'error'
			)
		}
	}

	const handleDeleteImage = (id: number): void => {
		const newListImage = listImage
		newListImage.splice(id, 1)
		setListImage([...newListImage])
	}

	useEffect(() => {
		if (totalData.set_up.image && listImage.length === 0)
			setListImage([
				{
					asset_type: 'image',
					asset_url: totalData.set_up.image,
					asset_name: ''
				}
			])
	}, [totalData])

	return (
		<Disclosure>
			<Disclosure.Button className="border-b border-gray-11 pb-3 w-full flex justify-between items-center ">
				<div className="flex items-center gap-2">
					<Typography
						title="Preview"
						className="text-gray-9"
						type={TypographyTypes.h2}
						fontLeading="22/27"
					/>
				</div>
				<div>
					<Icon src={Icons.arrowDown} className="w-4 text-gray-1" fillLine />
				</div>
			</Disclosure.Button>
			<Disclosure.Panel className="text-gray-500 h-full lg:!h-[570px] xl:!h-[670px]">
				<div>
					<input
						id="image-notification"
						type="file"
						className="hidden"
						{...registerInputFile}
						onChange={event => {
							if (event.target.files) {
								handleUploadImage(event.target.files[0])
							}
						}}
						accept="image/png, image/jpeg, image/jpg"
					/>

					<div className=" sm:bg-transparent-default px-[21px] pt-[21px] w-full h-[520px] 2xl:h-[520px] xl:h-[600px] md:h-[470px] sm:h-[500px] lg:h-[520px]">
						<div
							className="hidden xl:block 2xl:block xl:h-[79px] 2xl:h-[85px] shadow-lg bg-white p-4"
							style={{ borderRadius: '28px' }}
						>
							<div className="hidden sm:flex justify-between">
								<div className="flex">
									<img
										src={Images.patterLogo}
										alt="Logo"
										className="object-contain xl:h-[20px] xl:w-[20px]"
									/>
									<div className="items-center mb-2 ml-1">
										<div className="flex items-center">
											<p className="truncate max-w-[182px] text-[11px] font-Roboto font-[600] text-[#2C2C2C]">
												{totalData.set_up.title || 'Title Fills Here'}
											</p>
											<div
												className="w-1 h-1 rounded-full mx-1"
												style={{ background: '#5A5A5A' }}
											/>
											<p className="text-[11px] text-[#5A5A5A] font-Inter font-[400]">
												{organizationName}
											</p>
											<div
												className="w-1 h-1 rounded-full mx-1"
												style={{ background: '#5A5A5A' }}
											/>
											<p className="text-[10px] text-[#5A5A5A] font-Inter font-[400]">
												now
											</p>
										</div>
										<p className="truncate max-w-[182px] text-[11px] mt-2 font-Inter font-[400]">
											{totalData.set_up.message || 'Message Fills Here'}
										</p>
									</div>
								</div>

								<div className="flex ml-4 ">
									<img
										src={
											listImage.length !== 0
												? listImage[0].asset_url
												: Images.noImageLogo
										}
										alt="Logo"
										className="h-[46px] w-[55px] rounded-lg me-4"
									/>
									<div className="bg-gray-11 rounded-full h-[23px] w-[23px] flex justify-center items-center mx-2 ">
										<Icon
											src={Icons.arrowDown}
											className="w-[11px] text-gray-1"
											fillLine
										/>
									</div>
								</div>
							</div>
						</div>
						<div
							className={clsx(
								' shadow-lg bg-white p-4 my-4 w-full h-[70%] lg:h-[83%] md:h-[80%] sm:h-[87%] inline-block',
								totalData.set_up.title ? '2xl:h-[30.5rem]' : '2xl:h-full'
							)}
							style={{ borderRadius: '28px' }}
						>
							<div className="flex justify-between">
								<div className="flex flex-col">
									<div className="flex mt-2">
										<div>
											<img
												src={Images.patterLogo}
												alt="Logo"
												className="object-contain h-[20px] w-[26px]"
											/>
										</div>

										<div className="items-center mb-2 ">
											<div className="flex items-center">
												<p className=" text-[11px] font-Inter font-[400] text-[#5A5A5A]">
													{organizationName}
												</p>
												<div
													className="w-1 h-1 rounded-full mx-1 "
													style={{ background: '#5A5A5A' }}
												/>
												<p className=" text-[11px] text-[#5A5A5A] font-Inter font-[400]">
													now
												</p>
											</div>
										</div>
									</div>
									<div className="break-words max-w-[202px]">
										<p className="max-w-[224px] text-[11px] font-Inter font-[600] text-ellipsis overflow-hidden mt-3 text-[#2C2C2C] truncate">
											{totalData.set_up.title || 'Title Fills Here'}
										</p>
									</div>
								</div>

								<div className="flex ">
									<img
										src={
											listImage.length !== 0
												? listImage[0].asset_url
												: Images.noImageLogo
										}
										alt="Logo"
										className="h-[46px] w-[55px] rounded-lg me-4"
									/>
									<div className="bg-gray-11 rounded-full h-[23px] w-[23px] flex justify-center items-center mx-2 hidden sm:flex ">
										<Icon
											src={Icons.arrowUp}
											className="w-[11px] text-gray-1"
											fillLine
										/>
									</div>
								</div>
							</div>

							<div className="w-full overflow-hidden">
								<div className="p-1 w-full ">
									<p className="mt-2 font-Inter text-[0.8em] font-[400]  line-clamp-5 break-words">
										{totalData.set_up.message || 'Message Fills Here'}
									</p>
								</div>
								<div className="relative mt-2 flex justify-center items-center w-full">
									<img
										src={
											listImage.length !== 0
												? listImage[0].asset_url
												: Images.noImageBanner
										}
										alt="preview content"
										className={clsx(
											'rounded-lg  w-[85%] h-[140px] sm:w-[100%] sm:h-[200px] md:h-[140px] lg:h-[200px] xl:h-[230px] 2xl:w-[78%]  2xl:h-[350px]',
											listImage.length !== 0
												? 'object-scale-down'
												: 'object-contain'
										)}
									/>
									{listImage.length !== 0 && (
										<button
											type="button"
											onClick={() => handleDeleteImage(0)}
											className="absolute top-[-1px] right-3 bg-gray-18 p-1 rounded-full border-2 border-gray-3"
										>
											<Icon src={Icons.close} className="w-3 h-3 " />
										</button>
									)}
								</div>
							</div>
						</div>
						<div className="d-flex justify-center items-center  ">
							{listImage.length === 0 && (
								<Button
									color="white"
									iconLeft={Icons.addPhoto}
									className="w-full "
									label="Upload Image"
									onClick={() => {
										const input = document.getElementById('image-notification')
										if (input) input.click()
									}}
									disabled={listImage.length > 0}
								/>
							)}
						</div>
					</div>
					<div className="2xl:h-[150px]" />
					{/* se comenta porque no ahora */}
					{/* <Button
				color="Primary"
				label="Share Preview"
				className="w-full mt-3"
				onClick={() => {
					setSlideImages(false)
					setSlideLibraryImages(true)
				}}
				// disabled={listImage.length >= 5}
			/> */}
				</div>
			</Disclosure.Panel>
		</Disclosure>
	)
}
