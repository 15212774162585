import { ButtonGroup } from 'components/buttons/ButtonsGroups'
import { DropdownFull } from 'components/dropdown'
import { Input, InputAutoCompleteGoogleMaps } from 'components/input'
import { Typography } from 'components/typography'
import {
	FormPushNotification,
	RadioButtonsSendDirectPushProps,
	TypographyTypes,
	locationState
} from 'interfaces'
import { linkToUrl } from 'lib'
import React, { Dispatch, SetStateAction } from 'react'
import { useFormContext } from 'react-hook-form'
import { rules } from './RulesSendDirectPush'

export const RadioButtonsSendDirectPush: React.FC<
	RadioButtonsSendDirectPushProps
> = ({
	item,
	radioFilter,
	setRadioFilter,
	datacontentType,
	setContentType,
	contentType,
	placeHolderInput,
	setItem,
	totalData,
	setTotalData
}) => {
	const { setValue, register, watch, formState } =
		useFormContext<FormPushNotification>()
	const urls = watch('url')

	const handleChangeRadiuBtn = (value: string): void => {
		setValue('url', '')
		if (setItem)
			setItem({
				title: '',
				address: '',
				radius: 1,
				location_type: 'item',
				latitude: 0,
				longitude: 0
			})
		if (value === 'none') {
			setTotalData({
				...totalData,
				set_up: {
					...totalData.set_up,
					link_to: '',
					cta_Type: ''
				}
			})
			setContentType({ id: 0, name: 'Select' })
		} else {
			setTotalData({
				...totalData,
				set_up: {
					...totalData.set_up,
					cta_Type: value
				}
			})
		}
	}

	return (
		<div>
			<Typography
				className="text-gray-14 font-semibold mt-3 "
				type={TypographyTypes.h3}
				fontLeading="12/12"
				title="Link to:"
			/>
			<ButtonGroup
				items={linkToUrl}
				radioFilter={radioFilter}
				setRadioButtonOption={value => {
					setRadioFilter(value)
					handleChangeRadiuBtn(value as string)
				}}
			/>
			{radioFilter === 'content' && (
				<DropdownFull
					list={datacontentType}
					item={contentType}
					setItem={setContentType}
					className="mt-1"
				/>
			)}

			{radioFilter === 'address' && (
				<InputAutoCompleteGoogleMaps
					title={false}
					location={item as locationState}
					setLocation={setItem as Dispatch<SetStateAction<locationState>>}
					marginContainer="mt-1 rounded  border-gray-3 border-2"
					classNameInput="p-2 "
				/>
			)}
			{radioFilter === 'url' && (
				<Input
					name="url"
					type="text"
					rules={rules.urlPush}
					error={formState.errors.url}
					className="mt-1"
					borderFull={false}
					register={register}
					inputClassName="rounded p-0"
					setValueInput={setValue}
					watchValue={urls}
					placeholder={placeHolderInput}
					maxLength={250}
					cantLimit={250}
				/>
			)}
		</div>
	)
}
