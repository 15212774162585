import clsx from 'clsx'
import { Button } from 'components'
import { Icons, InputFileProps } from 'interfaces'
import React, { ChangeEvent, useRef, useState } from 'react'

export const InputFile: React.FC<InputFileProps> = ({
	buttonColor = 'Primary',
	className,
	renderButton,
	...props
}) => {
	const fileInput = useRef<HTMLInputElement>(null)
	const [, setValue] = useState('')

	const onButtonClick = (): void => {
		fileInput.current?.click()
	}

	const onFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
		setValue(URL.createObjectURL(event.target.files?.[0] as File))
	}

	return (
		<div>
			<input
				type="file"
				className="hidden"
				ref={fileInput}
				onChange={onFileChange}
				{...props}
			/>
			{renderButton ? (
				React.cloneElement(renderButton, { onClick: onButtonClick })
			) : (
				<Button
					type="button"
					className={clsx(className, '!w-max !min-w-[unset] !px-1 !py-1')}
					iconRight={Icons.cloudUploads}
					rightIconDivClassName="w-8 h-8"
					color={buttonColor}
					onClick={onButtonClick}
				/>
			)}
		</div>
	)
}
