import { Box, Input, InputPhoneDoble, Notes, Typography } from 'components'
import {
	OrganizationInformationForm,
	OrganizationInformationFormEditMainContact,
	TypographyTypes
} from 'interfaces'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

export const MainContact: React.FC<
	OrganizationInformationFormEditMainContact
> = ({ mainContact, editing }) => {
	const { register, setValue, formState } =
		useFormContext<OrganizationInformationForm>()

	const { errors } = formState

	useEffect(() => {
		if (editing) {
			setValue('mainContact', mainContact)
		}
	}, [editing, mainContact])

	return (
		<Box className="border-none min-h-[500px]">
			<div className="grid grid-cols-2 gap-8">
				<div className="flex flex-col gap-2">
					<Input
						name="mainContact.name"
						title="Name"
						type="text"
						placeholder="Bob Johnson"
						register={register}
						rules={{ required: 'Name is required', maxLength: 40 }}
						maxLength={40}
						error={errors.mainContact?.name}
					/>
					<InputPhoneDoble
						name="mainContact.phoneNumberCode"
						name2="mainContact.phoneNumber"
						title="Phone Number"
						register={register}
						setValue={setValue}
						rules={{ required: ' ' }}
						rules2={{ required: 'Phone is required' }}
						error={errors.mainContact?.phoneNumberCode}
						error2={errors.mainContact?.phoneNumber}
					/>
					<Input
						name="mainContact.address"
						title="Address"
						type="text"
						placeholder="Type organization address here"
						register={register}
						rules={{ required: 'Address is required' }}
						error={errors.mainContact?.address}
					/>
					<Input
						name="mainContact.addressDetails"
						title="Address Details"
						type="text"
						placeholder="Type organization address details here"
						register={register}
						rules={{ required: 'Address details is required' }}
						error={errors.mainContact?.addressDetails}
					/>
					<Input
						name="mainContact.email"
						title="E-mail"
						type="email"
						placeholder="example@gmail.com"
						register={register}
						rules={{ required: 'Email is required' }}
						error={errors.mainContact?.email}
					/>
				</div>
				<div className="flex flex-col">
					<Typography
						className="font-bold mb-2"
						type={TypographyTypes.label}
						title="Notes"
						fontLeading="12/12"
					/>
					<Notes {...register('mainContact.notes')} />
				</div>
			</div>
		</Box>
	)
}
