import { CircularProgress } from '@mui/material'
import { BoxDocuments, Button, Input, Typography } from 'components'
import { useToast } from 'hooks'
import {
	DocumentsInputs,
	DocumentsProps,
	Icons,
	TypographyTypes
} from 'interfaces'
import { dontEmojis } from 'lib'
import moment from 'moment'
import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { configGenaral, getExtFromBase64 } from 'utils'

export const Documents: React.FC<DocumentsProps> = ({
	onClickClose,
	documentsList,
	setDocumentsList,
	mode,
	isLoading
}): JSX.Element => {
	const { showToast } = useToast()
	const [documentUpload, setDocumentUpload] = useState<DocumentsInputs>()
	const {
		watch,
		register,
		setValue,
		handleSubmit,
		formState: { errors, isValid }
	} = useForm<DocumentsInputs>({
		mode: 'all'
	})
	const title = watch('title')
	const description = watch('description')

	const rules = {
		title: {
			required: { value: true, message: 'This field is required' },
			pattern: {
				value: dontEmojis,
				message: 'Invalid Field'
			}
		}
	}

	const registerInputFile = register?.(`pdf`)

	const onSubmit: SubmitHandler<DocumentsInputs> = values => {
		if (documentUpload) {
			const type = getExtFromBase64(documentUpload?.pdf)
			const fileExtension = type ? `.${type}` : ''
			setDocumentsList([
				...documentsList,
				{
					title: values.title,
					description: values?.description ?? '',
					size: documentUpload.size,
					pdf: documentUpload.pdf,
					name_file: `${values.title.replaceAll(' ', '')}-${moment().format(
						'YYYYMMDDHHmmss'
					)}${fileExtension}`
				}
			])
			setValue('title', '')
			setValue('description', '')
			setValue('pdf', '')
			setDocumentUpload(undefined)
		}
	}

	const handleUploadPDF = (
		file: File,
		titleFile: string,
		descriptionFile: string
	): void => {
		const fileSize = file.size
		const fileMb = fileSize / 1024 ** 2
		if (fileMb <= configGenaral.maxfileSizeMB) {
			const render = new FileReader()
			render.onload = e => {
				const uploadedObj = { file, url: e.target?.result as string }
				setDocumentUpload({
					description: descriptionFile,
					title: titleFile,
					size: Number(fileMb.toFixed(2)),
					pdf: uploadedObj.url,
					name_file: uploadedObj.file.name
				})
			}
			render.readAsDataURL(file)
		} else {
			showToast(
				'Error',
				`Please select a file less than ${configGenaral.maxfileSizeMB}MB.`,
				'error'
			)
		}
	}

	const handleDeleteFileOne = (): void => {
		setDocumentUpload(undefined)
		setValue('pdf', '')
	}

	const handleDeleteFileList = (id: number): void => {
		const newList = documentsList
		newList.splice(id, 1)
		setDocumentsList([...newList])
	}

	return (
		<div>
			{isLoading ? (
				<div className="flex items-center justify-center w-full h-[400px] py-2 px-1 ">
					<CircularProgress disableShrink />
				</div>
			) : (
				<form onSubmit={handleSubmit(onSubmit)} className="mt-5">
					<div>
						<Input
							name="title"
							title="Title"
							register={register}
							rules={rules.title}
							placeholder="Title"
							error={errors.title}
							className="mb-4"
							limit
							cantLimit={configGenaral.maxTitleLength}
							watchValue={title}
							setValueInput={setValue}
						/>
						{/* SE COMENTAN MIENTRAS EL CLIENTE DECIDE SI UTILIZAR O NO  */}
						{/* <Input
					name="description"
					title="Description"
					register={register}
					rules={rules.description}
					placeholder="Description"
					error={errors.description}
					className="mb-4"
					limit
					cantLimit={configGenaral.maxDescriptionLength}
					watchValue={description}
					setValueInput={setValue}
				/> */}
						<input
							id="pdf"
							type="file"
							className="hidden"
							{...registerInputFile}
							onChange={event => {
								if (event.target.files) {
									handleUploadPDF(event.target.files[0], title, description)
								}
							}}
							accept="application/pdf"
						/>
						<div className="border-b-[0.5px] border-gray-3 py-4">
							{documentUpload === undefined ? (
								<Button
									type="button"
									label="Upload"
									color="Gray3"
									className="w-full mt-4"
									iconLeft={Icons.upload}
									onClick={() => {
										const input = document.getElementById(`pdf`)
										if (input) input.click()
									}}
									disabled={!isValid}
								/>
							) : (
								<BoxDocuments
									size={documentUpload.size}
									title={documentUpload.name_file}
									onClick={handleDeleteFileOne}
								/>
							)}
							<Button
								type="submit"
								label="Add Document"
								color="Primary"
								className="w-full mt-4"
								disabled={documentUpload === undefined}
							/>
						</div>
						<div className="mt-4 flex flex-col gap-4 border-b-[0.5px] border-gray-3 pb-4">
							<Typography
								className="!font-semibold"
								title="Uploaded Documents:"
								type={TypographyTypes.span}
								fontLeading="12/12"
							/>
							{documentsList.length === 0 ? (
								<Typography
									type={TypographyTypes.span}
									title="Upload a new document first."
									fontLeading="15/11"
									className="!font-normal text-gray-3"
								/>
							) : (
								documentsList.map((item, index) => (
									<BoxDocuments
										key={item.size}
										size={item.size}
										title={item.title}
										onClick={() => handleDeleteFileList(index)}
									/>
								))
							)}
						</div>
					</div>

					<div className="flex items-center justify-between mt-4">
						<Button
							type="button"
							label="Cancel"
							color="Transparent"
							className="!min-w-[130px]"
							onClick={onClickClose}
						/>
						<Button
							label="Save"
							type="button"
							color="Primary"
							className="!min-w-[130px]"
							disabled={mode === 'create' && documentsList.length === 0}
							onClick={onClickClose}
						/>
					</div>
				</form>
			)}
		</div>
	)
}
