import { ExclamationCircleIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import { MessageProps } from 'interfaces'
import React from 'react'

const typeIcons: { [type in MessageProps['type']]: JSX.Element } = {
	error: (
		<ExclamationCircleIcon
			className="w-4 h-4 text-red-500"
			aria-hidden="true"
		/>
	)
}

export const Message: React.FC<MessageProps> = ({ text, type, marginL }) => {
	return (
		<div
			className={clsx(
				'flex items-center mt-2 text-red-500',
				marginL && `ml-${marginL}`
			)}
			style={{ marginLeft: marginL }}
		>
			<div className="mr-1 w-4 h-4">{typeIcons[type]}</div>
			<h1 className="text-xs font-Inter font-semibold" id="errorMessage">
				{text}
			</h1>
		</div>
	)
}
