import { Input, handleValid } from 'components'
import React, { useEffect } from 'react'
import { configGenaral } from 'utils'
import { PropsComponentsMyOrganization } from './interfaceSlide.type'

export const SlideTitle: React.FC<PropsComponentsMyOrganization> = ({
	active,
	pos,
	errors,
	register,
	setValue,
	watch,
	isValid,
	setFieldError
}): JSX.Element => {
	const errorTitle =
		errors?.tabs?.[active]?.components?.[pos]?.component_value?.title
	const rules = {
		title: {
			required: { value: true, message: 'This field is required' }
		}
	}

	const nameType = `title${active}-${pos}`

	useEffect(() => {
		handleValid(nameType, isValid as boolean, setFieldError)
	}, [isValid])

	return (
		<div className=" w-full pb-1">
			<Input
				name={`tabs.${active}.components.${pos}.component_value.title`}
				title="Title"
				register={register}
				rules={rules.title}
				error={errorTitle}
				borderFull
				limit
				cantLimit={configGenaral.maxTitleLength}
				watchValue={watch(
					`tabs.${active}.components.${pos}.component_value.title`
				)}
				setValueInput={setValue}
			/>
		</div>
	)
}
