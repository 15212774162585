import { API } from 'api/config'
import {
	BodyCreateContent,
	BodySharedContent,
	ContentResponseShared,
	CreateContentResponse,
	CreateContentResponseArray,
	EditContentListBody,
	EditContentListResponse
} from 'interfaces'

export const updateContentsList = async (
	organizationId: number,
	contentID: number,
	body: EditContentListBody
): Promise<EditContentListResponse | undefined> => {
	let res = undefined as undefined | EditContentListResponse
	await API()
		.patch(
			`/contents/${contentID}/organizations/${organizationId}/action`,
			body
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				res = error.response.data
			}
		)

	return res
}

export const patchContentEdit = async (
	organizationId: number,
	body: BodyCreateContent,
	contentId?: number
): Promise<CreateContentResponse | null> => {
	let res = null as CreateContentResponse | null
	if (contentId) {
		await API()
			.patch(`/contents/${contentId}/organizations/${organizationId}`, body)
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.error(error)
					res = error.response.data
				}
			)
	}
	return res
}

export const patchContentSharedEdit = async (
	organizationId: number,
	body: BodySharedContent,
	contentId?: number | null
): Promise<ContentResponseShared | null> => {
	let res = null as ContentResponseShared | null
	if (contentId) {
		await API()
			.patch(
				`/contents/organization_partner/${contentId}/organizations/${organizationId}`,
				body
			)
			.then(
				async response => {
					res = response.data
				},
				error => {
					// console.error(error)
					res = error.response.data
					throw new Error(error.response.data as string)
				}
			)
	}
	return res
}

export const patchContentSharedEditArray = async (
	organizationId: number,
	body: BodySharedContent
): Promise<CreateContentResponseArray | null> => {
	let res = null as CreateContentResponseArray | null
	if (body) {
		await API()
			.patch(
				`/contents/organization_partner/organizations/${organizationId}/bulk`,
				body
			)
			.then(
				async response => {
					res = response.data
				},
				error => {
					// console.error(error)
					res = error.response.data
					throw new Error(error.response.data as string)
				}
			)
	}
	return res
}
