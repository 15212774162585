import { API } from 'api/config'
import {
	BodyCreateLocation,
	CreateLocationResponse,
	EditLocationPinned,
	EditLocationsListRequest,
	MessageResponseNewLocationLists,
	NetworkPartnerPatchResponse
} from 'interfaces'

export const patchLocationEdit = async (
	organizationId: number,
	body: BodyCreateLocation,
	locationId?: number
): Promise<CreateLocationResponse | null> => {
	let res = null as CreateLocationResponse | null
	if (locationId) {
		await API()
			.patch(`/locations/${locationId}/organizations/${organizationId}`, body)
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.error(error)
					res = error.response.data
				}
			)
	}
	return res
}

export const updateLocationList = async (
	organizationId: number,
	locationId: number,
	body: EditLocationPinned
): Promise<NetworkPartnerPatchResponse | undefined> => {
	let res = undefined as undefined | NetworkPartnerPatchResponse
	await API()
		.patch(
			`/locations/${locationId}/organizations/${organizationId}/action`,
			body
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				res = error.response.data
			}
		)

	return res
}

export const patchEditLocationsList = async (
	organizationId: number,
	idList: number,
	body: EditLocationsListRequest
): Promise<MessageResponseNewLocationLists | null> => {
	let res = null as MessageResponseNewLocationLists | null
	await API()
		.patch(`/location_list/${idList}/organizations/${organizationId}`, body)
		.then(async response => {
			res = response.data
		})
		.catch(error => {
			res = { detail: error.response.data.detail }
		})
	return res
}
