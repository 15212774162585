import clsx from 'clsx'
import { Icon } from 'components'
import { NavProps } from 'interfaces'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

export const Nav: React.FC<NavProps> = ({
	navigation,
	sidebarOpen,
	responsive = false
}): JSX.Element => {
	const location = useLocation()

	return (
		<nav
			className={clsx(
				'space-y-1 flex flex-col justify-center items-center',
				!sidebarOpen && 'px-2',
				!responsive && 'mt-4'
			)}
		>
			{navigation.map(item => (
				<Link
					replace
					key={item.name}
					to={item.href}
					className={clsx(
						'flex items-center font-Inter w-full',
						!responsive ? 'justify-center' : 'px-6',
						item?.active.includes(location.pathname) && 'bg-blue-primary',
						sidebarOpen ? 'h-[48px]' : 'rounded-full h-[60px] w-[60px]',
						!item.userView && 'hidden'
					)}
				>
					<Icon
						fillPath
						src={item.icon}
						className={clsx(
							'w-7 h-7',
							item?.active.includes(location.pathname)
								? 'text-white'
								: 'text-gray-1'
						)}
					/>
					{sidebarOpen && (
						<p
							className={clsx(
								'w-32 ml-5',
								item?.active.includes(location.pathname)
									? 'text-white'
									: 'text-gray-1'
							)}
						>
							{item.name}
						</p>
					)}
				</Link>
			))}
		</nav>
	)
}
