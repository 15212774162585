import { CircularProgress } from '@mui/material'
import { addStaticList } from 'api'
import { Button, Input, TailwindModal, Typography } from 'components'
import {
	GenericModalProps,
	StaticListRequest,
	TypographyTypes
} from 'interfaces'
import React, { useCallback, useState } from 'react'

import { useToast } from 'hooks'
import Cookies from 'universal-cookie'

export const SaveStaticListModal: React.FC<GenericModalProps<number[]>> = ({
	open,
	setOpen,
	onSubmit,
	data
}) => {
	const cookies = new Cookies()
	const organizationId = cookies.get('organization_id')
	const [newListValue, setNewListValue] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const { showToast } = useToast()
	const addStaticListHandler = useCallback(
		async (body: StaticListRequest): Promise<void> => {
			setIsLoading(true)
			addStaticList(organizationId, body).then(response => {
				if (response) {
					showToast('Success', 'Static list created successfully.', 'success')
					if (onSubmit) {
						onSubmit()
					}
					setOpen(false)
				}
			})
		},
		[]
	)

	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			title="Save Selection as Static List"
			className="w-[474px] h-[248px] min-h-[248px] p-4 rounded-[16px]"
		>
			{!isLoading ? (
				<div className="flex flex-col gap-4 w-full mt-4">
					<Input
						name="listName"
						title="List Name"
						placeholder="Team Members"
						className="!w-full !p-[unset]"
						value={newListValue}
						onChange={event => setNewListValue(event.target.value)}
						inputClassName="!p-[unset] !py-1 !px-2"
						inputFocus
					/>
					<Typography
						title={`This Static List is comprised of only the people you selected. (${data?.length} total)`}
						fontLeading="15/18"
						type={TypographyTypes.p}
						className="!font-normal text-gray-1 text-left mt-2"
					/>
					<div className="flex justify-between items-center w-full">
						<Button
							label="Cancel"
							color="Transparent"
							onClick={() => setOpen(false)}
							className="!min-w-[unset] !w-[48%]"
						/>
						<Button
							label="Save"
							onClick={() =>
								addStaticListHandler({
									name: newListValue,
									organization_followers_id: data as number[]
								} as StaticListRequest)
							}
							className="!min-w-[unset] !w-[48%]"
							disabled={newListValue === ''}
						/>
					</div>
				</div>
			) : (
				<div className="flex items-center justify-center w-full h-[calc(100%_-_12px)] py-2 px-1 ">
					<CircularProgress disableShrink />
				</div>
			)}
		</TailwindModal>
	)
}
