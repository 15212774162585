import clsx from 'clsx'
import { RadioButtonGroupProps } from 'interfaces'
import React from 'react'
import { RadioButton } from './radioButton'

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
	items,
	radioButtonOption,
	setRadioButtonOption,
	classStyle,
	classStyleRadio
}): JSX.Element => {
	return (
		<div>
			<fieldset className={clsx(!classStyle && 'mt-4')}>
				<div
					className={clsx(
						classStyle ||
							'space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10'
					)}
				>
					{items.map(item => (
						<div className="flex items-center" key={item.id}>
							<RadioButton
								id={item.value}
								name="notification-method"
								checked={item.value === radioButtonOption}
								onChange={() => setRadioButtonOption(item.value)}
								className={classStyleRadio}
							/>
							<label
								htmlFor={item.value}
								className={clsx(
									classStyle
										? 'ml-2 font-[700] font-Inter text-[10px]'
										: 'ml-3',
									'block text-sm font-medium leading-6 text-gray-900'
								)}
							>
								{item.label}
							</label>
						</div>
					))}
				</div>
			</fieldset>
		</div>
	)
}
