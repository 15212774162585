import { API } from 'api/config'
import { ForgotPasswordResponse } from 'interfaces'

export const getMyForgotPassword = async (
	email: string
): Promise<ForgotPasswordResponse | null> => {
	let res = null as ForgotPasswordResponse | null
	await API()
		.get(
			`/send_email/forget_password?community_id=${
				process.env.REACT_APP_COMMUNITY_ID
			}&email=${email}&from_admin=${true}`
		)
		.then(async response => {
			res = response.data
		})
		.catch(error => {
			res = { detail: error.response.data.detail }
		})
	return res
}
