import { API } from 'api'
import { AxiosError } from 'axios'
import {
	DynamicListRequest,
	MessageResponse,
	PatchPeopleBatchesError,
	PatchPeopleBatchesPayload,
	PeopleTagsOrgRequest,
	SetFollowersGoalRequest,
	StaticListRequest,
	TagApprovalRequest,
	TagsApprovalRequest,
	UpdateFollowerStatusRequest,
	UpdateOrganizationRequest,
	UpdateUserInfoRequest,
	UpdateUserTagsRequest
} from 'interfaces'

export const setFollowersGoal = async (
	organizationId: number,
	body: SetFollowersGoalRequest | UpdateOrganizationRequest
): Promise<MessageResponse | null> => {
	let res = null
	await API()
		.patch(`/organization/${organizationId}`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const editStaticList = async (
	organizationId: number,
	id: number,
	body: StaticListRequest
): Promise<MessageResponse | null> => {
	let res = null
	await API()
		.patch(
			`/people/static_list/${id.toString()}/organization/${organizationId}`,
			body
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
				throw new Error(error.message ?? 'Unable to edit static list')
			}
		)
	return res
}

export const editDynamicList = async (
	organizationId: number,
	id: number,
	body: DynamicListRequest
): Promise<MessageResponse | null> => {
	let res = null
	await API()
		.patch(
			`/people/dynamic_list/${id.toString()}/organization/${organizationId}`,
			body
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const editTag = async (
	tagId: number,
	body: PeopleTagsOrgRequest
): Promise<MessageResponse | null> => {
	let res = null
	await API()
		.patch(`/people/tags/${tagId}`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const handleTag = async (
	organizationId: number,
	tagId: number,
	body: TagApprovalRequest
): Promise<MessageResponse | null> => {
	let res = null
	await API()
		.patch(
			`/people/tag/review/${tagId.toString()}/organization/${organizationId}`,
			body
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const handleMultipleTags = async (
	organizationId: number,
	body: TagsApprovalRequest
): Promise<MessageResponse | null> => {
	let res = null
	await API()
		.patch(`/people/tags/approval/organization/${organizationId}`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const updateFollowerStatus = async (
	organizationId: number,
	followerId: number,
	body: UpdateFollowerStatusRequest
): Promise<MessageResponse | null> => {
	let res = null
	await API()
		.patch(`/organization/${organizationId}/follower/${followerId}`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const updateUserInfo = async (
	// organizationId: number,
	userId: number,
	body: UpdateUserInfoRequest
): Promise<MessageResponse | null> => {
	let res = null
	await API()
		.patch(`/user/${userId}/personal_information`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const updateUserTags = async (
	organizationId: number,
	userId: number,
	body: UpdateUserTagsRequest
): Promise<MessageResponse | null> => {
	let res = null
	await API()
		.patch(`/user/${userId}/my_tags/organization/${organizationId}`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const patchPeopleBatches = async (
	args: PatchPeopleBatchesPayload
): Promise<{ message: string }> => {
	try {
		const { organizationId, userIds, ...rest } = args
		const response = await API().patch(
			`people/massive_update/${organizationId}`,
			{
				user_ids: userIds,
				...rest
			}
		)
		return response.data
	} catch (error) {
		const axiosError = error as AxiosError<PatchPeopleBatchesError>
		throw new Error(
			axiosError?.response?.data.errors?.[0]?.msg ??
				'Your changes could not be saved. Please try again in a few minutes.'
		)
	}
}
