import { GET_LOCATIONS } from 'api'
import { getLocationsList } from 'api/get/locations'

import { Button, Input, Layout, LocationListsModal, Title } from 'components'
import { LocationTable } from 'components/table/location'
import { PaginationContext } from 'context'
import { useSearchDebounce } from 'hooks'
import { Icons, MyContentProps } from 'interfaces'
import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'
import { configGenaral } from 'utils'

export const Location: React.FC<MyContentProps> = (): JSX.Element => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const [pageContent, setPageContent] = useState(1)
	const { tables } = useContext(PaginationContext)
	const { register, setValue, watch } = useForm()
	const watchSearch = watch('search')
	const { inputSearch, handleChangeDebounce } = useSearchDebounce(watchSearch)
	const [fmPartnerLists, setFMPartnerLists] = useState(false)

	const { data, isLoading, refetch, isRefetching } = useQuery({
		queryKey: [
			GET_LOCATIONS,
			userId,
			organizationId,
			pageContent,
			inputSearch,
			tables.myLocationsList.column,
			tables.myLocationsList.order
		],
		queryFn: () =>
			getLocationsList(
				organizationId,
				pageContent,
				inputSearch,
				tables.myLocationsList.column,
				tables.myLocationsList.order
			),
		refetchOnWindowFocus: false
	})

	return (
		<>
			<Layout>
				<div className=" 3xl:mx-auto 3xl:my-auto 3xl:w-3/4 mx-auto">
					<div className="flex items-center">
						<Title icon={Icons.distance} title="Locations" />
					</div>
					<div className="flex  flex-col-reverse sm:flex-row md:flex-row lg:flex-row gap-5 mt-4 items-center ">
						<Input
							inputFocus
							name="search"
							className="w-full sm:!w-[80%] md:!w-[80%] !p-[unset]  "
							borderFull={false}
							register={register}
							leftIcon={Icons.search}
							inputClassName="p-[unset] py-1 px-2"
							placeholder="Search Location"
							onChangeCustom={e => {
								handleChangeDebounce(e)
								setPageContent(1)
							}}
							rightIcon={Icons.cancel}
							rightClick={() => setValue('search', '')}
							maxLength={configGenaral.maxSearchValue}
						/>
						<div
							className="justify-between  md:flex-row sm:flex-row lg:flex-rowflex flex-col lg:flex-row   
				sm:flex-wrap md:flex-nowrap"
						>
							<div className="flex justify-center">
								<div className={` flex justify-end gap-2  sm:w-full  `}>
									<Button
										label="Location Lists"
										color="Transparent"
										className="max-w-[133px]"
										onClick={() => setFMPartnerLists(true)}
									/>

									<Button
										label="New Location"
										color="Primary"
										className="max-w-[133px]"
										linkTo="/location/create"
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="mt-2">
						<LocationTable
							data={data}
							isLoading={isLoading || isRefetching}
							page={pageContent}
							setPage={setPageContent}
							refetch={refetch}
							className="overflow-auto"
						/>
					</div>
				</div>
			</Layout>
			{fmPartnerLists && (
				<LocationListsModal
					open={fmPartnerLists}
					setOpen={setFMPartnerLists}
					refetch={refetch}
				/>
			)}
		</>
	)
}
