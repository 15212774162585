export enum Images {
	logoDO = '/asset/images/logoDO.png',
	profile = '/asset/images/profile.png',
	ladgLogo = '/LADG_logo.png',
	patterLogo = '/asset/images/PATTER.png',
	kentuckyFlower = '/asset/images/kentuckyFlower.png',
	customizePreview = '/asset/images/customizePreview.png',
	defaultAvatar = '/asset/images/defaultAvatar.png',
	defaultOrganizationLogo = '/asset/images/defaultOrganizationLogo.png',
	unionStrongLogo = '/asset/images/unionStrongLogo.png',
	unionStrongLogoCircle = '/asset/images/unionStrongLogoCircle.png',
	phoneImage = '/asset/images/createAppPhone.png',
	phoneLogo = '/asset/images/createAppLogo.png',
	yellowstonePark = '/asset/images/yellowstonePark.jpg',
	logoLADG = '/asset/images/LogoLadg.png',
	noImageLogo = '/asset/images/noImageLogo.png',
	noImageBanner = '/asset/images/noImageBanner.png',
	updateAdmin = '/asset/images/updateImage.png',
	updateAdminNew = '/asset/images/updateAdminNew.png',
	emtyColor = '/asset/images/emtyColor.gif',
	emtyBanner = '/asset/images/emtyBanner.gif',
	resorceOrg = '/asset/images/resorceOrg.png'
}

export enum Icons {
	arrowDown = '/asset/svg/arrowDown.svg',
	logout = '/asset/svg/logout.svg',
	content = '/asset/svg/content.svg',
	camera = '/asset/svg/camera.svg',
	menuOpen = '/asset/svg/menuOpen.svg',
	myPeople = '/asset/svg/myPeople.svg',
	settings = '/asset/svg/settings.svg',
	dashboard = '/asset/svg/dashboard.svg',
	myNetwork = '/asset/svg/myNetwork.svg',
	menuClose = '/asset/svg/menuClose.svg',
	myOrganization = '/asset/svg/myOrganization.svg',
	notifications = '/asset/svg/notifications.svg',
	postAdd = '/asset/svg/postAdd.svg',
	handUp = '/asset/svg/handUp.svg',
	person = '/asset/svg/person.svg',
	tag = '/asset/svg/tag.svg',
	list = '/asset/svg/list.svg',
	contentList = '/asset/svg/contentList.svg',
	accountTree = 'asset/svg/accountTree.svg',
	adUnits = '/asset/svg/adUnits.svg',
	addImages = '/asset/svg/addImage.svg',
	add = '/asset/svg/add.svg',
	addToHomeScreen = '/asset/svg/addToHomeScreen.svg',
	android = '/asset/svg/android.svg',
	apple = '/asset/svg/apple.svg',
	appShortcut = '/asset/svg/appShortcut.svg',
	mobileFriendly = '/asset/svg/mobileFriendly.svg',
	arrowBackIos = '/asset/svg/arrowBackIos.svg',
	arrowForwardIos = '/asset/svg/arrowForwardIos.svg',
	arrowLeft = '/asset/svg/arrowLeft.svg',
	arrowRight = '/asset/svg/arrowRight.svg',
	arrowDropDown = '/asset/svg/arrowDropDown.svg',
	arrowDropUp = '/asset/svg/arrowDropUp.svg',
	block = 'asset/svg/block.svg',
	attachment = 'asset/svg/attachment.svg',
	cancel = '/asset/svg/cancel.svg',
	checkCircle = '/asset/svg/checkCircle.svg',
	checkGeneric = '/asset/svg/checkGeneric.svg',
	closeGeneric = '/asset/svg/closeGeneric.svg',
	cloudUploads = '/asset/svg/cloudUploads.svg',
	dangerous = '/asset/svg/dangerous.svg',
	delete = '/asset/svg/delete.svg',
	doNotDisturbOn = '/asset/svg/doNotDisturbOn.svg',
	download = '/asset/svg/download.svg',
	dragHandle = '/asset/svg/dragHandle.svg',
	edit = '/asset/svg/edit.svg',
	expandLess = '/asset/svg/expandLess.svg',
	expandMore = '/asset/svg/expandMore.svg',
	expandMoreRight = '/asset/svg/expandMoreRight.svg',
	filterAltFill = '/asset/svg/filterAltFill.svg',
	group = '/asset/svg/group.svg',
	groups = '/asset/svg/groups.svg',
	imagesMode = '/asset/svg/imagesMode.svg',
	link = '/asset/svg/link.svg',
	listAlt = '/asset/svg/listAlt.svg',
	location = '/asset/svg/location.svg',
	locationOn = '/asset/svg/locationOn.svg',
	navigateBefore = '/asset/svg/navigateBefore.svg',
	navigateNext = '/asset/svg/navigateNext.svg',
	notificationsGeneric = '/asset/svg/notificationsGeneric.svg',
	password = '/asset/svg/password.svg',
	pencil = '/asset/svg/pencil.svg',
	pending = '/asset/svg/pending.svg',
	permMedia = '/asset/svg/permMedia.svg',
	personGeneric = '/asset/svg/personGeneric.svg',
	phone = '/asset/svg/phone.svg',
	photoCamera = '/asset/svg/photoCamera.svg',
	plus = '/asset/svg/plus.svg',
	pushPin = '/asset/svg/pushPin.svg',
	pushPinActive = '/asset/svg/pushPinActive.svg',
	save = '/asset/svg/save.svg',
	sell = '/asset/svg/sell.svg',
	share = '/asset/svg/share.svg',
	WhiteShared = '/asset/svg/sharedWhite.svg',
	simpleText = '/asset/svg/simpleText.svg',
	smsFailed = '/asset/svg/smsFailed.svg',
	socialMedia = '/asset/svg/socialMedia.svg',
	speakerNotes = '/asset/svg/speakerNotes.svg',
	thumbUp = '/asset/svg/thumbUp.svg',
	unfoldMore = '/asset/svg/unfoldMore.svg',
	visibility = '/asset/svg/visibility.svg',
	visibilityOff = '/asset/svg/visibilityOff.svg',
	warning = '/asset/svg/warning.svg',
	close = '/asset/svg/close.svg',
	check = '/asset/svg/check.svg',
	qrCode = '/asset/svg/qr_code.svg',
	removeTab = '/asset/svg/removeTab.svg',
	title = '/asset/svg/title.svg',
	mail = '/asset/svg/mail.svg',
	copy = '/asset/svg/copy.svg',
	performance = '/asset/svg/performance.svg',
	uploadCloud = '/asset/svg/uploadCloud.svg',
	bookmark = '/asset/svg/bookmark.svg',
	help = '/asset/svg/help.svg',
	upload = '/asset/svg/upload.svg',
	calendar = '/asset/svg/calendar.svg',
	colorize = '/asset/svg/colorize.svg',
	image = '/asset/svg/image.svg',
	arrowUp = '/asset/svg/arrowUp.svg',
	calendarCheck = '/asset/svg/calendarCheck.svg',
	tags = '/asset/svg/tags.svg',
	addPhoto = '/asset/svg/addPhoto.svg',
	defaultImage = '/asset/svg/defaultImage.svg',
	search = '/asset/svg/search.svg',
	pdf = '/asset/svg/pdf.svg',
	arrowDownFull = '/asset/svg/arrowDownFull.svg',
	switchAccessShortcut = '/asset/svg/switch_access_shortcut.svg',
	LADG_logo = '/asset/svg/LADG.svg',
	logo_LADG = '/asset/svg/logo_ladg.svg',
	logo_PATTER = '/asset/svg/logo_PATTER.svg',
	error = '/asset/svg/error.svg',
	organization_super_admin = '/asset/svg/organization_super_admin.svg',
	cancelBig = '/asset/svg/cancel_big.svg',
	uploadImage = '/asset/svg/uploadImage.svg',
	rectangle = '/asset/svg/Rectangle (1).svg',
	notificationsWhite = '/asset/svg/notifications (1).svg',
	closeGenericWhite = '/asset/svg/closeWhite.svg',
	cancelGreen = '/asset/svg/cancelGreen.svg',
	adUnitsPush = '/asset/svg/adUnitsPush.svg',
	closeCircle = '/asset/svg/close-circle.svg',
	rotateRight = '/asset/svg/rotateRight.svg',
	rotateLeft = '/asset/svg/rotateLeft.svg',
	square = '/asset/svg/square.svg',
	adminComputer = '/asset/svg/computer.svg',
	portrait = '/asset/svg/portrait.svg',
	landscape = '/asset/svg/landscape.svg',
	flipHorizontal = '/asset/svg/flipHorizontal.svg',
	flipVertical = '/asset/svg/flipVertical.svg',
	zoomIn = '/asset/svg/zoomIn.svg',
	zoomOut = '/asset/svg/zoomOut.svg',
	resetInfo = '/asset/svg/undo.svg',
	deleteX = '/asset/svg/delete_forever.svg',
	accountCircle = '/asset/svg/accountCircle.svg',
	secondaryPhone = '/asset/svg/secondaryPhone.svg',
	distance = '/asset/svg/distance.svg',
	errorTags = '/asset/svg/errorTags.svg',
	historyToggleOff = '/asset/svg/historyToggleOff.svg',
	checkCircleTags = '/asset/svg/checkCircle.svg',
	adminIconComputer = '/asset/svg/Adminicon.svg',
	settingsUser = '/asset/svg/settingsUser.svg',
	userAdd = '/asset/svg/user_add.svg',
	accountOff = '/asset/svg/account_circle_off.svg',
	google = '/asset/svg/devicon_google.svg',
	organization = '/asset/svg/badge.svg',
	ellipse = '/asset/svg/ellipse.svg',
	config = '/asset/svg/config.svg',
	proStatus = '/asset/svg/pro_icon.svg',
	liteStatus = '/asset/svg/lite_icon.svg',
	eliteStatus = '/asset/svg/elite_icon.svg',
	addPdf = '/asset/svg/add_pdf.svg',
	addAssets = '/asset/svg/add_assets.svg',
	document = '/asset/svg/document.svg'
}
