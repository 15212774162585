import { Popover } from '@mui/material'
import clsx from 'clsx'
import { Button, Icon, TailwindPopOver } from 'components'

import { Icons, LocationsActionCellProps } from 'interfaces'
import React, { useState } from 'react'

export const LocationActionCell: React.FC<LocationsActionCellProps> = ({
	data,
	editHandler,
	deleteHandler,
	pinHandler,
	dataNotification
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = (): void => {
		setAnchorEl(null)
	}

	const editpushAndContent = (): void => {
		if (data) {
			editHandler(data.id)
		}
	}

	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined

	return (
		<div className="flex gap-1 items-center">
			<Button
				iconLeft={Icons.pending}
				className="!w-max !min-w-[unset] !px-1 py-1 !border-none"
				color="Gray2"
				leftIconDivClassName="w-[20px] h-[20px]"
				onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
					handleClick(event)
				}}
			/>
			{!dataNotification ? (
				<div className="flex">
					<div
						aria-hidden="true"
						className="w-[20px] h-[20px] cursor-pointer"
						onClick={() => pinHandler()}
					>
						<Icon
							src={data?.is_pinned ? Icons.pushPin : Icons.pushPinActive}
							fillPath
							className={clsx(
								data?.is_pinned ? 'text-blue-primary' : 'text-gray-1'
							)}
						/>
					</div>
				</div>
			) : null}
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				PaperProps={{ style: { borderRadius: '16px' } }}
			>
				<TailwindPopOver
					handleClose={handleClose}
					title="Actions"
					className="gap-4 w-[278px] h-max"
				>
					<>
						<Button
							label="Edit"
							className="!min-w-[unset] !w-full !py-2"
							iconLeft={Icons.edit}
							color="Transparent"
							onClick={() => editpushAndContent()}
						/>

						<Button
							label="Delete"
							className="!min-w-[unset] !w-full !py-2"
							iconLeft={Icons.delete}
							color="Gray3"
							onClick={() => deleteHandler()}
						/>
					</>
				</TailwindPopOver>
			</Popover>
		</div>
	)
}
