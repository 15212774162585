import { API } from 'api'
import {
	AddNewTagResponse,
	FiltersDynamicListRequest,
	MessageResponse,
	PeopleTagsOrgRequest,
	StaticListRequest,
	TagDynamicListRequest
} from 'interfaces'

export const addStaticList = async (
	organizationId: number,
	body: StaticListRequest
): Promise<MessageResponse | null> => {
	let res = null
	await API()
		.post(`/people/static_list/organization/${organizationId}`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const addNewTag = async (
	organizationId: number,
	body: PeopleTagsOrgRequest
): Promise<AddNewTagResponse | null> => {
	let res = null
	await API()
		.post(`/people/tag/organization/${organizationId}`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const addTagDynamicList = async (
	organizationId: number,
	body: TagDynamicListRequest
): Promise<MessageResponse | null> => {
	let res = null
	await API()
		.post(`/people/tag/dynamic_list/${organizationId}`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const addFiltersDynamicList = async (
	organizationId: number,
	body: FiltersDynamicListRequest
): Promise<MessageResponse | null> => {
	let res = null
	await API()
		.post(`people/tag/dynamic_list/${organizationId}`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const editFiltersDynamicList = async (
	organizationId: number,
	id: number,
	body: FiltersDynamicListRequest
): Promise<MessageResponse | null> => {
	let res = null
	await API()
		.patch(`/people/dynamic_list/${id}/organization/${organizationId}`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}
