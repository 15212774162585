import { CircularProgress } from '@mui/material'
import { FormLogin, SelectedOrganization } from 'components'
import { Images, UserData } from 'interfaces'
import React, { useState } from 'react'

export const Login = (): JSX.Element => {
	const [step, setStep] = useState(0)
	const [user, setUser] = useState<UserData>({
		email: null,
		fullName: null,
		userId: null,
		organization_id: null,
		organization_name: null,
		organization_slug: null,
		roles: null,
		token: null
	})
	const [loading, setLoading] = useState(false)

	return (
		<div className="w-screen h-screen flex flex-col gap-4 items-center justify-center">
			{loading ? (
				<div className="flex items-center justify-center w-full h-[400px] py-2 px-1 ">
					<CircularProgress disableShrink />
				</div>
			) : (
				<>
					<div className="w-[101px] h-[91px]">
						<img
							src={Images.patterLogo}
							alt="Logo"
							className="w-full h-full object-contain"
						/>
					</div>
					{step === 0 && (
						<FormLogin
							user={user}
							setUser={setUser}
							setStep={setStep}
							setLoading={setLoading}
						/>
					)}
					{step === 1 && <SelectedOrganization user={user} setStep={setStep} />}
				</>
			)}
		</div>
	)
}
