/* eslint-disable no-console */
import { useToast } from 'hooks'
import { RequiredApprovalCheckboxProps } from 'interfaces'
import React, { useEffect, useState } from 'react'

export const RequiredApprovalCheckbox: React.FC<
	RequiredApprovalCheckboxProps
> = ({ defaultChecked, id, updateTags, isChildren, updateTagsChildrens }) => {
	const [disabled, setDisabled] = useState(false)
	const [value, setValue] = useState(defaultChecked)
	const { showToast } = useToast()
	const requireApprovalHandler = async (): Promise<void> => {
		setDisabled(true)
		const aux = !value
		try {
			setValue(aux)
			if (updateTags && updateTagsChildrens) {
				if (isChildren) {
					await updateTagsChildrens(id, aux)
				} else {
					await updateTags(id, aux)
				}
			}
			showToast('Success', "Tag's info updated successfully.", 'success')
		} catch (error) {
			console.error('Error updating tags:', error)
		} finally {
			setDisabled(false)
		}
	}

	useEffect(() => {
		setValue(defaultChecked)
	}, [defaultChecked, id])

	return (
		<input
			type="checkbox"
			className="h-4 w-4 rounded border-gray-1 text-blue-primary focus:ring-transparent-default mr-2 ml-1 cursor-pointer"
			checked={value}
			onChange={() => requireApprovalHandler()}
			disabled={disabled}
			title={disabled ? 'Updating...' : ''}
		/>
	)
}
