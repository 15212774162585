import { CircularProgress } from '@mui/material'
import {
	APP_BUILD_DATA_EDIT,
	getBuildAppOrganization,
	getCommunityBuildApps,
	GET_COMMUNITY_BUILD_APPS,
	updateAppBuild
} from 'api'
import {
	AdminOrganizationStepFooter,
	BuildAppList,
	Input,
	Pagination,
	Title
} from 'components'
import { BuildAppsContext } from 'context'
import { useSearchDebounce, useToast } from 'hooks'
import { AdminOrganizationStepComponentProps, Icons } from 'interfaces'
import React, { useContext, useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'

export const SelectBuildApp: React.FC<
	AdminOrganizationStepComponentProps<number | null>
> = ({ stepper, onNext, onBack, data: initialBuildApp }) => {
	const [page, setPage] = useState(1)

	const [orderBy, setOrderBy] = useState<string | null>(null)
	const [isChangeActive, setIsChangeActive] = useState(false)
	const [isLoadingLocal, setIsLoadingLocal] = useState(false)
	const [selectedBuild, setSelectedBuild] = useState<number | null>(
		initialBuildApp || null
	)
	const { isChangeData, setIsChangeData } = useContext(BuildAppsContext)

	const { state } = useLocation()
	const navigate = useNavigate()
	const { showToast } = useToast()

	const editing = state?.editing || undefined
	const id = state?.id || undefined

	const { register, control, resetField } = useForm<{ search: string }>({
		mode: 'onChange'
	})
	const watchSearch = useWatch({
		control,
		name: 'search'
	})
	const {
		inputSearch: search,
		handleChangeDebounce,
		resetInput
	} = useSearchDebounce(watchSearch)

	const { isLoading, data: buildApps } = useQuery(
		[
			GET_COMMUNITY_BUILD_APPS,
			{
				columnOrder: 'app_name',
				orderBy,
				page,
				search: search?.trim(),
				size: 10
			}
		],
		() =>
			getCommunityBuildApps({
				columnOrder: 'app_name',
				orderBy: orderBy ?? undefined,
				page,
				search: search?.trim(),
				size: 10
			})
	)

	const { isLoading: loadingData, data: dataToEdit } = useQuery(
		[
			APP_BUILD_DATA_EDIT,
			id,

			{
				columnOrder: 'app_name',
				orderBy,
				page,
				search: search?.trim(),
				size: 10
			}
		],
		() =>
			getBuildAppOrganization({
				id,
				columnOrder: 'app_name',
				orderBy: orderBy ?? undefined,
				page,
				search: search?.trim(),
				size: 10
			}),
		{
			enabled: id !== undefined
		}
	)
	const { data: dataToEditAll, refetch } = useQuery(
		[
			APP_BUILD_DATA_EDIT,
			id,
			isChangeActive,
			{
				columnOrder: 'app_name',
				orderBy
			}
		],
		() =>
			getBuildAppOrganization({
				id,
				columnOrder: 'app_name',
				orderBy: orderBy ?? undefined,
				page
			}),
		{
			enabled: id !== undefined
		}
	)

	useEffect(() => {
		if (dataToEdit && !selectedBuild && dataToEditAll) {
			setIsLoadingLocal(true)
			const dataActive = dataToEditAll.items.find(e => e.selected === true)
			if (dataActive) setSelectedBuild(dataActive.id)
			setIsLoadingLocal(false)
		}
	}, [dataToEdit, selectedBuild, dataToEditAll])

	const updateAppBuildOrganization = async (): Promise<void> => {
		const data = {
			build_app_id: selectedBuild
		}

		const response = await updateAppBuild(id, data)

		if (response && response.message === 'Update Build app sucessfully') {
			showToast('Success', `${response.message}`, 'success')
			setSelectedBuild(null)
			setIsChangeActive(!isChangeActive)
			refetch().then()
			navigate('/superAdmin/organization')
			setIsChangeData(!isChangeData)
		}
	}

	return (
		<div>
			{loadingData && dataToEdit ? (
				<div className="flex h-[60vh] w-full items-center justify-center">
					<CircularProgress disableShrink />
				</div>
			) : (
				<>
					<div className="flex justify-between mb-5">
						<Title
							icon={Icons.groups}
							title="Select Your Build App"
							subtitle="Set app build for your organization"
						/>
						{stepper}
					</div>
					<div className="flex py-3 w-full">
						<Input
							rightClick={() => {
								resetInput()
								resetField('search')
							}}
							name="search"
							onChange={handleChangeDebounce}
							search
							register={register}
							className="w-full"
							placeholder="Search an organization"
							rightIcon={Icons.cancel}
							inputClassName="!p-[unset] !py-1 !px-2"
							inputFocus
							borderFull={false}
						/>
					</div>
					<BuildAppList
						page={page}
						loading={(isLoading || loadingData) && !isLoadingLocal}
						onSelect={setSelectedBuild}
						onSort={(_header, sort) => setOrderBy(sort)}
						data={
							dataToEdit
								? dataToEdit?.items.map(element => ({
										id: element.id,
										name: element.app_name || ''
								  })) || []
								: buildApps?.items.map(build => ({
										id: build.id,
										name: build.app_name || ''
								  })) || []
						}
						dataSelected={[selectedBuild ?? -1]}
					/>

					<AdminOrganizationStepFooter
						className="mt-4 mb-2"
						onBack={() =>
							editing
								? navigate('/superAdmin/organization')
								: onBack?.(selectedBuild)
						}
						onNext={() => {
							if (selectedBuild) onNext?.(selectedBuild)
						}}
						disabledNext={!selectedBuild}
						save={editing}
						onSave={updateAppBuildOrganization}
					/>
					<Pagination
						totalRecords={buildApps?.total || 0}
						pageLimit={buildApps?.size}
						page={page}
						onPageChanged={({ currentPage }) => {
							setPage(currentPage || 1)
						}}
					/>
				</>
			)}
		</div>
	)
}
