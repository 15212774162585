/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * TODO:
 * Schedule appointment for review.
 */

import {
	GET_CONTENTS,
	getContentsList,
	patchEditMyFeed,
	postNewMyFeeds
} from 'api'
import {
	Button,
	Checkbox,
	ContentFeedsTable,
	Input,
	TailwindModal,
	Typography
} from 'components/index'
import { PaginationContext } from 'context'
import { useSearchDebounce, useToast } from 'hooks'
import {
	EditMyFeedsRequest,
	FeedContetMyFeedByIdResponse,
	GenericModalProps,
	Icons,
	NewMyFeedsForm,
	NewMyFeedsRequest,
	TypographyTypes
} from 'interfaces'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'

const MAX_SEARCH_LENGHT = 100

export const NewPartnerFeedsModal: React.FC<GenericModalProps<any>> = ({
	open,
	data,
	setOpen,
	refetch
}) => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const [search, setSearch] = useState('')
	const { inputSearch, handleChangeDebounce, resetInput } =
		useSearchDebounce('')
	const [page, setPage] = useState(1)
	const allConnectionsRef = useRef<HTMLInputElement>(null)
	const [dataSelected, setDataSelected] = useState<number[]>([])
	const { tables } = useContext(PaginationContext)
	const [isActiveContent, setIsActiveContent] = useState<boolean>(false)

	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors }
	} = useForm<NewMyFeedsForm>()

	const { data: contents, isLoading } = useQuery(
		[
			GET_CONTENTS,
			userId,
			organizationId,
			page,

			inputSearch,
			tables.myContentList.column,
			tables.myContentList.order
		],
		() =>
			getContentsList(
				organizationId,
				page,
				'published',
				'',
				inputSearch,
				tables.myContentList.column,
				tables.myContentList.order
			)
	)

	useEffect(() => {
		if (data) {
			setValue('name', data.name)
			if (data.feed_contents.length > 0) {
				setIsActiveContent(true)
				const newContents = data.feed_contents.map(
					(item: FeedContetMyFeedByIdResponse) => item.id
				)
				setDataSelected(newContents)
			}
		}
	}, [data])

	const { showToast } = useToast()

	const handleSubmitForm: SubmitHandler<NewMyFeedsForm> = async values => {
		if (data) {
			const body: EditMyFeedsRequest = {
				name: values.name,
				content_ids: dataSelected
			}
			patchEditMyFeed(organizationId, data.id, body).then(response => {
				if (response?.detail) {
					showToast('Error', response.detail, 'error')
				} else {
					showToast('Success', 'Feeds fed successfully', 'success')
					setOpen(false)
					refetch?.()
				}
			})
		} else {
			const body: NewMyFeedsRequest = {
				name: values.name,
				add_content_ids: dataSelected
			}
			postNewMyFeeds(organizationId, body).then(response => {
				if (response?.detail) {
					showToast('Error', response.detail, 'error')
				} else {
					showToast('Success', 'Feeds fed successfully', 'success')
					setOpen(false)
					refetch?.()
				}
			})
		}
	}

	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			title="Create New FM Feed"
			className="w-full md:w-[70%] p-4 rounded-[16px]"
		>
			<form
				onSubmit={handleSubmit(handleSubmitForm)}
				className="flex flex-col gap-4 w-full mt-4 overflow-y-auto"
			>
				<div className="flex w-full items-center gap-4">
					<Input
						title="Feed Name"
						name="name"
						placeholder="Name"
						register={register}
						className=" !p-[unset]"
						maxLength={MAX_SEARCH_LENGHT}
						borderFull
						inputClassName="!p-[unset] !py-1 !px-2"
						rules={{ required: 'Name is required' }}
						error={errors.name}
					/>
				</div>
				<div className="flex  items-center">
					<Checkbox
						onChange={() => {
							setIsActiveContent(!isActiveContent)
						}}
						ref={allConnectionsRef}
						className=" border-primary-9"
						checked={isActiveContent}
					/>
					<Typography
						className="text-[#A3A3A3] !font-normal ml-2"
						type={TypographyTypes.h1}
						fontLeading="18/21"
						title="Add Active Content Now"
					/>
				</div>

				{isActiveContent ? (
					<>
						<Typography
							className="text-[#A3A3A3] !font-normal "
							type={TypographyTypes.h1}
							fontLeading="15/18"
							title="Select Content"
						/>
						<div className="flex w-full items-center gap-4">
							<Input
								name="message"
								placeholder="Search Content, Title, Creator"
								className="!w-full !p-[unset]"
								onChange={e => {
									setSearch(e.target.value)
									handleChangeDebounce(e)
								}}
								search
								rightIcon={!search ? Icons.search : Icons.cancel}
								value={search}
								rightClick={() => {
									if (search) {
										resetInput()
										setSearch('')
									}
								}}
								maxLength={MAX_SEARCH_LENGHT}
								error={
									search.length >= MAX_SEARCH_LENGHT
										? {
												type: 'maxLength',
												message: `Maximum ${MAX_SEARCH_LENGHT} characters`
										  }
										: undefined
								}
								borderFull={false}
								inputClassName="!p-[unset] !py-1 !px-2"
								inputFocus
							/>
						</div>

						<ContentFeedsTable
							data={contents}
							isLoading={isLoading}
							page={page}
							setPage={setPage}
							setDataSelected={setDataSelected}
							dataSelected={dataSelected}
						/>
					</>
				) : null}

				<div className="w-full flex justify-between md:justify-center items-center gap-2.5">
					<Button
						label="Cancel"
						className="!min-w-[unset] !py-[9px] w-[190px]"
						color="Bg-gray"
						onClick={() => setOpen(!open)}
					/>
					<Button
						label="Create"
						type="submit"
						key={dataSelected.length}
						disabled={isActiveContent && dataSelected.length === 0}
					/>
				</div>
			</form>
		</TailwindModal>
	)
}
