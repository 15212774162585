import { Disclosure } from '@headlessui/react'
import { getUploadImageCover, getUploadImageIcon, updateImage } from 'api'
import { Button, Helper, Icon, Typography } from 'components'
import { useDebounceEffect, useToast } from 'hooks'
import {
	Icons,
	PercentCrop,
	ProfileImageMyOrganizationItem,
	ProfileImages,
	SlideFormProps,
	TypographyTypes
} from 'interfaces'
import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
	Crop,
	PixelCrop,
	ReactCrop,
	centerCrop,
	makeAspectCrop
} from 'react-image-crop'
import Cookies from 'universal-cookie'
import { canvasPreview, configGenaral } from 'utils'

export const SlideProfileImages: React.FC<
	SlideFormProps & {
		onSave: (result: ProfileImageMyOrganizationItem) => Promise<void>
	}
> = ({ data, setData, setOpen, setLoading, onSave }): JSX.Element => {
	const { showToast } = useToast()
	const previewCanvasRef = useRef<HTMLCanvasElement | null>(null)
	const imgRef = useRef<HTMLImageElement>(null)
	const [crop, setCrop] = useState<Crop | null>()
	const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>()
	const [isCropComplete, setIsCropComplete] = useState(false)
	const [isActiveFinish, setIsActiveFinish] = useState(false)
	const [scale, setScale] = useState(1)
	const [isCropSettingsVisible, setIsCropSettingsVisible] = useState(true)
	const [isImageUploaded, setIsImageUploaded] = useState(false)
	const [isFinishCropButtonVisible, setIsFinishCropButtonVisible] =
		useState(true)
	const [croppedImage, setCroppedImage] = useState<string | undefined>(
		data?.profile_image.cover
			? `${data?.profile_image.cover}?timestamp=${Date.now()}`
			: undefined
	)
	const [selectImageLogo, setSelectImageLogo] = useState<string | undefined>(
		data?.profile_image.icon
			? `${data?.profile_image.icon}?timestamp=${Date.now()}`
			: undefined
	)

	const { register } = useForm<ProfileImages>({
		mode: 'all'
	})

	const registerInputCover = register('coverUrl', {
		required: { value: true, message: 'Is required' }
	})
	const registerInputLogo = register('logoUrl', {
		required: { value: true, message: 'Is required' }
	})

	const cookies = new Cookies()
	const organizationSlug = cookies.get('organization_slug')
	const aspect = 1 / 0.5567
	const updatePhotoCover = async (image: string): Promise<string> => {
		const credentials = await getUploadImageCover(organizationSlug, 'png')
		if (!credentials) return ''
		if (image.includes('s3.amazonaws.com')) return image
		const getImage = await fetch(image)
		const imageBlob = await getImage.blob()
		await updateImage(credentials.url, {
			...credentials.fields,
			file: imageBlob
		})
		const url = `${credentials.url}${credentials.fields.key}`
		return url
	}

	const handleFinishCrop = (): void => {
		if (completedCrop && previewCanvasRef.current) {
			const canvas = previewCanvasRef.current
			const resizedCanvas = document.createElement('canvas')
			const resizedCtx = resizedCanvas.getContext('2d')

			resizedCanvas.width = 1920
			resizedCanvas.height = 1069

			if (resizedCtx !== null) {
				resizedCtx.imageSmoothingEnabled = false
				resizedCtx.drawImage(canvas, 0, 0, 1920, 1069)
			}

			const croppedImageData = resizedCanvas.toDataURL('image/png')
			setCroppedImage(croppedImageData)
			setIsCropSettingsVisible(false)
			setIsFinishCropButtonVisible(false)
			setIsCropComplete(true)
			setScale(1)
		}
	}

	useDebounceEffect(
		async () => {
			if (
				completedCrop?.width &&
				completedCrop?.height &&
				imgRef.current &&
				previewCanvasRef.current
			) {
				canvasPreview(
					imgRef.current,
					previewCanvasRef.current,
					completedCrop,
					scale
				)
			}
		},
		500,
		[completedCrop, scale]
	)

	const centerAspectCrop = (
		mediaWidth: number,
		mediaHeight: number,
		aspectt: number
	): PercentCrop => {
		return centerCrop(
			makeAspectCrop(
				{
					unit: '%',
					width: 90
				},
				aspectt,
				mediaWidth,
				mediaHeight
			),
			mediaWidth,
			mediaHeight
		)
	}

	const resetCrop = (): void => {
		setCrop(null)
		setCompletedCrop(null)
	}

	const updatePhotoIcon = async (image: string): Promise<string> => {
		const credentials = await getUploadImageIcon(organizationSlug, 'png')
		if (!credentials) return ''
		if (image.includes('s3.amazonaws.com')) return image
		const getImage = await fetch(image)

		const imageBlob = await getImage.blob()
		await updateImage(credentials.url, {
			...credentials.fields,
			file: imageBlob
		})
		const url = `${credentials.url}${credentials.fields.key}`
		return url
	}

	const saveProfileImages = async (): Promise<void> => {
		if (setLoading) {
			setOpen(false)
			setLoading(true)
			let coverUrl = null
			let iconUrl = null
			if (croppedImage) {
				coverUrl = await updatePhotoCover(croppedImage)
			}
			if (selectImageLogo) {
				iconUrl = await updatePhotoIcon(selectImageLogo)
			}

			if (data) {
				const aux = {
					...data,
					profile_image: {
						...data.profile_image,
						cover: coverUrl ?? '',
						icon: iconUrl ?? ''
					}
				}

				setData(aux)
				onSave?.(aux.profile_image)
				setLoading(false)
			}
		}
	}

	const MIN_WIDTH_DIMENSION = 1920
	const MIN_HEIGHT_DIMENSION = 1069

	const MIN_WIDTH_DIMENSION_LOGO = 1080
	const MIN_HEIGHT_DIMENSION_LOGO = 1080

	const handleMediaUploadCover = (file: File): void => {
		const fileSize = file.size
		const fileMb = fileSize / 1024 ** 2

		if (fileMb <= configGenaral.maxfileSizeMB) {
			const reader = new FileReader()
			reader.onload = async e => {
				const image = new Image()
				image.onload = () => {
					if (
						image.width >= MIN_WIDTH_DIMENSION &&
						image.height >= MIN_HEIGHT_DIMENSION
					) {
						const uploadedObj = { file, url: e.target?.result as string }
						setCroppedImage(uploadedObj.url)
						setIsImageUploaded(true)
						const { width, height } = image
						const newCrop = centerAspectCrop(width, height, aspect)
						setCrop(newCrop)
					} else {
						showToast(
							'Error',
							`Please upload an image with dimensions of at least ${MIN_WIDTH_DIMENSION}x${MIN_HEIGHT_DIMENSION} pixels.`,
							'error'
						)
					}
				}
				image.src = e.target?.result as string
			}
			reader.readAsDataURL(file)
		} else {
			showToast(
				'Error',
				`Please select a file less than ${configGenaral.maxfileSizeMB}MB.`,
				'error'
			)
		}
	}

	const handleMediaUploadLogo = (file: File): void => {
		const fileSize = file.size
		const fileMb = fileSize / 1024 ** 2

		if (fileMb <= configGenaral.maxfileSizeMB) {
			const reader = new FileReader()
			reader.onload = async e => {
				const image = new Image()
				image.onload = () => {
					if (
						image.width >= MIN_WIDTH_DIMENSION_LOGO &&
						image.height >= MIN_HEIGHT_DIMENSION_LOGO
					) {
						const uploadedObj = { file, url: e.target?.result as string }
						setSelectImageLogo(uploadedObj.url)
						setIsCropComplete(true)
					} else {
						showToast(
							'Error',
							`Please upload an image with dimensions of at least ${MIN_WIDTH_DIMENSION_LOGO} x ${MIN_HEIGHT_DIMENSION_LOGO} pixels.`,
							'error'
						)
					}
				}
				image.src = e.target?.result as string
			}
			reader.readAsDataURL(file)
		} else {
			showToast(
				'Error',
				`Please select a file less than ${configGenaral.maxfileSizeMB}MB.`,
				'error'
			)
		}
	}

	const handleDeleteLogo = (): void => {
		setSelectImageLogo(undefined)
		setIsCropComplete(false)
	}

	const handleDeleteImage = (): void => {
		setCroppedImage(undefined)
		resetCrop()
		setIsImageUploaded(false)
		setIsCropSettingsVisible(true)
		setIsFinishCropButtonVisible(true)
		setIsCropComplete(false)
		setIsActiveFinish(false)
		setScale(1)
	}
	const handleControlZoom = (): void => {
		if (completedCrop) {
			if (scale <= 0.5) {
				setScale(0.5)
			}
		}
	}

	return (
		<form className="mt-8 flex flex-col justify-between h-[95%]">
			<div className="mb-2 flex flex-col gap-12">
				<Disclosure>
					{({ open }) => (
						<>
							<Disclosure.Button className="flex items-center justify-between w-full">
								<Typography
									title="Cover Photo"
									type={TypographyTypes.h3}
									className="text-gray-9 !font-bold"
									fontLeading="15/18"
								/>
								<Icon
									src={open ? Icons.arrowUp : Icons.arrowDown}
									className="w-4"
								/>
							</Disclosure.Button>
							<Disclosure.Panel>
								<div className="py-5 flex flex-col gap-6">
									{croppedImage !== undefined && (
										<div className="relative ">
											<div className=" flex justify-center !items-center ">
												<ReactCrop
													crop={crop as Crop}
													aspect={16 / 9}
													onChange={(_, percentCrop) => {
														setCrop(percentCrop)
														setIsActiveFinish(false)
													}}
													onComplete={c => {
														setCompletedCrop(c)
														setIsActiveFinish(true)
													}}
													locked
												>
													<img
														src={croppedImage}
														alt="Cover"
														className="w-full h-[19rem] !object-contain"
														style={{
															transform: `scale(${scale})`
														}}
														ref={imgRef}
													/>
												</ReactCrop>
												{croppedImage !== undefined && (
													<button
														type="button"
														onClick={() => handleDeleteImage()}
														className="absolute top-1 right-2 bg-gray-18 p-1 rounded-full border-2 border-gray-3"
													>
														<Icon src={Icons.close} className="w-3 h-3 " />
													</button>
												)}
											</div>
										</div>
									)}

									{isCropSettingsVisible && isImageUploaded && (
										<div className="flex flex-col border-blue-primary justify-center w-[550px] pl-2 h-[100px] border-solid border-2 rounded-lg m-2 p-2">
											<Typography
												fontLeading="15/18"
												type={TypographyTypes.p}
												className="!font-normal text-black-1 mt-2 ml-2"
												title="Zoom:"
											/>
											<div className="flex flex-row">
												<div className="flex justify-around w-full m-2 p-2">
													<Button
														type="button"
														value={scale}
														onClick={() =>
															setScale(prevScale => prevScale + 0.01)
														}
														className="min-w-[40px] !py-1"
														color="Transparent"
														disabled={!completedCrop}
													>
														<Icon
															src={Icons.zoomIn}
															className="w-[25px]	h-[25px]"
														/>
													</Button>
													<Button
														type="button"
														value={scale}
														onClick={() => {
															setScale(prevScale => prevScale - 0.01)
															handleControlZoom()
														}}
														className="min-w-[40px] !py-1"
														color="Transparent"
														disabled={!completedCrop}
													>
														<Icon
															src={Icons.zoomOut}
															className="w-[25px] h-[25px]"
														/>
													</Button>
												</div>
											</div>
										</div>
									)}
									{isFinishCropButtonVisible && isImageUploaded && (
										<div className="pt-2">
											<Button
												label="Finish Crop"
												type="button"
												color="Primary"
												className="w-full mt-1"
												onClick={() => {
													handleFinishCrop()
													setIsCropSettingsVisible(false)
													setIsFinishCropButtonVisible(false)
													resetCrop()
												}}
												disabled={!isActiveFinish}
											/>
										</div>
									)}
									{!!completedCrop && (
										<canvas
											id="croppedCanvas"
											className="h-0 w-0 rounded-lg border-blue-300"
											ref={previewCanvasRef}
											style={{
												visibility: 'hidden',
												display: 'hidden',
												objectFit: 'contain'
											}}
										/>
									)}
									{croppedImage === undefined && (
										<div className="flex justify-between items-center">
											<input
												id="coverUrl"
												type="file"
												className="hidden"
												{...registerInputCover}
												onChange={event => {
													if (event.target.files) {
														handleMediaUploadCover(event.target.files[0])
													}
												}}
												accept="image/png, image/jpeg, image/jpg"
												maxLength={10}
											/>
											<Helper
												text="Upload an image that represents your organization. Supported formats: JPG, JPEG, PNG. Max. Size: 10 MB. Dimensions: Min 1080x1080 px."
												className="relative"
											/>
											<Button
												label="Upload"
												color="Transparent"
												iconLeft={Icons.addPhoto}
												onClick={() => {
													const input = document.getElementById('coverUrl')
													if (input) input.click()
												}}
											/>
										</div>
									)}
								</div>
							</Disclosure.Panel>
						</>
					)}
				</Disclosure>
				<Disclosure>
					{({ open }) => (
						<>
							<Disclosure.Button className="flex items-center justify-between w-full">
								<Typography
									title="Organization Logo"
									type={TypographyTypes.h3}
									className="text-gray-9 !font-bold"
									fontLeading="15/18"
								/>
								<Icon
									src={open ? Icons.arrowUp : Icons.arrowDown}
									className="w-4"
								/>
							</Disclosure.Button>
							<Disclosure.Panel>
								<div className="py-5 flex flex-col gap-6">
									{data && selectImageLogo && (
										<div className="bg-gray-3 flex items-center justify-center relative">
											<img
												className="h-[20rem] object-contain"
												src={selectImageLogo}
												alt="Icon"
											/>
											{selectImageLogo !== undefined && (
												<button
													type="button"
													onClick={() => handleDeleteLogo()}
													className="absolute top-1 right-2 bg-gray-18 p-1 rounded-full border-2 border-gray-3"
												>
													<Icon src={Icons.close} className="w-3 h-3 " />
												</button>
											)}
										</div>
									)}
									{selectImageLogo === undefined && (
										<div className="flex justify-between items-center">
											<input
												id="logoUrl"
												type="file"
												className="hidden"
												{...registerInputLogo}
												onChange={event => {
													if (event.target.files) {
														handleMediaUploadLogo(event.target.files[0])
													}
												}}
												accept="image/png, image/jpeg, image/jpg"
											/>
											<Helper
												text="Upload an image that represents your organization. Supported formats: JPG, JPEG, PNG. Max. Size: 10 MB. Dimensions: Min 1080x1080 px."
												className="relative"
											/>
											<Button
												label="Upload"
												color="Transparent"
												iconLeft={Icons.addPhoto}
												onClick={() => {
													const input = document.getElementById('logoUrl')
													if (input) input.click()
												}}
											/>
										</div>
									)}
								</div>
							</Disclosure.Panel>
						</>
					)}
				</Disclosure>
			</div>
			<div className="w-full flex justify-between items-center">
				<Button
					label="Cancel"
					color="Gray2"
					small
					onClick={() => setOpen(false)}
				/>
				<Button
					label="Save"
					small
					onClick={() => saveProfileImages()}
					disabled={(!selectImageLogo && !croppedImage) || !isCropComplete}
				/>
			</div>
		</form>
	)
}
