export const generateTransform = (
	scale: number,
	rotate: number,
	flipHorizontal: boolean,
	flipVertical: boolean
): string => {
	return `scale(${scale}) rotate(${rotate}deg) ${
		flipHorizontal ? 'scaleX(-1)' : ''
	} ${flipVertical ? 'scaleY(-1)' : ''}`
}
