/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * TODO:
 * Schedule appointment for review.
 */

import React, { useEffect, useState } from 'react'

export const useSelect = (
	data: any[],
	dataSelected?: number[],
	setDataSelected?: React.Dispatch<React.SetStateAction<number[]>>,
	idName = 'id'
): {
	selectAll: boolean
	handleSelectAll: () => void
	handleSelected: (itemID: number) => void
} => {
	const [selectAll, setSelectAll] = useState(false)

	const handleSelectAll = (): void => {
		if (dataSelected) {
			if (selectAll) {
				const dataPrueba = dataSelected.filter(
					item => !data.some(row => row.organization_follower_id === item)
				)
				setDataSelected?.(dataPrueba)
				setSelectAll(false)
			} else {
				setDataSelected?.(prevDataSelected => {
					const allRowIds = data.map(row => row[idName])
					const newIds = allRowIds.filter(id => !prevDataSelected.includes(id))
					const updatedSelection = [...prevDataSelected, ...newIds]
					setSelectAll(true)
					return updatedSelection
				})
			}
		}
	}

	const handleSelected = (itemID: number): void => {
		if (dataSelected && setDataSelected) {
			const newDataSelected = dataSelected.slice()
			const dataIndex = dataSelected.indexOf(itemID)
			if (dataIndex === -1) {
				newDataSelected.push(itemID)
			} else {
				newDataSelected.splice(dataIndex, 1)
			}
			setDataSelected(newDataSelected)
		}
	}

	useEffect(() => {
		if (dataSelected && setDataSelected) {
			const selected = data.some(
				e => !dataSelected.includes(e.organization_follower_id)
			)
			setSelectAll(!selected)
		} else {
			setSelectAll(false)
		}
	}, [dataSelected, data])

	return { selectAll, handleSelectAll, handleSelected }
}
