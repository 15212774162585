import clsx from 'clsx'
import {
	AdminOrganizationStepFooter,
	MainContact,
	OrganizationData,
	Settings,
	Tabs,
	Title
} from 'components'
import { useToast } from 'hooks'
import {
	AdminOrganizationStepComponentProps,
	Icons,
	OrganizationInformationForm,
	TabsOption
} from 'interfaces'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'

export const OrganizationInformation: React.FC<
	AdminOrganizationStepComponentProps<OrganizationInformationForm>
> = ({ stepper, onNext, onBack, data, information, onSave, refetch }) => {
	const [currentTab, setCurrentTab] = useState(0)
	const { showToast } = useToast()
	const { state } = useLocation()
	const editing = state?.editing || undefined
	const id = state?.id || undefined
	const form = useForm<OrganizationInformationForm>({
		defaultValues: data,
		mode: 'onChange'
	})
	const tabs: (TabsOption & { component: React.ReactElement })[] = [
		{
			label: 'Organization Data',
			value: 0,
			component: (
				<OrganizationData
					organizationData={information.organizationData}
					editing={editing && id}
					refetch={refetch}
					settings={information.settings}
				/>
			)
		},
		{
			label: 'Main Contact',
			value: 1,
			component: (
				<MainContact
					mainContact={information.mainContact}
					editing={editing && id}
				/>
			)
		},
		{
			label: 'Settings',
			value: 2,
			component: (
				<Settings
					settings={information.settings}
					editing={editing && id}
					views={information.views}
				/>
			)
		}
	]

	return (
		<FormProvider {...form}>
			<div className="flex justify-between mb-5">
				<Title
					icon={Icons.groups}
					title="Organization Information"
					subtitle="Manage Your Organization Data"
				/>
				{stepper}
			</div>
			<Tabs
				className="mb-[21px]"
				options={tabs}
				value={currentTab}
				onChange={setCurrentTab}
			/>
			<div className={clsx(currentTab !== 0 && 'hidden')}>
				{tabs[0].component}
			</div>
			<div className={clsx(currentTab !== 1 && 'hidden')}>
				{tabs[1].component}
			</div>
			<div className={clsx(currentTab !== 2 && 'hidden')}>
				{tabs[2].component}
			</div>
			<AdminOrganizationStepFooter
				className="mt-[29px]"
				save={currentTab === 2 && editing}
				onSave={form.handleSubmit(
					() => onSave?.(form.getValues()),
					e => {
						const tabsWithErrors = [
							e.organizationData ? 'Organization Data' : null,
							e.mainContact ? 'Main Contact' : null,
							e.settings ? 'Settings' : null
						].filter(tab => tab !== null)
						showToast(
							'Error',
							`There are errors in the ${tabsWithErrors.join(' and ')} tab${
								tabsWithErrors.length > 1 ? 's' : ''
							}`,
							'error'
						)
					}
				)}
				saveDraft
				onBack={() => {
					if (currentTab === 1) {
						setCurrentTab(0)
						return
					}
					onBack?.(form.getValues())
				}}
				onNext={
					currentTab === 0
						? () => setCurrentTab(1)
						: currentTab === 1
						? () => setCurrentTab(2)
						: form.handleSubmit(
								() => onNext?.(form.getValues()),
								e => {
									const tabsWithErrors = [
										e.organizationData ? 'Organization Data' : null,
										e.mainContact ? 'Main Contact' : null,
										e.settings ? 'Settings' : null
									].filter(tab => tab !== null)
									showToast(
										'Error',
										`There are errors in the ${tabsWithErrors.join(
											' and '
										)} tab${tabsWithErrors.length > 1 ? 's' : ''}`,
										'error'
									)
								}
						  )
				}
			/>
		</FormProvider>
	)
}
