export const calculateWidth = (
	big: boolean,
	huge: boolean,
	barBotton: boolean,
	optionsLength: number
): string => {
	if (big) {
		return '140px'
	}
	if (huge) {
		return `${100 / optionsLength}%`
	}
	if (barBotton) {
		return '150px'
	}
	return ''
}
