import clsx from 'clsx'
import { Typography } from 'components/typography'
import { TypographyTypes } from 'interfaces'
import React from 'react'
import { InputRadioCustomtProps } from './inputRadioCustom.type'

export const InputRadioCustom = (
	props: InputRadioCustomtProps
): JSX.Element => {
	const { title, inputProps = {}, labelProps = {} } = props
	const { className, onChange } = inputProps
	const { htmlFor } = labelProps
	return (
		<div className="flex items-center mb-2">
			<input
				type="radio"
				onChange={onChange}
				className={clsx(
					'h-4 w-4 border-gray-300 text-blue-primary focus:ring-white !bg-none',
					className
				)}
				{...inputProps}
			/>
			<label
				htmlFor={htmlFor}
				className="ml-3 block text-sm font-medium leading-6 text-gray-900"
				{...labelProps}
			>
				<Typography
					title={title ?? ''}
					type={TypographyTypes.h3}
					className="text-gray-9 !font-bold"
					fontLeading="15/18"
				/>
			</label>
		</div>
	)
}
