import { API } from 'api'
import {
	ContentHighLightItemResponse,
	DashboardFollowersResponse,
	DashboardPerformanceResponse,
	MyPeopleResponse,
	NewTagsResponse,
	NotificationsResponse,
	PromotionResponse
} from 'interfaces'

export const getPerformance = async (
	organizationId: number,
	period: string
): Promise<DashboardPerformanceResponse | null> => {
	let res = null as DashboardPerformanceResponse | null
	await API()
		.get(`/dashboard-admin/performance/${organizationId}?period=${period}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getFollowers = async (
	organizationId: number,
	period: string
): Promise<DashboardFollowersResponse[] | null> => {
	let res = null as DashboardFollowersResponse[] | null
	await API()
		.get(`/dashboard-admin/followers/${organizationId}?period=${period}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getBuildAppsDashboard = async (
	period: string
): Promise<DashboardFollowersResponse[] | null> => {
	let res = null as DashboardFollowersResponse[] | null
	await API()
		.get(
			`/dashboard-admin/build_apps/community/${process.env.REACT_APP_COMMUNITY_ID}?period=${period}`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getPromotion = async (
	organizationId: number
): Promise<PromotionResponse | null> => {
	let res = null as PromotionResponse | null
	await API()
		.get(`/dashboard-admin/shared/${organizationId}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getNewTags = async (
	organizationId: number
): Promise<NewTagsResponse[] | null> => {
	let res = null as NewTagsResponse[] | null
	await API()
		.get(`/dashboard-admin/tags_request/${organizationId}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getMyPeople = async (
	organizationId: number
): Promise<MyPeopleResponse[] | null> => {
	let res = null as MyPeopleResponse[] | null
	await API()
		.get(`/dashboard-admin/my_people_list/${organizationId}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getContentHighLightItem = async (
	organizationId: number
): Promise<ContentHighLightItemResponse[] | null> => {
	let res = null as ContentHighLightItemResponse[] | null
	await API()
		.get(`/dashboard-admin/content_highlights/${organizationId}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getNotifications = async (
	organizationId: number
): Promise<NotificationsResponse | null> => {
	let res = null as NotificationsResponse | null
	await API()
		.get(`/dashboard-admin/notifications/${organizationId}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getDashboardReport = async (
	organizationId: number
): Promise<string | null> => {
	let res = null as string | null
	await API()
		.get(`/report/people/my_list/${organizationId}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}
