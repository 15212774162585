import { CircularProgress } from '@mui/material'
import { validateUser } from 'api'
import {
	getAdminExisting,
	getEmailUsing,
	getUserAdmin
} from 'api/get/superAdminDashboard/superAdminDashboard'

import { Button } from 'components/buttons'
import { Checkbox } from 'components/checkbox'
import {
	Input,
	InputFile,
	InputPassword,
	InputPhoneDoble
} from 'components/input'
import { Message } from 'components/message'
import { Typography } from 'components/typography'
import { useToast } from 'hooks'
import {
	AdminForm,
	AdminTeamFormProps,
	Icons,
	TypographyTypes
} from 'interfaces'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'

export const AdminTeamForm: React.FC<AdminTeamFormProps> = props => {
	const {
		roles,
		onCancel,
		onSave,
		admin,
		onValidatingUser,
		registeredEmails,
		validateEmail,
		setValidateEmail,
		isCreatingAdmin
	} = props
	const [loading, setLoading] = useState(false)
	const [emailExists, setEmailExists] = useState(false)
	const [changeRoles, setChangeRoles] = useState(true)
	const [isBtnDisabled, setIsBtnDisabled] = useState(true)

	const { showToast } = useToast()

	const {
		register,
		handleSubmit,
		setValue,
		watch,
		formState: { errors, isDirty },
		trigger
	} = useForm<AdminForm>({
		defaultValues: admin ?? {
			email: '',
			fullName: '',
			password: '',
			phoneNumberCode: '',
			phoneNumber: '',
			image: null,
			roles: []
		}
	})
	const { state } = useLocation()
	const idOrganization = state?.id || undefined

	const getAdminUser = async (idUserAdmin: number): Promise<void> => {
		setValidateEmail(false)
		setLoading(true)
		const responseUser = await getUserAdmin(idOrganization, idUserAdmin)

		if (responseUser?.user) {
			const idRolesCheck = responseUser.user.roles.filter(
				e => e.selected === true
			)
			const allRol = idRolesCheck.map(e => e.id)
			const numberCellAndCode = responseUser.user?.phone_number
				? responseUser.user?.phone_number.split('-')
				: responseUser.user?.phone_number
			setValue(
				'phoneNumber',
				numberCellAndCode ? numberCellAndCode[1] : numberCellAndCode
			)
			setValue('email', responseUser.user.email)
			setValue('fullName', responseUser.user.full_name)
			setValue(
				'phoneNumberCode',
				numberCellAndCode ? numberCellAndCode[0] : numberCellAndCode
			)
			setValue('roles', allRol)
			setValue('id', responseUser.user.user_id)
			setValue('image', null)
			setValue('password', '')
		}
		setLoading(false)
	}

	useEffect(() => {
		if (admin?.id) {
			getAdminUser(admin.id)
			setValidateEmail(true)
		}
	}, [admin])

	useEffect(() => {
		if (isCreatingAdmin) {
			setValidateEmail(false)
			setEmailExists(false)
		}
	}, [isCreatingAdmin])

	register('roles', {
		validate: {
			required: value =>
				value.length >= 1 ? true : 'Admin must have minimun one role'
		}
	})

	const [rolesSelected, image] = watch(['roles', 'image'])

	const validateIfUserExists: SubmitHandler<AdminForm> = async data => {
		setLoading(true)
		const rolesId = data.roles.map(id => +id)
		try {
			onValidatingUser?.()
			if (admin?.id) {
				const emailUsing = await getEmailUsing(data.email, admin.id)
				if (emailUsing?.user && data.email !== admin.email) {
					showToast(
						'Error',
						`This email is being used by another admin`,
						'error'
					)
				} else {
					onSave({ ...data, roles: rolesId })
					setValidateEmail(false)
					setEmailExists(false)
					onCancel()
				}
			} else {
				const user = await validateUser(data.email)
				if (user?.user_id) {
					const numberCellAndCode = user.phone_number.split('-')
					onSave({
						email: user.email,
						fullName: user.full_name,
						image: data.image,
						password: data.password,
						phoneNumberCode:
							numberCellAndCode[0].length > 3 ? '' : numberCellAndCode[0],
						phoneNumber:
							numberCellAndCode[0].length > 3
								? numberCellAndCode[0]
								: numberCellAndCode[1],
						roles: rolesId,
						id: user.user_id
					})
					return
				}
				onCancel()

				onSave({ ...data, roles: rolesId })
			}
		} catch {
			onSave({ ...data, roles: rolesId })
		} finally {
			setLoading(false)
		}
	}

	const verifyEmail = async (email: string): Promise<void> => {
		const getCurrentEmail = registeredEmails.includes(email)
		if (getCurrentEmail) {
			showToast(
				'Error',
				`An admin has already been registered with this email`,
				'error'
			)
		} else {
			setLoading(true)
			const response = await getAdminExisting(email)
			if (response?.user_id) {
				setEmailExists(true)
			} else {
				setEmailExists(false)
				setValidateEmail(true)
			}
			setLoading(false)
		}
	}

	const rolesConfig = {
		confirmNewPassword: {
			required: {
				value: admin === undefined,
				message: 'Password is required'
			}
		}
	}

	const validateChangeRoles = (): void => {
		if (admin) {
			rolesSelected.sort((a, b) => a - b)
			admin.roles.sort((a, b) => a - b)
			const isEqual =
				rolesSelected.length === admin.roles.length &&
				rolesSelected.every((value, index) => value === admin.roles[index])
			setChangeRoles(isEqual)
		}
	}

	useEffect(() => {
		validateChangeRoles()
	}, [rolesSelected])

	useEffect(() => {
		setIsBtnDisabled(Boolean(!isDirty && changeRoles && admin))
	}, [isDirty, changeRoles])

	if (loading)
		return (
			<div className="flex h-full w-full justify-center items-center">
				<CircularProgress disableShrink />
			</div>
		)
	return (
		<form
			className="mt-6 flex flex-col gap-6"
			onSubmit={handleSubmit(validateIfUserExists)}
			noValidate
		>
			{validateEmail && (
				<Input
					name="fullName"
					type="text"
					title="Full Name"
					placeholder="Type admin Name here"
					register={register}
					rules={{ required: 'Full name is required' }}
					error={errors.fullName}
				/>
			)}
			<Input
				name="email"
				title="Email"
				type="email"
				placeholder="Type admin Email here"
				register={register}
				rules={{
					required: 'Email is required',
					validate: (value: string) =>
						registeredEmails.includes(value) && admin && !admin.id
							? 'An admin has already been registered with this email'
							: true
				}}
				error={errors.email}
			/>
			{validateEmail && (
				<>
					<InputPassword
						name="password"
						title="Password"
						placeholder="Type a password for your admin here"
						register={register}
						rules={rolesConfig.confirmNewPassword}
						error={errors.password}
						validate={!admin?.id}
					/>
					<InputPhoneDoble
						name="phoneNumberCode"
						name2="phoneNumber"
						title="Phone Number"
						register={register}
						setValue={setValue}
						rules={{ required: ' ' }}
						rules2={{ required: 'Phone is required' }}
						error={errors.phoneNumberCode}
						error2={errors.phoneNumber}
					/>
					<InputFile
						onChange={e => {
							const file = e.target.files?.item(0)
							if (file) setValue('image', file)
						}}
						onClick={e => {
							if (image) {
								e.preventDefault()
								setValue('image', null)
							}
						}}
						accept="image/*"
						renderButton={
							<Button
								hidden
								className="w-full !py-2"
								color="Gray2"
								type="button"
								label={image ? image.name : 'Upload Photo'}
								iconLeft={image ? Icons.close : Icons.upload}
							/>
						}
					/>
				</>
			)}

			{(emailExists || validateEmail) && (
				<>
					<div className="flex flex-col gap-6">
						{roles.length === 0 ? (
							<Typography
								fontLeading="12/12"
								title="No roles available"
								type={TypographyTypes.span}
							/>
						) : (
							roles
								.filter(role => role.id !== 5) // SUPER ADMIN ROLE
								.map(role => (
									<div className="flex gap-[9px]" key={role.id}>
										<Checkbox
											value={role.id}
											name="roles[]"
											checked={rolesSelected.includes(role.id)}
											onChange={e => {
												setValue(
													'roles',
													e.target.checked
														? [...rolesSelected, role.id]
														: rolesSelected.filter(roleId => roleId !== role.id)
												)
												trigger('roles')
											}}
										/>
										<div className="flex flex-col gap-3">
											<Typography
												className="!leading-none !font-bold"
												title={role.name}
												fontLeading="14/24"
												type={TypographyTypes.span}
											/>
											<Typography
												className="!text-gray-9 !font-normal"
												title={role.description}
												fontLeading="14/24"
												type={TypographyTypes.p}
											/>
										</div>
									</div>
								))
						)}
					</div>
					<div>
						<hr />
						{errors.roles?.message && (
							<Message text={errors.roles.message} type="error" />
						)}
					</div>
					<div className="flex justify-between gap-[12px]">
						<Button
							onClick={() => {
								onCancel()
								setEmailExists(false)
								setValidateEmail(false)
							}}
							className="!py-1"
							color="Transparent"
							label="Cancel"
						/>
						<Button
							className="!py-1"
							color="Primary"
							type="submit"
							label="Save"
							disabled={isBtnDisabled}
						/>
					</div>
				</>
			)}
			{!validateEmail && !emailExists && (
				<Button
					className="!py-1"
					color="Primary"
					type="button"
					label="validate"
					onClick={() => verifyEmail(watch('email'))}
				/>
			)}
		</form>
	)
}
