import CryptoJS from 'crypto-js'

type typeCrypto = 'encrypt' | 'decrypt'

export const encryptData = (data: string, type: typeCrypto): string => {
	const secretKey = process.env.REACT_APP_SECRET_KEY
	if (secretKey) {
		// encrypt
		if (type === 'encrypt' && secretKey) {
			const cifrado = CryptoJS.AES.encrypt(data, secretKey).toString()
			return cifrado
		}
		// decrypt
		const bytes = CryptoJS.AES.decrypt(data, secretKey)
		const textoDescifrado = bytes.toString(CryptoJS.enc.Utf8)
		return textoDescifrado
	}
	return 'An error occurred'
}
