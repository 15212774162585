const handleError = (state: string[], nameType: string): string[] => {
	if (state.includes(nameType)) {
		return state
	}
	return [...state, ...[nameType]]
}

export const handleValid = (
	nameType: string,
	isValid: boolean,
	setFieldError: React.Dispatch<React.SetStateAction<string[]>>
): void => {
	if (isValid) {
		setFieldError(prev => {
			const response =
				prev && prev.length > 0 ? prev.filter(e => e !== nameType) : []
			return response
		})
	} else {
		setFieldError(prev => {
			return handleError(prev, nameType)
		})
	}
}
