/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	GET_PEOPLE_MYPEOPLE_TABLE,
	GET_PEOPLE_TAGS_TABLE,
	getPeopleMyPeopleTable,
	getPeopleTagsTable
} from 'api'
import {
	BatchesTags,
	Button,
	Icon,
	Input,
	Layout,
	PeopleBatchesConfirmModal,
	PeopleBatchesTable,
	Title,
	Typography
} from 'components'
import { PaginationContext, useContextPeopleBatches } from 'context'
import { useSearchDebounce } from 'hooks'
import {
	Icons,
	PaginationType,
	PeopleBatchesColumns,
	PeopleBatchesModalType,
	PeopleMyTagsChildrenItem,
	PeopleMyTagsItemProps,
	TypographyTypes
} from 'interfaces'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useInfiniteQuery, useQuery } from 'react-query'
import Cookies from 'universal-cookie'

const customWidth = [5, 25, 35, 15, 20]

export const PeopleBatches = (): JSX.Element => {
	const cookies = new Cookies()
	const [refreshFlag, setRefreshFlag] = useState<number | null>(null)
	const organizationId = cookies.get('organization_id')
	const { tables } = useContext(PaginationContext)
	const [page, setPage] = useState(1)
	const [totalPages, setTotalPages] = useState(1)
	const [open, setOpen] = useState<PeopleBatchesModalType | null>(null)
	const [selectedTags, setSelectedTags] = useState<number[]>([])
	const { selectedUsers, setSelectedUsers } = useContextPeopleBatches()
	const { inputSearch, handleChangeDebounce, resetInput } =
		useSearchDebounce('')
	const { register, resetField } = useForm({
		mode: 'onChange',
		defaultValues: {
			selectAll: false,
			search: ''
		}
	})

	useEffect(() => {
		if (!refreshFlag) {
			setRefreshFlag(Date.now())
		}
		return () => {
			setSelectedUsers([])
		}
	}, [])

	const disableButton = !selectedUsers?.length

	const {
		data: dataPeople,
		isLoading,
		isRefetching: isRefetchingPeople
	} = useQuery({
		queryKey: [
			GET_PEOPLE_MYPEOPLE_TABLE,
			organizationId,
			tables.peopleBatches,
			page,
			inputSearch,
			refreshFlag
		],
		queryFn: () =>
			getPeopleMyPeopleTable(
				organizationId,
				page,
				inputSearch,
				tables.peopleBatches.column,
				tables.peopleBatches.order,
				undefined,
				20
			),
		refetchOnMount: 'always',
		refetchOnWindowFocus: false,
		onSuccess: response => {
			if (response?.pages) {
				setTotalPages(response.pages)
			}
		}
	})

	const {
		data: tagInfinityData,
		isLoading: tagsIsLoading,
		isRefetching: isRefetchingTags,
		hasNextPage: hasNextPageTags,
		fetchNextPage: fetchNextPageTags,
		isFetchingNextPage: isFetchingNextPageTags
	} = useInfiniteQuery({
		queryKey: [
			GET_PEOPLE_TAGS_TABLE,
			organizationId,
			tables.tags.column,
			tables.tags.order
		],
		queryFn: ({ pageParam = 1 }) =>
			getPeopleTagsTable(
				organizationId,
				pageParam,
				'',
				tables.tags.column,
				tables.tags.order,
				15
			),
		getNextPageParam: response => {
			const nextPage = Number(response?.page) + 1
			if (nextPage <= Number(response?.pages)) {
				return nextPage
			}
			return undefined
		},
		refetchOnWindowFocus: false
	})

	const tagData = useMemo(
		() =>
			tagInfinityData?.pages?.map(pageTag => pageTag?.items ?? []).flat() ?? [],
		[tagInfinityData]
	)

	const onPageChange = (pages: PaginationType): void => {
		setPage(pages.currentPage)
	}

	const columns = [
		{
			Header: '',
			checkbox: true,
			accessor: 'user_id'
		},
		{
			Header: 'Name',
			accessor: 'full_name'
		},
		{
			Header: 'Email',
			accessor: 'email'
		},
		{
			Header: 'Status',
			accessor: 'status'
		},
		{
			Header: 'Follower tag',
			accessor: 'followers_tag',
			list: true
		}
	]

	const onClickParent = (tag: PeopleMyTagsItemProps, value: boolean): void => {
		if (value) {
			setSelectedTags(prev => [...prev, tag.organization_tag_parent_id])
		} else {
			setSelectedTags(prev =>
				prev.filter(item => item !== tag.organization_tag_parent_id)
			)
		}
	}

	const onClickChildren = (
		tag: PeopleMyTagsChildrenItem,
		value: boolean
	): void => {
		if (value) {
			setSelectedTags(prev => [...prev, tag.organization_tag_id])
		} else {
			setSelectedTags(prev =>
				prev.filter(item => item !== tag.organization_tag_id)
			)
		}
	}

	const onClickOption = (type: PeopleBatchesModalType): void => {
		setOpen(type)
	}

	return (
		<Layout containerClassName="overflow-y-auto scrollbar-simple h-screen max-h-screen bg-gray-6">
			<div className="3xl:mx-auto 3xl:my-auto 3xl:w-3/4 mx-auto">
				<div className="flex items-center justify-between">
					<div className="hidden sm:block">
						<Title
							icon={Icons.groups}
							title="My People"
							subtitle="Manage your Community"
						/>
					</div>
					<div className="w-full block sm:hidden">
						<Title
							icon={Icons.groups}
							title="My People"
							subtitle="Manage your Community"
							className="bg-white shadow-lg w-full rounded-lg pl-4"
						/>
					</div>
				</div>
				<Button
					label="Back to My People"
					className="!py-1.5 my-2"
					color="Transparent"
					onClick={() => {
						onClickOption(PeopleBatchesModalType.back)
					}}
					iconLeft={Icons.arrowBackIos}
				/>
				<div className="flex flex-col 2lg:flex-row justify-between gap-3 mt-2 h-full">
					<div className="w-full 2lg:w-1/2 flex flex-1 flex-col">
						<Input
							name="search"
							placeholder="Search Followers"
							register={register}
							rightIcon={Icons.cancel}
							rightClick={() => {
								resetField('search')
								resetInput()
							}}
							inputClassName="!py-1"
							inputFieldClassName="focus:ring-blue-primary"
							inputFocus
							onChange={event => {
								handleChangeDebounce(event)
								if (page > 1) {
									setPage(1)
								}
							}}
							borderFull={false}
						/>
						<div className="flex flex-1 flex-col mt-3 bg-white rounded-2xl px-3 pt-4">
							<span className="flex items-center mb-3">
								<Icon src={Icons.settingsUser} className="h-7 w-7 mr-2" />
								<Typography
									title="Batch edit:"
									fontLeading="15/18"
									type={TypographyTypes.h3}
									className="text-blue-primary"
								/>
							</span>
							<PeopleBatchesTable
								columns={columns as PeopleBatchesColumns[]}
								customWidth={customWidth}
								data={dataPeople?.items ?? []}
								onSelectAll={undefined}
								isLoading={isLoading || isRefetchingPeople}
								total={totalPages}
								page={page}
								onPageChanged={onPageChange}
							/>
						</div>
					</div>
					<div className="w-full 2lg:w-1/2 gap-4 flex flex-col">
						<div className="bg-white rounded-2xl h-4/5 pt-4 px-3">
							<span className="flex items-center mb-3">
								<Icon src={Icons.userAdd} className="h-7 w-7 mr-2" />
								<Typography
									title="Select the tags you want to add or remove from the selection:"
									fontLeading="15/18"
									type={TypographyTypes.h3}
									className="text-blue-primary"
								/>
							</span>
							<BatchesTags
								data={tagData ?? []}
								isLoading={tagsIsLoading || isRefetchingTags}
								selectedRowTags={selectedTags}
								disabledActions={disableButton}
								onClickParent={onClickParent}
								onClickChildren={onClickChildren}
								onRemove={() => onClickOption(PeopleBatchesModalType.removeTag)}
								onAdd={() => onClickOption(PeopleBatchesModalType.addTag)}
								hasNextPage={hasNextPageTags}
								fetchNextPage={fetchNextPageTags}
								isFetchingNextPage={isFetchingNextPageTags}
							/>
						</div>
						<div className="flex gap-4 h-1/5">
							<div className="w-1/2 bg-white rounded-2xl flex flex-col justify-between py-4 px-3">
								<span className="flex items-center mb-3">
									<Icon src={Icons.delete} className="h-7 w-7 mr-2" />
									<Typography
										title="Remove users"
										fontLeading="15/18"
										type={TypographyTypes.h3}
										className="text-blue-primary"
									/>
								</span>
								<div className="flex justify-center">
									<button
										className="bg-blue-primary py-2.5 rounded-full w-full disabled:bg-gray-3"
										type="button"
										onClick={() =>
											onClickOption(PeopleBatchesModalType.removeSelected)
										}
										disabled={disableButton}
									>
										<Typography
											title="Remove Selected"
											fontLeading="15/18"
											type={TypographyTypes.h3}
											className="text-white"
										/>
									</button>
								</div>
							</div>
							<div className="w-1/2 bg-white rounded-2xl py-4 px-3 flex flex-col justify-between">
								<span className="flex items-center mb-3">
									<Icon src={Icons.accountOff} className="h-7 w-7 mr-2" />
									<Typography
										title="Change users status"
										fontLeading="15/18"
										type={TypographyTypes.h3}
										className="text-blue-primary"
									/>
								</span>
								<div className="flex flex-col justify-center gap-2 pb-2">
									<button
										className="bg-blue-primary py-2 rounded-full disabled:bg-gray-3"
										type="button"
										onClick={() => onClickOption(PeopleBatchesModalType.block)}
										disabled={disableButton}
									>
										<Typography
											title="Block"
											fontLeading="15/18"
											type={TypographyTypes.h3}
											className="text-white"
										/>
									</button>
									<button
										className="py-1.5 rounded-full border-2 border-blue-primary disabled:border-gray-3 batches-tags-button"
										type="button"
										onClick={() =>
											onClickOption(PeopleBatchesModalType.unblock)
										}
										disabled={disableButton}
									>
										<Typography
											title="Unblock"
											fontLeading="15/18"
											type={TypographyTypes.h3}
											className="text-blue-primary batches-tags-label"
										/>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<PeopleBatchesConfirmModal
					open={open}
					setOpen={setOpen}
					organizationId={organizationId}
					selectedTags={selectedTags}
				/>
			</div>
		</Layout>
	)
}
