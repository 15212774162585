/* eslint-disable no-template-curly-in-string */
import { CircularProgress } from '@mui/material'
import { Box, Button, Icon, Layout, Modal, Typography } from 'components'
import { v4 as uuidv4 } from 'uuid'

import {
	BodyCreateContent,
	DeliveryActiveData,
	DetailNotification,
	DropdownFullItemProps,
	FilesData,
	FormPushNotification,
	GetAudience,
	GetCustomRecurrence,
	GetCustomRecurrenceEnd,
	GetEventCustomRecurrencEnd,
	GetEventsEnd,
	GetGoodLink,
	GetTiming,
	Icons,
	ItemDataAssentsAll,
	locationState,
	PostCreateNotification,
	PushNotificationProps,
	TotalDataCreateContent,
	TypographyTypes,
	UpdateImageResponse
} from 'interfaces'
import Cookies from 'universal-cookie'

import { GET_DETAIL_NOTIFICATION, getUploadImageConten, updateImage } from 'api'
import {
	getDetailNotification,
	postCreateNotification,
	postEditNotification
} from 'api/post/pushNotification'
import { CreatePushNotification } from 'components/pushNotification/create/CreatePushNotification'
import { DirectPushSetUp } from 'components/pushNotification/create/DirectPushSetUp'
import { RadioButtonsSendDirectPush } from 'components/pushNotification/create/RadioButtonsSendPush'

import { PushDelivery } from 'components/pushNotification/create/PushDelivery'
import { TitleSendDirectPush } from 'components/pushNotification/create/TitleSendDirectPush'
import { useToast } from 'hooks'
import { linkToUrl } from 'lib'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

export const PushNotification: React.FC<PushNotificationProps> = ({
	messageDashboard,
	setMessageDashboard
}): JSX.Element => {
	const methods = useForm<FormPushNotification>({
		mode: 'all'
	})

	const [loading, setLoading] = useState(false)
	const cookies = new Cookies()
	const organizationId = cookies.get('organization_id')
	const userId = cookies.get('userId')
	const { id } = useParams()
	const idParse = id ? parseInt(id, 10) : undefined
	const [listImage, setListImage] = useState<ItemDataAssentsAll[]>([])
	const [publishMyPeope, setPublishMyPeope] = useState('1')
	const [itemRecurrence, setItemRecurrence] = useState('1')
	const [eventRecurrence, setEventRecurrence] = useState('1')
	const [dontSend, setDontSend] = useState(false)
	const [radioFilter, setRadioFilter] = useState(linkToUrl[0].value)
	const [placeHolderInput, setplaceHolderInput] = useState('')
	const [contentType, setContentType] = useState<DropdownFullItemProps>({
		id: 0,
		name: 'Select'
	})
	const [datacontentType, setDataContentType] = useState<
		DropdownFullItemProps[]
	>([])
	const [modalCancel, setModalCancel] = useState(false)
	const { showToast } = useToast()
	const navigate = useNavigate()
	const [deliveryActive, setDeliveryActive] = useState<DeliveryActiveData>({
		shedule: false,
		audience: false
	})

	const [item, setItem] = useState<locationState>({
		title: '',
		address: '',
		radius: 1,
		location_type: 'item',
		latitude: 0,
		longitude: 0
	})

	const [totalData, setTotalData] = useState<TotalDataCreateContent>({
		organization_id: Number(organizationId),
		author_id: Number(userId),
		slug: '',
		basic_setup: {
			category: 'notification',
			saving: false,
			sharing: false,
			is_pinned: false
		},
		set_up: {
			title: '',
			message: '',
			link_to: '',
			image: '',
			cta_Type: '',
			cta_longitude: 0,
			cta_latitude: 0
		},
		delivery_enhancements: {
			schedule: {
				timing: {
					release_date: '',
					expiration_date: ''
				},
				custom_recurrence: {
					repeating_schedule: false,
					repeat_start_date: '',
					repeat_every_type: '',
					repeat_every_value: 0,
					repeat_on: '',
					ends: {
						// after_ocurrence , on_date, never
						repeat_end_type: '',
						repeat_end_value: 0,
						repeat_end_date: '',
						after_ocurrences: 0
					}
				},
				event: {
					event_start_date: '',
					event_end_date: '',
					event_repeat_until: '',
					custom_recurrence: {
						repeating_schedule: false,
						repeat_start_date: '',
						repeat_every_type: '',
						repeat_every_value: 0,
						repeat_on: '',
						ends: {
							// after_ocurrence , on_date, never
							repeat_end_type: '',
							repeat_end_date: '',
							after_ocurrences: 0
						}
					}
				}
			},
			audience: {
				tags: [],
				lists: [],
				dynamic_lists: [],
				individuals: [],
				organizations: [],
				feeds: [],
				partner_lists: []
			},
			good_link: {
				link_type: '',
				link_value: '',
				link_call_action: '',
				is_sublink: false,
				sublink_type: '',
				sublink_value: '',
				sublink_call_action: ''
			},
			documents: [],
			push_notification: {
				title: '',
				message: '',
				is_message: false,
				is_title: false,
				image: '',
				timing: {
					release_date: ''
				},
				custom_recurrence: {
					repeating_schedule: false,
					repeat_start_date: '',
					// day week month year
					repeat_every_type: '',
					repeat_every_value: 0,
					repeat_on: '',
					ends: {
						// after_ocurrence , on_date, never
						repeat_end_type: '',
						repeat_end_date: '',
						after_ocurrences: 0
					}
				}
			},
			location: [],
			repeat_end_value: 0
		}
	})

	const { data, isLoading, refetch } = useQuery(
		[GET_DETAIL_NOTIFICATION, userId, organizationId, id],
		() =>
			getDetailNotification(organizationId, id ? parseInt(id, 10) : undefined)
	)

	const updateDocuments = async (
		credentials: UpdateImageResponse | null,
		file: string,
		name: string
	): Promise<string> => {
		if (!credentials) return ''
		const getFile = await fetch(file)
		const fileBlob = await getFile.blob()
		const newKey = credentials.fields.key.replace('${filename}', name)
		const body = {
			...credentials.fields,
			file: fileBlob,
			key: newKey
		}
		await updateImage(credentials.url, body)
		const url = `${credentials.url}${newKey}`
		return url
	}

	const cleanData = (
		dataToclean: PostCreateNotification
	): PostCreateNotification => {
		const databody = dataToclean
		if (!databody.release_date) {
			delete databody.release_date
		}
		if (!databody.repeating_schedule) {
			delete databody.repeating_schedule
		}
		if (!databody.repeat_start_date) {
			delete databody.repeat_start_date
		}
		if (!databody.repeat_every_type) {
			delete databody.repeat_every_type
		}
		if (!databody.repeat_every_value) {
			delete databody.repeat_every_value
		}
		if (!databody.repeat_on) {
			delete databody.repeat_on
		}
		if (!databody.after_ocurrences) {
			delete databody.after_ocurrences
		}
		if (!databody.repeat_end_date) {
			delete databody.repeat_end_date
		}
		if (!databody.repeat_end_type) {
			delete databody.repeat_end_type
		}
		if (databody.location) {
			delete databody.location.location_type
		}

		if (!databody.cta_type || totalData.set_up.cta_Type === 'none') {
			delete databody.cta_type
		}
		if (!databody.cta_value) {
			delete databody.cta_value
		}
		if (!databody.expiration_date) {
			delete databody.expiration_date
		}
		return databody
	}

	const updateOrCreateImage = async (
		credentialsImage: UpdateImageResponse | null
	): Promise<FilesData[]> => {
		const imageUrl = [] as FilesData[]

		if (listImage.length > 0) {
			await Promise.all(
				listImage.map(async elemet => {
					if (elemet.asset_url) {
						if (elemet.asset_name) {
							const url = await updateDocuments(
								credentialsImage,
								elemet.asset_url,
								elemet.asset_name
							)
							imageUrl.push({
								asset_url: url,
								asset_type: 'image'
							})
						} else {
							imageUrl.push({
								asset_url: elemet.asset_url,
								asset_type: elemet.asset_type
							})
						}
					}
				})
			)
		}
		return imageUrl
	}

	const handleSubmit = async (status: string): Promise<Promise<void>> => {
		setLoading(true)
		const slug = uuidv4()

		const credentialsImage = await getUploadImageConten(slug)
		const getImage = await updateOrCreateImage(credentialsImage)
		const body = totalData as unknown as BodyCreateContent

		const databody = {
			author_id: totalData.author_id,
			title: totalData.set_up?.title,
			description: body.set_up?.message,
			custom_image_url: getImage.length > 0 ? getImage[0].asset_url : '',
			status,
			cta_type: totalData.set_up.cta_Type,
			cta_value: totalData.set_up.link_to,
			cta_latitude: totalData.set_up.cta_latitude,
			cta_longitude: totalData.set_up.cta_longitude,
			release_date: body.delivery_enhancements?.schedule?.timing?.release_date,
			expiration_date:
				body.delivery_enhancements?.schedule?.timing?.expiration_date,
			repeating_schedule:
				body.delivery_enhancements?.schedule?.custom_recurrence
					?.repeating_schedule,
			repeat_start_date:
				body.delivery_enhancements?.schedule?.custom_recurrence
					?.repeat_start_date,
			repeat_every_type:
				body.delivery_enhancements?.schedule?.custom_recurrence
					?.repeat_every_type,
			repeat_every_value:
				body.delivery_enhancements?.schedule?.custom_recurrence
					?.repeat_every_value,
			repeat_on:
				body.delivery_enhancements?.schedule?.custom_recurrence?.repeat_on,
			after_ocurrences:
				body.delivery_enhancements?.schedule?.custom_recurrence?.ends
					.after_ocurrences,
			repeat_end_date:
				body.delivery_enhancements?.schedule?.custom_recurrence?.ends
					.repeat_end_date,
			repeat_end_type:
				body.delivery_enhancements?.schedule?.custom_recurrence?.ends
					.repeat_end_type,
			audience: body.delivery_enhancements?.audience,
			location: totalData.delivery_enhancements?.location[0] ?? {},
			repeat_end_value: totalData.delivery_enhancements.repeat_end_value ?? 0
		}

		const cleanBody = cleanData(databody)

		if (data) {
			postEditNotification(organizationId, cleanBody, idParse).then(
				response => {
					if (response?.detail) {
						setLoading(false)
						showToast(response.detail, 'Error', 'error')
					} else {
						showToast('Content Edit', 'Success', 'success')
						navigate('/content')
						setLoading(false)
						refetch()
					}
				}
			)
		} else {
			postCreateNotification(organizationId, cleanBody).then(response => {
				if (response?.detail) {
					setLoading(false)
					showToast(response.detail, 'Error', 'error')
				} else {
					showToast('Notification Created', 'Success', 'success')
					navigate('/content')
					setLoading(false)
				}
			})
		}
	}

	const getTiming = (dataGetTiming: DetailNotification): GetTiming => {
		return {
			release_date:
				dataGetTiming.timing.release_date &&
				dataGetTiming.timing.release_date !== null
					? dataGetTiming.timing.release_date
					: '',
			expiration_date:
				dataGetTiming.timing.expiration_date &&
				dataGetTiming.timing.expiration_date !== null
					? dataGetTiming.timing.expiration_date
					: ''
		}
	}

	const getCustomRecurrenceEnd = (
		dataRecurrenceEnd: DetailNotification
	): GetCustomRecurrenceEnd => {
		return {
			repeat_end_type:
				dataRecurrenceEnd.custom_recurrence.ends.repeat_end_type &&
				dataRecurrenceEnd.custom_recurrence.ends.repeat_end_type !== null
					? dataRecurrenceEnd.custom_recurrence.ends.repeat_end_type
					: '',
			repeat_end_date:
				dataRecurrenceEnd.custom_recurrence.ends.repeat_end_date &&
				dataRecurrenceEnd.custom_recurrence.ends.repeat_end_date !== null
					? dataRecurrenceEnd.custom_recurrence.ends.repeat_end_date
					: '',
			after_ocurrences:
				dataRecurrenceEnd.custom_recurrence.ends.after_ocurrences &&
				dataRecurrenceEnd.custom_recurrence.ends.after_ocurrences !== null
					? dataRecurrenceEnd.custom_recurrence.ends.after_ocurrences
					: 0,
			repeat_end_value:
				dataRecurrenceEnd.custom_recurrence.ends.repeat_end_value &&
				dataRecurrenceEnd.custom_recurrence.ends.repeat_end_value !== null
					? dataRecurrenceEnd.custom_recurrence.ends.repeat_end_value
					: 0
		}
	}

	const getCustomRecurrence = (
		dataCustomRecurrence: DetailNotification
	): GetCustomRecurrence => {
		return {
			repeating_schedule:
				dataCustomRecurrence?.custom_recurrence?.repeating_schedule ?? false,
			repeat_start_date:
				dataCustomRecurrence.custom_recurrence.repeat_start_date &&
				dataCustomRecurrence.custom_recurrence.repeat_start_date !== null
					? dataCustomRecurrence.custom_recurrence.repeat_start_date
					: '',
			repeat_every_type:
				dataCustomRecurrence.custom_recurrence.repeat_every_type &&
				dataCustomRecurrence.custom_recurrence.repeat_every_type !== null
					? dataCustomRecurrence.custom_recurrence.repeat_every_type
					: '',
			repeat_every_value:
				dataCustomRecurrence.custom_recurrence.repeat_every_value &&
				dataCustomRecurrence.custom_recurrence.repeat_every_value !== null
					? dataCustomRecurrence.custom_recurrence.repeat_every_value
					: 0,
			repeat_on:
				dataCustomRecurrence.custom_recurrence.repeat_on &&
				dataCustomRecurrence.custom_recurrence.repeat_on !== null
					? dataCustomRecurrence.custom_recurrence.repeat_on
					: '',
			ends: getCustomRecurrenceEnd(dataCustomRecurrence)
		}
	}

	const getEventEnd = (dataEventEnd: DetailNotification): GetEventsEnd => {
		return {
			repeat_end_type:
				dataEventEnd.custom_recurrence.ends.repeat_end_type &&
				dataEventEnd.custom_recurrence.ends.repeat_end_type !== null
					? dataEventEnd.custom_recurrence.ends.repeat_end_type
					: '',
			repeat_end_date:
				dataEventEnd.custom_recurrence.ends.repeat_end_date &&
				dataEventEnd.custom_recurrence.ends.repeat_end_date !== null
					? dataEventEnd.custom_recurrence.ends.repeat_end_date
					: '',
			after_ocurrences:
				dataEventEnd.custom_recurrence.ends.after_ocurrences &&
				dataEventEnd.custom_recurrence.ends.after_ocurrences !== null
					? dataEventEnd.custom_recurrence.ends.after_ocurrences
					: 0
		}
	}
	const getEventCustomRecurrencEnd = (
		EventCustomRecurrencEnd: DetailNotification
	): GetEventCustomRecurrencEnd => {
		return {
			repeating_schedule:
				EventCustomRecurrencEnd.custom_recurrence.repeating_schedule &&
				EventCustomRecurrencEnd.custom_recurrence.repeating_schedule !== null
					? EventCustomRecurrencEnd.custom_recurrence.repeating_schedule
					: false,
			repeat_start_date:
				EventCustomRecurrencEnd.custom_recurrence.repeat_start_date &&
				EventCustomRecurrencEnd.custom_recurrence.repeat_start_date !== null
					? EventCustomRecurrencEnd.custom_recurrence.repeat_start_date
					: '',
			repeat_every_type:
				EventCustomRecurrencEnd.custom_recurrence.repeat_every_type &&
				EventCustomRecurrencEnd.custom_recurrence.repeat_every_type !== null
					? EventCustomRecurrencEnd.custom_recurrence.repeat_every_type
					: '',
			repeat_every_value:
				EventCustomRecurrencEnd.custom_recurrence.repeat_every_value &&
				EventCustomRecurrencEnd.custom_recurrence.repeat_every_value !== null
					? EventCustomRecurrencEnd.custom_recurrence.repeat_every_value
					: 0,
			repeat_on:
				EventCustomRecurrencEnd.custom_recurrence.repeat_on &&
				EventCustomRecurrencEnd.custom_recurrence.repeat_on !== null
					? EventCustomRecurrencEnd.custom_recurrence.repeat_on
					: '',
			ends: getEventEnd(EventCustomRecurrencEnd)
		}
	}

	const getAudience = (dataAudience: DetailNotification): GetAudience => {
		return {
			tags: dataAudience.audience.tags ?? [],
			lists: dataAudience.audience.lists ?? [],
			dynamic_lists: dataAudience.audience.dynamic_lists ?? [],
			individuals: dataAudience.audience.individuals ?? [],
			organizations: dataAudience.audience.organizations ?? [],
			feeds: dataAudience.audience.feeds ?? [],
			partner_lists: dataAudience.audience.partner_lists ?? []
		}
	}
	const getGoodLink = (): GetGoodLink => {
		return {
			link_type: '',
			link_value: '',
			link_call_action: '',
			is_sublink: false,
			sublink_type: '',
			sublink_value: '',
			sublink_call_action: ''
		}
	}

	useEffect(() => {
		if (data) {
			setTotalData({
				organization_id: data.organization_id,
				author_id: null,
				slug: '',
				basic_setup: {
					category: '',
					saving: false,
					sharing: false,
					is_pinned: false
				},

				set_up: {
					title: data.title,
					message: data.message,
					image: data.image,
					link_to: data.cta_value ?? '',
					cta_Type: data.cta_type ?? ''
				},
				delivery_enhancements: {
					schedule: {
						timing: getTiming(data),
						custom_recurrence: getCustomRecurrence(data),
						event: {
							event_start_date: '',
							event_end_date: '',
							event_repeat_until: '',
							custom_recurrence: getEventCustomRecurrencEnd(data)
						}
					},
					audience: getAudience(data),
					good_link: getGoodLink(),
					location: data.location ?? [],
					repeat_end_value: data.repeat_end_value,
					push_notification: {
						title: '',
						message: '',
						is_message: false,
						is_title: false,
						image: '',
						timing: {
							release_date: ''
						},
						custom_recurrence: {
							repeating_schedule: false,
							repeat_start_date: '',
							repeat_every_type: '',
							repeat_every_value: 0,
							repeat_on: '',
							ends: {
								repeat_end_type: '',
								repeat_end_date: '',
								after_ocurrences: 0
							}
						}
					},
					documents: []
				}
			})
			setItem({
				...item,
				address: data.cta_value,
				longitude: data.cta_longitude,
				latitude: data.cta_latitude
			})
			if (
				data?.audience &&
				(data.audience.lists ??
					data.audience.tags ??
					data.audience.dynamic_lists ??
					data.audience.individuals ??
					data.audience.organizations)
			) {
				setPublishMyPeope('2')
			}
		}
	}, [data])

	return (
		<FormProvider {...methods}>
			<Layout>
				{loading || isLoading ? (
					<div className="flex items-center justify-center w-full h-[400px] py-2 px-1 ">
						<CircularProgress disableShrink />
					</div>
				) : (
					<>
						<Modal
							closeButton
							type="normal"
							open={modalCancel}
							setOpen={setModalCancel}
						>
							<div className="flex flex-col items-center gap-2">
								<Icon
									fillPath
									src={Icons.warning}
									className="w-10 text-blue-primary"
								/>
								<Typography
									type={TypographyTypes.span}
									fontLeading="15/18"
									className="!font-normal text-black-1"
									title="Are you sure you want to exit?"
								/>
								<Button
									type="button"
									label="Confirm"
									color="Primary"
									className="!min-w-[130px]"
									onClick={() => navigate('/content')}
								/>
							</div>
						</Modal>
						<div />

						<div className="flex flex-col sm:flex-col md:flex-row lg:flex-row mt-3 w-full justify-between 3xl:mx-auto 3xl:my-auto 3xl:w-3/4">
							<div className="flex flex-col sm:w-full md:w-[50%]">
								<div className="w-full">
									<TitleSendDirectPush title="Send Direct Push" />
								</div>
								<div className="w-full sm:w-full md:w-[95%] lg:w-[95%] 2xl:w-[95%] mt-4  h-full ">
									<div className="hidden md:block">
										<Box>
											<CreatePushNotification
												setListImage={setListImage}
												listImage={listImage}
												totalData={totalData}
											/>
										</Box>
									</div>
								</div>
							</div>
							<div className="flex flex-col w-full md:w-[50%] lg:w-[50%] sm:w-full sm:mt-2 md:mt-0 ">
								<Box>
									<DirectPushSetUp
										item={item}
										setItem={setItem}
										listImage={listImage}
										totalData={totalData}
										setDontSend={setDontSend}
										setTotalData={setTotalData}
										messageDashboard={messageDashboard}
										setMessageDashboard={setMessageDashboard}
										setplaceHolderInput={setplaceHolderInput}
										radioFilter={radioFilter}
										setRadioFilter={setRadioFilter}
										datacontentType={datacontentType}
										setDataContentType={setDataContentType}
										contentType={contentType}
										setContentType={setContentType}
									/>
								</Box>
								<Box className="mt-2">
									<RadioButtonsSendDirectPush
										item={item}
										setRadioFilter={setRadioFilter}
										radioFilter={radioFilter}
										placeHolderInput={placeHolderInput}
										datacontentType={datacontentType}
										setContentType={setContentType}
										contentType={contentType}
										setItem={setItem}
										totalData={totalData}
										setTotalData={setTotalData}
									/>
								</Box>

								<Box className="mt-2 md:hidden ">
									<CreatePushNotification
										setListImage={setListImage}
										listImage={listImage}
										totalData={totalData}
									/>
								</Box>
								<div>
									<Box className="mt-2">
										<PushDelivery
											totalData={totalData}
											deliveryActive={deliveryActive}
											setTotalData={setTotalData}
											itemRecurrence={itemRecurrence}
											publishMyPeope={publishMyPeope}
											eventRecurrence={eventRecurrence}
											setDeliveryActive={setDeliveryActive}
											setItemRecurrence={setItemRecurrence}
											setPublishMyPeope={setPublishMyPeope}
											setEventRecurrence={setEventRecurrence}
										/>
									</Box>
									<div className="w-full flex flex-col sm:flex-row justify-end gap-4 mt-4">
										<Button
											type="button"
											label="Cancel"
											color="Gray3"
											className="!min-w-[130px]"
											onClick={() => setModalCancel(true)}
										/>
										<Button
											type="button"
											label="Save Draft"
											color="white"
											className="!min-w-[130px]"
											disabled={!dontSend}
											onClick={() => handleSubmit('draft')}
										/>
										<Button
											type="button"
											label="Send"
											color="Primary"
											className="!min-w-[130px]"
											disabled={!dontSend}
											onClick={() => handleSubmit('processing')}
										/>
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</Layout>
		</FormProvider>
	)
}
