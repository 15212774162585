import { UpdateInformationAdmin } from 'components/settings/UpdateInformationAdmin'
import { GenericModalProps, PeopleMyTagsProps } from 'interfaces'
import React from 'react'
import { TailwindModal } from './tailwindModal'

export const UpdateAdminModal: React.FC<
	GenericModalProps<PeopleMyTagsProps>
> = ({ open, setOpen, isUpdate, handleCancel }): JSX.Element => {
	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			isUpdate={isUpdate}
			handleCancel={handleCancel}
			showCloseButton={false}
			className="w-[22.375rem]  p-4 rounded-[16px] flex flex-col "
		>
			<UpdateInformationAdmin isModal setOpen={setOpen} />
		</TailwindModal>
	)
}
