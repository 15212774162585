import { MyPeopleListItemProps, TypographyTypes } from 'interfaces'
import React from 'react'
import { Typography } from '../typography'

export const MyPeopleListItem: React.FC<MyPeopleListItemProps> = ({
	name,
	email
}): JSX.Element => {
	return (
		<div className="w-full">
			<div className="w-full flex justify-start gap-4 items-center">
				<div className="pl-1 flex flex-col gap-1">
					<Typography
						className="text-black-1"
						title={name}
						fontLeading="12/15"
						type={TypographyTypes.p}
					/>
					<Typography
						className="text-gray-9 !font-normal"
						title={email}
						fontLeading="12/15"
						type={TypographyTypes.p}
					/>
				</div>
			</div>
		</div>
	)
}
