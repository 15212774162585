import { CircularProgress } from '@mui/material'
import { PostForgotPassword } from 'api/post/forgotPassword'
import { Button, Input } from 'components'
import { useToast } from 'hooks'
import { ForgotResponse, Images, ResetPasswordForm } from 'interfaces'
import React, { useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

export const ResetPassword: React.FC = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues
	} = useForm<ResetPasswordForm>()
	const { id } = useParams()
	const navigate = useNavigate()
	const { showToast } = useToast()
	const [loading, setLoading] = useState(false)

	const validatePassword = (value: string): boolean | string => {
		if (value.length < 8) {
			return 'Password should be at least 8 characters long'
		}

		if (!/(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#<>+_{}.])/.test(value)) {
			return 'Password should include a combination of uppercase and lowercase letters, numbers, and symbols'
		}

		return true
	}

	const validatePasswordMatch = (value: string): boolean | string => {
		const password = getValues('password')
		if (value !== password) {
			return 'Passwords do not match'
		}
		return true
	}
	const rules = useMemo(() => {
		return {
			password: {
				required: { value: true, message: 'This field is required' },
				validate: validatePassword
			},
			currentPasswordConfirm: {
				required: { value: true, message: 'This field is required' },
				validate: validatePasswordMatch
			}
		}
	}, [])

	const onSubmit: SubmitHandler<ResetPasswordForm> = async data => {
		setLoading(true)
		try {
			if (id) {
				const body = {
					token: id,
					password: data.password
				}
				const DataTosend = await PostForgotPassword(body).then(
					(response: ForgotResponse | null) => {
						if (response?.detail) {
							showToast(response.detail, '', 'error')
						} else {
							showToast(response?.message ?? '', '', 'success')
							navigate('/')
						}
					}
				)
				return DataTosend
			}
		} catch (e) {
			console.warn(e)
		} finally {
			setLoading(false)
		}
		return null
	}

	return (
		<div className="w-screen h-screen flex flex-col gap-4 items-center justify-center">
			{loading ? (
				<div className="flex items-center justify-center w-full h-[400px] py-2 px-1 ">
					<CircularProgress disableShrink />
				</div>
			) : (
				<div className="w-screen h-screen flex flex-col gap-4 items-center justify-center">
					<div className="w-[101px] h-[91px]">
						<img
							src={Images.patterLogo}
							alt="Logo"
							className="w-full h-full object-contain"
						/>
					</div>
					<form
						className="w-[386px]  h-[400px] bg-blue-primary p-6 rounded-[16px] flex flex-col justify-center items-center"
						onSubmit={handleSubmit(onSubmit)}
					>
						<Input
							name="password"
							title="Set New Password"
							register={register}
							rules={rules.password}
							placeholder="Type a New password"
							error={errors.password}
							className="!w-full !p-[unset] mb-10"
							inputClassName="!p-[unset] min-h-[54px] !border-none !bg-white rounded-[24px]"
							showEye
							helper="Your password should be at least 8 characters long and include a combination of upper and lower case letters, numbers, and symbols."
							fillpat
						/>

						<Input
							name="currentPasswordConfirm"
							title="Confirm New Password"
							register={register}
							rules={rules.currentPasswordConfirm}
							placeholder="Type again your New password"
							error={errors.currentPasswordConfirm}
							className="!w-full !p-[unset] mb-10"
							inputClassName="!p-[unset] min-h-[54px] !border-none !bg-white rounded-[24px]"
							showEye
						/>
						<Button
							label="Confirm"
							type="submit"
							color="Transparent"
							className="!min-w-[unset] min-h-[54px] !w-[168px] !py-3 !px-6 !bg-white !border-none mb-2"
						/>
					</form>
				</div>
			)}
		</div>
	)
}
