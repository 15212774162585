import { Button, Icon, Typography } from 'components'
import { BoxDocumentsProps, Icons, TypographyTypes } from 'interfaces'
import React from 'react'

export const BoxDocuments: React.FC<BoxDocumentsProps> = ({
	size,
	title,
	onClick
}): JSX.Element => {
	return (
		<div className="flex justify-between">
			<div className="flex items-center gap-2">
				<Icon src={Icons.pdf} className="w-8" />
				<div className="flex flex-col">
					<Typography
						title={title}
						type={TypographyTypes.span}
						className="!font-normal text-black-1"
						fontLeading="12/15"
					/>
					<Typography
						title={`${size} Mb`}
						type={TypographyTypes.span}
						className="!font-normal text-black-1"
						fontLeading="10/12"
					/>
				</div>
			</div>
			<Button
				type="button"
				label="Delete"
				color="Gray3"
				className="!max-w-[112px] !min-w-[112px]"
				iconLeft={Icons.delete}
				onClick={onClick}
			/>
		</div>
	)
}
