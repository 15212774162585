import React, { useEffect } from 'react'
// RequireUser
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import 'App.css'
import {
	MenuContext,
	PeopleBatchesContextProvider,
	useContextMenu
} from 'context'
import { messages as enMessages } from 'locales/en/messages.js'
import { messages as esMessages } from 'locales/es/messages.js'
import { en, es } from 'make-plural/plurals'
import 'react-datetime/css/react-datetime.css'
import { Toaster } from 'react-hot-toast'
import { QueryClient, QueryClientProvider } from 'react-query'
import 'react-quill/dist/quill.snow.css'
import { RoutesElements } from 'routes'
import 'tailwindcss/tailwind.css'
import { PaginationContext, useContextPagination } from './context/pagination'

const App = (): JSX.Element => {
	const userContextValue = useContextMenu()
	const paginationContextValue = useContextPagination()
	const queryClient = new QueryClient()
	i18n.loadLocaleData('en', { plurals: en })
	i18n.loadLocaleData('es', { plurals: es })
	i18n.load({
		en: enMessages,
		es: esMessages
	})
	useEffect(() => {
		window.localStorage.setItem('language', 'es')
		i18n.activate('en')
	}, [])
	return (
		<MenuContext.Provider value={userContextValue}>
			<PaginationContext.Provider value={paginationContextValue}>
				<I18nProvider i18n={i18n}>
					<QueryClientProvider client={queryClient}>
						<PeopleBatchesContextProvider>
							<RoutesElements />
						</PeopleBatchesContextProvider>
						<Toaster
							position="top-right"
							toastOptions={{
								duration: 1500
							}}
						/>
					</QueryClientProvider>
				</I18nProvider>
			</PaginationContext.Provider>
		</MenuContext.Provider>
	)
}
export default App
