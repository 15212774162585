import clsx from 'clsx'
import { HeaderTableItem, Icon, Pagination, Typography } from 'components'
import { useContextPeopleBatches } from 'context'
import { useSelect } from 'hooks'
import {
	ChildrenTableProps,
	Icons,
	PeopleMyPeopleItem,
	TypographyTypes
} from 'interfaces'
import { onPageChanged } from 'lib'
import moment from 'moment'
import React from 'react'
import { FollowersTagCell } from './followersTagCell'
import { loginType } from './iconLoginType'

export const MyPeopleTable: React.FC<
	ChildrenTableProps<PeopleMyPeopleItem[]>
> = ({
	data,
	backendPages,
	customWidth,
	justify = 'center',
	bodyTableClassName,
	actionsButton,
	actionColumn = 'left',
	actionColumnWidth = 5,
	page,
	setPage,
	dataSelected,
	setDataSelected,
	borderTable,
	listLoginType
}): JSX.Element => {
	const { setSelectedUsers, setOrganizationFollower } =
		useContextPeopleBatches()
	const { selectAll, handleSelectAll, handleSelected } = useSelect(
		data!,
		dataSelected!,
		setDataSelected!,
		'organization_follower_id'
	)

	const onSelectAll = (): void => {
		const selectedIds = data?.map?.(user => user.user_id) ?? []
		const orgFollowersIds =
			data?.map?.(user => user.organization_follower_id) ?? []
		setSelectedUsers(prev => [...prev, ...selectedIds])
		setOrganizationFollower(prev => [...prev, ...orgFollowersIds])
		handleSelectAll()
	}

	const handleChangeCheckBox = (elements: PeopleMyPeopleItem): void => {
		if (dataSelected?.includes(elements.organization_follower_id)) {
			setSelectedUsers(prev =>
				prev.filter(element => element !== elements.user_id)
			)
		} else {
			setSelectedUsers(prev => [...prev, elements.user_id])
		}
	}

	return (
		<div className="flex gap-5 w-full mt-4 overflow-x-scroll hide-scroll-bar">
			<div className="min-w-[1300px] md:w-full lg:w-full">
				<div
					className={clsx(
						!borderTable && 'border-2 border-gray-7 rounded-lg md:w-full',
						'p-4 bg-white '
					)}
				>
					<table className="w-full md:overflow-y-hidden md:overflow-x-scroll hide-scroll-bar justify-center">
						<thead className="flex w-full h-5 mb-3 pr-5">
							<tr className="w-full flex justify-between items-center">
								{dataSelected && setDataSelected && (
									<th
										scope="col"
										className="flex items-center justify-center"
										style={{ flexBasis: `3%` }}
									>
										<input
											type="checkbox"
											className="h-4 w-4 rounded border-gray-1 text-blue-primary focus:ring-transparent-default cursor-pointer"
											checked={selectAll}
											onChange={onSelectAll}
										/>
									</th>
								)}
								{actionsButton && actionColumn === 'left' && (
									<th
										scope="col"
										className="flex items-center justify-center text-[15px] leading-[18px] text-gray-9 font-bold cursor-pointer"
										style={{ flexBasis: `${actionColumnWidth}%` }}
									>
										<Typography
											title="Action"
											fontLeading="15/18"
											type={TypographyTypes.span}
											className="text-gray-9"
										/>
									</th>
								)}
								{data && (
									<>
										<HeaderTableItem
											header="Name"
											justify={justify}
											table="myPeople"
											column="full_name"
											style={
												customWidth
													? { flexBasis: `${customWidth[0]}%` }
													: { flexBasis: `${100 / data.length}%` }
											}
										/>
										<HeaderTableItem
											header="Status"
											justify={justify}
											table="myPeople"
											column="status"
											style={
												customWidth
													? { flexBasis: `${customWidth[1]}%` }
													: { flexBasis: `${100 / data.length}%` }
											}
										/>

										<HeaderTableItem
											header="ID"
											justify={justify}
											table="myPeople"
											style={
												customWidth
													? { flexBasis: `${customWidth[2]}%` }
													: { flexBasis: `${100 / data.length}%` }
											}
										/>

										<HeaderTableItem
											header="Email"
											justify={justify}
											table="myPeople"
											column="email"
											style={
												customWidth
													? { flexBasis: `${customWidth[3]}%` }
													: { flexBasis: `${100 / data.length}%` }
											}
										/>

										<HeaderTableItem
											header="Zip/Postal"
											justify={justify}
											table="myPeople"
											column="postal_code"
											style={
												customWidth
													? { flexBasis: `${customWidth[4]}%` }
													: { flexBasis: `${100 / data.length}%` }
											}
										/>

										<HeaderTableItem
											header="Sign up Date"
											justify={justify}
											table="myPeople"
											column="last_login"
											style={
												customWidth
													? { flexBasis: `${customWidth[5]}%` }
													: { flexBasis: `${100 / data.length}%` }
											}
										/>

										<th
											scope="col"
											className={clsx(
												'flex items-center text-gray-9 cursor-pointer',
												justify === 'center' && 'justify-center',
												justify === 'left' && 'justify-left',
												justify === 'right' && 'justify-right'
											)}
											style={
												customWidth
													? { flexBasis: `${customWidth[6]}%` }
													: { flexBasis: `${100 / data.length}%` }
											}
										>
											<Typography
												title="Follower Tag"
												fontLeading="15/18"
												type={TypographyTypes.span}
												className="text-gray-9"
											/>
										</th>
										<HeaderTableItem
											header="OS"
											justify={justify}
											table="myPeople"
											column="device_type"
											style={
												customWidth
													? { flexBasis: `${customWidth[7]}%` }
													: { flexBasis: `${100 / data.length}%` }
											}
										/>
										<HeaderTableItem
											header="Login"
											justify={justify}
											table="myPeople"
											style={
												customWidth
													? { flexBasis: `${customWidth[8]}%` }
													: { flexBasis: `${100 / data.length}%` }
											}
										/>

										<HeaderTableItem
											header="Push On"
											justify={justify}
											table="myPeople"
											column="notification_push"
											style={
												customWidth
													? { flexBasis: `${customWidth[9]}%` }
													: { flexBasis: `${100 / data.length}%` }
											}
										/>

										<HeaderTableItem
											header="Loc On"
											justify={justify}
											table="myPeople"
											column="active_location"
											style={
												customWidth
													? { flexBasis: `${customWidth[9]}%` }
													: { flexBasis: `${100 / data.length}%` }
											}
										/>
									</>
								)}
								{actionsButton && actionColumn === 'right' && (
									<th
										scope="col"
										className="flex items-center justify-center text-[15px] leading-[18px] text-gray-9 font-bold cursor-pointer"
										style={{ flexBasis: `12%` }}
									>
										<Typography
											title="Actions"
											fontLeading="15/18"
											type={TypographyTypes.span}
											className="text-gray-9"
										/>
									</th>
								)}
							</tr>
						</thead>
						<tbody
							className={clsx(
								bodyTableClassName,
								'!h-[calc(100vh_-_28rem)] overflow-y-scroll hide-scroll-bar w-full  flex flex-col gap-2'
							)}
						>
							{data?.map?.(item => (
								<tr
									key={item.organization_follower_id}
									className="w-full flex justify-between"
								>
									{dataSelected && setDataSelected && (
										<td
											className="flex justify-center items-center py-2"
											style={{ flexBasis: `3%` }}
										>
											<input
												type="checkbox"
												className="h-4 w-4 rounded border-gray-1 text-blue-primary focus:ring-transparent-default cursor-pointer"
												checked={dataSelected.includes(
													item.organization_follower_id
												)}
												onChange={() => {
													handleChangeCheckBox(item)
													handleSelected(item.organization_follower_id)
													setOrganizationFollower(prev => [
														...prev,
														item.organization_follower_id
													])
												}}
											/>
										</td>
									)}
									{actionsButton && actionColumn === 'left' && (
										<td
											className={clsx(
												'h-10 flex justify-center items-center text-[15px] leading-[18px] text-center text-gray-9'
											)}
											style={{ flexBasis: `${actionColumnWidth}%` }}
										>
											{React.cloneElement(actionsButton, {
												value: item.organization_follower_id,
												data: item
											})}
										</td>
									)}
									<td
										className={clsx(
											'w-full h-10 flex items-center text-[15px] leading-[18px] text-gray-9 truncate max-w-auto',
											justify === 'center' && 'justify-center text-center',
											justify === 'left' && 'justify-left text-left',
											justify === 'right' && 'justify-right text-right'
										)}
										style={
											customWidth
												? { flexBasis: `${customWidth[0]}%` }
												: { flexBasis: `${100 / data.length}%` }
										}
									>
										<div className="truncate">{item.full_name}</div>
									</td>
									<td
										className={clsx(
											'w-full h-10 flex items-center text-[15px] leading-[18px] text-gray-9 capitalize',
											justify === 'center' && 'justify-center text-center',
											justify === 'left' && 'justify-left text-left',
											justify === 'right' && 'justify-right text-right'
										)}
										style={
											customWidth
												? { flexBasis: `${customWidth[1]}%` }
												: { flexBasis: `${100 / data.length}%` }
										}
									>
										{item.status}
									</td>

									<td
										className={clsx(
											' w-full h-10 flex items-center text-[15px] leading-[18px] text-gray-9  truncate max-w-auto',
											justify === 'center' && 'justify-center text-center',
											justify === 'left' && 'justify-left text-left',
											justify === 'right' && 'justify-right text-right'
										)}
										style={
											customWidth
												? { flexBasis: `${customWidth[2]}%` }
												: { flexBasis: `${100 / data.length}%` }
										}
									>
										<div className="truncate">
											{item.member_id !== null ? item.member_id : 'N/A'}
										</div>
									</td>

									<td
										className={clsx(
											'h-10 flex items-center text-[15px] leading-[18px] text-gray-9 mx-auto  truncate ',
											justify === 'center' && 'justify-center text-center',
											justify === 'left' && 'justify-left text-left',
											justify === 'right' && 'justify-right text-right'
										)}
										style={
											customWidth
												? { flexBasis: `${customWidth[3]}%` }
												: { flexBasis: `${100 / data.length}%` }
										}
									>
										<div className="truncate">{item.email}</div>
									</td>

									<td
										className={clsx(
											'w-full h-10 flex items-center text-[15px] leading-[18px] text-gray-9',
											justify === 'center' && 'justify-center text-center',
											justify === 'left' && 'justify-left text-left',
											justify === 'right' && 'justify-right text-right'
										)}
										style={
											customWidth
												? { flexBasis: `${customWidth[4]}%` }
												: { flexBasis: `${100 / data.length}%` }
										}
									>
										{item.postal_code}
									</td>
									<td
										className={clsx(
											'w-full h-10 flex items-center text-[15px] leading-[18px] text-gray-9',
											justify === 'center' && 'justify-center text-center',
											justify === 'left' && 'justify-left text-left',
											justify === 'right' && 'justify-right text-right'
										)}
										style={
											customWidth
												? { flexBasis: `${customWidth[5]}%` }
												: { flexBasis: `${100 / data.length}%` }
										}
									>
										{moment(item.last_login).format('MM/DD/YYYY')}
									</td>
									<td
										className={clsx(
											'w-full h-10 flex items-center text-[15px] leading-[18px] text-gray-9',
											justify === 'center' && 'justify-center text-center',
											justify === 'left' && 'justify-left text-left',
											justify === 'right' && 'justify-right text-right'
										)}
										style={
											customWidth
												? { flexBasis: `${customWidth[6]}%` }
												: { flexBasis: `${100 / data.length}%` }
										}
									>
										<FollowersTagCell
											// eslint-disable-next-line @typescript-eslint/no-explicit-any
											followerTags={item.followers_tag as any}
										/>
									</td>
									<td
										className={clsx(
											'w-full h-10 flex items-center text-[15px] leading-[18px] text-gray-9',
											justify === 'center' && 'justify-center text-center',
											justify === 'left' && 'justify-left text-left',
											justify === 'right' && 'justify-right text-right'
										)}
										style={
											customWidth
												? { flexBasis: `${customWidth[7]}%` }
												: { flexBasis: `${100 / data.length}%` }
										}
									>
										<div className="w-5 h-5">
											<Icon
												src={
													item.device_type
														? loginType[item.device_type]
														: Icons.cancel
												}
												fillPath
												className="text-gray-3"
											/>
										</div>
									</td>
									<td
										className={clsx(
											'w-full h-10 flex items-center text-[15px] leading-[18px] text-gray-9',
											justify === 'center' && 'justify-center text-center',
											justify === 'left' && 'justify-left text-left',
											justify === 'right' && 'justify-right text-right'
										)}
										style={
											customWidth
												? { flexBasis: `${customWidth[8]}%` }
												: { flexBasis: `${100 / data.length}%` }
										}
									>
										<span className="relative w-8">
											{item.user_signin_type.length > 1 && listLoginType && (
												<td>
													{React.cloneElement(listLoginType, {
														data: item.user_signin_type
													})}
												</td>
											)}

											<div className="w-5 h-5">
												<Icon
													src={
														item.user_signin_type[0]
															? loginType[item.user_signin_type[0].signin_type]
															: Icons.block
													}
													fillPath={
														!(
															item.user_signin_type[0] &&
															item.user_signin_type[0].signin_type === 'google'
														)
													}
													className="text-gray-3"
												/>
											</div>
										</span>
									</td>
									<td
										className={clsx(
											'w-full h-10 flex items-center text-[15px] leading-[18px] text-gray-9',
											justify === 'center' && 'justify-center text-center',
											justify === 'left' && 'justify-left text-left',
											justify === 'right' && 'justify-right text-right'
										)}
										style={
											customWidth
												? { flexBasis: `${customWidth[9]}%` }
												: { flexBasis: `${100 / data.length}%` }
										}
									>
										<div className="w-4 h-4">
											<Icon
												src={
													item.notification_push
														? Icons.checkCircle
														: Icons.cancel
												}
												fillPath
												className="text-gray-3"
											/>
										</div>
									</td>
									<td
										className={clsx(
											'w-full h-10 flex items-center text-[15px] leading-[18px] text-gray-9',
											justify === 'center' && 'justify-center text-center',
											justify === 'left' && 'justify-left text-left',
											justify === 'right' && 'justify-right text-right'
										)}
										style={
											customWidth
												? { flexBasis: `${customWidth[9]}%` }
												: { flexBasis: `${100 / data.length}%` }
										}
									>
										<div className="w-4 h-4">
											<Icon
												src={
													item.active_location
														? Icons.checkCircle
														: Icons.cancel
												}
												fillPath
												className="text-gray-3"
											/>
										</div>
									</td>

									{actionsButton && actionColumn === 'right' && (
										<td
											className={clsx(
												'h-10 flex justify-center items-center text-[15px] leading-[18px] text-center text-gray-9'
											)}
											style={{ flexBasis: `12%` }}
										>
											{React.cloneElement(actionsButton, {
												value: item.organization_follower_id
											})}
										</td>
									)}
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<div className="w-full flex items-center justify-end">
					<Pagination
						totalRecords={backendPages || 1}
						onPageChanged={pages => onPageChanged(pages, setPage!)}
						page={page}
						pageLimit={1}
					/>
				</div>
			</div>
		</div>
	)
}
