import { API } from 'api/config'
import {
	BodyCreateLocation,
	CreateLocationResponse,
	MessageResponseNewLocationLists,
	NewLocationListRequest
} from 'interfaces'
import { locationCreate } from './apiCreate'

export const postCreateLocation = async (
	organizationId: number,
	body: BodyCreateLocation
): Promise<CreateLocationResponse | null> => {
	let res = null as CreateLocationResponse | null
	await API()
		.post(`${locationCreate.CREATE}/${organizationId}`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				if (error.response.data.errors) {
					res = error.response.data.errors[0].msg
				} else {
					res = error.response.data.detail.error
				}
			}
		)
	return res
}

export const postNewLocationLists = async (
	organizationId: number,
	body: NewLocationListRequest
): Promise<MessageResponseNewLocationLists | null> => {
	let res = null as MessageResponseNewLocationLists | null
	await API()
		.post(`/location_list/${organizationId}`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				res = { detail: error.response.data.detail }
			}
		)
	return res
}
