import {
	Button,
	ContentHighlights,
	DashboardPerformance,
	Followers,
	Layout,
	MyPeopleList,
	NewTagRequests,
	Notifications,
	Promotion,
	Slide,
	SlideNotification,
	Title
} from 'components'
import { DashboardProps, Icons } from 'interfaces'
import React, { useState } from 'react'

export const Dashboard: React.FC<DashboardProps> = ({
	setPeriod,
	setTagApprovalModal
}) => {
	const [notification, setNotification] = useState(false)

	return (
		<Layout>
			<div className="3xl:mx-auto 3xl:my-auto 3xl:w-3/4 mx-auto">
				<Slide
					open={notification}
					setOpen={setNotification}
					title="Notifications"
				>
					<SlideNotification />
				</Slide>

				<div className="md:flex items-center justify-between">
					<div className="flex items-center justify-between  rounded-lg mb-4 md:mb-0 p-4 md:p-0">
						<Title icon={Icons.dashboard} title="Dashboard" />
						<Notifications
							className="block md:hidden"
							onClick={() => setNotification(true)}
						/>
					</div>
					<div className="flex items-center gap-4 min-w-full md:min-w-max overflow-x-scroll hide-scroll-bar">
						<Notifications
							className="hidden md:block"
							onClick={() => setNotification(true)}
						/>
						<Button
							label="Add New Content Item"
							iconLeft={Icons.add}
							linkTo="/content/create"
							className="min-w-[224px]"
						/>
						<Button
							label="New Direct Push Notification"
							iconLeft={Icons.add}
							linkTo="/content/push-notification"
							className="min-w-[269px]"
						/>
					</div>
				</div>
				<div className="grid grid-cols-1 2md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5">
					<DashboardPerformance />
					<Followers />
					<Promotion />
					<NewTagRequests
						setPeriod={setPeriod}
						setTagApprovalModal={setTagApprovalModal}
					/>
					<MyPeopleList setPeriod={setPeriod} />
					<ContentHighlights />
				</div>
			</div>
		</Layout>
	)
}
