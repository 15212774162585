import { ContextMenuType } from 'interfaces'
import { createContext, useCallback, useState } from 'react'
import Cookies from 'universal-cookie'

const cookies = new Cookies()
const fullNameCookies = cookies.get('fullName') as string

const MenuContextDefaultValue: ContextMenuType = {
	fullName: fullNameCookies,
	sidebarOpen: true,
	setFullName: () => null,
	setSidebarOpen: () => null
}
export const MenuContext = createContext<ContextMenuType>(
	MenuContextDefaultValue
)
export const useContextMenu = (): ContextMenuType => {
	const [sidebarOpen, setSidebarOpenValue] = useState<boolean>(
		MenuContextDefaultValue.sidebarOpen
	)

	const [fullName, setFullNameValue] = useState<string>(
		MenuContextDefaultValue.fullName
	)

	const setSidebarOpen = useCallback(
		(value: boolean) => {
			setSidebarOpenValue(value)
		},
		[setSidebarOpenValue]
	)

	const setFullName = useCallback(
		(value: string) => {
			setFullNameValue(value)
		},
		[setFullNameValue]
	)
	return {
		fullName,
		setFullName,
		sidebarOpen,
		setSidebarOpen
	}
}
