import clsx from 'clsx'
import { Button } from 'components/buttons'
import { Typography } from 'components/typography'
import {
	Images,
	TypographyTypes,
	UpdateInformationAdminProps
} from 'interfaces'
import React, { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import { cleanVersionString } from 'utils'
import packageJson from '../../../package.json'

export const UpdateInformationAdmin: React.FC<UpdateInformationAdminProps> = ({
	isModal,
	setOpen
}): JSX.Element => {
	const cookies = new Cookies()
	const packageVersionCookies = cookies.get('package_version')
	const [isDisabled, setIsDisabled] = useState(false)
	const packageVersionLocal = packageJson.version
	const cleanedCookiesVersion = cleanVersionString(packageVersionCookies)
	const cleanedLocalVersion = cleanVersionString(packageVersionLocal)

	useEffect(() => {
		if (
			Number(cleanedCookiesVersion) !== Number(cleanedLocalVersion) ||
			!cleanedLocalVersion
		) {
			setIsDisabled(true)
		} else {
			setIsDisabled(false)
		}
	}, [packageVersionCookies, packageVersionLocal])

	const textNotificationUpdate = `Hey, We have improved the admin panel! Update to the latest version ${
		packageVersionLocal ? `(${packageVersionLocal})` : ''
	} by refreshing the tab, for enhanced features and a smoother, more enjoyable experience.`

	const textSimilarVersion = `The admin panel is running in the latest version ${
		packageVersionLocal ? `(${packageVersionLocal})` : ''
	}`

	const handleUpdate = (): void => {
		cookies.set('package_version', packageVersionLocal, { path: '/' })
		window.location.reload()
	}

	const handleCancel = (): void => {
		cookies.set('modalAlreadyShown', 'true', {
			maxAge: 60 * 60 * 24 * 7
		})
		setOpen?.(false)
	}
	return (
		<div className="w-auto flex flex-col justify-between h-full ">
			{!isModal && (
				<div className="h-full">
					<Typography
						type={TypographyTypes.h2}
						className="font-Inter"
						title={
							!isDisabled
								? 'You are running the current Version'
								: 'Update Available!'
						}
						fontLeading="15/18"
					/>
				</div>
			)}

			<div className="flex justify-center items-center">
				<img
					src={Images.updateAdminNew}
					alt="update_image"
					className={!isDisabled ? 'h-[14rem]' : 'h-[12rem]'}
				/>
			</div>

			<div className="flex justify-center items-center mt-4 mb-5">
				<Typography
					type={TypographyTypes.p}
					className="font-light text-justify"
					title={!isDisabled ? textSimilarVersion : textNotificationUpdate}
					fontLeading="15/22"
				/>
			</div>
			<div
				className={clsx(
					'flex justify-center ',
					isModal ? 'items-center flex-col gap-4' : 'md:justify-end '
				)}
			>
				<Button
					label="Update"
					type="button"
					onClick={handleUpdate}
					color="Primary"
					disabled={!isDisabled}
				/>
				{isModal && (
					<Button
						label="I’ll Update later"
						type="button"
						onClick={handleCancel}
						color="Gray2"
						className="text-blue-primary"
					/>
				)}
			</div>
		</div>
	)
}
