import clsx from 'clsx'
import { Button, Typography } from 'components'
import { Icons, MaterialUiPopOverProps, TypographyTypes } from 'interfaces'
import React from 'react'

export const TailwindPopOver: React.FC<MaterialUiPopOverProps> = React.memo(
	({ className, children, title, handleClose, titleClassName }) => {
		return (
			<div
				className={clsx(
					className,
					'flex flex-col px-[10px] bg-white',
					!title ? 'py-3 gap-3' : 'py-2 gap-4'
				)}
			>
				{title && (
					<div
						className={clsx(
							'flex items-center justify-between border-b-[1px] border-gray-3 pb-2',
							titleClassName
						)}
					>
						<Typography
							className="text-black-1 font-semibold"
							type={TypographyTypes.h3}
							fontLeading="18/21"
							title={title}
						/>
						<Button
							iconLeft={Icons.cancel}
							leftIconDivClassName="!w-7 !h-7"
							className="!w-max !min-w-[unset] !px-[unset] !py-[unset] !border-none"
							color="Transparent"
							onClick={() => handleClose()}
						/>
					</div>
				)}
				{children}
			</div>
		)
	}
)
