import { Icon, Typography } from 'components'
import { TypeComponentsItemsArray } from 'components/dynamicTab/optionsNewComponent'
import { Icons, NewComponentModalProps, TypographyTypes } from 'interfaces'
import React from 'react'

export const NewComponentModal: React.FC<NewComponentModalProps> = ({
	setOpenModal,
	createNewComponent
}): JSX.Element => {
	const listItem = [
		{
			name: 'Title',
			icon: Icons.title,
			value: 'title'
		},
		{
			name: 'Custom Text',
			icon: Icons.simpleText,
			value: 'simple_text'
		},
		{
			name: 'Phone',
			icon: Icons.phone,
			value: 'phone'
		},
		{
			name: 'Location',
			icon: Icons.location,
			value: 'location'
		},
		{
			name: 'Link',
			icon: Icons.link,
			value: 'link'
		},
		// {
		// 	name: 'Social Media',
		// 	icon: Icons.socialMedia,
		// 	value: 'social_media'
		// },
		{
			name: 'Tags',
			icon: Icons.tags,
			value: 'tags'
		},
		{
			name: 'Mail',
			icon: Icons.mail,
			value: 'mail'
		}
		// {
		// 	name: 'Content',
		// 	icon: Icons.content,
		// 	value: 'content'
		// },
		// {
		// 	name: 'Attachment',
		// 	icon: Icons.attachment,
		// 	value: 'attachment'
		// }
	]

	const handleOpenSlide = (val: string): void => {
		setOpenModal(false)
		const aux = val as TypeComponentsItemsArray
		createNewComponent?.(aux)
	}

	return (
		<div className="flex flex-col w-full gap-6">
			{listItem.map((item, index) => {
				return (
					<div
						aria-hidden="true"
						className="flex justify-between w-full cursor-pointer"
						key={`modal-item${index}`}
						onClick={() => handleOpenSlide(item.value)}
					>
						<div className="flex items-center">
							<Icon
								fillPath
								src={item.icon}
								className="w-7 text-blue-primary mr-4"
							/>
							<Typography
								title={item.name}
								className="text-gray-9"
								fontLeading="15/18"
								type={TypographyTypes.p}
							/>
						</div>
						<Icon fillPath src={Icons.plus} className="w-7 text-blue-primary" />
					</div>
				)
			})}
		</div>
	)
}
