/* eslint-disable camelcase */
import axios, { AxiosError } from 'axios'
import { BranchConfig, BranchDeepLinkInfo } from 'interfaces'

export type BranchConfigArg = {
	key: string
	secret: string
}

export type BranchUrlQueryParams = {
	url: string
	branch_key: string
}
export type ErrorBranch = {
	error?: {
		code: number
		message?: string
	}
}

export const getBranchConfig = async ({
	key,
	secret
}: BranchConfigArg): Promise<BranchConfig> => {
	try {
		const response = await axios<BranchConfig>(
			`https://api2.branch.io/v1/app/${key}`,
			{
				params: {
					branch_secret: secret
				}
			}
		)
		if (response?.data) return response.data

		throw new Error('Unable to get Branch config')
	} catch (error) {
		const axiosError = error as AxiosError<ErrorBranch>
		throw new Error(
			axiosError?.response?.data?.error?.message ??
				'Unable to get Branch config'
		)
	}
}
export const getBranchDeepLinkInfo = async ({
	url,
	branch_key
}: BranchUrlQueryParams): Promise<BranchDeepLinkInfo> => {
	try {
		const response = await axios<BranchDeepLinkInfo>(
			`https://api2.branch.io/v1/url`,
			{
				params: {
					url,
					branch_key
				}
			}
		)
		if (response?.data) return response.data

		throw new Error('Unable to get deep link Branch information')
	} catch (error) {
		const axiosError = error as AxiosError<ErrorBranch>
		throw new Error(
			axiosError?.response?.data?.error?.message ??
				'Unable to get deep link Branch information'
		)
	}
}
