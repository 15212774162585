import { Icons } from 'interfaces'

export const loginType: {
	google: string
	apple: string
	ios: string
	email: string
	web: string
	organization: string
	android: string
} = {
	google: Icons.google,
	apple: Icons.apple,
	ios: Icons.apple,
	email: Icons.mail,
	web: Icons.adminIconComputer,
	organization: Icons.organization,
	android: Icons.android
}
