import { CircularProgress } from '@mui/material'
import { Input, InputCheck } from 'components'
import { Icons, IndividualsProps, PeopleMyPeopleItem } from 'interfaces'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { configGenaral } from 'utils'

export const Individuals: React.FC<IndividualsProps> = ({
	data,
	isLoading,
	resetInput,
	totalList,
	setTotalList,
	fetchNextPage,
	isFetchingNextPage,
	handleChangeDebounce,
	type
}): JSX.Element => {
	const { ref, inView } = useInView()
	const [search, setSearch] = useState('')
	const [listTotalIndividuals, setListTotalIndividuals] = useState<number[]>(
		totalList.individuals
	)
	const [listIndividuals, setListIndividuals] = useState<PeopleMyPeopleItem[]>(
		[]
	)

	useEffect(() => {
		if (inView) {
			if (
				data &&
				data.pages[0] &&
				data.pages[0].pages > data.pageParams.length
			) {
				fetchNextPage()
			}
		}
	}, [inView])

	useEffect(() => {
		if (data?.pages) {
			let aux = [] as PeopleMyPeopleItem[]
			data.pages.forEach(page => {
				if (page) {
					aux = [...aux, ...page.items]
				}
				setListIndividuals(aux)
			})
		}
	}, [data])

	useEffect(() => {
		setTotalList({
			...totalList,
			individuals: listTotalIndividuals
		})
	}, [listTotalIndividuals])

	return (
		<div>
			<Input
				name="search"
				placeholder="Search Individuals"
				className="w-full !p-[unset]"
				onChangeCustom={e => {
					setSearch(e.target.value)
					handleChangeDebounce(e)
				}}
				rightIcon={Icons.cancel}
				rightClick={() => {
					resetInput()
					setSearch('')
				}}
				value={search}
				maxLength={configGenaral.maxSearchValue}
				inputClassName="!p-[unset] !py-1 !px-2"
				inputFocus
				borderFull={false}
				search
			/>
			<div className="mt-2">
				{isLoading ? (
					<div className="flex items-center justify-center w-full h-[400px] py-2 px-1 ">
						<CircularProgress disableShrink />
					</div>
				) : (
					<div className="max-h-[370px] overflow-y-auto">
						{listIndividuals?.map(item => (
							<InputCheck
								reachView={false}
								id={item.organization_follower_id}
								key={item.user_id}
								title={item.full_name}
								className="mb-4 w-full"
								list={listTotalIndividuals}
								setList={setListTotalIndividuals}
								name={`${item.full_name}-${item.user_id}`}
								type={type}
							/>
						))}
						<div ref={ref}>
							{isFetchingNextPage && (
								<div className="flex items-center justify-center w-full h-[50px] py-2 px-1 ">
									<CircularProgress disableShrink />
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
