import { Popover } from '@mui/material'
import { Box } from 'components/boxs'
import { Button } from 'components/buttons'
import { Icon } from 'components/icon'
import { Icons } from 'interfaces'
import React, { useState } from 'react'

export const OrganizationTagActions: React.FC<{
	onEdit: () => void
	onHide: () => void
	onDelete: () => void
	hidded: boolean
}> = props => {
	const { onEdit, onHide, onDelete, hidded } = props
	const [button, setButton] = useState<HTMLButtonElement | null>(null)
	return (
		<>
			<button type="button" onClick={e => setButton(e.currentTarget)}>
				<Icon
					fillPath
					src={Icons.pending}
					className="w-6 h-6  max-w-[22px] max-h-[22px]"
				/>
			</button>
			<Popover
				anchorEl={button}
				open={Boolean(button)}
				onClose={() => setButton(null)}
				PaperProps={{ className: '!shadow-none' }}
			>
				<Box className="flex !p-3 flex-col gap-3 border-2">
					<>
						<Button
							className="w-36"
							label="Edit"
							iconLeft={Icons.edit}
							onClick={() => {
								onEdit()
								setButton(null)
							}}
							color="Transparent"
						/>
						<Button
							className="w-36"
							label={hidded ? 'Show' : 'Hide'}
							iconLeft={hidded ? Icons.visibility : Icons.visibilityOff}
							onClick={() => {
								onHide()
								setButton(null)
							}}
							color="Primary"
						/>
						<Button
							className="w-36"
							label="Delete"
							iconLeft={Icons.delete}
							onClick={() => {
								onDelete()
								setButton(null)
							}}
							color="Transparent"
						/>
					</>
				</Box>
			</Popover>
		</>
	)
}
