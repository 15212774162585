import { Button } from 'components/buttons'
import { Input } from 'components/input'
import {
	FilterDinamicModalProps,
	ListfilterDinamic,
	PeopleMyListsItem,
	SaveListDinamic
} from 'interfaces'

import { addFiltersDynamicList } from 'api'
import { useToast } from 'hooks'
import React, { useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Cookies from 'universal-cookie'
import { TailwindModal } from '../tailwindModal'

const SaveDinamicListModal: React.FC<
	FilterDinamicModalProps<PeopleMyListsItem>
> = ({ open, setOpen, rows, onConvertData, myListsRefetch, setIsSaveList }) => {
	const cookies = new Cookies()
	const organizationId = cookies.get('organization_id')
	const { showToast } = useToast()
	const {
		register,
		handleSubmit,
		setValue,
		formState: { isValid, errors }
	} = useForm<SaveListDinamic>({
		mode: 'all'
	})

	const rules = useMemo(() => {
		return {
			name: {
				required: { value: true, message: 'This field is required' }
			}
		}
	}, [])

	const onSubmit: SubmitHandler<SaveListDinamic> = async data => {
		try {
			const dataConverted = rows && onConvertData && onConvertData(rows)

			const body = {
				name: data?.name ?? '',
				filters: dataConverted as ListfilterDinamic[]
			}
			setOpen(!open)
			setIsSaveList?.(false)
			await addFiltersDynamicList(organizationId, body).then(response => {
				if (response) {
					showToast('Success', 'List saved successfully', 'success')
					if (myListsRefetch) {
						myListsRefetch()
					}
				}
			})
		} catch (error) {
			console.warn(error)
		}
	}

	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			title="Save Dynamic List Changes"
			className="w-[36.813rem]  p-4 rounded-[16px] flex flex-col "
			helper
			titleHelper="Select followers below. Name and save as a Dynamic List"
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className=" w-full rounded-md mb-3 mt-3 overflow-x-auto">
					<div>
						<Input
							name="name"
							title="List Name"
							register={register}
							rules={rules.name}
							placeholder="Name"
							error={errors.name}
							className="my-4"
							limit
							cantLimit={200}
							setValueInput={setValue}
						/>
					</div>

					<div className=" flex gap-2 flex-wrap ">
						{rows?.map((item, index) => (
							<Button
								label={item.propierty.name}
								key={index}
								className="!min-w-[unset] !py-1 !px-4 !bg-white"
								color="Transparent"
								type="button"
								buttonTextClassName="text-[0.7rem] sm:text-[9px] md:text-[9px] lg:text-[1em]"
							/>
						))}
					</div>

					<div className="w-full flex flex-col sm:flex-row justify-between gap-4 mt-3">
						<Button
							label="Cancel"
							color="Bg-gray-2"
							onClick={() => {
								setOpen(!open)
							}}
							className="!min-w-[16.438rem] "
						/>
						<Button
							type="submit"
							label="Corfirm"
							disabled={!isValid}
							className="!min-w-[16.438rem] "
						/>
					</div>
				</div>
			</form>
		</TailwindModal>
	)
}

export default SaveDinamicListModal
