import { CircularProgress } from '@mui/material'
import {
	GET_DASHBOARD_CONTENT_HIGHLIGHT_ITEM,
	getContentHighLightItem
} from 'api'
import { Box, ContentHighlightItem, TitleBox, Typography } from 'components'
import { BoxComponentProps, Icons, TypographyTypes } from 'interfaces'
import React from 'react'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'

export const ContentHighlights: React.FC<BoxComponentProps> = ({
	styles
}): JSX.Element => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const { data, isLoading } = useQuery(
		[GET_DASHBOARD_CONTENT_HIGHLIGHT_ITEM, userId, organizationId],
		() => getContentHighLightItem(organizationId)
	)

	return (
		<div className={styles}>
			<TitleBox
				title="Content Highlights"
				icon={Icons.permMedia}
				className="mb-4"
			/>
			<Box className="flex flex-col gap-3 w-full h-[274px] py-4 px-6">
				<>
					<Typography
						title="Active & Scheduled Content"
						fontLeading="15/15"
						type={TypographyTypes.h3}
						className="text-black-1"
					/>
					<div className="flex flex-col w-full h-[calc(100%_-_27px)]">
						{isLoading ? (
							<div className="flex items-center justify-center w-full h-[200px] py-2 px-1 ">
								<CircularProgress disableShrink />
							</div>
						) : (
							<>
								<div className="flex justify-between items-center w-full">
									<Typography
										fontLeading="15/18"
										type={TypographyTypes.p}
										title="Title"
										className="!font-normal text-gray-9 basis-[53%]"
									/>
									<div className="flex justify-between items-center basis-[43%]">
										<Typography
											fontLeading="15/18"
											type={TypographyTypes.p}
											title="Type"
											className="basis-[35%] !font-normal text-gray-9"
										/>
										<Typography
											fontLeading="15/18"
											type={TypographyTypes.p}
											title="Live Date"
											className="basis-[44%] !font-normal text-gray-9"
										/>
									</div>
								</div>
								<div className="relative overflow-y-auto h-[calc(100% - 18px)] inset-0">
									{data && data.length > 0 ? (
										data.map(item => {
											return (
												<ContentHighlightItem
													key={item.id}
													name={item.title}
													type={item.type}
													createdAt={item.created_at}
												/>
											)
										})
									) : (
										<div className="w-full h-full flex justify-center items-center">
											<Typography
												title="You don't have any active content."
												type={TypographyTypes.h3}
												fontLeading="12/15"
												className="text-gray-7"
											/>
										</div>
									)}
								</div>
							</>
						)}
					</div>
				</>
			</Box>
		</div>
	)
}
