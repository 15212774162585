import clsx from 'clsx'
import { Icon } from 'components'
import { BottonsProps, Icons } from 'interfaces'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const Bottons: React.FC<BottonsProps> = ({
	roles,
	sidebarOpen,
	handleLogout,
	isSuperAdmin,
	setSidebarOpen,
	responsive = false
}): JSX.Element => {
	const navigate = useNavigate()
	return (
		<div className="flex flex-shrink-0 p-4">
			<div
				className={clsx(
					'flex flex-col w-full',
					sidebarOpen ? 'items-start' : 'items-center'
				)}
			>
				{(roles?.includes(5) || isSuperAdmin) && (
					<button
						type="button"
						className="flex items-center pb-7"
						onClick={() => navigate('/superAdmin/organization')}
					>
						<Icon
							src={Icons.switchAccessShortcut}
							className={clsx('w-7 h-7', sidebarOpen && 'mr-5 ml-4')}
						/>
						{sidebarOpen && <p className="text-gray-1">Switch Organization</p>}
					</button>
				)}
				<button
					type="button"
					className="flex items-center pb-7"
					onClick={handleLogout}
				>
					<Icon
						src={Icons.logout}
						className={clsx('w-7 ', sidebarOpen && 'mr-5 ml-4')}
					/>
					{sidebarOpen && <p className="text-gray-1">Log out</p>}
				</button>
				{!responsive && (
					<button
						type="button"
						className="bg-gray-1 px-3 py-3 rounded-full"
						onClick={() => setSidebarOpen(!sidebarOpen)}
					>
						<Icon
							src={sidebarOpen ? Icons.menuOpen : Icons.menuClose}
							className="w-7 !max-h-[1.75rem]"
						/>
					</button>
				)}
			</div>
		</div>
	)
}
