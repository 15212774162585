/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * TODO:
 * Schedule appointment for review.
 */

import { Button, Icon, TailwindModal, Typography } from 'components'
import { GenericModalProps, Icons, TypographyTypes } from 'interfaces'
import React from 'react'

export const TagsDeleteConfirmationModal: React.FC<GenericModalProps<any>> = ({
	open,
	setOpen,
	onClickConfirm
}) => {
	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			showCloseButton
			className="w-[310px] h-[194px] flex justify-center items-center p-2 rounded-[16px]"
		>
			<div className="flex flex-col gap-4 w-full rounded-[16px]">
				<div className="flex flex-col gap-2 w-full items-center">
					<div className="w-9 h-9">
						<Icon src={Icons.delete} fillPath className="text-gray-9" />
					</div>
					<Typography
						title="You’re about to delete a Tag(s)"
						fontLeading="18/21"
						type={TypographyTypes.h3}
						className="text-gray-9 text-center"
					/>
					<Typography
						title="Are you sure?"
						fontLeading="15/18"
						type={TypographyTypes.p}
						className="!font-normal text-gray-9 text-center"
					/>
				</div>
				<div className="flex justify-center items-center gap-4 w-full">
					<Button
						label="Delete"
						color="Transparent"
						onClick={onClickConfirm}
						className="!min-w-[unset] !py-3 !px-4"
					/>
					<Button
						label="Cancel"
						onClick={() => setOpen(false)}
						className="!min-w-[unset] !py-3 !px-4"
					/>
				</div>
			</div>
		</TailwindModal>
	)
}
