import { CheckIcon, LockClosedIcon } from '@heroicons/react/outline'
import { CircularProgress, Tooltip, Zoom } from '@mui/material'
import { createApp, getUploadImage, updateImage } from 'api'
import clsx from 'clsx'
import {
	BranchSetupForm,
	Button,
	Input,
	InputFile2,
	Message,
	SuperAdminLayout,
	Typography
} from 'components'
import { useToast } from 'hooks'
import {
	BuildAppRequest,
	BuildAppResponse,
	CreateAppForm,
	Icons,
	Images,
	InputFileType,
	TypographyTypes
} from 'interfaces'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import ReactQuill from 'react-quill'
import { useNavigate } from 'react-router-dom'
import { configGenaral } from 'utils'
import './CreateApp.css'

export const CreateApp = (): JSX.Element => {
	const {
		register,
		watch,
		handleSubmit,
		setValue,
		formState: { isValid }
	} = useForm<CreateAppForm>({ mode: 'all' })
	const descriptionRef = useRef<ReactQuill | null>(null)
	const [description, setDescription] = useState('')
	const [appLogoValue, setAppLogoValue] = useState<InputFileType | null>(null)
	const [splashLogoValue, setSplashLogoValue] = useState<InputFileType | null>(
		null
	)
	const [appbuild, setAppbuild] = useState<BuildAppResponse>()
	const [deepLinkCreated, setDeepLinkCreated] = useState(false)
	const [splashBackgroundValue, setSplashBackgroundValue] =
		useState<InputFileType | null>(null)

	const [options, setOptions] = useState([
		{ name: 'Icon App', value: 'iconApp', disabled: false, completed: false },
		{
			name: 'Splash Screen',
			value: 'splashScreen',
			disabled: false,
			completed: false
		},
		{
			name: 'Characteristics',
			value: 'characteristics',
			disabled: true,
			completed: false
		}
	])
	const rules = useMemo(() => {
		return {
			name: {
				required: { value: true, message: 'This field is required' }
			}
		}
	}, [])
	const appNameValue = watch('name')

	const [period, setPeriod] = useState(options[0].value)
	const [isLoading, setIsLoading] = useState(false)
	const navigate = useNavigate()
	const { showToast } = useToast()

	const isChar = period === 'characteristics'
	const isIconApp = period === 'iconApp'
	const isSplash = period === 'splashScreen'

	const updatePhotoHandler = useCallback(
		async (file: InputFileType): Promise<string> => {
			const credentials = await getUploadImage(file.name)
			if (!credentials) return ''
			const getImage = await fetch(file.content)
			const imageBlob = await getImage.blob()
			await updateImage(credentials.url, {
				...credentials.fields,
				file: imageBlob
			})
			return `${credentials.url}${credentials.fields.key}`
		},
		[]
	)

	const updateOptions = (): void => {
		setOptions(prev =>
			prev.map(item => ({
				...item,
				disabled: false,
				completed: item.value !== 'characteristics'
			}))
		)
	}

	const addTagDynamicListHandler: SubmitHandler<CreateAppForm> = async (
		body
	): Promise<void> => {
		setIsLoading(true)
		let logoUrl = null
		let splashLogoUrl = null
		let splashBackgroundUrl = null
		if (appLogoValue !== null) {
			logoUrl = await updatePhotoHandler(appLogoValue)
		}
		if (splashLogoValue !== null) {
			splashLogoUrl = await updatePhotoHandler(splashLogoValue)
		}
		if (splashBackgroundValue !== null) {
			splashBackgroundUrl = await updatePhotoHandler(splashBackgroundValue)
		}
		const request = {
			app_name: body.name,
			description,
			splash_background_url: splashBackgroundUrl,
			splash_icon_url: splashLogoUrl,
			icon_url: logoUrl,
			community_id: Number(process.env.REACT_APP_COMMUNITY_ID)
		} as BuildAppRequest
		createApp(request)
			.then(response => {
				if (response) {
					setAppbuild(response)
					setValue('name', '')
					setDescription('')
					setAppLogoValue(null)
					setSplashLogoValue(null)
					setSplashBackgroundValue(null)
					showToast('Success', 'App created successfully', 'success')
					updateOptions()
				}
			})
			.finally(() => {
				setPeriod('characteristics')
				setIsLoading(false)
			})
	}

	const onSubmit = (): void => {
		if (isIconApp || isSplash) {
			handleSubmit(addTagDynamicListHandler)()
		} else {
			navigate(`/superAdmin/appBuilds/version/${appbuild?.id?.toString()}`)
		}
	}

	const isDiabled = (): boolean => {
		if (isIconApp || isSplash) {
			return (
				!appNameValue || !appLogoValue || !isValid || !splashBackgroundValue
			)
		}

		return !deepLinkCreated
	}

	return (
		<SuperAdminLayout className="!py-[unset]">
			{!isLoading ? (
				<form
					className="flex flex-col justify-between w-full h-screen bg-white py-5 px-8"
					onSubmit={onSubmit}
				>
					<div className="flex justify-between w-full">
						<Typography
							title="Create Build App"
							type={TypographyTypes.h1}
							className="text-black-1"
							fontLeading="26/31"
						/>
						<div className="relative flex">
							<div className="w-7 h-7 flex items-center justify-center mr-[200px] rounded-[50%] bg-blue-primary border border-2 border-blue-primary">
								<Typography
									title="1"
									type={TypographyTypes.span}
									className="text-white"
									fontLeading="16/22"
								/>
							</div>
							<div className="absolute top-[12px] left-[28px] w-[200px] h-[2px] border border-dashed border-blue-primary" />
							<div className="w-7 h-7 flex items-center justify-center mr rounded-[50%] bg-transparent-default border border-2 border-gray-9">
								<Typography
									title="2"
									type={TypographyTypes.span}
									className="text-gray-9"
									fontLeading="16/22"
								/>
							</div>
						</div>
					</div>
					<div className="flex w-full h-[calc(100%_-_140px)]">
						<div
							className={clsx('flex flex-col basis-[50%]', {
								'basis-full': isChar
							})}
						>
							<div className={clsx({ 'w-1/2': isChar })}>
								<div className="flex justify-between gap-4 px-2 shadow-lg pt-2 rounded-lg mb-2">
									{options.map(option => (
										<button
											type="button"
											key={option.value}
											className={clsx('w-full flex flex-col justify-end')}
											onClick={() => setPeriod(option.value)}
											disabled={option.disabled || option.completed}
										>
											<Tooltip
												title={
													<Typography
														className={clsx('text-white/80 !font-normal')}
														type={TypographyTypes.span}
														title="To be able to edit the features, you will have to complete the creation of the APP."
														fontLeading="15/15"
													/>
												}
												arrow
												disableHoverListener={!option.disabled}
												TransitionComponent={Zoom}
											>
												<div className="flex items-center justify-center w-full">
													<Typography
														className={clsx(
															'!font-medium px-2 transition-all !text-base sm:!text-lg',
															period === option.value
																? 'text-blue-primary'
																: 'text-gray-9',
															option.disabled && 'text-gray-8'
														)}
														type={TypographyTypes.span}
														title={option.name}
														fontLeading="18/21"
													/>
													{option.disabled && (
														<LockClosedIcon className="h-5 w-5 text-gray-8" />
													)}
													{option.completed && (
														<CheckIcon className="h-5 w-5 text-blue-primary" />
													)}
												</div>
											</Tooltip>
											<div
												className={clsx(
													'w-full h-1 rounded-[2px] mt-2',
													period === option.value && 'bg-blue-primary'
												)}
											/>
										</button>
									))}
								</div>
							</div>
							{period === 'iconApp' && (
								<>
									<Typography
										title="Title and Logo"
										type={TypographyTypes.h2}
										className="text-blue-primary mt-6"
										fontLeading="18/21"
									/>
									<InputFile2
										title="Upload Organization Icon"
										name="orgIcon"
										icon={Icons.imagesMode}
										icon2={Icons.photoCamera}
										setValue={setAppLogoValue}
										className="mt-6"
									/>
									<Input
										name="name"
										title="Your App Name"
										placeholder="Write a name for Your App"
										register={register}
										rules={rules.name}
										className="!w-full !p-[unset] mt-6"
										inputClassName="!p-[unset] !py-1"
										inputFieldClassName="!font-normal"
										inputFocus
										limit
										cantLimit={20}
										watchValue={watch('name')}
										setValueInput={setValue}
									/>
									<Typography
										title="Description for Your App"
										type={TypographyTypes.label}
										className={clsx('text-black-1 mt-6')}
										fontLeading="12/12"
									/>
									<ReactQuill
										theme="snow"
										ref={descriptionRef}
										value={description}
										onChange={(value: string) =>
											setDescription(value.replace(/color:\s?[^;]+;/gi, ''))
										}
										className="w-full h-[245px] mt-4 quillCustom1"
										placeholder="Write the description here"
									/>
									{description.length > configGenaral.maxDescriptionLength && (
										<Message
											text={`Maximum ${configGenaral.maxDescriptionLength} characters exceeded`}
											type="error"
										/>
									)}
								</>
							)}
							{period === 'splashScreen' && (
								<div>
									<Typography
										title="Logo and background"
										type={TypographyTypes.h2}
										className="text-blue-primary mt-6"
										fontLeading="18/21"
									/>
									<InputFile2
										title="Upload Splash Screen Icon"
										name="splashScreenIcon"
										icon={Icons.imagesMode}
										icon2={Icons.photoCamera}
										setValue={setSplashLogoValue}
										className="mt-6"
									/>
									<InputFile2
										title="Upload Splash Background"
										name="splashScreenBackground"
										icon={Icons.imagesMode}
										icon2={Icons.photoCamera}
										setValue={setSplashBackgroundValue}
										className="mt-6"
									/>
								</div>
							)}
							{isChar && (
								<div className="pb-4 pt-6 rounded-xl border border-blue-primary px-5 mt-5">
									<BranchSetupForm
										appBuild={appbuild}
										onSuccessDeepLink={url => setDeepLinkCreated(!!url)}
									/>
								</div>
							)}
						</div>

						{!isChar && (
							<div className="flex flex-col items-center basis-[50%]">
								<div className="relative w-[290px] h-[588px]">
									<div className="absolute z-20 w-full h-full">
										<img
											src={Images.phoneImage}
											alt="Phone"
											className="w-full h-full"
										/>
									</div>

									{period === 'iconApp' && (
										<>
											<div className="absolute z-20 top-[50%] right-[50%] translate-x-[50%] translate-y-[-50%] z-10 w-[143px] h-[143px] shadow-xl">
												<img
													src={
														appLogoValue === null
															? Images.phoneLogo
															: appLogoValue.content
													}
													alt="Logo"
													className="w-full h-full object-contain"
												/>
											</div>
											<Typography
												title={appNameValue}
												type={TypographyTypes.h3}
												className="absolute z-20 top-[378px] right-[50%] translate-x-[50%] text-black-1 text-center"
												fontLeading="18/21"
											/>
										</>
									)}
									{period === 'splashScreen' && (
										<>
											{splashBackgroundValue !== null && (
												<div className="relative z-10 w-full h-full rounded-[50px] opacity-60">
													<img
														src={splashBackgroundValue.content}
														alt="splashBackground"
														className="w-full h-full rounded-[50px]"
													/>
												</div>
											)}
											{splashLogoValue !== null && (
												<div className="absolute z-20 top-[50%] right-[50%] translate-x-[50%] translate-y-[-50%] z-10 w-[95px] h-[95px] shadow-xl rounded-[12px]">
													<img
														src={splashLogoValue.content}
														alt="splashLogo"
														className="w-full h-full object-contain"
													/>
												</div>
											)}
										</>
									)}
								</div>
							</div>
						)}
					</div>
					<div
						className={clsx('flex justify-between w-full mt-6', {
							' !justify-end': isChar
						})}
					>
						{!isChar && (
							<Button
								label="Back"
								className="!min-w-[unset] !w-[110px]"
								color="Transparent"
								onClick={() => navigate(-1)}
							/>
						)}
						<Button
							label="Save"
							className="!min-w-[unset] !w-[110px]"
							type="submit"
							disabled={isDiabled()}
						/>
					</div>
				</form>
			) : (
				<div className="flex items-center justify-center w-full h-screen py-2 px-1 ">
					<CircularProgress disableShrink />
				</div>
			)}
		</SuperAdminLayout>
	)
}
