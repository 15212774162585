import { CheckCircleIcon } from '@heroicons/react/outline'
import { CircularProgress } from '@mui/material'
import { patchPeopleBatches } from 'api'
import clsx from 'clsx'
import { Icon, Modal, Typography } from 'components'
import { useContextPeopleBatches } from 'context'
import { useToast } from 'hooks'
import {
	Icons,
	PatchPeopleBatchesPayload,
	PeopleBatchesConfirmModalProps,
	PeopleBatchesModalType,
	PeopleBatchesStatus,
	PeopleBatchesTagAction,
	TypographyTypes
} from 'interfaces'
import React from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { PRIMARY_BLUE_COLOR } from 'utils'

const labels: { [key in PeopleBatchesModalType]: string } = {
	back: 'Are you sure you want to go back without making changes?',
	removeTag: 'Are you sure you want to remove tags to the selected users?',
	addTag: 'Are you sure you want to add tags to the selected users?',
	block: 'Are you sure you want to block the selected users?',
	unblock: 'Are you sure you want to unblock the selected users?',
	removeSelected: 'Are you sure you want to remove selected users?'
}

export const PeopleBatchesConfirmModal = (
	props: PeopleBatchesConfirmModalProps
): JSX.Element => {
	const { open, setOpen, organizationId, selectedTags, onSuccess, onError } =
		props
	const navigate = useNavigate()
	const { showToast } = useToast()
	const {
		selectedUsers,
		organizationFollower,
		setSelectedUsers,
		setOrganizationFollower
	} = useContextPeopleBatches()
	// const isSuccess = true
	const {
		mutate,
		isLoading: isLoadingPatch,
		isSuccess
	} = useMutation({
		mutationFn: (params: Omit<PatchPeopleBatchesPayload, 'organizationId'>) =>
			patchPeopleBatches({ organizationId, ...params }),
		onSuccess: () => {
			setSelectedUsers([])
			setOrganizationFollower([])
			setTimeout(() => {
				navigate('/people', { replace: true })
				setOpen(null)
			}, 800)
			onSuccess?.()
		},
		onError: () => {
			showToast(
				'Error',
				'Your changes could not be saved. Please try again in a few minutes.',
				'error'
			)
			setOpen(null)
			onError?.()
		}
	})

	const modalActions: { [key in PeopleBatchesModalType]: () => void } = {
		back: () => {
			setSelectedUsers([])
			navigate('/people', { replace: true })
		},
		removeTag: () =>
			mutate({
				userIds: selectedUsers,
				organization_follower_ids: organizationFollower,
				organization_tags: {
					action: PeopleBatchesTagAction.REMOVE,
					ids: selectedTags
				}
			}),
		addTag: () =>
			mutate({
				userIds: selectedUsers,
				organization_follower_ids: organizationFollower,
				organization_tags: {
					action: PeopleBatchesTagAction.ADD,
					ids: selectedTags
				}
			}),
		block: () =>
			mutate({
				organization_follower_ids: organizationFollower,
				userIds: selectedUsers,
				status_user: PeopleBatchesStatus.BLOCK
			}),
		unblock: () =>
			mutate({
				organization_follower_ids: organizationFollower,
				userIds: selectedUsers,
				status_user: PeopleBatchesStatus.UNBLOCK
			}),
		removeSelected: () =>
			mutate({
				organization_follower_ids: organizationFollower,
				userIds: selectedUsers,
				remove_users: true
			})
	}

	const disabledModalActions = !(isLoadingPatch || isSuccess)

	return (
		<Modal
			setOpen={(val: boolean) => (!val ? setOpen(null) : null)}
			open={!!open}
			type="normal"
			closeButton={disabledModalActions}
			className="!z-[100]"
			colorIcon="w-[14px] h-[14px] text-gray-3"
			borderIcon="p-[5px]"
			sizeModal={clsx('!w-auto pb-4 px-4 pt-2.5', {
				'!pt-4': isSuccess || isLoadingPatch
			})}
			marginL
			closeOutside={disabledModalActions}
		>
			<div>
				{isLoadingPatch && (
					<div className="flex min-h-[152px] w-[380px] justify-center items-center">
						<CircularProgress
							style={{ color: PRIMARY_BLUE_COLOR }}
							disableShrink
						/>
					</div>
				)}
				{isSuccess && (
					<div className="flex min-h-[152px] w-[380px] justify-center items-center">
						<CheckCircleIcon className=" text-blue-primary h-16 w-16" />
					</div>
				)}
				{!isLoadingPatch && !isSuccess && (
					<div className="flex flex-col items-center">
						<Icon
							className=" text-blue-primary h-8 w-8 mb-4 "
							fillPath
							src={Icons.warning}
						/>
						<Typography
							title={open ? labels?.[open] : ''}
							fontLeading="15/18"
							type={TypographyTypes.h3}
							className=" text-gray-18 !font-normal text-center "
						/>
						<div className="w-[65%] flex items-center justify-between mt-4 gap-5">
							<button
								className="bg-blue-primary py-2 rounded-full border-2 border-blue-primary w-1/2"
								type="button"
								onClick={() => setOpen(null)}
							>
								<Typography
									title="Cancel"
									fontLeading="15/18"
									type={TypographyTypes.h3}
									className="text-white"
								/>
							</button>
							<button
								className="py-2 rounded-full border-2 border-blue-primary w-1/2"
								type="button"
								onClick={() => {
									if (open) {
										modalActions?.[open]()
									}
								}}
							>
								<Typography
									title="Confirm"
									fontLeading="15/18"
									type={TypographyTypes.h3}
									className="text-blue-primary"
								/>
							</button>
						</div>
					</div>
				)}
			</div>
		</Modal>
	)
}
