import { CircularProgress } from '@mui/material'
import { createAppVersion } from 'api'
import clsx from 'clsx'
import {
	Button,
	Icon,
	Input,
	Message,
	TailwindModal,
	Typography
} from 'components'
import { useToast } from 'hooks'
import {
	BuildAppResponse,
	BuildAppsModalForm,
	GenericModalProps,
	Icons,
	Images,
	TypographyTypes
} from 'interfaces'
import React, { useCallback, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import ReactQuill from 'react-quill'
import { useParams } from 'react-router-dom'
import { configGenaral } from 'utils'
import './buildAppModal.css'

export const BuildAppsModal: React.FC<GenericModalProps<BuildAppResponse>> = ({
	open,
	setOpen,
	onSubmit,
	data
}) => {
	const {
		register,
		handleSubmit,
		setValue,
		watch,
		formState: { errors, isValid }
	} = useForm<BuildAppsModalForm>({
		mode: 'all'
	})
	const [description, setDescription] = useState('')
	const rules = useMemo(() => {
		return {
			name: {
				required: { value: true, message: 'This field is required' }
			},
			version: {
				required: { value: true, message: 'This field is required' }
			},
			email: {
				required: { value: true, message: 'This field is required' }
			}
		}
	}, [])

	const [selected, setSelected] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [step, setStep] = useState('first')
	const { id } = useParams()
	const { showToast } = useToast()
	const createAppVersionHandler: SubmitHandler<BuildAppsModalForm> =
		useCallback(
			async (values): Promise<void> => {
				setIsLoading(true)
				createAppVersion(id, {
					version_name: values.name,
					version_build: values.version,
					version_testers: values.email,
					build_app_id: Number(id),
					os_type: selected,
					description,
					status: 'draft'
				})
					.then(response => {
						if (response) {
							showToast('Success', 'App created successfully', 'success')
							if (onSubmit) {
								onSubmit()
							}
							setOpen(false)
						}
					})
					.finally(() => {
						setIsLoading(false)
					})
			},
			[id, selected]
		)

	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			showCloseButton={step === 'first'}
			title={(() => {
				let title: string | undefined
				if (step === 'second' && selected === 'android') {
					title = 'App Android version'
				} else if (step === 'second' && selected === 'ios') {
					title = 'App iOS version'
				}
				return title
			})()}
			className={clsx(
				'flex flex-col items-center rounded-[16px]',
				step === 'first' && 'p-8 w-[464px] h-[440px]',
				step === 'second' && 'py-4 px-6 w-[976px] h-[768px] min-h-[768px]'
			)}
			titleClassName="!text-[31px] !leading-[37px]"
		>
			{!isLoading ? (
				<>
					{step === 'first' && (
						<>
							<div className="w-8 h-8">
								<Icon src={Icons.save} fillPath className="text-blue-primary" />
							</div>
							<Typography
								title="Please select what version would you like to create"
								type={TypographyTypes.h2}
								className="text-blue-primary text-center mt-4"
								fontLeading="16/22"
							/>
							<div className="flex gap-10 mt-8">
								<button
									className={clsx(
										'flex flex-col gap-8 items-center justify-center w-[158px] h-[192px]',
										selected === 'android'
											? 'border border-blue-primary rounded-[16px]'
											: 'shadow-[0px_1px_10px_4px_rgba(50,_50,_71,_0.08)]'
									)}
									type="button"
									onClick={() => setSelected('android')}
								>
									<div className="w-[78px] h-[78px]">
										<Icon
											src={Icons.android}
											fillPath
											className="text-green-secondary"
										/>
									</div>
									<div className="flex justify-center items-center gap-2 py-1 w-[106px] border border-gray-3 rounded-[24px]">
										<Typography
											title="Android"
											fontLeading="12/15"
											type={TypographyTypes.span}
											className="text-blue-primary !font-semibold"
										/>
										{selected === 'android' && (
											<div className="w-6 h-6 p-1 rounded-[50%] bg-gray-3">
												<Icon
													src={Icons.check}
													fillPath
													className="text-white"
												/>
											</div>
										)}
									</div>
								</button>
								<button
									className={clsx(
										'flex flex-col gap-8 items-center justify-center w-[158px] h-[192px]',
										selected === 'ios'
											? 'border border-blue-primary rounded-[16px]'
											: 'shadow-[0px_1px_10px_4px_rgba(50,_50,_71,_0.08)]'
									)}
									type="button"
									onClick={() => setSelected('ios')}
								>
									<div className="w-[78px] h-[78px]">
										<Icon src={Icons.apple} fillPath className="text-black-2" />
									</div>
									<div className="flex justify-center items-center gap-2 py-1 w-[106px] border border-gray-3 rounded-[24px]">
										<Typography
											title="iOS"
											fontLeading="12/15"
											type={TypographyTypes.span}
											className="text-blue-primary !font-semibold"
										/>
										{selected === 'ios' && (
											<div className="w-6 h-6 p-1 rounded-[50%] bg-gray-3">
												<Icon
													src={Icons.check}
													fillPath
													className="text-white"
												/>
											</div>
										)}
									</div>
								</button>
							</div>
							<Button
								label="Next"
								className="!min-w-[unset] !w-[120px] mt-10"
								disabled={selected === ''}
								onClick={() => setStep('second')}
							/>
						</>
					)}
					{step === 'second' && (
						<form
							className="flex flex-col justify-end gap-10 w-full h-full"
							onSubmit={handleSubmit(createAppVersionHandler)}
						>
							<div className="flex w-full">
								<div className="basis-[50%] flex flex-col items-left">
									<Input
										name="name"
										title="Version name"
										placeholder="Text here"
										register={register}
										error={errors.name}
										rules={rules.name}
										className="!w-full !p-[unset] mt-6"
										inputClassName="!p-[unset] !py-1"
										inputFieldClassName="!font-normal"
										inputFocus
										limit
										cantLimit={20}
										watchValue={watch('name')}
										setValueInput={setValue}
									/>
									<Input
										name="version"
										title="Store Version Number"
										placeholder="1.0"
										register={register}
										error={errors.version}
										rules={rules.version}
										className="!w-full !p-[unset] mt-6"
										inputClassName="!p-[unset] !py-1"
										inputFieldClassName="!font-normal"
										inputFocus
										watchValue={watch('version')}
										setValueInput={setValue}
										regexRestriction={/^[0-9.]+$/}
										limit
										cantLimit={10}
									/>
									<Typography
										title="What's New in This Version?"
										type={TypographyTypes.label}
										className={clsx('text-black-1 mt-6')}
										fontLeading="12/12"
									/>
									<ReactQuill
										theme="snow"
										value={description}
										onChange={(value: string) =>
											setDescription(value.replace(/color:\s?[^;]+;/gi, ''))
										}
										className="w-full h-[186px] mt-4 quillCustom2"
										placeholder="Write the description here"
									/>
									{description.length > configGenaral.maxDescriptionLength && (
										<Message
											text={`Maximum ${configGenaral.maxDescriptionLength} characters exceeded`}
											type="error"
										/>
									)}
									<Input
										name="email"
										title="Tester user's email"
										placeholder="Enter user's email"
										type="email"
										register={register}
										error={errors.email}
										rules={rules.email}
										className="!w-full !p-[unset] mt-5"
										inputClassName="!p-[unset] !py-1"
										inputFieldClassName="!font-normal !p-[unset]"
										inputFocus
									/>
								</div>
								<div className="basis-[50%] flex justify-center items-center">
									<div className="relative w-[260px] h-[588px]">
										<div className="absolute z-20 w-full h-full">
											<img
												src={Images.phoneImage}
												alt="Phone"
												className="w-full h-full"
											/>
										</div>
										<div className="relative z-10 w-full h-full rounded-[50px] opacity-60">
											<img
												src={data?.splash_background_url}
												alt="splashBackground"
												className="w-full h-full rounded-[50px]"
											/>
										</div>
										<div className="absolute z-20 top-[50%] right-[50%] translate-x-[50%] translate-y-[-50%] w-[95px] h-[95px] shadow-xl rounded-[12px]">
											<img
												src={data?.splash_icon_url}
												alt="splashLogo"
												className="w-full h-full object-contain"
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="flex justify-end w-full">
								<Button
									label="Save"
									className="!min-w-[unset] !w-[110px]"
									type="submit"
									disabled={
										!isValid ||
										description
											.replaceAll('<p>', '')
											.replaceAll('<br>', '')
											.replaceAll('</p>', '').length === 0
									}
								/>
							</div>
						</form>
					)}
				</>
			) : (
				<div className="flex items-center justify-center w-full h-screen py-2 px-1">
					<CircularProgress disableShrink />
				</div>
			)}
		</TailwindModal>
	)
}
