import { CircularProgress } from '@mui/material'
import { Button } from 'components/buttons'
import { Typography } from 'components/typography'
import { NetworkActionProps, TypographyTypes } from 'interfaces'
import React from 'react'
import { TailwindModal } from '../tailwindModal'

export const NetworkActionsModal: React.FC<NetworkActionProps> = ({
	onActionClick,
	acceptHidden,
	declineHidden,
	disconnectHidden,
	isLoading,
	onClose,
	open
}) => {
	return (
		<TailwindModal
			open={open}
			setOpen={onClose}
			className="w-[240px] h-max p-2 rounded-2xl flex flex-col gap-4 items-center"
		>
			<>
				<div className="flex w-full justify-between">
					<Typography
						fontLeading="18/21"
						title="Actions"
						type={TypographyTypes.h3}
					/>
				</div>
				{isLoading ? (
					<CircularProgress className="my-20" disableShrink />
				) : (
					<>
						<Button
							label="Accept"
							className="!min-w-[unset] !w-full !py-2"
							onClick={() => onActionClick('accepted')}
							hidden={acceptHidden}
						/>
						<Button
							label="Decline"
							className="!min-w-[unset] !w-full !py-2"
							color="DangerTransparent"
							onClick={() => onActionClick('declined')}
							hidden={declineHidden}
						/>
						<Button
							label="Cancel"
							className="!min-w-[unset] !w-full !py-2 !font-semibold"
							color="Transparent"
							onClick={onClose}
						/>
						<hr style={{ border: 'px solid #A3A3A3', width: '100%' }} />
						<Button
							label="Disconnect FM partnership"
							className="!min-w-[unset] !w-full !p-2  !font-semibold"
							color="Transparent"
							hidden={disconnectHidden}
							onClick={() => onActionClick('disconnect')}
						/>
					</>
				)}
			</>
		</TailwindModal>
	)
}
