import { useEffect, useState } from 'react'

const getYouTubeVideoId = (url: URL): string => {
	return url.searchParams.has('v')
		? (url.searchParams.get('v') as string)
		: url.pathname.substr(1)
}

const getVimeoVideoId = (url: URL): string => {
	const parts = url.pathname.split('/')
	return parts.length > 1 ? parts[parts.length - 1] : ''
}

const useVideoId = (link: string | undefined): string => {
	const [videoId, setVideoId] = useState<string>('')

	useEffect(() => {
		if (!link) {
			setVideoId('')
			return
		}

		try {
			const url = new URL(link)
			let parsedVideoId = ''

			switch (true) {
				case url.hostname.includes('youtube.com') ||
					url.hostname.includes('youtu.be'):
					parsedVideoId = getYouTubeVideoId(url)
					break
				case url.hostname.includes('vimeo.com'):
					parsedVideoId = getVimeoVideoId(url)
					break
				default:
					console.warn('Unsupported video platform')
					break
			}

			setVideoId(parsedVideoId)
		} catch (error) {
			setVideoId('')
		}
	}, [link])

	return videoId
}

export default useVideoId
