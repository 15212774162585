import clsx from 'clsx'
import { Box, Button, Icon } from 'components'
import { BoxComponentProps, Icons } from 'interfaces'
import React from 'react'

export const SendDirectPush: React.FC<BoxComponentProps> = ({
	styles
}): JSX.Element => {
	return (
		<div className={clsx(styles)}>
			<Box className="w-full h-[274px]">
				<>
					<div className="flex items-center justify-between mb-4">
						<h2 className="text-[15px] leading-[15px] font-Inter font-[700]">
							Send Direct Push
						</h2>
					</div>
					<div className="h-[80%]">
						<div className="flex items-center gap-2 h-[80%]">
							<div className="w-[22px] h-[22px]">
								<Icon
									src={Icons.adUnitsPush}
									fillPath
									className="text-gray-9 h-[24px] w-[24px]"
								/>
							</div>
							<h3 className="text-[12px] leading-[15px] font-Inter font-[400]">
								{`Create and send a push notification to your followers' phones
								(independent of a content post).`}
							</h3>
						</div>

						<div className="w-full">
							<Button
								label="New Direct Push Notification"
								iconLeft={Icons.add}
								className="!w-[100%]"
								linkTo="/content/push-notification"
								buttonTextClassName="md:text-[10px] lg:text-[11px] xl:text-[14px] sm:text-[11px] "
							/>
						</div>
					</div>
				</>
			</Box>
		</div>
	)
}
