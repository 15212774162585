/* eslint-disable max-len */
import axios, { AxiosInstance } from 'axios'
import Cookies from 'universal-cookie'
import { ApiConfigutationProps } from './api.interface'

export const API = (props?: ApiConfigutationProps): AxiosInstance => {
	const cookies = new Cookies()
	const baseURL = props?.customUrl ?? process.env.REACT_APP_API
	const token = cookies.get('token') || props?.customAuthorization
	const API_INSTANCE = axios.create({
		baseURL
	})

	API_INSTANCE.interceptors.request.use(
		config => {
			const configuration = config

			if (configuration.headers) {
				if (props?.contentType) {
					configuration.headers['Content-Type'] = props?.contentType
				}
				if (token && !props?.authorization) {
					configuration.headers.Authorization = props?.customAuthorization
						? props.customAuthorization
						: token
				}
			}
			configuration.headers['ngrok-skip-browser-warning'] = 1
			return configuration
		},
		error => {
			return Promise.reject(error)
		}
	)

	return API_INSTANCE
}
