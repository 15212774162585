import { CircularProgress } from '@mui/material'
import { ADMIN_DATA_EDIT, GET_USER_ROLES, getUserRoles } from 'api'
import {
	createNewAdminOrganization,
	deleteAdminService,
	getAdminOrganization,
	patchContentEditAdmin
} from 'api/get/superAdminDashboard/superAdminDashboard'
import {
	AdminOrganizationStepFooter,
	AdminTeamForm,
	AdminTeamTable,
	Button,
	DeleteAdminModal,
	Input,
	Pagination,
	Slide,
	Title
} from 'components'
import { useToast } from 'hooks'
import {
	AdminForm,
	AdminOrganizationStepComponentProps,
	AdminTeamType,
	Icons,
	Item
} from 'interfaces'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'

export const AdminTeam: React.FC<
	AdminOrganizationStepComponentProps<AdminTeamType[]>
> = props => {
	const { onBack, stepper, data: registeredAdmins, onSave, editing } = props
	const [search, setSearch] = useState('')
	const [isCreatingAdmin, setIsCreatingAdmin] = useState(false)
	const [validateEmail, setValidateEmail] = useState(false)

	const [isValidatingUser, setIsValidatingUser] = useState(false)
	const [adminToEdit, setAdminToEdit] = useState<number | null>(null)
	const [adminToDelete, setAdminToDelete] = useState<number | null>(null)
	const [page, setPage] = useState(1)
	const [admins, setAdmins] = useState<AdminTeamType[]>(registeredAdmins || [])
	const isEditingAdmin = Boolean(adminToEdit)
	const { showToast } = useToast()
	const navigate = useNavigate()

	const { data: userRoles, isLoading } = useQuery(
		[GET_USER_ROLES],
		getUserRoles
	)

	const { state } = useLocation()
	const id = state?.id || undefined

	const {
		data: dataToEdit,
		refetch,
		isLoading: loading
	} = useQuery([ADMIN_DATA_EDIT, id], () => getAdminOrganization(id), {
		enabled: id !== undefined
	})

	function createSuccess(
		message: string,
		status: 'success' | 'error' | 'warning'
	): void {
		showToast(status, `${message}`, status)
		refetch()
	}

	const deleteAdmin = async (idUser: number): Promise<void> => {
		if (!editing) {
			const newAdmins = admins.filter(admin => admin.id !== idUser)

			setAdmins(newAdmins)
		} else {
			const responseDelete = await deleteAdminService(id, idUser)

			if (
				responseDelete &&
				responseDelete.message === 'Super admin deleted for this organization'
			) {
				createSuccess(responseDelete?.message, 'success')
			}
		}
	}

	const createAdmin = async (admin: AdminForm): Promise<void> => {
		const dataNew = {
			...admin,
			image: null,
			full_name: admin.fullName,
			community_id: process.env.REACT_APP_COMMUNITY_ID,
			phone_number: `${admin.phoneNumberCode}-${admin.phoneNumber}`
		}

		if (!editing) {
			if (admins.length === 0) {
				setAdmins(prev => [
					...prev,
					{
						...admin,
						id: admin.id ?? new Date().getTime(),
						newUser: !admin.id
					}
				])
			} else if (admins.find(item => item.email !== dataNew.email)) {
				setAdmins(prev => [
					...prev,
					{
						...admin,
						id: admin.id ?? new Date().getTime(),
						newUser: !admin.id
					}
				])
			} else {
				const updateAdmins = admins
				const idAdmin = updateAdmins.findIndex(
					item => item.email === dataNew.email
				)
				updateAdmins[idAdmin] = {
					...admin,
					id: admin.id ?? new Date().getTime(),
					newUser: !admin.id
				}
				setAdmins(updateAdmins)
			}
			showToast(
				'New admin created!',
				`They can join your organization now with the permissions you gave them`,
				'success'
			)
			setIsValidatingUser(false)
		} else if (admin?.id) {
			const dataExisting = {
				user_id: admin.id,
				roles: admin.roles,
				community_id: process.env.REACT_APP_COMMUNITY_ID
			}
			createNewAdminOrganization(id, dataExisting).then(response => {
				if (response) {
					if (response.detail) {
						createSuccess(response.detail, 'error')
						showToast(response.detail, '', 'error')
					} else {
						createSuccess(response.message, 'success')
						showToast(response.message, '', 'success')
					}
				}
			})
		} else {
			createNewAdminOrganization(id, dataNew).then(response => {
				if (response) {
					if (response.detail) {
						createSuccess(response.detail, 'error')
						showToast(response.detail, '', 'error')
					} else {
						createSuccess(response.message, 'success')
						showToast(response.message, '', 'success')
					}
				}
			})
		}

		setAdminToEdit(null)
		setIsCreatingAdmin(false)
	}

	const updateAdmin = async (
		adminId: number,
		data: AdminForm
	): Promise<void> => {
		if (!editing) {
			deleteAdmin(adminId)
			createAdmin(data)
		} else {
			const dataUpdate = {
				email: data.email,
				full_name: data.fullName,
				roles: data.roles,
				id: data.id,
				phone_number: `${data.phoneNumberCode}-${data.phoneNumber}`
			}
			const dataUpdateWithPassword = {
				email: data.email,
				full_name: data.fullName,
				password: data.password,
				roles: data.roles,
				id: data.id,
				phone_number: `${data.phoneNumberCode}-${data.phoneNumber}`
			}

			const response = await patchContentEditAdmin(
				id,
				data.password ? dataUpdateWithPassword : dataUpdate,
				data.id
			)
			if (response && response.message === 'Super admin update successfully') {
				createSuccess(response?.message, 'success')
			}
		}
		setAdminToEdit(null)
	}

	useEffect(() => {
		if (dataToEdit) {
			const newDataAdmin = [] as AdminTeamType[]
			dataToEdit.items.map((item: Item) => {
				const nameRoles: string[] = item.roles.split(',')
				const idRoles: number[] = []
				nameRoles.map(element => {
					if (userRoles?.roles) {
						userRoles?.roles.filter(a => {
							if (a.name === element) {
								return idRoles.push(a.id)
							}
							return []
						})
					}
					return []
				})
				return newDataAdmin.push({
					id: item.user_id,
					fullName: item.full_name,
					email: item.email,
					password: '', // temporal porque no llegan de back
					phoneNumberCode: '', // temporal porque no llegan de back
					phoneNumber: '', // temporal porque no llegan de back
					image: null, // temporal porque no llegan de back
					roles: idRoles
				})
			})
			setAdmins(newDataAdmin)
		}
	}, [dataToEdit, userRoles])

	return (
		<>
			<div className="flex justify-between mb-5">
				<Title
					icon={Icons.groups}
					title="Admin Team"
					subtitle="Manage Yours Team Members"
				/>
				{stepper}
			</div>

			{isLoading || loading ? (
				<div className="h-[60vh] flex justify-center items-center">
					<CircularProgress disableShrink />
				</div>
			) : (
				<>
					<div className="flex items-center gap-8 mb-6">
						<Input
							name="search"
							search
							value={search}
							rightClick={() => setSearch('')}
							onChange={e => setSearch(e.target.value)}
							className="w-full"
							placeholder="Search Administrator"
							rightIcon={Icons.cancel}
							inputClassName="!p-[unset] !py-1 !px-2"
							inputFocus
							borderFull={false}
						/>
						<Button
							onClick={() => setIsCreatingAdmin(true)}
							color="Primary"
							label="Add New Admin"
						/>
					</div>
					<AdminTeamTable
						roles={userRoles?.roles || []}
						data={admins.filter(
							admin =>
								admin.fullName
									.toLowerCase()
									.includes(search.toLowerCase().trim()) ||
								admin.email.toLowerCase().includes(search.toLowerCase().trim())
						)}
						page={page}
						setPage={setPage}
						onEdit={setAdminToEdit}
						onDelete={setAdminToDelete}
					/>
					<AdminOrganizationStepFooter
						className="mt-4 mb-2"
						onBack={() => {
							if (editing) {
								navigate('/superAdmin/organization')
							} else {
								onBack?.(admins)
							}
						}}
						onSave={() => {
							if (editing) {
								navigate('/superAdmin/organization')
							} else if (admins.length > 0) onSave?.(admins)
						}}
						disabledNext={admins.length === 0}
						save
					/>
					<Pagination
						itemsShowing={admins.length}
						totalRecords={admins.length}
						pageLimit={50}
						page={page}
						onPageChanged={({ currentPage }) => setPage(currentPage)}
					/>
				</>
			)}
			<Slide
				open={isCreatingAdmin || isValidatingUser || isEditingAdmin}
				setOpen={() => {
					setIsCreatingAdmin(false)
					setAdminToEdit(null)
					setValidateEmail(false)
				}}
				title="Add New Admin"
			>
				{isLoading ? (
					<div className="flex h-full items-center justify-center">
						<CircularProgress disableShrink />
					</div>
				) : (
					<AdminTeamForm
						admin={admins.find(admin => admin.id === adminToEdit)}
						roles={userRoles?.roles || []}
						registeredEmails={admins.map(admin => admin.email)}
						onSave={
							adminToEdit !== null
								? data => updateAdmin(adminToEdit, data)
								: createAdmin
						}
						onCancel={() => {
							setIsCreatingAdmin(false)
							setAdminToEdit(null)
						}}
						validateEmail={validateEmail}
						isCreatingAdmin={isCreatingAdmin}
						setValidateEmail={setValidateEmail}
					/>
				)}
			</Slide>
			<DeleteAdminModal
				open={adminToDelete !== null}
				setOpen={() => setAdminToDelete(null)}
				onClickConfirm={() => {
					if (adminToDelete) deleteAdmin(adminToDelete)
					setAdminToDelete(null)
				}}
			/>
		</>
	)
}
