import clsx from 'clsx'
import { Table } from 'components'
import { Button } from 'components/buttons'
import {
	ParnetTableProps,
	PartnerTableItem,
	PartnerTableResponse
} from 'interfaces'
import moment from 'moment'

import React, { useCallback, useEffect, useMemo } from 'react'
import { Column, Row } from 'react-table'
import { renderCellCapitalize, renderCellSimpleValue } from 'utils'

export const PartnerTablet: React.FC<
	ParnetTableProps<PartnerTableResponse> & {
		setIdShared: (id: number) => void
		setIdSharedContent: React.Dispatch<React.SetStateAction<number | undefined>>
	}
> = ({
	data,
	page,
	setPage,
	isLoading,
	onItemsShowingChange,
	dataSelected,
	itemsShowing,
	setOpen,
	setIdShared,
	setOpenModalDecline,
	setIdSharedContent,
	setDataSelected,
	className
}) => {
	const handleRowSelect = useCallback<
		(rows: Row<PartnerTableItem>[], selected: boolean) => void
	>((rows, selected) => {
		const ids = rows.map(row => row.original.partner_organization_content_id)
		if (selected) {
			setDataSelected?.(prev => [...prev, ...ids])
		} else {
			setDataSelected?.(prev =>
				prev.filter(partnerId => !ids.includes(partnerId))
			)
		}
	}, [])

	moment.updateLocale('es', {
		invalidDate: 'N/A'
	})

	const renderCellAction = (row: Row<PartnerTableItem>): JSX.Element => {
		return (
			<div className="flex gap-2">
				<Button
					label="Accept and configure"
					className="!w-max !min-w-[unset] !px-3 py-3 "
					color="Primary"
					leftIconDivClassName="w-[18px] h-[18px]"
					onClick={() => {
						setOpen?.(true)
						setIdShared(row.original.partner_organization_content_id)
					}}
				/>

				<Button
					label="Decline"
					className="!w-max !min-w-[unset] !px-3 py-3 "
					color="Primary"
					onClick={() => {
						setOpenModalDecline?.(true)
						setIdSharedContent(row.original.partner_organization_content_id)
					}}
				/>
			</div>
		)
	}

	const columns = useMemo(
		(): Column<PartnerTableItem>[] => [
			{ Header: 'Title', accessor: 'title' },
			{
				Header: 'Type',
				accessor: 'type',
				Cell: ({ value }) => renderCellCapitalize(value, { replace: true })
			},
			{
				Header: 'Schedule',
				accessor: 'release_date',
				Cell: ({ value }) => renderCellSimpleValue(value)
			},
			{ Header: 'Organization', accessor: 'organization' },
			{
				Header: 'Action',
				accessor: 'partner_organization_content_id',
				disableSortBy: true,
				Cell: ({ row }) => renderCellAction(row)
			}
		],
		[]
	)

	useEffect(() => {
		if (data && data.items.length === 0 && page !== 1) {
			setPage(page - 1)
		}
	}, [data])

	return (
		<div className={clsx(className, 'flex flex-col w-full overflow-x-auto')}>
			<Table<PartnerTableItem>
				data={data?.items ?? []}
				page={page}
				isFetching={isLoading}
				onPageChange={setPage}
				columns={columns}
				noDataMessage="You have no content pending approval."
				onRowSelect={handleRowSelect}
				bodyTableClassName="!h-[calc(100vh_-_28rem)] w-full"
				customWidth={[50, 50, 50, 60, 60]}
				justify="left"
				showPagination
				pages={data?.size}
				total={data?.total}
				rowSelected={row =>
					dataSelected?.includes(
						row.original.partner_organization_content_id
					) ?? false
				}
				itemsShowing={itemsShowing}
				onItemsShowingChange={onItemsShowingChange}
				hideSelectionHeader
			/>
		</div>
	)
}
