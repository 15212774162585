import { Icon } from 'components/icon'
import { Typography } from 'components/typography'
import useVideoId from 'hooks/useVideoId'
import { Icons, TypographyTypes } from 'interfaces'
import React from 'react'
import { regexVimeoOrYoutube } from 'utils'

interface VideoPlayerProps {
	videoUrl: string | undefined
	setSlideImages: React.Dispatch<React.SetStateAction<boolean>>
	setOpenSlide: React.Dispatch<React.SetStateAction<boolean>>
	type?: 'content'
}

const VideoPlayer = ({
	videoUrl,
	setOpenSlide,
	setSlideImages,
	type
}: VideoPlayerProps): JSX.Element => {
	const videoIdUrl = useVideoId(videoUrl)

	const obj = {
		rutaYoutube: {
			url: 'https://www.youtube.com/embed/'
		},
		rutaVimeo: {
			url: 'https://player.vimeo.com/video/'
		}
	}

	return (
		<div>
			{videoUrl && regexVimeoOrYoutube.test(videoUrl) && (
				<>
					<iframe
						src={`${
							videoUrl.includes('vimeo.com')
								? obj.rutaVimeo.url
								: obj.rutaYoutube.url
						}${videoIdUrl}`}
						title="Vimeo video Player"
						allowFullScreen
						className="rounded-lg w-full max-h-[528px] min-h-[528px]"
					/>
					{type !== 'content' && (
						<button
							type="button"
							onClick={() => {
								setOpenSlide(true)
								setSlideImages(true)
							}}
							className="flex items-center justify-center py-3 bg-white shadow-boxPreview rounded w-full mt-2"
						>
							<div>
								<Typography
									fontLeading="15/18"
									type={TypographyTypes.p}
									title="Click here to add an"
									className="!font-normal text-lavender"
								/>
								<Typography
									fontLeading="15/22"
									type={TypographyTypes.p}
									title="Image."
									className="!font-semibold text-lavender"
								/>
							</div>
							<Icon src={Icons.uploadCloud} className="w-[26px] pl-2 " />
						</button>
					)}
				</>
			)}
		</div>
	)
}

export default VideoPlayer

VideoPlayer.defaultProps = {
	type: undefined
}
