import clsx from 'clsx'
import { Icon } from 'components/icon'
import { ColorPickerProps, Icons } from 'interfaces'
import React, { useRef, useState } from 'react'

export const ColorButton: React.FC<{
	color: string
	selected: boolean
	onClick: () => void
}> = ({ color, selected, onClick }) => (
	// eslint-disable-next-line jsx-a11y/control-has-associated-label
	<button
		type="button"
		onClick={onClick}
		className={clsx(
			'w-7 h-7 rounded-full cursor-pointer flex items-center justify-center border-[1px] border-gray-3 overflow-hidden',
			selected && 'p-[2px]'
		)}
	>
		<div
			className={clsx(
				'w-full h-full rounded-full',
				selected && 'border-[1px] border-gray-3'
			)}
			style={{ backgroundColor: color }}
		/>
	</button>
)

export const ColorPicker: React.FC<ColorPickerProps> = props => {
	const { colors, selected, allowCustomColor, onChange } = props
	const colorPicker = useRef<HTMLInputElement>(null)
	const [customColor, setCustomColor] = useState<string | null>(
		selected && !colors.includes(selected) ? selected : null
	)

	return (
		<div className="flex gap-4">
			{colors.map(color => (
				<ColorButton
					key={color}
					color={color}
					selected={color === selected}
					onClick={() => onChange?.(color)}
				/>
			))}
			{allowCustomColor && customColor && (
				<ColorButton
					color={customColor}
					selected={customColor === selected}
					onClick={() => onChange?.(customColor)}
				/>
			)}

			{allowCustomColor && (
				<button
					type="button"
					className="w-8 h-8 rounded-full bg-transparent-default p-[6px]"
					onClick={() => colorPicker.current?.click()}
				>
					<input
						type="color"
						className="absolute invisible"
						ref={colorPicker}
						onChange={e => {
							setCustomColor(e.target.value)
							onChange?.(e.target.value)
						}}
					/>
					<Icon className="py-[2px]" src={Icons.colorize} />
				</button>
			)}
		</div>
	)
}
