import { Button, Icon, Input, Typography, handleValid } from 'components'
import { GroupComponentsLinkProps, Icons, TypographyTypes } from 'interfaces'
import React, { useEffect } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { configGenaral } from 'utils'
import {
	PropsComponentsMyOrganization,
	onChangeFieldProps
} from './interfaceSlide.type'

const GroupComponents: React.FC<
	GroupComponentsLinkProps & onChangeFieldProps
> = ({
	register,
	index,
	deleteComponent,
	watch,
	setValue,
	onChangeField,
	errorCustom
}): JSX.Element => {
	const rules = {
		title: {
			required: { value: true, message: 'This field is required' }
		}
	}

	const watchTitle = watch?.(`tags.${index}.title`)

	return (
		<div className="flex flex-col w-full gap-6 mb-6">
			<Input
				name={`tags.${index}.title`}
				title="Tag"
				register={register}
				rules={rules.title}
				error={errorCustom?.title}
				borderFull
				onChangeCustom={e => {
					const { value } = e.target
					onChangeField({
						title: value
					})
				}}
				limit
				cantLimit={configGenaral.maxTagsLength}
				watchValue={watchTitle}
				setValueInput={setValue}
			/>
			{index > 0 && (
				<div
					aria-hidden="true"
					className=" flex gap-2 cursor-pointer"
					onClick={() => deleteComponent(index)}
				>
					<Icon
						src={Icons.delete}
						className="w-[16px] h-[18px] text-gray-3"
						fillPath
					/>
					<Typography
						title="Remove Link"
						className="text-gray-3"
						fontLeading="15/18"
						type={TypographyTypes.p}
					/>
				</div>
			)}
		</div>
	)
}

export const SlideTags: React.FC<PropsComponentsMyOrganization> = ({
	setValue: setValueForm,
	setErrorForm,
	active,
	pos,
	watch: watchParent,
	setFieldError
}): JSX.Element => {
	const data = watchParent().tabs?.[active]?.components?.[pos].component_value
	const responseArray = data.tags?.map(element => {
		return {
			title: element
		}
	})
	const {
		register,
		control,
		setValue,
		watch,
		formState: { errors, isValid }
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			tags: responseArray ?? [
				{
					title: ''
				}
			]
		}
	})

	const { fields, remove, append } = useFieldArray({
		control,
		name: 'tags'
	})

	const nameType = `tags${active}-${pos}`

	useEffect(() => {
		handleValid(nameType, isValid, setFieldError)
		setErrorForm?.(!isValid)
	}, [isValid])

	return (
		<div className="flex flex-col justify-between overflow-hidden p-2">
			<div className="flex flex-col w-full overflow-y-auto max-h-[calc(100vh-130px)] pb-6">
				<div className="mr-2">
					{fields.map((component, index) => {
						return (
							<Controller
								key={`tags-${component.id}`}
								control={control}
								name={`tags.${index}`}
								render={({ field }) => {
									return (
										<GroupComponents
											register={register}
											index={index}
											errors={errors}
											deleteComponent={remove}
											watch={watch}
											setValue={setValue}
											onChangeField={response => {
												field.onChange({
													...response
												})
												const result = watch().tags.map(elemet => elemet.title)
												setValueForm(
													`tabs.${active}.components.${pos}.component_value.tags`,
													result
												)
											}}
											errorCustom={errors?.tags?.[index]}
										/>
									)
								}}
							/>
						)
					})}
				</div>
				<div className="w-full mt-6">
					<Button
						type="button"
						label="Add"
						small
						onClick={() => append({ title: '' })}
						iconLeft={Icons.plus}
					/>
				</div>
			</div>
		</div>
	)
}
