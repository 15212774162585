import {
	CheckCircleIcon,
	ExclamationCircleIcon,
	XCircleIcon,
	XIcon
} from '@heroicons/react/outline'
import clsx from 'clsx'
import { UseToastType } from 'interfaces'
import React from 'react'
import toast from 'react-hot-toast'

export const useToast = (): UseToastType => {
	const showToast = (
		title: string,
		subtitle: string,
		type: 'success' | 'error' | 'warning'
	): void => {
		toast.custom(t => (
			<div
				className={clsx(
					'max-w-[390px] w-full bg-white shadow-lg rounded-lg pointer-events-auto flex z-50',
					'absolute left-1/2 -translate-x-1/2'
				)}
				id={`toast${title}`}
			>
				<div className="flex-1 w-0 p-4">
					<div className="flex items-start">
						<div className="flex-shrink-0">
							{type === 'success' && (
								<CheckCircleIcon
									className="h-6 w-6 text-blue-primary"
									aria-hidden="true"
								/>
							)}
							{type === 'error' && (
								<XCircleIcon
									className="h-6 w-6 text-red-primary"
									aria-hidden="true"
								/>
							)}
							{type === 'warning' && (
								<ExclamationCircleIcon
									className="h-6 w-6 text-yellow-400"
									aria-hidden="true"
								/>
							)}
						</div>
						<div className="ml-3 w-0 flex-1 pt-0.5">
							<p className="text-sm font-semibold font-Poppins text-white-100">
								{title}
							</p>
							<p className="mt-1 text-sm font-normal font-Poppins text-white-100">
								{subtitle}
							</p>
						</div>
						<div className="ml-4 flex-shrink-0 flex">
							<button
								className="bg-white-100 rounded-md inline-flex text-gray-400 hover:text-gray-500"
								type="button"
								onClick={() => {
									toast.remove(t.id)
								}}
							>
								<span className="sr-only">Close</span>
								<XIcon
									className="h-5 w-5 bg-primary-500 text-white-100"
									aria-hidden="true"
								/>
							</button>
						</div>
					</div>
				</div>
			</div>
		))
	}
	return { showToast }
}
