import { Box } from 'components/boxs'
import { DropdownFull } from 'components/dropdown'
import { Icon } from 'components/icon'
import { Input } from 'components/input'
import { ModalConfirm } from 'components/modal'
import { TypeFeed } from 'components/typeFeed'
import { Typography } from 'components/typography'
import { useToast } from 'hooks'
import {
	DropdownFullItemProps,
	Icons,
	OrganizationInformationForm,
	OrganizationInformationFormEditSettings,
	TypographyTypes,
	ViewOrganization
} from 'interfaces'
import { allSettingsTimeTypesData } from 'lib'
import React, { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { regexUrl } from 'utils'

export const Settings: React.FC<OrganizationInformationFormEditSettings> = ({
	settings,
	editing,
	views
}) => {
	const { register, setValue, formState, control, watch } =
		useFormContext<OrganizationInformationForm>()

	const valueSsoLin = watch('settings.sso_link')
	const data = watch('views')
	const { showToast } = useToast()

	const { errors } = formState
	const [time, setTime] = useState<DropdownFullItemProps>()
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [dataView, setDataView] = useState<ViewOrganization[] | null>(null)

	useEffect(() => {
		setDataView(views)
	}, [views])

	const rules = {
		sso_link: {
			pattern: {
				value: regexUrl,
				message: 'Invalid link'
			}
		},
		data_refresh_frecuency_sso: {
			required: {
				value: valueSsoLin?.length > 1,
				message: 'Please select an option'
			}
		},
		email_support_sso: {
			maxLength: { value: 150, message: 'Maximum characters exceeded' }
		}
	}

	useEffect(() => {
		if (editing) {
			setValue('settings', settings)
			setValue('views', views)
			const response = allSettingsTimeTypesData.find(
				e => e.value === settings?.data_refresh_frecuency_sso?.toString()
			)
			if (response) setTime(response)
		}
	}, [editing, settings])

	useEffect(() => {
		if (time !== undefined) {
			setValue('settings.data_refresh_frecuency_sso', Number(time?.value), {
				shouldValidate: true
			})
		}
	}, [time])

	const handleDelete = (): void => {
		setValue('settings.sso_link', '')
		setValue('settings.data_refresh_frecuency_sso', undefined)
		setTime(undefined)
		setIsModalOpen(false)
	}

	const validateActiveItems = (element: ViewOrganization[]): boolean => {
		const activeItems = element.filter(item => item.enabled)

		if (activeItems.length === 0) {
			showToast(
				'All views disabled',
				`You need to enable at least one, please set the most valuable view to your customers`,
				'error'
			)
			return false
		}

		const orders = new Set()
		const hasDuplicates = activeItems.some(item => {
			if (orders.has(item.order)) {
				showToast(
					'Repeated Order',
					`Set the correct order for the views that are enabled, can’t repeat numbers!.`,
					'error'
				)
				return true
			}
			orders.add(item.order)

			return false
		})

		return !hasDuplicates
	}

	return (
		<>
			<Box className="border-none min-h-[530px] mb-6">
				<>
					<div className="flex flex-row items-center pb-4 gap-2">
						<Icon src={Icons.config} className="w-5 h-5 text-blue-primary" />
						<Typography
							title="Manage App Views:"
							fontLeading="15/18"
							type={TypographyTypes.h3}
							className="text-black-1"
						/>
					</div>

					<div className="grid grid-cols-2 md:grid-cols-3 gap-4">
						{dataView?.map((element, index) => (
							<Controller
								control={control}
								name={`views.${index as number}`}
								rules={{
									validate: () => {
										return validateActiveItems(data)
									}
								}}
								render={({ field: { onChange } }) => (
									<div className="flex flex-col min-w-[32%]">
										<TypeFeed
											element={element}
											key={`${element.name}_${element.id}`}
											handleChange={value => {
												onChange(value)
												setDataView(data)
											}}
										/>
									</div>
								)}
							/>
						))}
					</div>
				</>
			</Box>
			<Box className="border-none min-h-[350px]">
				<div className="grid grid-cols-2 gap-8">
					<div className="flex flex-col">
						<div className="flex flex-row items-center pb-4 gap-2">
							<Icon src={Icons.config} className="w-5 h-5 text-blue-primary" />
							<Typography
								title="SSO connection:"
								fontLeading="15/18"
								type={TypographyTypes.h3}
								className="text-black-1"
							/>
						</div>
						<div className="p-4  border-blue-primary rounded-2xl border-2 gap-2">
							<Input
								title="Organization SSO link:"
								placeholder="Paste LINK here"
								name="settings.sso_link"
								onChange={e => {
									setValue('settings.sso_link', e.target.value)
								}}
								type="text"
								register={register}
								rules={rules.sso_link}
								error={errors.settings?.sso_link}
								rightIcon={Icons.delete}
								fillpat
								rightClick={() => {
									setIsModalOpen(true)
								}}
							/>

							<div className="pt-8">
								<Typography
									title="Data refresh frecuency"
									fontLeading="12/12"
									type={TypographyTypes.h3}
									className="text-black-1 pb-4"
								/>
								<Controller
									name="settings.data_refresh_frecuency_sso"
									control={control}
									rules={rules.data_refresh_frecuency_sso}
									render={() => (
										<DropdownFull
											placeholder="Set timeframe"
											item={time as DropdownFullItemProps}
											list={allSettingsTimeTypesData}
											setItem={setTime}
											icon={Icons.arrowDownFull}
											className="min-w-[180px]"
											iconClass="w-[10px] text-blue-primary"
											error={errors.settings?.data_refresh_frecuency_sso}
										/>
									)}
								/>
							</div>
						</div>
					</div>
					<div className="flex flex-col">
						<div className="flex flex-row items-center pb-4 gap-2">
							<Typography
								title="Support email:"
								fontLeading="15/18"
								type={TypographyTypes.h3}
								className="text-black-1"
							/>
						</div>
						<div className="p-4  border-blue-primary rounded-2xl border-2">
							<Input
								title="Set email:"
								placeholder="Default is support@patter.com"
								type="email"
								name="settings.email_support_sso"
								register={register}
								rules={rules.email_support_sso}
							/>
							<Typography
								title="Please note that by default email support@patter.com will be used for support. We recommend editing the email if you have a different one."
								fontLeading="12/12"
								type={TypographyTypes.h3}
								className="text-gray-3 pt-4"
							/>
						</div>
					</div>
					<ModalConfirm
						open={isModalOpen}
						setOpen={setIsModalOpen}
						onClickConfirm={() => {
							handleDelete()
						}}
						icon={Icons.warning}
						descripcion="Are you sure you want to delete the LINK"
						buttonLabel="Confirm"
					/>
				</div>
			</Box>
		</>
	)
}
