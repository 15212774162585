import clsx from 'clsx'
import { TabsProps } from 'interfaces'
import React, { FC } from 'react'

export const Tabs: FC<TabsProps> = props => {
	const { onChange, options, value: selectedTab, className } = props
	return (
		<div
			className={clsx(
				'flex justify-center px-16 pt-2 shadow-md gap-16 w-fit bg-white rounded-lg',
				className
			)}
		>
			{options.map(option => (
				<button
					key={option.value}
					type="button"
					className={clsx(
						'bg-transparent-default text-center flex flex-col',
						selectedTab === option.value && 'border-b-primary'
					)}
					onClick={() => onChange?.(option.value)}
				>
					<span
						className={clsx(
							'px-1 mb-1 !leading-none',
							selectedTab === option.value && 'text-blue-primary'
						)}
					>
						{option.label}
					</span>
					<div
						className={clsx(
							'rounded-lg bg-blue-primary h-1 w-full',
							selectedTab !== option.value && 'invisible'
						)}
					/>
				</button>
			))}
		</div>
	)
}
