import clsx from 'clsx'

export const getColorPrimary = (
	isDisable: boolean,
	colorProps: string
): string => {
	return clsx(
		!isDisable &&
			colorProps === 'Primary' &&
			'bg-blue-primary text-white border-2 border-blue-primary',
		isDisable &&
			colorProps === 'Primary' &&
			'bg-gray-3 text-white border-2 border-gray-3'
	)
}
export const getColorBlackScale = (
	isDisable: boolean,
	colorProps: string
): string => {
	return clsx(
		!isDisable &&
			colorProps === 'Bg-gray-2' &&
			'bg-gray-2 text-[#5A5A5A] border-2 border-gray-2',
		isDisable &&
			colorProps === 'Bg-gray-2' &&
			'bg-gray-2 text-[#5A5A5A] border-2 border-gray-2',
		!isDisable &&
			colorProps === 'Bg-gray' &&
			'bg-gray-2 text-white border-2 border-gray-2',
		isDisable &&
			colorProps === 'Bg-gray' &&
			'bg-gray-2 text-white border-2 border-gray-2',
		isDisable &&
			colorProps === 'text-black' &&
			'bg-transparent-default text-transparent-black border-2 border-gray-3',
		!isDisable &&
			colorProps === 'text-black' &&
			'bg-transparent-default text-transparent-black border-2 border-blue-primary'
	)
}
export const getColorBlackGray = (
	isDisable: boolean,
	colorProps: string
): string => {
	return clsx(
		colorProps === 'Gray' &&
			'bg-transparent-default text-gray-8 border-2 border-gray-8',
		colorProps === 'Gray2' &&
			'bg-transparent-default text-gray-1 border-2 border-gray-1',
		isDisable &&
			colorProps === 'Gray3' &&
			'bg-transparent-default text-gray-3 border-2 border-gray-3',
		!isDisable &&
			colorProps === 'Gray3' &&
			'bg-transparent-default text-lavender border-2 border-lavender',
		colorProps === 'Gray4' && 'bg-gray-9 text-white border-2 border-gray-9',
		colorProps === 'Gray5' &&
			'bg-transparent-default text-gray-18 border-2 border-gray-18'
	)
}
export const getColor = (isDisable: boolean, colorProps: string): string => {
	return clsx(
		getColorPrimary(isDisable, colorProps),
		getColorBlackScale(isDisable, colorProps),
		isDisable &&
			colorProps === 'Transparent' &&
			'bg-transparent-default text-gray-3 border-2 border-gray-3',
		!isDisable &&
			colorProps === 'Transparent' &&
			'bg-transparent-default text-blue-primary border-2 border-blue-primary',
		getColorBlackGray(isDisable, colorProps),
		colorProps === 'Lavender' && 'bg-lavender text-white',
		colorProps === 'LavenderLight' &&
			'bg-gray-11 text-gray-9 border-gray-11 border-2',
		!isDisable &&
			colorProps === 'DangerTransparent' &&
			'bg-transparent-default text-red-primary border-2 border-red-primary',
		isDisable &&
			colorProps === 'DangerTransparent' &&
			'bg-transparent-default text-gray-3 border-2 border-gray-3',
		colorProps === 'PurpleTransparent' &&
			'bg-transparent-default text-purple border-2 border-purple',
		!isDisable &&
			colorProps === 'white' &&
			'bg-white text-blue-primary border-2 border-blue-primary',
		isDisable &&
			colorProps === 'white' &&
			'bg-gray-3 text-white border-2 border-gray-3'
	)
}
export const getColorTextIcon = (
	isDisable: boolean,
	colorProps: string
): string => {
	return clsx(
		colorProps === 'Primary' && 'text-white',
		isDisable && colorProps === 'Transparent' && 'text-gray-3',
		!isDisable && colorProps === 'Transparent' && 'text-blue-primary',
		colorProps === 'Gray' && 'text-gray-8',
		colorProps === 'Gray2' && 'text-gray-1',
		colorProps === 'Gray3' && 'text-gray-3'
	)
}
export const getColorTextIconRight = (
	isDisable: boolean,
	colorProps: string
): string => {
	return clsx(
		getColorTextIcon(isDisable, colorProps),
		(colorProps === 'Lavender' || colorProps === 'Gray4') && 'text-white',
		colorProps === 'Gray5' && 'text-gray-18',
		colorProps === 'LavenderLight' && 'text-lavender',
		colorProps === 'DangerTransparent' && 'text-red-primary',
		colorProps === 'PurpleTransparent' && 'text-purple'
	)
}
export const getColorTextIconLeft = (
	isDisable: boolean,
	colorProps: string
): string => {
	return clsx(
		getColorTextIcon(isDisable, colorProps),
		(colorProps === 'Lavender' || colorProps === 'Gray4') && 'text-white',
		colorProps === 'LavenderLight' && 'text-lavender',
		colorProps === 'DangerTransparent' && 'text-red-primary',
		colorProps === 'PurpleTransparent' && 'text-purple'
	)
}
