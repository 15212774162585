/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * TODO:
 * Schedule appointment for review.
 */

import { debounce, DebouncedFunc } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'

export const useSearchDebounce = (
	watchSearch: string
): {
	inputSearch: string
	handleChangeDebounce: DebouncedFunc<any>
	resetInput: () => void
} => {
	const [inputSearch, setInputSearch] = useState('')

	useEffect(() => {
		setInputSearch(watchSearch ?? '')
	}, [watchSearch])

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setInputSearch(event?.target.value.trim())
	}

	const resetInput = (): void => {
		setInputSearch('')
	}

	const handleChangeDebounce = useCallback(debounce(handleChange, 1000), [])

	return { inputSearch, handleChangeDebounce, resetInput }
}
