import { CircularProgress } from '@mui/material'
import {
	GET_PEOPLE_TAGS_ORG_EXCLUDE,
	addNewTag,
	getPeopleTagsExclude
} from 'api'
import clsx from 'clsx'
import {
	Button,
	Icon,
	Input,
	MyPeopleParent,
	TagsDeleteConfirmationModal,
	TailwindModal,
	Typography
} from 'components'
import { useToast } from 'hooks'
import {
	DeleteTagsRequest,
	FollowerTagParent,
	GenericModalProps,
	Icons,
	PeopleMyTagsChildrenItem,
	PeopleMyTagsItemProps,
	PeopleMyTagsProps,
	PeopleTagsOrgRequest,
	TypographyTypes
} from 'interfaces'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'

export const DeleteTagsModal: React.FC<
	GenericModalProps<PeopleMyTagsProps>
> = ({
	open,
	setOpen,
	data,
	selected,
	setAnchorEl,
	isChildren,
	deleteTagsParents,
	deleteTagsChildrens
}) => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const [step, setStep] = useState('first')
	const dataFiltered = useMemo(() => {
		let dataReturned: (
			| PeopleMyTagsChildrenItem
			| PeopleMyTagsItemProps
			| undefined
		)[] = []
		if (data && selected) {
			if (isChildren) {
				dataReturned = data.items.flatMap(item =>
					item.subRows?.filter(subtag =>
						selected.includes(subtag.organization_tag_id)
					)
				)
			} else {
				dataReturned = data.items.filter(
					item =>
						selected.includes(item.organization_tag_parent_id) &&
						(item?.subRows?.length === 0 || item?.subRows?.length !== 0)
				)
			}
		}
		return dataReturned
	}, [])

	const [newTagValue, setNewTagValue] = useState('')
	const [isLoadingRequest, setIsLoadingRequest] = useState(false)
	const [tagsDeleteModal, setTagsDeleteModal] = useState(false)
	const [myTagsDataSelected, setMyTagsDataSelected] = useState<number[]>([])
	const [selectAll, setSelectAll] = useState(false)
	const { showToast } = useToast()
	const {
		data: orgData,
		isLoading: orgIsLoading,
		refetch: refetchOrgData
	} = useQuery(
		[
			GET_PEOPLE_TAGS_ORG_EXCLUDE,
			selected?.[0] as number,
			userId,
			organizationId
		],
		() =>
			getPeopleTagsExclude(organizationId, selected?.[0] as number, 'delete')
	)
	const validateHeigth =
		orgData &&
		orgData?.organization_follower_independent_tags?.length !== 0 &&
		orgData?.organization_follower_parent_tags?.length !== 0

	const deleteTagsHandler = useCallback(
		async (body: DeleteTagsRequest): Promise<void> => {
			if (deleteTagsChildrens && isChildren) {
				showToast('Success', 'Tag delete successfully', 'success')
				await deleteTagsChildrens(body)
			} else if (deleteTagsParents) {
				showToast('Success', 'Tag children delete successfully', 'success')
				await deleteTagsParents(body)
			}
		},
		[]
	)

	const addNewTagHandler = useCallback(
		async (body: PeopleTagsOrgRequest): Promise<void> => {
			setIsLoadingRequest(true)
			await addNewTag(organizationId, body)
				.then(response => {
					if (response) {
						showToast('Success', 'Tag created successfully', 'success')
						setNewTagValue('')
						refetchOrgData()
					}
				})
				.finally(() => {
					setIsLoadingRequest(false)
				})
		},
		[]
	)

	const handleSelectAll = (): void => {
		if (orgData) {
			const aux: number[] = []

			orgData.organization_follower_parent_tags?.forEach(tagParent => {
				tagParent.tags_child.forEach(tagChild => {
					if (tagChild?.id) {
						aux.push(tagChild.id)
					}
				})
			})

			orgData.organization_follower_independent_tags?.forEach(
				tagIndependent => {
					if (tagIndependent?.id) {
						aux.push(tagIndependent.id)
					}
				}
			)

			setSelectAll(true)
			setMyTagsDataSelected(aux)
		}
	}

	const handleSelected = (itemID: number): void => {
		const newDataSelected = myTagsDataSelected.slice()
		const dataIndex = myTagsDataSelected.indexOf(itemID)
		if (dataIndex === -1) {
			newDataSelected.push(itemID)
		} else {
			newDataSelected.splice(dataIndex, 1)
		}
		setMyTagsDataSelected(newDataSelected)
	}

	useEffect(() => {
		if (orgData?.organization_follower_independent_tags) {
			let aux = 0
			orgData.organization_follower_parent_tags.forEach(tagParent => {
				tagParent.tags_child.forEach(() => {
					aux += 1
				})
			})
			const total = aux + orgData.organization_follower_independent_tags.length
			if (myTagsDataSelected.length === total) {
				setSelectAll(true)
			} else {
				setSelectAll(false)
			}
		}
	}, [myTagsDataSelected, orgData])

	const handleStepsModal = (): void => {
		if (orgData && orgData.users_to_assign.length > 0) {
			if (step === 'first') {
				setStep('second')
			} else {
				setTagsDeleteModal(true)
			}
		} else {
			setTagsDeleteModal(true)
		}
	}

	const isSteps: { first: string; second: string; [key: string]: string } = {
		first: 'Deleting a tag is permanent and cannot be undone',
		second:
			'Before deleting this tag, please select or create another tag to ensure proper user categorization.'
	}

	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			title="Are you sure you want to delete the following Tag(s)?"
			className={clsx(
				'h-[311px] w-[474px] p-4 rounded-[16px] overflow-y-auto',
				step === 'first' && 'sm:h-[222px]',
				step === 'second' && validateHeigth
					? 'h-[611px] min-h-[511px]'
					: 'h-auto min-h-[111px]'
			)}
		>
			<>
				{(orgIsLoading || isLoadingRequest) && (
					<div className="flex justify-center items-center w-full h-[calc(100%_-_26px)]">
						<CircularProgress disableShrink />
					</div>
				)}
				{!(orgIsLoading || isLoadingRequest) && (
					<div className="flex flex-col justify-between items-center w-full h-[calc(100%_-_52px)] mt-6">
						<div className="flex flex-col gap-2 sm:gap-6 w-full">
							{!isChildren && (
								<Typography
									title={(dataFiltered as PeopleMyTagsItemProps[])
										.map(item => item.name)
										.join(', ')}
									fontLeading="18/21"
									type={TypographyTypes.p}
									className="!font-normal text-gray-9 text-left"
								/>
							)}
							{isChildren && (
								<Typography
									title={(dataFiltered as PeopleMyTagsChildrenItem[])
										.map(item => item.name)
										.join(', ')}
									fontLeading="18/21"
									type={TypographyTypes.p}
									className="!font-normal text-gray-9 text-left"
								/>
							)}
							<Typography
								title={isSteps[step]}
								fontLeading="15/18"
								type={TypographyTypes.p}
								className="!font-normal text-gray-1 text-left"
							/>
							{step === 'second' && orgData && (
								<>
									<div className="w-full flex flex-col gap-2 mt-4">
										<div className="flex flex-col w-full border-blue-primary border-solid border-[1px] rounded-lg p-[16px] min-h-max">
											<div className="flex border-b-[0.5px] border-gray-3 pb-3">
												<Icon
													src={Icons.secondaryPhone}
													className="w-[40px] h-[40px]"
												/>
												<div className="flex flex-col pl-2">
													<Typography
														title="Select"
														fontLeading="15/18"
														type={TypographyTypes.p}
														className="text-blue-primary"
													/>
													<Typography
														title="User´s tags in the platform:"
														fontLeading="15/22"
														type={TypographyTypes.p}
													/>
												</div>
											</div>
											{orgData?.organization_follower_parent_tags &&
												orgData.organization_follower_parent_tags.length >
													0 && (
													<div className="border-b-[0.5px] border-gray-3 pb-3">
														{orgData.organization_follower_parent_tags
															.sort((a, b) =>
																a.name && b.name
																	? a.name.localeCompare(b.name)
																	: 0
															)
															.map(tagParent => {
																return (
																	<MyPeopleParent
																		key={`${tagParent.id}_${tagParent.name}`}
																		tagParent={
																			tagParent as unknown as FollowerTagParent
																		}
																		myTagsDataSelected={myTagsDataSelected}
																		handleSelected={id => {
																			handleSelected(id)
																		}}
																	/>
																)
															})}
													</div>
												)}
											<div className="mt-[7px] flex flex-col w-full hide-scroll-bar min-h-max gap-2">
												{orgData?.organization_follower_independent_tags
													?.slice()
													.sort((a, b) =>
														a.name && b.name ? a.name.localeCompare(b.name) : 0
													)
													.map(tagIndependent => {
														return (
															<div
																className="flex items-center justify-between gap-1 p-1 w-full "
																key={`${tagIndependent.id}_${tagIndependent.name}`}
															>
																<div className="flex  gap-2 items-center w-[50%]">
																	<input
																		type="checkbox"
																		name="roles[]"
																		className="h-4 w-4 border-gray-300 text-blue-primary focus:ring-transparent-default cursor-pointer"
																		checked={myTagsDataSelected.includes(
																			tagIndependent.id
																		)}
																		onChange={() => {
																			handleSelected(tagIndependent.id)
																		}}
																	/>
																	<Typography
																		className="text-black-1"
																		title={tagIndependent.name ?? ''}
																		fontLeading="15/18"
																		type={TypographyTypes.p}
																	/>
																	{!tagIndependent.is_visible && (
																		<Typography
																			className="text-gray-1"
																			title="(Hidden)"
																			fontLeading="10/12"
																			type={TypographyTypes.p}
																		/>
																	)}
																</div>
															</div>
														)
													})}
											</div>
										</div>
									</div>
									<div className="w-full flex flex-col justify-end gap-2  sticky bottom-[-16px] bg-white z-10 p-2">
										{!selectAll && (
											<div className="w-[100%] ">
												<Button
													label="Select All"
													color="Transparent"
													onClick={() => handleSelectAll()}
													className="w-full sm:!min-w-[unset]"
												/>
											</div>
										)}
									</div>
								</>
							)}
							{step === 'second' && (
								<div className="flex justify-between items-end mb-1">
									<Input
										name="tagName"
										title="Or create a new Tag"
										placeholder="Add Tag Name"
										className="!w-[337px] !p-[unset]"
										value={newTagValue}
										onChange={event => setNewTagValue(event.target.value)}
										inputClassName="!p-[unset] !pb-2"
										inputFocus
									/>
									<Button
										label="Add"
										className="!min-w-[unset] !py-1 !px-[14px] !bg-white"
										color="Transparent"
										iconLeft={Icons.add}
										disabled={newTagValue === ''}
										onClick={() =>
											addNewTagHandler({
												name: newTagValue,
												required_approval: false
											})
										}
									/>
								</div>
							)}
						</div>
						<div
							className={clsx(
								'w-full flex flex-col sm:flex-row gap-4 justify-between items-center pt-4 pb-3 '
							)}
						>
							<Button
								label="Cancel"
								color="Transparent"
								onClick={() => setOpen(false)}
								className="w-full sm:!min-w-[unset] sm:!w-[45.5%]"
							/>
							<Button
								label="Continue"
								onClick={handleStepsModal}
								className=" w-full sm:!min-w-[unset] sm:!w-[45.5%]"
								disabled={(() => {
									let bool = true
									if (step === 'first') {
										bool = false
									} else if (
										step === 'second' &&
										myTagsDataSelected.length !== 0
									) {
										bool = false
									}
									return bool
								})()}
							/>
							{tagsDeleteModal && (
								<TagsDeleteConfirmationModal
									open={tagsDeleteModal}
									setOpen={setTagsDeleteModal}
									onClickConfirm={() => {
										setTagsDeleteModal(false)
										setAnchorEl?.(null)
										deleteTagsHandler({
											tags_id: selected as number[],
											tag_to_replace: myTagsDataSelected
										})
									}}
								/>
							)}
						</div>
					</div>
				)}
			</>
		</TailwindModal>
	)
}
