import { Box } from 'components/boxs'
import { Message } from 'components/index'
import { InputTextArea } from 'components/input'
import { Typography } from 'components/typography'
import { propsPreviewModal, TypographyTypes } from 'interfaces'
import moment from 'moment'
import React from 'react'

const PartnerDetails: React.FC<propsPreviewModal> = ({
	data,
	accepte,
	setAccepte,
	textValue,
	setTextValue
}) => {
	const MESSAGE_MAX_LENGTH = 140

	return (
		<Box className="h-full">
			<>
				<div className="w-full">
					<Typography
						title="Partner Details:"
						className="text-gray-9  border-gray-11 pb-3"
						type={TypographyTypes.h2}
						fontLeading="22/27"
					/>
				</div>
				<div className="flex justify-between items-center mt-3">
					<Typography
						title="Creator"
						className="text-gray-9  border-gray-11 pb-3"
						type={TypographyTypes.h3}
						fontLeading="18/21"
					/>
					<Typography
						title="Followers"
						className="text-gray-9  border-gray-11 pb-3"
						type={TypographyTypes.h3}
						fontLeading="18/21"
					/>
				</div>
				<div className="flex justify-between items-center">
					<Typography
						type={TypographyTypes.p}
						fontLeading="15/18"
						className="!font-normal text-black-1"
						title={`${data?.organization}`}
					/>
					<Typography
						type={TypographyTypes.p}
						fontLeading="15/18"
						className="!font-normal text-black-1"
						title={`${data?.followers}`}
					/>
				</div>
				{data &&
					Object?.keys(
						data?.content_info?.delivery_enhancements?.push_notification
					).length !== 0 && (
						<div className="mt-9">
							<div className="w-full">
								<Typography
									title="Push Notification Suggested by Partner"
									className="text-gray-9  border-gray-11 pb-3"
									type={TypographyTypes.h2}
									fontLeading="22/27"
								/>
							</div>
							<div className="flex justify-between items-center mt-3">
								<Typography
									title="Push Notification Message"
									className="text-gray-9  border-gray-11 pb-3"
									type={TypographyTypes.h3}
									fontLeading="18/21"
								/>
								<Typography
									title="Send date"
									className="text-gray-9  border-gray-11 pb-3"
									type={TypographyTypes.h3}
									fontLeading="18/21"
								/>
								<Typography
									title="Accept"
									className="text-gray-9  border-gray-11 pb-3"
									type={TypographyTypes.h3}
									fontLeading="18/21"
								/>
							</div>
							<div className="flex justify-between items-center">
								<Typography
									type={TypographyTypes.p}
									fontLeading="15/18"
									className="!font-normal text-black-1"
									title={`${data?.content_info?.delivery_enhancements?.push_notification?.message}`}
								/>
								<Typography
									type={TypographyTypes.p}
									fontLeading="15/18"
									className="!font-normal text-black-1"
									title={` ${moment
										.utc(data?.last_edit)
										.local()
										.format('MM/DD/YYYY hh:mm A')}`}
								/>
								<input
									type="checkbox"
									className="h-4 w-4 rounded border-gray-1 text-blue-primary focus:ring-transparent-default cursor-pointer"
									checked={accepte}
									onChange={() => {
										if (setAccepte) setAccepte(!accepte)
									}}
								/>
							</div>
						</div>
					)}

				<div className="mt-10">
					<div className="w-full">
						<Typography
							title="Message to Partner (optional)"
							className="text-gray-9  border-gray-11 pb-3"
							type={TypographyTypes.h2}
							fontLeading="22/27"
						/>

						<div>
							<InputTextArea
								name="textArea"
								textValue={textValue}
								maxLength={MESSAGE_MAX_LENGTH + 1}
								textAreaClassName="!h-[70px] !resize-none"
								setTextValue={setTextValue}
								color="Gray3"
								placeholder="Write a message to your partner as thank you or feedback."
							/>
							{textValue && textValue.length > MESSAGE_MAX_LENGTH && (
								<Message
									type="error"
									text={`The message must have a maximum of ${MESSAGE_MAX_LENGTH} characters`}
								/>
							)}
						</div>
					</div>
				</div>
			</>
		</Box>
	)
}

export default PartnerDetails
