import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import { Typography } from 'components'
import { SecretTextProps, TypographyTypes } from 'interfaces'
import React, { useState } from 'react'

export const ToggleableSecretText = (props: SecretTextProps): JSX.Element => {
	const { value } = props
	const [visibleKey, setVisibleKey] = useState(false)
	return (
		<div className="border-2 border-blue-primary rounded-full flex justify-between pr-1 items-center pl-2.5 mt-4 h-10">
			<Typography
				title={visibleKey ? value ?? '' : '*'.repeat(value?.length ?? 10)}
				type={TypographyTypes.span}
				fontLeading="15/15"
				className={clsx('text-gray-9 !font-normal leading-[15px]', {
					'pt-1 !font-bold': !visibleKey
				})}
			/>
			<button
				type="button"
				className="px-3 py-2"
				onClick={() => setVisibleKey(prev => !prev)}
			>
				{visibleKey ? (
					<EyeOffIcon className="text-gray-18 cursor-pointer h-4 w-4" />
				) : (
					<EyeIcon className="text-gray-18 cursor-pointer h-4 w-4" />
				)}
			</button>
		</div>
	)
}
