import {
	AdminOrganizationStepFooter,
	Button,
	DeleteTagModal,
	Input,
	OrganizationTagForm,
	OrganizationTagsTable,
	TailwindModal,
	Title
} from 'components'
import {
	AdminOrganizationStepComponentProps,
	Icons,
	OrganizationTagType,
	TagForm
} from 'interfaces'
import React, { useState } from 'react'

export const Tags: React.FC<
	AdminOrganizationStepComponentProps<OrganizationTagType[]>
> = props => {
	const { onNext, onBack, stepper, data: initialTags } = props
	const [search, setSearch] = useState('')
	const [tags, setTags] = useState<OrganizationTagType[]>(initialTags || [])
	const [formOpen, setFormOpen] = useState<boolean>(false)
	const [tagEditing, setTagEditing] = useState<OrganizationTagType | null>(null)
	const [tagsToDelete, setTagsToDelete] = useState<string[]>([])

	const createTag = (data: TagForm): void => {
		setTags([
			...tags,
			{
				...data,
				visible: true,
				expanded: true,
				selected: false,
				requiredApproval: false
			}
		])
		setFormOpen(false)
	}

	const updateTag = (tagName: string, data: TagForm): void => {
		const { name, parentTag } = data
		setTags(prev =>
			prev.map(tag => {
				if (tag.parentTag === tagName) return { ...tag, parentTag: name }
				if (tag.name !== tagName) return tag
				return {
					...tag,
					name,
					parentTag,
					selected: tag.parentTag !== data.parentTag ? false : tag.selected
				}
			})
		)
		setFormOpen(false)
		setTagEditing(null)
	}

	const expandTag = (tagName: string, expanded: boolean): void => {
		setTags(prev =>
			prev.map(tag => (tag.name !== tagName ? tag : { ...tag, expanded }))
		)
	}

	const hideTag = (tagName: string, visible: boolean): void => {
		setTags(prev =>
			prev.map(tag => (tag.name === tagName ? { ...tag, visible } : tag))
		)
	}

	const childTags = (tagName: string): OrganizationTagType[] => {
		return tags.filter(tag => tag.parentTag === tagName)
	}

	const deleteTag = (tagName: string): void => {
		setTags(prev => {
			const childs = childTags(tagName).map(subTag => subTag.name)
			return prev.filter(
				tag => tag.name !== tagName && !childs.includes(tag.name)
			)
		})
	}

	const selectTag = (tagName: string, selected: boolean): void => {
		const subTags = childTags(tagName)
		const isParentTag = subTags.length > 0
		let updatedTags = tags
		if (isParentTag) {
			updatedTags = updatedTags.map(tag => {
				const isChildTag = Boolean(
					subTags.find(subTag => subTag.name === tag.name)
				)
				if (tag.name === tagName || isChildTag) return { ...tag, selected }
				return tag
			})
		} else {
			updatedTags = updatedTags.map(tag => {
				return tag.name === tagName ? { ...tag, selected } : tag
			})
		}
		setTags(updatedTags)
	}

	return (
		<>
			<div className="flex justify-between mb-5">
				<Title
					icon={Icons.groups}
					title="Tags"
					subtitle="Manage the Organization's tags"
				/>
				{stepper}
			</div>
			<div className="flex items-center gap-8 mb-6">
				<Input
					name="search"
					search
					className="w-full"
					value={search}
					placeholder="Search Tags"
					rightIcon={Icons.cancel}
					inputClassName="!p-[unset] !py-1 !px-2"
					inputFocus
					rightClick={() => setSearch('')}
					borderFull={false}
					onChange={e => setSearch(e.target.value)}
				/>
				<Button
					hidden={tags.filter(tag => tag.selected).length === 0}
					iconLeft={Icons.delete}
					onClick={() =>
						setTagsToDelete(
							tags.filter(tag => tag.selected).map(tag => tag.name)
						)
					}
					className="py-2"
					color="Gray2"
					label="Delete"
				/>
				<Button
					onClick={() => setFormOpen(!formOpen)}
					color="Primary"
					label="Add New Tag"
				/>
			</div>
			<OrganizationTagsTable
				tags={tags.filter(tag =>
					tag.name.toLowerCase().includes(search.toLowerCase().trim())
				)}
				onDelete={tag => deleteTag(tag.name)}
				onEdit={tag => {
					setTagEditing(tag)
					setFormOpen(true)
				}}
				onExpand={(tag, value) => expandTag(tag.name, value)}
				onHide={(tag, value) => hideTag(tag.name, value)}
				onRequiredApproval={({ name }, requiredApproval) => {
					setTags(prev =>
						prev.map(tag => {
							return tag.name === name ? { ...tag, requiredApproval } : tag
						})
					)
				}}
				onSelect={(tag, value) => selectTag(tag.name, value)}
			/>
			<AdminOrganizationStepFooter
				className="mt-4 mb-2"
				onBack={() => onBack?.(tags)}
				onNext={() => {
					if (tags.length > 0) onNext?.(tags)
				}}
				disabledNext={tags.length === 0}
			/>
			<TailwindModal
				className="rounded-lg p-4 min-w-[474px]"
				showCloseButton
				open={formOpen}
				setOpen={() => {
					setTagEditing(null)
					setFormOpen(false)
				}}
			>
				<OrganizationTagForm
					tag={tagEditing ?? undefined}
					disabledNestUnder={
						tagEditing !== null && childTags(tagEditing?.name).length > 0
					}
					parents={tags.filter(
						tag => !tag.parentTag && tag.name !== tagEditing?.name
					)}
					onCancel={() => {
						setFormOpen(false)
						setTagEditing(null)
					}}
					onSubmit={
						tagEditing ? data => updateTag(tagEditing.name, data) : createTag
					}
					createdTags={tags.map(tag => tag.name)}
				/>
			</TailwindModal>
			<DeleteTagModal
				tags={tagsToDelete}
				open={tagsToDelete.length > 0}
				setOpen={() => setTagsToDelete([])}
				onConfirm={() => {
					tagsToDelete.forEach(deleteTag)
					setTagsToDelete([])
				}}
			/>
		</>
	)
}
