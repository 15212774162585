/* eslint-disable @typescript-eslint/no-unused-vars */
import { CircularProgress } from '@mui/material'
import { GET_CONTENT_PERFORMANCE, getContents } from 'api'
import clsx from 'clsx'
import { AnimatedProgressBar, Box, Dropdown, Typography } from 'components'
import { BoxComponentProps, TypographyTypes } from 'interfaces'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'

export const ContentPerformance: React.FC<BoxComponentProps> = ({
	styles
}): JSX.Element => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')

	const names = [
		'Content Created',
		'Total Reach',
		'Saved Content',
		'Shared Content'
	]
	const [period, setPeriod] = useState('last_seven_days')

	const { data, isLoading } = useQuery(
		[GET_CONTENT_PERFORMANCE, period, userId, organizationId],
		() => getContents(organizationId, period)
	)

	return (
		<div className={clsx(styles)}>
			<Box className="w-full h-[274px]">
				<>
					<div className="flex items-center justify-between mb-4">
						<Typography
							title="Performance"
							type={TypographyTypes.h2}
							fontLeading="15/15"
						/>
						<Dropdown setPeriod={setPeriod} />
					</div>
					{isLoading ? (
						<div className="flex items-center justify-center w-full h-[200px] py-2 px-1 ">
							<CircularProgress disableShrink />
						</div>
					) : (
						data && (
							<div className="flex flex-col gap-2">
								<AnimatedProgressBar
									name={names[0]}
									value={data.content_created.new_value}
									maxValue={data.content_created.old_value}
								/>
								<AnimatedProgressBar
									name={names[1]}
									value={data.total_reach.new_value}
									maxValue={data.total_reach.old_value}
								/>
								<AnimatedProgressBar
									name={names[2]}
									value={data.saved_content.new_value}
									maxValue={data.saved_content.old_value}
								/>
								<AnimatedProgressBar
									name={names[3]}
									value={data.shared_content.new_value}
									maxValue={data.shared_content.old_value}
								/>
							</div>
						)
					)}
				</>
			</Box>
		</div>
	)
}
