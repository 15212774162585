import clsx from 'clsx'
import { Button } from 'components/buttons'
import { AdminOrganizationStepFooterProps } from 'interfaces'
import React from 'react'

export const AdminOrganizationStepFooter: React.FC<
	AdminOrganizationStepFooterProps
> = props => {
	const {
		onBack,
		onNext,
		onSave,
		onSaveDraft,
		save,
		className,
		saveDraft,
		disabledNext
	} = props

	return (
		<div className={clsx('flex w-full justify-between', className)}>
			<Button
				label="Back"
				color="Transparent"
				className="!bg-white !py-[5px] px-10"
				onClick={onBack}
			/>
			<div className="flex gap-6">
				{saveDraft && (
					<Button
						hidden
						className="!py-[5px] px-7"
						color="Gray2"
						onClick={onSaveDraft}
						label="Save Draft"
					/>
				)}
				<Button
					disabled={disabledNext}
					className="!py-[5px] px-12"
					color="Primary"
					label={save ? 'Save' : 'Next'}
					onClick={save ? onSave : onNext}
				/>
			</div>
		</div>
	)
}
