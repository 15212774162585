import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import { Button, Helper, Message, Typography } from 'components'
import { InputProps, TypographyTypes } from 'interfaces'
import React, { useEffect, useState } from 'react'

export const Input: React.FC<InputProps> = ({
	type,
	name,
	title,
	rules,
	error,
	showEye,
	leftIcon,
	register,
	rightIcon,
	className,
	inputClassName,
	titleClassName,
	inputFieldClassName,
	leftClick,
	rightClick,
	limit = false,
	cantLimit = 60,
	inputFocus = false,
	borderFull = true,
	search = false,
	watchValue,
	setValueInput,
	onChangeCustom,
	regexRestriction,
	disabled,
	helper,
	fillpat,
	emoji = false,
	...props
}): JSX.Element => {
	const [isVisible, setIsVisible] = useState(false)
	const [focus, setFocus] = useState(false)

	let rulesInput = rules
	if (type === 'email')
		rulesInput = {
			...rules,
			pattern: {
				value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
				message: 'Invalid email address'
			}
		}

	const handleShowPass = (): void => {
		setIsVisible(!isVisible)
	}

	const eyeIcon = isVisible ? (
		<EyeIcon className="text-gray-900 cursor-pointer" />
	) : (
		<EyeOffIcon className="text-gray-800 cursor-pointer" />
	)

	const registerInput = register?.(name, rulesInput)
	const aux = name
	const regex = /^[a-zA-Z0-9.,\s_'":@/]*$/

	const isVisibleType = isVisible ? 'text' : 'password'

	const handleSearch = (): void => {
		if (watchValue && setValueInput)
			if (regex.test(watchValue)) {
				setValueInput(aux, watchValue)
			} else {
				setValueInput(aux, watchValue.substring(0, watchValue.length - 1))
			}
	}

	const handleSearchWithLimit = (): void => {
		if (watchValue && setValueInput)
			if (regex.test(watchValue) && watchValue.length <= cantLimit) {
				setValueInput(aux, watchValue)
			} else {
				setValueInput(aux, watchValue.substring(0, watchValue.length - 1))
			}
	}

	const hasValid = (isValid: boolean, value: string): string => {
		return isValid ? value : value.substring(0, value.length - 1)
	}

	useEffect(() => {
		if (watchValue && setValueInput) {
			if (watchValue.length > cantLimit) {
				const cadena = watchValue.substring(0, watchValue.length - 1)
				setValueInput(aux, cadena)
			}

			if (search) {
				handleSearch()
			}
			if (search && cantLimit) {
				handleSearchWithLimit()
			}

			if (regexRestriction) {
				const isValid = regexRestriction.test(watchValue)
				const newValue = hasValid(isValid, watchValue)
				setValueInput(aux, newValue)
			}
			if (regexRestriction && cantLimit) {
				const isValid =
					regexRestriction.test(watchValue) && watchValue.length <= cantLimit
				const newValue = hasValid(isValid, watchValue)
				setValueInput(aux, newValue)
			}
		}
	}, [watchValue])

	return (
		<div
			className={clsx(className, 'flex flex-col w-full bg-transparent-default')}
		>
			{title && !helper && (
				<Typography
					htmlFor={name}
					title={title}
					type={TypographyTypes.label}
					className={clsx(titleClassName, 'text-black-1 mb-4')}
					fontLeading="12/12"
				/>
			)}

			{title && helper && (
				<div className="flex items-center justify-between mb-2">
					<div>
						<Typography
							htmlFor={name}
							title={title}
							type={TypographyTypes.label}
							className={clsx(titleClassName, 'text-black-1 mb-4')}
							fontLeading="12/12"
						/>
					</div>
					<div>
						<Helper
							text={helper}
							className="relative border-none"
							filpath={fillpat}
						/>
					</div>
				</div>
			)}
			<div
				className={clsx(
					'relative flex flex-row-reverse justify-between  border-gray-3 p-2 w-full',
					borderFull ? 'border-b-[0.5px]' : 'border-2',
					search && 'focus-within:border-blue-primary rounded-sm',
					inputClassName
				)}
			>
				<div className={clsx('flex items-center flex-row-reverse w-full')}>
					{showEye && (
						<button
							type="button"
							className={clsx('absolute right-4 top-[35%] w-4 h-4 ')}
							onClick={handleShowPass}
							id="showEye"
							data-testid="eyeIcon"
						>
							{eyeIcon}
						</button>
					)}
					{rightIcon && !showEye && (
						<Button
							iconLeft={rightIcon}
							className="!w-max !min-w-[unset] !p-0 !border-none"
							color={focus ? 'Transparent' : 'Gray3'}
							leftIconDivClassName="!w-6 !h-6"
							onClick={rightClick}
						/>
					)}

					<input
						{...registerInput}
						id={name}
						type={!showEye ? type : isVisibleType}
						className={clsx(
							' w-full text-[15px] leading-[22px] placeholder-gray-3 text-gray-9 font-semibold appearance-none outline-none bg-transparent-default border-0 focus:ring-0',
							emoji ? 'font-NotoColorEmoji' : 'font-Inter',
							inputFieldClassName
						)}
						disabled={disabled}
						data-testid={name}
						onFocus={inputFocus ? () => setFocus(true) : props.onFocus}
						onBlur={inputFocus ? () => setFocus(false) : props.onBlur}
						onChange={e => {
							const { value } = e.target

							if (type === 'number' && parseFloat(value) < 0) {
								e.target.value = '0'
							}

							// SE COMENTA MIENTRAS YA QUE ESTA LIMITANDO EL USO DE CARACTERES ESPECIALES COMO EMOJIS
							// const regex =
							/// [\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F1E0}-\u{1F1FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/gu
							// if (regex.test(e.target.value)) {
							// e.target.value = e.target.value.substring(
							// 0,
							// e.target.value.length - 1
							// )
							// }

							if (registerInput) {
								registerInput.onChange(e).then()
							}
							if (onChangeCustom) {
								onChangeCustom(e)
							}
						}}
						{...props}
					/>
				</div>

				{leftIcon && (
					<Button
						iconLeft={leftIcon}
						className="!w-max !min-w-[unset] !p-0 !border-none"
						color={focus ? 'Transparent' : 'Gray3'}
						leftIconDivClassName="!w-6 !h-6"
						onClick={leftClick}
					/>
				)}
				{limit && (
					<div className="absolute -bottom-[15px] right-0">
						<h1 className="text-xs leading-[9px] text-black-1/50">
							{watchValue ? watchValue.length : 0}/{cantLimit}
						</h1>
					</div>
				)}
			</div>
			{error?.message && (
				<Message text={error.message} type="error" marginL="1.5%" />
			)}
		</div>
	)
}
