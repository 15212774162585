import { CircularProgress } from '@mui/material'
import {
	GET_CONTENTS,
	GET_PEOPLE_TOTAL_FOLLOWERS,
	getContentShared,
	getTotalFollowers,
	patchContentSharedEdit,
	patchContentSharedEditArray
} from 'api'
import clsx from 'clsx'
import { Icon, Typography } from 'components'
import { Button } from 'components/buttons'
import { Input } from 'components/input'
import { PartnerTablet } from 'components/table/Content/PartnerTable'
import { useSearchDebounce } from 'hooks'
import {
	BodySharedContent,
	Icons,
	PartnerTableResponse,
	PropsModalAproval,
	SharePartnerInputs,
	TableResponseContentApproval,
	TypographyTypes
} from 'interfaces'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'
import { TailwindModal } from '../tailwindModal'
import { CopyModal } from './CopyModal'
import { DeclineModal } from './DeclineModal'
import { ReviewPartnerModal } from './reviewPartnerModal'

export const AprovalPendingModal: React.FC<
	PropsModalAproval<TableResponseContentApproval>
> = ({ open, setOpen, setNumberAproval, refetchSharedTrue }): JSX.Element => {
	const { register, watch } = useForm<SharePartnerInputs>({
		mode: 'all'
	})
	const cookies = new Cookies()
	const organizationId = cookies.get('organization_id')
	const watchSearch = watch('search')
	const [idShared, setIdShared] = useState<number | undefined>()
	const [idSharedContent, setIdSharedContent] = useState<number | undefined>()
	const [dataSelected, setDataSelected] = useState<number[]>([])
	const [openModalReview, setOpenModalReview] = useState(false)
	const [openModalDecline, setOpenModalDecline] = useState(false)
	const [openModalCopy, setOpenModalCopy] = useState(false)
	const [openModalCopyPrimary, setOpenModalCopyPrimary] = useState(false)
	const [subscriptionsTablePageSize, setSubscriptionsTablePageSize] =
		useState(10)
	const [openModalSendArray, setOpenModalSendArray] = useState(false)
	const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
	const { inputSearch, handleChangeDebounce, resetInput } =
		useSearchDebounce(watchSearch)
	const [page, setPage] = useState(1)
	const [openPublishModal, setOpenPublishModal] = useState(false)
	const [submitSuccess, setSubmitSuccess] = useState(false)
	const [sharedContentTitle, setSharedContentTitle] = useState('')

	const publishModalSuccessTitle = `Success! You've published Partner Content${
		sharedContentTitle ? ':' : ''
	}`

	const {
		data,
		isLoading,
		refetch: refetchShared,
		isRefetching
	} = useQuery([GET_CONTENTS, organizationId, page, inputSearch], () =>
		getContentShared(organizationId, {
			page,
			search: inputSearch.trim(),
			pageSize: subscriptionsTablePageSize,
			is_approved: false
		})
	)
	const { data: totalFollowers } = useQuery({
		queryKey: [GET_PEOPLE_TOTAL_FOLLOWERS, organizationId],
		queryFn: () => getTotalFollowers(organizationId)
	})

	useEffect(() => {
		if (data) setNumberAproval(data?.total)
	}, [data])

	useEffect(() => {
		if (!openPublishModal) {
			setSharedContentTitle('')
		}
	}, [openPublishModal])

	const submitHandler = async (
		body: BodySharedContent,
		id: number,
		options?: { title: string }
	): Promise<void> => {
		setIsLoadingSubmit(true)
		setOpenPublishModal(true)
		setSharedContentTitle(options?.title ?? '')
		await patchContentSharedEdit(organizationId, body, id)
			.then(response => {
				if (response) {
					refetchShared()
					refetchSharedTrue()
					setDataSelected([])
					setOpenModalReview(false)
					resetInput()
					setSubmitSuccess(true)
				}
			})
			.catch(() => {
				setSubmitSuccess(false)
			})
			.finally(() => {
				setIsLoadingSubmit(false)
				setTimeout(() => {
					setOpenPublishModal(false)
				}, 4000)
			})
	}

	const submitHandlerArray = async (body: BodySharedContent): Promise<void> => {
		setIsLoadingSubmit(true)
		setOpenPublishModal(true)
		await patchContentSharedEditArray(organizationId, body)
			.then(response => {
				if (response) {
					setSubmitSuccess(true)
					refetchShared()
					refetchSharedTrue()
					setDataSelected([])
				}
			})
			.catch(() => {
				setSubmitSuccess(false)
			})
			.finally(() => {
				setIsLoadingSubmit(false)
				setTimeout(() => {
					setOpenPublishModal(false)
				}, 4000)
			})
	}

	return (
		<>
			<TailwindModal
				open={open}
				setOpen={setOpen}
				title="Content pending for Approval"
				className="w-11/12  p-4 rounded-[16px]"
			>
				<>
					<div className="flex gap-5 mt-4 items-center">
						<Input
							name="search"
							className="mb-4"
							borderFull={false}
							register={register}
							leftIcon={Icons.search}
							inputClassName="rounded-[10px]"
							placeholder="Search your partners"
							onChangeCustom={handleChangeDebounce}
						/>
					</div>

					<div className="mt-4">
						<PartnerTablet
							data={data as PartnerTableResponse | undefined}
							page={page}
							setPage={setPage}
							setOpen={setOpenModalReview}
							isLoading={isLoading || isRefetching}
							setIdShared={setIdShared}
							setOpenModalDecline={setOpenModalDecline}
							setIdSharedContent={setIdSharedContent}
							setDataSelected={setDataSelected}
							dataSelected={dataSelected}
							onItemsShowingChange={setSubscriptionsTablePageSize}
							className="overflow-auto"
						/>
					</div>
					<div className="w-full flex flex-col sm:flex-row justify-end gap-4 mt-4">
						<Button
							type="button"
							label="Decline Selected"
							color="white"
							className="!min-w-[191px] ml-4 "
							disabled={dataSelected.length === 0}
							onClick={() => {
								setOpenModalSendArray(true)
								setOpenModalDecline(true)
								resetInput()
							}}
							iconRight={Icons.warning}
							buttonTextClassName="md:text-[12px] lg:text-[13px] xl:text-[14px] sm:text-[11px] "
						/>
						<Button
							type="button"
							label="Copy as a Draft"
							color="white"
							className="!min-w-[191px] ml-4 "
							helper="Copy item to your Content Hub to edit and/or publish later."
							classPanelHelper="!top-[-75px] text-xl"
							onClick={() => {
								setOpenModalCopyPrimary(true)
								setOpenModalCopy(true)
								resetInput()
							}}
							disabled={dataSelected.length === 0}
							buttonTextClassName="md:text-[12px] lg:text-[13px] xl:text-[14px] sm:text-[11px] "
						/>
						<Button
							type="button"
							label="Publish Selected Now"
							color="Primary"
							className="!min-w-[191px] ml-4 "
							onClick={() => {
								resetInput()
								submitHandlerArray({
									organization_partner_ids: dataSelected,
									status: 'published',
									is_approved: true
								})
							}}
							helper="Publish this item now to ALL of your followers. Once published you will not be able to re-configure or enhance this item."
							fillpat
							classPanelHelper="!top-[-83px]"
							disabled={dataSelected.length === 0}
							buttonTextClassName="md:text-[12px] lg:text-[13px] xl:text-[14px] sm:text-[11px] "
						/>
					</div>
					<ReviewPartnerModal
						setOpen={setOpenModalReview}
						open={openModalReview}
						idShared={idShared}
						onActionsClick={submitHandler}
					/>
				</>
			</TailwindModal>

			<DeclineModal
				open={openModalDecline}
				setOpen={setOpenModalDecline}
				onClickConfirm={submitHandler}
				idSharedConten={idSharedContent}
				setOpenModalDecline={setOpenModalDecline}
				openModalSendArray={openModalSendArray}
				setOpenModalSendArray={setOpenModalSendArray}
				onClickSubmit={submitHandlerArray}
				dataSelected={dataSelected}
			/>

			<CopyModal
				setOpen={setOpenModalCopy}
				open={openModalCopy}
				onActionsCopyArray={submitHandlerArray}
				dataSelected={dataSelected}
				openModalCopyPrimary={openModalCopyPrimary}
			/>

			<TailwindModal
				open={openPublishModal}
				setOpen={setOpenPublishModal}
				showCloseButton={!isLoadingSubmit}
				closeOnOverlayTap={!isLoadingSubmit}
				className="w-[360px] p-2 rounded-[16px]"
			>
				{isLoadingSubmit ? (
					<div className="flex min-h-[148px] items-center justify-center">
						<CircularProgress disableShrink />
					</div>
				) : (
					<div
						className={clsx(
							'flex flex-col gap-4 w-full pb-2.5 pt-5 items-center justify-center',
							{ 'pb-3.5': submitSuccess }
						)}
					>
						<span
							className={clsx(
								'border-2 border-blue-primary rounded-full px-2',
								{
									'border-red-tagRejected': !submitSuccess
								}
							)}
						>
							<Icon
								src={submitSuccess ? Icons.check : Icons.close}
								fillPath
								className={clsx('text-blue-primary w-4 h-8', {
									'text-red-tagRejected': !submitSuccess
								})}
							/>
						</span>
						<Typography
							title={
								submitSuccess
									? publishModalSuccessTitle
									: 'Shared Content could not be published'
							}
							fontLeading="15/18"
							type={TypographyTypes.h3}
							className="!font-normal text-black-1 text-center"
						/>
						{submitSuccess && (
							<Typography
								title={sharedContentTitle}
								fontLeading="15/18"
								type={TypographyTypes.h3}
								className=" text-black-1 text-center"
							/>
						)}
						{Boolean(totalFollowers?.current_followers) && submitSuccess && (
							<Typography
								title={`To your ${totalFollowers?.current_followers} followers`}
								fontLeading="15/18"
								type={TypographyTypes.h3}
								className="!font-normal text-black-1 text-center"
							/>
						)}
					</div>
				)}
			</TailwindModal>
		</>
	)
}
