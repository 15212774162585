import { Button } from 'components/buttons'
import { Icon } from 'components/icon'
import { Typography } from 'components/typography'
import { Icons, ModalExportProps, TypographyTypes } from 'interfaces'
import React from 'react'
import { TailwindModal } from '../tailwindModal'

const ExportDataMyPeopleModal: React.FC<ModalExportProps<unknown>> = ({
	open,
	setOpen,
	setOpenExportSucces,
	setActivateExport
}) => {
	const handleConfirmClick = (): void => {
		setOpen(!open)
		setOpenExportSucces(true)
		setActivateExport(true)
	}

	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			showCloseButton
			className="w-[22.380rem] p-4 rounded-[16px] flex flex-col"
		>
			<div className="flex flex-col gap-4 w-full">
				<div className="flex flex-col gap-3 w-full items-center">
					<div className="w-[30px] h-[30px]">
						<Icon src={Icons.warning} fillPath className="text-blue-primary" />
					</div>

					<Typography
						title="Are you sure you want to export the selected users data as a CSV file?"
						fontLeading="15/18"
						type={TypographyTypes.h3}
						className="!font-normal text-black-1 text-center"
					/>
				</div>
				<div className="flex justify-center items-center gap-4 w-full">
					<Button
						label="Yes, export selected"
						className="!min-w-[unset] !py-3 !px-9"
						onClick={() => handleConfirmClick()}
					/>
				</div>
			</div>
		</TailwindModal>
	)
}

export default ExportDataMyPeopleModal
