import { Popover } from '@mui/material'
import { Icon, TailwindPopOver, Typography } from 'components'
import {
	OrganizationStatusActionsProps,
	OrgStatus,
	TypographyTypes
} from 'interfaces'
import { getOrgStatus, orgStatusIcon, orgStatusLabel } from 'lib'
import React, { useState } from 'react'

const statusData = [
	{ id: OrgStatus.lite, label: orgStatusLabel.lite, icon: orgStatusIcon.lite },
	{ id: OrgStatus.pro, label: orgStatusLabel.pro, icon: orgStatusIcon.pro },
	{
		id: OrgStatus.elite,
		label: orgStatusLabel.elite,
		icon: orgStatusIcon.elite
	}
]

export const OrganizationStatusActions = (
	props: OrganizationStatusActionsProps
): JSX.Element => {
	const { orgStatus, onChageStatus } = props
	const { label, icon } = getOrgStatus(orgStatus)
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	const open = Boolean(anchorEl)

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = (): void => {
		setAnchorEl(null)
	}

	return (
		<div>
			<button
				className="rounded-full border-2 border-blue-primary flex items-center justify-center  w-[90px]"
				aria-label="org_type"
				type="button"
				onClick={handleClick}
			>
				<Typography
					className="font-normal text-blue-primary py-1 mr-1"
					title={label}
					fontLeading="15/18"
					type={TypographyTypes.span}
				/>

				<Icon
					src={icon}
					fillPath
					className="text-blue-primary h-4 w-4 !min-h-3 !min-w-3"
				/>
			</button>
			<Popover
				id={open ? 'simple-popover' : undefined}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				PaperProps={{ style: { borderRadius: '16px' } }}
			>
				<TailwindPopOver
					handleClose={handleClose}
					title="Change"
					className="w-auto px-4 !pb-1 !gap-2 max-w-[210px] min-w-[180px]"
					titleClassName="!border-b-0 pb-0 "
				>
					<div className="px-1.5 flex flex-col divide-y">
						{statusData.map(status => (
							<div
								key={status.id}
								className="py-3 px-5 flex items-center justify-center"
							>
								<button
									className="rounded-full border-2 border-blue-primary flex items-center justify-center w-full"
									aria-label="org_type"
									type="button"
									onClick={() => onChageStatus?.(status.id)}
								>
									<Typography
										className="font-normal text-blue-primary py-1 mr-1"
										title={status.label}
										fontLeading="15/18"
										type={TypographyTypes.span}
									/>

									<Icon
										src={status.icon}
										fillPath
										className="text-blue-primary h-4 w-4 !min-h-3 !min-w-3"
									/>
								</button>
							</div>
						))}
					</div>
				</TailwindPopOver>
			</Popover>
		</div>
	)
}
