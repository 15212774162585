import { ropsTotalList } from 'components'
import { DocumentsData, FilesData, locationState, OrgStatus } from './states'

export interface NetworkSubscriptionTableResponse {
	items: NetworkSubscriptionTableItem[]
	total: number
	page: number
	size: number
	pages: number
}

export interface NetworkMyFeedsTableResponse {
	items: NetworkMyFeedsTableItem[]
	total: number
	page: number
	size: number
	pages: number
}

export interface FeedContetMyFeedByIdResponse {
	id: number
	type: string
	creator: string
	title: string
	published: string
}

export interface NetworkMyFeedByIdResponse {
	id: number
	author_id: number
	updated_at: string
	organization_id: number
	name: string
	created_at: string
	feed_contents: FeedContetMyFeedByIdResponse[]
}

export interface NetworkSubscriptionTableItem {
	feed_id: number
	organization_name_feed: string
	organization_suscription_id: string
	feed_name: string
	direction: string
	email: string
	request_date: string
	follower_feed_id: number
	status: NetworkStatus
}

export interface NetworkMyFeedsTableItem {
	id: number
	organization_id: number
	name: string
	created_at: string
	updated_at: string
	subscribers: number
	active_content: number
	created_by: string
}

export interface PartnerFeedsTableResponse {
	items: PartnerFeedsTableItem[]
	total: number
	page: number
	size: number
	pages: number
}
export interface PartnerFeedsTableItem {
	feed_name: string
	organization_name_feed: string
	status: string
	direction: string
	id: number
	request_date: string
	contact: string
	partner_name: string
}

export interface NetworkPartnerTableResponse {
	items: NetworkPartnerTableItem[]
	total: number
	page: number
	size: number
	pages: number
}

export interface PartnerTableResponse {
	items: PartnerTableItem[]
	total: number
	page: number
	size: number
	pages: number
}

export interface PartnerTableItem {
	partner_organization_content_id: number
	title: string
	type: string
	release_date: string
	organization: string
	organization_partner_id?: number
}

export interface MyTagsTableResponse {
	items: OrganizationTag[]
	total: number
	page: number
	size: number
	pages: number
}
export interface OrganizationTag {
	organization_tag_parent_id: number
	count_son: number
	name: string
	required_approval: boolean
	is_visible: boolean
	created_at: string
	num_users: number
	subtags: SubTag[]
}

export interface SubTag {
	organization_tag_id: number
	name: string
	required_approval: boolean
	is_visible: boolean
	num_users: number
}
export interface NetworkPartnerPatchResponse {
	message: string
	detail?: string
}

export interface UserDataSettingResponsive {
	email: string
	full_name: string
	phone_number: string
}

export type NetworkStatus =
	| 'accepted'
	| 'declined'
	| 'sent'
	| 'received'
	| 'disconnect'

export interface NetworkPartnerTableItem {
	id: number
	organization_name: string
	partner_name: string
	request_date: string
	status: NetworkStatus
	direction: string
	created_by: string
	email: string
}

export interface NewOutboundPartnerResponse {
	items: NewOutboundPartnerItem[]
	total: number
	page: number
	size: number
	pages: number
}

export interface NewOutboundPartnerItem {
	id: number
	name: string
	followers: number
}

export interface NewOutboundSubscriptionResponse {
	items: NewOutboundSubscriptionItem[]
	total: number
	page: number
	size: number
	pages: number
}

export interface NewOutboundSubscriptionItem {
	id: number
	created_at: Date
	name: string
	organization_id: number
	updated_at: Date
	subscribers: number
	active_content: number
	organization_name: string
}

export interface NetworkMessagesResponse {
	items: NetworkMessagesItem[]
	total: number
	page: number
	size: number
	pages: number
}

export interface NetworkMessagesItem {
	id_organization: number
	organization_name: string
	email_organization: string
	message: string | null
	created_by: string
	request_date: Date
}

export interface FMPartnerListPartnersResponse {
	items: FMPartnerListPartnersItem[]
	total: number
	page: number
	size: number
	pages: number
}

export interface FMPartnerListPartnersItem {
	organization_partner_id: number
	partner_id: string
	partner_name: string
	followers: number
	profile_image: string
	icon_image_url: string
}

export interface FMPartnerListFollowersResponse {
	items: FMPartnerListFollowersItem[]
	total: number
	page: number
	size: number
	pages: number
}

export interface FMPartnerListFollowersItem {
	id: number
	name: string
	organization_id: number
	followers: number
}

export interface FMPartnerListId {
	name: string
	partners: FMPartnerList[]
}

export interface FMPartnerList {
	id: number
	organization_partner_id: number
	followers: number
}

export type SendMessageToNewPartnersRequest = {
	partner_ids: number[]
	created_by: number
	message: string
}

export interface SendMessageToNewPartnersResponse {
	partner_ids: number[]
}

export type AcceptOutboundSubscriptionsRequest = {
	feed_ids: number[]
}

export type NewMyFeedsRequest = {
	name: string
	add_content_ids: number[]
}

export type EditMyFeedsRequest = {
	name: string
	content_ids: number[]
}

export type EditPartnerListRequest = {
	name: string
	organization_partner_ids: number[]
}
export type NewPartnerListRequest = {
	name: string
	organization_partner_ids: number[]
}

export interface PatchUserInfoData {
	full_name: string
	phone_number: string
	email: string
}

export interface PutResetPasswordData {
	current_pass: string
	new_pass: string
}

export interface PutResetPasswordResponse {
	message?: string
	detail?: string
}

export interface ValueTypePerformanceResponse {
	new_value: number
	old_value: number
}

export interface DashboardPerformanceResponse {
	followers: ValueTypePerformanceResponse
	reach: ValueTypePerformanceResponse
	saved_post: ValueTypePerformanceResponse
	shared_post: ValueTypePerformanceResponse
	sends: ValueTypePerformanceResponse
}

export interface DashboardFollowersResponse {
	day: string
	name: string
	value: number
	year: number
	month: number
	month_name: string
	total: number
}

export interface PromotionResponse {
	share_url: string
}

export interface NewTagsResponse {
	id: number
	name: string
	image: string
	tags: {
		id_tag: number
		name_tag: string
	}[]
}

export interface MyPeopleResponse {
	id: number
	name: string
	email: string
	image: string
}

export type NewTagsRequest = {
	follower_id: number
	tags_id: number[]
	action: string
}

export interface NewTagsPostResponse {
	message: string
}

export type ExportResponse = NewTagsPostResponse

export interface SendEmailResponse {
	send_to: string
}

export interface ContentHighLightItemResponse {
	id: number
	title: string
	type: string
	created_at: string
}

export interface NotificationsResponse {
	followers_wait_approval: number
	new_partners: { id: number; name: string }[]
	fm_request: { id: number; name: string }[]
	feed_request: FeedRequest
}

export interface FeedRequest {
	requests: number
	approvals: number
}

export interface PeopleMyPeopleResponse {
	items: PeopleMyPeopleItem[]
	size: number
	pages: number
	page: number
	total: number
}

export type TagsOpenTypeResponse = {
	id: number
	open: boolean
}
export interface PeopleMyPeopleItem {
	user_id: number
	full_name: string
	email: string
	notification_push: boolean
	active_location: boolean
	device_type: typeSignin
	status: string
	postal_code: string
	last_login: string
	organization_follower_id: number
	followers_tag: PeopleMyPeopleFollowersTag[]
	borderTable?: boolean
	phone_number: string
	phone_code: string
	new_password: string
	member_id: number
	user_signin_type: UserSigninType[]
}

export interface UserSigninType {
	id: number
	signin_type: typeSignin
	updated_at: Date
}

export type typeSignin = 'google' | 'apple' | 'email' | 'organization'

export interface PeopleMyListsResponse {
	items: PeopleMyListsItem[]
	total: number
	page: number
	size: number
	pages: number
}

export interface PeopleMyListsItem {
	id: number
	name: string
	members: number
	type: 'Dynamic' | 'Static'
}

export interface PeopleMyTagsParentResponse {
	items: PeopleMyTagsParentItem[]
	size: number
	pages: number
	page: number
	total: number
}

export interface PeopleMyTagsParentItem {
	name: string
	organization_tag_parent_id: number
	count_son: number
	required_approval: boolean
	is_visible: boolean
	num_users: number
}

export interface PeopleMyTagsChildrenResponse {
	items: PeopleMyTagsChildrenItem[]
}

export interface PeopleMyTagsResponse {
	items: PeopleMyTagsChildrenItems[]
}

export interface PeopleMyFilterResponse {
	items: FilterlistRequest
}

interface FilterlistRequest {
	filters: ListfilterDinamic[]
	id: number
	name: string
}
export interface PeopleMyTagsChildrenItems {
	organization_id: number
	name: string
	id: number
}
export interface PeopleMyTagsChildrenItem {
	organization_tag_id: number
	name: string
	required_approval: boolean
	is_visible: boolean
	num_users: number
	count_son?: number
	organization_tag_parent_id?: number
}

export interface TagsApprovalResponse {
	items: TagsApprovalItem[]
	size: number
	pages: number
	page: number
	total: number
}

export interface TagsApprovalItem {
	user_id: number
	organization_follower_id: number
	name: string
	email: string
	// followers_tag: TagItem[]
	followers_tag?: FollowerTagItems
}

export interface TagItem {
	organization_follower_parent_tags?: OrganizationFollowerParentTags[]
	organization_follower_independent_tags?: OrganizationFollowerIndependentTags[]
	organization_follower_tag_id: number
	name: string
}

export interface FollowerTagIndependent {
	organization_follower_tag_id: number
	required_approval?: boolean
	tagged?: boolean
	id?: number
	is_approved?: boolean | null
	tag_name: string
	name?: string
	is_visible?: boolean
}
export interface TagsChild {
	organization_follower_tag_id: number
	tag_name: string
	name?: string
	id?: number
	required_approval?: boolean
	is_approved?: boolean | null
	tagged?: boolean
	is_visible?: boolean
}
export interface FollowerTagParent {
	name?: string
	is_visible?: boolean
	tag_name: string
	is_approved?: boolean | null
	id?: number
	organization_follower_tag_id: number
	tags_child: TagsChild[]
}

export interface FollowerTagItems {
	organization_follower_parent_tags: FollowerTagParent[]
	organization_follower_independent_tags: FollowerTagIndependent[]
}
export interface TagItemNew {
	follower_tags: FollowerTagItems[]
}

export interface FollowersTag {
	organization_follower_parent_tags?: OrganizationFollowerParentTags[]
	organization_follower_independent_tags?: OrganizationFollowerIndependentTags[]
}

export interface OrganizationFollowerParentTags {
	name: string
	tagsChild: tagsChild[]
}

export interface tagsChild {
	organization_follower_tag_id: number
	name: string
}

export interface OrganizationFollowerIndependentTags {
	organization_follower_tag_id: number
	name: string
}

export interface PeopleMyPeopleFollowersTag {
	organization_follower_tag_id: number
	name: string
	required_approval: boolean
}

export interface TotalFollowersResponse {
	current_followers: number
	followers_goal: number
}

export interface UsersPlatformResponse {
	ios_devices: number
	android_devices: number
	location_on: number
	push_on: number
}

export interface AddNewTagResponse {
	id: number
}

export interface MessageResponse {
	message: string
	detail?: string
}

export interface Message {
	message: string
}

export interface SetFollowersGoalRequest {
	follower_goal: number
}

export interface StaticListRequest {
	organization_followers_id: number[]
	name: string
}

export interface DynamicListRequest {
	tags_id: number[]
	name: string
}

export interface EditStaticListOptionsResponse {
	items: EditStaticListOptionsItem[]
	total: number
}

export interface EditStaticListOptionsItem {
	organization_follower_id: number
	user_id: number
	user_name: string
	present_list: boolean
}

export interface EditDynamicListOptionsResponse {
	items: EditDynamicListOptionsItem[]
	total: number
}

export interface EditDynamicListOptionsItem {
	dynamic_list_tag_id: number
	tag_id: number
	tag_name: string
	tag_required_approval: boolean
}

export interface EditTagResponse {
	items: EditTagItem[]
	size: number
	pages: number
	page: number
	total: number
}

export interface EditTagItem {
	id: number
	name: string
	required_approval: boolean
	organization_id: number
}

export interface ItemsTag {
	id: number
	name: string
	required_approval: boolean
	is_visible: boolean
	is_approved: boolean | null
}

export interface ParentTag extends ItemsTag {
	tags_child: ItemsTag[]
}

export interface GetPeopleTagsDeleteOrgResponse {
	organization_follower_parent_tags: ParentTag[]
	organization_follower_independent_tags: ItemsTag[]
	users_to_assign: number[]
}
export interface GetPeopleTagsOrgResponse {
	items: GetPeopleTagsOrgItem[]
}

export interface GetPeopleTagsOrgItem {
	id: number
	name: string
	required_approval: boolean
	organization_id: number
}
export interface PeopleTagsOrgRequest {
	name?: string
	required_approval?: boolean
	organization_tag_parent_id?: number
	organization_tag_id?: number
	organization_id?: number
	is_visible?: boolean
}

export interface TagDynamicListRequest {
	name: string
	tags_id: number[]
}
export interface FiltersDynamicListRequest {
	name: string
	filters: ListfilterDinamic[]
}

export interface ListfilterDinamic {
	comparator: string
	operator: string
	property: string
	value: string
}

export interface TagsUserRequest {
	organization_follower_parent_tags?: FollowerTagParent[]
	organization_follower_independent_tags?: FollowerTagIndependent[]
	id: number
	name: string
	tagged: boolean
}

export interface UpdateUserInfoRequest {
	full_name: string
	email: string
	phone_number: string
	postal_code: string
	new_password: string
	member_id: number
}

export interface UpdateUserTagsRequest {
	tags_id: number[]
}

export interface BannerMyOrganizationItem {
	id?: number
	organization_id: number
	banner_order: number
	title: string
	image_url: string
	redirect_type: string
	redirect_value: string
}

export interface PhoneNumbersItem {
	title: string
	phone_number: string
	code: string
}

export interface LinkItem {
	text_link: string
	url: string
}

export interface SocialMediaItem {
	type: string
	username: string | null
}

export interface MailItem {
	title: string
	email: string
}

export interface DocumentItem {
	title: string
	url: string
}

export type TypeComponentsItems =
	| 'social_media'
	| 'attachment'
	| 'link'
	| 'content'
	| 'mail'
	| 'simple_text'
	| 'location'
	| 'tags'
	| 'phone'
	| 'title'
	| ''

export interface TabComponentsMyOrganizationValueItem {
	title?: string
	description?: string
	phones?: PhoneNumbersItem[]
	address_line?: string
	zip_code?: string
	links?: LinkItem[]
	social_media?: SocialMediaItem[]
	name?: string
	tags?: string[]
	documents?: DocumentItem[]
	mails?: MailItem[]
	content?: string
}

export interface TabComponentsMyOrganizationItem {
	id?: number
	component_order: number
	component_type: TypeComponentsItems
	component_value: TabComponentsMyOrganizationValueItem
}

export interface TabFormResponse {
	name: string
	components: TabComponentsMyOrganizationItem[]
	audience: ropsTotalList
}

export interface TabMyOrganizationItem {
	id?: number
	organization_id?: number
	name: string
	section_order?: number
	components: TabComponentsMyOrganizationItem[]
	audience: { tags: number[] }
}
export interface ProfileImageMyOrganizationItem {
	cover: string
	icon: string
	primary_color?: string
	secondary_color?: string
	third_color?: string
	fourth_color?: string
}

export interface MyOrganizationResponse {
	banners: BannerMyOrganizationItem[]
	profile_image: ProfileImageMyOrganizationItem
	tabs: TabMyOrganizationItem[]
}

export interface ForgotPasswordResponse {
	detail?: string
}

export interface ForgotPassword {
	token: string
	password: string
}

export interface ForgotResponse {
	detail?: string
	message?: string
}
export interface SinginRequest {
	community_id: number
	signin_type: string
	signin_username: string
	signin_value: string
	device_type: string
}

export interface OrganizationResponse {
	id: number
	name: string
	roles: number[]
	slug: string
	icon_image_url: string
}

export interface SingigResponse {
	detail?: string
	message?: string
	id?: number
	email?: string
	community_id?: number
	full_name?: string
	phone_number?: string
	postal_code?: string
	unique_identifier?: null
	image?: null
	is_active?: boolean
	organizations?: OrganizationResponse[]
	access_token?: string
	is_community_admin?: boolean
}

export interface PatchMyOrganizationResponse {
	message: string
}

export interface UpdateImageBody {
	acl: string
	key: string
	'x-amz-algorithm': string
	'x-amz-credential': string
	'x-amz-date': string
	'x-amz-security-token': string
	policy: string
	'x-amz-signature': string
	file: Blob
}
export interface UpdateImageResponse {
	url: string
	fields: UpdateImageBody
}
export interface UserInfoResponse {
	id: number
	communiy_id: null
	name: string
	is_default: boolean
	phone_number: string
	address: string
	email: string
	slug: string
	follower_goal: number
	share_url: string
	at_least_one_tag: boolean
	tag_multiselect: boolean
}

export interface TagApprovalRequest {
	action: string
}

export interface TagsApprovalRequest {
	organization_follower_ids: number[]
	is_approval: boolean
}

export interface UpdateOrganizationRequest {
	at_least_one_tag: boolean
	tag_multiselect: boolean
}

export interface UpdateFollowerStatusRequest {
	status: string
}

export interface DeleteTagsRequest {
	tags_id: number[]
	tag_to_replace: number | number[]
}

export interface UserRole {
	id: number
	name: string
	description: string
	created_at: string | null
	updated_at: string | null
	is_hidden: boolean
}

export interface UserRolesResponse {
	roles: UserRole[]
}

export interface UserNoExistsResponse {
	user_id: null
}

export interface UserExistsResponse {
	email: string
	full_name: string
	phone_number: string
	user_id: number
}

export type ValidateUserResponse = UserNoExistsResponse | UserExistsResponse

export interface CreateOrganizationRequest {
	organization_data: OrganizationData
	main_contact: MainContact
	sso: SSO
	build_app_id: number
	slug: string
	customize_app: CustomizeApp
	users_admin: UserAdmin[]
	tags_organization: TagsOrganization[]
	views: ViewOrganization[]
}
export interface UpdaterganizationRequest {
	organization: Partial<OrganizationDataUpdate>
	main_contact: MainContact
	sso: SSO
	documents: Document[]
	views: ViewOrganization[]
}
export interface UpdateOrganizationBodyRequest {
	organization?: Partial<OrganizationDataUpdate>
	main_contact?: Partial<MainContact>
	documents?: Document[]
}

export interface OrganizationDataUpdate {
	name: string
	// email: string
	notes: string
	address: string
	address_details: string
	phone_number: string
	share_url: string
	url: string
	organization_type?: OrgStatus | null
}

export interface CustomizeApp {
	primary_color: string
	secondary_color: string
	third_color: string
	fourth_color: string
	icon_image_url: string | null
}

export interface MainContact {
	name: string
	phone_number: string
	address: string
	address_details: string
	notes: string
	email?: string
	share_url?: string
	url?: string
	branch_url_migration?: string
	branch_url_sso?: string
	build_app_branch_key?: string
	build_app_branch_secret_key?: string
	slug?: string
	from_migration?: boolean
}

export interface SSO {
	sso_link: string
	data_refresh_frecuency_sso: number | null
	email_support_sso: string
}

export interface OrganizationData extends MainContact {
	documents: Document[]
}
export interface Document {
	document_url: string
	name: string
	type_document: string
}

export interface TagsOrganization {
	tag_parent: Tag
	tags: Tag[]
}

export interface Tag {
	name: string
	required_approval: boolean
}

export interface ExistingUserEdit {
	roles: number[]
	full_name: string
	phone_number: string
	email: string
	password?: string
	image?: File | null
}
interface ExistingUser {
	roles: number[]
	full_name: string
	phone_number: string
	email: string
	password: string
	image: string | null
}

interface NewUser {
	user_id: number
	roles: number[]
}

export type UserAdmin = NewUser | ExistingUser

export interface UploadDocumentCreedentialsResponse {
	url: string
	fields: {
		acl: string
		key: string
		AWSAccessKeyId: string
		policy: string
		signature: string
	}
}

export interface ContentPerformanceResponse {
	content_created: ValueTypePerformanceResponse
	total_reach: ValueTypePerformanceResponse
	saved_content: ValueTypePerformanceResponse
	shared_content: ValueTypePerformanceResponse
}

export type BuildAppItem = {
	organization_id: number
	id: number
	app_name: string
	description: string
	icon_url: string
	splash_icon_url: string
	splash_background_url: string
	status: string
	last_version: {
		android: string
		ios: string
	}
}

export type GetCommunityBuildAppsResponse = {
	items: BuildAppItem[]
	size: number
	pages: number
	page: number
	total: number
}

export type Organization = {
	id: number
	name: string
	phone_number: string
	address: string
	last_content: null | string
	users: number
	email: string
	admin_email: string
	build_app: string
	build_app_id: number
	contents: number
	icon_image_url: null | string
	share_url: string
	slug: string
	organization_type?: OrgStatus | null
}

export type GetOrganizationsResponse = {
	items: Organization[]
	total: number
	page: number
	size: number
	pages: number
}

export interface AppNamesTableResponse {
	items: AppNamesTableItem[]
	total: number
	page: number
	size: number
	pages: number
}

export interface AppNamesTableItem {
	id: number
	app_name: string
	description: string
	icon_url: string
	splash_icon_url: string
	splash_background_url: string
	versions: Versions
	branch_key: string
	branch_secret_key: string
	branch_url: string
}

export interface TableResposeData {
	id: number
	type: string
	organization: string
	title: string
	schedule: string
}
export interface Versions {
	android: Version | null
	ios: Version | null
}

export interface Version {
	id: number
	build_app_id: number
	version_build: string
	version_name: string
	description: string
	os_type: string
	version_testers: string
	status: string
}

export interface LastVersionAppNameItem {
	android: string
	ios: string
}

export interface AppVersionsTableResponse {
	items: AppNamesTableItem[]
	total: number
	page: number
	size: number
	pages: number
}

export interface TableResponseContentApproval {
	total: number
	page: number
	size: number
	pages: number
}

export interface AppVersionsTableItem {
	version_name: string
	id: number
	os_type: string
	version_testers: string
	created_at: string
	build_app_id: number
	version_build: string
	description: string
	status: string
	updated_at: string
}

export interface BuildAppRequest {
	app_name: string
	description: string
	icon_url: string
	splash_icon_url: string
	splash_background_url: string
	community_id: number
}

export interface BuildAppResponse {
	id: number
	app_name: string
	description: string
	icon_url: string
	splash_icon_url: string
	splash_background_url: string
}

export interface BuildAppVersionRequest {
	build_app_id: number
	version_name: string
	version_build: string
	description: string
	version_testers: string
	os_type: string
	status: string
}

export interface BuildAppVersionResponse {
	id: number
	build_app_id: number
	version_build: string
	version_name: string
	description: string
	os_type: string
	version_testers: string
	status: null
}

export interface BuildAppInfoResponse {
	app_name: string
	description: string
	splash_icon_url: string
	created_at: string
	id: number
	icon_url: string
	splash_background_url: string
	updated_at: string
}

export type ContentReachRequest = {
	tags: number[]
	lists: number[]
	dynamic_lists: number[]
	individuals: number[]
}

export type ContentReachResponse = {
	detail?: string
	reach: number
}

export interface ItemDataAssentsAll {
	asset_type: string
	asset_url: string
	asset_name?: string
	asset_aspect?: string
}

export interface AssentsAllResponse {
	items: ItemDataAssentsAll[]
	total: number
	page: number
	size: number
	pages: number
	details?: string
}

export type CreateContentResponse = {
	detail?: string
	data?: ContentListItem
}

export type ContentResponseShared = {
	content_id: number
	created_at: string
	id: number
	status: string
	message: string
	organization_partner_id: number
	updated_at: string
}

export interface DataBodySedContent {
	organization_partner_ids: number[]
	status: string
}

export type CreateContentResponseArray = {
	detail?: string
	data?: DataBodySedContent
}

export type CreateContentResponseShared = {
	detail?: string
}

export interface ContentListItem {
	content_category: 'take_action' | 'event' | 'info' | 'notification' | null
	created_at: string | null
	creator: string
	fm: 'not_amplified' | 'inbound' | 'outbound' | 'accepted' | 'ampified'
	id_content: number
	id: number
	content_id: number
	is_pinned: boolean | null
	last_edit: string
	reach: string | null
	repeat_start_date: string
	status: 'draft' | 'deleted' | 'published' | 'finished'
	title: string
	dataNotification?: boolean
	updated_at?: string
	author?: string
	release_date?: string
	organization?: string
	type: string
	partner_organization_content_id: number
	order?: number
}

export interface ContentTableItem {
	organization_id: number
	content_category: string
	release_date: string
	author: string
	title: string
	status: string
	force_multiplier: string
	created_at: string
	updated_at: string
	reach: number | null
	is_pinned: boolean | null
}
export interface ContentListResponse {
	detail?: string
	items: ContentListItem[]
	total: number
	page: number
	size: number
	pages: number
}

export interface ContentTableProps {
	items: ContentListItem[]
	total: number
	page: number
	size: number
	pages: number
}

export interface EditContentListBody {
	is_pinned?: boolean
	order?: null | number
	status?: 'draft' | 'deleted' | 'published'
}

export interface EditContentListResponse {
	message: string
	detail?: string
}

export type CreateContentListResponse = Omit<EditContentListResponse, 'detail'>
export interface ContentListSharedResponse {
	partner_organization_content_id: number
	is_approved: boolean
	type: string
	schedule: null | string
	title: string
	status: string
	creator: string
	published: null | string
	last_edit: string
	reach: string | null
	fm: string
	organization_partner_id: number
	organization: string
	content_id: number
}

export interface ContentResponse {
	detail?: string
	organization_id: number
	author_id: number
	slug: string
	status: string
	basic_setup: {
		// info event take_action notification
		category: string
		sharing: boolean
		saving: boolean
		is_pinned: boolean
		preview: {
			files: FilesData[]
			video_link_external: string
		}
	}
	set_up: {
		title: string
		description: string
	}
	delivery_enhancements?: {
		is_perpetual: boolean
		schedule?: {
			timing: {
				release_date: string
				expiration_date: string
			}
			custom_recurrence: {
				repeating_schedule: boolean
				repeat_start_date: string
				// day week month year
				repeat_every_type: string
				repeat_every_value: number
				repeat_on: string
				ends: {
					// after_ocurrence , on_date, never
					repeat_end_type: string
					repeat_end_date?: string | null
					after_ocurrences?: number | null
				}
			}
			event?: {
				event_start_date: string
				event_end_date: string
				event_repeat_until: string
				custom_recurrence: {
					event_repeating_schedule: boolean
					event_repeat_start_date: string
					event_repeat_every_type: string
					event_repeat_every_value: number
					event_repeat_on: string
					ends: {
						// after_ocurrence , on_date, never
						event_repeat_end_type: string
						event_repeat_end_date?: string | null
						event_after_ocurrences?: number | null
					}
				}
			}
		}
		audience?: {
			tags: number[]
			lists: number[]
			dynamic_list: number[]
			individuals: number[]
			organizations: number[]
			feeds: number[]
			partner_lists: number[]
		}
		no_good_link?: boolean
		good_link?: {
			link_type: string
			link_value: string
			link_call_action: string
			is_sublink?: boolean
			sublink_type?: string
			sublink_value?: string
			sublink_call_action?: string
		}
		locations?: locationState[]
		documents?: DocumentsData[]
		repeat_end_value?: number
		push_notification?: {
			title: string
			message: string
			is_title: boolean
			is_message: boolean
			image: string
			timing?: {
				release_date: string
			}
			custom_recurrence?: {
				repeating_schedule: boolean
				repeat_start_date: string
				// day week month year
				repeat_every_type: string
				repeat_every_value: number
				repeat_on: string
				ends?: {
					// after_ocurrence , on_date, never
					repeat_end_type: string
					repeat_end_date: string
					after_ocurrences: number
				}
			}
		}
	}
}

export interface OrganizationsByUserData {
	id: string
	slug: string
	icon_image_url: string
	profile_image: string
	name: string
	roles: [
		{
			credential_id: number
			credential_name: string
		}
	]
}

export interface OrganizationsByUserResponse {
	items: OrganizationsByUserData[]
	page: number
	pages: number
	size: number
	total: number
}

export interface GetOrganizationDataResponse {
	organization: MainContact
	main_contact: MainContact
	sso: SSO
	documents: Document[]
	views: ViewOrganization[]
}

export interface ViewOrganization {
	id?: number
	name: string
	order: string
	enabled: boolean
	description: string
	image: string
}

export interface GetAdminOrganizationResponse {
	items: Item[]
	total: number
	page: number
	size: number
	pages: number
}

export interface Item {
	organization_follower_id: number
	user_id: number
	full_name: string
	roles: string
	email: string
}

export interface ItemElement {
	items: ElementNewDataAdmin[]
}
export interface ElementNewDataAdmin {
	id: number
	fullName: string
	email: string
}

export interface GetAdminExisting {
	email?: string
	full_name?: string
	phone_number?: string
	user_id: number
}

export interface GetUserAdmin {
	user: User
}

export interface User {
	organization_follower_id: number
	user_id: number
	full_name: string
	email: string
	phone_number: string
	roles: Role[]
}

export interface Role {
	id: number
	name: string
	description: string
	selected: boolean
}

export interface GetBuildAppOrganization {
	items: ItemBuildApp[]
}
export interface StaticListData {
	id: number
	name: string
	members: number
	type: string
}

export interface DynamicListItem {
	id: number
	name: string
	members: number
	type: string
}

export interface StaticListResponse {
	items: StaticListData[]
	total: number
	page: number
	size: number
	pages: number
}

export interface DynamicListResponse {
	items: DynamicListItem[]
	size: number
	pages: number
	page: number
	total: number
}

export interface ListSharedContentResponse {
	items: PartnerOrganization[]
	total: number
	page: number
	size: number
	pages: number
}

export interface PartnerOrganization {
	partner_organization_content_id: number
	type: string
	schedule: null | string
	title: string
	organization: string
}
export interface ItemBuildApp {
	id: number
	app_name: string
	selected: boolean
	description: string
	icon_url: string
	splash_icon_url: string
	splash_background_url: string
}
export interface IdUpdateBuilApp {
	build_app_id: number | null
}

export interface GetEmailUsing {
	user: boolean
}

export interface DetailNotification {
	status: string
	content_id: null
	organization_id: number
	template_notification_id: null
	cta_type: string
	cta_value: string
	cta_latitude: number
	cta_longitude: number
	created_at: Date
	updated_at: Date
	expiration_date: null
	id: number
	title: string
	message: string
	is_title: boolean
	is_message: boolean
	image: string
	timing: {
		release_date: null
		expiration_date: null
	}
	custom_recurrence: {
		repeating_schedule: boolean
		repeat_start_date: string | null
		repeat_every_type: string
		repeat_every_value: number
		repeat_on: string
		ends: {
			repeat_end_value: number
			repeat_end_type: string
			repeat_end_date: string | null
			after_ocurrences: number | null
		}
	}
	location: locationState[]

	audience: {
		tags: number[]
		lists: number[]
		dynamic_lists: number[]
		individuals: number[]
		organizations: number[]
		feeds: number[]
		partner_lists: number[]
	}
	repeat_end_value: number
}

export interface Location {
	id: number
	title: string
	address: string
	latitude: number
	longitude: number
	radius: number
	is_virtual_event: boolean
	virtual_event_link: null
}

export interface ResponseDuplicateNotification {
	id: number
	title: string
	description: null
	custom_image_url: null
	release_date: null
	repeating_schedule: boolean
	repeat_start_date: null
	expiration_date: null
	repeat_every_type: null
	repeat_every_value: null
	repeat_on: null
	repeat_end_type: null
	repeat_end_date: null
	after_ocurrences: null
	cta_type: string
	cta_value: string
	is_title: boolean
	is_message: boolean
	author_id: null
	original_notification_id: null
	updated_at: null
	created_at: null
	status: string
}

export interface EditLocationPinned {
	is_pinned: boolean
}

export interface LocationListsTableResponse {
	items: LocationListsTableItem[]
	total: number
	page: number
	size: number
	pages: number
}

export interface LocationListsTableItem {
	id: number
	organization_id: number
	name: string
	author_id: number
	total_content: number | null
	location_ids?: number[]
}

export type NewLocationListRequest = {
	name: string
	author_id: number
	location_ids: number[]
}

export interface MessageResponseNewLocationLists {
	id?: number
	organization_id?: number
	name?: string
	author_id?: number
	created_at?: Date
	updated_at?: Date
	detail?: string
}

export type EditLocationsListRequest = {
	name: string
	location_ids: number[]
}

export type ItemComponentValue = {
	title: string
	description: string
}

export interface UpdateTabsResponse {
	components: TabComponentsMyOrganizationItem[] | undefined
	id?: number
	organization_id: number
	name: string
	section_order: number
}
export interface CreateComponentOrganizationResponse {
	components: (
		| TabComponentsMyOrganizationItem
		| {
				component_order: number
				component_type: string
				component_value: {
					title: string
					description?: string
					address_line?: string
					zip_code?: string
				}
		  }
	)[]
	id?: number
	organization_id: number
	name: string
	section_order: number
}

export interface GetTiming {
	release_date: string
	expiration_date: string
}

export interface GetCustomRecurrenceEnd {
	repeat_end_type: string
	repeat_end_date: string
	after_ocurrences: number
	repeat_end_value: number
}
export interface GetCustomRecurrence {
	repeating_schedule: boolean
	repeat_start_date: string
	repeat_every_type: string
	repeat_every_value: number
	repeat_on: string
	ends: GetCustomRecurrenceEnd
}
export interface GetEventsEnd {
	repeat_end_type: string
	repeat_end_date: string
	after_ocurrences: number
}
export interface GetEventCustomRecurrencEnd {
	repeating_schedule: boolean
	repeat_start_date: string
	repeat_every_type: string
	repeat_every_value: number
	repeat_on: string
	ends: GetEventsEnd
}
export interface GetAudience {
	tags: number[]
	lists: number[]
	dynamic_lists: number[]
	individuals: number[]
	organizations: number[]
	feeds: number[]
	partner_lists: number[]
}
export interface GetGoodLink {
	link_type: string
	link_value: string
	link_call_action: string
	is_sublink: boolean
	sublink_type: string
	sublink_value: string
	sublink_call_action: string
}

export type TypeOrder = 'desc' | 'asc' | null

export enum PeopleBatchesStatus {
	BLOCK = 'block',
	UNBLOCK = 'unblock'
}
export enum PeopleBatchesTagAction {
	ADD = 'add',
	REMOVE = 'remove'
}
export interface PatchPeopleBatchesPayload {
	organizationId: number
	userIds: number[]
	organization_follower_ids: number[]
	status_user?: PeopleBatchesStatus
	remove_users?: boolean
	organization_tags?: {
		action: PeopleBatchesTagAction
		ids: number[]
	}
}

export interface PatchPeopleBatchesError {
	errors: { msg: string }[]
}
export interface BranchConfig {
	alternate_short_url_domain: string
	always_open_app: number
	android_app: number
	android_app_links_enabled: boolean
	android_cd_enabled: unknown | null
	android_cd_hashed: unknown | null
	android_package_name: string
	android_uri_scheme: string
	android_url: string
	android_tablet_url: string | null
	auto_fetch: unknown | null
	blackberry_url: string | null
	deepview_android: unknown | null
	deepview_desktop: string
	deepview_ios: unknown | null
	deepview_configs: unknown | null
	default_desktop_url: string
	default_short_url_domain: string
	desktop_uri_scheme: string
	dev_email: string
	dev_name: string
	esp_config: unknown | null
	fire_url: string | null
	imei_collection_enabled: unknown | null
	imei_terms_and_conditions: unknown | null
	ios_app: number
	ios_bundle_id: string
	ios_bundle_settings: unknown | null
	ios_additional_bundle_ids: string[]
	ios_cd_enabled: unknown | null
	ios_cd_hashed: unknown | null
	ios_team_id: string
	ios_uri_scheme: string
	ios_url: string
	ipad_url: string | null
	ios_app_store_id: number
	map_utm_params: unknown | null
	reserved_analytics_to_utm: boolean
	og_app_id: string | null
	og_description: string | null
	og_image_url: string
	og_title: string
	origin: unknown | null
	sha256_cert_fingerprints: string[]
	short_url_domain: string
	sitemap_enabled: unknown | null
	text_message: string | null
	tmta_enabled: unknown | null
	universal_linking_enabled: boolean
	web_url: string
	wechat_url: string | null
	windows_phone_url: string | null
	redirect_domains_whitelist: unknown | null
	journeys_open_app: unknown | null
	passive_deepview_ios: unknown | null
	passive_deepview_android: unknown | null
	uri_redirect_mode: number
	timezone: string
	mac_uri_scheme: string | null
	mac_store_url: string | null
	windows_uri_scheme: string | null
	windows_store_url: string | null
	windows_package_family_name: unknown | null
	organization_id: unknown | null
	sk_ad_network_enabled: unknown | null
	ios_app_store_id_verified: unknown | null
	local_currency: unknown | null
	organic_search_domains: unknown | null
	qr_code_settings: unknown | null
	custom_partners_limit: unknown | null
	id: string
	app_key: string
	creation_date: string
	app_name: string
	ios_store_country: unknown | null
	branch_key: string
	branch_secret: string
	zuora_account_id: string
}

export interface BranchDeepLink {
	url: string
	key?: string
}

export interface BranchDeepLinkInfo {
	data: BranchDeepLinkDataData
	alias: string
	type: number
}

export interface BranchDeepLinkDataData {
	'+url': string
	'~creation_source': number
	'~id': string
	url: string
}
