import { CircularProgress } from '@mui/material'
import {
	BranchConfigArg,
	BranchUrlQueryParams,
	getBranchConfig,
	getBranchDeepLinkInfo
} from 'api'
import { saveBranchKey } from 'api/patch/buildApp'
import { BranchUrlArg, createDeepLink } from 'api/post/branch'
import { Button, Icon, LinkFiller, Typography } from 'components'
import { useToast } from 'hooks'
import {
	BranchKeysFormValues,
	BranchSetupFormProps,
	Icons,
	TypographyTypes
} from 'interfaces'
import { normalizeDeepLinkAlias } from 'lib'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { encryptData } from 'utils'
import { BranchKeysForm } from './BranchKeysForm'
import { ConfirmDeepLinkCreation } from './ConfirmDeepLinkCreation'

interface BranchForm {
	branchKey: string
	branchSecret: string
	alias: string
}

const Loading = (): JSX.Element => {
	return (
		<div className=" w-full flex items-center justify-center py-5">
			<CircularProgress disableShrink style={{ color: '#17B3C5' }} />
		</div>
	)
}

export const BranchSetupForm = (props: BranchSetupFormProps): JSX.Element => {
	const { appBuild, onSuccessDeepLink, refetch } = props
	const decryptBranchKey = encryptData(appBuild?.branch_key ?? '', 'decrypt')
	const decryptSecretKey = encryptData(
		appBuild?.branch_secret_key ?? '',
		'decrypt'
	)
	const { showToast } = useToast()
	const [deepLink, setDeepLink] = useState('')
	const [urlDomain, setUrlDomain] = useState('')
	const [confirmAliasOpen, setConfirmAliasOpen] = useState(false)
	const [isValidAlias, setIsValidAlias] = useState(false)

	const defaultAlias = normalizeDeepLinkAlias(appBuild?.app_name ?? '')

	const {
		handleSubmit,
		watch,
		formState: { errors },
		setValue,
		control
	} = useForm<BranchForm>({
		mode: 'onChange',
		defaultValues: {
			branchKey: decryptBranchKey ?? '',
			branchSecret: decryptSecretKey ?? '',
			alias: ''
		}
	})

	const onCopyLink = (): void => {
		navigator.clipboard.writeText(deepLink.trim())
		showToast('Copied', 'AAC copied to the clipboard', 'success')
	}

	const { mutate: mutateGetDeepLink, isLoading: isLoadingDeepLinkInfo } =
		useMutation({
			mutationFn: (params: BranchUrlQueryParams) =>
				getBranchDeepLinkInfo(params),
			onSuccess: data => {
				setValue('alias', data.alias)
			},
			onError: (error: Error) => {
				showToast('Error', error.message, 'error')
			}
		})

	const { mutate, isLoading, isError } = useMutation({
		mutationFn: (params: BranchConfigArg) => getBranchConfig(params),
		onSuccess: async data => {
			const encryBranchKey = encryptData(data.branch_key, 'encrypt')
			const encrySecretKey = encryptData(data.branch_secret, 'encrypt')
			await saveBranchKey(
				{
					branch_key: encryBranchKey,
					branch_secret_key: encrySecretKey
				},
				appBuild?.id as number
			)
			refetch?.()
			setUrlDomain(data?.default_short_url_domain)
		},
		onError: (error: Error) => {
			showToast('Error', error.message, 'error')
		}
	})

	const { mutate: mutateDeepLink, isLoading: isLoadingDeepLink } = useMutation({
		mutationFn: (params: BranchUrlArg) => createDeepLink(params),
		onSuccess: async data => {
			if (data?.url) {
				await saveBranchKey(
					{
						branch_url: data?.url
					},
					appBuild?.id as number
				)
				onSuccessDeepLink?.(data?.url)
				setDeepLink(data?.url)
				mutateGetDeepLink({
					url: data?.url ?? '',
					branch_key: watch('branchKey')
				})
				refetch?.()
			}
		},
		onError: (error: Error) => {
			if (error.message.includes('alias conflict')) {
				showToast(
					'Error',
					'This alias cannot be used. Please select a different one.',
					'error'
				)
			} else {
				showToast('Error', error.message, 'error')
			}
		}
	})

	const onSave = (args: BranchKeysFormValues): void => {
		setValue('branchKey', args.branchKey.trim())
		setValue('branchSecret', args.branchSecret.trim())
		mutate({ key: args.branchKey.trim(), secret: args.branchSecret.trim() })
	}

	const onGenerateAAC = (args: BranchForm): void => {
		setConfirmAliasOpen(false)
		const deepLinkBody = {
			alias: args?.alias ? args?.alias : `${defaultAlias}_container`,
			branch_key: args.branchKey
		}
		mutateDeepLink(deepLinkBody)
	}
	const onClickGenerate = (): void => {
		const alias = watch('alias')
		if (alias) {
			handleSubmit(onGenerateAAC)()
		} else {
			setConfirmAliasOpen(true)
		}
	}

	useEffect(() => {
		if (appBuild?.branch_url) setDeepLink(appBuild?.branch_url as string)
		if (decryptBranchKey && decryptSecretKey && !appBuild?.branch_url)
			mutate({ key: decryptBranchKey.trim(), secret: decryptSecretKey.trim() })
	}, [])

	return (
		<div className="flex flex-col gap-7">
			<BranchKeysForm
				onClickSave={onSave}
				hideKeys={Boolean(urlDomain)}
				appBuild={appBuild}
			/>
			{isLoading || isLoadingDeepLinkInfo ? (
				<Loading />
			) : (
				<>
					<div>
						{!deepLink && (
							<>
								<Typography
									title="Generate Alias"
									type={TypographyTypes.h3}
									fontLeading="18/21"
									className="text-gray-9 mb-4"
								/>
								<Typography
									title="Alias"
									type={TypographyTypes.h3}
									className="text-black-1 !mb-2"
									fontLeading="12/12"
								/>
								<Controller
									control={control}
									name="alias"
									render={({ field: { onChange } }) => {
										return (
											<LinkFiller
												urlDomain={urlDomain}
												onChange={onChange}
												placeholder="Enter the alias"
												setIsValidAlias={setIsValidAlias}
											/>
										)
									}}
								/>
								<div className="flex justify-end mt-3">
									<Button
										label="Generate AAC"
										className="!min-w-[unset] !w-fit !py-1.5 bg-blue-primary/90 hover:bg-blue-primary disabled:bg-gray-3"
										color="Primary"
										disabled={
											isError || !!errors.alias || !urlDomain || !isValidAlias
										}
										onClick={onClickGenerate}
									/>
								</div>
							</>
						)}
					</div>
					{isLoadingDeepLink || isLoadingDeepLinkInfo ? (
						<Loading />
					) : (
						<div>
							<Typography
								title="Link branch"
								type={TypographyTypes.h3}
								fontLeading="18/21"
								className="text-gray-9"
							/>
							<div className="border-2 border-blue-primary rounded-full flex justify-between pr-1 items-center pl-2.5 pb-1 mt-4">
								<Typography
									title={deepLink}
									type={TypographyTypes.span}
									fontLeading="15/15"
									className="text-gray-9 !font-normal"
								/>
								<button
									type="button"
									className="px-3 py-2"
									onClick={onCopyLink}
									disabled={!deepLink}
								>
									<Icon src={Icons.copy} fillPath className="text-gray-1" />
								</button>
							</div>
						</div>
					)}
				</>
			)}
			<ConfirmDeepLinkCreation
				open={confirmAliasOpen}
				setOpen={setConfirmAliasOpen}
				onClick={() => {
					handleSubmit(onGenerateAAC)()
				}}
				defaultAlias={defaultAlias}
			/>
		</div>
	)
}
