import { Button, Typography } from 'components'
import { LocationProps, TypographyTypes, locationState } from 'interfaces'
import React, { useEffect, useState } from 'react'
import { RadiusLocation } from '../location'

export const RadiusDelivery: React.FC<LocationProps> = ({
	totalData,
	onClickClose,
	setTotalData,
	setOpenSlide,
	deliveryActive,
	setDeliveryActive
}): JSX.Element => {
	const [delivery, setDelivery] = useState<locationState>({
		title: '',
		address: '',
		radius: 1,
		location_type: 'delivery',
		latitude: 0,
		longitude: 0
	})

	const [deliveryTitle, setDeliveryTitle] = useState('')

	const handleSubmnit = (): void => {
		setDeliveryActive({
			...deliveryActive,
			location: true
		})
		const aux = [] as locationState[]

		const newDelivery = delivery
		newDelivery.title = deliveryTitle

		if (newDelivery.address !== '') {
			aux.push(delivery)
		}
		if (delivery.address !== '') {
			if (aux.length > 0) {
				setTotalData({
					...totalData,
					delivery_enhancements: {
						...totalData.delivery_enhancements,
						location: aux
					}
				})
			}
		}
		setOpenSlide(false)
	}

	useEffect(() => {
		const dataDelivery = totalData.delivery_enhancements.location[0]

		if (dataDelivery) {
			setDelivery({
				...delivery,
				title: dataDelivery.title,
				address: dataDelivery.address,
				radius: dataDelivery.radius,
				latitude: dataDelivery.latitude,
				longitude: dataDelivery.longitude
			})
		}
	}, [])

	return (
		<div className="mt-5 flex flex-col justify-between h-full">
			<div>
				<RadiusLocation
					delivery={delivery}
					totalData={totalData}
					setDelivery={setDelivery}
					deliveryTitle={deliveryTitle}
					setDeliveryTitle={setDeliveryTitle}
				/>
				<Typography
					type={TypographyTypes.span}
					fontLeading="15/11"
					className="!font-normal text-black-1"
					title="Choose a location and radius. Your push will be delivered only to followers who enter or are within that radius."
				/>
			</div>

			<div className="flex items-center justify-between">
				<Button
					type="button"
					label="Cancel"
					color="Transparent"
					className="!min-w-[130px]"
					onClick={onClickClose}
				/>
				<Button
					label="Save"
					type="submit"
					color="Primary"
					className="!min-w-[130px]"
					onClick={handleSubmnit}
				/>
			</div>
		</div>
	)
}
