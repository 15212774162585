/* eslint-disable no-template-curly-in-string */
import { CircularProgress } from '@mui/material'
import {
	GET_EDIT_CONTENT,
	getContentById,
	getUploadFileConten,
	getUploadImageConten,
	patchContentEdit,
	postCreateContent,
	updateImage
} from 'api'
import {
	Box,
	Button,
	ContentDelivery,
	ContentSetUp,
	CreateNewContent,
	Icon,
	Layout,
	Modal,
	Typography
} from 'components'
import { useToast } from 'hooks'
import {
	BodyCreateContent,
	DeliveryActiveData,
	DocumentsData,
	DocumentsInputs,
	FilesData,
	Icons,
	ItemDataAssentsAll,
	TotalDataCreateContent,
	TypographyTypes,
	UpdateImageResponse
} from 'interfaces'
import { removeHTMLTags } from 'lib'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { getArrayFromObjectAudience, configGenaral } from 'utils'
import { v4 as uuidv4 } from 'uuid'

interface CreateContentProps {
	mode: 'create' | 'edit'
}

export const CreateContent: React.FC<CreateContentProps> = ({
	mode
}): JSX.Element => {
	const { id } = useParams()
	const cookies = new Cookies()
	const navigate = useNavigate()
	const { showToast } = useToast()
	const userId = cookies.get('userId')
	const [loading, setLoading] = useState(false)

	const [dontSend, setDontSend] = useState(false)
	const organizationId = cookies.get('organization_id')
	const [modalCancel, setModalCancel] = useState(false)
	const [publishMyPeope, setPublishMyPeope] = useState('1')
	const [defineTypeLocation, setDefineTypeLocation] = useState('1')
	const [itemRecurrence, setItemRecurrence] = useState('1')
	const [eventRecurrence, setEventRecurrence] = useState('1')
	const [itemDatePushNotofication, setItemDatePushNotofication] = useState('3')
	const [listImage, setListImage] = useState<ItemDataAssentsAll[]>([])
	const [listImagePush, setListImagePush] = useState<ItemDataAssentsAll>()
	const [documentsList, setDocumentsList] = useState<DocumentsInputs[]>([])
	const [videoId, setVideoId] = useState<string>('')
	const [valueInput, setValueInput] = useState('')
	const [openDocument, setOpenDocument] = useState(false)
	const [totalData, setTotalData] = useState<TotalDataCreateContent>({
		organization_id: organizationId,
		author_id: userId,
		slug: '',
		basic_setup: {
			category: 'contet_type',
			saving: false,
			sharing: false,
			is_pinned: false,
			preview: {
				files: [],
				video_link_external: ''
			}
		},
		set_up: {
			title: '',
			description: ''
		},
		delivery_enhancements: {
			is_perpetual: false,
			schedule: {
				timing: {
					release_date: '',
					expiration_date: ''
				},
				custom_recurrence: {
					repeating_schedule: false,
					repeat_start_date: '',
					repeat_every_type: '',
					repeat_every_value: 0,
					repeat_on: '',
					ends: {
						// after_ocurrence , on_date, never
						repeat_end_type: '',
						repeat_end_date: '',
						repeat_end_value: 0,
						after_ocurrences: 0
					}
				},
				event: {
					event_start_date: '',
					event_end_date: '',
					event_repeat_until: '',
					custom_recurrence: {
						repeating_schedule: false,
						repeat_start_date: '',
						repeat_every_type: '',
						repeat_every_value: 0,
						repeat_on: '',
						ends: {
							// after_ocurrence , on_date, never
							repeat_end_type: '',
							repeat_end_date: '',
							after_ocurrences: 0
						}
					}
				}
			},
			audience: {
				tags: [],
				lists: [],
				dynamic_lists: [],
				individuals: [],
				organizations: [],
				feeds: [],
				partner_lists: []
			},
			no_good_link: false,
			good_link: {
				link_type: '',
				link_value: '',
				link_call_action: '',
				is_sublink: false,
				sublink_type: '',
				sublink_value: '',
				sublink_call_action: ''
			},
			documents: [],
			push_notification: {
				title: '',
				message: '',
				is_message: true,
				is_title: true,
				image: '',
				timing: {
					release_date: ''
				},
				custom_recurrence: {
					repeating_schedule: false,
					repeat_start_date: '',
					// day week month year
					repeat_every_type: '',
					repeat_every_value: 0,
					repeat_on: '',
					ends: {
						// after_ocurrence , on_date, never
						repeat_end_type: '',
						repeat_end_date: '',
						after_ocurrences: 0
					}
				}
			},
			location: [],
			repeat_end_value: 0
		}
	})
	const [deliveryActive, setDeliveryActive] = useState<DeliveryActiveData>({
		shedule: false,
		goodLink: false,
		audience: false,
		documents: false,
		location: false,
		push_notification: false
	})

	const { data, isLoading, isFetching } = useQuery({
		queryKey: [GET_EDIT_CONTENT, userId, organizationId, id],
		queryFn: () => {
			setLoading(true)
			return getContentById(organizationId, id ? parseInt(id, 10) : undefined)
		},
		refetchOnWindowFocus: false,
		onSuccess: () => {
			setLoading(false)
		}
	})

	const updateDocuments = async (
		credentials: UpdateImageResponse | null,
		file: string,
		name: string
	): Promise<string> => {
		if (!credentials) return ''
		const getFile = await fetch(file)
		const fileBlob = await getFile.blob()
		const newName = name.replaceAll('#', 'nro')
		const newKey = credentials.fields.key.replace('${filename}', newName)
		const body = {
			...credentials.fields,
			file: fileBlob,
			key: newKey
		}
		await updateImage(credentials.url, body)
		const url = `${credentials.url}${newKey}`
		return url
	}

	const handleSubmit = async (status: string): Promise<Promise<void>> => {
		setLoading(true)
		const slug = uuidv4()
		const documentsUrl = [] as DocumentsData[]
		const imageUrl = [] as FilesData[]
		let imagePushUrl = undefined as FilesData | undefined
		const credentialsImage = await getUploadImageConten(slug)
		const credentialsDocuments = await getUploadFileConten(slug)
		let body = totalData as unknown as BodyCreateContent
		await Promise.all(
			listImage.map(async (elemet, index) => {
				if (elemet.asset_url) {
					if (elemet.asset_name) {
						const url = await updateDocuments(
							credentialsImage,
							elemet.asset_url,
							elemet.asset_name
						)
						imageUrl.push({
							asset_url: `${url}?size=${elemet.asset_aspect}`,
							asset_type: 'image',
							order: index
						})
					} else {
						imageUrl.push({
							asset_type: elemet.asset_type,
							asset_url: `${elemet.asset_url}`,
							order: index
						})
					}
				}
			})
		)

		if (listImagePush) {
			if (listImagePush.asset_name) {
				const url = await updateDocuments(
					credentialsImage,
					listImagePush.asset_url,
					listImagePush.asset_name
				)
				imagePushUrl = {
					asset_url: url,
					asset_type: 'image'
				}
			} else {
				imagePushUrl = {
					asset_type: listImagePush.asset_type,
					asset_url: listImagePush.asset_url
				}
			}
		}

		if (documentsList.length !== 0) {
			await Promise.all(
				documentsList.map(async elemet => {
					if (elemet.pdf) {
						const url = await updateDocuments(
							credentialsDocuments,
							elemet.pdf,
							elemet.name_file
						)
						documentsUrl.push({
							title: elemet.title,
							description: elemet.description,
							document_url: url
						})
					} else {
						documentsUrl.push({
							title: elemet.title,
							description: elemet.description,
							document_url: elemet.urlS3 as string
						})
					}
				})
			)
		}

		if (deliveryActive.shedule) {
			if (body.basic_setup.category === 'event') {
				if (itemRecurrence === '1') {
					delete body.delivery_enhancements?.schedule?.custom_recurrence
				} else {
					delete body.delivery_enhancements?.schedule?.timing
					if (
						body.delivery_enhancements?.schedule?.custom_recurrence
							?.repeat_every_type !== 'week'
					) {
						delete body.delivery_enhancements?.schedule?.custom_recurrence
							?.repeat_on
					}
					if (
						body.delivery_enhancements?.schedule?.custom_recurrence?.ends
							.repeat_end_type === 'never'
					) {
						delete body.delivery_enhancements.schedule.custom_recurrence.ends
							.after_ocurrences
						delete body.delivery_enhancements.schedule.custom_recurrence.ends
							.repeat_end_date
					} else if (
						body.delivery_enhancements?.schedule?.custom_recurrence?.ends
							.repeat_end_type === 'on_date'
					) {
						delete body.delivery_enhancements.schedule.custom_recurrence.ends
							.after_ocurrences
					} else if (
						body.delivery_enhancements?.schedule?.custom_recurrence?.ends
							.repeat_end_type === 'after_ocurrence'
					) {
						delete body.delivery_enhancements.schedule.custom_recurrence.ends
							.repeat_end_date
					}
				}
				delete body.delivery_enhancements?.schedule?.event?.custom_recurrence
				if (eventRecurrence === '1') {
					delete body.delivery_enhancements?.schedule?.event?.event_repeat_until
				}
			} else {
				delete body.delivery_enhancements?.schedule?.event
				if (itemRecurrence === '1') {
					delete body.delivery_enhancements?.schedule?.custom_recurrence
				} else {
					delete body.delivery_enhancements?.schedule?.timing
					if (
						body.delivery_enhancements?.schedule?.custom_recurrence
							?.repeat_every_type !== 'week'
					) {
						delete body.delivery_enhancements?.schedule?.custom_recurrence
							?.repeat_on
					}
					if (
						body.delivery_enhancements?.schedule?.custom_recurrence?.ends
							.repeat_end_type === 'never'
					) {
						delete body.delivery_enhancements.schedule.custom_recurrence.ends
							.after_ocurrences
						delete body.delivery_enhancements.schedule.custom_recurrence.ends
							.repeat_end_date
					} else if (
						body.delivery_enhancements?.schedule?.custom_recurrence?.ends
							.repeat_end_type === 'on_date'
					) {
						delete body.delivery_enhancements.schedule.custom_recurrence.ends
							.after_ocurrences
					} else if (
						body.delivery_enhancements?.schedule?.custom_recurrence?.ends
							.repeat_end_type === 'after_ocurrence'
					) {
						delete body.delivery_enhancements.schedule.custom_recurrence.ends
							.repeat_end_date
					}
				}
			}
		} else {
			delete body.delivery_enhancements?.schedule
			delete body.delivery_enhancements?.is_perpetual
		}

		if (deliveryActive.goodLink) {
			if (body?.delivery_enhancements?.good_link?.is_sublink === false) {
				delete body.delivery_enhancements.good_link.is_sublink
				delete body.delivery_enhancements.good_link.sublink_type
				delete body.delivery_enhancements.good_link.sublink_value
				delete body.delivery_enhancements.good_link.sublink_call_action
			}

			if (body?.delivery_enhancements?.good_link?.link_value === '') {
				delete body.delivery_enhancements.good_link
			}
		} else {
			delete body.delivery_enhancements?.good_link
			delete body.delivery_enhancements?.no_good_link
		}

		if (body?.delivery_enhancements?.documents && documentsUrl.length === 0) {
			body.delivery_enhancements.documents = []
		} else if (body?.delivery_enhancements?.documents) {
			body.delivery_enhancements.documents = documentsUrl
		}
		if (
			body.delivery_enhancements &&
			Object.keys(body.delivery_enhancements).length === 0
		) {
			delete body.delivery_enhancements
		}

		if (
			body.basic_setup.preview &&
			body.basic_setup.preview.video_link_external === ''
		) {
			delete body.basic_setup?.preview.video_link_external
		}

		if (!deliveryActive.push_notification) {
			delete body.delivery_enhancements?.push_notification
		}

		if (!deliveryActive.location) {
			delete body.delivery_enhancements?.location
		}

		if (
			deliveryActive.location &&
			body.delivery_enhancements?.location?.[0]?.location_id
		) {
			delete body.delivery_enhancements?.location?.[0].title
			delete body.delivery_enhancements?.location?.[0].address
			delete body.delivery_enhancements?.location?.[0].radius
			delete body.delivery_enhancements?.location?.[0].location_category
			delete body.delivery_enhancements?.location?.[0].is_virtual_event
			delete body.delivery_enhancements?.location?.[0].virtual_event_link
			delete body.delivery_enhancements?.location?.[0].location_type
			delete body.delivery_enhancements?.location?.[0].latitude
			delete body.delivery_enhancements?.location?.[0].longitude
		}

		body = {
			...body,
			slug,
			status,
			basic_setup: {
				...body.basic_setup,
				preview: {
					...body.basic_setup.preview,
					files: imageUrl.sort((a, b) => {
						if (a.order && b.order) {
							if (a.order > b.order) {
								return 1
							}

							if (a.order < b.order) {
								return -1
							}
						}
						return 0
					})
				}
			}
		}
		if (body?.delivery_enhancements?.push_notification) {
			body.delivery_enhancements.push_notification.image =
				imagePushUrl?.asset_url as string
			if (
				!body.delivery_enhancements.push_notification.is_title &&
				body.set_up
			) {
				body.delivery_enhancements.push_notification.title = body.set_up?.title
			}
			if (
				!body.delivery_enhancements.push_notification.is_message &&
				body.set_up
			) {
				body.delivery_enhancements.push_notification.message = removeHTMLTags(
					body.set_up.description as string
				)
			}
			if (
				!body.delivery_enhancements.push_notification.custom_recurrence
					?.repeating_schedule
			) {
				delete body.delivery_enhancements.push_notification.custom_recurrence
			}
		}
		if (
			body.delivery_enhancements &&
			body.delivery_enhancements.is_perpetual &&
			itemRecurrence === '4'
		) {
			delete body.delivery_enhancements.schedule?.timing
			delete body.delivery_enhancements.schedule?.custom_recurrence
		}
		if (
			body.delivery_enhancements &&
			body.delivery_enhancements.is_perpetual &&
			itemRecurrence !== '4'
		) {
			delete body.delivery_enhancements.schedule
		}

		if (body?.delivery_enhancements?.no_good_link) {
			delete body.delivery_enhancements.good_link
		}

		if (data !== undefined) {
			patchContentEdit(
				organizationId,
				body,
				id ? parseInt(id, 10) : undefined
			).then(response => {
				if (response?.detail) {
					setLoading(false)
					showToast(response.detail, 'Error', 'error')
				} else {
					showToast('Content Edit', 'Success', 'success')
					navigate('/content')
					setLoading(false)
				}
			})
		} else {
			try {
				const response = await postCreateContent(organizationId, body)

				if (response?.detail) {
					setLoading(false)
					showToast(response.detail, 'Error', 'error')
				} else {
					showToast('Content Created', 'Success', 'success')
					navigate('/content')
				}
			} catch (error) {
				setLoading(false)
				showToast('Error creating content', 'Error', 'error')
			}
		}
	}

	useEffect(() => {
		if (data?.detail) {
			showToast('Error', `${data.detail}`, 'error')
			navigate('/content')
		} else if (data?.organization_id) {
			const isHaveLocation = !!data?.delivery_enhancements?.locations?.length

			if (
				isHaveLocation &&
				data?.delivery_enhancements?.locations?.[0].latitude
			) {
				setDefineTypeLocation('2')
			} else {
				setDefineTypeLocation('1')
			}
			setTotalData({
				organization_id: data.organization_id,
				author_id: data.author_id,
				slug: data.slug,
				basic_setup: {
					category: data.basic_setup.category ? data.basic_setup.category : '',
					saving: data.basic_setup.saving,
					sharing: data.basic_setup.sharing,
					is_pinned: data.basic_setup.is_pinned,
					preview: {
						files: data.basic_setup.preview.files.filter(
							item => item.asset_type === 'image'
						),
						video_link_external: data.basic_setup.preview.files.find(
							item => item.asset_type === 'external_video'
						)
							? (data.basic_setup.preview.files.find(
									item => item.asset_type === 'external_video'
							  )?.asset_url as string)
							: ''
					}
				},
				set_up: {
					title: data.set_up.title,
					description: data.set_up.description
				},
				delivery_enhancements: {
					is_perpetual: data?.delivery_enhancements?.is_perpetual ?? false,
					schedule: {
						timing: {
							release_date:
								data?.delivery_enhancements?.schedule?.timing?.release_date ??
								'',
							expiration_date:
								data?.delivery_enhancements?.schedule?.timing
									?.expiration_date ?? ''
						},
						custom_recurrence: {
							repeating_schedule:
								data?.delivery_enhancements?.schedule?.custom_recurrence
									?.repeating_schedule ?? false,
							repeat_start_date:
								data?.delivery_enhancements?.schedule?.custom_recurrence
									?.repeat_start_date ?? '',
							repeat_every_type:
								data?.delivery_enhancements?.schedule?.custom_recurrence
									?.repeat_every_type ?? '',
							repeat_every_value:
								data?.delivery_enhancements?.schedule?.custom_recurrence
									?.repeat_every_value ?? 0,
							repeat_on:
								data?.delivery_enhancements?.schedule?.custom_recurrence
									?.repeat_on ?? '',
							ends: {
								repeat_end_type:
									data?.delivery_enhancements?.schedule?.custom_recurrence?.ends
										?.repeat_end_type ?? '',
								repeat_end_date:
									data?.delivery_enhancements?.schedule?.custom_recurrence?.ends
										?.repeat_end_date ?? '',
								after_ocurrences:
									data?.delivery_enhancements?.schedule?.custom_recurrence?.ends
										?.after_ocurrences ?? 0,

								repeat_end_value:
									data?.delivery_enhancements?.schedule?.custom_recurrence?.ends
										?.after_ocurrences ?? 0
							}
						},
						event: {
							event_start_date:
								data?.delivery_enhancements?.schedule?.event
									?.event_start_date ?? '',
							event_end_date:
								data?.delivery_enhancements?.schedule?.event?.event_end_date ??
								'',
							event_repeat_until:
								data?.delivery_enhancements?.schedule?.event
									?.event_repeat_until ?? '',
							custom_recurrence: {
								repeating_schedule:
									data?.delivery_enhancements?.schedule?.event
										?.custom_recurrence?.event_repeating_schedule ?? false,
								repeat_start_date:
									data?.delivery_enhancements?.schedule?.event
										?.custom_recurrence?.event_repeat_start_date ?? '',
								repeat_every_type:
									data?.delivery_enhancements?.schedule?.event
										?.custom_recurrence?.event_repeat_every_type ?? '',
								repeat_every_value:
									data?.delivery_enhancements?.schedule?.event
										?.custom_recurrence?.event_repeat_every_value ?? 0,
								repeat_on:
									data?.delivery_enhancements?.schedule?.event
										?.custom_recurrence?.event_repeat_on ?? '',
								ends: {
									repeat_end_type:
										data?.delivery_enhancements?.schedule?.event
											?.custom_recurrence?.ends?.event_repeat_end_type ?? '',
									repeat_end_date:
										data?.delivery_enhancements?.schedule?.event
											?.custom_recurrence?.ends.event_repeat_end_date ?? '',
									after_ocurrences:
										data?.delivery_enhancements?.schedule?.event
											?.custom_recurrence?.ends?.event_after_ocurrences ?? 0
								}
							}
						}
					},
					audience: {
						tags: getArrayFromObjectAudience(
							data?.delivery_enhancements?.audience?.tags
						),
						lists: getArrayFromObjectAudience(
							data?.delivery_enhancements?.audience?.lists
						),
						dynamic_lists: getArrayFromObjectAudience(
							data?.delivery_enhancements?.audience?.dynamic_list
						),
						individuals: getArrayFromObjectAudience(
							data?.delivery_enhancements?.audience?.individuals
						),
						organizations: getArrayFromObjectAudience(
							data?.delivery_enhancements?.audience?.organizations
						),
						feeds: getArrayFromObjectAudience(
							data?.delivery_enhancements?.audience?.feeds
						),
						partner_lists: getArrayFromObjectAudience(
							data?.delivery_enhancements?.audience?.partner_lists
						)
					},
					good_link: {
						link_type: data?.delivery_enhancements?.good_link?.link_type ?? '',
						link_value:
							data?.delivery_enhancements?.good_link?.link_value ?? '',
						link_call_action:
							data?.delivery_enhancements?.good_link?.link_call_action ?? '',
						is_sublink:
							data?.delivery_enhancements?.good_link?.is_sublink ?? false,
						sublink_type:
							data?.delivery_enhancements?.good_link?.sublink_type ?? '',
						sublink_value:
							data?.delivery_enhancements?.good_link?.sublink_value ?? '',
						sublink_call_action:
							data?.delivery_enhancements?.good_link?.sublink_call_action ?? ''
					},
					no_good_link: data?.delivery_enhancements?.no_good_link ?? false,
					location: data?.delivery_enhancements?.locations?.length
						? data?.delivery_enhancements?.locations
						: [],
					repeat_end_value: data.delivery_enhancements?.repeat_end_value ?? 0,
					push_notification: {
						title: data.delivery_enhancements?.push_notification?.title ?? '',
						message:
							data.delivery_enhancements?.push_notification?.message ?? '',
						is_message:
							data.delivery_enhancements?.push_notification?.is_message ||
							false,
						is_title:
							data.delivery_enhancements?.push_notification?.is_title || false,
						image: data.delivery_enhancements?.push_notification?.image ?? '',
						timing: {
							release_date:
								data.delivery_enhancements?.push_notification?.timing
									?.release_date ?? ''
						},
						custom_recurrence: {
							repeat_every_type:
								data.delivery_enhancements?.push_notification?.custom_recurrence
									?.repeat_every_type ?? '',
							repeat_every_value:
								data.delivery_enhancements?.push_notification?.custom_recurrence
									?.repeat_every_value || 1,
							repeat_on:
								data.delivery_enhancements?.push_notification?.custom_recurrence
									?.repeat_on || '',
							repeat_start_date:
								data.delivery_enhancements?.push_notification?.custom_recurrence
									?.repeat_start_date || '',
							repeating_schedule:
								data.delivery_enhancements?.push_notification?.custom_recurrence
									?.repeating_schedule || false,
							ends: {
								after_ocurrences:
									data.delivery_enhancements?.push_notification
										?.custom_recurrence?.ends?.after_ocurrences || 1,

								repeat_end_date:
									data.delivery_enhancements?.push_notification
										?.custom_recurrence?.ends?.repeat_end_date ?? '',
								repeat_end_type:
									data.delivery_enhancements?.push_notification
										?.custom_recurrence?.ends?.repeat_end_type ?? ''
							}
						}
					},
					documents: data?.delivery_enhancements?.documents?.length
						? data.delivery_enhancements.documents
						: []
				}
			})
			if (data?.delivery_enhancements?.documents) {
				const newDocumentsList:
					| React.SetStateAction<DocumentsInputs[]>
					| DocumentsInputs[] = []
				data.delivery_enhancements.documents.forEach(item => {
					newDocumentsList.push({
						description: item.description,
						name_file: item.document_url.split('documents/')[1],
						size: 0,
						title: item.title,
						urlS3: item.document_url
					})
				})
				setDocumentsList(newDocumentsList)
			}
			if (
				data?.delivery_enhancements?.audience &&
				(data.delivery_enhancements.audience.lists ||
					data.delivery_enhancements.audience.tags ||
					data.delivery_enhancements.audience.dynamic_list ||
					data.delivery_enhancements.audience.individuals ||
					data.delivery_enhancements.audience.organizations ||
					data.delivery_enhancements.audience.feeds ||
					data.delivery_enhancements.audience.partner_lists)
			) {
				setPublishMyPeope('2')
			} else {
				setPublishMyPeope('1')
			}
		}
	}, [data])

	const hasDescriptionValid =
		totalData.set_up.description &&
		totalData.set_up.description.length > 0 &&
		totalData.set_up.description.length <= configGenaral.maxDescriptionLength

	useEffect(() => {
		if (totalData.set_up.title.length > 0 && hasDescriptionValid) {
			setDontSend(true)
		} else {
			setDontSend(false)
		}
	}, [totalData])

	const emptyAssets =
		listImage.length === 0 &&
		!documentsList.length &&
		valueInput === '' &&
		videoId === ''

	return (
		<Layout>
			{loading || isLoading ? (
				<div className="flex items-center justify-center w-full h-[400px] py-2 px-1 ">
					<CircularProgress disableShrink />
				</div>
			) : (
				<div>
					<Modal
						closeButton
						type="normal"
						open={modalCancel}
						setOpen={setModalCancel}
					>
						<div className="flex flex-col items-center gap-2">
							<Icon
								fillPath
								src={Icons.warning}
								className="w-10 text-blue-primary"
							/>
							<Typography
								type={TypographyTypes.span}
								fontLeading="15/18"
								className="!font-normal text-black-1"
								title="Are you sure you want to exit?"
							/>
							<Button
								type="button"
								label="Confirm"
								color="Primary"
								className="!min-w-[130px]"
								onClick={() => navigate('/content')}
							/>
						</div>
					</Modal>
					<div className="flex flex-col sm:flex-col 3md:flex-col lg:flex-row mt-3 w-full justify-between  3xl:mx-auto 3xl:my-auto 3xl:w-3/4 gap-3">
						<Box className="flex flex-col h-full lg:!w-[65%]">
							<CreateNewContent
								mode={mode === 'create' ? 'create' : 'edit'}
								listImage={listImage}
								totalData={totalData}
								setListImage={setListImage}
								setTotalData={setTotalData}
								videoId={videoId}
								setVideoId={setVideoId}
								setValueInput={setValueInput}
								valueInput={valueInput}
								isFetching={isFetching}
								setOpenDocument={setOpenDocument}
								documentsList={documentsList}
							/>
						</Box>
						<div className="flex flex-col w-full 3md:w-full sm:mt-2 md:mt-0 lg:!w-[35%] lg:!min-w-[35%]">
							<Box className="w-full">
								<ContentSetUp
									totalData={totalData}
									setDontSend={setDontSend}
									setTotalData={setTotalData}
								/>
							</Box>
							<Box className="mt-2">
								<ContentDelivery
									totalData={totalData}
									setTotalData={setTotalData}
									documentsList={documentsList}
									listImagePush={listImagePush}
									itemRecurrence={itemRecurrence}
									deliveryActive={deliveryActive}
									publishMyPeope={publishMyPeope}
									eventRecurrence={eventRecurrence}
									setDocumentsList={setDocumentsList}
									setListImagePush={setListImagePush}
									setDeliveryActive={setDeliveryActive}
									setItemRecurrence={setItemRecurrence}
									setPublishMyPeope={setPublishMyPeope}
									setEventRecurrence={setEventRecurrence}
									itemDatePushNotofication={itemDatePushNotofication}
									setItemDatePushNotofication={setItemDatePushNotofication}
									isLoading={isFetching}
									mode={mode === 'create' ? 'create' : 'edit'}
									defineTypeLocation={defineTypeLocation}
									setDefineTypeLocation={setDefineTypeLocation}
									openDocument={openDocument}
									setOpenDocument={setOpenDocument}
								/>
							</Box>
						</div>
					</div>
					<div className="w-full flex flex-col sm:flex-row justify-end gap-4 mt-4 3xl:mx-auto 3xl:my-auto 3xl:w-3/4 3xl:mt-3">
						<Button
							type="button"
							label="Cancel"
							color="Gray3"
							className="!min-w-[130px]"
							onClick={() => setModalCancel(true)}
						/>
						<Button
							type="button"
							label="Save Draft"
							color="white"
							className="!min-w-[130px]"
							disabled={
								totalData.basic_setup.category === 'contet_type' || !dontSend
							}
							onClick={() => handleSubmit('draft')}
						/>
						<Button
							type="button"
							label={mode === 'create' ? 'Publish' : 'Save Changes'}
							color="Primary"
							className="!min-w-[130px]"
							disabled={
								totalData.basic_setup.category === 'contet_type' ||
								!dontSend ||
								emptyAssets
							}
							onClick={() => handleSubmit('published')}
						/>
					</div>
				</div>
			)}
		</Layout>
	)
}
