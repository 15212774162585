export interface FilesData {
	asset_url: string
	asset_type: string
	order?: number
}

export interface DocumentsData {
	title: string
	description: string
	document_url: string
}

export interface TotalDataCreateContent {
	organization_id: number
	author_id: number | null
	slug: string
	basic_setup: {
		// info event take_action notification
		category: string
		sharing: boolean
		saving: boolean
		is_pinned: boolean
		preview?: {
			files: FilesData[]
			video_link_external: string
			croppedImage?: string | null
		}
	}
	set_up: {
		title: string
		description?: string
		message?: string
		link_to?: string
		image?: string
		cta_Type?: string
		cta_latitude?: number
		cta_longitude?: number
	}
	delivery_enhancements: {
		is_perpetual?: boolean
		schedule: {
			timing: {
				release_date: string
				expiration_date: string
			}
			custom_recurrence: {
				repeating_schedule: boolean
				repeat_start_date: string
				// day week month year
				repeat_every_type: string
				repeat_every_value: number
				repeat_on: string
				ends: {
					// after_ocurrence , on_date, never
					repeat_end_value: number
					repeat_end_type: string
					repeat_end_date: string
					after_ocurrences: number
				}
			}
			event: {
				event_start_date: string
				event_end_date: string
				event_repeat_until: string
				custom_recurrence: {
					repeating_schedule: boolean
					repeat_start_date: string
					repeat_every_type: string
					repeat_every_value: number
					repeat_on: string
					ends: {
						// after_ocurrence , on_date, never
						repeat_end_type: string
						repeat_end_date?: string
						after_ocurrences?: number
					}
				}
			}
		}
		audience: {
			tags: number[]
			lists: number[]
			dynamic_lists: number[]
			individuals: number[]
			organizations: number[]
			feeds: number[]
			partner_lists: number[]
		}
		no_good_link?: boolean | undefined
		good_link: {
			link_type: string
			link_value: string
			link_call_action: string
			is_sublink: boolean
			sublink_type: string
			sublink_value: string
			sublink_call_action: string
		}
		documents: DocumentsData[]
		push_notification: {
			title: string
			message: string
			is_title: boolean
			is_message: boolean
			image: string
			timing: {
				release_date: string | null
			}
			custom_recurrence: {
				repeating_schedule: boolean
				repeat_start_date: string | null
				// day week month year
				repeat_every_type: string | null
				repeat_every_value: number
				repeat_on: string
				ends: {
					// after_ocurrence , on_date, never
					repeat_end_type: string
					repeat_end_date: string | null
					after_ocurrences: number
				}
			}
		}
		location: locationState[]
		repeat_end_value: number
	}
}

export interface BodyCreateContent {
	organization_id: number
	author_id: number
	slug: string
	status: string
	basic_setup: {
		// info event take_action notification
		category: string
		sharing: boolean
		saving: boolean
		is_pinned: boolean
		preview?: {
			files: FilesData[]
			video_link_external?: string
		}
	}
	set_up?: {
		title: string
		description?: string
		message?: string
		link_to?: string
		image?: string
	}
	delivery_enhancements?: {
		is_perpetual?: boolean
		schedule?: {
			timing?: {
				release_date: string
				expiration_date: string
			}
			custom_recurrence?: {
				repeating_schedule: boolean
				repeat_start_date: string
				// day week month year
				repeat_every_type: string
				repeat_every_value: number
				repeat_on?: string
				ends: {
					// after_ocurrence , on_date, never
					repeat_end_type: string
					repeat_end_date?: string | null
					after_ocurrences?: number | null
				}
			}
			event?: {
				event_start_date?: string
				event_end_date?: string
				event_repeat_until?: string
				custom_recurrence?: {
					repeating_schedule: boolean
					repeat_start_date: string
					repeat_every_type: string
					repeat_every_value: number
					repeat_on?: string
					ends: {
						// after_ocurrence , on_date, never
						repeat_end_type: string
						repeat_end_date?: string | null
						after_ocurrences?: number | null
					}
				}
			}
		}
		audience?: {
			tags: number[]
			lists: number[]
			dynamic_lists: number[]
			individuals: number[]
			organizations: number[]
			feeds: number[]
			partner_lists: number[]
		}
		no_good_link?: boolean
		good_link?: {
			link_type: string
			link_value: string
			link_call_action: string
			is_sublink?: boolean
			sublink_type?: string
			sublink_value?: string
			sublink_call_action?: string
		}
		documents?: DocumentsData[]
		location?: locationState[]
		push_notification?: {
			title: string
			message: string
			is_title: boolean
			is_message: boolean
			image: string
			timing?: {
				release_date: string
			}
			custom_recurrence?: {
				repeating_schedule: boolean
				repeat_start_date: string
				// day week month year
				repeat_every_type: string
				repeat_every_value: number
				repeat_on: string
				ends?: {
					// after_ocurrence , on_date, never
					repeat_end_type: string
					repeat_end_date: string
					after_ocurrences: number
				}
			}
		}
	}
}

export interface BodySharedContent {
	organization_partner_id?: number
	content_id?: number
	status?: string
	message?: string
	organization_partner_ids?: number[]
	is_approved?: boolean
}

export interface BodySharedContentCopy {
	organization_partner_id?: number
	content_id?: number
	is_approved?: boolean
	message?: string
	status?: string
}
export interface PostCreateNotification {
	title: string | undefined
	author_id: number | null
	slug?: string
	description: string | undefined
	custom_image_url: string
	template_notification_id?: string | null
	status: string
	release_date: string | undefined
	expiration_date: string | undefined
	repeating_schedule: boolean | undefined
	repeat_start_date: string | undefined
	repeat_every_type: string | undefined
	repeat_every_value: number | undefined
	repeat_on: string | undefined
	repeat_end_type: string | undefined
	repeat_end_date: string | null | undefined
	after_ocurrences: number | null | undefined
	audience?: {
		tags: number[]
		lists: number[]
		dynamic_lists: number[]
		individuals: number[]
		organizations: number[]
		feeds: number[]
	}
	location: {
		title?: string
		address?: string
		radius?: number
		location_category?: string
		is_virtual_event?: boolean
		virtual_event_link?: string
		location_type?: string
		latitude?: number
		longitude?: number
	}
	cta_type?: string
	cta_value?: string
}

export interface locationState {
	title?: string
	address?: string
	radius?: number
	location_category?: string
	is_virtual_event?: boolean
	virtual_event_link?: string
	location_type?: string
	latitude?: number
	longitude?: number
	location_id?: number
}

export interface UserData {
	email: string | null
	fullName: string | null
	userId: number | null
	organization_id: number | null
	organization_name: string | null
	organization_slug: string | null
	roles: string[] | null
	token: string | null
}

export interface Reach {
	tags: number[]
	lists: number[]
	dynamic_lists: number[]
	individuals: number[]
	organizations: number[]
	feeds: number[]
	partner_lists: number[]
	organization_id: string
}

export interface DeliveryActiveData {
	shedule: boolean
	goodLink?: boolean
	audience: boolean
	documents?: boolean
	location?: boolean
	push_notification?: boolean
}
export interface DeliveryActivePush {
	shedule: boolean
	audience: boolean
	documents: boolean
	location: boolean
	push_notification: boolean
}

export interface LocationState {
	center: {
		lat: number
		lng: number
	}
	address: string
	zoom: number
}

export interface BodyCreateLocation {
	author_id: number | undefined
	location_list_id?: number
	setup: Setup
	status?: string
	coordinates: Coordinates
	assets: Assets
	documents: DocumentS[]
}

export interface Assets {
	files: File[]
	video_link_external: string | undefined
}

export interface File {
	asset_type: string | undefined
	asset_url: string | undefined
}

export interface Coordinates {
	latitude: number | undefined
	longitude: number | undefined
	address: string | undefined
}

export interface DocumentS {
	title: string | undefined
	description: string | undefined
	document_url: string | undefined
}

export interface Setup {
	title: string | undefined
	description: string | undefined
}

export interface CreateLocationResponse {
	detail?: string
	organization_id: number
	title: string
	description: string
	latitude: number
	longitude: number
	address: string
	author_id: number
	is_pinned: boolean
	created_at: Date
	updated_at: Date
	id: number
}

export type moduleCreate = 'contents' | 'locations'

export interface LocationListResponse {
	items: ItemLocations[]
	total: number
	page: number
	size: number
	pages: number
}

export interface ItemLocations {
	id: number
	title: string
	address: string
	created_at: string
	location_list_id: number | null
	location_list_name: string | null
	total_location: number
	is_pinned: boolean
	total_content?: number
}

export interface LocationByIDResponse {
	detail?: string
	id: number
	organization_id: number
	author_id: number
	location_list_id: string | undefined | null
	assets: AssetById[]
	set_up: SetUpById
	coordinates: CoordinatesById
	documents: DocumentsData[]
}

export interface AssetById {
	id: number
	location_id: number
	asset_type: string
	asset_url: string
}

export interface CoordinatesById {
	latitude: number
	longitude: number
	address: string
}

export interface SetUpById {
	title: string
	description: string
}

export interface ItemSelect {
	label: string
	value: number
}
export interface InfoToModalConfirm {
	icon: string
	title: string
	descripcion: string
}
export interface StateSelection {
	at_least_one_tag: boolean
	tag_multiselect: boolean
}

export enum OrgStatus {
	lite = 'lite',
	pro = 'pro',
	elite = 'elite'
}
