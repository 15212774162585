import { Box } from 'components/boxs'
import { Icon } from 'components/icon'
import { Typography } from 'components/typography'
import { Icons, OrganizationCardProps, TypographyTypes } from 'interfaces'
import React from 'react'

const OrganizationCardIndicator: React.FC<{
	label: string
	value: string | null
}> = ({ label, value }) => {
	return (
		<div className="flex py-2 justify-between">
			<Typography
				className="whitespace-nowrap !font-normal"
				title={label}
				type={TypographyTypes.span}
				fontLeading="15/18"
			/>
			{value ? (
				<Typography
					className="text-blue-primary whitespace-nowrap !font-normal"
					title={value}
					type={TypographyTypes.span}
					fontLeading="15/18"
				/>
			) : (
				<Icon className="w-4 h-4 p-[1px]" src={Icons.error} />
			)}
		</div>
	)
}

export const OrganizationCard: React.FC<OrganizationCardProps> = props => {
	const { content, logo, lastActive, users, name } = props

	return (
		<Box className="flex flex-col items-stretch justify-center !pt-1 !p-0 min-w-[173px] !border-0 shadow-md">
			<>
				<img
					className="mx-auto h-10 w-10 rounded-lg object-scale-down"
					src={logo}
					alt={`${name} logo`}
				/>
				<Typography
					className="!font-normal text-[#0A1034] text-center my-auto mx-auto px-3 max-w-[250px]"
					title={name}
					type={TypographyTypes.span}
					fontLeading="15/18"
				/>
				<div className="flex w-full flex-col px-3">
					<OrganizationCardIndicator label="Last active" value={lastActive} />
					<hr />
					<OrganizationCardIndicator
						label="Content"
						value={content ? Intl.NumberFormat('en-EU').format(content) : null}
					/>
					<hr />
					<OrganizationCardIndicator
						label="Users"
						value={users ? Intl.NumberFormat('en-EU').format(users) : null}
					/>
				</div>
			</>
		</Box>
	)
}
