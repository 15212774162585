export const GET_NETWORK_PARTNERS_TABLE = 'GET_NETWORK_PARTNERS_TABLE'
export const GET_NETWORK_FEEDS_TABLE = 'GET_NETWORK_FEEDS_TABLE'
export const GET_NETWORK_SUBSCRIPTION_TABLE = 'GET_NETWORK_SUBSCRIPTION_TABLE'
export const GET_NETWORK_PARTNERS_FEED_TABLE = 'GET_NETWORK_PARTNERS_FEED_TABLE'
export const GET_NEW_OUTBOUND_PARTNER_TABLE = 'GET_NEW_OUTBOUND_PARTNER_TABLE'
export const GET_NEW_OUTBOUND_SUBSCRIPTION_TABLE =
	'GET_NEW_OUTBOUND_SUBSCRIPTION_TABLE'
export const GET_NETWORK_MESSAGES = 'GET_NETWORK_MESSAGES'
export const GET_FM_PARTNER_LIST_FOLLOWERS_PARTNERS =
	'GET_FM_PARTNER_LIST_FOLLOWERS_PARTNERS'
export const GET_FM_PARTNER_LIST_FOLLOWERS_FOLLOWERS =
	'GET_FM_PARTNER_LIST_FOLLOWERS_FOLLOWERS'
export const GET_USER_INFO = 'GET_USER_INFO'
export const GET_DASHBOARD_PERFORMANCE = 'GET_DASHBOARD_PERFORMANCE'
export const GET_DASHBOARD_FOLLOWERS = 'GET_DASHBOARD_FOLLOWERS'
export const GET_DASHBOARD_PROMOTION = 'GET_DASHBOARD_PROMOTION'
export const GET_DASHBOARD_NEW_TAGS = 'GET_DASHBOARD_NEW_TAGS'
export const GET_DASHBOARD_MY_PEOPLE = 'GET_DASHBOARD_MY_PEOPLE'
export const GET_DASHBOARD_CONTENT_HIGHLIGHT_ITEM =
	'GET_DASHBOARD_CONTENT_HIGHLIGHT_ITEM'
export const GET_DASHBOARD_NOTIFICATIONS = 'GET_DASHBOARD_NOTIFICATIONS'
export const GET_DASHBOARD_BUILD_APPS = 'GET_DASHBOARD_BUILD_APPS'
export const GET_PEOPLE_MYPEOPLE_TABLE = 'GET_PEOPLE_MYPEOPLE_TABLE'
export const GET_PEOPLE_EXPORT = 'GET_PEOPLE_EXPORT'
export const GET_USER_ROLES = 'GET_USER_ROLES'
export const VALIDATE_USER = 'VALIDATE_USER'
export const GET_PEOPLE_MYLISTS_TABLE = 'GET_PEOPLE_MYLISTS_TABLE'
export const GET_TAGS_TABLE = 'GET_TAGS_TABLE'
export const GET_PEOPLE_TAGS_TABLE = 'GET_PEOPLE_TAGS_TABLE'
export const GET_PEOPLE_SUBTAGS_TABLE = 'GET_PEOPLE_SUBTAGS_TABLE'
export const GET_PEOPLE_TOTAL_FOLLOWERS = 'GET_PEOPLE_TOTAL_FOLLOWERS'
export const GET_PEOPLE_USERS_PLATFORM = 'GET_PEOPLE_USERS_PLATFORM'
export const GET_PEOPLE_EDIT_STATIC_LIST_OPTIONS =
	'GET_PEOPLE_EDIT_STATIC_LIST_OPTIONS'
export const GET_PEOPLE_EDIT_DYNAMIC_LIST_OPTIONS =
	'GET_PEOPLE_EDIT_DYNAMIC_LIST_OPTIONS'
export const GET_PEOPLE_TAGS_ORG = 'GET_PEOPLE_TAGS_ORG'
export const GET_PEOPLE_TAGS_ORG_EXCLUDE = 'GET_PEOPLE_TAGS_ORG_EXCLUDE'
export const GET_TAGS_APPROVAL_TABLE = 'GET_TAGS_APPROVAL_TABLE'
export const GET_MY_ORGANIZATION = 'GET_MY_ORGANIZATION'
export const GET_PEOPLE_SETTINGS = 'GET_PEOPLE_SETTINGS'
export const GET_CONTENT_PERFORMANCE = 'GET_CONTENT_PERFORMANCE'
export const GET_COMMUNITY_BUILD_APPS = 'GET_COMMUNITY_BUILD_APPS'
export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS'
export const GET_COMMUNITY_APP_VERSIONS = 'GET_COMMUNITY_APP_VERSIONS'
export const GET_BUILD_APP = 'GET_BUILD_APP'
export const GET_ASSETS_ALL_ORGANIZATIONS = 'GET_ASSETS_ALL_ORGANIZATIONS'
export const GET_CONTENTS = 'GET_CONTENTS'
export const GET_CONTENTS_PINNED = 'GET_CONTENTS_PINNED'

export const GET_CONTENTS_TABLET = 'GET_CONTENTS_TABLET'

export const GET_USER_TAGS = 'GET_USER_TAGS'
export const GET_EDIT_CONTENT = 'GET_EDIT_CONTENT'
export const GET_ORGANIZATION_BY_USER = 'GET_ORGANIZATION_BY_USER'
export const ORGANIZATION_DATA_EDIT = 'ORGANIZATION_DATA_EDIT'
export const ADMIN_DATA_EDIT = 'ADMIN_DATA_EDIT'
export const APP_BUILD_DATA_EDIT = 'APP_BUILD_DATA_EDIT'
export const GET_STATIC_LIST = 'GET_STATIC_LIST'
export const GET_NOTIFICATION = 'GET_NOTIFICATION'
export const GET_DETAIL_CONTENT = 'GET_DETAIL_CONTENT'
export const GET_DETAIL_NOTIFICATION = 'GET_DETAIL_NOTIFICATION'
export const GET_MY_FEEDS_TABLE = 'GET_MY_FEEDS_TABLE'
export const GET_MY_FEED = 'GET_MY_FEED'
export const GET_LOCATIONS = 'GET_LOCATIONS'
export const GET_EDIT_LOCATION = 'GET_EDIT_LOCATION'
export const GET_LIST_LOCATIONS_FOLLOWERS = 'GET_LIST_LOCATIONS_FOLLOWERS'
export const GET_LIST_LOCATIONS_BY_ID = 'GET_LIST_LOCATIONS_BY_ID'
export const GET_DINAMYC_LIST = 'GET_DINAMYC_LIST'
