import clsx from 'clsx'
import {
	Button,
	Icon,
	ImageCropSettings,
	Input,
	Message,
	Typography
} from 'components'
import { useDebounceEffect, useToast } from 'hooks'
import {
	Icons,
	ImageSlideProps,
	SlideImageFormInputs,
	TypographyTypes
} from 'interfaces'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
	Crop,
	PixelCrop,
	ReactCrop,
	centerCrop,
	convertToPixelCrop,
	makeAspectCrop
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import {
	canvasPreview,
	configGenaral,
	generateTransform,
	regexVimeoOrYoutube
} from 'utils'

export const Image: React.FC<ImageSlideProps> = ({
	totalData,
	listImage,
	setTotalData,
	setOpenSlide,
	setListImage,
	setSlideImages,
	setSlideLibraryImages,
	mode,
	isCropSettingsVisible,
	setIsCropSettingsVisible,
	setSendImageCropped,
	sendImageCropped,
	setIsFinishCropButtonVisible,
	isFinishCropButtonVisible,
	setIsCropComplete,
	isCropComplete,
	setVideoId,
	setValueInput,
	valueInput
}): JSX.Element => {
	const { showToast } = useToast()
	const [disableMultiple, setDisableMultiple] = useState(false)
	const previewCanvasRef = useRef<HTMLCanvasElement | null>(null)
	const imgRef = useRef<HTMLImageElement>(null)
	const [crop, setCrop] = useState<Crop | null>()
	const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>()
	const [scale, setScale] = useState(1)
	const [rotate, setRotate] = useState(0)
	const [flipVertical, setFlipVertical] = useState(false)
	const [flipHorizontal, setFlipHorizontal] = useState(false)
	const [aspectString, setAspectString] = useState<string | undefined>()
	const [isActiveFinish, setIsActiveFinish] = useState(false)
	const [isCropped, setIsCropped] = useState(true)
	const [valueInputUrlVideo, setValueInputUrlVideo] = useState('')

	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors }
	} = useForm<SlideImageFormInputs>({
		mode: 'all'
	})

	const rules = {
		url: {
			maxLength: { value: 150, message: 'Maximum characters exceeded' },
			pattern: {
				value: regexVimeoOrYoutube,
				message: 'Invalid link'
			}
		}
	}

	useEffect(() => {
		if (
			valueInputUrlVideo &&
			setVideoId &&
			regexVimeoOrYoutube.test(valueInputUrlVideo)
		) {
			setVideoId(valueInputUrlVideo)
		} else {
			setVideoId?.('')
		}
	}, [valueInputUrlVideo])

	useEffect(() => {
		if (valueInput && setValueInput && regexVimeoOrYoutube.test(valueInput)) {
			setValueInput(valueInput)
		}
	}, [valueInput])

	const isAssetCropped =
		listImage[listImage.length - 1]?.asset_url?.includes('.amazonaws.com/')

	useEffect(() => {
		if (listImage.length >= 5) {
			setDisableMultiple(true)
		} else {
			setDisableMultiple(false)
		}
	}, [listImage])

	const registerInputFile = register('image')

	const resetCropState = (): void => {
		setCrop(null)
		setCompletedCrop(null)
		setIsCropSettingsVisible?.(true)
		setIsFinishCropButtonVisible(true)
		setIsCropComplete(false)
		setScale(1)
		setRotate(0)
		setFlipVertical(false)
		setFlipHorizontal(false)
		setIsActiveFinish(false)
	}

	const handleFinishCrop = (): void => {
		if (completedCrop && previewCanvasRef.current) {
			const canvas = previewCanvasRef.current
			const resizedCanvas = document.createElement('canvas')
			const resizedCtx = resizedCanvas.getContext('2d')

			if (aspectString === '1/1') {
				resizedCanvas.width = 1080
				resizedCanvas.height = 1080
				if (resizedCtx !== null) {
					resizedCtx.imageSmoothingEnabled = false
					resizedCtx.drawImage(canvas, 0, 0, 1080, 1080)
				}
			} else if (aspectString === '1/1.2941') {
				resizedCanvas.width = 1080
				resizedCanvas.height = 1398
				if (resizedCtx !== null) {
					resizedCtx.imageSmoothingEnabled = false
					resizedCtx.drawImage(canvas, 0, 0, 1080, 1398)
				}
			} else {
				resizedCanvas.width = 1920
				resizedCanvas.height = 1080
				if (resizedCtx !== null) {
					resizedCtx.imageSmoothingEnabled = false
					resizedCtx.drawImage(canvas, 0, 0, 1920, 1080)
				}
			}

			const resizedImageData = resizedCanvas.toDataURL('image/png')

			const updatedListImage = [...listImage]
			updatedListImage[updatedListImage.length - 1].asset_url = resizedImageData

			const newImage = {
				asset_type: 'image',
				asset_url: resizedImageData,
				asset_name: `${moment().format('YYYYMMDDHHmmss')}-image`,
				asset_aspect: aspectString
			}

			setSendImageCropped(prevList => [...prevList, newImage])
			setIsFinishCropButtonVisible(false)
			setIsCropSettingsVisible?.(false)
			setIsCropComplete(true)
			setIsCropped(true)
			setRotate(0)
			setScale(1)
		}
	}

	const handleUploadImage = (file: File): void => {
		const fileSize = file.size
		const fileMb = fileSize / 1024 ** 2
		if (fileMb < configGenaral.maxfileSizeMB) {
			const render = new FileReader()
			render.onload = e => {
				const uploadedObj = { file, url: e.target?.result as string }
				setListImage([
					...listImage,
					{
						asset_type: 'image',
						asset_url: uploadedObj.url,
						asset_name: `${moment().format('YYYYMMDDHHmmss')}-image`
					}
				])
				setAspectString(undefined)
			}
			render.readAsDataURL(file)
		} else {
			showToast(
				'Error',
				`Please select a file less than ${configGenaral.maxfileSizeMB}MB.`,
				'error'
			)
			setIsCropped(true)
		}
		resetCropState()
	}

	useEffect(() => {
		if (isAssetCropped) {
			setIsCropComplete(true)
		}
	}, [isAssetCropped])

	useEffect(() => {
		if (valueInputUrlVideo) {
			setIsCropComplete(true)
		}
	}, [valueInputUrlVideo])

	useDebounceEffect(
		() => {
			const generateCanvasPreview = async (): Promise<void> => {
				if (
					completedCrop?.width &&
					completedCrop?.height &&
					imgRef.current &&
					previewCanvasRef.current
				) {
					await canvasPreview(
						imgRef.current,
						previewCanvasRef.current,
						completedCrop,
						scale,
						rotate
						// flipVertical,
						// flipHorizontal
					)
				}
			}

			generateCanvasPreview()
		},
		500,
		[completedCrop, scale, rotate, flipVertical, flipHorizontal]
	)

	const handleDeleteImage = (id: number): void => {
		const newListImage = listImage
		newListImage.splice(id, 1)
		setListImage([...newListImage])
		setSendImageCropped([...newListImage])
		setIsCropped(true)
		setAspectString(undefined)
		resetCropState()
		const input = document.getElementById('image') as HTMLInputElement
		if (input) {
			input.value = ''
		}
	}

	const onSubmit: SubmitHandler<SlideImageFormInputs> = async () => {
		if (
			sendImageCropped !== undefined &&
			sendImageCropped !== null &&
			sendImageCropped.length > 0
		) {
			setTotalData({
				...totalData,
				basic_setup: {
					...totalData.basic_setup,
					preview: {
						...totalData.basic_setup.preview,
						files: sendImageCropped,
						video_link_external: valueInputUrlVideo || (valueInput as string)
					}
				}
			})
			setOpenSlide(false)
			setSlideImages(false)
		} else {
			setTotalData({
				...totalData,
				basic_setup: {
					...totalData.basic_setup,
					preview: {
						...totalData.basic_setup.preview,
						files: [],
						video_link_external: valueInputUrlVideo || (valueInput as string)
					}
				}
			})
			setOpenSlide(false)
			setSlideImages(false)
		}
	}

	const centerAspectCrop = (
		mediaWidth: number,
		mediaHeight: number,
		aspectt: number
	): Crop => {
		return centerCrop(
			makeAspectCrop(
				{
					unit: '%',
					width: 90
				},
				aspectt,
				mediaWidth,
				mediaHeight
			),
			mediaWidth,
			mediaHeight
		)
	}

	const handleToggleAspectClick = (
		aspect: number,
		aspectStringg: string
	): void => {
		setAspectString(aspectStringg)
		if (imgRef.current) {
			const { width, height } = imgRef.current
			const newCrop = centerAspectCrop(width, height, aspect)

			setCrop(newCrop)
			// Updates the preview
			setCompletedCrop(convertToPixelCrop(newCrop, width, height))
		}
	}

	const handleControlZoom = (): void => {
		if (completedCrop) {
			if (scale <= 0.5) {
				setScale(0.5)
			}
		}
	}

	const resetCrop = (): void => {
		setCrop(null)
		setCompletedCrop(null)
	}

	useEffect(() => {
		if (totalData?.basic_setup?.preview) {
			const videoLinkExternal =
				totalData.basic_setup.preview.video_link_external
			if (videoLinkExternal) {
				setValue('url', videoLinkExternal)
				setValueInputUrlVideo(videoLinkExternal)
				setValueInput?.(videoLinkExternal)
			}
		}
	}, [totalData])

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="h-full flex flex-col justify-between"
		>
			<div>
				{listImage.length === 0 ? (
					<div className="bg-gray-11 px-[61px] py-36 w-full my-4">
						<div className="flex items-center justify-center gap-2 py-3 px-5 bg-white shadow-boxPreview rounded w-full">
							<Typography
								fontLeading="15/18"
								type={TypographyTypes.p}
								className="!font-normal text-lavender"
								title="You can use your own or grab one from the Library. 
						Your Images and Videos will appear here as you add them."
							/>
							<Icon src={Icons.uploadCloud} className="min-w-[25px] w-[25px]" />
						</div>
					</div>
				) : (
					<div className="my-4 relative">
						<div className="relative">
							<div className="flex justify-center items-center">
								<ReactCrop
									crop={crop as Crop}
									onChange={(_, percentCrop) => {
										setCrop(percentCrop)
										setIsActiveFinish(false)
									}}
									onComplete={c => {
										setCompletedCrop(c)
										setIsActiveFinish(true)
									}}
									locked
								>
									<img
										src={listImage[listImage.length - 1].asset_url}
										alt="preview content"
										className="!max-h-[20rem]"
										style={{
											transform: generateTransform(
												scale,
												rotate,
												flipHorizontal,
												flipVertical
											)
										}}
										ref={imgRef}
									/>
								</ReactCrop>

								{listImage.length > 1 && (
									<div
										className={`flex gap-2 bg-gray-9 rounded-lg p-3 max-w-max absolute ${
											isCropSettingsVisible && !isAssetCropped
												? 'bottom-[18.5rem]'
												: 'bottom-[1.5rem]'
										} left-[1rem]`}
									>
										{listImage.map(
											(item, index) =>
												listImage.length - 1 !== index && (
													<div className="relative" key={item.asset_aspect}>
														<img
															src={item.asset_url}
															alt="preview content"
															className="w-[59px] h-14 rounded-lg"
														/>
														<button
															type="button"
															onClick={() => handleDeleteImage(index)}
															className="absolute -top-1 -right-1 bg-gray-18 p-1 rounded-full border-2 border-gray-3"
														>
															<Icon src={Icons.close} className="w-3 h-3 " />
														</button>
													</div>
												)
										)}
									</div>
								)}
							</div>
							{isCropSettingsVisible &&
								!isAssetCropped &&
								(mode === 'edit' || mode === 'create') && (
									<ImageCropSettings
										handleToggleAspectClick={handleToggleAspectClick}
										rotate={rotate}
										setRotate={setRotate}
										scale={scale}
										setScale={setScale}
										handleControlZoom={handleControlZoom}
										completedCrop={completedCrop as PixelCrop}
										aspectString={aspectString}
									/>
								)}
							{isFinishCropButtonVisible && !isAssetCropped && (
								<div className="pt-2">
									<Button
										label="Finish Crop"
										type="button"
										color="Primary"
										className="w-full mt-1"
										onClick={() => {
											handleFinishCrop()
											setIsFinishCropButtonVisible(false)
											setIsCropSettingsVisible?.(false)
											resetCrop()
											setIsCropComplete(true)
										}}
										disabled={!isActiveFinish}
									/>
								</div>
							)}
							{!!completedCrop && (
								<canvas
									id="croppedCanvas"
									className=" h-0 w-0 rounded-lg border-blue-300"
									ref={previewCanvasRef}
									style={{
										visibility: 'hidden',
										border: '1px solid black',
										objectFit: 'contain'
									}}
								/>
							)}
							<button
								type="button"
								onClick={() => handleDeleteImage(-1)}
								className="absolute -top-1 -right-1 bg-gray-18 p-1 rounded-full border-2 border-gray-3"
							>
								<Icon src={Icons.close} className="w-3 h-3 " />
							</button>
						</div>
					</div>
				)}
				<input
					id="image"
					type="file"
					className="hidden"
					{...registerInputFile}
					onChange={event => {
						if (event.target.files && event.target.files.length !== 0) {
							setIsCropped(false)
							handleUploadImage(event.target.files[0])
						} else {
							setIsCropped(true)
						}
					}}
					accept="image/png, image/jpeg, image/jpg"
				/>
				<div className="flex flex-col gap-4">
					<Button
						color="Primary"
						label="Upload From Your Device"
						className="w-full mt-1"
						onClick={() => {
							const input = document.getElementById('image')
							if (input) input.click()
						}}
						disabled={listImage.length >= 5 || !isCropped}
					/>
					<Button
						color="Transparent"
						iconLeft={Icons.addImages}
						className="w-full mt-1"
						label="Browse From Your Library"
						onClick={() => {
							setSlideImages(false)
							setSlideLibraryImages(true)
						}}
						disabled={listImage.length >= 5 || !isCropped}
					/>
					<button
						type="button"
						className="flex items-center justify-center gap-3 w-full"
						onClick={() => {
							const input = document.getElementById('image')
							if (input) input.click()
						}}
						disabled={listImage.length >= 5 || !isCropped}
					>
						<Icon
							src={Icons.plus}
							className={clsx(
								'w-5 h-5',
								disableMultiple || !isCropped
									? 'text-gray-9'
									: 'text-blue-primary '
							)}
							fillPath
						/>
						<Typography
							title="Add Multiple Images"
							className={clsx(
								'!font-semibold',
								disableMultiple || !isCropped
									? 'text-gray-9'
									: 'text-blue-primary '
							)}
							type={TypographyTypes.span}
							fontLeading="15/22"
						/>
					</button>
				</div>
				<div className="pt-4">
					<Input
						name="url"
						rules={rules.url}
						error={errors.url}
						borderFull={false}
						register={register}
						setValueInput={setValue}
						inputClassName="rounded"
						title="Link to Your Video"
						placeholder="YouTube or Vimeo"
						onChange={e => {
							setValueInputUrlVideo(e.target.value)
							setValueInput?.(e.target.value)
						}}
						value={valueInput}
					/>
					{valueInputUrlVideo &&
						!regexVimeoOrYoutube.test(valueInputUrlVideo) && (
							<Message type="error" text="Invalid link" />
						)}
				</div>
			</div>
			<div className="flex items-center justify-between mt-4">
				<Button
					type="button"
					label="Cancel"
					color="Transparent"
					className="!min-w-[130px]"
					onClick={() => {
						setOpenSlide(false)
						setSlideImages(false)
					}}
				/>
				<Button
					label="Save"
					type="submit"
					color="Primary"
					className="!min-w-[130px]"
					disabled={
						(listImage.length === 0 &&
							valueInputUrlVideo === '' &&
							valueInput === '') ||
						!isCropComplete
					}
				/>
			</div>
		</form>
	)
}
