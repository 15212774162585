import clsx from 'clsx'
import { Message } from 'components/message'
import { InputPhoneDobleProps } from 'interfaces'
import React from 'react'
import { InputPhone } from './inputPhone'

export const InputPhoneDoble: React.FC<InputPhoneDobleProps> = ({
	name,
	name2,
	title,
	rules,
	rules2,
	error,
	error2,
	register,
	setValue,
	onChangeCustom2,
	onChangeCustom,
	watchValue,
	watchValueCode
}): JSX.Element => {
	return (
		<div className="mb-5">
			<label
				htmlFor={name}
				className={clsx(
					'text-[12px] leading-[12px] font-bold mb-3 font-Inter text-black-1'
				)}
			>
				{title}
			</label>
			<div className="relative flex gap-2">
				<span className="absolute top-[12px]">+</span>
				<InputPhone
					className="w-[15%] p-0 pl-1"
					inputClassName="px-0"
					name={name}
					placeholder="1"
					register={register}
					rules={rules}
					setValueInput={setValue}
					maxLength={3}
					onChangeCustom={onChangeCustom}
					watchValue={watchValueCode}
					autoComplete={name}
				/>
				<InputPhone
					name={name2}
					register={register}
					rules={rules2}
					setValueInput={setValue}
					onChangeCustom={onChangeCustom2}
					placeholder="(800) 000-0000"
					watchValue={watchValue}
					autoComplete={name2}
				/>
			</div>
			{error?.message && <Message text={error?.message} type="error" />}
			{error2?.message && <Message text={error2?.message} type="error" />}
		</div>
	)
}
