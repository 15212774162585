import clsx from 'clsx'
import { HeaderTableItem, Pagination } from 'components'
import { useSelect } from 'hooks'
import { ChildrenTableProps, TagsApprovalItem } from 'interfaces'
import { onPageChanged } from 'lib'
import React from 'react'
import { FollowersTagCell } from './followersTagCell'

export const TagsApprovalTable: React.FC<
	ChildrenTableProps<TagsApprovalItem[]>
> = React.memo(
	({
		data,
		backendPages,
		customWidth,
		justify = 'center',
		bodyTableClassName,
		actionsButton,
		actionColumn = 'left',
		actionColumnWidth = 5,
		page,
		setPage,
		dataSelected,
		setDataSelected,
		setOpen,
		refetch
	}): JSX.Element => {
		const { selectAll, handleSelectAll, handleSelected } = useSelect(
			data ?? [],
			dataSelected,
			setDataSelected,
			'organization_follower_id'
		)
		return (
			<div className={clsx('min-w-[700px]', backendPages === 1 && 'mb-[54px]')}>
				<div className="py-2 px-1 bg-white border-2 border-gray-7 rounded-lg min-w-min">
					<table className="w-full overflow-y-hidden">
						<thead className="flex w-full h-5 mb-3">
							<tr className="w-full flex justify-between items-center">
								{dataSelected && setDataSelected && (
									<th
										scope="col"
										className="flex items-center justify-center"
										style={{ flexBasis: `3%` }}
									>
										<input
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-blue-primary focus:ring-transparent-default cursor-pointer"
											checked={selectAll}
											onChange={handleSelectAll}
										/>
									</th>
								)}
								{actionsButton && actionColumn === 'left' && (
									<th
										scope="col"
										className="flex items-center justify-center text-[15px] leading-[18px] text-gray-9 font-bold cursor-pointer"
										style={{ flexBasis: `${actionColumnWidth}%` }}
									>
										Actions
									</th>
								)}
								{data && (
									<>
										<HeaderTableItem
											header="Name"
											table="tagsApproval"
											column="name"
											justify={justify}
											style={
												customWidth
													? { flexBasis: `${customWidth[0]}%` }
													: { flexBasis: `${100 / data.length}%` }
											}
										/>
										<HeaderTableItem
											header="Email"
											table="tagsApproval"
											column="email"
											justify={justify}
											style={
												customWidth
													? { flexBasis: `${customWidth[1]}%` }
													: { flexBasis: `${100 / data.length}%` }
											}
										/>
										<HeaderTableItem
											header="Tag"
											justify={justify}
											style={
												customWidth
													? { flexBasis: `${customWidth[2]}%` }
													: { flexBasis: `${100 / data.length}%` }
											}
										/>
									</>
								)}
								{actionsButton && actionColumn === 'right' && (
									<th
										scope="col"
										className="flex items-center justify-center text-[15px] leading-[18px] text-gray-9 font-bold cursor-pointer"
										style={{ flexBasis: `12%` }}
									>
										Actions
									</th>
								)}
							</tr>
						</thead>
						<tbody
							className={clsx(
								bodyTableClassName,
								'w-full h-[14rem] overflow-y-auto flex flex-col gap-2'
							)}
						>
							{data?.map(item => {
								return (
									<tr
										key={item.organization_follower_id}
										className="w-full flex justify-between"
									>
										{dataSelected && setDataSelected && (
											<td
												className="flex justify-center items-center h-10"
												style={{ flexBasis: `3%` }}
											>
												<input
													type="checkbox"
													className="h-4 w-4 rounded border-gray-300 text-blue-primary focus:ring-transparent-default cursor-pointer"
													checked={dataSelected.includes(
														item.organization_follower_id
													)}
													onChange={() =>
														handleSelected(item.organization_follower_id)
													}
												/>
											</td>
										)}
										{actionsButton && actionColumn === 'left' && (
											<td
												className={clsx(
													'h-10 flex justify-center items-center text-[15px] leading-[18px] text-center text-gray-9'
												)}
												style={{ flexBasis: `${actionColumnWidth}%` }}
											>
												{React.cloneElement(actionsButton, {
													value: item.organization_follower_id,
													data: item
												})}
											</td>
										)}
										{data && setOpen && (
											<>
												<td
													className={clsx(
														'w-full h-10 flex items-center text-[15px] leading-[18px] text-gray-9',
														justify === 'center' &&
															'justify-center text-center',
														justify === 'left' && 'justify-left text-left',
														justify === 'right' && 'justify-right text-right'
													)}
													style={
														customWidth
															? { flexBasis: `${customWidth[0]}%` }
															: { flexBasis: `${100 / data.length}%` }
													}
												>
													{item.name}
												</td>
												<td
													className={clsx(
														'w-full h-10 flex items-center text-[15px] leading-[18px] text-gray-9',
														justify === 'center' &&
															'justify-center text-center',
														justify === 'left' && 'justify-left text-left',
														justify === 'right' && 'justify-right text-right'
													)}
													style={
														customWidth
															? { flexBasis: `${customWidth[1]}%` }
															: { flexBasis: `${100 / data.length}%` }
													}
												>
													{item.email}
												</td>
												<td
													className={clsx(
														'w-full h-10 flex items-center text-[15px] leading-[18px] text-gray-9',
														justify === 'center' &&
															'justify-center text-center',
														justify === 'left' && 'justify-left text-left',
														justify === 'right' && 'justify-right text-right'
													)}
													style={
														customWidth
															? { flexBasis: `${customWidth[2]}%` }
															: { flexBasis: `${100 / data.length}%` }
													}
												>
													<FollowersTagCell
														followerTags={item.followers_tag}
														button
														setOpen={setOpen}
														refetch={refetch}
													/>
												</td>
											</>
										)}
										{actionsButton && actionColumn === 'right' && (
											<td
												className={clsx(
													'h-10 flex justify-center items-center text-[15px] leading-[18px] text-center text-gray-9'
												)}
												style={{ flexBasis: `12%` }}
											>
												{React.cloneElement(actionsButton, {
													value: item.organization_follower_id
												})}
											</td>
										)}
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
				<div className="w-full flex items-center">
					<Pagination
						totalRecords={backendPages ?? 1}
						onPageChanged={pages => onPageChanged(pages, setPage)}
						page={page}
						pageLimit={1}
					/>
				</div>
			</div>
		)
	}
)
