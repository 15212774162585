import clsx from 'clsx'
import { Typography, handlePlaceSelected } from 'components'
import { InputAutoCompleteGoogleMapsProps, TypographyTypes } from 'interfaces'
import React from 'react'
import GooglePlacesAutocomplete from 'react-google-autocomplete'

export const InputAutoCompleteGoogleMaps: React.FC<
	InputAutoCompleteGoogleMapsProps
> = ({
	location,
	setLocation,
	addressInput,
	title = true,
	borderFull = false,
	classNameInput,
	marginContainer
}): JSX.Element => {
	return (
		<div
			className={clsx(
				borderFull && 'border-[2px] rounded-sm',
				!borderFull && !marginContainer && 'border-b-[0.5px]',
				marginContainer ?? 'mt-4',
				'flex flex-col  border-gray-3'
			)}
		>
			{title && (
				<Typography
					title="Address"
					type={TypographyTypes.label}
					className="text-black-1 mb-4"
					fontLeading="12/12"
				/>
			)}
			<GooglePlacesAutocomplete
				ref={addressInput}
				placeholder="Begin typing address"
				onPlaceSelected={result =>
					handlePlaceSelected(result, location, setLocation)
				}
				onChange={evt => {
					const { value } = evt.target as HTMLInputElement

					if (!value) {
						setLocation({
							title: '',
							address: '',
							latitude: 0,
							longitude: 0
						})
					}
				}}
				options={{
					types: ['address'],
					fields: ['address_components', 'geometry', 'formatted_address']
				}}
				defaultValue={location.address}
				apiKey={process.env.REACT_APP_GOOGLE_MAPS}
				className={clsx(
					classNameInput ?? 'pb-4',
					'text-[15px] leading-[22px] placeholder-gray-3 text-gray-9'
				)}
			/>
		</div>
	)
}
