import { InputAutoCompleteGoogleMaps, Maps } from 'components'
import { InputRange } from 'components/input/inputRange'
import { DeliveryRadiusProps, LocationContentItemForm } from 'interfaces'
import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'

export const RadiusLocation: React.FC<DeliveryRadiusProps> = ({
	delivery,
	totalData,
	setDelivery
}): JSX.Element => {
	const addressInput = useRef<HTMLInputElement>(null)
	const { setValue } = useForm<LocationContentItemForm>()

	useEffect(() => {
		const dataDelivery = totalData.delivery_enhancements.location.find(
			location => location.location_type === 'delivery'
		)

		if (dataDelivery?.title) {
			setValue('name', dataDelivery.title)
		}
	}, [totalData])

	return (
		<form>
			<InputAutoCompleteGoogleMaps
				location={delivery}
				setLocation={setDelivery}
				addressInput={addressInput}
			/>
			<Maps
				location={delivery}
				setLocation={setDelivery}
				addressInput={addressInput}
			/>
			<div className="mt-4">
				<InputRange setValue={setDelivery} value={delivery} />
			</div>
		</form>
	)
}
