import { Button, Icon, Typography } from 'components'
import { TailwindModal } from 'components/modal'
import {
	ConfirmDeepLinkCreationProps,
	Icons,
	TypographyTypes
} from 'interfaces'
import React from 'react'

export const ConfirmDeepLinkCreation = (
	props: ConfirmDeepLinkCreationProps
): JSX.Element => {
	const { open, setOpen, defaultAlias, onClick } = props
	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			showCloseButton
			className="w-[360px] rounded-2xl pt-2 pb-4"
		>
			<div className="flex flex-col gap-4 w-full items-center px-4">
				<div className="flex flex-col gap-3 w-full items-center">
					<Icon
						src={Icons.warning}
						fillPath
						className="text-blue-primary w-10 h-10 mt-4"
					/>
					<Typography
						title="Are you sure to create the link branch without an alias?"
						fontLeading="15/15"
						type={TypographyTypes.h3}
						className="text-black-1 text-center !font-normal"
					/>
					<span className="text-black-1 text-center !font-normal text-[15px]">
						It will be created by default with the alias{' '}
						<span className="text-blue-primary">
							{`${defaultAlias}_`}
							<span className="font-bold">container</span>
						</span>
					</span>
				</div>
				<Button
					label="Confirm"
					onClick={onClick}
					className="w-fit !py-3 !px-9"
				/>
			</div>
		</TailwindModal>
	)
}
