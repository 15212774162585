import { API } from 'api/config'
import { ForgotPassword, ForgotResponse } from 'interfaces'

export const PostForgotPassword = async (
	body: ForgotPassword
): Promise<ForgotResponse | null> => {
	let res = null as ForgotResponse | null
	await API()
		.post(`/user/forget_password`, body)
		.then(response => {
			res = response.data
		})
		.catch(error => {
			res = { detail: error.response.data.detail }
		})
	return res
}
