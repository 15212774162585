import clsx from 'clsx'
import { Typography } from 'components'
import { Icon } from 'components/icon'
import { FollowerTagParent, Icons, TypographyTypes } from 'interfaces'
import React, { useState } from 'react'
import { getStateTags, stateTags } from './utilStateTags'

interface MyPeopleParentProps {
	tagParent: FollowerTagParent
	myTagsDataSelected: number[]
	handleSelected: (id: number) => void
}
export const MyPeopleParent: React.FC<MyPeopleParentProps> = ({
	tagParent,
	myTagsDataSelected,
	handleSelected
}) => {
	const [open, setOpen] = useState(true)
	return (
		<div
			className="flex flex-col gap-2 w-full justify-between pt-3"
			key={`${tagParent.id}_${tagParent.name}`}
		>
			<div onClick={() => setOpen(!open)} className="flex" aria-hidden="true">
				<Typography
					title={tagParent.name as string}
					fontLeading="15/18"
					type={TypographyTypes.p}
					className=" w-full pt-2"
				/>
				<div className="w-[24px] h-[24px]">
					<Icon
						src={open ? Icons.expandMore : Icons.expandLess}
						fillPath
						className="text-gray-3 "
					/>
				</div>
			</div>
			<div>
				{tagParent?.id && open && (
					<div id={`${tagParent.id}`}>
						{tagParent.tags_child.length !== 0 &&
							tagParent.tags_child
								.slice()
								.sort((a, b) =>
									a.name && b.name ? a.name.localeCompare(b.name) : 0
								)
								.map(tagChild => {
									const stateCurrent = getStateTags(tagChild)
									const fontTitle =
										stateCurrent &&
										stateTags[stateCurrent].state === '(Approval required)'
											? '10/12'
											: '12/16'

									return (
										<div
											className="flex items-center justify-between gap-2 p-1 w-full py-[8px]"
											key={`${tagChild.id}_${tagChild.name}`}
										>
											<div className="flex w-full gap-2 items-center">
												<input
													type="checkbox"
													className="h-4 w-4 border-gray-300 text-blue-primary focus:ring-transparent-default cursor-pointer "
													checked={myTagsDataSelected.includes(
														tagChild.id as number
													)}
													onChange={() => handleSelected(tagChild.id as number)}
												/>
												<p className="text-sm font-normal">
													{tagChild.name ? tagChild.name : ''}
												</p>
												{!tagChild.is_visible && (
													<Typography
														className="text-gray-1"
														title="(Hidden)"
														fontLeading="10/12"
														type={TypographyTypes.span}
													/>
												)}
											</div>

											<div className="w-[50%] text-xs  flex items-center">
												{stateCurrent && (
													<>
														<Typography
															title={stateTags[stateCurrent].state}
															fontLeading={fontTitle}
															type={TypographyTypes.p}
														/>
														<Icon
															src={stateTags[stateCurrent].icon}
															fillPath
															className={clsx(
																'w-6 h-6 ml-1',
																stateTags[stateCurrent].className
															)}
														/>
													</>
												)}
											</div>
										</div>
									)
								})}
					</div>
				)}
			</div>
		</div>
	)
}
