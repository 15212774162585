import clsx from 'clsx'
import { Images } from 'interfaces'
import React from 'react'

export const Avatar: React.FC<
	React.DetailedHTMLProps<
		React.ImgHTMLAttributes<HTMLImageElement>,
		HTMLImageElement
	>
> = props => {
	const { src, className, alt, ...imgProps } = props
	return (
		<img
			src={src ?? Images.defaultAvatar}
			className={clsx(
				'rounded-full border border-gray-3 bg-gray-11 h-[58px] w-[58px]',
				className
			)}
			alt={alt}
			{...imgProps}
		/>
	)
}
