/* eslint-disable no-template-curly-in-string */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getUploadImageBanner, updateImage } from 'api'
import clsx from 'clsx'
import { Button, Helper, Icon, Input, Typography } from 'components'
import { useToast } from 'hooks'
import {
	BannerMyOrganizationItem,
	GroupComponentsLinkProps,
	Icons,
	SlideFormProps,
	TypographyTypes,
	UpdateImageResponse
} from 'interfaces'
import React, { useEffect, useState } from 'react'
import { FieldError, useForm } from 'react-hook-form'
import Cookies from 'universal-cookie'
import { configGenaral } from 'utils'

const GroupComponents: React.FC<GroupComponentsLinkProps> = ({
	register,
	index,
	errors,
	deleteComponent,
	handleMediaUploadBanner,
	groupBanner,
	watch,
	setValue
}): JSX.Element => {
	const rules = {
		title: {
			required: { value: true, message: 'Is required' }
		},
		url: {
			required: { value: true, message: 'Is required' },
			pattern: {
				value: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
				message: 'Please enter a valid url'
			}
		}
	}
	const [errorTitleValid, setErrorTitleValid] = useState(false)
	const [errorLinkValid, setErrorLinkValid] = useState(false)
	const titleError = (
		errorTitleValid ? errors[`title-${index}`] : undefined
	) as FieldError | undefined
	const urlError = (errorLinkValid ? errors[`url-${index}`] : undefined) as
		| FieldError
		| undefined
	const watchTitle = watch?.(`title-${index}`)
	const watchUrl = watch?.(`url-${index}`)

	const registerInput = register(`coverUrl-${index}`, {
		required: { value: true, message: 'Is required' }
	})

	return (
		<div className="flex flex-col w-full gap-6 mb-6">
			<Input
				name={`title-${index}`}
				title="Banner Button"
				register={register}
				rules={rules.title}
				error={titleError}
				borderFull
				limit
				cantLimit={configGenaral.maxTitleLength}
				watchValue={watchTitle}
				setValueInput={setValue}
				onChangeCustom={() => {
					setErrorTitleValid(true)
				}}
			/>
			<Input
				name={`url-${index}`}
				title="Banner Action"
				register={register}
				rules={rules.url}
				error={urlError}
				borderFull
				placeholder="https://www.example.com"
				onChangeCustom={() => {
					setErrorLinkValid(true)
				}}
			/>
			<div className="w-full flex items-center justify-between mb-4">
				<input
					id={`coverUrl-${index}`}
					type="file"
					className="hidden"
					{...registerInput}
					onChange={event => {
						if (event.target.files) {
							if (handleMediaUploadBanner)
								handleMediaUploadBanner(
									event.target.files[0],
									index,
									watchTitle,
									watchUrl
								)
						}
					}}
					accept="image/png, image/jpeg, image/jpg"
				/>
				<div className="flex items-center justify-start gap-4">
					<h3
						className={clsx(
							'text-[12px] leading-[12px] font-bold font-Inter text-black-1'
						)}
					>
						Background Image
					</h3>
					<Helper
						text="Banners are located in the home of the user’s app, this banner could improve your communication or marketing efforts."
						className="relative"
					/>
				</div>
				<Button
					label="Upload"
					color="Transparent"
					iconLeft={Icons.addPhoto}
					small
					onClick={() => {
						const input = document.getElementById(`coverUrl-${index}`)
						if (input) input.click()
					}}
				/>
			</div>
			{groupBanner?.[index]?.image_url && (
				<div className="w-full h-[120px] rounded-2xl overflow-hidden border-[1px] border-gray-3 relative">
					{/* <Typography
						title={watchTitle}
						className="text-white absolute top-4 left-4"
						fontLeading={'18/21'}
						type={TypographyTypes.h2}
					/> */}
					<img
						className="w-full h-full"
						src={groupBanner[index].image_url}
						alt={`banner-${index}`}
					/>
				</div>
			)}
			<button
				type="button"
				aria-hidden="true"
				className=" flex gap-2 cursor-pointer"
				onClick={() => deleteComponent(index)}
				disabled={
					groupBanner &&
					(groupBanner[0].title === '' || groupBanner[0].redirect_value === '')
				}
			>
				<Icon
					src={Icons.delete}
					className="w-[16px] h-[18px] text-gray-3"
					fillPath
				/>
				<Typography
					title="Remove"
					className="text-gray-3"
					fontLeading="15/18"
					type={TypographyTypes.p}
				/>
			</button>
		</div>
	)
}

export const SlideBanner: React.FC<
	SlideFormProps & {
		onSave: (result: BannerMyOrganizationItem[]) => Promise<void>
	}
> = ({ setOpen, data, setData, setLoading, onSave }): JSX.Element => {
	const { showToast } = useToast()
	const cookies = new Cookies()
	const organizationId = parseInt(cookies.get('organization_id'), 10)
	const organizationSlug = cookies.get('organization_slug')
	const defaultBanner = {
		organization_id: organizationId,
		banner_order: 0,
		title: '',
		image_url: '',
		redirect_type: '',
		redirect_value: ''
	}

	const [disableButton, setDisableButton] = useState(true)
	const [groupElements, setGroupElements] = useState(
		data?.banners?.length ? data.banners : [defaultBanner]
	)

	const {
		register,
		setValue,
		watch,
		formState: { errors }
	} = useForm<any>({
		mode: 'all'
	})

	useEffect(() => {
		groupElements.forEach((component, index) => {
			if (index + 1 === groupElements?.length) {
				if (component?.image_url) {
					setDisableButton(false)
				} else {
					setDisableButton(true)
				}
			}
			setValue(`title-${index}`, component.title, { shouldValidate: true })
			setValue(`url-${index}`, component.redirect_value, {
				shouldValidate: true
			})
		})
	}, [groupElements])

	const handleMediaUploadBanner = (
		file: File,
		index: number,
		title: string,
		url: string
	): void => {
		const fileSize = file.size
		const fileMb = fileSize / 1024 ** 2
		if (fileMb <= configGenaral.maxfileSizeMB) {
			const reader = new FileReader()
			reader.onload = e => {
				const uploadedObj = { file, url: e.target?.result as string }
				const aux = groupElements.map((component, i) => {
					if (index === i) {
						return {
							...component,
							image_url: uploadedObj.url,
							title,
							redirect_value: url,
							redirect_type: uploadedObj.file.name
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, '')
								.replace(/ /g, '-')
						}
					}
					return component
				})
				setGroupElements(aux)
			}
			reader.readAsDataURL(file) // convert to base64 string
		} else {
			showToast(
				'Error',
				`Please select a file less than ${configGenaral.maxfileSizeMB}MB.`,
				'error'
			)
		}
	}

	const updatePhotoBanner = async (
		credentials: UpdateImageResponse | null,
		image: string,
		name: string
	): Promise<string> => {
		if (!credentials) return ''
		const getImage = await fetch(image)
		const imageBlob = await getImage.blob()
		const newKey = credentials.fields.key.replace(
			'${filename}',
			`${name}-${Date.now()}`
		)
		const body = {
			...credentials.fields,
			file: imageBlob,
			key: newKey
		}
		await updateImage(credentials.url, body)
		const url = `${credentials.url}${newKey}`
		return url
	}

	const onSubmit = async (): Promise<void> => {
		let showError = false

		groupElements.forEach((item, index) => {
			const watchTitle = watch(`title-${index}`)
			const watchUrl = watch(`url-${index}`)
			if (
				!watchTitle ||
				watchTitle === '' ||
				!watchUrl ||
				watchUrl === '' ||
				!item.redirect_value ||
				item.redirect_value === ''
			) {
				showError = true
			}
		})

		if (!showError) {
			if (setLoading && data) {
				setOpen(false)
				setLoading(true)
				const bannerUrl = groupElements.map(() => '')
				const credentials = await getUploadImageBanner(organizationSlug, 'png')
				await Promise.all(
					groupElements.map(async (elemet, index) => {
						if (elemet.image_url.includes('data:image')) {
							bannerUrl[index] = await updatePhotoBanner(
								credentials,
								elemet.image_url,
								elemet.redirect_type
							)
						} else {
							bannerUrl[index] = elemet.image_url
						}
					})
				)
				const aux = {
					...data,
					banners: groupElements.map((component, index) => {
						const watchTitle = watch(`title-${index}`)
						const watchUrl = watch(`url-${index}`)
						return {
							...component,
							banner_order: index,
							image_url: bannerUrl[index],
							title: watchTitle,
							redirect_type: '',
							redirect_value: watchUrl
						}
					})
				}
				setData(aux)
				onSave?.(aux.banners)
				setOpen(false)
				setLoading(false)
			}
		} else {
			showToast('Error', `Please fill in all the fields`, 'error')
		}
	}

	const addComponent = (): void => {
		setGroupElements(prev => [
			...prev,
			{
				organization_id: organizationId,
				banner_order: 0,
				title: '',
				image_url: '',
				redirect_type: '',
				redirect_value: ''
			}
		])
	}

	const deleteComponent = (pos: number): void => {
		const aux = groupElements.filter((_component, index) => pos !== index)
		setGroupElements(aux)
	}

	return (
		<form className="flex flex-col justify-between overflow-hidden h-[calc(100vh-100px)] mt-6">
			<div className="flex flex-col w-full overflow-y-scroll hide-scroll-bar max-h-[calc(100vh-130px)] pb-6">
				{groupElements.map((_component, index) => {
					return (
						<GroupComponents
							key={`component-${index}`}
							register={register}
							index={index}
							errors={errors}
							deleteComponent={deleteComponent}
							handleMediaUploadBanner={handleMediaUploadBanner}
							groupBanner={groupElements}
							watch={watch}
							setValue={setValue}
						/>
					)
				})}
				{groupElements.length < 5 && (
					<div className="w-full mt-6">
						<Button
							type="button"
							label="Add"
							small
							onClick={() => addComponent()}
							iconLeft={Icons.plus}
							disabled={disableButton}
						/>
					</div>
				)}
			</div>
			<div className="w-full flex justify-between items-center mt-3">
				<Button
					label="Cancel"
					color="Gray2"
					small
					onClick={() => setOpen(false)}
				/>
				<Button type="button" label="Save" small onClick={() => onSubmit()} />
			</div>
		</form>
	)
}
