import { CircularProgress } from '@mui/material'
import { GET_ORGANIZATION_BY_USER, getOrganizationsByUser } from 'api'
import { Button, Input, InputRadioImage, Typography } from 'components'
import { MenuContext } from 'context'
import { useSearchDebounce } from 'hooks'
import {
	Icons,
	OrganizationsByUserData,
	SelectedOrganizationProps,
	TypographyTypes
} from 'interfaces'
import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { configGenaral } from 'utils'

export const SelectedOrganization: React.FC<SelectedOrganizationProps> = ({
	user,
	setStep
}): JSX.Element => {
	const { setFullName } = useContext(MenuContext)
	const cookies = new Cookies()
	const navigate = useNavigate()
	const [search, setSearch] = useState('')
	const [organizationSelected, setOrganizationSelected] =
		useState<OrganizationsByUserData>()
	const { inputSearch, handleChangeDebounce, resetInput } =
		useSearchDebounce('')
	const { data, isLoading } = useQuery(
		[GET_ORGANIZATION_BY_USER, inputSearch, user.token],
		() =>
			getOrganizationsByUser(
				user.email as string,
				user.token as string,
				inputSearch
			)
	)

	useEffect(() => {
		if (data) {
			setOrganizationSelected(data.items[0])
		}
	}, [data])

	const handleSubmit = (): void => {
		const roles = [] as number[]
		organizationSelected?.roles.forEach(item => {
			roles.push(item.credential_id)
		})
		setFullName(user.fullName as string)
		cookies.set('fullName', user.fullName, { path: '/' })
		cookies.set('userId', user.userId, { path: '/' })
		cookies.set('organization_id', organizationSelected?.id, {
			path: '/'
		})
		cookies.set('organization_name', organizationSelected?.name, {
			path: '/'
		})
		cookies.set('organization_logo', organizationSelected?.icon_image_url, {
			path: '/'
		})
		cookies.set('organization_slug', organizationSelected?.slug, {
			path: '/'
		})
		cookies.set('roles', roles, {
			path: '/'
		})
		cookies.set('token', user.token, { path: '/' })
		navigate('/')
	}

	return (
		<div className="border border-blue-primary rounded-2xl p-4 min-w-[455px]">
			<Typography
				title="Welcome back!"
				fontLeading="22/27"
				type={TypographyTypes.h1}
				className="text-black-1 mb-2"
			/>
			<div className="flex items-center gap-2 border-b border-gray-4 pb-2">
				<div className="h-14 w-14 flex justify-center items-center bg-gray-3 border-[4px] border-blue-primary rounded-full">
					<Typography
						title={user.fullName ? (user.fullName[0] as string) : ''}
						fontLeading="22/27"
						type={TypographyTypes.p}
						className="text-white"
					/>
				</div>
				<div>
					<Typography
						title={user.fullName ? (user.fullName as string) : ''}
						fontLeading="22/27"
						type={TypographyTypes.p}
						className="text-gray-1 !font-bold"
					/>
					<Typography
						title="Admin"
						fontLeading="22/27"
						type={TypographyTypes.p}
						className="text-gray-1 !font-normal"
					/>
				</div>
			</div>
			<Typography
				title="Please select the organization you want to manage:"
				fontLeading="12/15"
				type={TypographyTypes.p}
				className="text-black-1 !font-normal py-4"
			/>
			<Input
				name="search"
				placeholder="Search Organization"
				className="w-full !p-[unset]"
				onChangeCustom={e => {
					setSearch(e.target.value)
					handleChangeDebounce(e)
				}}
				rightIcon={Icons.cancel}
				rightClick={() => {
					resetInput()
					setSearch('')
				}}
				value={search}
				maxLength={configGenaral.maxSearchValue}
				inputClassName="!p-[unset] !py-1 !px-2"
				inputFocus
				borderFull={false}
				search
			/>
			<div className="pt-2 max-h-[230px] overflow-y-scroll border-b border-gray-4 pb-2">
				{isLoading ? (
					<div className="flex items-center justify-center w-full h-[400px] py-2 px-1 ">
						<CircularProgress disableShrink />
					</div>
				) : (
					data?.items?.map(option => (
						<InputRadioImage
							key={option.id}
							option={option}
							defaultOptions={data.items[0].id}
							optionSelected={setOrganizationSelected}
						/>
					))
				)}
			</div>
			<div className="flex flex-row justify-between mt-2">
				<Button
					label="Cancel"
					type="button"
					color="Transparent"
					className="min-h-[40px] !min-w-[124px] !max-w-[124px] !bg-white"
					onClick={() => setStep(0)}
				/>
				<Button
					label="Log in"
					type="button"
					color="Primary"
					className="min-h-[40px] !min-w-[124px] !max-w-[124px]"
					onClick={handleSubmit}
				/>
			</div>
		</div>
	)
}
