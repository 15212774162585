import clsx from 'clsx'
import { Box } from 'components/boxs'
import { Checkbox } from 'components/checkbox'
import { Icon } from 'components/icon'
import { Typography } from 'components/typography'
import {
	Icons,
	OrganizationTagRowProps,
	OrganizationTagTableProps,
	OrganizationTagType,
	TypographyTypes
} from 'interfaces'
import React, { useMemo } from 'react'
import { Column, useSortBy, useTable } from 'react-table'
import { OrganizationTagActions } from './OrganizationTagActions'

const OrganizationTagRow: React.FC<OrganizationTagRowProps> = props => {
	const {
		childTagsCount,
		tag,
		onExpand,
		onSelect,
		onRequiredApproval,
		onDelete,
		onEdit,
		onHide,
		selected,
		subTag,
		expanded
	} = props
	return (
		<tr className={clsx(subTag && 'bg-gray-11', '[&>td]:py-5')}>
			<td className="flex items-center gap-2 text-gray-1">
				<button
					onClick={onExpand}
					type="button"
					className={clsx(
						(subTag || !childTagsCount || childTagsCount <= 0) && 'invisible',
						subTag && 'ml-4',
						expanded && 'text-blue-primary'
					)}
				>
					<Icon
						fillPath
						className="w-[24px] h-[24px]"
						src={expanded ? Icons.expandLess : Icons.expandMore}
					/>
				</button>
				<Checkbox onChange={onSelect} checked={selected} />
				<OrganizationTagActions
					onEdit={() => onEdit?.()}
					onDelete={() => onDelete?.()}
					onHide={() => onHide?.()}
					hidded={!tag.visible}
				/>
			</td>
			<td>
				<span className={clsx(subTag ? 'text-sm' : 'text-base')}>
					{tag.name}
				</span>
			</td>
			<td className="text-gray-1">
				<Icon
					fillPath
					className="w-[24px] h-[24px]"
					src={tag.visible ? Icons.visibility : Icons.visibilityOff}
				/>
			</td>
			<td className="flex items-center gap-2">
				<Checkbox
					checked={tag.requiredApproval}
					onChange={onRequiredApproval}
				/>
				<Typography
					className="!font-normal"
					title="Required Approval"
					type={TypographyTypes.p}
					fontLeading="15/18"
				/>
			</td>
			<td>{childTagsCount}</td>
		</tr>
	)
}

export const OrganizationTagsTable: React.FC<
	OrganizationTagTableProps
> = props => {
	const {
		tags,
		onDelete,
		onEdit,
		onHide,
		onRequiredApproval,
		onSelect,
		onExpand
	} = props

	const data = useMemo(() => tags, [tags])
	const childTags = (tagName: string): OrganizationTagType[] => {
		return tags.filter(tag => tag.parentTag === tagName)
	}

	const columns: Column<OrganizationTagType>[] = useMemo(
		() => [
			{ Header: 'Action', disableSortBy: true },
			{
				Header: 'Tag Name',
				accessor: 'name',
				sortType: (rowA, rowB) => {
					if (
						rowA.original.name.toLowerCase() > rowB.original.name.toLowerCase()
					)
						return 1
					if (
						rowA.original.name.toLowerCase() < rowB.original.name.toLowerCase()
					)
						return -1
					return 0
				}
			},
			{
				Header: 'Visibility',
				accessor: 'visible',
				sortType: (rowA, rowB) => {
					if (rowA.original.visible) return 1
					if (rowB.original.visible) return -1
					return 0
				}
			},
			{
				Header: 'Approval',
				accessor: 'requiredApproval',
				sortType: (rowA, rowB) => {
					if (rowA.original.requiredApproval) return 1
					if (rowB.original.requiredApproval) return -1
					return 0
				}
			},
			{
				Header: 'Sub Tags',
				accessor: row => childTags(row.name),
				sortType: (rowA, rowB) => {
					const rowAChilds = childTags(rowA.original.name)
					const rowBChilds = childTags(rowB.original.name)
					if (rowAChilds > rowBChilds) return 1
					if (rowAChilds < rowBChilds) return -1
					return 0
				}
			}
		],
		[]
	)

	const { headerGroups, rows, getTableProps, getTableBodyProps } = useTable(
		{
			data,
			autoResetSortBy: false,
			columns
		},
		useSortBy
	)

	return (
		<Box className="!p-0 h-[400px] overflow-y-auto">
			<table {...getTableProps()} className="w-full">
				<thead>
					{headerGroups.map(group => (
						<tr
							{...group.getHeaderGroupProps()}
							className="text-left [&>th]:p-4 text-gray-9"
						>
							{group.headers.map(header => (
								<th
									{...header.getHeaderProps(header.getSortByToggleProps())}
									className="sticky top-0 z-10 bg-white"
								>
									<div className="flex gap-2 items-center">
										{header.render('Header')}
										{!header.disableSortBy && (
											<div className="w-5 h-5">
												<Icon
													src={
														header.isSorted
															? header.isSortedDesc
																? Icons.expandLess
																: Icons.expandMore
															: Icons.unfoldMore
													}
													fillPath
													className="text-gray-13"
												/>
											</div>
										)}
									</div>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows
						.map(row => row.original)
						.filter(tag => !tag.parentTag)
						.map(tag => {
							return (
								<>
									<OrganizationTagRow
										key={tag.name}
										tag={tag}
										selected={tag.selected}
										expanded={tag.expanded}
										onDelete={() => onDelete?.(tag)}
										onExpand={() => onExpand?.(tag, !tag.expanded)}
										onHide={() => onHide?.(tag, !tag.visible)}
										onSelect={() => onSelect?.(tag, !tag.selected)}
										onEdit={() => onEdit?.(tag)}
										childTagsCount={childTags(tag.name).length}
										onRequiredApproval={() =>
											onRequiredApproval?.(tag, !tag.requiredApproval)
										}
									/>
									{tag.expanded &&
										childTags(tag.name).map(subTag => (
											<OrganizationTagRow
												key={subTag.name}
												tag={subTag}
												selected={subTag.selected}
												onDelete={() => onDelete?.(subTag)}
												onHide={() => onHide?.(subTag, !subTag.visible)}
												onSelect={() => onSelect?.(subTag, !subTag.selected)}
												onEdit={() => onEdit?.(subTag)}
												onRequiredApproval={() =>
													onRequiredApproval?.(subTag, !subTag.requiredApproval)
												}
												subTag
											/>
										))}
								</>
							)
						})}
				</tbody>
			</table>
		</Box>
	)
}
