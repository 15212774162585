import { Popover } from '@mui/material'
import {
	Button,
	MyPeopleBlockModal,
	MyPeopleEditModal,
	MyPeopleRemoveModal,
	TailwindPopOver
} from 'components'
import { Icons, PeopleMyPeopleActionCellProps } from 'interfaces'
import React, { useState } from 'react'

export const PeopleMyPeopleActionCell: React.FC<
	PeopleMyPeopleActionCellProps
> = ({ value, data, refetch }) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	const [myPeopleBlockModal, setMyPeopleBlockModal] = useState(false)
	const [myPeopleRemoveModal, setMyPeopleRemoveModal] = useState(false)
	// const [myPeopleResetModal, setMyPeopleResetModal] = useState(false)
	const [myPeopleEditModal, setMyPeopleEditModal] = useState(false)

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = (): void => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined
	return (
		<div className="flex gap-1">
			<Button
				iconLeft={Icons.pending}
				className="!w-max !min-w-[unset] !px-1 py-1 !border-none"
				color="Gray2"
				leftIconDivClassName="w-[20px] h-[20px]"
				onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
					handleClick(event)
				}}
			/>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				PaperProps={{ style: { borderRadius: '16px' } }}
			>
				<TailwindPopOver
					handleClose={handleClose}
					title="Actions"
					className="gap-4 w-[240px] h-max"
				>
					<>
						<Button
							label="Edit"
							className="!min-w-[unset] !w-full !py-2"
							iconLeft={Icons.edit}
							onClick={() => setMyPeopleEditModal(true)}
						/>
						{myPeopleEditModal && (
							<MyPeopleEditModal
								open={myPeopleEditModal}
								setOpen={setMyPeopleEditModal}
								value={value}
								data={data}
								refetch={refetch}
							/>
						)}
						{/* <Button* /}
						{/* 	label="Reset Password" */}
						{/* 	className="!min-w-[unset] !w-full !py-2" */}
						{/* 	iconLeft={Icons.password} */}
						{/* 	onClick={() => setMyPeopleResetModal(true)} */}
						{/* /> */}
						{/* {myPeopleResetModal && ( */}
						{/* 	<MyPeopleResetModal */}
						{/* 		open={myPeopleResetModal} */}
						{/* 		setOpen={setMyPeopleResetModal} */}
						{/* 		onClickConfirm={() => { */}
						{/* 			resetPasswordHandler(value as number) */}
						{/* 			handleClose() */}
						{/* 			setMyPeopleResetModal(false) */}
						{/* 		}} */}
						{/* 	/> */}
						{/* )} */}
						<div className="w-full h-[1px] bg-gray-15" />
						<Button
							label={data?.status === 'block' ? 'Unblock' : 'Block'}
							className="!min-w-[unset] !w-full !py-2"
							iconLeft={data?.status === 'block' ? Icons.check : Icons.block}
							onClick={() => setMyPeopleBlockModal(true)}
						/>
						{myPeopleBlockModal && (
							<MyPeopleBlockModal
								open={myPeopleBlockModal}
								setOpen={setMyPeopleBlockModal}
								data={data?.status}
								value={value}
								refetch={refetch}
							/>
						)}
						<Button
							label="Remove"
							className="!min-w-[unset] !w-full !py-2"
							iconLeft={Icons.dangerous}
							onClick={() => setMyPeopleRemoveModal(true)}
						/>
						{myPeopleRemoveModal && (
							<MyPeopleRemoveModal
								open={myPeopleRemoveModal}
								setOpen={setMyPeopleRemoveModal}
								value={value}
								refetch={refetch}
							/>
						)}
					</>
				</TailwindPopOver>
			</Popover>
		</div>
	)
}
