import { CircularProgress } from '@mui/material'
import { getContentSharedById } from 'api'
import { Button } from 'components/buttons'
import { Icons, PropsModalReview } from 'interfaces'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { TailwindModal } from '../tailwindModal'
import { CopyModal } from './CopyModal'
import { DeclineModal } from './DeclineModal'
import PartnerDetails from './PartnerDetails'
import PreviewDetails from './PreviewDetails'

export const ReviewPartnerModal: React.FC<PropsModalReview> = ({
	open,
	setOpen,
	idShared,
	onActionsClick
}) => {
	const cookies = new Cookies()
	const organizationId = cookies.get('organization_id')
	const { data, isLoading } = useQuery([idShared, organizationId], () =>
		getContentSharedById(organizationId, idShared)
	)
	const [accepte, setAccepte] = useState(false)
	const [openModalDecline, setOpenModalDecline] = useState(false)
	const [openModalCopy, setOpenModalCopy] = useState(false)
	const [textValue, setTextValue] = useState('')
	const navigate = useNavigate()
	const handleNavigate = (): void => {
		if (data) {
			navigate(`/content/edit/${data.content_id}`)
		}
	}

	return (
		<div>
			<TailwindModal
				open={open}
				setOpen={setOpen}
				title="Review Partner Content"
				className="w-[951px] bg-[#F5F4F4] p-4 rounded-[16px] "
			>
				{isLoading ? (
					<div className="flex h-full items-center justify-center">
						<CircularProgress disableShrink />
					</div>
				) : (
					<>
						<div className="flex flex-col sm:flex-col md:flex-row lg:flex-row mt-3 w-full justify-between">
							<div className="flex flex-col gap-4 w-auto sm:w-auto md:w-[380px] lg:w-[390px]">
								<PreviewDetails data={data} />
							</div>
							<div className="flex flex-col  w-auto mt-2 sm:w-auto md:w-[340px] lg:w-[501px]">
								<PartnerDetails
									data={data}
									setAccepte={setAccepte}
									accepte={accepte}
									textValue={textValue}
									setTextValue={setTextValue}
								/>
							</div>
						</div>
						<div className="w-full flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row justify-between gap-4 mt-4">
							<Button
								type="button"
								label="Decline Selected"
								color="white"
								className="!min-w-[191px] sm:!min-w-[151px] md:!min-w-[151px] lg:!min-w-[191px] xl:!min-w-[191px]"
								onClick={() => setOpenModalDecline(true)}
								iconRight={Icons.warning}
								buttonTextClassName="md:text-[13px] lg:text-[11px] xl:text-[14px] sm:text-[11px] "
							/>
							<Button
								type="button"
								label="Copy as a Draft"
								color="white"
								className="!min-w-[191px] sm:!min-w-[151px] md:!min-w-[151px] lg:!min-w-[191px] xl:!min-w-[191px]"
								onClick={() => setOpenModalCopy(true)}
								helper="Copy item to your Content Hub to edit and/or publish later."
								classPanelHelper="!top-[-50px]"
								buttonTextClassName="md:text-[13px] lg:text-[11px] xl:text-[14px] sm:text-[11px] "
							/>
							<Button
								type="button"
								label="Publish Now"
								color="white"
								className="!min-w-[191px] sm:!min-w-[151px]  md:!min-w-[151px] lg:!min-w-[191px] xl:!min-w-[191px]"
								onClick={() => {
									if (onActionsClick && data?.partner_organization_content_id) {
										onActionsClick?.(
											{
												status: 'published',
												is_approved: true,
												message: textValue
											},
											data?.partner_organization_content_id,
											{ title: data?.title }
										)
									}
								}}
								helper="Publish this item now to ALL of your followers. Once published you will not be able to re-configure or enhance this item."
								classPanelHelper="!top-[-83px]"
								buttonTextClassName="md:text-[13px] lg:text-[11px] xl:text-[14px] sm:text-[11px] "
							/>
							<Button
								type="button"
								label="Accept & Configure"
								color="Primary"
								className="!min-w-[191px] sm:!min-w-[151px] md:!min-w-[151px] lg:!min-w-[191px] xl:!min-w-[191px]"
								onClick={() => handleNavigate()}
								helper="Accept this content item as is and then configure with your own delivery/engagement enhancements (Push Notifications, Audience, etc.)"
								fillpat
								classPanelHelper="!top-[-90px]"
								buttonTextClassName="md:text-[13px] lg:text-[11px] xl:text-[14px] sm:text-[11px] "
							/>
						</div>
					</>
				)}
			</TailwindModal>

			<DeclineModal
				open={openModalDecline}
				setOpen={setOpenModalDecline}
				onClickConfirm={onActionsClick}
				idSharedConten={data?.partner_organization_content_id}
				setOpenModalDecline={setOpenModalDecline}
			/>

			<CopyModal
				setOpen={setOpenModalCopy}
				open={openModalCopy}
				onActionsCopy={onActionsClick}
				idCopy={data?.partner_organization_content_id}
				setOpenReview={setOpen}
				textValue={textValue}
				organizationPartnerId={data?.organization_partner_id}
				contentId={data?.content_id}
			/>
		</div>
	)
}
