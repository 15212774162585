import { CircularProgress } from '@mui/material'
import { GET_DASHBOARD_BUILD_APPS, getBuildAppsDashboard } from 'api'
import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	Title,
	Tooltip
} from 'chart.js'
import { Box, GroupButton } from 'components'
import { BoxComponentProps, DataGraphicProps } from 'interfaces'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const AppBuildsFollowers: React.FC<BoxComponentProps> = ({
	styles
}): JSX.Element => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const optionsToRender = useMemo(
		() => [
			{ name: '7 days', value: 'last_seven_days' },
			{ name: '6 months', value: 'six_months' },
			{ name: '12 months', value: 'twelve_months' }
		],
		[]
	)
	const graphOptions = useMemo(() => {
		return {
			maintainAspectRatio: false,
			responsive: true,
			plugins: {
				legend: {
					display: false
				}
			},
			scales: {
				x: {
					ticks: {
						color: '#000000',
						font: {
							family: 'Roboto Condensed'
						}
					}
				},
				y: {
					beginAtZero: true,
					ticks: {
						color: '#000000',
						font: {
							family: 'Roboto Condensed',
							size: 12
						}
					}
				}
			}
		}
	}, [])
	const [period, setPeriod] = useState(optionsToRender[0].value)

	const [dataGraphic, setDataGraphic] = useState<DataGraphicProps>()
	const chartRef = useRef<
		ChartJS<'bar', number[], string> & { toBase64Image(): string }
	>(null)

	const { data, isLoading } = useQuery(
		[GET_DASHBOARD_BUILD_APPS, userId, period],
		() => getBuildAppsDashboard(period)
	)

	useEffect(() => {
		if (data) {
			if (period === 'last_seven_days') {
				setDataGraphic({
					labels: data.map(item => item.name),
					datasets: [
						{
							label: optionsToRender[0].value,
							data: data.map(item => item.value),
							backgroundColor: '#17B3C5'
						}
					]
				})
			} else {
				setDataGraphic({
					labels: data.map(item => item.month_name),
					datasets: [
						{
							label: optionsToRender[0].value,
							data: data.map(item => item.total),
							backgroundColor: '#17B3C5'
						}
					]
				})
			}
		}
	}, [data])

	return (
		<div className={styles}>
			<Box className="flex flex-col justify-between w-full h-[274px] p-[unset] px-[18px] py-2">
				<>
					<div className="flex justify-between items-center">
						<GroupButton
							period={period}
							setPeriod={setPeriod}
							options={optionsToRender}
						/>
						{/* <Button
							label="Save as image"
							iconLeft={Icons.download}
							color="Transparent"
							className="!min-w-[unset] !w-[168px] !h-8 !px-[unset] !py-[unset]"
							onClick={handleExportClick}
						/> */}
					</div>
					<div className="w-full flex justify-center h-[190px]">
						{isLoading && (
							<div className="flex items-center justify-center w-full h-[200px] py-2 px-1 ">
								<CircularProgress disableShrink />
							</div>
						)}
						{dataGraphic && !isLoading && (
							<Bar
								data={dataGraphic}
								options={graphOptions}
								style={{ display: 'inline-block' }}
								ref={chartRef}
							/>
						)}
					</div>
				</>
			</Box>
		</div>
	)
}
