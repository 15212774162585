import { API } from 'api'
import {
	AssentsAllResponse,
	ContentListResponse,
	ContentListSharedResponse,
	ContentPerformanceResponse,
	ContentResponse,
	DynamicListResponse,
	StaticListResponse,
	TypeOrder,
	UpdateImageResponse,
	moduleCreate
} from 'interfaces'

export const getContents = async (
	organizationId: number,
	period: string
): Promise<ContentPerformanceResponse | null> => {
	let res = null as ContentPerformanceResponse | null
	await API()
		.get(
			`/contents/organization/${organizationId}/performances?period=${period}`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
				res = error.response.data
			}
		)
	return res
}

export const getAssetsAll = async (
	organizationId: number,
	page: number
): Promise<AssentsAllResponse | null> => {
	let res = null as AssentsAllResponse | null
	await API()
		.get(
			`/contents/assets/organization/${organizationId}?page=${page}&filter_by=image&size=10`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
				res = error.response.data
			}
		)
	return res
}

export const getUploadImageConten = async (
	slug: string,
	module?: moduleCreate
): Promise<UpdateImageResponse | null> => {
	let res = null as UpdateImageResponse | null
	await API()
		.get(`/upload/${module ?? 'contents'}/images?slug=${slug}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)

	return res
}

export const getUploadFileConten = async (
	slug: string,
	module?: moduleCreate
): Promise<UpdateImageResponse | null> => {
	let res = null as UpdateImageResponse | null
	await API()
		.get(`/upload/${module ?? 'contents'}/documents?slug=${slug}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)

	return res
}

export const getContentsList = async (
	organizationId: number,
	page: number,
	status: string,
	contentType: string,
	search: string,
	column: string,
	order: TypeOrder,
	size?: number
): Promise<ContentListResponse | undefined> => {
	let res = undefined as ContentListResponse | undefined
	const getStatus = status !== '' ? `&content_status=${status}` : ''
	const getType = contentType !== '' ? `&content_category=${contentType}` : ''
	const getSearch = search !== '' ? `&search=${search}` : ''
	const getColumn = column !== '' ? `&column_order=${column}` : ''
	const getOrder = order !== null ? `&order_by=${order}` : ''
	const getSize = size !== null ? `&size=${size}` : ''
	await API()
		.get(
			`/contents/organizations/${organizationId}?page=${page}${getStatus}${getType}${getSearch}${getColumn}${getOrder}${getSize}&list_by=only_unpinned`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
				res = error.response.data
			}
		)
	return res
}

export const getContentsListPinned = async (
	organizationId: number,
	page: number,
	status: string,
	contentType: string,
	fmStatus: string,
	search: string,
	size?: number
): Promise<ContentListResponse | undefined> => {
	let res = undefined as ContentListResponse | undefined
	const getStatus = status !== '' ? `&content_status=${status}` : ''
	const getType = contentType !== '' ? `&content_category=${contentType}` : ''
	const getFmStatus = fmStatus !== '' ? `&fm_status=${fmStatus}` : ''
	const getSearch = search !== '' ? `&search=${search}` : ''
	const getSize = size !== null ? `&size=${size}` : ''

	await API()
		.get(
			`/contents/organizations/${organizationId}?page=${page}${getStatus}${getType}${getFmStatus}${getSearch}${getSize}&column_order=order&order_by=asc&list_by=only_pinned`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
				res = error.response.data
			}
		)
	return res
}

export const getNotificationList = async (
	organizationId: number,
	page: number,
	status: string,
	fmStatus: string,
	search: string,
	column: string,
	order: TypeOrder
): Promise<ContentListResponse | undefined> => {
	let res = undefined as ContentListResponse | undefined
	const getStatus = status !== '' ? `&notification_status=${status}` : ''
	const getFmStatus = fmStatus !== '' ? `&fm_status=${fmStatus}` : ''
	const getSearch = search !== '' ? `&search=${search}` : ''
	const getColum = column !== '' ? `&column_order=${column}` : ''
	const getOrder = order !== null ? `&order_by=${order}` : ''
	await API()
		.get(
			`/notifications/organizations/${organizationId}?page=${page}${getStatus}${getFmStatus}${getSearch}${getColum}${getOrder}`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
				res = error.response.data
			}
		)
	return res
}

export const getContentById = async (
	organizationId: number,
	contentId: number | undefined
): Promise<ContentResponse | undefined> => {
	let res = undefined as ContentResponse | undefined
	if (contentId) {
		await API()
			.get(`/contents/${contentId}/organizations/${organizationId}`)
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.error(error)
					res = error.response.data
				}
			)
	}
	return res
}

export const getContentSharedById = async (
	organizationId: number,
	contentId: number | undefined
): Promise<ContentListSharedResponse | undefined> => {
	let res = undefined as ContentListSharedResponse | undefined
	if (contentId) {
		await API()
			.get(
				`/contents/organization_partner/organizations/${organizationId}/partner_content/${contentId}`
			)
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.error(error)
					res = error.response.data
				}
			)
	}
	return res
}

export const getContentShared = async (
	organizationId: number,
	filters?: {
		page?: number
		search?: string
		pageSize?: number
		type?: string
		is_approved: boolean
	}
): Promise<ContentListResponse | undefined> => {
	let res = undefined as ContentListResponse | undefined
	await API()
		.get(
			`/contents/organization_partner/organizations/${organizationId}/partner_content`,
			{
				params: {
					size: filters?.pageSize,
					search: filters?.search,
					is_approved: filters?.is_approved,
					page: filters?.page,
					type: filters?.type
				}
			}
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
				res = error.response.data
			}
		)
	return res
}

export const getStaticList = async (
	organizationId: number,
	page: number,
	search?: string
): Promise<StaticListResponse | null> => {
	let res = null as StaticListResponse | null
	await API()
		.get(
			`/people/static_list/organization/${organizationId}?page=${page}&search=${
				search ?? ''
			}`
		)
		.then(response => {
			res = response.data
		})
		.catch(error => console.warn(error))
	return res
}

export const getDinamycList = async (
	organizationId: number,
	page: number,
	searchValue: string
): Promise<DynamicListResponse | null> => {
	let res = null
	const getSearch =
		searchValue && searchValue.length > 0 ? `&search=${searchValue}` : ''
	await API()
		.get(
			`/people/dynamic_list/organization/${organizationId}?size=10&page=${page.toString()}${getSearch}`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const getDynamicList = async (
	organizationId: number
): Promise<StaticListResponse | null> => {
	let res = null as StaticListResponse | null
	await API()
		.get(`/people/dynamic_list/organization/${organizationId}`)
		.then(response => {
			res = response.data
		})
		.catch(error => console.warn(error))
	return res
}

export const getContentsListSelect = async (
	organizationId: number,
	column: string,
	order: TypeOrder,
	queryParams?: { content_status?: string }
): Promise<ContentListResponse | undefined> => {
	let res = undefined as ContentListResponse | undefined
	const getColumn = column !== '' ? `&column_order=${column}` : ''
	const getOrder = order !== null ? `&order_by=${order}` : ''
	const params = queryParams ? { ...queryParams } : {}
	await API()
		.get(`/contents/organizations/${organizationId}?${getColumn}${getOrder}`, {
			params
		})
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
				res = error.response.data
			}
		)
	return res
}
