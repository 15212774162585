import { Button, Checkbox, DropdownFull, Input, Message } from 'components'
import {
	DropdownFullItemProps,
	OrganizationTagFormProps,
	TagForm
} from 'interfaces'
import React from 'react'
import { useForm } from 'react-hook-form'

export const OrganizationTagForm: React.FC<OrganizationTagFormProps> = ({
	parents,
	onSubmit,
	onCancel,
	tag: tagToEdit,
	createdTags,
	disabledNestUnder
}) => {
	const {
		register,
		watch,
		setValue,
		handleSubmit,
		formState: { errors }
	} = useForm<TagForm>({
		defaultValues: {
			...tagToEdit,
			nestUnder: !disabledNestUnder && Boolean(tagToEdit?.parentTag)
		}
	})
	const [nestUnder, parentTag] = watch(['nestUnder', 'parentTag'])

	const parentTags: DropdownFullItemProps[] =
		parents?.map(tag => ({ id: tag.name, name: tag.name })) || []

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="flex flex-col gap-4 w-full"
		>
			<span className="font-semibold text-base leading-6">
				{tagToEdit ? 'Edit Tag' : 'New Tag'}
			</span>
			<Input
				name="name"
				placeholder="Add Tag Name"
				title={tagToEdit ? 'Tag name:' : 'Please enter a new tag name:'}
				register={register}
				rules={{
					required: 'Tag name is required',
					validate: (value: string) => {
						return createdTags?.find(
							tag => tag === value && tag !== tagToEdit?.name
						)
							? 'A tag with this name already exists'
							: true
					}
				}}
				error={errors.name}
			/>
			<div className="flex gap-2 items-center">
				<Checkbox
					disabled={disabledNestUnder}
					id="nest_under_check"
					{...register('nestUnder', {
						onChange: () => setValue('parentTag', null)
					})}
					checked={nestUnder}
				/>
				<label htmlFor="nest_under_check">Nest Under:</label>
			</div>
			<div className="w-full">
				<DropdownFull
					menuClassName="!static border"
					disabled={!nestUnder}
					list={parentTags}
					item={{
						id: parentTag || '',
						name:
							parentTag ||
							(parentTags.length === 0
								? 'No tags available'
								: 'Please select a Parent Tag')
					}}
					setItem={tag => setValue('parentTag', tag.name)}
				/>
				{errors.parentTag?.message && (
					<Message text={errors.parentTag.message} type="error" />
				)}
			</div>
			<div className="flex justify-between">
				<Button
					className="min-w-[40%]"
					color="Transparent"
					label="Cancel"
					onClick={onCancel}
				/>
				<Button type="submit" className="min-w-[40%]" label="Save" />
			</div>
		</form>
	)
}
