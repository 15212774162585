import { GET_LIST_LOCATIONS_FOLLOWERS } from 'api'
import { getLocationsListFollowers } from 'api/get/locations'
import {
	BoxSwitch,
	DropdownFull,
	Icon,
	Image,
	LibraryImage,
	Slide,
	Typography
} from 'components'
import {
	CreateNewContentProps,
	DropdownFullItemProps,
	Icons,
	ItemDataAssentsAll,
	TypographyTypes
} from 'interfaces'
import { contentTypeData } from 'lib'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'
import { regexVimeoOrYoutube } from 'utils'
import { ContentAssetSlot } from './contentAssetButton'
import VideoPlayer from './videoCard'

export const CreateNewContent: React.FC<CreateNewContentProps> = ({
	totalData,
	listImage,
	setTotalData,
	setListImage,
	mode,
	typeFunction = 'Content',
	videoId,
	setVideoId,
	setValueInput,
	valueInput,
	setOpenDocument,
	documentsList
}): JSX.Element => {
	const [saving, setSaving] = useState(false)
	const [sharing, setSharing] = useState(false)
	const [openSlide, setOpenSlide] = useState(false)
	const [slideImages, setSlideImages] = useState(false)
	const [slideLibraryImages, setSlideLibraryImages] = useState(false)
	const [isCropSettingsVisible, setIsCropSettingsVisible] = useState(true)
	const [isFinishCropButtonVisible, setIsFinishCropButtonVisible] =
		useState(true)
	const [isCropComplete, setIsCropComplete] = useState(false)
	const [contentType, setContentType] = useState<DropdownFullItemProps>({
		id: 0,
		name:
			typeFunction === 'Content' ? 'Content Type' : 'Add to an existing list'
	})
	const [dataContentType, setDataContentType] = useState<
		DropdownFullItemProps[]
	>([])
	const cookies = new Cookies()
	const [nameSlide, setNameSlide] = useState('')

	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const [sendImageCropped, setSendImageCropped] = useState<
		ItemDataAssentsAll[]
	>([])
	const [itemSelected, setItemSelected] = useState<DropdownFullItemProps[]>([])

	useEffect(() => {
		if (!openSlide) {
			setNameSlide('')
			setSlideImages(false)
			setSlideLibraryImages(false)
		}
	}, [openSlide])

	useEffect(() => {
		setTotalData({
			...totalData,
			basic_setup: {
				...totalData.basic_setup,
				category: contentType.value ? contentType.value : ''
			}
		})
	}, [contentType])

	useEffect(() => {
		setTotalData({
			...totalData,
			basic_setup: {
				...totalData.basic_setup,
				saving
			}
		})
	}, [saving])

	useEffect(() => {
		setTotalData({
			...totalData,
			basic_setup: {
				...totalData.basic_setup,
				sharing
			}
		})
	}, [sharing])

	useEffect(() => {
		if (totalData.basic_setup.category !== '') {
			const dataCurrent =
				typeFunction === 'Content' ? contentTypeData : dataContentType
			const aux = dataCurrent.find(item =>
				typeFunction === 'Content'
					? item.value === totalData.basic_setup.category
					: (item.id as string) === totalData.basic_setup.category
			)

			if (aux !== undefined) {
				setContentType(aux)
			}
		}
		setSharing(totalData.basic_setup.sharing)
		setSaving(totalData.basic_setup.saving)
		if (totalData.basic_setup.preview) {
			setListImage(totalData.basic_setup.preview.files)
			setSendImageCropped(totalData.basic_setup.preview.files)
			setVideoId?.(totalData.basic_setup.preview.video_link_external)
		}
	}, [totalData, dataContentType])

	const { data: dataListsLocations } = useQuery(
		[GET_LIST_LOCATIONS_FOLLOWERS, userId, organizationId],
		() => getLocationsListFollowers(organizationId),
		{
			enabled: typeFunction === 'Location'
		}
	)

	useEffect(() => {
		if (dataListsLocations && dataListsLocations.items.length > 0) {
			const dataInputSelect = dataListsLocations.items.map(element => {
				return {
					id: element.id,
					name: element.name,
					value: element.id.toString()
				}
			})
			setDataContentType(dataInputSelect)
		} else {
			setDataContentType([])
		}
	}, [dataListsLocations])

	useEffect(() => {
		const itemsToRender =
			(mode === 'create' || mode === 'edit') && contentType.id !== 0
				? [
						{
							id: 0,
							name: 'Without Any Location List'
						},
						...dataContentType
				  ]
				: dataContentType
		setItemSelected(itemsToRender)
	}, [totalData, dataContentType])

	const hasImages = Boolean(listImage?.length)

	const hasVideo =
		!hasImages &&
		(regexVimeoOrYoutube.test(videoId as string) ||
			regexVimeoOrYoutube.test(valueInput as string))

	const hasPdf = !hasImages && !hasVideo && !!documentsList?.length
	const emptyAssets = !hasImages && !hasVideo && !hasPdf

	return (
		<div className="flex-col h-full relative">
			<Slide
				title={nameSlide}
				open={openSlide}
				scroll={slideLibraryImages}
				setOpen={setOpenSlide}
				classNameTitle="!font-bold"
				wide={false}
			>
				<>
					{slideImages && (
						<Image
							sendImageCropped={sendImageCropped}
							setSendImageCropped={setSendImageCropped}
							isCropSettingsVisible={isCropSettingsVisible}
							setIsCropSettingsVisible={setIsCropSettingsVisible}
							setIsFinishCropButtonVisible={setIsFinishCropButtonVisible}
							isFinishCropButtonVisible={isFinishCropButtonVisible}
							isCropComplete={isCropComplete}
							setIsCropComplete={setIsCropComplete}
							totalData={totalData}
							listImage={listImage}
							setOpenSlide={setOpenSlide}
							setTotalData={setTotalData}
							setListImage={setListImage}
							setSlideImages={state => {
								setNameSlide('Crop Image')
								setSlideImages(state)
							}}
							setSlideLibraryImages={state => {
								setNameSlide('Add Images From Library')
								setSlideLibraryImages(state)
							}}
							mode={mode}
							setVideoId={setVideoId}
							videoId={videoId}
							setValueInput={setValueInput}
							valueInput={valueInput}
						/>
					)}
					{slideLibraryImages && (
						<LibraryImage
							setSendImageCropped={setSendImageCropped}
							listImage={listImage}
							setOpenSlide={setOpenSlide}
							setListImage={setListImage}
							setSlideImages={state => {
								setNameSlide('')
								setSlideImages(state)
							}}
							setSlideLibraryImages={state => {
								setNameSlide('')
								setSlideLibraryImages(state)
							}}
						/>
					)}
				</>
			</Slide>
			<div className="flex flex-col h-full">
				<Typography
					className="text-gray-9"
					type={TypographyTypes.h2}
					title={`${mode === 'create' ? 'Create New' : 'Edit'} ${typeFunction}`}
					fontLeading="31/37"
				/>
				<Typography
					title="Preview:"
					type={TypographyTypes.h3}
					className="text-lavender"
					fontLeading="18/21"
				/>

				{typeFunction === 'Content' ? (
					<ContentAssetSlot
						setSlideImages={setSlideImages}
						setOpenSlide={setOpenSlide}
						emptyAssets={Boolean(emptyAssets)}
						hasImages={hasImages}
						hasPdf={Boolean(hasPdf)}
						hasVideo={Boolean(hasVideo)}
						onClickPdf={() => {
							setOpenDocument?.(true)
						}}
						onClickMedia={() => {
							setOpenSlide(true)
							setSlideImages(true)
							setNameSlide('Crop Image')
						}}
						imageSrc={listImage?.[0]?.asset_url}
						videoSrc={videoId || valueInput}
					/>
				) : (
					<div className="border-blue-primary border-solid border-[2px] rounded-xl min-w-full">
						{!hasImages && !hasVideo && (
							<div className="bg-gray-11 px-[61px] py-36 w-full h-[528px] rounded-xl">
								<button
									type="button"
									onClick={() => {
										setOpenSlide(true)
										setSlideImages(true)
										setNameSlide('Crop Image')
									}}
									className="flex items-center justify-center py-3 bg-white shadow-boxPreview rounded w-full "
								>
									<div>
										<Typography
											fontLeading="15/18"
											type={TypographyTypes.p}
											title="Click here to add an"
											className="!font-normal text-lavender"
										/>
										<Typography
											fontLeading="15/22"
											type={TypographyTypes.p}
											title="Image."
											className="!font-semibold text-lavender"
										/>
									</div>
									<Icon src={Icons.uploadCloud} className="w-[26px] pl-2 " />
								</button>
							</div>
						)}

						{hasImages && (
							<button
								type="button"
								className="flex w-full items-center justify-center"
								onClick={() => {
									setOpenSlide(true)
									setSlideImages(true)
									setNameSlide('Crop Image')
								}}
							>
								<img
									src={listImage[0].asset_url}
									alt="preview"
									className="1lg:max-w-[544.28px] max-w-[330.28px] min-w-[204.73px] max-h-[23rem]  py-3 px-7"
								/>
							</button>
						)}
						{!hasImages && hasVideo && (
							<VideoPlayer
								videoUrl={videoId || valueInput}
								setSlideImages={setSlideImages}
								setOpenSlide={setOpenSlide}
							/>
						)}
					</div>
				)}

				<DropdownFull
					className="mt-11"
					list={typeFunction === 'Content' ? contentTypeData : itemSelected}
					item={contentType}
					setItem={setContentType}
				/>
				{typeFunction === 'Content' && (
					<div>
						<BoxSwitch
							border
							title="Sharing"
							enabled={sharing}
							icon={Icons.share}
							setEnabled={setSharing}
						/>
						<BoxSwitch
							border
							title="Saving"
							enabled={saving}
							icon={Icons.bookmark}
							setEnabled={setSaving}
						/>
					</div>
				)}
			</div>
		</div>
	)
}
