import { API } from 'api/config'
import { MessageResponse, NetworkPartnerPatchResponse } from 'interfaces'

export const deletePartnerList = async (
	organizationId: number,
	partnerListId: number
): Promise<NetworkPartnerPatchResponse | null> => {
	let res = null
	await API()
		.delete(`/partner_list/${organizationId}/list/${partnerListId}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const deleteMyFeeds = async (
	organizationId: number,
	myFeedId: number
): Promise<MessageResponse | null> => {
	let res = null
	await API()
		.delete(`/feeds/${myFeedId}/organization/${organizationId}`)
		.then(async response => {
			res = response.data
		})
		.catch(error => {
			res = { detail: error.response.data.detail, message: '' }
		})
	return res
}
