import { Table } from 'components'
import {
	ItemLocations,
	LocationListResponse,
	ParentTableProps
} from 'interfaces'
import React, { useCallback, useMemo } from 'react'
import { Column, Row } from 'react-table'

export const LocationListsTable: React.FC<
	ParentTableProps<LocationListResponse>
> = ({
	data,
	page,
	setPage,
	isLoading,
	setDataSelected,
	className,
	dataSelected,
	listToEdit
}) => {
	const columns = useMemo(
		(): Column<ItemLocations>[] => [
			{ Header: 'Locations', accessor: 'title', disableSortBy: true }
		],
		[]
	)

	const handleRowSelect = useCallback<
		(rows: Row<ItemLocations>[], selected: boolean) => void
	>((rows, selected) => {
		const ids = rows.map(row => row.original.id)
		if (selected) {
			setDataSelected?.(prev => [...prev, ...ids])
		} else {
			setDataSelected?.(prev =>
				prev.filter(partnerId => !ids.includes(partnerId))
			)
		}
	}, [])

	const getChecked = (id: number): boolean => {
		return dataSelected?.includes(id) ?? false
	}

	return (
		<div className={className}>
			<Table<ItemLocations>
				data={data?.items ?? []}
				page={page}
				columns={columns}
				customWidth={[95]}
				bodyTableClassName="!h-[calc(100vh_-_598px)] overflow-y-auto"
				noDataMessage="No Result Found"
				justify="left"
				isFetching={isLoading}
				onPageChange={setPage}
				selectionCustomWidth={0}
				onRowSelect={handleRowSelect}
				rowSelected={row => getChecked(row.original.id) ?? false}
				showPagination
				total={data?.total}
				pages={data?.size}
				hideSelectionHeader
				customWidthOverride
				rowDisabled={row => {
					return !!(
						row.original.location_list_id &&
						!getChecked?.(row.original.id) &&
						row.original.location_list_id !== listToEdit
					)
				}}
			/>
		</div>
	)
}
