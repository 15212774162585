import clsx from 'clsx'
import { Typography } from 'components'
import { GroupButtonProps, TypographyTypes } from 'interfaces'
import React from 'react'
import { calculateWidth } from 'utils'

export const GroupButton: React.FC<GroupButtonProps> = ({
	options,
	period,
	setPeriod,
	className,
	buttonClassName,
	big = false,
	huge = false,
	barBotton = false,
	numberAproval,
	buttomNormal,
	resetFilter,
	containerOptionsClassName
}) => {
	const dataNumber = options.filter(
		element => element.value === 'Partner Content'
	)
	const isBig = big ? '15/18' : '12/15'
	const isHuge = huge ? '18/21' : isBig

	return (
		<div
			className={clsx(
				className,
				'flex gap-4 shadow-[0px_3px_3px_rgba(0,_0,_0,_0.1)] rounded-[8px] bg-white  overflow-x-auto md:!overflow-x-hidden sm:item-center sm:justify-center',
				big && 'px-3',
				!huge ? 'md:w-max' : 'md:w-full'
			)}
		>
			<div
				className={clsx(
					'flex overflow-x-auto md:!overflow-x-hidden lg:!overflow-x-auto xl:!overflow-x-hidden xl:item-center xl:justify-center  px-4',
					containerOptionsClassName
				)}
			>
				{options.map((item, index) => {
					return (
						<div
							className={clsx(
								buttonClassName,
								big ? 'flex flex-col' : undefined
							)}
							key={`renderedOption${index + 1}`}
							style={{
								width: calculateWidth(big, huge, barBotton, options.length)
							}}
						>
							<button
								type="button"
								onClick={() => {
									setPeriod(item.value)
									if (item.value === 'My People' && resetFilter) {
										resetFilter()
									}
								}}
								className={clsx(
									'px-1 py-[10px] flex justify-center items-center max-h-[35px]',
									(big || huge || barBotton) && 'w-full',
									buttomNormal || 'min-w-[100px]'
								)}
							>
								{!!dataNumber.length && item.name === 'Partner Content' && (
									<p className="bg-blue-primary rounded-2xl w-[25px] h-[22px] text-white text-sm flex items-center justify-center ">
										{`${numberAproval}`}
									</p>
								)}

								<Typography
									className={clsx(
										'sm:font-medium px-2',
										period === item.value ? 'text-blue-primary' : 'text-gray-9'
									)}
									type={TypographyTypes.span}
									fontLeading={isHuge}
									title={item.name}
								/>
							</button>
							{(big || huge || barBotton) && period === item.value && (
								<div className="w-full h-1 rounded-[2px] bg-blue-primary" />
							)}
						</div>
					)
				})}
			</div>
		</div>
	)
}
