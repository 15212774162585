import React from 'react'
import { AdminTeamActionsCell } from './AdminTeamActionsCell'

interface AdminTeamCellProps {
	onDelete: (id: number) => void
	onEdit: (id: number) => void
}

const AdminTeamCell: React.FC<AdminTeamCellProps> = ({ onDelete, onEdit }) => {
	return <AdminTeamActionsCell onDelete={onDelete} onEdit={onEdit} />
}
export default AdminTeamCell
