/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * TODO:
 * Schedule appointment for review.
 */

import {
	GET_FM_PARTNER_LIST_FOLLOWERS_FOLLOWERS,
	GET_FM_PARTNER_LIST_FOLLOWERS_PARTNERS,
	deletePartnerList,
	getFMPartnerList,
	getFMPartnerListFollowers,
	getFMPartnerListPartners,
	patchEditPartneList,
	postNewPartnerList
} from 'api'
import {
	Button,
	FmPartnerListTableFollowers,
	FmPartnerListTablePartners,
	Input,
	TailwindModal
} from 'components'
import { useSearchDebounce, useToast } from 'hooks'
import {
	EditPartnerListRequest,
	FMPartnerListFollowersResponse,
	FMPartnerListPartnersResponse,
	GenericModalProps,
	Icons,
	NewPartnerListRequest
} from 'interfaces'
import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'
import { DelectListParnert } from './DelectListParnert'

const MAX_SEARCH_LENGTH = 100

export const FmPartnerListsModal: React.FC<GenericModalProps<any>> = ({
	open,
	setOpen
}): JSX.Element => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const [newListValue, setNewListValue] = useState('')
	const [search, setSearch] = useState('')
	const { inputSearch, handleChangeDebounce, resetInput } =
		useSearchDebounce('')
	const [dataSelected, setDataSelected] = useState<number[]>([])
	const [partnersPage, setPartnersPage] = useState(1)
	const [followersPage, setFollowersPage] = useState(1)
	const [listToDelete, setListToDelete] = useState<number | null>(null)
	const [listToEdit, setListToEdit] = useState<number>(0)

	const {
		data: partnersData,
		isLoading: partnersIsLoading,
		refetch: partnersRefetch
	} = useQuery(
		[
			GET_FM_PARTNER_LIST_FOLLOWERS_PARTNERS,
			{ page: partnersPage, search: inputSearch.trim(), userId, organizationId }
		],
		() =>
			getFMPartnerListPartners(organizationId, {
				page: partnersPage,
				search: inputSearch.trim()
			})
	)

	const {
		data: followersData,
		isLoading: followersIsLoading,
		refetch: refetchFollowers
	} = useQuery(
		[
			GET_FM_PARTNER_LIST_FOLLOWERS_FOLLOWERS,
			followersPage,
			userId,
			organizationId
		],
		() => getFMPartnerListFollowers(organizationId, followersPage)
	)

	const { data: partnerlistData } = useQuery(
		[
			GET_FM_PARTNER_LIST_FOLLOWERS_FOLLOWERS,
			listToEdit,
			userId,
			organizationId
		],
		() => getFMPartnerList(organizationId, listToEdit)
	)

	const { showToast } = useToast()

	const submitHandler = useCallback(
		async (body: NewPartnerListRequest): Promise<void> => {
			postNewPartnerList(organizationId, body).then(response => {
				if (response) {
					showToast('Success', 'New list created successfully', 'success')
					refetchFollowers()
					partnersRefetch()
					setDataSelected([])
					setNewListValue('')
					setOpen(!open)
				}
			})
		},
		[]
	)

	const submitHandlerEdit = async (
		body: EditPartnerListRequest
	): Promise<void> => {
		patchEditPartneList(organizationId, listToEdit, body).then(response => {
			if (response) {
				showToast('Success', 'List edit successfully', 'success')
				refetchFollowers()
				partnersRefetch()
				setDataSelected([])
				setNewListValue('')
				setOpen(!open)
			}
		})
	}

	const deleteList = async (listId: number): Promise<void> => {
		const res = await deletePartnerList(organizationId, listId)
		showToast(
			res ? 'Deleted' : 'Error',
			res ? 'List deleted successfully' : 'Error deleting list',
			res ? 'success' : 'error'
		)
		refetchFollowers()
	}

	useEffect(() => {
		if (partnerlistData && listToEdit) {
			setNewListValue(partnerlistData.name)

			const filteredArray = partnerlistData.partners?.filter(
				partnerlistItem => {
					return partnersData?.items.some(
						partnersItem =>
							partnersItem.organization_partner_id ===
							partnerlistItem.organization_partner_id
					)
				}
			)

			const uniqueIds: any = []
			filteredArray?.forEach(item => {
				if (!uniqueIds.includes(item.organization_partner_id)) {
					uniqueIds.push(item.organization_partner_id)
				}
			})

			const selectedIds = uniqueIds ?? []
			setDataSelected(selectedIds)
		}
	}, [partnerlistData, listToEdit])

	const handleClick = (): void => {
		if (partnerlistData && listToEdit) {
			submitHandlerEdit({
				name: newListValue,
				organization_partner_ids: dataSelected
			})
		} else {
			submitHandler({
				name: newListValue,
				organization_partner_ids: dataSelected
			})
		}
	}
	return (
		<>
			<TailwindModal
				open={open}
				setOpen={setOpen}
				title="FM Partner Lists"
				className="md:w-[95vw] w-full p-4 rounded-[16px] h-[87vh]"
			>
				<>
					<div className="flex flex-col gap-6 w-full md:h-[calc(100%_-_134px)] h-[calc(100%_-_164px)] mt-4 overflow-y-auto">
						<Input
							name="message"
							title="New list name"
							placeholder="Text here"
							className="!w-full !p-[unset]"
							value={newListValue}
							onChange={event => setNewListValue(event.target.value)}
							maxLength={MAX_SEARCH_LENGTH}
							error={
								newListValue.length >= MAX_SEARCH_LENGTH
									? {
											message: `Maximum ${MAX_SEARCH_LENGTH} characters`,
											type: 'maxLength'
									  }
									: undefined
							}
							inputClassName="!p-[unset] !py-1 !px-2"
							inputFocus
						/>
						<Input
							name="search"
							placeholder="Search"
							className="!w-full !p-[unset]"
							onChange={e => {
								setSearch(e.target.value)
								handleChangeDebounce(e)
							}}
							rightIcon={Icons.cancel}
							rightClick={() => {
								resetInput()
								setSearch('')
							}}
							value={search}
							maxLength={MAX_SEARCH_LENGTH}
							inputClassName="!p-[unset] !py-1 !px-2"
							inputFocus
							error={
								search.length >= MAX_SEARCH_LENGTH
									? {
											message: `Maximum ${MAX_SEARCH_LENGTH} characters`,
											type: 'maxLength'
									  }
									: undefined
							}
							borderFull={false}
							search
						/>
						<div className="w-full flex gap-4 flex-col md:flex-row">
							<FmPartnerListTableFollowers
								data={
									followersData as FMPartnerListFollowersResponse | undefined
								}
								isLoading={followersIsLoading}
								className="basis-[50%]"
								page={followersPage}
								onDelete={setListToDelete}
								onEdit={setListToEdit}
								setPage={setFollowersPage}
							/>
							<FmPartnerListTablePartners
								data={partnersData as FMPartnerListPartnersResponse | undefined}
								isLoading={partnersIsLoading}
								className="basis-[50%]"
								page={partnersPage}
								setPage={setPartnersPage}
								setDataSelected={setDataSelected}
								dataSelected={dataSelected}
							/>
						</div>
					</div>
					<div className="flex justify-center items-center mt-4 gap-4 w-full md:flex-row flex-col-reverse">
						<Button
							label="Cancel"
							color="LavenderLight"
							onClick={() => setOpen(false)}
						/>
						<Button
							label="Save"
							disabled={newListValue === '' || dataSelected.length === 0}
							onClick={() => handleClick()}
						/>
					</div>
				</>
			</TailwindModal>
			<DelectListParnert
				open={listToDelete !== null}
				setOpen={() => setListToDelete(null)}
				onClickConfirm={() => {
					if (listToDelete) deleteList(listToDelete)
					setListToDelete(null)
				}}
			/>
		</>
	)
}
