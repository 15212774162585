import { Dialog, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { Button, Helper, Typography } from 'components'
import { Icons, ModalProps, TypographyTypes } from 'interfaces'
import React, { Fragment } from 'react'

export const TailwindModal: React.FC<ModalProps> = ({
	className,
	children,
	title,
	titleClassName,
	open,
	setOpen,
	showCloseButton,
	titleHelper,
	helper,
	fillpat,
	handleCancel,
	isUpdate,
	onClose,
	closeOnOverlayTap = true,
	id
}) => {
	return (
		<Transition appear show={open} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-[2000]"
				onClose={() => {
					if (closeOnOverlayTap) {
						setOpen(false)
					}
				}}
				id={id}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black-1 bg-opacity-25" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel
								className={clsx(
									className,
									'transform overflow-hidden bg-white shadow-xl transition-all text-left'
								)}
							>
								{title && (
									<div className="w-full flex items-center justify-between">
										<div className="min-w-[12%] flex  justify-between">
											<Typography
												className={clsx(
													titleClassName,
													'text-gray-14 font-semibold'
												)}
												type={TypographyTypes.h3}
												fontLeading="14/24"
												title={title}
											/>
											{helper && (
												<div className="ml-2">
													<Helper
														text={titleHelper as string}
														className="relative border-none text-sm"
														filpath={fillpat}
														classPanel="min-w-[300px]  ml-2"
													/>
												</div>
											)}
										</div>

										<Button
											iconLeft={Icons.cancelBig}
											leftIconDivClassName="!w-[26px] !h-[26px]"
											className="!w-max !min-w-[unset] !px-[unset] !py-[unset] !border-none"
											color="Gray3"
											aria-label="Cancel modal"
											onClick={() => {
												setOpen(false)
												if (onClose) {
													onClose()
												}
											}}
										/>
									</div>
								)}
								{showCloseButton && (
									<Button
										iconLeft={Icons.cancelBig}
										leftIconDivClassName="!w-[26px] !h-[26px]"
										className="!w-max !min-w-[unset] mt-2 mr-2 absolute top-0 right-0 !px-[unset] !py-[unset] !border-none"
										color="Gray3"
										onClick={() => {
											if (isUpdate) {
												handleCancel?.()
											} else {
												setOpen(false)
											}
										}}
										aria-label="Close Modal"
									/>
								)}
								{children}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}
