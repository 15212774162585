/* eslint-disable @typescript-eslint/no-explicit-any */
import { CircularProgress } from '@mui/material'
import clsx from 'clsx'
import { Button } from 'components/buttons'
import { DateTimer } from 'components/dateTimer'
import { DropdownFull } from 'components/dropdown'
import { Icon } from 'components/icon'
import { Input } from 'components/input'
import {
	DinamicListFormProps,
	DropdownFullItemProps,
	Icons,
	ListTags
} from 'interfaces'
import {
	typeAndOR,
	typeOS,
	typeOperatorDiffDate,
	typeOperatorPushAndLoc,
	typeOperatorSigupDate,
	typePropierty,
	typePushAndLoc,
	typeStatus
} from 'lib/dataMypeoplefilter'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { TailwindModal } from '../tailwindModal'

export const DinamicListForm: React.FC<DinamicListFormProps> = ({
	open,
	setOpen,
	onCloseModal,
	handleSubmit,
	onSubmit,
	register,
	rules,
	errors,
	setValue,
	rows,
	getValue,
	resetRow,
	tags,
	deleteRow,
	addRow,
	resetFilter,
	typeState,
	isValid,
	isvalidForm,
	isLoading,
	loadingTags
}) => {
	const [dataTags, setDataTags] = useState<ListTags[] | null>(null)
	const [dateSelected, setDateSelected] = useState(false)
	const [selectedDate, setSelectedDate] = useState<
		string | Date | moment.Moment
	>()

	useEffect(() => {
		if (rows?.length) {
			const currentDate = rows[0]?.value ?? moment().format('MM/DD/YYYY')
			setSelectedDate(currentDate as string)
		}
	}, [])

	useEffect(() => {
		if (tags && tags?.length > 0) {
			setDataTags(tags)
		}
	}, [tags])

	const handleSearch = (value: string): void => {
		const response = tags?.filter(elements => {
			const name = elements.label.toUpperCase()
			return name.includes(value.toUpperCase())
		})

		if (value) setDataTags(response as ListTags[])
		if (!value) setDataTags(tags as ListTags[])
	}

	const posibleOptions = [
		'active_location',
		'is_active',
		'notification_push',
		'device_type'
	]

	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			title="Filter"
			className={clsx(
				'w-[60rem]  p-4 rounded-[16px] flex flex-col overflow-y-auto',
				rows && rows?.length > 7 ? 'h-[100%]' : ''
			)}
			helper
			titleHelper="Filter by user’s properties, then save Dynamic List, Export users or save the resulting users as a Static List"
			onClose={onCloseModal}
		>
			<div>
				{isLoading ? (
					<div className="flex items-center justify-center w-full h-[200px] py-2 px-1">
						<CircularProgress disableShrink />
					</div>
				) : (
					<form onSubmit={handleSubmit(onSubmit)}>
						{typeState === 'Edit' && (
							<div>
								<Input
									name="name"
									title="List Name"
									register={register}
									rules={rules.name}
									placeholder="Name"
									error={errors.name}
									className="my-4"
									limit
									cantLimit={200}
									watchValue={isvalidForm}
									setValueInput={setValue}
								/>
							</div>
						)}
						<div className="border-2 w-full rounded-md mb-3 mt-3 min-h-[15rem] overflow-x-auto sm:overflow-x-visible ">
							{rows?.map((row: any, index: number) => (
								<div
									className="flex justify-between mt-4 mb-4 w-[31.25rem] sm:!w-full p-2"
									key={`${index}_${row?.propierty?.value}`}
								>
									<div>
										<DropdownFull
											item={row?.comparator}
											list={typeAndOR}
											setItem={valor => {
												getValue(index, 'comparator', valor)
											}}
											className={index === 0 ? 'invisible' : '!min-w-[1.25rem]'}
										/>
									</div>

									<div>
										<DropdownFull
											item={row?.propierty}
											list={typePropierty}
											setItem={valor => getValue(index, 'propierty', valor)}
											className="w-[6.938rem] sm:!min-w-[12.125rem] sm:!max-w-[12.125rem]"
											menuClassName="max-h-[8rem]"
										/>
									</div>
									<div>
										<DropdownFull
											item={row?.operator}
											list={
												posibleOptions.includes(row?.propierty?.value)
													? typeOperatorPushAndLoc
													: row?.propierty?.value === 'created_at'
													? typeOperatorSigupDate
													: typeOperatorDiffDate
											}
											setItem={valor => getValue(index, 'operator', valor)}
											className="w-[6.938rem] sm:min-w-[12.125rem] sm:!max-w-[12.125rem]"
											menuClassName="max-h-[8rem]"
										/>
									</div>
									{row?.propierty?.value === 'active_location' ||
									row?.propierty?.value === 'notification_push' ? (
										<div>
											<DropdownFull
												item={row?.value as DropdownFullItemProps}
												list={typePushAndLoc}
												setItem={valor => getValue(index, 'value', valor)}
												className="w-[5.938rem] sm:min-w-[12.938rem] sm:!max-w-[12.938rem]"
												menuClassName="max-h-[8rem]"
											/>
										</div>
									) : row?.propierty?.value === 'tag' &&
									  tags &&
									  (row?.operator?.name === 'Is' ||
											row?.operator?.name === 'Is Not') ? (
										<div>
											<Select
												options={dataTags as ListTags[]}
												placeholder="Search tag"
												// eslint-disable-next-line @typescript-eslint/no-explicit-any
												onInputChange={(e: any) => handleSearch(e)}
												// eslint-disable-next-line @typescript-eslint/no-explicit-any
												onChange={(e: any) => {
													getValue(index, 'value', e || '')
												}}
												value={dataTags?.find(
													element => element.value === Number(row?.value?.value)
												)}
												isLoading={loadingTags}
												isClearable
												className="w-[5.938rem] sm:min-w-[20rem] sm:!max-w-[20rem] "
												styles={{
													menuList: base => ({
														...base,
														height: '10rem',
														color: '#929BB2'
													}),
													singleValue: base => ({
														...base,
														color: '#929BB2'
													})
												}}
											/>
										</div>
									) : row?.propierty?.value === 'is_active' ? (
										<div>
											<DropdownFull
												item={row?.value as DropdownFullItemProps}
												list={typeStatus}
												setItem={valor => getValue(index, 'value', valor)}
												className="w-[5.938rem] sm:min-w-[12.938rem] sm:!max-w-[12.938rem]"
												menuClassName="max-h-[8rem]"
											/>
										</div>
									) : row?.propierty?.value === 'device_type' ? (
										<div>
											<DropdownFull
												item={row?.value as DropdownFullItemProps}
												list={typeOS}
												setItem={valor => getValue(index, 'value', valor)}
												className="w-[5.938rem] sm:min-w-[12.938rem] sm:!max-w-[12.938rem]"
												menuClassName="max-h-[8rem]"
											/>
										</div>
									) : row?.propierty?.value === 'created_at' ? (
										<div>
											<DateTimer
												value={selectedDate}
												validate={moment('01/01/1900', 'MM/DD/YYYY')}
												className={clsx(
													'mt-1.5',
													dateSelected ? 'text-black-1' : 'text-lavender'
												)}
												icon={Icons.calendar}
												setDate={value => {
													setSelectedDate(value as string)
													getValue(
														index,
														'value',
														value as DropdownFullItemProps &
															(string | number | DropdownFullItemProps)
													)
													setDateSelected(true)
												}}
												initialValue={moment().format('MM/DD/YYYY')}
												timeFormat={false}
											/>
										</div>
									) : (
										<div>
											<Input
												name="title"
												type="text"
												borderFull={false}
												inputClassName="!p-0 rounded w-[5.938rem] sm:min-w-[12.938rem] sm:!max-w-[12.938rem]"
												titleClassName="!text-[12px] !leading-[12px]"
												maxLength={60}
												value={
													typeof row?.value === 'string' ||
													typeof row?.value === 'number'
														? row?.value
														: row?.value?.value
												}
												onChange={e =>
													getValue(
														index,
														'value',
														e.target.value as DropdownFullItemProps &
															(string | number | DropdownFullItemProps)
													)
												}
											/>
										</div>
									)}

									<div className="flex">
										<button type="button" onClick={() => resetRow?.(index)}>
											<Icon
												src={Icons.resetInfo}
												fillPath
												className="text-gray-9 max-h-[30px] w-[30px]"
											/>
										</button>
										{rows?.length !== 1 && (
											<button type="button" onClick={() => deleteRow(index)}>
												<Icon
													src={Icons.deleteX}
													fillPath
													className="text-gray-9 max-h-[30px] w-[30px]"
												/>
											</button>
										)}
									</div>
								</div>
							))}
							<hr className="bg-gray-2 w-full" />
							<div className=" flex justify-end p-3 w-[31.25rem] sm:w-full ">
								<Button
									label="Add parameter"
									color="white"
									onClick={() => addRow()}
									className="!min-w-[unset] !min-h-[2rem]"
									disabled={rows && rows?.length === 10}
								/>
							</div>
						</div>

						<div className="flex-grow" />

						<div className="w-full flex flex-col sm:flex-row justify-between gap-4 ">
							<Button
								label="Reset Filter"
								color="Bg-gray-2"
								onClick={() => {
									if (resetFilter) {
										resetFilter()
									}
								}}
								className="!min-w-[16.438rem] "
							/>
							<Button
								label={
									typeState === 'Create' ? 'View Results' : 'Save List Dinamic'
								}
								type="submit"
								className="!min-w-[16.438rem] "
								disabled={!isValid || isvalidForm === ''}
							/>
						</div>
					</form>
				)}
			</div>
		</TailwindModal>
	)
}
