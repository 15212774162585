/* eslint-disable no-param-reassign */
import { PixelCrop } from 'react-image-crop'

const TO_RADIANS = Math.PI / 180

export async function canvasPreview(
	image: HTMLImageElement,
	canvas: HTMLCanvasElement,
	crop: PixelCrop,
	scale = 1,
	rotate = 0,
	flipVertical = false,
	flipHorizontal = false
): Promise<string> {
	return new Promise((resolve, reject) => {
		const ctx = canvas.getContext('2d')

		if (!ctx) {
			reject(new Error('No 2d context'))
			return
		}
		const scaleX = image.naturalWidth / image.width
		const scaleY = image.naturalHeight / image.height
		const pixelRatio = window.devicePixelRatio

		canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
		canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

		ctx.scale(pixelRatio, pixelRatio)
		ctx.imageSmoothingQuality = 'high'

		const cropX = crop.x * scaleX
		const cropY = crop.y * scaleY

		const rotateRads = rotate * TO_RADIANS
		const centerX = image.naturalWidth / 2
		const centerY = image.naturalHeight / 2

		ctx.save()

		if (flipHorizontal) {
			ctx.translate(canvas.width, 0)
			ctx.scale(-1, 1)
		}
		if (flipVertical) {
			ctx.translate(0, canvas.height)
			ctx.scale(1, -1)
		}

		ctx.translate(-cropX, -cropY)
		ctx.translate(centerX, centerY)
		ctx.rotate(rotateRads)
		ctx.scale(scale, scale)
		ctx.translate(-centerX, -centerY)
		ctx.drawImage(
			image,
			0,
			0,
			image.naturalWidth,
			image.naturalHeight,
			0,
			0,
			image.naturalWidth,
			image.naturalHeight
		)

		ctx.restore()

		// Get the cropped image data as a base64-encoded JPEG
		canvas.toBlob(
			blob => {
				const reader = new FileReader()
				reader.onload = () => {
					if (reader.result && typeof reader.result === 'string') {
						resolve(reader.result)
					} else {
						reject(new Error('Failed to convert cropped image to base64'))
					}
				}
				reader.onerror = error => {
					reject(error)
				}
				reader.readAsDataURL(blob as Blob)
			},
			'image/jpeg',
			1.0
		)
	})
}
