import { Icon, Typography } from 'components'
import { PerformanceBarProps, TypographyTypes } from 'interfaces'
import { formatNumber } from 'lib'
import React from 'react'

export const AnimatedProgressBar: React.FC<PerformanceBarProps> = ({
	name,
	icon,
	showPercent = false,
	showValue = true,
	showComparison = false,
	value, // new
	maxValue // old
}): JSX.Element => {
	const realValue = value === null ? 0 : value
	const realMaxValue = maxValue === null || maxValue === 0 ? 1 : maxValue
	const total = (realValue * 100) / realMaxValue
	return (
		<div className="w-full flex flex-col">
			<div className="w-full flex justify-between items-center">
				<div>
					{name && (
						<Typography
							title={name}
							fontLeading="12/15"
							type={TypographyTypes.h3}
						/>
					)}
					{icon && (
						<div className="w-[28px] h-[32px]">
							<Icon src={icon} fillPath className="text-blue-primary" />
						</div>
					)}
				</div>
				<div>
					{showPercent && (
						<div className="flex gap-2 items-center">
							<Typography
								title={`${((realValue / realMaxValue) * 100)
									.toFixed(2)
									.toString()}%`}
								fontLeading="22/27"
								type={TypographyTypes.h3}
								className="text-gray-9"
							/>
							<Typography
								title="Achieved"
								fontLeading="12/16"
								type={TypographyTypes.span}
								className="text-gray-9"
							/>
						</div>
					)}
					{showValue && (
						<Typography
							title={value !== null ? value.toString() : '0'}
							fontLeading="14/24"
							type={TypographyTypes.h3}
						/>
					)}
				</div>
			</div>
			<div className="w-full bg-gray-3 rounded-full h-[4px]">
				<div
					className="bg-blue-primary h-full rounded-full transition-all duration-100 ease-linear"
					style={{
						width: `${total > 100 ? 100 : total}%`
					}}
				/>
			</div>
			{showComparison && (
				<div className="flex flex-col mt-[15px] gap-3">
					<div>
						<Typography
							title="Current Followers: "
							fontLeading="15/18"
							type={TypographyTypes.span}
							className="text-black-1 !font-normal"
						/>
						<Typography
							title={formatNumber(value as number)}
							fontLeading="15/18"
							type={TypographyTypes.span}
							className="text-black-1"
						/>
					</div>
					<div>
						<Typography
							title="Follower's Goal: "
							fontLeading="15/18"
							type={TypographyTypes.span}
							className="text-black-1 !font-normal"
						/>
						<Typography
							title={formatNumber(maxValue as number)}
							fontLeading="15/18"
							type={TypographyTypes.span}
							className="text-black-1"
						/>
					</div>
				</div>
			)}
		</div>
	)
}
