import { API } from 'api/config'
import {
	AcceptOutboundSubscriptionsRequest,
	MessageResponse,
	NewMyFeedsRequest,
	NewPartnerListRequest,
	SendMessageToNewPartnersRequest,
	SendMessageToNewPartnersResponse
} from 'interfaces'

export const postNewOutboundPartnerTable = async (
	organizationId: number,
	body: SendMessageToNewPartnersRequest
): Promise<SendMessageToNewPartnersResponse | null> => {
	let res = null as SendMessageToNewPartnersResponse | null
	await API()
		.post(`/network/partner/${organizationId}`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const postNewOutboundSubscriptionTable = async (
	organizationId: number,
	body: AcceptOutboundSubscriptionsRequest
): Promise<MessageResponse | null> => {
	let res = null as MessageResponse | null
	await API()
		.post(`/network/suscription/${organizationId}`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const postNewPartnerList = async (
	organizationId: number,
	body: NewPartnerListRequest
): Promise<MessageResponse | null> => {
	let res = null as MessageResponse | null
	await API()
		.post(`/partner_list/${organizationId}`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const postNewMyFeeds = async (
	organizationId: number,
	body: NewMyFeedsRequest
): Promise<MessageResponse | null> => {
	let res = null as MessageResponse | null
	await API()
		.post(`/organization/feeds/${organizationId}`, body)
		.then(async response => {
			res = response.data
		})
		.catch(error => {
			res = { detail: error.response.data.detail, message: '' }
		})
	return res
}
