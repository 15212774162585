import { CircularProgress } from '@mui/material'
import { Button } from 'components/buttons'
import { Icon } from 'components/icon'
import { Typography } from 'components/typography'
import {
	Icons,
	TypographyTypes,
	UnsuscribedPartnerModalProps
} from 'interfaces'
import React from 'react'
import { TailwindModal } from '../tailwindModal'

export const UnsubcribePartnerModal: React.FC<UnsuscribedPartnerModalProps> = ({
	open,
	onClose,
	isLoading,
	onActionClick
}) => {
	return (
		<TailwindModal
			open={open}
			setOpen={onClose}
			className="w-[240px] h-max p-2 rounded-2xl flex flex-col gap-4 items-center"
		>
			<>
				<div className="flex w-full justify-between">
					<Typography
						fontLeading="18/21"
						title="Actions"
						type={TypographyTypes.h3}
					/>
					<button type="button" onClick={onClose}>
						<Icon
							src={Icons.cancel}
							fillPath
							className="text-blue-primary max-h-[20px] w-[20px] "
						/>
					</button>
				</div>
				{isLoading ? (
					<CircularProgress className="my-20" disableShrink />
				) : (
					<>
						<Button
							label="Unsubcribe"
							className="!min-w-[unset] !w-full !p-2  !font-semibold"
							color="Primary"
							onClick={() => onActionClick('disconnect')}
						/>

						<Button
							label="Cancel"
							className="!min-w-[unset] !w-full !py-2 !font-semibold"
							color="Transparent"
							onClick={onClose}
						/>
					</>
				)}
			</>
		</TailwindModal>
	)
}
