import { Popover } from '@headlessui/react'
import clsx from 'clsx'
import { Icon, Typography } from 'components'
import { HelperProps, Icons, TypographyTypes } from 'interfaces'
import React, { useState } from 'react'
import { usePopper } from 'react-popper'

export const Helper: React.FC<HelperProps> = ({
	text,
	className,
	classPanel,
	filpath = false
}): JSX.Element => {
	const [referenceElement, setReferenceElement] =
		useState<HTMLButtonElement | null>(null)
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
		null
	)
	const { styles, attributes } = usePopper(referenceElement, popperElement)

	return (
		<Popover className={className}>
			<Popover.Button
				className="flex items-center"
				ref={setReferenceElement}
				aria-label="button helper"
			>
				<Icon
					src={Icons.help}
					fillPath={filpath}
					className="w-5 h-5 text-white "
				/>
			</Popover.Button>
			<Popover.Panel
				className={clsx(
					'absolute !top-[4px] z-10 bg-gray-3 px-4 py-2 min-w-[300px] rounded-2xl',
					classPanel
				)}
				ref={setPopperElement}
				style={styles.popper}
				{...attributes.popper}
			>
				<Typography
					type={TypographyTypes.span}
					fontLeading="15/18"
					title={text}
					className="text-white !font-bold"
				/>
			</Popover.Panel>
		</Popover>
	)
}
