import clsx from 'clsx'
import { ButtonGroupProps } from 'interfaces'
import React, { useEffect, useState } from 'react'
import Button from './button'

export const ButtonGroup: React.FC<ButtonGroupProps> = ({
	items,
	classStyle,
	setRadioButtonOption,
	radioFilter
}) => {
	const [selectedButtonId, setSelectedButtonId] = useState<number | null>(0)
	useEffect(() => {
		if (radioFilter) {
			const newItem = items.find(item => item.value === radioFilter)
			if (newItem) {
				setSelectedButtonId(newItem.id)
			}
		}
	}, [])
	return (
		<div className="overflow-x-auto ">
			<fieldset className={clsx(!classStyle && 'mt-4 ')}>
				<div
					className={clsx(
						classStyle ||
							'flex space-x-4 mb-2 sm:flex sm:items-center sm:space-x-3 md:space-x-3 lg:space-x-3 '
					)}
				>
					{items.map(item => (
						<div className="flex items-center" key={item.id}>
							<Button
								type="button"
								label={item.label}
								color="white"
								className={`!min-w-[130px] hover:text-white hover:bg-blue-primary ${
									selectedButtonId === item.id
										? 'bg-blue-primary !text-white'
										: ''
								}`}
								onClick={() => {
									setRadioButtonOption(item.value)
									setSelectedButtonId(item.id)
								}}
							/>
						</div>
					))}
				</div>
			</fieldset>
		</div>
	)
}
