import { CircularProgress } from '@mui/material'
import clsx from 'clsx'
import { GenericTable, Typography } from 'components'
import {
	AppVersionsTableResponse,
	ParentTableProps,
	TypographyTypes
} from 'interfaces'
import React from 'react'

export const BuildAppsAppVersionsTable: React.FC<
	ParentTableProps<AppVersionsTableResponse>
> = ({ data, className, isLoading, page, setPage }) => {
	return (
		<div className={clsx(className, 'flex flex-col w-full')}>
			{isLoading && (
				<div className="flex items-center justify-center w-full h-[calc(100vh_-_301px)] py-2 px-1 bg-white border-2 border-gray-7 rounded-lg">
					<CircularProgress disableShrink />
				</div>
			)}
			{data && data?.items.length === 0 && (
				<div className="flex items-center justify-center w-full h-[calc(100vh_-_301px)] py-2 px-1 bg-white border-2 border-gray-7 rounded-lg">
					<Typography
						title="No data found"
						type={TypographyTypes.h3}
						fontLeading="18/21"
						className="text-gray-7"
					/>
				</div>
			)}
			{data && data?.items.length > 0 && (
				<GenericTable
					data={data.items}
					backendPages={data.pages}
					page={page}
					setPage={setPage}
					headers={['Name', 'Version', 'OS', 'Date']}
					rows={['version_name', 'version_build', 'os_type', 'created_at']}
					table="appVersions"
					customWidth={[20, 15, 10, 24]}
					bodyTableClassName="!h-[calc(100vh_-_353px)]"
					justify="center"
				/>
			)}
		</div>
	)
}
