import clsx from 'clsx'
import { Icon } from 'components'
import { Icons, NotificationsProps } from 'interfaces'
import React from 'react'

export const Notifications: React.FC<NotificationsProps> = ({
	onClick,
	className
}): JSX.Element => {
	return (
		<button
			type="button"
			onClick={onClick}
			className={clsx(
				'border-2 border-blue-primary rounded-full py-[9px] px-3 max-w-max',
				className
			)}
		>
			<Icon
				fillPath
				src={Icons.notifications}
				className="w-4 text-blue-primary"
			/>
		</button>
	)
}
