import {
	PaginationContextDefaultType,
	PaginationContextType,
	TypeOrder
} from 'interfaces'
import { createContext, useState } from 'react'

const PaginationContextDefaultValue: PaginationContextType = {
	tables: {
		myPeople: {
			column: '',
			order: null
		},
		myLists: {
			column: '',
			order: null
		},
		tags: {
			column: '',
			order: null
		},
		tagsApproval: {
			column: '',
			order: null
		},
		myContentList: {
			column: '',
			order: null
		},
		appNames: {
			column: '',
			order: null
		},
		appVersions: {
			column: '',
			order: null
		},
		myLocationsList: {
			column: '',
			order: null
		},
		peopleBatches: {
			column: '',
			order: null
		}
	},
	setOrder: () => null
}
export const PaginationContext = createContext<PaginationContextType>(
	PaginationContextDefaultValue
)
export const useContextPagination = (): PaginationContextType => {
	const [columnValues, setColumnValues] =
		useState<PaginationContextDefaultType>({
			tables: PaginationContextDefaultValue.tables
		})

	const setOrder = (
		table:
			| 'myPeople'
			| 'myLists'
			| 'tags'
			| 'tagsApproval'
			| 'myContentList'
			| 'appNames'
			| 'appVersions'
			| 'myLocationsList'
			| 'peopleBatches',
		column: string,
		order: TypeOrder
	): void => {
		setColumnValues(prevState => {
			return {
				...prevState,
				tables: {
					...prevState.tables,
					[table]: {
						column,
						order
					}
				}
			}
		})
	}

	return {
		...columnValues,
		setOrder
	}
}
