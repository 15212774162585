import { API } from 'api'
import {
	EditDynamicListOptionsResponse,
	EditStaticListOptionsResponse,
	ExportResponse,
	GetPeopleTagsDeleteOrgResponse,
	GetPeopleTagsOrgResponse,
	PeopleMyFilterResponse,
	PeopleMyListsResponse,
	PeopleMyPeopleResponse,
	PeopleMyTagsChildrenResponse,
	PeopleMyTagsParentResponse,
	PeopleMyTagsResponse,
	TagsApprovalResponse,
	TagsUserRequest,
	TotalFollowersResponse,
	TypeOrder,
	UserInfoResponse,
	UsersPlatformResponse
} from 'interfaces'

export const getPeopleMyPeopleTable = async (
	organizationId: number,
	page: number,
	searchValue: string,
	column?: string,
	orderBy?: TypeOrder,
	filters?: string,
	size = 10
): Promise<PeopleMyPeopleResponse | null> => {
	let res = null
	await API()
		.get(
			`/people/people_list/${organizationId}
			?size=${size}
			&page=${page.toString()}
			${searchValue && searchValue.length > 0 ? `&search=${searchValue}` : ''}
			${column && column.length > 0 ? `&column_order=${column}` : ''}
			${orderBy && orderBy.length > 0 ? `&order_by=${orderBy}` : ''}${
				filters && filters !== '' ? `&filters=${filters}` : ''
			}&list_pending_tags=false
			`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const getExportMyPeople = async (
	organizationId: number,
	page: number,
	searchValue: string,
	column?: string,
	orderBy?: TypeOrder,
	filters?: string,
	idUser?: number[]
): Promise<ExportResponse | null> => {
	let res = null
	await API()
		.get(
			`/people/export_people_data/${organizationId}
			?page=${page.toString()}
			${searchValue && searchValue.length > 0 ? `&search=${searchValue}` : ''}
			${column && column.length > 0 ? `&column_order=${column}` : ''}
			${orderBy && orderBy.length > 0 ? `&order_by=${orderBy}` : ''}${
				filters ? `&filters=${filters}` : ''
			}${
				idUser ? `&organization_follower_ids=${idUser}` : ''
			}&list_pending_tags=false 
			`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const getPeopleMyListsTable = async (
	organizationId: number,
	page: number,
	searchValue: string,
	column?: string,
	orderBy?: TypeOrder
): Promise<PeopleMyListsResponse | null> => {
	let res = null
	await API()
		.get(
			`/people/organization/${organizationId}
			?size=10
			&page=${page.toString()}
			${searchValue && searchValue.length > 0 ? `&search=${searchValue}` : ''}
			${column && column.length > 0 ? `&column_order=${column}` : ''}
			${orderBy && orderBy.length > 0 ? `&order_by=${orderBy}` : ''}
			`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const getPeopleTagsTable = async (
	organizationId: number,
	page: number,
	searchValue: string,
	column?: string,
	orderBy?: TypeOrder,
	size?: number
): Promise<PeopleMyTagsParentResponse | null> => {
	let res = null
	await API()
		.get(
			`/people/tags/parent/${organizationId}
			?size=${size ?? 10}&page
			=${page.toString()}
			${searchValue && searchValue.length > 0 ? `&search=${searchValue}` : ''}
			${column && column.length > 0 ? `&column_order=${column}` : ''}
			${orderBy && orderBy.length > 0 ? `&order_by=${orderBy}` : ''}
			`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const getPeopleSubtagsTable = async (
	id: number | null
): Promise<PeopleMyTagsChildrenResponse | null> => {
	let res = null
	if (id) {
		await API()
			.get(`/people/tags/sub_tags/${id.toString()}?size=1000`)
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.warn('Error:', error.message)
				}
			)
	}
	return res
}

export const getPeopleTag = async (
	organizationId: number
): Promise<PeopleMyTagsResponse | null> => {
	let res = null
	if (organizationId) {
		await API()
			.get(`/people/tags/child_independent/${organizationId}?not_paginate=true`)
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.warn('Error:', error.message)
				}
			)
	}
	return res
}

export const getListFiltersId = async (
	organizationId: number,
	listId: number
): Promise<PeopleMyFilterResponse | null> => {
	let res = null
	if (organizationId && listId) {
		await API()
			.get(`/people/dynamic_list/${listId}/organization/${organizationId}`)
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.warn('Error:', error.message)
				}
			)
	}
	return res
}

export const getTotalFollowers = async (
	organizationId: number
): Promise<TotalFollowersResponse | null> => {
	let res = null
	await API()
		.get(`/people/total_followers/${organizationId}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const getUsersPlatform = async (
	organizationId: number
): Promise<UsersPlatformResponse | null> => {
	let res = null
	await API()
		.get(`/people/users_plataform/${organizationId}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const getEditStaticListOptions = async (
	organizationId: number,
	id: number,
	searchValue?: string
): Promise<EditStaticListOptionsResponse | null> => {
	let res = null
	await API()
		.get(
			`/people/static_list/${id.toString()}/organization/${organizationId}
			${searchValue && searchValue.length > 0 ? `?search=${searchValue}` : ''}
		`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const getEditDynamicListOptions = async (
	organizationId: number,
	id: number
): Promise<EditDynamicListOptionsResponse | null> => {
	let res = null
	await API()
		.get(`/people/dynamic_list/${id.toString()}/organization/${organizationId}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const getPeopleTags = async (
	organizationId: number
): Promise<GetPeopleTagsOrgResponse | null> => {
	let res = null
	await API()
		.get(`/people/tags/organization/${organizationId}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const getPeopleTagsExclude = async (
	organizationId: number,
	id: number,
	mode: string
): Promise<GetPeopleTagsDeleteOrgResponse | null> => {
	let res = null
	const queryString = `/people/tags/organization/${organizationId}?exclude_tag_id=${id}${
		mode ? `&mode=${mode}` : ''
	}`

	try {
		const response = await API().get(queryString)
		res = response.data
	} catch (error) {
		console.warn('Error:', error)
	}

	return res
}

export const getPeopleTagsEditExclude = async (
	organizationId: number,
	id: number
): Promise<GetPeopleTagsOrgResponse | null> => {
	let res = null
	await API()
		.get(`/people/tags/organization/${organizationId}?exclude_tag_id=${id}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const getOrgInfo = async (
	organizationId: number
): Promise<UserInfoResponse | null> => {
	let res = null
	await API()
		.get(`/organization/${organizationId}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const getTagsApprovalTable = async (
	organizationId: number,
	page?: number,
	searchValue?: string,
	column?: string,
	orderBy?: TypeOrder
	// size?: number
): Promise<TagsApprovalResponse | null> => {
	let res = null
	await API()
		.get(
			`/people/tag/review/organization/${organizationId}
			?size=10
			${page && page > 0 ? `&page=${page.toString()}` : ''}
			${searchValue && searchValue.length > 0 ? `&search=${searchValue}` : ''}
			${column && column.length > 0 ? `&column_order=${column}` : ''}
			${orderBy && orderBy.length > 0 ? `&order_by=${orderBy}` : ''}
			`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const getTagsApprovalDashboard = async (
	organizationId: number
): Promise<TagsApprovalResponse | null> => {
	let res = null
	await API()
		.get(
			`/people/tag/review/organization/${organizationId}?size=5&order_by=desc&column_order=created_at`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const getUserTags = async (
	organizationId: number,
	userId: number | undefined
): Promise<TagsUserRequest | null> => {
	let res = null
	await API()
		.get(`/user/${userId}/tags/organization/${organizationId}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}
