/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * TODO:
 * Schedule appointment for review.
 */

import { CircularProgress } from '@mui/material'
import { updateFollowerStatus } from 'api'
import { Button, Icon, TailwindModal, Typography } from 'components'
import { useToast } from 'hooks'
import {
	GenericModalProps,
	Icons,
	TypographyTypes,
	UpdateFollowerStatusRequest
} from 'interfaces'
import React, { useCallback, useState } from 'react'
import Cookies from 'universal-cookie'

export const MyPeopleRemoveModal: React.FC<GenericModalProps<any>> = ({
	open,
	setOpen,
	value,
	refetch
}) => {
	const [requestIsLoading, setRequestIsLoading] = useState(false)
	const { showToast } = useToast()
	const cookies = new Cookies()
	const organizationId = cookies.get('organization_id')
	const updateFollowerHandler = useCallback(
		async (body: UpdateFollowerStatusRequest): Promise<void> => {
			setRequestIsLoading(false)
			updateFollowerStatus(organizationId, value as number, body).then(
				response => {
					setRequestIsLoading(true)
					if (response) {
						showToast('Success', 'Follower deleted successfully.', 'success')
						if (refetch) {
							refetch()
						}
						setOpen(false)
					}
				}
			)
		},
		[]
	)
	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			showCloseButton
			className="w-[358px] h-[210px] sm:h-[160px] flex items-center justify-center px-2 py-4 rounded-[16px]"
		>
			{!requestIsLoading ? (
				<div className="flex flex-col gap-4 w-full rounded-[16px]">
					<div className="flex flex-col gap-3 w-full items-center">
						<div className="w-[30px] h-[30px]">
							<Icon
								src={Icons.warning}
								fillPath
								className="text-blue-primary"
							/>
						</div>
						<Typography
							title="Are you sure you want to remove a user?"
							fontLeading="15/18"
							type={TypographyTypes.h3}
							className="!font-normal text-black-1 text-center"
						/>
					</div>
					<div className="w-full flex flex-col sm:flex-row gap-4 mt-4 justify-between items-center">
						<Button
							label="Cancel"
							color="Transparent"
							onClick={() => setOpen(false)}
							className="w-full sm:!min-w-[unset] sm:!py-3 sm:!px-9"
						/>
						<Button
							label="Confirm"
							className="w-full sm:!min-w-[unset] sm:!py-3 sm:!px-9"
							onClick={() => updateFollowerHandler({ status: 'remove' })}
						/>
					</div>
				</div>
			) : (
				<CircularProgress disableShrink />
			)}
		</TailwindModal>
	)
}
