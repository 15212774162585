import { CircularProgress } from '@mui/material'
import clsx from 'clsx'
import { BranchSetupForm, Slide, Typography } from 'components'
import {
	AppNamesTableItem,
	AppNamesTableResponse,
	ParentTableProps,
	TypographyTypes
} from 'interfaces'
import React, { useState } from 'react'
import { AppNamesTable } from './appNamesTable'
import { BuildAppsAppNamesActionCell } from './buildAppsAppNamesActionCell'

export const BuildAppsAppNamesTable: React.FC<
	ParentTableProps<AppNamesTableResponse>
> = ({ data, className, isLoading, page, setPage, refetch }) => {
	const [openBranchSlide, setOpenBranchSlide] = useState(false)
	const [selectedAppBuild, setSelectedAppBuild] = useState<
		AppNamesTableItem | undefined | null
	>()
	return (
		<div className={clsx(className, 'flex flex-col w-full')}>
			{isLoading && (
				<div className="flex items-center justify-center w-full h-[calc(100vh_-_497px)] py-2 px-1 bg-white border-2 border-gray-7 rounded-lg">
					<CircularProgress disableShrink />
				</div>
			)}
			{!isLoading && data && data?.items.length === 0 && (
				<div className="flex items-center justify-center w-full h-[calc(100vh_-_497px)] py-2 px-1 bg-white border-2 border-gray-7 rounded-lg">
					<Typography
						title="No data found"
						type={TypographyTypes.h3}
						fontLeading="18/21"
						className="text-gray-7"
					/>
				</div>
			)}
			{!isLoading && data && data?.items.length > 0 && (
				<AppNamesTable
					data={data.items}
					backendPages={data.pages}
					page={page}
					setPage={setPage}
					customWidth={[20, 10, 10, 30]}
					actionColumnWidth={6}
					actionsButton={
						<BuildAppsAppNamesActionCell
							onClickBranch={cellData => {
								setSelectedAppBuild(cellData)
								setOpenBranchSlide(true)
							}}
						/>
					}
					bodyTableClassName="!h-[calc(100vh_-_615px)]"
					justify="center"
				/>
			)}
			<Slide
				open={openBranchSlide}
				setOpen={value => {
					setOpenBranchSlide(value)
					setSelectedAppBuild(null)
					refetch?.()
				}}
				title="AAC"
				classNameTitle="text-gray-font"
				closeIconColor="Gray2"
			>
				<div className="mt-5">
					<BranchSetupForm appBuild={selectedAppBuild} refetch={refetch} />
				</div>
			</Slide>
		</div>
	)
}
