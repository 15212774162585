import { Popover } from '@mui/material'
import { Box } from 'components/boxs'
import { Button } from 'components/buttons'
import { Icon } from 'components/icon'
import { Icons, OrganizationTableActionsProps } from 'interfaces'
import React, { useState } from 'react'

export const OrganizationsTableActionsCell: React.FC<
	OrganizationTableActionsProps
> = props => {
	const { onEditAdminTeam, onEditAppBuild, onEditInformation } = props
	const [button, setButton] = useState<HTMLButtonElement | null>(null)
	return (
		<>
			<button type="button" onClick={e => setButton(e.currentTarget)}>
				<Icon className="max-h-[20px] w-[20px]" fillPath src={Icons.pending} />
			</button>

			<Popover
				anchorEl={button}
				open={Boolean(button)}
				onClose={() => setButton(null)}
				PaperProps={{ className: '!shadow-none' }}
			>
				<Box className="flex !p-3 flex-col gap-3 border-2">
					<>
						<Button
							label="Edit Information"
							iconLeft={Icons.edit}
							onClick={onEditInformation}
							color="Transparent"
						/>
						<Button
							label="Edit Admin Team"
							iconLeft={Icons.edit}
							onClick={onEditAdminTeam}
							color="Transparent"
						/>
						<Button
							label="Edit App Build"
							iconLeft={Icons.edit}
							onClick={onEditAppBuild}
							color="Transparent"
						/>
					</>
				</Box>
			</Popover>
		</>
	)
}
