import { Popover } from '@mui/material'
import {
	Button,
	EditStaticListModal,
	MyListsRemoveModal,
	TailwindPopOver
} from 'components'
import FilterDinamicModal from 'components/modal/myPeople/FilterDinamicModal'
import { Icons, PeopleMyListsActionCellProps } from 'interfaces'
import React, { useState } from 'react'

export const PeopleMyListActionCell: React.FC<PeopleMyListsActionCellProps> = ({
	data,
	type,
	refetch,
	setDataFilter,
	setRows,
	rows,
	setOpenSaveList,
	onConvertData,
	propierty,
	operator,
	comparator,
	setIsSaveList,
	resetFilter,
	resetRow
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	const [myListEditStaticModal, setMyListEditStaticModal] = useState(false)
	const [myListEditDynamicModal, setMyListEditDynamicModal] = useState(false)
	const [myListDeleteModal, setMyListDeleteModal] = useState(false)

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = (): void => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined

	return (
		<div className="flex gap-1">
			<Button
				iconLeft={Icons.pending}
				className="!w-max !min-w-[unset] !px-1 py-1 !border-none"
				color="Gray2"
				leftIconDivClassName="w-[20px] h-[20px]"
				onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
					handleClick(event)
				}}
			/>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				PaperProps={{ style: { borderRadius: '16px' } }}
			>
				<TailwindPopOver handleClose={handleClose} className="w-[170px] h-max">
					<>
						<Button
							label="Edit"
							className="!min-w-[unset] !w-full !py-2"
							onClick={
								type === 'Static'
									? () => setMyListEditStaticModal(true)
									: type === 'Dynamic'
									? () => {
											setMyListEditDynamicModal(true)
									  }
									: () => null
							}
							iconLeft={Icons.edit}
							color="Transparent"
						/>
						{myListEditStaticModal && type === 'Static' && (
							<EditStaticListModal
								open={myListEditStaticModal}
								setOpen={setMyListEditStaticModal}
								data={data}
								refetch={refetch}
							/>
						)}
						{myListEditDynamicModal && type === 'Dynamic' && (
							<FilterDinamicModal
								setOpen={setMyListEditDynamicModal}
								open={myListEditDynamicModal}
								data={data}
								setDataFilter={setDataFilter}
								setRows={setRows}
								rows={rows}
								setOpenSaveList={setOpenSaveList}
								onConvertData={onConvertData}
								propierty={propierty}
								operator={operator}
								comparator={comparator}
								setIsSaveList={setIsSaveList}
								resetFilter={resetFilter}
								resetRow={resetRow}
								typeState="Edit"
								mylistTableRefetch={refetch}
							/>
						)}
						<Button
							label="Delete"
							className="!min-w-[unset] !w-full !py-2"
							onClick={() => setMyListDeleteModal(true)}
							iconLeft={Icons.delete}
							color="Transparent"
						/>
						{myListDeleteModal && (
							<MyListsRemoveModal
								open={myListDeleteModal}
								setOpen={setMyListDeleteModal}
								data={data}
								refetch={refetch}
								type={type}
							/>
						)}
					</>
				</TailwindPopOver>
			</Popover>
		</div>
	)
}
