import { ResetPassword } from 'components/login/ResetPassword'
import {
	AdminOrganization,
	AppBuilds,
	Content,
	CreateApp,
	CreateContent,
	CreateLocation,
	Dashboard,
	Location,
	Login,
	MyNetwork,
	MyOrganization,
	MyPeople,
	PeopleBatches,
	Settings,
	SuperAdminDashboard,
	SuperAdminSettings,
	Versions
} from 'pages'
import { PushNotification } from 'pages/PushNotification'

import {
	ContenManager,
	MyNetworkManager,
	MyPeopleManager,
	NoAuth,
	RequireUser,
	SuperAdminUser
} from 'provider'
import React, { useMemo, useState } from 'react'
import { Route, Routes } from 'react-router-dom'

export const RoutesElements = (): JSX.Element => {
	const [messageDashboard, setMessageDashboard] = useState<string>('')

	const optionsToRender = useMemo(
		() => [
			{ name: 'My People', value: 'My People' },
			{ name: 'My Lists', value: 'My Lists' },
			{ name: 'My Tags', value: 'My Tags' }
		],
		[]
	)
	const [period, setPeriod] = useState(optionsToRender[0].value)
	const [tagApprovalModal, setTagApprovalModal] = useState(false)
	const networkOptionsToRender = useMemo(
		() => [
			{ name: 'Partners', value: 'Partners' },
			{ name: 'Partner Feeds', value: 'Partner Feeds' },
			{ name: 'My Feeds', value: 'My Feeds' }
		],
		[]
	)
	const [networkPeriod, setNetworkPeriod] = useState(
		networkOptionsToRender[0].value
	)

	const currentContenOptionsToRender = useMemo(
		() => [
			{ name: 'Content Items', value: 'Content Items' },
			{ name: 'Push', value: 'Direct Pushes' }
		],
		[]
	)
	const onlyContenOptionsToRender = useMemo(
		() => [{ name: 'Content Items', value: 'Content Items' }],
		[]
	)
	const [contentPeriod, setContentPeriod] = useState(
		currentContenOptionsToRender[0].value
	)

	const contenOrPartnertOptionsToRender = useMemo(
		() => [
			{ name: 'My Content', value: 'My Content' },
			{ name: 'Partner Content', value: 'Partner Content' }
		],
		[]
	)
	const [contenOrPartnert, setContenOrPartnert] = useState(
		contenOrPartnertOptionsToRender[0].value
	)

	return (
		<Routes>
			<Route
				path="/login"
				element={
					<NoAuth>
						<Login />
					</NoAuth>
				}
			/>
			<Route
				path="/resetPassword/:id"
				element={
					<NoAuth>
						<ResetPassword />
					</NoAuth>
				}
			/>
			{/* Admin */}
			<Route
				path="/"
				element={
					<RequireUser>
						<Dashboard
							setPeriod={setPeriod}
							setNetworkPeriod={setNetworkPeriod}
							setTagApprovalModal={setTagApprovalModal}
						/>
					</RequireUser>
				}
			/>
			<Route
				path="/content"
				element={
					<RequireUser>
						<ContenManager>
							<Content
								optionsToRender={currentContenOptionsToRender}
								period={contentPeriod}
								setPeriod={setContentPeriod}
								optionsToRenderTwo={contenOrPartnertOptionsToRender}
								periodMyContent={contenOrPartnert}
								setPeriodMyconten={setContenOrPartnert}
								optionsToRenderContent={onlyContenOptionsToRender}
								setMessageDashboard={setMessageDashboard}
							/>
						</ContenManager>
					</RequireUser>
				}
			/>
			<Route
				path="/content/create"
				element={
					<RequireUser>
						<ContenManager>
							<CreateContent mode="create" />
						</ContenManager>
					</RequireUser>
				}
			/>
			<Route
				path="/content/edit/:id"
				element={
					<RequireUser>
						<ContenManager>
							<CreateContent mode="edit" />
						</ContenManager>
					</RequireUser>
				}
			/>
			<Route
				path="/content/push-notification"
				element={
					<RequireUser>
						<ContenManager>
							<PushNotification
								messageDashboard={messageDashboard}
								setMessageDashboard={setMessageDashboard}
							/>
						</ContenManager>
					</RequireUser>
				}
			/>
			<Route
				path="/content/push-notification/edit/:id"
				element={
					<RequireUser>
						<ContenManager>
							<PushNotification />
						</ContenManager>
					</RequireUser>
				}
			/>{' '}
			<Route
				path="/location"
				element={
					<RequireUser>
						<ContenManager>
							<Location
								optionsToRender={currentContenOptionsToRender}
								period={contentPeriod}
								setPeriod={setContentPeriod}
								optionsToRenderTwo={contenOrPartnertOptionsToRender}
								periodMyContent={contenOrPartnert}
								setPeriodMyconten={setContenOrPartnert}
								optionsToRenderContent={onlyContenOptionsToRender}
								setMessageDashboard={setMessageDashboard}
							/>
						</ContenManager>
					</RequireUser>
				}
			/>
			<Route
				path="/location/create"
				element={
					<RequireUser>
						<ContenManager>
							<CreateLocation mode="create" />
						</ContenManager>
					</RequireUser>
				}
			/>
			<Route
				path="/location/edit/:id"
				element={
					<RequireUser>
						<ContenManager>
							<CreateLocation mode="edit" />
						</ContenManager>
					</RequireUser>
				}
			/>
			<Route
				path="/people"
				element={
					<RequireUser>
						<MyPeopleManager>
							<MyPeople
								period={period}
								setPeriod={setPeriod}
								optionsToRender={optionsToRender}
								tagApprovalModal={tagApprovalModal}
								setTagApprovalModal={setTagApprovalModal}
							/>
						</MyPeopleManager>
					</RequireUser>
				}
			/>
			<Route
				path="/people/edit"
				element={
					<RequireUser>
						<MyPeopleManager>
							<PeopleBatches />
						</MyPeopleManager>
					</RequireUser>
				}
			/>
			<Route
				path="/network"
				element={
					<RequireUser>
						<MyNetworkManager>
							<MyNetwork
								optionsToRender={networkOptionsToRender}
								period={networkPeriod}
								setPeriod={setNetworkPeriod}
							/>
						</MyNetworkManager>
					</RequireUser>
				}
			/>
			<Route
				path="/organization"
				element={
					<RequireUser>
						<ContenManager>
							<MyOrganization />
						</ContenManager>
					</RequireUser>
				}
			/>
			<Route
				path="/settings"
				element={
					<RequireUser>
						<Settings />
					</RequireUser>
				}
			/>
			{/* Super Admin */}
			<Route
				path="/superAdmin/organization"
				element={
					<RequireUser>
						<SuperAdminUser>
							<SuperAdminDashboard />
						</SuperAdminUser>
					</RequireUser>
				}
			/>
			<Route
				path="/superAdmin/organization/create"
				element={
					<RequireUser>
						<SuperAdminUser>
							<AdminOrganization />
						</SuperAdminUser>
					</RequireUser>
				}
			/>
			<Route
				path="/superAdmin/settings"
				element={
					<RequireUser>
						<SuperAdminSettings />
					</RequireUser>
				}
			/>
			<Route
				path="/superAdmin/appBuilds"
				element={
					<RequireUser>
						<SuperAdminUser>
							<AppBuilds />
						</SuperAdminUser>
					</RequireUser>
				}
			/>
			<Route
				path="/superAdmin/appBuilds/version/:id"
				element={
					<RequireUser>
						<SuperAdminUser>
							<Versions />
						</SuperAdminUser>
					</RequireUser>
				}
			/>
			<Route
				path="/superAdmin/appBuilds/create-app"
				element={
					<RequireUser>
						<SuperAdminUser>
							<CreateApp />
						</SuperAdminUser>
					</RequireUser>
				}
			/>
		</Routes>
	)
}
