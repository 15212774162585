import { duplicateContent, updateContentsList } from 'api'
import {
	deleteNotification,
	duplicateNotification
} from 'api/post/pushNotification'
import clsx from 'clsx'
import { ContentActionCell, HeaderTableItem, Pagination } from 'components'
import { ConfirmDelete } from 'components/pushNotification/delete/ConfirmDelete'
import { useToast } from 'hooks'
import {
	ChildrenTableProps,
	ContentListItem,
	EditContentListBody,
	EditContentListResponse,
	PaginationType
} from 'interfaces'
import moment from 'moment'

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { formatLocalTime } from 'utils'

export const MyContentTable: React.FC<
	ChildrenTableProps<ContentListItem[]>
> = ({
	data,
	backendPages,
	headers,
	customWidth,
	justify = 'center',
	bodyTableClassName,
	actionsButton,
	actionColumn = 'left',
	actionColumnWidth = 5,
	page,
	setPage,
	refetch,
	setLoading,
	dataNotification,
	contentOrPartnet,
	isOrder,
	refetchPinned
	// setIsUserInitiated
}): JSX.Element => {
	// const [selectAll, setSelectAll] = useState(false)
	const navigate = useNavigate()
	const cookies = new Cookies()
	const { showToast } = useToast()
	const [listToDelete, setListToDelete] = useState<number | null>(null)
	const [messageModalToDelete, setMessageModalToDelete] = useState<{
		title: string
		id: number
		category: string
	} | null>(null)
	const [orderOpen, setOrderOpen] = useState<boolean>(false)
	const organizationId = cookies.get('organization_id')

	const onPageChanged = (pages: PaginationType): void => {
		const newPage = pages.currentPage
		if (setPage) {
			setPage(newPage)
		}
	}

	// se comenta porque se utilizara para exportar data
	// const handleSelectAll = (): void => {
	// 	if (data && dataSelected && setDataSelected) {
	// 		if (selectAll) {
	// 			setDataSelected([])
	// 			setSelectAll(false)
	// 		} else {
	// 			setDataSelected(data.map(row => row.organization_id))
	// 			setSelectAll(true)
	// 		}
	// 	}
	// }

	// const handleSelected = (itemID: number): void => {
	// 	if (dataSelected && setDataSelected) {
	// 		const newDataSelected = dataSelected.slice()
	// 		const dataIndex = dataSelected.indexOf(itemID)
	// 		if (dataIndex === -1) {
	// 			newDataSelected.push(itemID)
	// 		} else {
	// 			newDataSelected.splice(dataIndex, 1)
	// 		}
	// 		setDataSelected(newDataSelected)
	// 	}
	// }

	const editHandler = (
		id: number,
		category: string | null,
		type: string | null
	): void => {
		if (category) {
			navigate(`/content/edit/${id}`)
		} else if (!category && type === 'Content') {
			navigate(`/content/edit/${id}`)
		} else {
			navigate(`/content/push-notification/edit/${id}`)
		}
	}

	const handleRefetchTable = (
		res: EditContentListResponse | undefined
	): void => {
		if (res?.message) {
			if (refetch) {
				refetch()
			}
			if (setLoading) setLoading(false)
		}
	}

	const copyHandler = async (
		id: number,
		category: string | null
	): Promise<void> => {
		setLoading?.(true)
		if (category) {
			const res = await duplicateContent(organizationId, id)
			if (res) {
				refetch?.()
				refetchPinned?.()
			}

			setLoading?.(false)
		} else {
			const res = await duplicateNotification(organizationId, id)
			if (res?.id) {
				showToast('Success', 'Duplicate notification successfully', 'success')
				refetch?.()
				setLoading?.(false)
			}
		}
	}

	const deactivateHandler = async (
		id: number,
		val: 'draft' | 'deleted' | 'published'
	): Promise<void> => {
		if (setLoading) setLoading(true)
		const body = {
			status: val
		}
		const res = await updateContentsList(organizationId, id, body)
		handleRefetchTable(res)
		refetchPinned?.()
	}

	const deleteHandler = async (
		id: number,
		category: string | null
	): Promise<void> => {
		if (setLoading) setLoading(true)
		if (category) {
			const body = {
				status: 'deleted'
			} as EditContentListBody
			const res = await updateContentsList(organizationId, id, body)
			handleRefetchTable(res)
			setMessageModalToDelete(null)
			refetchPinned?.()
		} else {
			const res = await deleteNotification(organizationId, id)
			if (res?.message)
				showToast('Success', 'Notification successfully removed', 'success')

			handleRefetchTable(res)
		}
	}

	const pinHandler = async (id: number, val: boolean): Promise<void> => {
		try {
			if (setLoading) setLoading(true)
			const body = {
				is_pinned: val
			}
			await updateContentsList(organizationId, id, body)
			showToast('Content successfully pinned', 'Success', 'success')

			if (refetchPinned && refetch) {
				refetch?.()
				refetchPinned()
			}
		} catch (error) {
			console.warn(error)
		} finally {
			if (setLoading) setLoading(false)
		}
	}

	const pinHandlerOrder = async (
		id: number,
		val: boolean,
		order: number | null
	): Promise<void> => {
		try {
			const body = {
				is_pinned: val,
				order
			}
			const res = await updateContentsList(organizationId, id, body)
			if (res?.detail) {
				showToast(
					'Error',
					'There is already another content pinned with the same order',
					'error'
				)
			} else {
				showToast('Content successfully pinned', 'Success', 'success')
				if (isOrder) {
					if (refetchPinned) {
						refetchPinned()
					}
				}
			}
		} catch (error) {
			console.warn(error)
		}
	}

	const determineItemStatus = (
		status: string,
		releaseDate: string | null,
		contentCategory: string
	): string => {
		const contentCategories = ['event', 'info', 'take_action']

		if (
			status === 'finished' &&
			(contentCategory as string) &&
			contentCategories.includes(contentCategory as string)
		) {
			return 'Expired'
		}
		if (
			releaseDate !== null &&
			status !== 'finished' &&
			status !== 'draft' &&
			moment.utc(releaseDate).local().isBefore(moment())
		) {
			return 'Published'
		}
		if (
			releaseDate !== null &&
			status !== 'finished' &&
			status !== 'draft' &&
			moment.utc(releaseDate).local().isAfter(moment())
		) {
			return 'Scheduled'
		}
		return status
	}

	const callToAction = (): void => {
		if (messageModalToDelete !== null) {
			deleteHandler(messageModalToDelete.id, messageModalToDelete.category)
		} else {
			deleteHandler(listToDelete as number, '')
		}
		setListToDelete(null)
	}

	const calculateWidhtColumn = (): number => {
		const values = data?.map(e => e.order as number)
		const maxValues = Math.max(...(values as number[]))
		const total = 40
		const widthAction = (maxValues.toString().length / total) * 100

		return widthAction
	}

	return (
		<>
			<div className={clsx('w-full', backendPages === 1 && 'mb-[34px]')}>
				<div className="py-2 px-1 bg-white border-2 border-gray-7 rounded-lg w-full min-w-max">
					<table className="w-full min-h-max min-w-max">
						<thead className="flex w-full h-5 mb-3">
							<tr className="w-full flex justify-between items-center">
								{/* se comenta porque se utilizara para exportar data */}
								{/* {dataSelected && setDataSelected && (
									<th
										scope="col"
										className="flex items-center justify-center"
										style={{ flexBasis: `2%` }}
									>
										<input
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-blue-primary focus:ring-transparent-default cursor-pointer"
											checked={selectAll}
											onChange={handleSelectAll}
										/>
									</th>
								)} */}
								{actionsButton && actionColumn === 'left' && (
									<th
										scope="col"
										className="flex items-center justify-center text-[15px] leading-[18px] text-gray-9 font-bold cursor-pointer"
										style={{
											flexBasis: `${
												calculateWidhtColumn() || actionColumnWidth
											}%`
										}}
									>
										{dataNotification ? 'Action' : 'Actions'}
									</th>
								)}

								<HeaderTableItem
									header="Type"
									justify={justify}
									table="myContentList"
									column="content_category"
									style={
										customWidth
											? { flexBasis: `${customWidth[0]}%` }
											: { flexBasis: `${100 / 10}%` }
									}
								/>

								<HeaderTableItem
									header="Scheduled"
									justify={justify}
									table="myContentList"
									column={
										contentOrPartnet === 2 ? 'schedule' : 'repeat_start_date'
									}
									style={
										customWidth
											? { flexBasis: `${customWidth[1]}%` }
											: { flexBasis: `${100 / 9}%` }
									}
								/>
								<HeaderTableItem
									header={contentOrPartnet === 2 ? 'Organization' : 'Creator'}
									justify={justify}
									table="myContentList"
									column={contentOrPartnet === 2 ? 'organization' : 'creator'}
									style={
										customWidth
											? { flexBasis: `${customWidth[2]}%` }
											: { flexBasis: `${100 / 9}%` }
									}
								/>
								<HeaderTableItem
									header="Title"
									justify={justify}
									table="myContentList"
									column="title"
									style={
										customWidth
											? { flexBasis: `${customWidth[3]}%` }
											: { flexBasis: `${100 / 9}%` }
									}
								/>
								<HeaderTableItem
									header="Status"
									justify={justify}
									table="myContentList"
									column="status"
									style={
										customWidth
											? { flexBasis: `${customWidth[4]}%` }
											: { flexBasis: `${100 / 9}%` }
									}
								/>
								<HeaderTableItem
									header="Created"
									justify={justify}
									table="myContentList"
									column="created_at"
									style={
										customWidth
											? { flexBasis: `${customWidth[5]}%` }
											: { flexBasis: `${100 / 9}%` }
									}
								/>
								<HeaderTableItem
									header="Last Edit"
									justify={justify}
									table="myContentList"
									column="last_edit"
									style={
										customWidth
											? { flexBasis: `${customWidth[6]}%` }
											: { flexBasis: `${100 / 9}%` }
									}
								/>
								<HeaderTableItem
									header="Reach"
									justify={justify}
									table="myContentList"
									column="reach"
									style={
										customWidth
											? { flexBasis: `${customWidth[7]}%` }
											: { flexBasis: `${100 / 9}%` }
									}
								/>
								{actionsButton && actionColumn === 'right' && (
									<th
										scope="col"
										className="flex items-center justify-center text-[15px] leading-[18px] text-gray-9 font-bold cursor-pointer"
										style={{ flexBasis: `12%` }}
									>
										Actions
									</th>
								)}
							</tr>
						</thead>
						<tbody
							className={clsx(
								bodyTableClassName,
								'w-full h-[14rem] overflow-y-scroll   flex flex-col gap-2 '
							)}
						>
							{data?.map((item, index) => (
								<tr
									key={`${item.id_content}`}
									className="w-full flex justify-between h-max"
								>
									{actionsButton && actionColumn === 'left' && (
										<td
											className={clsx(
												' flex justify-center items-center text-[15px] leading-[18px] text-center text-gray-9'
											)}
											style={{
												flexBasis: `${
													calculateWidhtColumn() || actionColumnWidth
												}%`
											}}
										>
											<ContentActionCell
												editHandler={(id, category, context) => {
													if (contentOrPartnet === 2) {
														navigate(`/content/edit/${id}`)
													} else {
														editHandler(id, category, context)
													}
												}}
												copyHandler={copyHandler}
												deactivateHandler={() =>
													deactivateHandler(
														item.id_content,
														item.status === 'published' ? 'draft' : 'published'
													)
												}
												deleteHandler={() => {
													if (
														item.content_category ||
														item?.partner_organization_content_id
													) {
														setMessageModalToDelete({
															title: 'Content',
															id:
																item.id_content ||
																item?.id ||
																item?.partner_organization_content_id,
															category: item.content_category || item.type
														})
													}
													setListToDelete(
														item.id_content ||
															item?.id ||
															item?.partner_organization_content_id
													)
												}}
												pinHandler={() =>
													pinHandler(item.id_content, !item.is_pinned)
												}
												pinHandlerOrder={pinHandlerOrder}
												data={item}
												index={index}
												dataNotification={dataNotification}
												isOrder={isOrder}
												setOrderOpen={setOrderOpen}
												orderOpen={orderOpen}
											/>
										</td>
									)}
									{headers && (
										<>
											{(item.content_category || item.type) && (
												<td
													className={clsx(
														'font-Inter capitalize',
														'w-full  flex items-center text-[15px] leading-[18px] text-gray-9 ',
														justify === 'center' &&
															'justify-center text-center',
														justify === 'left' && 'justify-left text-left',
														justify === 'right' && 'justify-right text-right'
													)}
													style={
														customWidth
															? { flexBasis: `${customWidth[0]}%` }
															: { flexBasis: `${100 / headers.length}%` }
													}
												>
													{item.content_category
														? item.content_category.replace(/_/g, ' ')
														: item.type}
												</td>
											)}
											<td
												className={clsx(
													'font-Inter',
													'w-full  flex items-center text-[15px] leading-[18px] text-gray-9',
													justify === 'center' && 'justify-center text-center',
													justify === 'left' && 'justify-left text-left',
													justify === 'right' && 'justify-right text-right'
												)}
												style={
													customWidth
														? { flexBasis: `${customWidth[1]}%` }
														: { flexBasis: `${100 / headers.length}%` }
												}
											>
												{item.repeat_start_date
													? formatLocalTime(
															item.repeat_start_date,
															'MM/DD/YYYY hh:mm A'
													  )
													: item.release_date
													? formatLocalTime(
															item.release_date,
															'MM/DD/YYYY hh:mm A'
													  )
													: 'N/A'}
											</td>
											<td
												className={clsx(
													'font-Inter',
													'w-full  flex items-center text-[15px] leading-[18px] text-gray-9',
													justify === 'center' && 'justify-center text-center',
													justify === 'left' && 'justify-left text-left',
													justify === 'right' && 'justify-right text-right'
												)}
												style={
													customWidth
														? { flexBasis: `${customWidth[2]}%` }
														: { flexBasis: `${100 / headers.length}%` }
												}
											>
												{contentOrPartnet && contentOrPartnet === 2
													? item?.organization
													: item.creator || item.author}
											</td>
											<td
												className={clsx(
													'font-Inter',
													'w-full  flex items-center text-[15px] leading-[18px] text-gray-9',
													justify === 'center' && 'justify-center text-center',
													justify === 'left' && 'justify-left text-left',
													justify === 'right' && 'justify-right text-right'
												)}
												style={
													customWidth
														? { flexBasis: `${customWidth[3]}%` }
														: { flexBasis: `${100 / headers.length}%` }
												}
											>
												{item.title}
											</td>

											<td
												className={clsx(
													'font-Inter',
													'w-full  flex items-center text-[15px] leading-[18px] text-gray-9 capitalize',
													justify === 'center' && 'justify-center text-center',
													justify === 'left' && 'justify-left text-left',
													justify === 'right' && 'justify-right text-right'
												)}
												style={
													customWidth
														? { flexBasis: `${customWidth[4]}%` }
														: { flexBasis: `${100 / headers.length}%` }
												}
											>
												{`${determineItemStatus(
													item.status,
													item.release_date as string,
													item.content_category as string
												)}`}
											</td>

											<td
												className={clsx(
													'font-Inter',
													'w-full  flex items-center text-[15px] leading-[18px] text-gray-9',
													justify === 'center' && 'justify-center text-center',
													justify === 'left' && 'justify-left text-left',
													justify === 'right' && 'justify-right text-right'
												)}
												style={
													customWidth
														? { flexBasis: `${customWidth[5]}%` }
														: { flexBasis: `${100 / headers.length}%` }
												}
											>
												{`${
													item.created_at
														? formatLocalTime(
																item.created_at,
																'MM/DD/YYYY hh:mm A'
														  )
														: 'N/A'
												}`}
											</td>
											<td
												className={clsx(
													'font-Inter',
													'w-full  flex items-center text-[15px] leading-[18px] text-gray-9',
													justify === 'center' && 'justify-center text-center',
													justify === 'left' && 'justify-left text-left',
													justify === 'right' && 'justify-right text-right'
												)}
												style={
													customWidth
														? { flexBasis: `${customWidth[6]}%` }
														: { flexBasis: `${100 / headers.length}%` }
												}
											>
												{`${
													item.last_edit
														? formatLocalTime(
																item.last_edit,
																'MM/DD/YYYY hh:mm A'
														  )
														: item.updated_at
														? formatLocalTime(
																item.updated_at,
																'MM/DD/YYYY hh:mm A'
														  )
														: 'N/A'
												}`}
											</td>
											<td
												className={clsx(
													'font-Inter',
													'w-full  flex items-center text-[15px] leading-[18px] text-gray-9',
													justify === 'center' && 'justify-center text-center',
													justify === 'left' && 'justify-left text-left',
													justify === 'right' && 'justify-right text-right'
												)}
												style={
													customWidth
														? { flexBasis: `${customWidth[7]}%` }
														: { flexBasis: `${100 / headers.length}%` }
												}
											>
												{!item.reach || item.reach === null ? 0 : item.reach}
											</td>
										</>
									)}
									{actionsButton && actionColumn === 'right' && (
										<td
											className={clsx(
												' flex justify-center items-center text-[15px] leading-[18px] text-center text-gray-9'
											)}
											style={{ flexBasis: `12%` }}
										>
											{React.cloneElement(actionsButton, {
												value: item.id_content,
												drag: item.is_pinned
											})}
										</td>
									)}
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<div className="w-full flex items-center justify-end">
					<Pagination
						totalRecords={backendPages ?? 1}
						onPageChanged={onPageChanged}
						page={page}
						pageLimit={1}
					/>
				</div>
			</div>
			<ConfirmDelete
				open={listToDelete !== null}
				setOpen={() => {
					setListToDelete(null)
					setMessageModalToDelete(null)
				}}
				onClickConfirm={() => {
					if (listToDelete) {
						callToAction()
					}
				}}
				title={messageModalToDelete?.title}
			/>
		</>
	)
}
