export const handleAddTag = (
	list: number[],
	id: number,
	setList: React.Dispatch<React.SetStateAction<number[]>>
): void => {
	let newList = list
	if (list.includes(id)) {
		newList = list.filter(item => item !== id)
	} else {
		newList.push(id)
	}
	setList([...newList])
}
