import { CircularProgress } from '@mui/material'
import { GET_DASHBOARD_MY_PEOPLE, getMyPeople } from 'api'
import clsx from 'clsx'
import { Box, Button, MyPeopleListItem, TitleBox, Typography } from 'components'
import { BoxComponentProps, Icons, TypographyTypes } from 'interfaces'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'

export const MyPeopleList: React.FC<BoxComponentProps> = ({
	styles,
	setPeriod
}): JSX.Element => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const { data, isLoading } = useQuery(
		[GET_DASHBOARD_MY_PEOPLE, userId, organizationId],
		() => getMyPeople(organizationId)
	)
	const navigate = useNavigate()

	return (
		<div className={styles}>
			<TitleBox title="My People List" icon={Icons.listAlt} className="mb-4" />
			<Box className="flex flex-col items-start w-full h-[274px]">
				<>
					<Typography
						title="New Followers"
						fontLeading="15/15"
						type={TypographyTypes.h3}
						className="text-black-1 mb-1"
					/>
					<Typography
						title="These users have recently registered."
						fontLeading="10/12"
						type={TypographyTypes.p}
						className="text-gray-8 !font-normal"
					/>
					<div
						className={clsx(
							'flex flex-col gap-2 w-full h-[calc(100%_-_43px)]',
							isLoading && 'items-center justify-center',
							data && 'mt-4'
						)}
					>
						{isLoading && <CircularProgress disableShrink />}
						{!isLoading && data && data.length === 0 && (
							<div className="w-full h-full flex justify-center items-center">
								<Typography
									title="You don't have any new followers."
									type={TypographyTypes.h3}
									fontLeading="12/15"
									className="text-gray-7"
								/>
							</div>
						)}
						{data &&
							!isLoading &&
							data.length > 0 &&
							data.map(item => {
								return (
									<MyPeopleListItem
										key={item.id}
										name={item.name}
										email={item.email}
										imageUrl={item.image}
									/>
								)
							})}
					</div>
					<Button
						label="SEE ALL MEMBERS"
						iconRight={Icons.navigateNext}
						color="Gray5"
						className="!min-w-[unset] !py-0 !px-0 !border-none"
						rightIconDivClassName="!w-5 !h-5"
						buttonTextClassName="!font-bold"
						onClick={() => {
							navigate('/people')
							if (setPeriod) {
								setPeriod('My People')
							}
						}}
					/>
				</>
			</Box>
		</div>
	)
}
