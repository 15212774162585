import { Disclosure } from '@headlessui/react'
import { GET_PEOPLE_TAGS_TABLE, getPeopleTagsTable } from 'api'
import clsx from 'clsx'
import { Icon, InputRadioCustom, Tags, Typography } from 'components'
import { useSearchDebounce } from 'hooks'
import { Icons, Reach, TypographyTypes } from 'interfaces'
import { itemAudience } from 'lib'
import React from 'react'
import { useInfiniteQuery } from 'react-query'
import Cookies from 'universal-cookie'

interface AccordionAudienceProps {
	active: number
	publishMyPeope: string
	setPublishMyPeope: React.Dispatch<React.SetStateAction<string>>
	totalList: Reach
	setTotalList: React.Dispatch<React.SetStateAction<Reach>>
	defaultOpen?: boolean
	defaultOpenParent?: boolean
}

export const AccordionAudience: React.FC<AccordionAudienceProps> = ({
	active,
	publishMyPeope,
	setPublishMyPeope,
	totalList,
	setTotalList,
	defaultOpen = false,
	defaultOpenParent = false
}): JSX.Element => {
	const cookies = new Cookies()
	const organizationId = cookies.get('organization_id')
	const userId = cookies.get('userId')

	const handleChangeRadioInput = (
		e: React.ChangeEvent<HTMLInputElement>
	): void => {
		const { value } = e.target
		setPublishMyPeope(value)
	}

	const {
		inputSearch: inputSearchTags,
		handleChangeDebounce: handleChangeDebounceTags,
		resetInput: resetInputTags
	} = useSearchDebounce('')

	const {
		data: dataTags,
		fetchNextPage: fetchNextPageTags,
		isFetchingNextPage: isFetchingNextPageTags,
		isLoading: isLoadingTags
	} = useInfiniteQuery(
		[GET_PEOPLE_TAGS_TABLE, userId, organizationId, inputSearchTags],
		({ pageParam = 1 }) =>
			getPeopleTagsTable(organizationId, pageParam, inputSearchTags),
		{
			getPreviousPageParam: firstPage =>
				firstPage?.page ? firstPage.page : undefined,
			getNextPageParam: lastPage =>
				lastPage?.page ? lastPage.page + 1 : undefined
		}
	)

	return (
		<Disclosure defaultOpen={defaultOpen}>
			{({ open: openDisclosure }) => (
				<>
					<Disclosure.Button className="w-full flex justify-between items-center rounded-[8px] cursor-pointer mt-4 mb-1 border-[1px] border-blue-primary">
						<div className="flex items-center gap-2 w-full  justify-between  p-2">
							<Typography
								title="Audience"
								className="text-gray-9"
								fontLeading="15/18"
								type={TypographyTypes.span}
							/>
						</div>
						<div>
							<Icon
								fillPath
								src={Icons.expandMore}
								className={clsx(
									openDisclosure ? 'rotate-180 transform' : '',
									'w-7 text-blue-primary ml-3'
								)}
							/>
						</div>
					</Disclosure.Button>
					<Disclosure.Panel className="text-gray-500 mb-4 p-4 border-x-[0.5px] border-b-[0.5px] border-gray-7 rounded-b-[16px]">
						<>
							{itemAudience.map(item => (
								<InputRadioCustom
									title={item.title}
									key={item.id}
									inputProps={{
										onChange: handleChangeRadioInput,
										value: item.id,
										id: `tabs.${active}-${item.id}`,
										name: `tabs.${active}`,
										checked: item.id === publishMyPeope
									}}
									labelProps={{
										htmlFor: `tabs.${active}-${item.id}`
									}}
								/>
							))}

							{publishMyPeope === '2' && (
								<Tags
									data={dataTags}
									isLoading={isLoadingTags}
									totalList={totalList}
									resetInput={resetInputTags}
									setTotalList={setTotalList}
									fetchNextPage={fetchNextPageTags}
									isFetchingNextPage={isFetchingNextPageTags}
									handleChangeDebounce={handleChangeDebounceTags}
									defaultOpenParent={defaultOpenParent}
								/>
							)}
						</>
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	)
}

AccordionAudience.defaultProps = {
	defaultOpen: false,
	defaultOpenParent: false
}
