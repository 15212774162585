import React, { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { ZendeskProvider } from 'react-use-zendesk'
import Cookies from 'universal-cookie'

export const RequireUser = ({
	children
}: {
	children: JSX.Element
}): JSX.Element => {
	const location = useLocation()
	const cookies = new Cookies()
	const token = cookies.get('token')

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const win = window as any
	useEffect(() => {
		if (win?.zE) {
			win.zE.show()
		}
		return () => win.zE.hide('webWidget')
	}, [win])

	if (!token) {
		return <Navigate to="/login" state={{ from: location }} />
	}

	return (
		<ZendeskProvider apiKey={process.env.REACT_APP_KEY_ZENDESK ?? ''}>
			{children}
		</ZendeskProvider>
	)
}
