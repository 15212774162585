import { updateOrganization } from 'api'
import { Button, Modal, Table, Typography } from 'components'
import { useToast } from 'hooks'
import {
	OrganizationType,
	OrganizationsTableProps,
	TableProps,
	TypographyTypes,
	UpdateOrganizationBodyRequest
} from 'interfaces'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router'
import Cookies from 'universal-cookie'
import { OrganizationStatusActions } from './organizationStatusActions'
import { OrganizationsTableActionsCell } from './organizationTableActionsCell'

type ChangeStatusPyload = {
	id: number
	body: UpdateOrganizationBodyRequest
}

const columns = (actions: {
	onEditAdminTeam: (id: string) => void
	onEditAppBuild: (id: string, users: number) => void
	onEditInformation: (id: string) => void
	onCopyAAC: (id: string) => void
	onView: (id: OrganizationType) => void
	onChageStatus: (payload: ChangeStatusPyload) => void
}): TableProps['columns'] => [
	{
		Header: <div className="!px-3" />,
		accessor: 'id',
		disableSortBy: true,
		Cell: ({ row }) => {
			const organization = row.original as OrganizationType
			return (
				<OrganizationsTableActionsCell
					onEditAdminTeam={() => actions.onEditAdminTeam(`${organization.id}`)}
					onEditAppBuild={() =>
						actions.onEditAppBuild(`${organization.id}`, organization.users)
					}
					onEditInformation={() =>
						actions.onEditInformation(`${organization.id}`)
					}
				/>
			)
		}
	},
	{
		Header: 'Organization',
		accessor: 'name'
	},
	{
		Header: 'Last Active',
		accessor: 'lastContent',
		disableSortBy: true
	},
	{
		Header: 'Users',
		accessor: 'users',
		disableSortBy: true
	},
	{
		Header: 'App Build',
		accessor: 'buildApp',
		disableSortBy: true
	},
	{
		Header: 'Admin Email',
		accessor: 'email',
		disableSortBy: true
	},
	{
		Header: 'Address',
		accessor: 'address',
		disableSortBy: true
	},
	{
		Header: 'Main Phone',
		accessor: 'phoneNumber',
		disableSortBy: true
	},
	{
		Header: 'Status ORG',
		accessor: 'organization_type',
		disableSortBy: true,
		Cell: ({ value, row }) => {
			const organization = row.original as OrganizationType
			return (
				<OrganizationStatusActions
					orgStatus={value}
					onChageStatus={type =>
						actions.onChageStatus({
							id: organization.id,
							body: {
								organization: {
									organization_type: type
								}
							}
						})
					}
				/>
			)
		}
	},
	{
		Header: '',
		accessor: 'shareUrl',
		disableSortBy: true,
		Cell: ({ value }: { value: string }) => (
			<Button
				label="Copy AAC"
				className="!w-max !h-min !min-w-[unset] !px-4 !py-1"
				color="Transparent"
				onClick={() => actions.onCopyAAC(value)}
			/>
		)
	},
	{
		Header: '',
		disableSortBy: true,
		accessor: 'view',
		Cell: ({ row }) => (
			<Button
				label="View"
				className="!w-max !h-min !min-w-[unset] !px-4 !py-1"
				color="Transparent"
				onClick={() => {
					const organization = row.original as OrganizationType
					actions.onView(organization)
				}}
			/>
		)
	}
]

export const OrganizationsTable: React.FC<OrganizationsTableProps> = ({
	organizations,
	refetchOrganizations,
	...tableProps
}) => {
	const navigate = useNavigate()
	const { showToast } = useToast()
	const [open, setOpen] = useState(false)
	const [cantUsers, setCantUsers] = useState(0)

	const handleOptionsEdit = (id: string, acction: string): void => {
		navigate('/superAdmin/organization/create', {
			state: {
				editing: true,
				flag: acction,
				id
			}
		})
	}

	const { mutate: onChageStatus, isLoading } = useMutation({
		mutationFn: (params: ChangeStatusPyload) =>
			updateOrganization(params.id, params.body),
		onSuccess: response => {
			refetchOrganizations?.()
			showToast(
				'Success',
				response?.message ?? 'Organization updated successfully',
				'success'
			)
		}
	})

	const handleOptionsBuildApp = (id: string, users: number): void => {
		if (users > 0) {
			setCantUsers(users)
			setOpen(true)
		} else {
			handleOptionsEdit(id, 'buildApp')
		}
	}

	const handleOptionsAdminTeam = (id: string): void => {
		handleOptionsEdit(id, 'admin')
	}
	const handleOptionsInformation = (id: string): void => {
		handleOptionsEdit(id, 'organization')
	}

	const handleAACCopy = (aac: string): void => {
		navigator.clipboard.writeText(aac)
		showToast('Copied', 'AAC copied to the clipboard', 'success')
	}

	const handleView = (organization: OrganizationType): void => {
		const cookies = new Cookies()
		cookies.remove('organization_id')
		cookies.remove('organization_name')
		cookies.remove('organization_slug')
		cookies.remove('organization_logo', { path: '/' })
		cookies.set('organization_id', organization.id, {
			path: '/'
		})
		cookies.set('organization_name', organization.name, {
			path: '/'
		})
		cookies.set('organization_slug', organization.slug, {
			path: '/'
		})
		cookies.set('organization_logo', organization.logo, {
			path: '/'
		})
		navigate('/')
	}

	return (
		<>
			<Modal
				setOpen={setOpen}
				open={open}
				type="normal"
				closeButton
				title="Edit App Build"
				colorIcon="w-[14px]"
				borderIcon="p-[2px]"
			>
				<div className="flex flex-col items-center  mt-5">
					<Typography
						type={TypographyTypes.h2}
						fontLeading="22/27"
						className="!font-normal"
						title={`You can't change app build, since your app has ${cantUsers} users`}
					/>
					<Button
						label="Close"
						className="!min-w-[unset] !py-[9px] w-[224px] mt-5"
						onClick={() => setOpen(false)}
					/>
				</div>
			</Modal>
			<Table
				{...tableProps}
				columns={columns({
					onCopyAAC: handleAACCopy,
					onEditAdminTeam: handleOptionsAdminTeam,
					onEditAppBuild: handleOptionsBuildApp,
					onEditInformation: handleOptionsInformation,
					onView: handleView,
					onChageStatus
				})}
				data={organizations}
				justify="left"
				customWidth={[3, 12, 8, 5, 10, 15, 12, 11, 9, 10, 7]}
				contentLarg
				isFetching={tableProps?.isFetching || isLoading}
			/>
		</>
	)
}
