import { CircularProgress } from '@mui/material'
import clsx from 'clsx'
import { Typography } from 'components'
import {
	ContentTableProps,
	ParentTableProps,
	TypographyTypes
} from 'interfaces'
import React, { useState } from 'react'
import { MyContentTable } from './myContentTable'

export const ContentTable: React.FC<ParentTableProps<ContentTableProps>> = ({
	data,
	page,
	setPage,
	className,
	isLoading,
	dataSelected,
	setDataSelected,
	refetch,
	dataNotification,
	isOrder,
	contentOrPartnet,
	setIsUserInitiated,
	classNameLoading,
	refetchPinned
}) => {
	const [loading, setLoading] = useState(false)
	let bodyTableClassName = ''

	if (data && data.items?.length === 1 && isOrder) {
		bodyTableClassName = 'max-h-[40px]'
	} else if (data && data?.items?.length === 2 && isOrder) {
		bodyTableClassName = 'max-h-[85px]'
	} else if (data && data?.items?.length === 3 && isOrder) {
		bodyTableClassName = 'max-h-[135px]'
	}

	const spaceColums = dataNotification
		? [6, 12, 12, 25, 10, 5, 10, 8]
		: [6, 12, 12, 25, 10, 8, 10, 8]

	const headers = [
		'Type',
		'Scheduled',
		'Creator',
		'Title',
		'Status',
		'Published',
		'Last Edit',
		'Reach'
	]

	if (loading || isLoading) {
		return (
			<div className={clsx(className, 'flex flex-col w-full')}>
				<div
					className={clsx(
						classNameLoading,
						'flex items-center justify-center w-full h-[calc(100vh-_446px)] py-2 px-1 bg-white border-2 border-gray-7 rounded-lg'
					)}
				>
					<CircularProgress disableShrink />
				</div>
			</div>
		)
	}

	return (
		<div className={clsx(className, 'flex flex-col w-full')}>
			{data?.items?.length ? (
				<div className="overflow-x-auto">
					<MyContentTable
						data={data.items}
						backendPages={data.pages}
						page={page}
						headers={headers}
						customWidth={spaceColums}
						actionColumnWidth={4}
						actionsButton={<h1>Actions</h1>}
						bodyTableClassName={
							isOrder ? bodyTableClassName : 'h-[calc(100vh-_552px)]'
						}
						justify="center"
						setPage={setPage}
						dataSelected={dataSelected}
						setDataSelected={setDataSelected}
						refetch={refetch}
						dataNotification={dataNotification}
						setLoading={setLoading}
						contentOrPartnet={contentOrPartnet}
						isOrder={isOrder}
						setIsUserInitiated={setIsUserInitiated}
						refetchPinned={refetchPinned}
					/>
				</div>
			) : (
				<div
					className={clsx(
						classNameLoading,
						'flex items-center justify-center w-full h-[calc(100vh_-_446px)] py-2 px-1 bg-white border-2 border-gray-7 rounded-lg'
					)}
				>
					<Typography
						title="You don't have any content."
						type={TypographyTypes.h3}
						fontLeading="18/21"
						className="text-gray-7"
					/>
				</div>
			)}
		</div>
	)
}
