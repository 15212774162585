import { CircularProgress } from '@mui/material'
import {
	createNewOrganization,
	ORGANIZATION_DATA_EDIT,
	updateOrganizationInformation,
	uploadFile
} from 'api'
import { getOrganizationInfo } from 'api/get/superAdminDashboard/superAdminDashboard'
import { OrganizationCreatedModal, Stepper, SuperAdminLayout } from 'components'
import { useToast } from 'hooks'
import {
	AdminOrganizationForm,
	AdminOrganizationProps,
	AdminOrganizationSteps,
	CreateOrganizationRequest,
	Document,
	UpdaterganizationRequest
} from 'interfaces'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery, useQueryClient } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { AdminTeam } from './AdminTeam'
import { Customize } from './Customize'
import { OrganizationInformation } from './OrganizationInformation'
import { SelectBuildApp } from './SelectBuildApp'
import { Tags } from './Tags'

const view = [
	{
		name: 'Compact',
		order: '1',
		enabled: true,
		description: 'The most efficient setup',
		image:
			'https://patter-next-gen.s3.us-west-2.amazonaws.com/default_organizations_images/Compact+view.svg'
	},
	{
		name: 'List',
		order: '2',
		enabled: true,
		description: 'Showcase photos, videos and PDFs',
		image:
			'https://patter-next-gen.s3.us-west-2.amazonaws.com/default_organizations_images/Updated+navigation+38.svg'
	},
	{
		name: 'Locations',
		order: '3',
		enabled: true,
		description: 'Set important locations',
		image:
			'https://patter-next-gen.s3.us-west-2.amazonaws.com/default_organizations_images/Locations.svg'
	}
]

const dataInitialState: AdminOrganizationForm = {
	adminTeam: [],
	buildApp: null,
	customize: {
		accentColor: '',
		backgroundColor: '',
		textColor: '',
		supportColor: '',
		icon: null
	},
	information: {
		mainContact: {
			address: '',
			addressDetails: '',
			email: '',
			name: '',
			notes: '',
			phoneNumber: '',
			phoneNumberCode: ''
		},
		organizationData: {
			address: '',
			addressDetails: '',
			documents: [],
			email: '',
			name: '',
			notes: '',
			phoneNumber: '',
			phoneNumberCode: '',
			share_url: '',
			url: ''
		},
		settings: {
			sso_link: '',
			data_refresh_frecuency_sso: undefined,
			email_support_sso: 'support@patter.com'
		},
		views: view
	},
	tags: []
}

export const AdminOrganization: React.FC<AdminOrganizationProps> = ({
	initialStep
}) => {
	const { state } = useLocation()
	const { showToast } = useToast()
	const editing = state?.editing || undefined
	const id = state?.id || undefined
	const flag = state?.flag || undefined
	const [isLoading, setIsLoading] = useState(false)
	const queryClient = useQueryClient()
	const { data: dataToEdit, refetch } = useQuery({
		queryKey: [ORGANIZATION_DATA_EDIT, id],
		queryFn: () => {
			setIsLoading(true)
			return getOrganizationInfo(id)
		},
		enabled: id !== undefined,
		onSuccess: () => {
			setIsLoading(false)
		},
		refetchOnWindowFocus: false
	})
	const navigate = useNavigate()

	const [currentFileServer, setCurrentFileServer] = useState<Document[]>([])
	const [arrayNewFileState, setArrayNewFileState] = useState<File[]>([])
	const [organizationFormData, setOrganizationFormData] =
		useState<AdminOrganizationForm>(dataInitialState)
	const [currentStep, setCurrentStep] = useState<AdminOrganizationSteps>(
		initialStep || AdminOrganizationSteps.Information
	)
	const { setValue, getValues } = useForm<AdminOrganizationForm>({
		defaultValues: dataInitialState
	})

	const [organizationCreated, setOrganizationCreated] = useState<
		boolean | null
	>(null)

	const nextStep = (): void => {
		if (AdminOrganizationSteps[currentStep + 1])
			setCurrentStep(current => current + 1)
	}
	const previousStep = (): void => {
		if (AdminOrganizationSteps[currentStep - 1])
			setCurrentStep(current => current - 1)
	}

	const createOrganization = async (): Promise<void> => {
		const slug = uuidv4()
		const { adminTeam, buildApp, customize, information, tags } = getValues()
		setIsLoading(true)
		const documents = await Promise.all(
			information.organizationData.documents.map<Promise<Document | null>>(
				async file => uploadFile(slug, file)
			)
		)

		const organizationData: CreateOrganizationRequest = {
			slug,
			build_app_id: buildApp || -1,
			customize_app: {
				icon_image_url: customize.icon
					? (await uploadFile(slug, customize.icon))?.document_url ?? null
					: null,
				primary_color: customize.accentColor,
				secondary_color: customize.backgroundColor,
				third_color: customize.textColor,
				fourth_color: customize.supportColor
			},
			main_contact: {
				address: information.mainContact.address,
				address_details: information.mainContact.addressDetails,
				email: information.mainContact.email as string,
				name: information.mainContact.name,
				notes: information.mainContact.notes,
				phone_number: `${information.mainContact.phoneNumberCode}-${information.mainContact.phoneNumber}`
			},
			organization_data: {
				address: information.organizationData.address,
				address_details: information.organizationData.addressDetails,
				// email: information.organizationData.email as string,
				name: information.organizationData.name,
				url: information.organizationData.url as string,
				share_url: information.organizationData.share_url,
				notes: information.organizationData.notes,
				phone_number: `${information.organizationData.phoneNumberCode}-${information.organizationData.phoneNumber}`,
				documents: documents.filter(doc => doc !== null) as Document[]
			},
			sso: {
				sso_link: information.settings.sso_link,
				data_refresh_frecuency_sso:
					information.settings?.data_refresh_frecuency_sso ?? 0,
				email_support_sso: information.settings.email_support_sso
			},
			views: information.views,
			tags_organization: tags
				.filter(tag => !tag.parentTag)
				.map(tag => {
					const subTags = tags.filter(subTag => subTag.parentTag === tag.name)
					return {
						tag_parent: {
							name: tag.name,
							required_approval: tag.requiredApproval
						},
						tags: subTags.map(subTag => ({
							name: subTag.name,
							required_approval: subTag.requiredApproval
						}))
					}
				}),
			users_admin: await Promise.all(
				adminTeam.map(async admin => {
					if (!admin.newUser) {
						return {
							roles: admin.roles,
							user_id: admin.id
						}
					}
					return {
						email: admin.email,
						full_name: admin.fullName,
						image: admin.image
							? (await uploadFile(slug, admin.image))?.document_url ?? null
							: null,
						password: admin.password,
						roles: admin.roles,
						phone_number: `${admin.phoneNumberCode}-${admin.phoneNumber}`
					}
				})
			)
		}

		const res = await createNewOrganization(organizationData)
		setOrganizationCreated(res !== null)
		setIsLoading(false)
	}

	const updateOrganizationInfo = async (): Promise<void> => {
		const slug = uuidv4()
		const { information } = getValues()
		setIsLoading(true)
		const dataDocumentLocal = information.organizationData.documents.filter(
			e => !e.name.includes('service-')
		)
		const dataDocumentServer = information.organizationData.documents.map(e =>
			currentFileServer.filter(
				item => item.name === e.name.replace('service-', '')
			)
		)
		const newData = dataDocumentServer.flat()
		const documents = await Promise.all(
			dataDocumentLocal.map<Promise<Document | null>>(async file =>
				uploadFile(slug, file)
			)
		)
		const newAndCurrentFile = [...documents, ...newData]
		const organizationData: UpdaterganizationRequest = {
			organization: {
				address: information.organizationData.address,
				address_details: information.organizationData.addressDetails,
				url: information.organizationData.url,
				// email: information.organizationData.email,
				name: information.organizationData.name,
				notes: information.organizationData.notes,
				share_url: information.organizationData.share_url,
				phone_number: `${information.organizationData.phoneNumberCode}-${information.organizationData.phoneNumber}`
			},
			main_contact: {
				address: information.mainContact.address,
				address_details: information.mainContact.addressDetails,
				email: information.mainContact.email,
				name: information.mainContact.name,
				notes: information.mainContact.notes,
				phone_number: `${information.mainContact.phoneNumberCode}-${information.mainContact.phoneNumber}`
			},
			sso: {
				sso_link: information.settings.sso_link,
				data_refresh_frecuency_sso:
					information.settings?.data_refresh_frecuency_sso ?? null,
				email_support_sso:
					information.settings.email_support_sso ?? 'support@patter.com'
			},
			documents: newAndCurrentFile.filter(doc => doc !== null) as Document[],
			views: information.views
		}
		const response = await updateOrganizationInformation(id, organizationData)

		if (response && response.message === 'Organization updated successfully') {
			showToast('Success', `${response.message}`, 'success')
			queryClient.invalidateQueries(ORGANIZATION_DATA_EDIT)
			navigate('/superAdmin/organization')
		}
		if (response?.detail) showToast('Error', response?.detail, 'error')
		setIsLoading(false)
	}
	const stepper = (step: number): JSX.Element | undefined =>
		editing ? undefined : <Stepper step={step} stepsCount={4} />

	const steps: { [step in AdminOrganizationSteps]: JSX.Element } = {
		0: (
			<OrganizationInformation
				data={getValues().information}
				stepper={stepper(1)}
				editing={editing}
				onNext={data => {
					setValue('information', data)
					nextStep()
				}}
				onBack={() => navigate('/superAdmin/organization')}
				information={organizationFormData.information}
				onSave={data => {
					setValue('information', data)
					updateOrganizationInfo()
				}}
				refetch={refetch}
			/>
		),
		1: (
			<SelectBuildApp
				data={getValues().buildApp}
				stepper={stepper(1)}
				editing={editing}
				onNext={buildId => {
					setValue('buildApp', buildId)
					nextStep()
				}}
				onBack={buildId => {
					setValue('buildApp', buildId)
					previousStep()
				}}
				information={organizationFormData.information}
			/>
		),
		2: (
			<Customize
				data={getValues().customize}
				stepper={stepper(2)}
				editing={editing}
				onNext={data => {
					setValue('customize', data)
					nextStep()
				}}
				onBack={data => {
					setValue('customize', data)
					previousStep()
				}}
				information={organizationFormData.information}
			/>
		),
		3: (
			<Tags
				data={getValues().tags}
				stepper={stepper(3)}
				editing={editing}
				onNext={data => {
					setValue('tags', data)
					nextStep()
				}}
				onBack={data => {
					setValue('tags', data)
					previousStep()
				}}
				information={organizationFormData.information}
			/>
		),
		4: (
			<AdminTeam
				data={getValues().adminTeam}
				stepper={stepper(4)}
				editing={editing && id}
				onBack={data => {
					setValue('adminTeam', data)
					previousStep()
				}}
				onSave={data => {
					setValue('adminTeam', data)
					createOrganization()
				}}
				information={organizationFormData.information}
			/>
		)
	}

	function obtenerNombreArchivoDesdeURL(url: string): string {
		const partesURL = url.split('/')
		return partesURL[partesURL.length - 1]
	}

	async function descargarArchivoPDF(url: string): Promise<File> {
		const response = await fetch(url)
		const blob = await response.blob()
		const fileName = obtenerNombreArchivoDesdeURL(url)
		const newNameFile = `service-${fileName}`
		const lastModified = new Date().getTime()
		const archivo = new File([blob], newNameFile, {
			type: 'application/pdf',
			lastModified
		})
		return archivo
	}

	const generateDocuments = async (): Promise<void> => {
		if (dataToEdit) {
			const { documents } = dataToEdit
			const files = await Promise.all(
				documents.map(async document => {
					const file = await descargarArchivoPDF(document.document_url)
					return file
				})
			)
			setCurrentFileServer(documents)
			setArrayNewFileState(files)
		}
	}

	useEffect(() => {
		if (editing && id) {
			if (flag === 'admin') {
				setCurrentStep(4)
			}
			if (flag === 'buildApp') {
				setCurrentStep(1)
			}
		}
	}, [dataToEdit])

	useEffect(() => {
		generateDocuments()
	}, [dataToEdit])

	useEffect(() => {
		if (editing && id && dataToEdit) {
			const numberPhoneMainContact =
				dataToEdit.main_contact.phone_number.split('-') // temporalmente se coloca el de organization porque llega sin el codigo
			const numberPhoneOrganizationData =
				dataToEdit.organization.phone_number.split('-')

			setOrganizationFormData({
				...organizationFormData,
				information: {
					...dataInitialState.information,
					mainContact: {
						address: dataToEdit.main_contact.address,
						addressDetails: dataToEdit.main_contact.address_details,
						email: dataToEdit.main_contact.email,
						name: dataToEdit.main_contact.name,
						notes: dataToEdit.main_contact.notes,
						phoneNumber: `${numberPhoneMainContact[1]}-${numberPhoneMainContact[2]}`,
						phoneNumberCode: numberPhoneMainContact[0]
					},
					organizationData: {
						address: dataToEdit.organization.address,
						addressDetails: dataToEdit.organization.address_details,
						// email: dataToEdit.organization.email,
						name: dataToEdit.organization.name,
						notes: dataToEdit.organization.notes,
						phoneNumber: `${numberPhoneOrganizationData[1]}-${numberPhoneOrganizationData[2]}`,
						phoneNumberCode: numberPhoneOrganizationData[0],
						documents: arrayNewFileState,
						share_url: dataToEdit.organization.share_url as string,
						url: dataToEdit.organization.url as string,
						build_app_branch_key: dataToEdit.organization.build_app_branch_key,
						build_app_branch_secret_key:
							dataToEdit.organization.build_app_branch_secret_key,
						slug: dataToEdit.organization.slug,
						branch_url_migration: dataToEdit.organization.branch_url_migration,
						branch_url_sso: dataToEdit.organization.branch_url_sso,
						from_migration: dataToEdit.organization.from_migration
					},
					settings: {
						sso_link: dataToEdit.sso.sso_link,
						data_refresh_frecuency_sso:
							dataToEdit.sso.data_refresh_frecuency_sso ?? undefined,
						email_support_sso: dataToEdit.sso.email_support_sso
					},
					views: dataToEdit.views
				}
			})
		}
	}, [dataToEdit, arrayNewFileState])

	return (
		<SuperAdminLayout>
			{isLoading ? (
				<div className="flex h-[60vh] w-full items-center justify-center">
					<CircularProgress disableShrink />
				</div>
			) : (
				<>
					{steps[currentStep]}
					<OrganizationCreatedModal
						open={organizationCreated !== null}
						setOpen={() => {
							if (organizationCreated) {
								navigate('/superAdmin/organization')
								return
							}
							setOrganizationCreated(null)
						}}
						error={organizationCreated !== true}
						onClickConfirm={() => {
							if (organizationCreated) {
								navigate('/superAdmin/organization')
								return
							}
							setOrganizationCreated(null)
						}}
					/>
				</>
			)}
		</SuperAdminLayout>
	)
}
