import { Icon } from 'components/icon'
import { Typography } from 'components/typography'
import { useToast } from 'hooks'
import { Icons, TypographyTypes } from 'interfaces'
import React from 'react'

export const CopyText: React.FC<{ text: string }> = props => {
	const { text } = props
	const { showToast } = useToast()

	const onCopyLink = (): void => {
		if (text) {
			navigator.clipboard.writeText(text.trim())
			showToast('Copied', 'AAC copied to the clipboard', 'success')
		}
	}
	return (
		<div className="border-2 border-blue-primary rounded-full flex justify-between pr-1 items-center pl-2.5 pb-1 my-4">
			<Typography
				title={text}
				type={TypographyTypes.span}
				fontLeading="15/15"
				className="text-gray-9 !font-normal"
			/>

			<button
				type="button"
				className="px-3 py-2"
				onClick={onCopyLink}
				disabled={!text}
			>
				<Icon src={Icons.copy} fillPath className="text-gray-1" />
			</button>
		</div>
	)
}
