import { API } from 'api/config'
import {
	MessageResponse,
	NewTagsPostResponse,
	NewTagsRequest,
	SendEmailResponse
} from 'interfaces'

export const postNewTags = async (
	organizationId: number,
	body: NewTagsRequest
): Promise<NewTagsPostResponse | null> => {
	let res = null as NewTagsPostResponse | null
	await API()
		.post(`/dashboard-admin/tags_request/${organizationId}`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const sendEmail = async (
	organizationId: number,
	body: SendEmailResponse
): Promise<MessageResponse | null> => {
	let res = null as MessageResponse | null
	await API()
		.post(`/dashboard-admin/send_url/${organizationId}`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}
