import { CircularProgress } from '@mui/material'
import { GET_DASHBOARD_NOTIFICATIONS, getNotifications } from 'api'
import clsx from 'clsx'
import { Typography } from 'components'
import { TypographyTypes } from 'interfaces'
import React from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie'

export const SlideNotification = (): JSX.Element => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const { data, isLoading } = useQuery(
		[GET_DASHBOARD_NOTIFICATIONS, organizationId, userId],
		() => getNotifications(organizationId)
	)

	return (
		<div
			className={clsx(
				'flex flex-col gap-9 w-full h-full',
				isLoading && 'items-center justify-center'
			)}
		>
			{isLoading && <CircularProgress disableShrink />}
			{data && (
				<>
					<Typography
						className="text-gray-8 !font-normal"
						title="Check Notifications Here"
						fontLeading="15/18"
						type={TypographyTypes.h2}
					/>
					<div className="flex flex-col gap-7 w-full">
						{data?.followers_wait_approval > 0 && (
							<Link to="/people">
								<div className="flex flex-col gap-4 w-full border-b border-gray-3 pb-3">
									<div className="flex justify-between gap-1 w-full">
										<Typography
											title="New Followers"
											fontLeading="16/18"
											className="text-blue-primary mb-1"
											type={TypographyTypes.h3}
										/>
										{/* <Button
										label=""
										className="!min-w-[unset] !px-[unset] !py-[unset] !border-none"
										iconLeft={Icons.check}
										leftIconDivClassName="!w-4 !h-3"
										color="Gray2"
										onClick={() => setShowFollowersWaitApproval(false)}
									/> */}
									</div>
									<Typography
										className="text-black-1 !font-normal"
										fontLeading="16/22"
										title={`You Got ${data?.followers_wait_approval} waiting for Tag Approval`}
										type={TypographyTypes.h3}
									/>
								</div>
							</Link>
						)}
						{data?.new_partners.length > 0 && (
							<Link to="/network">
								<div className="flex flex-col gap-4 w-full border-b border-gray-3 pb-3">
									<div className="flex justify-between gap-1 w-full">
										<Typography
											title="New Partners"
											fontLeading="16/18"
											className="text-blue-primary mb-1"
											type={TypographyTypes.h3}
										/>
										{/* <Button
										label=""
										className="!min-w-[unset] !px-[unset] !py-[unset] !border-none"
										iconLeft={Icons.check}
										leftIconDivClassName="!w-4 !h-3"
										color="Gray2"
										onClick={() => setShowPartners(false)}
									/> */}
									</div>
									<div className="flex flex-col gap-1 w-full">
										{data?.new_partners.map(request => {
											return (
												<Typography
													key={request.id}
													className="text-black-1 !font-normal"
													fontLeading="16/22"
													title={`${request.name} wants to be your partner.`}
													type={TypographyTypes.h3}
												/>
											)
										})}
									</div>
								</div>
							</Link>
						)}
						{data?.fm_request.length > 0 && (
							<Link to="/content">
								<div className="flex flex-col gap-4 w-full border-b border-gray-3 pb-3">
									<div className="flex justify-between gap-1 w-full">
										<Typography
											title="New FM requests"
											fontLeading="16/18"
											className="text-blue-primary"
											type={TypographyTypes.h3}
										/>
										{/* <Button
										label=""
										className="!min-w-[unset] !px-[unset] !py-[unset] !border-none"
										iconLeft={Icons.check}
										leftIconDivClassName="!w-4 !h-3"
										color="Gray2"
										onClick={() => setShowFmRequest(false)}
									/> */}
									</div>
									<div className="flex flex-col gap-1 w-full">
										{data?.fm_request.map(request => {
											return (
												<Typography
													key={request.id}
													className="text-black-1 !font-normal"
													fontLeading="16/22"
													title={`${request.name} wants to share new content with you.`}
													type={TypographyTypes.h3}
												/>
											)
										})}
									</div>
								</div>
							</Link>
						)}
					</div>
				</>
			)}
		</div>
	)
}
