/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * TODO:
 * Schedule appointment for review.
 */

import { GET_NETWORK_MESSAGES, getMessages } from 'api'
import {
	Input,
	MessageCenterTable,
	RadioButtonGroup,
	TailwindModal
} from 'components'
import { useSearchDebounce } from 'hooks'
import { GenericModalProps, Icons, NetworkMessagesResponse } from 'interfaces'
import { messageCenterOptions } from 'lib'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'

const MAX_SEARCH_LENGHT = 100

export const MessageCenterModal: React.FC<GenericModalProps<any>> = ({
	open,
	setOpen
}): JSX.Element => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const [radioButtonOption, setRadioButtonOption] = useState(
		messageCenterOptions[0].value
	)
	const [search, setSearch] = useState('')
	const { inputSearch, handleChangeDebounce, resetInput } =
		useSearchDebounce('')
	const [page, setPage] = useState(1)
	const { data, isLoading } = useQuery(
		[
			GET_NETWORK_MESSAGES,
			page,
			inputSearch.trim(),
			radioButtonOption,
			userId,
			organizationId
		],
		() =>
			getMessages(organizationId, page, inputSearch.trim(), radioButtonOption)
	)

	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			title="Partner Message Center"
			className="md:w-[69.2vw] w-full p-4 rounded-[16px]"
		>
			<div className="flex flex-col gap-4 w-full h-[calc(100%_-_76px)] mt-4 overflow-y-auto">
				<RadioButtonGroup
					items={messageCenterOptions}
					radioButtonOption={radioButtonOption}
					setRadioButtonOption={setRadioButtonOption}
					value={radioButtonOption}
				/>
				<Input
					name="search"
					placeholder="Search FM Feeds or Partners"
					className="!w-full !p-[unset]"
					onChange={e => {
						setSearch(e.target.value)
						handleChangeDebounce(e)
					}}
					maxLength={MAX_SEARCH_LENGHT}
					rightIcon={Icons.cancel}
					rightClick={() => {
						resetInput()
						setSearch('')
					}}
					value={search}
					error={
						search.length >= MAX_SEARCH_LENGHT
							? {
									type: 'maxLength',
									message: `Maximum ${MAX_SEARCH_LENGHT} characters`
							  }
							: undefined
					}
					inputClassName="!p-[unset] !py-1 !px-2"
					inputFocus
					borderFull={false}
					search
				/>
				<MessageCenterTable
					data={data as NetworkMessagesResponse | undefined}
					isLoading={isLoading}
					page={page}
					setPage={setPage}
				/>
			</div>
		</TailwindModal>
	)
}
