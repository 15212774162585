import clsx from 'clsx'
import { Images, LastOrganizationsListProps } from 'interfaces'
import React from 'react'
import { OrganizationCard } from './organizationCard'

export const LastOrganizationsList: React.FC<LastOrganizationsListProps> = ({
	styles,
	organizations
}) => {
	return (
		<div className={clsx('w-full overflow-x-auto flex gap-4 py-2', styles)}>
			{organizations.map((organization, idx) => (
				<OrganizationCard
					key={idx}
					lastActive={organization.lastContent}
					content={organization.contents}
					name={organization.name}
					users={organization.users}
					logo={organization.logo ?? Images.defaultOrganizationLogo}
				/>
			))}
		</div>
	)
}
