import { CircularProgress } from '@mui/material'
import { GET_USER_INFO, getUserInfo, patchUserInfo } from 'api'
import { Button, Input, InputPhoneDoble } from 'components'
import { MenuContext } from 'context'
import { useToast } from 'hooks'
import { UserDataFormInputs } from 'interfaces'
import React, { useContext, useEffect, useState } from 'react'
import { SubmitHandler, useForm, UseFormSetValue } from 'react-hook-form'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'

export const UserDataForm = (): JSX.Element => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')

	const { showToast } = useToast()
	const { setFullName } = useContext(MenuContext)
	const [loading, setLoading] = useState(false)
	const { data, refetch, isLoading } = useQuery([GET_USER_INFO, userId], () =>
		getUserInfo(userId)
	)
	const {
		watch,
		register,
		setValue,
		handleSubmit,
		reset,
		formState: { errors, isDirty, isValid }
	} = useForm<UserDataFormInputs>({
		mode: 'all',
		defaultValues: {
			name: '',
			phoneNumber: '',
			phoneNumberCode: '',
			email: ''
		}
	})

	const email = watch('email')

	const rules = {
		name: {
			required: { value: true, message: 'This field is required' },
			maxLength: { value: 20, message: 'Maximum characters exceeded' }
		},
		phoneNumber: {
			required: { value: true, message: 'This field is required' },
			pattern: {
				value: /^\((\d{3})\) (\d{3})[- ]?(\d{4})$/,
				message: 'Invalid phone number'
			}
		},
		phoneNumberCode: {
			required: { value: true, message: 'This field is required' },
			maxLength: { value: 3, message: 'Maximum characters exceeded' }
		},
		email: {
			required: { value: true, message: 'This field is required' },
			maxLength: { value: 60, message: 'Maximum characters exceeded' }
		}
	}

	const onSubmit: SubmitHandler<UserDataFormInputs> = async values => {
		setLoading(true)
		try {
			await patchUserInfo({
				full_name: values.name,
				phone_number: `${values.phoneNumberCode}-${values.phoneNumber}`,
				email: values.email
			}).then(response => {
				refetch()
				setFullName(values.name)
				if (response && response.message)
					showToast(response.message, '', 'success')
				setLoading(false)
				reset({
					name: watch('name'),
					email,
					phoneNumberCode: watch('phoneNumberCode'),
					phoneNumber: watch('phoneNumber')
				})
			})
		} catch (error) {
			console.error('Error', error)
		}
	}

	useEffect(() => {
		if (data) {
			setValue('email', data.email, { shouldValidate: true })
			setValue('name', data.full_name, { shouldValidate: true })
			if (data.phone_number) {
				const splitted = data.phone_number.split('-')
				if (splitted?.length > 1) {
					setValue('phoneNumberCode', splitted[0], {
						shouldValidate: true
					})
					const last = splitted?.[2]
					const phoneNumber = `${splitted[1]}${last ? '-' : ''}${last ?? ''}`
					setValue('phoneNumber', phoneNumber, {
						shouldValidate: true
					})
				} else {
					setValue('phoneNumber', data.phone_number)
				}
			}
		}
	}, [data])

	const customSetValue: UseFormSetValue<UserDataFormInputs> = (
		name,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		value: any
	) => {
		setValue(name, value, {
			shouldValidate: true,
			shouldDirty: true
		})
	}

	return (
		<div className="h-[90%]">
			{(isLoading || loading) && (
				<div className="flex items-center justify-center w-full h-[400px] py-2 px-1 ">
					<CircularProgress disableShrink />
				</div>
			)}
			{!isLoading && !loading && data && (
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="mt-5 h-full flex flex-col justify-between"
				>
					<div>
						<Input
							name="name"
							title="Name"
							register={register}
							rules={rules.name}
							placeholder="Name"
							error={errors.name}
							className="mb-4"
							min={0}
						/>
						<InputPhoneDoble
							title="Phone Number"
							name="phoneNumberCode"
							name2="phoneNumber"
							register={register}
							setValue={customSetValue}
							rules={rules.phoneNumberCode}
							rules2={rules.phoneNumber}
							error={errors.phoneNumberCode}
							error2={errors.phoneNumber}
						/>
						<Input
							name="email"
							title="E-mail"
							type="email"
							register={register}
							rules={rules.email}
							placeholder="example@gmail.com"
							error={errors.email}
							limit
							watchValue={email}
							setValueInput={setValue}
						/>
					</div>
					<div className="flex justify-center md:justify-end pt-8">
						<Button
							label="Save"
							type="submit"
							color="Primary"
							disabled={!isDirty || !isValid}
						/>
					</div>
				</form>
			)}
		</div>
	)
}
