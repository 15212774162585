import { GET_COMMUNITY_APP_VERSIONS, getAppVersions, getBuildApp } from 'api'
import {
	BuildAppsAppVersionsTable,
	BuildAppsModal,
	Button,
	Input,
	SuperAdminLayout,
	Typography
} from 'components'
import { PaginationContext } from 'context'
import { useDebouncedSearch } from 'hooks'
import {
	AppVersionsTableResponse,
	BuildAppResponse,
	Icons,
	TypographyTypes
} from 'interfaces'
import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { configGenaral } from 'utils'

export const Versions = (): JSX.Element => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const navigate = useNavigate()
	const { id } = useParams()
	const [page, setPage] = useState(1)
	const [versionsModal, setVersionsModal] = useState(false)
	const { register, resetField, setValue, watch } = useForm<{
		search: string
	}>({
		mode: 'onChange'
	})
	const watchSearch = watch('search')
	const { debouncedSearch, resetSearch } = useDebouncedSearch(watch('search'))
	const { tables } = useContext(PaginationContext)
	const { isLoading, data, refetch } = useQuery(
		[
			GET_COMMUNITY_APP_VERSIONS,
			userId,
			id,
			debouncedSearch,
			tables.appVersions.column,
			tables.appVersions.order
		],
		() =>
			getAppVersions(
				id,
				page,
				debouncedSearch,
				tables.appVersions.column,
				tables.appVersions.order
			)
	)
	const { isLoading: appIsLoading, data: appData } = useQuery(
		['GET_BUILD_APP', id],
		() => getBuildApp(id)
	)

	return (
		<SuperAdminLayout>
			<>
				<div className="flex justify-between w-full">
					<Typography
						title="App Versions"
						type={TypographyTypes.h1}
						className="text-black-1"
						fontLeading="26/31"
					/>
					<div className="relative flex px-2 py-1 bg-white rounded-[10px]">
						<div className="w-7 h-7 flex items-center justify-center mr-[200px] rounded-[50%] bg-blue-primary border border-2 border-blue-primary">
							<Typography
								title="1"
								type={TypographyTypes.span}
								className="text-white"
								fontLeading="16/22"
							/>
						</div>
						<div className="absolute top-[16px] left-[36px] w-[200px] h-[1px] border border-dashed border-blue-primary" />
						<div className="w-7 h-7 flex items-center justify-center rounded-[50%] bg-blue-primary border border-2 border-blue-primary">
							<Typography
								title="2"
								type={TypographyTypes.span}
								className="text-white"
								fontLeading="16/22"
							/>
						</div>
					</div>
				</div>
				<Typography
					title="Existing Versions"
					type={TypographyTypes.h2}
					className="text-blue-primary mt-6"
					fontLeading="18/21"
				/>
				<div className="flex justify-between items-center mt-6">
					<Input
						name="search"
						placeholder="Search"
						className="!w-[calc(100%_-_176px)] !p-[unset]"
						inputClassName="!p-[unset] !py-1 !px-2"
						inputFieldClassName="!font-normal"
						register={register}
						rightIcon={Icons.cancel}
						rightClick={() => {
							resetSearch()
							resetField('search')
						}}
						inputFocus
						borderFull={false}
						search
						limit
						cantLimit={configGenaral.maxSearchValue}
						watchValue={watchSearch}
						setValueInput={setValue}
					/>
					<Button
						label="Add new version"
						className="!min-w-[unset] !w-[160px]"
						onClick={() => setVersionsModal(true)}
					/>
					{versionsModal && !appIsLoading && (
						<BuildAppsModal
							data={appData as BuildAppResponse | undefined}
							open={versionsModal}
							setOpen={setVersionsModal}
							onSubmit={() => {
								refetch().then()
							}}
						/>
					)}
				</div>
				<BuildAppsAppVersionsTable
					data={data as AppVersionsTableResponse | undefined}
					isLoading={isLoading}
					page={page}
					setPage={setPage}
					className="mt-6"
				/>
				<div className="flex justify-end w-full mt-6">
					<Button
						label="Back"
						className="!min-w-[unset] !w-[110px]"
						color="Transparent"
						onClick={() => navigate('/superAdmin/appBuilds')}
					/>
				</div>
			</>
		</SuperAdminLayout>
	)
}
