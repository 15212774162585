/* eslint-disable no-restricted-globals */

import { InputProps } from 'interfaces'
import * as React from 'react'
import { Input } from './input'

export const InputPhone: React.FC<
	InputProps & React.InputHTMLAttributes<HTMLInputElement>
> = ({ name, setValueInput, rules, onChangeCustom, watchValue, ...props }) => {
	const [isDelete, setIsDelete] = React.useState(false)
	const [isRemove, setIsRemoce] = React.useState(false)

	const onKeyDownHandler = (
		event: React.KeyboardEvent<HTMLInputElement>
	): void => {
		const codigo = event.which || event.keyCode
		if (codigo === 8) {
			setIsDelete(true)
		} else {
			setIsDelete(false)
		}
		if (codigo === 32 || codigo === 190) {
			setIsRemoce(true)
		} else {
			setIsRemoce(false)
		}
	}

	const formatPhoneNumber = (phoneNumber: string): string => {
		if (!phoneNumber) {
			return ''
		}

		const phoneBeforeReplace = phoneNumber.replace(/\D/g, '')

		let formattedPhoneNumber = ''

		if (phoneBeforeReplace.length >= 3) {
			formattedPhoneNumber = `(${phoneBeforeReplace.slice(0, 3)}`

			if (phoneBeforeReplace.length >= 6) {
				formattedPhoneNumber += `) ${phoneBeforeReplace.slice(3, 6)}`

				if (phoneBeforeReplace.length >= 10) {
					formattedPhoneNumber += `-${phoneBeforeReplace.slice(6, 10)}`
				} else {
					formattedPhoneNumber += `-${phoneBeforeReplace.slice(6)}`
				}
			} else {
				formattedPhoneNumber += phoneBeforeReplace.slice(3)
			}
		} else {
			formattedPhoneNumber = phoneBeforeReplace
		}

		return formattedPhoneNumber
	}

	const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>): void => {
		e.preventDefault()
		const pastedText = e.clipboardData.getData('text')
		const sanitizedText = pastedText.replace(/\D/g, '')
		const formattedText = formatPhoneNumber(sanitizedText)
		if (setValueInput) {
			setValueInput(name, formattedText, { shouldValidate: true })
		}
	}

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const val = e.target.value
		const cadena = val
			.replace(/\s/g, '')
			.replaceAll('(', '')
			.replaceAll(')', '')
			.replaceAll('-', '')
		let newFormat = false
		if (!isDelete) {
			if (cadena) {
				let format = ''

				if (cadena.length === 3 && !isRemove) {
					newFormat = true
					format = cadena.replace(/(\d{3})/g, '($1) ')
				} else if (cadena.length === 6) {
					newFormat = true
					format = cadena.replace(/(\d{3})(\d{3})/g, '($1) $2-')
				} else if (cadena.length === 10) {
					newFormat = true
					format = cadena.replace(/(\d{3})(\d{3})(\d{4})/g, '($1) $2-$3')
				}

				setValueInput?.(name, newFormat ? format : val)
			} else {
				const cadenaNew = val.substring(0, val.length - 1)
				setValueInput?.(name, cadenaNew)
			}
		} else if (cadena.length === 0) {
			setValueInput?.(name, '')
		}
		setIsDelete(false)
		onChangeCustom?.(e)
	}

	const finalRules = React.useMemo(() => {
		return {
			...rules
		}
	}, [rules])

	return (
		<Input
			name={name}
			type="tel"
			onChangeCustom={handleChange}
			onKeyDown={onKeyDownHandler}
			maxLength={14}
			rules={finalRules}
			onPaste={handlePaste}
			watchValue={watchValue}
			{...props}
		/>
	)
}
