import clsx from 'clsx'
import { Table } from 'components'
import {
	NewOutboundSubscriptionItem,
	NewOutboundSubscriptionResponse,
	ParentTableProps
} from 'interfaces'
import React, { useCallback, useMemo } from 'react'
import { Column, Row } from 'react-table'
import { renderCellDateTimeUTC } from 'utils'

export const NewSubscriptionRequestTable: React.FC<
	ParentTableProps<NewOutboundSubscriptionResponse>
> = ({
	data,
	page,
	setPage,
	className,
	isLoading,
	setDataSelected,
	dataSelected,
	onItemsShowingChange
}) => {
	const columns = useMemo(
		(): Column<NewOutboundSubscriptionItem>[] => [
			{ Header: 'FM Feed', accessor: 'name' },
			{ Header: 'Org', accessor: 'organization_name' },
			{ Header: 'Subscribers', accessor: 'subscribers', sortType: 'basic' },
			{
				Header: 'Active Content',
				accessor: 'active_content',
				sortType: 'basic'
			},
			{
				Header: 'Last Publish',
				accessor: 'created_at',
				disableSortBy: true,
				Cell: ({ value }) => renderCellDateTimeUTC(value)
			}
		],
		[]
	)

	const handleRowSelect = useCallback<(rows: Row[], selected: boolean) => void>(
		(rows, selected) => {
			const feedIds = rows.map(row => {
				const { id: feedId } = row.original as NewOutboundSubscriptionItem
				return feedId
			})
			if (selected) {
				setDataSelected?.(prev => [...prev, ...feedIds])
			} else {
				setDataSelected?.(prev =>
					prev.filter(feedId => !feedIds.includes(feedId))
				)
			}
		},
		[setDataSelected]
	)

	const rowSelected = (row: Row): boolean => {
		const subscription = row.original as NewOutboundSubscriptionItem
		return dataSelected?.includes(subscription.id) ?? false
	}

	return (
		<div className={clsx(className, 'flex flex-col w-full')}>
			<Table
				data={data?.items ?? []}
				page={page}
				pages={data?.size}
				total={data?.total}
				columns={columns as Column[]}
				selectionCustomWidth={3}
				customWidth={[15, 15, 19, 13, 16]}
				bodyTableClassName="!h-[calc(100vh_-_518px)]"
				justify="left"
				onPageChange={setPage}
				isFetching={isLoading}
				onItemsShowingChange={onItemsShowingChange}
				showPagination
				onRowSelect={handleRowSelect}
				rowSelected={rowSelected}
			/>
		</div>
	)
}
