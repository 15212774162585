/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * TODO:
 * Schedule appointment for review.
 */

import { Icons, OrgStatus, TypeComponentsItems } from 'interfaces'

export const shallowCompare = function <T extends Record<string, any>>(
	obj1: T,
	obj2: T
): boolean {
	const obj1Keys = Object.keys(obj1)
	const obj2Keys = Object.keys(obj2)
	let value = true

	if (obj1Keys.length !== obj2Keys.length) {
		value = false
	}

	obj1Keys.forEach((key): void => {
		if (obj1[key] !== obj2[key]) {
			value = false
		}
	})

	return value
}

export const getNameComponentForType = (value: TypeComponentsItems): string => {
	if (value === 'social_media') {
		return 'Social Media'
	}
	if (value === 'attachment') {
		return 'Attachments'
	}
	if (value === 'link') {
		return 'Link'
	}
	if (value === 'content') {
		return 'Content'
	}
	if (value === 'mail') {
		return 'Mail'
	}
	if (value === 'simple_text') {
		return 'Custom Text'
	}
	if (value === 'location') {
		return 'Location'
	}
	if (value === 'tags') {
		return 'Tags'
	}
	if (value === 'phone') {
		return 'Phone'
	}
	if (value === 'title') {
		return 'Title'
	}

	return 'N/A'
}

export const orgStatusLabel = {
	[OrgStatus.lite]: 'Lite',
	[OrgStatus.pro]: 'Pro',
	[OrgStatus.elite]: 'Elite'
}

export const orgStatusIcon = {
	[OrgStatus.lite]: Icons.liteStatus,
	[OrgStatus.pro]: Icons.proStatus,
	[OrgStatus.elite]: Icons.eliteStatus
}

export const getOrgStatus = (
	status?: OrgStatus | null
): { label: string; icon: string } => {
	if (!status) return { label: 'Select', icon: Icons.help }

	const label = orgStatusLabel?.[status] ?? 'Select'
	const icon = orgStatusIcon?.[status] ?? Icons.help

	return { label, icon }
}
