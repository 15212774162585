/* eslint-disable no-template-curly-in-string */
import { CircularProgress } from '@mui/material'
import {
	GET_EDIT_LOCATION,
	getUploadFileConten,
	getUploadImageConten,
	updateImage
} from 'api'
import { getLocationById } from 'api/get/locations'
import { patchLocationEdit } from 'api/patch/location'
import { postCreateLocation } from 'api/post/location'
import {
	Box,
	Button,
	ContentDelivery,
	ContentSetUp,
	CreateNewContent,
	Icon,
	Layout,
	Modal,
	Typography
} from 'components'
import { useToast } from 'hooks'
import {
	BodyCreateLocation,
	DeliveryActiveData,
	DocumentsData,
	DocumentsInputs,
	FilesData,
	Icons,
	ItemDataAssentsAll,
	LocationByIDResponse,
	TotalDataCreateContent,
	TypographyTypes,
	UpdateImageResponse,
	locationState
} from 'interfaces'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { configGenaral } from 'utils'
import { v4 as uuidv4 } from 'uuid'

interface CreateContentProps {
	mode: 'create' | 'edit' | undefined
}

export const CreateLocation: React.FC<CreateContentProps> = ({
	mode
}): JSX.Element => {
	const { id } = useParams()
	const getId = id ? parseInt(id, 10) : undefined

	const cookies = new Cookies()
	const navigate = useNavigate()
	const { showToast } = useToast()
	const userId = cookies.get('userId')
	const [loading, setLoading] = useState(false)

	const [dontSend, setDontSend] = useState(false)
	const organizationId = cookies.get('organization_id')
	const [modalCancel, setModalCancel] = useState(false)
	const [publishMyPeope, setPublishMyPeope] = useState('1')
	const [itemRecurrence, setItemRecurrence] = useState('1')
	const [eventRecurrence, setEventRecurrence] = useState('1')
	const [itemDatePushNotofication, setItemDatePushNotofication] = useState('3')
	const [listImage, setListImage] = useState<ItemDataAssentsAll[]>([])
	const [listImagePush, setListImagePush] = useState<ItemDataAssentsAll>()
	const [videoId, setVideoId] = useState<string>('')
	const [valueInput, setValueInput] = useState('')

	const [item, setItem] = useState<locationState>({
		title: '',
		address: '',
		radius: 1,
		location_type: 'item',
		latitude: 0,
		longitude: 0
	})

	const [documentsList, setDocumentsList] = useState<DocumentsInputs[]>([])
	const [totalData, setTotalData] = useState<TotalDataCreateContent>({
		organization_id: organizationId,
		author_id: userId,
		slug: '',
		basic_setup: {
			category: 'Add to an existing list',
			saving: false,
			sharing: false,
			is_pinned: false,
			preview: {
				files: [],
				video_link_external: ''
			}
		},
		set_up: {
			title: '',
			description: ''
		},
		delivery_enhancements: {
			is_perpetual: false,
			schedule: {
				timing: {
					release_date: '',
					expiration_date: ''
				},
				custom_recurrence: {
					repeating_schedule: false,
					repeat_start_date: '',
					repeat_every_type: '',
					repeat_every_value: 0,
					repeat_on: '',
					ends: {
						// after_ocurrence , on_date, never
						repeat_end_type: '',
						repeat_end_date: '',
						repeat_end_value: 0,
						after_ocurrences: 0
					}
				},
				event: {
					event_start_date: '',
					event_end_date: '',
					event_repeat_until: '',
					custom_recurrence: {
						repeating_schedule: false,
						repeat_start_date: '',
						repeat_every_type: '',
						repeat_every_value: 0,
						repeat_on: '',
						ends: {
							// after_ocurrence , on_date, never
							repeat_end_type: '',
							repeat_end_date: '',
							after_ocurrences: 0
						}
					}
				}
			},
			audience: {
				tags: [],
				lists: [],
				dynamic_lists: [],
				individuals: [],
				organizations: [],
				feeds: [],
				partner_lists: []
			},
			no_good_link: false,
			good_link: {
				link_type: '',
				link_value: '',
				link_call_action: '',
				is_sublink: false,
				sublink_type: '',
				sublink_value: '',
				sublink_call_action: ''
			},
			documents: [],
			push_notification: {
				title: '',
				message: '',
				is_message: true,
				is_title: true,
				image: '',
				timing: {
					release_date: ''
				},
				custom_recurrence: {
					repeating_schedule: false,
					repeat_start_date: '',
					// day week month year
					repeat_every_type: '',
					repeat_every_value: 0,
					repeat_on: '',
					ends: {
						// after_ocurrence , on_date, never
						repeat_end_type: '',
						repeat_end_date: '',
						after_ocurrences: 0
					}
				}
			},
			location: [],
			repeat_end_value: 0
		}
	})
	const [deliveryActive, setDeliveryActive] = useState<DeliveryActiveData>({
		shedule: false,
		goodLink: false,
		audience: false,
		documents: false,
		location: false,
		push_notification: false
	})

	const { data, isLoading, isFetching } = useQuery({
		queryKey: [GET_EDIT_LOCATION, userId, organizationId, id],
		queryFn: () =>
			getLocationById(organizationId, id ? parseInt(id, 10) : undefined),
		refetchOnWindowFocus: false
	})

	const updateDocuments = async (
		credentials: UpdateImageResponse | null,
		file: string,
		name: string
	): Promise<string> => {
		if (!credentials) return ''
		const getFile = await fetch(file)
		const fileBlob = await getFile.blob()
		const newName = name.replaceAll('#', 'nro')
		const newKey = credentials.fields.key.replace('${filename}', newName)
		const body = {
			...credentials.fields,
			file: fileBlob,
			key: newKey
		}
		await updateImage(credentials.url, body)
		const url = `${credentials.url}${newKey}`
		return url
	}

	const updateOrCreateImage = async (
		credentialsImage: UpdateImageResponse | null
	): Promise<FilesData[]> => {
		const imageUrl = [] as FilesData[]
		await Promise.all(
			listImage.map(async (elemet, index) => {
				if (elemet.asset_url) {
					if (elemet.asset_name) {
						const url = await updateDocuments(
							credentialsImage,
							elemet.asset_url,
							elemet.asset_name
						)
						imageUrl.push({
							asset_url: `${url}?size=${elemet.asset_aspect}`,
							asset_type: 'image',
							order: index
						})
					} else {
						imageUrl.push({
							asset_type: elemet.asset_type,
							asset_url: `${elemet.asset_url}`,
							order: index
						})
					}
				}
			})
		)
		return imageUrl
	}

	const updateOrCreateDocument = async (
		credentialsDocuments: UpdateImageResponse | null
	): Promise<DocumentsData[]> => {
		const documentsUrl = [] as DocumentsData[]

		if (documentsList.length !== 0) {
			await Promise.all(
				documentsList.map(async elemet => {
					if (elemet.pdf) {
						const url = await updateDocuments(
							credentialsDocuments,
							elemet.pdf,
							elemet.name_file
						)
						documentsUrl.push({
							title: elemet.title,
							description: elemet.description,
							document_url: url
						})
					} else {
						documentsUrl.push({
							title: elemet.title,
							description: elemet.description,
							document_url: elemet.urlS3 as string
						})
					}
				})
			)
		}
		return documentsUrl
	}

	const ordenImage = async (
		credencialImage: UpdateImageResponse | null
	): Promise<FilesData[]> => {
		const getImage = await updateOrCreateImage(credencialImage)
		return getImage.sort((a, b) => {
			if (a.order && b.order) {
				if (a.order > b.order) {
					return 1
				}

				if (a.order < b.order) {
					return -1
				}
			}
			return 0
		})
	}

	const cleanData = (
		dataToclean: BodyCreateLocation,
		getDomentsProp: DocumentsData[]
	): BodyCreateLocation => {
		const databody = dataToclean
		if (databody.documents && getDomentsProp.length === 0) {
			databody.documents = []
		} else if (databody.documents) {
			databody.documents = getDomentsProp
		}

		if (databody.assets && databody.assets.video_link_external === '') {
			delete databody.assets.video_link_external
		}
		return databody
	}

	const handleSubmit = async (status: string): Promise<Promise<void>> => {
		setLoading(true)
		const slug = uuidv4()
		const credentialsImage = await getUploadImageConten(slug, 'contents')
		const credentialsDocuments = await getUploadFileConten(slug, 'contents')
		const getDocuments = await updateOrCreateDocument(credentialsDocuments)

		const bodyData = {
			author_id: userId as number,
			location_list_id: Number(totalData.basic_setup.category),
			status,
			setup: {
				title: totalData.set_up.title,
				description: totalData.set_up.description
			},
			coordinates: {
				address: item.address as string,
				latitude: item.latitude as number,
				longitude: item.longitude as number
			},
			assets: {
				files: await ordenImage(credentialsImage),
				video_link_external: totalData.basic_setup.preview?.video_link_external
			},
			documents: getDocuments
		}

		const cleanBody = cleanData(bodyData, getDocuments)

		if (data) {
			patchLocationEdit(organizationId, cleanBody, getId).then(response => {
				if (response?.detail) {
					showToast(response.detail, 'Error', 'error')
				} else {
					showToast('Location Edit', 'Success', 'success')
					navigate('/location')
				}
				setLoading(false)
			})
		} else {
			postCreateLocation(organizationId, cleanBody).then(response => {
				if (response?.detail) {
					showToast(response.detail, 'Error', 'error')
				} else {
					showToast('Location Created', 'Success', 'success')
					navigate('/location')
				}
				setLoading(false)
			})
		}
	}

	const getExternalVideo = (
		dataExternalVideo: LocationByIDResponse
	): string => {
		return dataExternalVideo.assets.find(e => e.asset_type === 'external_video')
			? (dataExternalVideo.assets.find(e => e.asset_type === 'external_video')
					?.asset_url as string)
			: ''
	}

	useEffect(() => {
		if (data?.detail) {
			showToast('Error', `${data.detail}`, 'error')
			navigate('/location')
		} else if (data?.organization_id) {
			setTotalData({
				organization_id: data.organization_id,
				author_id: data.author_id,
				slug: '',
				basic_setup: {
					category: data.location_list_id as string,
					saving: false,
					sharing: false,
					is_pinned: false,
					preview: {
						files: data.assets.filter(e => e.asset_type === 'image'),
						video_link_external: getExternalVideo(data)
					}
				},
				set_up: {
					title: data.set_up.title,
					description: data.set_up.description
				},
				delivery_enhancements: {
					is_perpetual: false,
					schedule: {
						timing: {
							release_date: '',
							expiration_date: ''
						},
						custom_recurrence: {
							repeating_schedule: false,
							repeat_start_date: '',
							repeat_every_type: '',
							repeat_every_value: 0,
							repeat_on: '',
							ends: {
								repeat_end_type: '',
								repeat_end_date: '',
								after_ocurrences: 0,
								repeat_end_value: 0
							}
						},
						event: {
							event_start_date: '',
							event_end_date: '',
							event_repeat_until: '',
							custom_recurrence: {
								repeating_schedule: false,
								repeat_start_date: '',
								repeat_every_type: '',
								repeat_every_value: 0,
								repeat_on: '',
								ends: {
									repeat_end_type: '',
									repeat_end_date: '',
									after_ocurrences: 0
								}
							}
						}
					},
					audience: {
						tags: [],
						lists: [],
						dynamic_lists: [],
						individuals: [],
						organizations: [],
						feeds: [],
						partner_lists: []
					},
					good_link: {
						link_type: '',
						link_value: '',
						link_call_action: '',
						is_sublink: false,
						sublink_type: '',
						sublink_value: '',
						sublink_call_action: ''
					},
					no_good_link: false,
					location: [],
					repeat_end_value: 0,
					push_notification: {
						title: '',
						message: '',
						is_message: false,
						is_title: false,
						image: '',
						timing: {
							release_date: ''
						},
						custom_recurrence: {
							repeat_every_type: '',
							repeat_every_value: 1,
							repeat_on: '',
							repeat_start_date: '',
							repeating_schedule: false,
							ends: {
								after_ocurrences: 1,
								repeat_end_date: '',
								repeat_end_type: ''
							}
						}
					},
					documents:
						data.documents && data.documents.length > 0 ? data.documents : []
				}
			})
			if (data.documents) {
				const newDocumentsList:
					| React.SetStateAction<DocumentsInputs[]>
					| DocumentsInputs[] = []
				data.documents.forEach(e => {
					newDocumentsList.push({
						description: e.description,
						name_file: e.document_url.split('documents/')[1],
						size: 0,
						title: e.title,
						urlS3: e.document_url
					})
				})
				setDocumentsList(newDocumentsList)
			}
			setItem({
				title: '',
				address: data.coordinates.address,
				radius: 1,
				location_type: 'item',
				latitude: data.coordinates.latitude,
				longitude: data.coordinates.longitude
			})
		}
	}, [data])

	const hasDescriptionValid =
		totalData.set_up.description &&
		totalData.set_up.description.length > 0 &&
		totalData.set_up.description.length <= configGenaral.maxDescriptionLength

	useEffect(() => {
		if (
			totalData.set_up.title.length > 0 &&
			hasDescriptionValid &&
			totalData.basic_setup.preview &&
			(listImage.length > 0 || videoId)
		) {
			setDontSend(true)
		} else {
			setDontSend(false)
		}
	}, [totalData])

	return (
		<Layout>
			{loading || isLoading || isFetching ? (
				<div className="flex items-center justify-center w-full h-[400px] py-2 px-1 ">
					<CircularProgress disableShrink />
				</div>
			) : (
				<div>
					<Modal
						closeButton
						type="normal"
						open={modalCancel}
						setOpen={setModalCancel}
					>
						<div className="flex flex-col items-center gap-2">
							<Icon
								fillPath
								src={Icons.warning}
								className="w-10 text-blue-primary"
							/>
							<Typography
								type={TypographyTypes.span}
								fontLeading="15/18"
								className="!font-normal text-black-1"
								title="Are you sure you want to exit?"
							/>
							<Button
								type="button"
								label="Confirm"
								color="Primary"
								className="!min-w-[130px]"
								onClick={() => navigate('/location')}
							/>
						</div>
					</Modal>
					<div className="flex flex-col lg:flex-row mt-3 w-full justify-between 3xl:mx-auto 3xl:my-auto 3xl:w-3/4 gap-3">
						<Box
							className={`flex flex-col w-full  lg:!w-[65%] min-h-[482px] ${
								listImage.length === 0 ? 'h-[800px]' : 'h-[41rem]'
							} md:min-h-[650px]`}
						>
							<CreateNewContent
								mode={mode}
								listImage={listImage}
								totalData={totalData}
								setListImage={setListImage}
								setTotalData={setTotalData}
								typeFunction="Location"
								videoId={videoId}
								setVideoId={setVideoId}
								setValueInput={setValueInput}
								valueInput={valueInput}
							/>
						</Box>
						<div className="flex flex-col w-full lg:!w-[35%] lg:!min-w-[35%] sm:mt-2 md:mt-0">
							<Box>
								<ContentSetUp
									totalData={totalData}
									setDontSend={setDontSend}
									setTotalData={setTotalData}
									titleComponent="Create New Location"
									item={item}
									setItem={setItem}
								/>
							</Box>
							<Box className="mt-2">
								<ContentDelivery
									totalData={totalData}
									setTotalData={setTotalData}
									documentsList={documentsList}
									listImagePush={listImagePush}
									itemRecurrence={itemRecurrence}
									deliveryActive={deliveryActive}
									publishMyPeope={publishMyPeope}
									eventRecurrence={eventRecurrence}
									setDocumentsList={setDocumentsList}
									setListImagePush={setListImagePush}
									setDeliveryActive={setDeliveryActive}
									setItemRecurrence={setItemRecurrence}
									setPublishMyPeope={setPublishMyPeope}
									setEventRecurrence={setEventRecurrence}
									itemDatePushNotofication={itemDatePushNotofication}
									setItemDatePushNotofication={setItemDatePushNotofication}
									isLoading={isFetching}
									mode={mode === 'create' ? 'create' : 'edit'}
									typeFunction="Location"
								/>
							</Box>
						</div>
					</div>
					<div className="w-full flex flex-col sm:flex-row justify-end gap-4 mt-4 3xl:mx-auto 3xl:my-auto 3xl:w-3/4 3xl:mt-3">
						<Button
							type="button"
							label="Cancel"
							color="Gray3"
							className="!min-w-[130px]"
							onClick={() => setModalCancel(true)}
						/>
						<Button
							type="button"
							label={mode === 'create' ? 'Publish location' : 'Save Changes'}
							color="Primary"
							className="!min-w-[130px]"
							disabled={item.latitude === 0 || !dontSend}
							onClick={() => handleSubmit('published')}
						/>
					</div>
				</div>
			)}
		</Layout>
	)
}
