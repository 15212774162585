import { CircularProgress } from '@mui/material'
import clsx from 'clsx'
import { Checkbox, Icon, Pagination, Typography } from 'components'
import {
	Icons,
	PaginationType,
	TableDataType,
	TableProps,
	TypographyTypes
} from 'interfaces'
import moment from 'moment'
import React, { useEffect, useMemo } from 'react'
import {
	CellProps,
	Column,
	Renderer,
	Row,
	useExpanded,
	usePagination,
	useSortBy,
	useTable
} from 'react-table'
import { getCustomWidth, justifyValues } from 'utils'

// eslint-disable-next-line @typescript-eslint/ban-types
export const Table = <D extends object = TableDataType<{}>>({
	data,
	columns,
	customWidth: initialColumnsWidths,
	selectionCustomWidth,
	onRowSelect,
	bodyTableClassName,
	justify = 'center',
	showPagination = true,
	page: backendPage,
	pages: backendPages,
	total: backendTotal,
	onPageChange: setCurrentPage,
	isFetching,
	noDataMessage,
	hideSelectionHeader,
	rowSelected,
	contentLarg,
	borderTable,
	customWidthOverride = false,
	rowDisabled
}: TableProps<D>): JSX.Element => {
	const customWidth = onRowSelect
		? [selectionCustomWidth ?? 10, ...(initialColumnsWidths ?? [])]
		: initialColumnsWidths

	const getSortedDescIcons = (isSorted: boolean | undefined): string => {
		return isSorted ? Icons.expandLess : Icons.expandMore
	}

	const renderHeader = (rows: Row<D>[]): JSX.Element => {
		if (hideSelectionHeader) return <div />
		return (
			<Checkbox
				checked={rows.length > 0 && rows.every(rowSelected ?? (() => false))}
				onChange={e => onRowSelect?.(rows, e.target.checked)}
				className={clsx(borderTable ? 'w-4 h-4' : 'w-3 h-3', 'my-auto')}
				aria-label="select all options"
			/>
		)
	}

	const renderCellRow = (row: Row<D>): JSX.Element => {
		return (
			<Checkbox
				checked={rowSelected?.(row)}
				onChange={e => onRowSelect?.([row], e.target.checked)}
				className={clsx(borderTable ? 'w-4 h-4' : 'w-3 h-3', 'my-auto')}
				aria-label={`select row ${row.id}`}
				disabled={rowDisabled?.(row)}
			/>
		)
	}

	const selectionColumn: Column<D> = {
		id: 'selection',
		disableSortBy: true,
		Header: ({ rows }) => renderHeader(rows),
		Cell: (({ row }) => renderCellRow(row)) as Renderer<CellProps<D>>
	}

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		setPageSize
	} = useTable<D>(
		{
			columns: useMemo(
				() => (onRowSelect ? [selectionColumn, ...columns] : columns),
				[rowSelected, onRowSelect, columns]
			),

			data: useMemo(() => data, [JSON.stringify(data)]),
			autoResetSortBy: false,
			autoResetExpanded: false
		},
		useSortBy,
		useExpanded,
		usePagination
	)

	useEffect(() => {
		setPageSize(2000)
	}, [])

	const onPageChanged = (pages: PaginationType): void => {
		const newPage = pages.currentPage
		if (setCurrentPage) {
			setCurrentPage(newPage)
		}
	}

	return (
		<div
			className={`min-w-[${
				customWidthOverride ? '100%' : '1310px'
			}] md:w-[100%]`}
		>
			<div
				className={clsx(
					!borderTable && 'border-2 border-gray-7 rounded-lg md:w-full',
					'p-4 bg-white '
				)}
			>
				<table
					{...getTableProps()}
					className="w-full md:overflow-y-hidden md:overflow-x-scroll hide-scroll-bar "
				>
					<thead className="flex w-full h-5 mb-3">
						{headerGroups.map(headerGroup => (
							<tr
								{...headerGroup.getHeaderGroupProps()}
								className="w-full flex justify-between items-center md:gap-0"
							>
								{headerGroup.headers.map((column, index) => (
									<th
										{...column.getHeaderProps(column.getSortByToggleProps())}
										scope="col"
										className={clsx(
											'flex items-center text-[15px] leading-[18px] text-gray-9 font-bold cursor-pointer md:w-0 lg:w-0',
											justifyValues?.[justify],
											!customWidth?.at(index) && 'w-full'
										)}
										style={getCustomWidth({
											index,
											total: columns.length,
											customWidth
										})}
									>
										{column.render('Header')}
										{!column.disableSortBy && (
											<div className="w-5 h-5">
												<Icon
													src={
														column.isSorted
															? getSortedDescIcons(column.isSortedDesc)
															: Icons.unfoldMore
													}
													fillPath
													className="text-gray-13"
												/>
											</div>
										)}
									</th>
								))}
							</tr>
						))}
					</thead>

					<tbody
						{...getTableBodyProps()}
						className={clsx(
							bodyTableClassName,
							!bodyTableClassName?.includes('overflow-y-auto') &&
								'overflow-y-scroll hide-scroll-bar',
							'w-full h-[14rem]  flex flex-col gap-2'
						)}
					>
						{!isFetching && data.length === 0 && (
							<tr className="h-full">
								<td
									colSpan={columns.length}
									className="flex w-full justify-center items-center h-full"
								>
									<div className="w-full flex items-center justify-center">
										<Typography
											className="text-gray-1 !font-normal"
											type={TypographyTypes.span}
											fontLeading="15/18"
											title={noDataMessage ?? 'No Data Found'}
										/>
									</div>
								</td>
							</tr>
						)}
						{isFetching ? (
							<tr className="h-full">
								<td
									colSpan={columns.length}
									className="flex w-full justify-center items-center h-full"
								>
									<CircularProgress disableShrink />
								</td>
							</tr>
						) : (
							page.map(row => {
								prepareRow(row)
								return (
									<tr
										{...row.getRowProps()}
										className="w-full flex justify-between border-b border-b-gray-11 py-2 items-center md:gap-0 "
									>
										{row.cells.map((cell, index) => {
											return (
												<td
													{...cell.getCellProps()}
													className={clsx(
														'flex text-[15px] leading-[18px] text-gray-9 md:pl-0',
														justifyValues?.[justify],
														!customWidth?.at(index) && 'w-full',
														rowDisabled?.(row) && 'text-gray-1'
													)}
													role="cell"
													style={getCustomWidth({
														index,
														total: columns.length,
														customWidth
													})}
												>
													{contentLarg ? (
														<p className="text-ellipsis overflow-hidden">
															{cell.column.id === 'release_date'
																? moment(cell.value).format('YYYY')
																: cell.render('Cell')}
														</p>
													) : cell.column.id === 'release_date' ? (
														moment
															.utc(cell.value)
															.local()
															.format('MM/DD/YYYY hh:mm A')
													) : (
														cell.render('Cell')
													)}
												</td>
											)
										})}
									</tr>
								)
							})
						)}
					</tbody>
				</table>
			</div>
			{showPagination && backendTotal ? (
				<Pagination
					pageLimit={backendPages}
					totalRecords={backendTotal}
					page={backendPage}
					onPageChanged={onPageChanged}
				/>
			) : null}
		</div>
	)
}
