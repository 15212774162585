import { Menu, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { Icon } from 'components/icon'
import { Typography } from 'components/typography'
import {
	DropdownProps,
	Icons,
	ItemDropdownOptionProps,
	TypographyTypes
} from 'interfaces'
import React, { Fragment, useMemo, useState } from 'react'

export const Dropdown: React.FC<DropdownProps> = ({
	setPeriod
}): JSX.Element => {
	const [optionSelected, setOptionSelected] = useState<ItemDropdownOptionProps>(
		{
			id: 1,
			name: 'Last 7 days',
			option: 'last_seven_days'
		}
	)

	const option = useMemo(
		() => [
			{ id: 0, name: 'Today', option: 'today' },
			{ id: 1, name: 'Last 7 days', option: 'last_seven_days' },
			{ id: 2, name: '6 Months', option: 'six_months' },
			{ id: 3, name: '12 Months', option: 'twelve_months' }
		],
		[]
	)

	const handleClick = (item: ItemDropdownOptionProps): void => {
		setOptionSelected(item)
		setPeriod(item.option)
	}
	return (
		<Menu as="div" className="relative inline-block text-left">
			<div>
				<Menu.Button className="flex items-center gap-2">
					<Typography
						title={optionSelected.name}
						fontLeading="12/15"
						type={TypographyTypes.span}
						className="!font-normal text-black-1"
					/>
					<Icon src={Icons.arrowDown} className="w-2 text-black-1" />
				</Menu.Button>
			</div>

			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white border border-gray-3">
					<div className="py-1">
						{option.map(
							item =>
								item.id !== optionSelected.id && (
									<Menu.Item key={item.id}>
										{({ active }) => (
											<button
												type="button"
												onClick={() => handleClick(item)}
												className={clsx(
													active
														? 'bg-gray-100 text-gray-900'
														: 'text-gray-700',
													'block px-4 py-2 text-sm w-full text-left'
												)}
											>
												<Typography
													title={item.name}
													fontLeading="12/15"
													type={TypographyTypes.span}
													className="!font-normal text-gray-9"
												/>
											</button>
										)}
									</Menu.Item>
								)
						)}
					</div>
				</Menu.Items>
			</Transition>
		</Menu>
	)
}
