import { CircularProgress } from '@mui/material'
import { GET_DASHBOARD_FOLLOWERS, getFollowers } from 'api'
import clsx from 'clsx'
import { Box, GroupButton, TitleBox, Typography } from 'components'
import {
	BoxComponentProps,
	DataGraphicProps,
	Icons,
	TypographyTypes
} from 'interfaces'
import React, { useEffect, useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'

export const PeopleFollowers: React.FC<BoxComponentProps> = ({ styles }) => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const optionsToRender = useMemo(
		() => [
			{ name: '7 days', value: 'last_seven_days' },
			{ name: '6 months', value: 'six_months' },
			{ name: '12 months', value: 'twelve_months' }
		],
		[]
	)
	const [period, setPeriod] = useState(optionsToRender[0].value)
	const graphOptions = useMemo(() => {
		return {
			maintainAspectRatio: false,
			responsive: true,
			plugins: {
				legend: {
					display: false
				}
			},
			scales: {
				x: {
					ticks: {
						color: '#000000',
						font: {
							family: 'Inter'
						}
					}
				},
				y: {
					beginAtZero: true,
					ticks: {
						color: '#000000',
						font: {
							family: 'Inter',
							size: 12
						}
					}
				}
			}
		}
	}, [])
	const { data, isLoading } = useQuery(
		[GET_DASHBOARD_FOLLOWERS, period, userId, organizationId],
		() => getFollowers(organizationId, period)
	)
	const [dataGraphic, setDataGraphic] = useState<DataGraphicProps>()

	useEffect(() => {
		if (data) {
			if (period === 'last_seven_days') {
				setDataGraphic({
					labels: data.map(item => item.name),
					datasets: [
						{
							label: optionsToRender[0].value,
							data: data.map(item => item.total),
							backgroundColor: '#17B3C5'
						}
					]
				})
			} else {
				setDataGraphic({
					labels: data.map(item => item.month_name),
					datasets: [
						{
							label: optionsToRender[0].value,
							data: data.map(item => item.total),
							backgroundColor: '#17B3C5'
						}
					]
				})
			}
		}
	}, [data])

	return (
		<div className={clsx('overflow-auto', styles)}>
			<TitleBox title="Followers" icon={Icons.personGeneric} className="mb-4" />
			<Box className="flex flex-col justify-between items-center w-full h-[274px] p-[unset] px-[18px] py-2 overflow-auto min-h-min">
				<>
					<div className="flex justify-between">
						<GroupButton
							period={period}
							setPeriod={setPeriod}
							options={optionsToRender}
						/>
						{/* <Button
							label="Download List"
							iconLeft={Icons.download}
							color="Transparent"
							className="!min-w-[unset] w-[168px] h-8 px-[unset] py-[unset]"
						/> */}
					</div>
					<div className="w-full flex justify-center items-center h-[190px]">
						{isLoading && <CircularProgress disableShrink />}
						{!isLoading &&
							dataGraphic &&
							dataGraphic.datasets[0].data.every(item => item === 0) && (
								<div className="w-full h-full flex justify-center items-center">
									<Typography
										title="You don't have any followers to track yet."
										type={TypographyTypes.h3}
										fontLeading="12/15"
										className="text-gray-7"
									/>
								</div>
							)}
						{!isLoading &&
							dataGraphic &&
							!dataGraphic.datasets[0].data.every(item => item === 0) && (
								<Bar
									data={dataGraphic}
									options={graphOptions}
									style={{ display: 'inline-block' }}
								/>
							)}
					</div>
				</>
			</Box>
		</div>
	)
}
