import clsx from 'clsx'
import { Input, Typography } from 'components'
import { LinkFillerProps, TypographyTypes } from 'interfaces'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

export const LinkFiller = (props: LinkFillerProps): JSX.Element => {
	const {
		urlDomain,
		className,
		pathClassName,
		onChange,
		placeholder,
		setIsValidAlias
	} = props

	const {
		register,
		watch,
		formState: { errors, isValid }
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			alias: ''
		}
	})

	useEffect(() => {
		setIsValidAlias(isValid)
	}, [isValid])

	const rules = {
		alias: {
			pattern: {
				value: /^[^\s!@#$%^&*(),.?":{}|<>/A-Z_+~`';-]+$/,
				message:
					"Your link can't contain invalid characters (%, &, *, $, @, (), /, <>, ?, {}, #) whitespace, or uppercase letters."
			}
		}
	}

	return (
		<div className={className}>
			{urlDomain && (
				<div className="mb-3 flex items-center gap-2">
					<Typography
						title={`${urlDomain}/`}
						type={TypographyTypes.span}
						fontLeading="15/15"
						className="text-gray-9 !font-normal"
					/>
					<input
						className={clsx(
							'!font-normal text-blue-primary px-2 bg-transparent-default w-full',
							'border border-gray-19 rounded-lg',
							'text-center py-1',
							'ring-0 focus:ring-0 focus:outline-none focus:ring-offset-0',
							pathClassName
						)}
						value={watch('alias')}
						disabled
					/>
				</div>
			)}
			<Input
				name="alias"
				placeholder={placeholder}
				register={register}
				error={errors.alias}
				rules={rules.alias}
				onChangeCustom={onChange}
				inputFieldClassName="placeholder:font-normal"
			/>
		</div>
	)
}
