import {
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
	ThemeProvider
} from '@mui/material'
import { Checkbox, Icon, Typography } from 'components'
import { DropdownCheckboxGroupProps, Icons, TypographyTypes } from 'interfaces'
import React, { useRef, useState } from 'react'
import { selectTheme } from 'themes'
import Button from './button'

export const DropdownCheckboxGroup: React.FC<DropdownCheckboxGroupProps> = ({
	options,
	names,
	selectedOptions,
	setSelectedOptions,
	...props
}) => {
	const [open, setOpen] = useState(false)
	const allConnectionsRef = useRef<HTMLInputElement>(null)
	const handleClose = (): void => {
		setOpen(false)
	}

	const handleOpen = (): void => {
		setOpen(true)
	}

	const handleChange = (
		event: SelectChangeEvent<typeof selectedOptions>
	): void => {
		const {
			target: { value }
		} = event
		const values = typeof value === 'string' ? value.split(',') : value
		setSelectedOptions(values)
	}

	return (
		<FormControl>
			<Button onClick={handleOpen} id="openMenu" {...props} />
			<ThemeProvider theme={selectTheme}>
				<Select
					labelId="demo-multiple-checkbox-label"
					id="demo-multiple-checkbox"
					multiple
					value={selectedOptions}
					onChange={handleChange}
					MenuProps={{
						anchorEl: document.getElementById('openMenu'),
						PaperProps: {
							style: {
								borderRadius: 16,
								paddingTop: 16,
								marginTop: 16
							}
						},
						MenuListProps: { className: '!p-0' }
					}}
					sx={{ display: 'none' }}
					open={open}
					onClose={handleClose}
				>
					<div className="flex justify-between items-center px-4 mb-4">
						<Typography
							className="text-black-1"
							type={TypographyTypes.h3}
							fontLeading="18/21"
							title="Filter by"
						/>
						<button type="button" onClick={() => setOpen(false)}>
							<Icon
								src={Icons.cancel}
								fillPath
								className="text-gray-9 max-h-[20px] w-[20px]"
							/>
						</button>
					</div>
					<MenuItem
						key={selectedOptions.length}
						style={{ gap: 10, width: 221 }}
						onClick={e => {
							e.preventDefault()
							e.stopPropagation()
							allConnectionsRef.current?.click()
						}}
					>
						<Checkbox
							onChange={e => {
								setSelectedOptions(e.target.checked ? options : [])
							}}
							ref={allConnectionsRef}
							className="w-3 h-3 border-gray-9"
							checked={selectedOptions.length === options.length}
						/>
						<Typography
							className="text-gray-9 !font-normal capitalize"
							type={TypographyTypes.span}
							fontLeading="12/15"
							title="All Connections"
						/>
					</MenuItem>
					{names.map((name, index) => (
						<MenuItem
							style={{ gap: 10, width: 221 }}
							key={name}
							value={options[index]}
						>
							<Checkbox
								readOnly
								className="w-3 h-3 border-gray-9"
								checked={selectedOptions.indexOf(options[index]) > -1}
							/>
							<Typography
								className="text-gray-9 !font-normal capitalize"
								type={TypographyTypes.span}
								fontLeading="12/15"
								title={name}
							/>
						</MenuItem>
					))}
				</Select>
			</ThemeProvider>
		</FormControl>
	)
}
