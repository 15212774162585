import clsx from 'clsx'
import {
	Icon,
	PeopleMyTagsActionCell,
	RequiredApprovalCheckbox,
	Table
} from 'components'

import {
	Icons,
	ParnetTableProps,
	PeopleMyTagsItemProps,
	PeopleMyTagsProps
} from 'interfaces'

import React, { useEffect, useMemo } from 'react'
import { CellProps, Column, Row } from 'react-table'
import { renderCellSimpleValue } from 'utils'

type CellTagType = CellProps<PeopleMyTagsItemProps>
export const MyTagsTablet: React.FC<ParnetTableProps<PeopleMyTagsProps>> = ({
	data,
	page,
	setPage,
	isLoading,
	className,
	updateTags,
	refetch,
	setData,
	// tagsSelected,
	// setTagsSelected,
	updateTagsChildrens,
	deleteLoading,
	setDeleteLoading,
	myPeopleRefetch,
	updateTagsVisibilityChildrens,
	deleteTagsParents,
	deleteTagsChildrens
}) => {
	// const handleClickParent = (itemID: number): void => {
	// 	if (data && tagsSelected && setTagsSelected) {
	// 		setTagsSelected(prevTags => {
	// 			if (!prevTags) {
	// 				return prevTags
	// 			}

	// 			return prevTags.map(tag =>
	// 				tag.id === itemID ? { ...tag, selected: !tag.selected } : tag
	// 			)
	// 		})
	// 	}
	// }

	const renderApprovalCell = (row: Row<PeopleMyTagsItemProps>): JSX.Element => {
		return (
			<>
				<RequiredApprovalCheckbox
					id={
						('organization_tag_id' in row.original
							? row.original.organization_tag_id
							: row.original.organization_tag_parent_id) as number
					}
					defaultChecked={row.original.required_approval}
					refetch={refetch}
					updateTags={updateTags}
					isChildren={row.depth === 1}
					updateTagsChildrens={updateTagsChildrens}
					data={data}
				/>
				Required Approval
			</>
		)
	}

	const renderVisibilityCell = (isVisible: boolean): JSX.Element => {
		return (
			<div className="w-4 h-4 flex justify-center items-center ml-10">
				<Icon
					src={isVisible ? Icons.visibility : Icons.visibilityOff}
					fillPath
					className="text-gray-1"
				/>
			</div>
		)
	}

	const renderCellTagName = (name: string, depth: number): JSX.Element => {
		return (
			<span
				className={`ml-${depth * 1.3} ${depth ? 'font-normal' : 'font-bold'}`}
			>
				{name}
			</span>
		)
	}

	const renderActions = (row: Row<PeopleMyTagsItemProps>): JSX.Element => {
		const tagsId =
			'organization_tag_id' in row.original
				? row.original.organization_tag_id
				: row.original.organization_tag_parent_id

		return (
			<td className="flex justify-end items-center gap-3 h-10 ">
				<div
					className="w-4 h-4 block "
					aria-hidden
					style={{ marginLeft: `${row.depth * 3}rem` }}
				>
					{row.canExpand && (
						<button
							className="w-4 h-4 cursor-pointer "
							aria-hidden
							type="button"
							{...row.getToggleRowExpandedProps()}
						>
							<Icon
								src={row.isExpanded ? Icons.expandLess : Icons.expandMore}
								fillPath
								className="text-gray-3 "
							/>
						</button>
					)}
				</div>
				{/* <input
				type="checkbox"
				className="h-4 w-4 rounded border-gray-1 text-blue-primary focus:ring-transparent-default cursor-pointer "
				// checked={tagSelected.selected}
				onChange={() => handleClickParent(tagsId)}
			/> */}

				{React.cloneElement(
					<PeopleMyTagsActionCell
						allData={data as PeopleMyTagsProps}
						refetch={refetch}
						setData={setData}
						myPeopleRefetch={myPeopleRefetch}
						deleteLoading={deleteLoading}
						setDeleteLoading={setDeleteLoading}
						updateTagsVisibilityChildrens={updateTagsVisibilityChildrens}
						isChildren={row.depth === 1}
						deleteTagsParents={deleteTagsParents}
						deleteTagsChildrens={deleteTagsChildrens}
					/>,
					{
						value: tagsId,
						data: row.original,
						isChildren: row.depth === 1
					}
				)}
			</td>
		)
	}

	const columns = useMemo(
		(): Column<PeopleMyTagsItemProps>[] => [
			{
				Header: 'Actions',
				id: 'expander',
				Cell: ({ row }: CellTagType) => renderActions(row)
			},
			{
				Header: 'Tag Name',
				accessor: 'name',
				Cell: ({ row }: CellTagType) =>
					renderCellTagName(row.original.name, row.depth)
			},
			{
				Header: 'Visibility',
				accessor: 'is_visible',
				Cell: ({ row }: CellTagType) =>
					renderVisibilityCell(row.original.is_visible)
			},
			{
				Header: 'Approval',
				accessor: 'organization_tag_parent_id',
				Cell: ({ row }: CellTagType) => renderApprovalCell(row)
			},
			{
				Header: 'Sub Tags',
				accessor: 'count_son',
				Cell: ({ row }: CellTagType) =>
					renderCellSimpleValue(
						'count_son' in row.original ? row.original.count_son : ''
					)
			}
		],
		[]
	)

	useEffect(() => {
		if (data && data.items?.length === 0 && page !== 1) {
			setPage(page - 1)
		}
	}, [data])

	return (
		<div className={clsx(className, 'flex flex-col w-full overflow-x-auto')}>
			<Table<PeopleMyTagsItemProps>
				data={data?.items ?? []}
				page={page}
				isFetching={isLoading}
				onPageChange={setPage}
				columns={columns}
				noDataMessage="You don't have any partners"
				bodyTableClassName="!h-[calc(100vh_-_28rem)] w-full"
				customWidth={[20, 50, 50, 60, 60]}
				justify="left"
				showPagination
				pages={data?.size}
				total={data?.total}
				hideSelectionHeader
			/>
		</div>
	)
}
