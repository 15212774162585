import clsx from 'clsx'
import { Box } from 'components/boxs'
import { Typography } from 'components/typography'
import { TypographyTypes } from 'interfaces'
import React from 'react'

export const Notes = React.forwardRef<
	HTMLTextAreaElement,
	React.DetailedHTMLProps<
		React.TextareaHTMLAttributes<HTMLTextAreaElement>,
		HTMLTextAreaElement
	>
>((props, ref) => {
	return (
		<Box className={clsx('!p-0 !border', props.className)}>
			<>
				<div className="h-14 flex justify-between p-5 border-b">
					<Typography
						className="text-gray-3 !font-normal"
						type={TypographyTypes.span}
						fontLeading="15/15"
						title="(2,000 characters Max.)"
					/>
				</div>
				<div className="flex">
					<textarea
						ref={ref}
						{...props}
						maxLength={2000}
						placeholder="Write here..."
						className="placeholder:text-gray-3 min-h-[100px] w-full h-full p-5 appearance-none border-none !ring-transparent-default"
					/>
				</div>
			</>
		</Box>
	)
})
