import { PeopleBathesType } from 'interfaces'
import React, { createContext, ReactNode, useContext, useState } from 'react'

export const PeopleBatchesContext = createContext<PeopleBathesType>({
	selectedUsers: [],
	organizationFollower: [],
	setSelectedUsers: () => null,
	setOrganizationFollower: () => null
})
export const PeopleBatchesContextValue = (): PeopleBathesType => {
	const [selectedUsers, setSelectedUsers] = useState<number[]>([])
	const [organizationFollower, setOrganizationFollower] = useState<number[]>([])

	return {
		selectedUsers,
		setSelectedUsers,
		organizationFollower,
		setOrganizationFollower
	}
}

export const PeopleBatchesContextProvider = ({
	children
}: {
	children: ReactNode | ReactNode[]
}): JSX.Element => {
	const peopleBatchesValue = PeopleBatchesContextValue()

	return (
		<PeopleBatchesContext.Provider value={peopleBatchesValue}>
			{children}
		</PeopleBatchesContext.Provider>
	)
}

export const useContextPeopleBatches = (): PeopleBathesType => {
	const peopleBatchesValue = useContext(PeopleBatchesContext)
	return peopleBatchesValue
}
