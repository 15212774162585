import clsx from 'clsx'
import { Icon, Typography } from 'components'
import { TitleProps, TypographyTypes } from 'interfaces'
import React from 'react'

export const Title: React.FC<TitleProps> = ({
	icon,
	title,
	subtitle,
	className
}): JSX.Element => {
	return (
		<div className={clsx(className, 'flex flex-col')}>
			<div className="flex gap-2">
				<Icon fillPath src={icon} className="w-7 text-black" />
				<Typography
					title={title}
					type={TypographyTypes.h1}
					className="font-Inter text-black-1"
					fontLeading="22/27"
				/>
			</div>
			{subtitle && (
				<Typography
					title={subtitle}
					type={TypographyTypes.h2}
					className="text-gray-3 font-Inter"
					fontLeading="15/18"
				/>
			)}
		</div>
	)
}
