import { AdjustmentsIcon } from '@heroicons/react/outline'
import { CircularProgress } from '@mui/material'
import {
	BranchConfigArg,
	getBranchConfig,
	updateOrganizationInformation
} from 'api'
import { createDeepLink } from 'api/post/branch'
import clsx from 'clsx'
import {
	Box,
	Button,
	CopyText,
	FileUploader,
	Input,
	InputPhoneDoble,
	LinkFiller,
	Message,
	Notes,
	Typography
} from 'components'
import { useToast } from 'hooks'
import {
	OrganizationInformationForm,
	OrganizationInformationFormEdit,
	TypographyTypes,
	UpdaterganizationRequest
} from 'interfaces'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useLocation } from 'react-router-dom'
import { configGenaral, encryptData } from 'utils'
import {
	ArgMutationBranch,
	QueryparamsAAC,
	TypeParamUpdate
} from './interfaceGenerateBranch.type'

export const OrganizationData: React.FC<OrganizationInformationFormEdit> = ({
	organizationData,
	editing,
	refetch,
	settings
}) => {
	const {
		register,
		setValue,
		watch,
		formState: { errors }
	} = useFormContext<OrganizationInformationForm>()
	const documents = watch('organizationData.documents')
	const [urlDomain, setUrlDomain] = useState('')
	const url = watch('organizationData.url')
	const { state } = useLocation()
	const id = state?.id || undefined
	const { showToast } = useToast()
	const [aliasState, setAliasState] = useState<string>('')
	const [keyBranch, setKeyBranch] = useState<string | null>(null)
	const [isValidAlias, setIsValidAlias] = useState(false)

	const rules = {
		share_url: {
			required: { value: true, message: 'This field is required' },
			maxLength: { value: 150, message: 'Maximum characters exceeded' },
			pattern: {
				value:
					/^(http|https|ftp):\/\/[^\s/$.?#].[^\s]*$/ ||
					/^[a-zA-Z0-9_!@#$%^&*(),.?":{}|<> ]*$/,
				message: 'Invalid link'
			}
		},
		url: {
			required: { value: true, message: 'This field is required' },
			maxLength: { value: 2000, message: 'Maximum characters exceeded' },
			pattern: {
				value:
					/^(http|https|ftp):\/\/[^\s/$.?#].[^\s]*$/ ||
					/^[a-zA-Z0-9_!@#$%^&*(),.?":{}|<> ]*$/,
				message: 'Invalid link'
			}
		}
	}

	useEffect(() => {
		if (editing) {
			setValue('organizationData', organizationData)
			setValue('organizationData.documents', [...organizationData.documents])
		}
	}, [editing, organizationData])

	const { mutate, isLoading, isError } = useMutation({
		mutationFn: (params: BranchConfigArg) => getBranchConfig(params),
		onSuccess: data => {
			setUrlDomain(data?.default_short_url_domain)
		},
		onError: (error: Error) => {
			showToast('Error', error.message, 'error')
		}
	})

	useEffect(() => {
		const decryptBranchKey = encryptData(
			organizationData?.build_app_branch_key ?? '',
			'decrypt'
		)
		const decryptSecretKey = encryptData(
			organizationData?.build_app_branch_secret_key ?? '',
			'decrypt'
		)
		setKeyBranch(decryptBranchKey)
		if (decryptBranchKey && decryptSecretKey && !organizationData?.share_url)
			mutate({ key: decryptBranchKey.trim(), secret: decryptSecretKey.trim() })
	}, [organizationData])

	const { mutate: mutateOrganization } = useMutation({
		mutationFn: (params: Partial<UpdaterganizationRequest>) =>
			updateOrganizationInformation(id, params),
		onSuccess: async data => {
			if (data?.message === 'Organization updated successfully') {
				refetch?.()
			}
		},
		onError: (error: Error) => {
			if (error.message.includes('alias conflict')) {
				showToast('Error', 'An error occurred while saving the AAC', 'error')
			} else {
				showToast('Error', error.message, 'error')
			}
		}
	})

	const { mutate: generateBranchUrl, isLoading: loadingGenerateBranchUrl } =
		useMutation({
			mutationFn: (params: ArgMutationBranch) =>
				createDeepLink(params.body, params.key),
			onSuccess: data => {
				if (data?.url) {
					if (data.key) {
						const dataUrl: Partial<UpdaterganizationRequest> = {
							organization: {
								[data.key]: data?.url
							}
						}
						mutateOrganization(dataUrl)
					}
				}
			},
			onError: (error: Error) => {
				if (error.message.includes('alias conflict')) {
					showToast(
						'Error',
						'This alias cannot be used. Please select a different one.',
						'error'
					)
				} else {
					showToast('Error', error.message, 'error')
				}
			}
		})

	const handleSubmit = (param: QueryparamsAAC): void => {
		const name = organizationData?.name.replaceAll(' ', '')

		let propsPath
		let argKey: TypeParamUpdate

		switch (param) {
			case 'migration_slug':
				propsPath = `${name}Migration`
				break
			case 'org_slug':
				propsPath = name
				break
			default:
				propsPath = `${name}SSO`
				break
		}

		switch (param) {
			case 'org_slug':
				argKey = 'share_url'
				break
			case 'migration_slug':
				argKey = 'branch_url_migration'
				break
			default:
				argKey = 'branch_url_sso'
				break
		}

		const deepLinkBody = {
			alias: aliasState || propsPath,
			branch_key: keyBranch as string,
			data: { $deeplink_path: `${param}:${organizationData?.slug}` }
		}

		generateBranchUrl({ body: deepLinkBody, key: argKey })
	}

	const messageError =
		'We cannot create the (AAC) because the key has not been entered in the app build.'

	const buttonGenerate = (
		key: QueryparamsAAC,
		locationMessage: 'top' | 'bottom',
		valid?: boolean
	): JSX.Element => {
		const hasError = !organizationData?.build_app_branch_key
		return (
			<>
				{locationMessage === 'top' && hasError && (
					<Message type="error" text={messageError} />
				)}
				<div
					className={clsx(
						locationMessage === 'top' ? 'flex justify-end py-4' : 'my-4'
					)}
				>
					<Button
						label="Generate"
						className={clsx(
							locationMessage === 'bottom'
								? 'w-full'
								: '!min-w-[unset] !w-fit !py-1.5',
							'bg-blue-primary/90 hover:bg-blue-primary disabled:bg-gray-3'
						)}
						color="Primary"
						disabled={isError || hasError || valid}
						onClick={() => handleSubmit(key)}
					/>

					{locationMessage === 'bottom' && hasError && (
						<Message type="error" text={messageError} />
					)}
				</div>
			</>
		)
	}

	return (
		<Box className="!p-4 !rounded-2xl">
			<div className="flex flex-col gap-3">
				<div className="border border-gray-19 p-4 rounded-2xl">
					<div className="grid grid-cols-2 gap-8">
						<div className="flex flex-col gap-2">
							<Input
								name="organizationData.name"
								title="Name"
								type="text"
								placeholder="Type organization name here"
								maxLength={40}
								rules={{ required: 'Name is required', maxLength: 40 }}
								register={register}
								error={errors.organizationData?.name}
							/>
							<InputPhoneDoble
								name="organizationData.phoneNumberCode"
								name2="organizationData.phoneNumber"
								title="Phone Number"
								register={register}
								setValue={setValue}
								rules={{ required: ' ' }}
								rules2={{ required: 'Phone is required' }}
								error={errors.organizationData?.phoneNumberCode}
								error2={errors.organizationData?.phoneNumber}
							/>
							<Input
								name="organizationData.address"
								title="Address"
								type="text"
								placeholder="Type organization address here"
								register={register}
								rules={{ required: 'Address is required' }}
								error={errors.organizationData?.address}
							/>
							<Input
								name="organizationData.addressDetails"
								title="Address Details"
								type="text"
								placeholder="Type organization address details here"
								register={register}
								rules={{ required: 'Address Details is required' }}
								error={errors.organizationData?.addressDetails}
							/>
							{/* <Input
							name="organizationData.email"
							title="E-mail"
							type="email"
							placeholder="example@gmail.com"
							register={register}
							rules={{ required: 'Email is required' }}
							error={errors.organizationData?.email}
						/> */}
							<Input
								name="organizationData.url"
								title="URL Organization"
								register={register}
								rules={rules.url}
								placeholder="Insert Link"
								watchValue={url}
								error={errors.organizationData?.url}
								className="my-4"
							/>
						</div>
						<div className="flex flex-col">
							<div>
								<Typography
									className="font-bold"
									type={TypographyTypes.label}
									title="Notes"
									fontLeading="12/12"
								/>
								<Notes {...register('organizationData.notes')} />
							</div>
							<div className="mt-3">
								<Typography
									className="!mb-4"
									type={TypographyTypes.label}
									title="Upload Documents:"
									fontLeading="12/12"
								/>
								<FileUploader
									files={documents}
									accept=".pdf"
									onChange={e => {
										const file = e.target.files?.item(0)
										if (file) {
											const fileSize = file.size
											const fileMb = fileSize / 1024 ** 2
											if (fileMb > configGenaral.maxfileSizeMB) {
												showToast(
													'Error',
													`Please select a file less than ${configGenaral.maxfileSizeMB}MB.`,
													'error'
												)
												return
											}
											setValue('organizationData.documents', [
												...documents,
												file
											])
										}
									}}
									onDelete={file =>
										setValue(
											'organizationData.documents',
											documents.filter(document => document.name !== file.name)
										)
									}
								/>
							</div>
						</div>
					</div>
				</div>
				{editing && (
					<div className="border border-gray-19 p-3 rounded-2xl flex flex-col gap-4 ">
						<div className="flex items-center gap-2">
							<span className="border rounded-lg p-1 border-black-1 flex items-center justify-center w-fit">
								<AdjustmentsIcon className="w-5 h-5" />
							</span>
							<Typography
								className="font-bold text-black-1"
								type={TypographyTypes.label}
								title="Allboard code:"
								fontLeading="15/15"
							/>
						</div>
						<div className="flex gap-2">
							<div className="border border-blue-primary rounded-2xl px-2.5 py-2 w-1/2 max-h-[190px]">
								<Typography
									className="font-bold text-black-1"
									type={TypographyTypes.label}
									title="Organization:"
									fontLeading="18/21"
								/>
								{isLoading || loadingGenerateBranchUrl ? (
									<div className="flex items-center justify-center w-full  py-2 px-1 ">
										<CircularProgress disableShrink />
									</div>
								) : !organizationData?.share_url ? (
									<>
										<LinkFiller
											urlDomain={urlDomain}
											placeholder="Type here the alias for the organization"
											onChange={e => setAliasState(e.target.value)}
											setIsValidAlias={setIsValidAlias}
										/>
										{buttonGenerate('org_slug', 'top', !isValidAlias)}
									</>
								) : (
									<CopyText text={organizationData?.share_url} />
								)}
							</div>
							{(!!organizationData.from_migration || !!settings.sso_link) && (
								<div className="border border-blue-primary rounded-2xl px-2.5 py-2 w-1/2">
									{!!settings.sso_link && (
										<>
											<Typography
												className="font-bold text-black-1"
												type={TypographyTypes.label}
												title="SSO:"
												fontLeading="18/21"
											/>
											{isLoading || loadingGenerateBranchUrl ? (
												<div className="flex items-center justify-center w-full  py-2 px-1 ">
													<CircularProgress disableShrink />
												</div>
											) : !organizationData.branch_url_sso ? (
												buttonGenerate('org_sso', 'bottom')
											) : (
												<CopyText text={organizationData?.branch_url_sso} />
											)}
										</>
									)}
									{!!organizationData.from_migration && (
										<>
											<Typography
												className="font-bold text-black-1"
												type={TypographyTypes.label}
												title="Migration:"
												fontLeading="18/21"
											/>
											{isLoading || loadingGenerateBranchUrl ? (
												<div className="flex items-center justify-center w-full py-2 px-1 ">
													<CircularProgress disableShrink />
												</div>
											) : !organizationData.branch_url_migration ? (
												buttonGenerate('migration_slug', 'bottom')
											) : (
												<CopyText
													text={organizationData?.branch_url_migration}
												/>
											)}
										</>
									)}
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		</Box>
	)
}
