import clsx from 'clsx'
import { BoxsProps } from 'interfaces'
import React from 'react'

export const BoxSettings: React.FC<BoxsProps> = ({
	children,
	className
}): JSX.Element => {
	return (
		<div
			className={clsx(
				className,
				'shadow-boxSettings bg-white py-4 px-[17px] rounded-lg'
			)}
		>
			{children}
		</div>
	)
}
