import { Disclosure } from '@headlessui/react'
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import {
	AppColorMatching,
	EntryScreenColorMatching,
	NotificationColorMatching
} from 'components/'
import { Button } from 'components/buttons'
import { ColorPicker } from 'components/colorPicker'
import { Icon } from 'components/icon'
import { Typography } from 'components/typography'
import {
	Icons,
	ProfileImageMyOrganizationItem,
	SlideColors,
	TypographyTypes
} from 'interfaces'
import React, { useEffect, useState } from 'react'

export const SliderColors: React.FC<
	SlideColors & {
		onSave: (result: ProfileImageMyOrganizationItem) => Promise<void>
	}
> = ({ data, slideOpen, setData, setOpen, onSave }) => {
	const listAccentColor = [
		'#941B22',
		'#00B188',
		'#FF9900',
		'#00148B',
		'#673AB7',
		'#D63D3D'
	]

	const listBackgroundColor = [
		'#F8FAFF',
		'#FFF8F8',
		'#D9D9D9',
		'#FFFFFF',
		'#5A5A5A',
		'#2C2C2C'
	]

	const listSupportColor = [
		'#FFFFFF',
		'#C5C5C5',
		'#A3A3A3',
		'#5A5A5A',
		'#323232'
	]
	const [openPreview, setOpenPreview] = useState(false)

	const [accentColor, setAccentColor] = useState<string>(
		data?.profile_image?.primary_color ?? listAccentColor[0]
	)

	const [backgroundColor, setBackgroundColor] = useState<string>(
		data?.profile_image?.secondary_color ?? listBackgroundColor[0]
	)

	const [supportColor, setSupportColor] = useState<string>(
		data?.profile_image?.fourth_color ?? listBackgroundColor[0]
	)

	const handleClosePreview = (): void => {
		setOpenPreview(false)
	}

	useEffect(() => {
		if (!slideOpen) {
			handleClosePreview()
		}
	}, [slideOpen])

	const handleSubmit = (): void => {
		if (data) {
			const aux = {
				...data,
				profile_image: {
					...data.profile_image,
					primary_color: accentColor,
					secondary_color: backgroundColor,
					third_color: '#FFFFFF',
					fourth_color: supportColor
				}
			}

			setData(aux)
			onSave?.(aux.profile_image)
			setOpen(false)
			handleClosePreview()
		}
	}

	return (
		<form className="mt-4 flex flex-col  h-full">
			<div className="flex justify-center w-full">
				<AppColorMatching
					accentColor={accentColor}
					backgroundColor={backgroundColor}
					supportColor={supportColor}
				/>
			</div>
			<div className="overflow-y-auto mt-2    ">
				<div className="mt-2">
					<Disclosure>
						{({ open }) => (
							<>
								<Disclosure.Button className="flex items-center justify-between w-full">
									<Typography
										title="Accent Color"
										type={TypographyTypes.h3}
										className="text-gray-9 !font-bold"
										fontLeading="15/18"
									/>
									<Icon
										src={open ? Icons.arrowUp : Icons.arrowDown}
										className="w-4"
									/>
								</Disclosure.Button>

								<Disclosure.Panel>
									<div className="py-1 flex flex-col gap-6 mt-2">
										<ColorPicker
											allowCustomColor
											onChange={setAccentColor}
											selected={accentColor}
											colors={listAccentColor}
										/>

										<Disclosure.Panel />
									</div>
								</Disclosure.Panel>
							</>
						)}
					</Disclosure>
				</div>

				<div className="mt-4">
					<Disclosure>
						{({ open }) => (
							<>
								<Disclosure.Button className="flex items-center justify-between w-full">
									<Typography
										title="Background Color"
										type={TypographyTypes.h3}
										className="text-gray-9 !font-bold"
										fontLeading="15/18"
									/>
									<Icon
										src={open ? Icons.arrowUp : Icons.arrowDown}
										className="w-4"
									/>
								</Disclosure.Button>

								<Disclosure.Panel>
									<div className="py-1 flex flex-col gap-6 mt-2">
										<ColorPicker
											allowCustomColor
											onChange={setBackgroundColor}
											selected={backgroundColor}
											colors={listBackgroundColor}
										/>

										<Disclosure.Panel />
									</div>
								</Disclosure.Panel>
							</>
						)}
					</Disclosure>
				</div>
				<div className="mt-4 mb-4">
					<Disclosure>
						{({ open }) => (
							<>
								<Disclosure.Button className="flex items-center justify-between w-full">
									<Typography
										title="Support Color"
										type={TypographyTypes.h3}
										className="text-gray-9 !font-bold"
										fontLeading="15/18"
									/>
									<Icon
										src={open ? Icons.arrowUp : Icons.arrowDown}
										className="w-4"
									/>
								</Disclosure.Button>

								<Disclosure.Panel>
									<div className="py-1 flex gap-6 mt-2 justify-between items-center">
										<ColorPicker
											allowCustomColor
											onChange={setSupportColor}
											selected={supportColor}
											colors={listSupportColor}
										/>

										<button
											type="button"
											className="flex flex-col items-center border border-blue-primary px-2 py-0.5 rounded-md opacity-80 hover:opacity-100"
											onClick={() => setOpenPreview(prev => !prev)}
										>
											{openPreview ? (
												<EyeOffIcon className="text-blue-primary h-6 w-6" />
											) : (
												<EyeIcon className="text-blue-primary h-6 w-6" />
											)}
											<Typography
												title="View"
												type={TypographyTypes.span}
												className=" text-blue-primary text-sm font-light"
												fontLeading="15/18"
											/>
										</button>
									</div>
								</Disclosure.Panel>
							</>
						)}
					</Disclosure>
				</div>
			</div>

			<div className="w-full flex justify-between items-center mt-3">
				<Button
					label="Cancel"
					color="Gray2"
					small
					onClick={() => setOpen(false)}
				/>
				<Button label="Save" small onClick={() => handleSubmit()} />
			</div>

			<div
				className={clsx(
					'px-4 py-3 gap-4 bg-white transition-all flex flex-col  rounded-[16px]',
					'fixed',
					'bottom-1 -translate-x-[108%]',
					{
						'opacity-100': openPreview,
						hidden: !openPreview
					}
				)}
			>
				<div className="justify-between items-center flex border-b pb-2 border-gray-4">
					<Typography
						title="Support Color"
						type={TypographyTypes.span}
						className="font-normal text-lg !text-gray-9 "
						fontLeading="18/21"
					/>
					<Button
						iconLeft={Icons.closeGeneric}
						className="!w-max !min-w-[unset] !px-[unset] !py-[unset] !border-gray-3"
						leftIconDivClassName="!w-7 !h-7 text-gray-3"
						color="Gray3"
						onClick={handleClosePreview}
					/>
				</div>
				<div className="flex gap-4 pb-3">
					<EntryScreenColorMatching
						accentColor={accentColor}
						backgroundColor={backgroundColor}
						supportColor={supportColor}
					/>
					<NotificationColorMatching
						accentColor={accentColor}
						backgroundColor={backgroundColor}
						supportColor={supportColor}
					/>
				</div>
			</div>
		</form>
	)
}
