import { Disclosure } from '@headlessui/react'
import { CircularProgress } from '@mui/material'
import { GET_PEOPLE_SUBTAGS_TABLE, getPeopleSubtagsTable } from 'api'
import clsx from 'clsx'
import { Icon, InputCheck, Typography } from 'components'
import { Icons, SubTagsProps, TypographyTypes } from 'interfaces'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'

export const SubTags: React.FC<SubTagsProps> = ({
	tag,
	list,
	setList,
	defaultOpenParent
}): JSX.Element => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')

	const { data, isLoading } = useQuery(
		[GET_PEOPLE_SUBTAGS_TABLE, userId, organizationId, tag],
		() => getPeopleSubtagsTable(tag.organization_tag_parent_id)
	)

	const handleSelectedAll = (state: string): void => {
		if (data) {
			let newList = list.slice()

			if (state === 'Select All') {
				data.items.forEach(subTag => {
					if (!newList.includes(subTag.organization_tag_id)) {
						newList.push(subTag.organization_tag_id)
					}
				})
			} else {
				data.items.forEach(subTag => {
					newList = newList.filter(item => item !== subTag.organization_tag_id)
				})
			}

			setList([...newList])
		}
	}

	const idSubTags = data?.items.map(item => item.organization_tag_id)

	const elementosEnOtroArray = (): boolean => {
		if (idSubTags) {
			return idSubTags.every(item => list.includes(item))
		}
		return false
	}

	useEffect(() => {
		if (list) elementosEnOtroArray()
	}, [list])

	return (
		<Disclosure
			key={tag.organization_tag_parent_id}
			defaultOpen={defaultOpenParent}
		>
			{({ open }) => (
				<div className="relative z-0">
					{open && tag.is_visible !== false && (
						<button
							type="button"
							className="bg-blue-primary px-4 py-[3px] rounded-[100px] absolute top-0 right-0 z-10"
							onClick={() =>
								handleSelectedAll(
									!elementosEnOtroArray() ? 'Select All' : 'Deselect All'
								)
							}
						>
							<Typography
								title={clsx(
									!elementosEnOtroArray() ? 'Select All' : 'Deselect All'
								)}
								className="text-white"
								type={TypographyTypes.h2}
								fontLeading="15/22"
							/>
						</button>
					)}

					<Disclosure.Button className="w-full flex justify-between items-center mb-4">
						<div className="flex items-center gap-2">
							<Typography
								className="text-black-1"
								title={tag.name}
								fontLeading="15/18"
								type={TypographyTypes.p}
							/>
							{tag.is_visible === false && (
								<Typography
									className="text-gray-1"
									title="(Hidden)"
									fontLeading="10/12"
									type={TypographyTypes.p}
								/>
							)}
						</div>
						{!open && (
							<Icon
								src={Icons.arrowDown}
								className="w-4 text-gray-1"
								fillLine
							/>
						)}
						{open && tag.is_visible === false && (
							<Icon
								src={Icons.arrowUp}
								className="w-4 text-gray-9"
								fillLine
								fillPath
							/>
						)}
					</Disclosure.Button>
					<Disclosure.Panel className="text-gray-500 mb-4   border-b-[0.5px] border-gray-3">
						{isLoading ? (
							<div className="flex items-center justify-center w-full h-[400px] py-2 px-1 ">
								<CircularProgress disableShrink />
							</div>
						) : (
							data?.items
								.slice()
								.sort((a, b) =>
									a.name && b.name ? a.name.localeCompare(b.name) : 0
								)
								.map(item => (
									<InputCheck
										list={list}
										className="mb-4"
										setList={setList}
										title={item.name}
										reach={item.num_users}
										id={item.organization_tag_id}
										key={item.organization_tag_id}
										name={`${item.name}-${item.organization_tag_id}`}
										isVisible={item.is_visible}
										hasCheckVisible
									/>
								))
						)}
					</Disclosure.Panel>
				</div>
			)}
		</Disclosure>
	)
}
