import {
	AdminOrganizationStepFooter,
	Avatar,
	Box,
	Button,
	ColorPicker,
	InputFile,
	Message,
	Title,
	Typography
} from 'components'
import {
	AdminOrganizationStepComponentProps,
	CustomizeForm,
	Icons,
	TypographyTypes
} from 'interfaces'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { configGenaral } from 'utils'
import { PreviewOrganization } from './PreviewOrganization'

const availableColors = {
	accentColor: [
		'#941B22',
		'#00B188',
		'#FF9900',
		'#00148B',
		'#673AB7',
		'#D63D3D'
	],
	backgroundColor: [
		'#F8FAFF',
		'#FFF8F8',
		'#F4F4F4',
		'#FFFFFF',
		'#5A5A5A',
		'#2C2C2C'
	],
	textColor: ['#FFFFFF', '#323232'],
	supportColor: ['#FFFFFF', '#C5C5C5', '#A3A3A3', '#5A5A5A', '#323232']
}

const ICON_MIN_HEIGHT = 1080
const ICON_MIN_WIDTH = 1080

export const Customize: React.FC<
	AdminOrganizationStepComponentProps<CustomizeForm>
> = ({ stepper, onNext, onBack, data }) => {
	const {
		setValue,
		watch,
		register,
		handleSubmit,
		formState: { errors, isValid },
		trigger
	} = useForm<CustomizeForm>({
		defaultValues: {
			accentColor: data?.accentColor || availableColors.accentColor[0],
			backgroundColor:
				data?.backgroundColor || availableColors.backgroundColor[0],
			textColor: data?.textColor || availableColors.textColor[0],
			supportColor: data?.supportColor || availableColors.supportColor[0],
			icon: data?.icon || null
		}
	})
	const [iconUrl, setIconUrl] = useState<string>()

	const iconHasValidDimensions = async (): Promise<boolean> => {
		if (!iconUrl) return false
		const image = new Image()
		image.src = iconUrl
		return new Promise(res => {
			image.onload = () => {
				const { height, width } = image
				res(height >= ICON_MIN_HEIGHT && width >= ICON_MIN_WIDTH)
			}
		})
	}

	register('accentColor', { required: 'Accent color is required' })
	register('backgroundColor', { required: 'Background color is required' })
	register('icon', {
		required: 'Icon is required',
		validate: {
			validateImageDimensions: async () => {
				return !(await iconHasValidDimensions())
					? `Please upload an image with dimensions of at least ${ICON_MIN_WIDTH} x ${ICON_MIN_HEIGHT} pixels.`
					: true
			},
			validateSize: (file: File | null) =>
				file && file.size / 1024 ** 2 > configGenaral.maxfileSizeMB
					? `Please select a file less than ${configGenaral.maxfileSizeMB}MB.`
					: true
		}
	})
	register('textColor', { required: 'Text color is required' })

	const { accentColor, backgroundColor, icon, textColor, supportColor } =
		watch()

	const generateIconUrl = (file: File): void => {
		const reader = new FileReader()
		reader.addEventListener(
			'load',
			() => {
				setIconUrl(reader.result as string)
				trigger('icon')
			},
			false
		)

		if (file) {
			reader.readAsDataURL(file)
		}
	}
	useEffect(() => {
		if (icon) generateIconUrl(icon)
	}, [icon])

	return (
		<>
			<div className="flex justify-between mb-5">
				<Title
					icon={Icons.groups}
					title="Customize"
					subtitle="Customize the look of your organization"
				/>
				{stepper}
			</div>
			<div className="flex justify-between">
				<Box className="w-9/12 lg:w-9/12 md:mr-11 flex flex-col gap-4 p-8 xs:mr-20">
					<>
						<div>
							<Typography
								className="text-gray-9"
								type={TypographyTypes.h2}
								title="Upload the icon for your organization"
								fontLeading="15/18"
							/>
							<Typography
								className="text-gray-9 !font-normal"
								type={TypographyTypes.span}
								title="Should be at least 1080 x 1080px"
								fontLeading="15/18"
							/>
						</div>
						<div className="flex gap-7 items-center">
							<Avatar src={iconUrl} />
							<InputFile
								onChange={e => {
									const file = e.target.files?.item(0)
									if (file) setValue('icon', file)
								}}
								accept="image/png, image/jpeg, image/jpg"
								renderButton={
									<Button
										className="py-2 px-6"
										color="Transparent"
										label="Upload"
									/>
								}
							/>
							{errors.icon?.message && (
								<Message text={errors.icon.message} type="error" />
							)}
						</div>
						<div>
							<Typography
								className="text-gray-9"
								type={TypographyTypes.h2}
								title="Select the colors of your organization"
								fontLeading="15/18"
							/>
							<Typography
								className="text-gray-9 !font-normal"
								type={TypographyTypes.span}
								title="Color should reflect your organization's brand"
								fontLeading="15/18"
							/>
						</div>
						<div className="flex flex-col gap-12">
							<div>
								<Typography
									className="text-gray-9 mb-2"
									type={TypographyTypes.h2}
									title="Accent Color"
									fontLeading="15/18"
								/>
								<ColorPicker
									allowCustomColor
									onChange={color => setValue('accentColor', color)}
									selected={accentColor}
									colors={availableColors.accentColor}
								/>
							</div>
							<div>
								<Typography
									className="text-gray-9 mb-2"
									type={TypographyTypes.h2}
									title="Background Color"
									fontLeading="15/18"
								/>
								<ColorPicker
									allowCustomColor
									onChange={color => setValue('backgroundColor', color)}
									selected={backgroundColor}
									colors={availableColors.backgroundColor}
								/>
							</div>
							<div>
								<Typography
									className="text-gray-9 mb-2"
									type={TypographyTypes.h2}
									title="Support Color"
									fontLeading="15/18"
								/>
								<ColorPicker
									allowCustomColor
									onChange={color => setValue('supportColor', color)}
									selected={supportColor}
									colors={availableColors.supportColor}
								/>
							</div>
						</div>
					</>
				</Box>

				<Box className="w-96 flex flex-col h-full ">
					<PreviewOrganization
						accentColor={accentColor}
						backgroundColor={backgroundColor}
						supportColors={supportColor}
						iconUrl={iconUrl}
					/>
				</Box>
			</div>
			<AdminOrganizationStepFooter
				className="mt-4 mb-2"
				saveDraft
				onBack={() =>
					onBack?.({
						accentColor,
						backgroundColor,
						textColor,
						supportColor,
						icon
					})
				}
				disabledNext={!isValid}
				onNext={handleSubmit(() =>
					onNext?.({
						accentColor,
						backgroundColor,
						textColor,
						supportColor,
						icon
					})
				)}
			/>
		</>
	)
}
