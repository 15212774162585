import { ExclamationCircleIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import { InputTextAreaProps } from 'interfaces'
import React, { useEffect } from 'react'

export const InputTextArea: React.FC<InputTextAreaProps> = ({
	name,
	color,
	error,
	rules,
	register,
	className,
	textAreaClassName,
	textValue,
	watchValue,
	setTextValue,
	setValueInput,
	disabled,
	row,
	type,
	limit = false,
	cantLimit = 60,
	...props
}) => {
	const registerInput = register?.(name, rules)

	useEffect(() => {
		if (watchValue && watchValue.length > cantLimit && setValueInput) {
			const cadena = watchValue.substring(0, watchValue.length - 1)
			const aux = name
			setValueInput(aux, cadena)
		}
	}, [watchValue])

	return (
		<div className={className}>
			<div className="relative">
				<textarea
					{...registerInput}
					id={name}
					rows={row ?? 5}
					disabled={disabled}
					{...props}
					onChange={e => {
						if (type === 'number') {
							const inputValue = e.target.value
							const onlyNumbers = inputValue.replace(/[^0-9]/g, '')
							e.target.value = onlyNumbers
							setTextValue?.(onlyNumbers)
						} else {
							setTextValue?.(e.target.value)
						}
					}}
					value={textValue}
					className={clsx(
						'w-full p-3 focus:ring-0 focus:border-gray-3 ',
						color === 'Gray3' &&
							'bg-transparent-default text-black border border-gray-3 rounded ',
						textAreaClassName,
						'text-base sm:text-sm md:text-sm'
					)}
				/>
				{limit && (
					<div className="absolute -bottom-[15px] right-0">
						<h1 className="text-xs leading-[9px] text-black-1/50">
							{watchValue ? watchValue.length : 0}/{cantLimit}
						</h1>
					</div>
				)}
			</div>
			{error?.message && (
				<div className="flex items-center mt-2 text-red-500">
					<div className="mr-1 w-4 h-4">
						<ExclamationCircleIcon
							className="w-4 h-4 text-red-500"
							aria-hidden="true"
						/>
					</div>
					<h1 className="text-xs">{error.message}</h1>
				</div>
			)}
		</div>
	)
}
