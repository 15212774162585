import clsx from 'clsx'
import { Box, Button, ModalSetOrder, Typography } from 'components'
import { TypographyTypes, ViewOrganization } from 'interfaces'
import React from 'react'

interface TypeFeedProps {
	element: ViewOrganization
	classNameComponent?: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleChange: (value: any) => void
}

export const TypeFeed: React.FC<TypeFeedProps> = (props): JSX.Element => {
	const { classNameComponent, element, handleChange } = props
	const isEnabled = element.enabled

	return (
		<div
			className={clsx(
				'border border-blue-primary rounded-[16px] p-1 min-h-[25rem]',
				classNameComponent,
				{
					'border-[#A3A3A3]': !isEnabled
				}
			)}
		>
			<Box className="flex flex-col justify-between w-full ">
				<>
					<Typography
						title={element.name}
						fontLeading="16/18"
						type={TypographyTypes.h3}
						className="mb-2"
					/>
					<div
						className={clsx('flex justify-center items-center', {
							grayScale: !isEnabled
						})}
					>
						<img
							src={element.image}
							alt={element.name}
							className="max-h-[300px] max-w-[138px]"
						/>
					</div>

					<Typography
						title={element.description}
						fontLeading="15/22"
						type={TypographyTypes.p}
						className="text-gray-1 my-2 flex justify-center items-center md:justify-start"
					/>
					<div className="flex gap-3 w-full justify-center items-center flex-wrap my-1 md:flex-row sm:flex-col md:justify-between">
						<div className="flex items-center">
							<Typography
								title="Set order in app:"
								fontLeading="16/18"
								type={TypographyTypes.h3}
								className="pr-2"
							/>
							<ModalSetOrder
								order={element.order}
								handleSubmit={value =>
									handleChange({
										...element,
										order: value
									})
								}
								classNameButton={clsx({ 'bg-[#A3A3A3]': !isEnabled })}
								isDisabled={!isEnabled}
							/>
						</div>
						<Button
							label={!isEnabled ? 'Enable' : 'Disable'}
							color={!isEnabled ? 'Primary' : 'Transparent'}
							className=" !min-w-[96px] !max-w-[96px]"
							onClick={() => handleChange({ ...element, enabled: !isEnabled })}
						/>
					</div>
				</>
			</Box>
		</div>
	)
}

TypeFeed.defaultProps = {
	classNameComponent: ''
}
