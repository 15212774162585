import { Dialog, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { Button, Helper, Typography } from 'components'
import { Icons, SlideProps, TypographyTypes } from 'interfaces'
import React, { Fragment } from 'react'

export const Slide: React.FC<SlideProps> = ({
	open,
	title,
	scroll,
	setOpen,
	helper,
	titleHelper,
	fillpat,
	children,
	classNameTitle,
	wide,
	closeIconColor
}): JSX.Element => {
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={setOpen}>
				<div className="fixed inset-0 bg-black-1 bg-opacity-25" />

				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel
									className={clsx('pointer-events-auto w-screen max-w-md', {
										'max-w-[38.5rem]': !wide
									})}
								>
									<div className="flex h-full flex-col overflow-y-scroll hide-scroll-bar bg-white py-6 shadow-[-4px_0px_8px_rgba(0,_0,_0,_0.15)] rounded-l-[16px]">
										<div className="px-4 sm:px-6">
											<div className="flex items-start justify-between ">
												<div className="flex justify-between  w-[35%] sm:w-[30%] ">
													<Typography
														title={title}
														fontLeading="18/21"
														className={clsx('text-black-1', classNameTitle)}
														type={TypographyTypes.h2}
													/>

													{helper && (
														<Helper
															text={titleHelper as string}
															className="relative border-none text-sm"
															filpath={fillpat}
															classPanel="min-w-[300px]  ml-2"
														/>
													)}
												</div>

												<Dialog.Title className="text-lg font-medium text-gray-900" />
												<div className="ml-3 flex h-7 items-center">
													<Button
														iconLeft={Icons.closeGeneric}
														className="!w-max !min-w-[unset] !px-[unset] !py-[unset]"
														leftIconDivClassName="!w-7 !h-7"
														color={closeIconColor ?? 'Gray3'}
														onClick={() => setOpen(false)}
													/>
												</div>
											</div>
										</div>
										<div
											className={clsx(
												'relative flex-1 px-4 sm:px-6',
												scroll ? 'overflow-y-hidden' : ''
											)}
										>
											{children}
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
