/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * TODO:
 * Schedule appointment for review.
 */

import { CircularProgress } from '@mui/material'
import {
	GET_LIST_LOCATIONS_BY_ID,
	GET_LIST_LOCATIONS_FOLLOWERS,
	GET_LOCATIONS
} from 'api'
import { deleteLocationsList } from 'api/delete/location'
import {
	getLocationsList,
	getLocationsListById,
	getLocationsListFollowers
} from 'api/get/locations'
import { patchEditLocationsList } from 'api/patch/location'
import { postNewLocationLists } from 'api/post/location'
import { Button, Input, ModalConfirm, TailwindModal } from 'components'
import { LocationListsTable } from 'components/table/location/locationListsTable'
import { LocationListsTableFollowers } from 'components/table/location/locationListsTableFollowers'
import { useSearchDebounce, useToast } from 'hooks'
import {
	EditLocationsListRequest,
	GenericModalProps,
	Icons,
	InfoToModalConfirm,
	LocationListResponse,
	LocationListsTableResponse,
	NewLocationListRequest
} from 'interfaces'
import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'

const MAX_SEARCH_LENGTH = 15

export const LocationListsModal: React.FC<GenericModalProps<any>> = ({
	open,
	setOpen,
	refetch
}): JSX.Element => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const [newListValue, setNewListValue] = useState('')
	const [search, setSearch] = useState('')
	const { inputSearch, handleChangeDebounce, resetInput } =
		useSearchDebounce('')
	const [dataSelected, setDataSelected] = useState<number[]>([])
	const [listsLocationsPage, setListsLocationsPage] = useState(1)
	const [followersPage, setFollowersPage] = useState(1)
	const [listToDelete, setListToDelete] = useState<number | null>(null)
	const [infoModalCreateEdit, setInfoModalCreateEdit] =
		useState<InfoToModalConfirm | null>(null)
	const [listToEdit, setListToEdit] = useState<number>(0)
	const [isLoadingLocal, setIsLoadingLocal] = useState<boolean>(false)

	const {
		data,
		isLoading,
		refetch: refetchFollowers
	} = useQuery(
		[GET_LIST_LOCATIONS_FOLLOWERS, followersPage, userId, organizationId],
		() => getLocationsListFollowers(organizationId)
	)

	const { data: dataListById, isLoading: isLoadingById } = useQuery(
		[GET_LIST_LOCATIONS_BY_ID, listToEdit, userId, organizationId],
		() => getLocationsListById(organizationId, listToEdit),
		{
			enabled: listToEdit !== 0
		}
	)

	const { showToast } = useToast()

	const deleteList = async (listId: number): Promise<void> => {
		setIsLoadingLocal(true)
		const res = await deleteLocationsList(organizationId, listId)
		showToast(
			res ? 'Deleted' : 'Error',
			res ? 'List deleted successfully' : 'Error deleting list',
			res ? 'success' : 'error'
		)
		refetchFollowers()
		setIsLoadingLocal(false)
	}
	const isEdit = dataListById && listToEdit

	useEffect(() => {
		if (isEdit) {
			setNewListValue(dataListById.name)

			const uniqueIds: any = []
			dataListById.location_ids?.forEach(item => {
				if (!uniqueIds.includes(item)) {
					uniqueIds.push(item)
				}
			})

			const selectedIds = uniqueIds ?? []
			setDataSelected(selectedIds)
		}
	}, [dataListById, listToEdit])

	const {
		data: listLocations,
		isLoading: listLocationsIsLoading,
		refetch: listLocationsRefetch
	} = useQuery(
		[
			GET_LOCATIONS,
			{
				page: followersPage,
				search: inputSearch.trim(),
				userId,
				organizationId,
				listsLocationsPage
			},
			isEdit
		],
		() =>
			getLocationsList(
				organizationId,
				listsLocationsPage,
				inputSearch.trim(),
				'',
				null
			)
	)

	const submitHandler = useCallback(
		async (body: NewLocationListRequest): Promise<void> => {
			postNewLocationLists(organizationId, body).then(response => {
				if (response?.detail) {
					showToast('Error', response.detail, 'error')
				} else {
					refetchFollowers()
					listLocationsRefetch()
					setDataSelected([])
					setNewListValue('')
					setInfoModalCreateEdit({
						icon: Icons.checkCircleTags,
						title: 'Location List Created',
						descripcion: 'Your new list was created successfully'
					})
					refetch?.()
				}
				setIsLoadingLocal(false)
			})
		},
		[]
	)

	const submitHandlerEdit = async (
		body: EditLocationsListRequest
	): Promise<void> => {
		patchEditLocationsList(organizationId, listToEdit, body).then(response => {
			if (response?.detail) {
				showToast('Error', response.detail, 'error')
			} else {
				setListToEdit(0)
				refetchFollowers()
				listLocationsRefetch()
				setDataSelected([])
				setNewListValue('')
				refetch?.()
				setInfoModalCreateEdit(null)
			}
			setIsLoadingLocal(false)
		})
	}

	const handleClick = (): void => {
		setIsLoadingLocal(true)
		if (isEdit) {
			submitHandlerEdit({
				name: newListValue,
				location_ids: dataSelected
			})
		} else {
			submitHandler({
				name: newListValue,
				author_id: userId,
				location_ids: dataSelected
			})
		}
	}

	const loading =
		listLocationsIsLoading ?? isLoading ?? isLoadingById ?? isLoadingLocal
	return (
		<>
			<TailwindModal
				open={open}
				setOpen={setOpen}
				title="Location Lists"
				className="md:w-[95vw] w-full p-4 rounded-[16px] h-[87vh]"
			>
				{loading ? (
					<div className="flex h-full w-full justify-center items-center">
						<CircularProgress disableShrink />
					</div>
				) : (
					<>
						<div className="flex flex-col gap-6 w-full md:h-[calc(100%_-_134px)] h-[calc(100%_-_164px)] mt-4 overflow-y-auto">
							<Input
								name="message"
								title="New list name"
								placeholder="Text here"
								className="!w-full !p-[unset]"
								value={newListValue}
								onChange={event => setNewListValue(event.target.value)}
								maxLength={MAX_SEARCH_LENGTH}
								error={
									newListValue.length >= MAX_SEARCH_LENGTH
										? {
												message: `Maximum ${MAX_SEARCH_LENGTH} characters`,
												type: 'maxLength'
										  }
										: undefined
								}
								inputClassName="!p-[unset] !py-1 !px-2"
								inputFocus
							/>
							<Input
								name="search"
								placeholder="Search Locations"
								className="!w-full !p-[unset]"
								onChange={e => {
									setSearch(e.target.value)
									setListsLocationsPage(1)
									handleChangeDebounce(e)
								}}
								rightIcon={Icons.cancel}
								rightClick={() => {
									resetInput()
									setSearch('')
								}}
								value={search}
								maxLength={MAX_SEARCH_LENGTH}
								inputClassName="!p-[unset] !py-1 !px-2"
								inputFocus
								error={
									search.length >= MAX_SEARCH_LENGTH
										? {
												message: `Maximum ${MAX_SEARCH_LENGTH} characters`,
												type: 'maxLength'
										  }
										: undefined
								}
								borderFull={false}
								search
							/>
							<div className="w-full flex gap-4 flex-col md:flex-row">
								<LocationListsTableFollowers
									data={data as LocationListsTableResponse | undefined}
									isLoading={listLocationsIsLoading}
									className="basis-[50%]"
									page={followersPage}
									onDelete={setListToDelete}
									onEdit={setListToEdit}
									setPage={setFollowersPage}
								/>
								<LocationListsTable
									data={listLocations as LocationListResponse | undefined}
									isLoading={
										listLocationsIsLoading ?? isLoading ?? isLoadingById
									}
									className="basis-[50%]"
									page={listsLocationsPage}
									setPage={setListsLocationsPage}
									setDataSelected={setDataSelected}
									dataSelected={dataSelected}
									listToEdit={listToEdit}
								/>
							</div>
						</div>
						<div className="flex justify-center items-center mt-4 gap-4 w-full md:flex-row flex-col-reverse">
							<Button
								label="Cancel"
								color="LavenderLight"
								onClick={() => setOpen(false)}
							/>
							<Button
								label="Save"
								disabled={newListValue === '' || dataSelected.length === 0}
								onClick={() => {
									if (isEdit) {
										setInfoModalCreateEdit({
											icon: Icons.checkCircleTags,
											title: 'Update List',
											descripcion: 'Are you sure you want to Update this list?'
										})
									} else {
										handleClick()
									}
								}}
							/>
						</div>
					</>
				)}
			</TailwindModal>
			<ModalConfirm
				open={listToDelete !== null}
				setOpen={() => setListToDelete(null)}
				onClickConfirm={() => {
					if (listToDelete) deleteList(listToDelete)
					setListToDelete(null)
				}}
				icon={Icons.warning}
				title="Delete List"
				descripcion="Are you sure you want to delete this list?"
			/>
			<ModalConfirm
				open={infoModalCreateEdit !== null}
				setOpen={() => setInfoModalCreateEdit(null)}
				onClickConfirm={() => {
					if (isEdit) {
						handleClick()
					} else {
						setInfoModalCreateEdit(null)
					}
				}}
				icon={infoModalCreateEdit?.icon}
				title={infoModalCreateEdit?.title}
				descripcion={infoModalCreateEdit?.descripcion}
			/>
		</>
	)
}
