import moment from 'moment'

export const formatCurrency = (x: number, format: string): string => {
	let aux: string
	switch (format) {
		case 'pt-BR':
			aux = new Intl.NumberFormat('pt-BR', {
				style: 'currency',
				currency: 'BRL'
			}).format(x)
			break
		case 'en-US':
			aux = `BRL ${new Intl.NumberFormat('en-US').format(x)}`
			break
		case 'es-ES':
			aux = `BRL ${new Intl.NumberFormat('es-ES').format(x)}`
			break
		default:
			aux = ''
	}
	return aux.replace(/.{2}.$/, '')
}

export const formatNumber = (x: number, format = 'en-US'): string => {
	let aux: string
	switch (format) {
		case 'pt-BR':
			aux = new Intl.NumberFormat('pt-BR').format(x)
			break
		case 'en-US':
			aux = new Intl.NumberFormat('en-US').format(x)
			break
		case 'es-ES':
			aux = new Intl.NumberFormat('es-ES').format(x)
			break
		default:
			aux = ''
	}
	return aux
}

export const formatDateTime = (x: Date, format: string): string => {
	let aux: string
	switch (format) {
		case 'pt-BR':
			aux = x.toLocaleString('pt-BR').replace(/(.*)\D\d+/, '$1')
			break
		case 'en-US':
			aux = x.toLocaleString('en-US').replace(/(.*)\D\d+/, '$1')
			break
		case 'es-ES':
			aux = x.toLocaleString('es-ES').replace(/(.*)\D\d+/, '$1')
			break
		default:
			aux = ''
	}
	return aux
}

export const formatDate = (x: Date | null, format: string): string => {
	let aux: string
	if (x) {
		switch (format) {
			case 'pt-BR':
				aux = x.toLocaleDateString('pt-BR', {
					year: 'numeric',
					month: 'numeric',
					day: 'numeric'
				})
				break
			case 'en-US':
				aux = x.toLocaleDateString('en-US', {
					year: 'numeric',
					month: 'numeric',
					day: 'numeric'
				})
				break
			case 'es-ES':
				aux = x.toLocaleDateString('es-ES', {
					year: 'numeric',
					month: 'numeric',
					day: 'numeric'
				})
				break
			default:
				aux = ''
		}
		return aux
	}
	return ''
}

export const formatTimeString = (x: string, format: string): string => {
	let aux: string
	const [hours, minutes] = x.split(':')
	const date = new Date()
	date.setHours(Number(hours))
	date.setMinutes(Number(minutes))
	switch (format) {
		case 'pt-BR':
			aux = date.toLocaleTimeString('pt-BR', {
				hour: 'numeric',
				minute: 'numeric',
				hour12: true
			})
			break
		case 'en-US':
			aux = date.toLocaleTimeString('en-US', {
				hour: 'numeric',
				minute: 'numeric',
				hour12: true
			})
			break
		case 'es-ES':
			aux = date.toLocaleTimeString('es-ES', {
				hour: 'numeric',
				minute: 'numeric',
				hour12: true
			})
			break
		default:
			aux = ''
	}
	return aux
}

export const diffDate = (x: string): boolean => {
	const today = moment().format('YYYY-MM-DD').split('-')
	const newDate = moment(x).format('YYYY-MM-DD').split('-')
	let band = true
	if (parseInt(today[0], 10) < parseInt(newDate[0], 10)) {
		band = false
	} else if (parseInt(today[1], 10) < parseInt(newDate[1], 10)) {
		band = false
	} else if (parseInt(today[2], 10) < parseInt(newDate[2], 10)) {
		band = false
	}
	return band
}

export const notPastDateValidation = (x: string): boolean => {
	const today = moment().format('YYYY-MM-DD').split('-')
	const newDate = moment(x).format('YYYY-MM-DD').split('-')
	if (parseInt(today[0], 10) > parseInt(newDate[0], 10)) {
		return false
	}
	if (parseInt(today[1], 10) > parseInt(newDate[1], 10)) {
		if (parseInt(newDate[0], 10) >= parseInt(today[0], 10)) {
			return false
		}
	}
	if (parseInt(today[2], 10) > parseInt(newDate[2], 10)) {
		if (parseInt(today[0], 10) >= parseInt(newDate[0], 10)) {
			if (parseInt(today[1], 10) >= parseInt(newDate[1], 10)) {
				return false
			}
		}
	}
	return true
}

export const diffTwoDate = (x: string, y: string): boolean => {
	if (x === 'Data inválida' || y === 'Data inválida') {
		return true
	}
	const startDate = y.split('-')
	const endDate = moment(x).format('YYYY-MM-DD').split('-')
	if (parseInt(startDate[0], 10) > parseInt(endDate[0], 10)) {
		return true
	}
	if (parseInt(startDate[1], 10) > parseInt(endDate[1], 10)) {
		if (parseInt(endDate[0], 10) >= parseInt(startDate[0], 10)) {
			return true
		}
	}
	if (parseInt(startDate[2], 10) >= parseInt(endDate[2], 10)) {
		if (parseInt(startDate[0], 10) >= parseInt(endDate[0], 10)) {
			if (parseInt(startDate[1], 10) >= parseInt(endDate[1], 10)) {
				return true
			}
		}
	}
	return false
}

export const normalizeDeepLinkAlias = (alias: string): string => {
	return alias.replace(/[^\w\d-_]/g, '_')
}
