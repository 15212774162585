import {
	GET_TAGS_TABLE,
	editFiltersDynamicList,
	getListFiltersId,
	getPeopleTag
} from 'api'
import { useToast } from 'hooks'
import {
	ConvertedDataItem,
	ConvertedItem,
	DropdownFullItemProps,
	FilterDinamicModalProps,
	ListfilterDinamic,
	MyObject,
	MyObjectLoc,
	OriginalItem,
	PeopleMyListsItem,
	SaveListDinamic
} from 'interfaces'
import {
	allOptionsOperator,
	typeAndOR,
	typeOS,
	typePropierty,
	typePushAndLoc,
	typeStatus
} from 'lib/dataMypeoplefilter'
import React, { useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'
import { DinamicListForm } from './FilterModalDinami'

const FilterDinamicModal: React.FC<
	FilterDinamicModalProps<PeopleMyListsItem>
> = ({
	open,
	setOpen,
	rows,
	setRows,
	onConvertData,
	propierty,
	operator,
	comparator,
	setDataFilter,
	setIsSaveList,
	data: dataListDinamic,
	resetFilter,
	resetRow,
	typeState,
	mylistTableRefetch,
	setPeopleMyPeopleTablePage
}) => {
	const cookies = new Cookies()
	const organizationId = cookies.get('organization_id')
	const [isValid, setIsValid] = useState(false)
	const { showToast } = useToast()
	const {
		register,
		handleSubmit,
		setValue,
		watch,
		formState: { errors }
	} = useForm<SaveListDinamic>({
		mode: 'all'
	})
	const nameWatch = watch('name')
	const rules = useMemo(() => {
		return {
			name: {
				required: { value: true, message: 'This field is required' }
			}
		}
	}, [])

	const { data: dataTags, isLoading: loadingTags } = useQuery(
		[GET_TAGS_TABLE, open],
		() => getPeopleTag(organizationId)
	)

	const { data: dataFilterId, isLoading } = useQuery([dataListDinamic], () =>
		getListFiltersId(organizationId, dataListDinamic?.id as number)
	)

	const validateProps = setRows && rows && comparator && propierty && operator

	const addRow = (): void => {
		if (validateProps) {
			setRows([...rows, { comparator, propierty, operator, value: '' }])
		}
	}

	const deleteRow = (index: number): void => {
		if (setRows && rows) {
			const newRows = [...rows]
			newRows.splice(index, 1)
			setRows(newRows)
		}
	}

	const getValue = (
		index: number,
		campo: string,
		valor: DropdownFullItemProps & (string | number | DropdownFullItemProps)
	): void => {
		if (setRows && rows) {
			const camposInput =
				campo === 'comparator' ||
				campo === 'propierty' ||
				campo === 'operator' ||
				campo === 'value'
			if (camposInput) {
				const newRows = [...rows]
				newRows[index][campo] = valor
				setRows(newRows)
			} else {
				setRows([])
				console.error(`El campo ${campo} no es una clave válida.`)
			}
		}
	}

	const handleFilter = (): void => {
		if (rows && setDataFilter && onConvertData && setPeopleMyPeopleTablePage) {
			const convertedData: ConvertedDataItem[] = onConvertData(rows)
			const jsonString = JSON.stringify(convertedData)
			const urlEncodedString = encodeURIComponent(jsonString)
			setDataFilter(urlEncodedString)
			setIsSaveList?.(true)
			setOpen(!open)
			setPeopleMyPeopleTablePage(1)
		}
	}

	useEffect(() => {
		const dataConverte = rows && onConvertData?.(rows)

		const hasInvalidValues = dataConverte?.some(
			row =>
				row.value === '' ??
				row.operator === 'Operator' ??
				row.property === 'Properties'
		)

		setIsValid(!hasInvalidValues)
	}, [rows])

	const capitalizeFirstLetter = (str: string): string => {
		return str.charAt(0).toUpperCase() + str.slice(1)
	}

	const whatKind = {
		property: typePropierty,
		operator: allOptionsOperator,
		comparator: typeAndOR
	}

	function getNameByValue(
		value: string,
		type: 'property' | 'operator' | 'comparator'
	): string {
		const typeArray = whatKind[type]
		const matchingItem = typeArray.find(item => item.value === value)
		if (matchingItem) {
			matchingItem.name = capitalizeFirstLetter(matchingItem.name)
		}

		return matchingItem ? matchingItem.name : value
	}

	function searchValueInArray(
		valor: string,
		propiertys: string
	): MyObject | MyObjectLoc | string {
		const found = dataTags?.items.find(item => item.id.toString() === valor)
		const foundLocOnPush = typePushAndLoc.find(item => item.value === valor)
		const foundStatus = typeStatus.find(item => item.value === valor)
		const foundOS = typeOS.find(item => item.value === valor)
		if (found && propiertys === 'tag') {
			return {
				id: found.id,
				name: found.name,
				value: found.id.toString()
			}
		}
		if (
			foundLocOnPush &&
			(propiertys === 'active_location' || propiertys === 'notification_push')
		) {
			return {
				name: foundLocOnPush.name,
				value: foundLocOnPush.value
			}
		}

		if (foundStatus && propiertys === 'status') {
			return {
				name: foundStatus.name,
				value: foundStatus.value
			}
		}

		if (foundOS && propiertys === 'device_type') {
			return {
				name: foundOS.name,
				value: foundOS.value
			}
		}

		return valor
	}

	const convertItem = (originalItem: OriginalItem): ConvertedItem => {
		return {
			comparator: {
				name: getNameByValue(originalItem.comparator, 'comparator'),
				value: originalItem.comparator
			},
			propierty: {
				name: getNameByValue(originalItem.property, 'property'),
				value: originalItem.property
			},
			operator: {
				name: getNameByValue(originalItem.operator, 'operator'),
				value: originalItem.operator
			},
			value: searchValueInArray(originalItem.value, originalItem.property)
		}
	}

	useEffect(() => {
		if (dataListDinamic && dataFilterId && dataTags) {
			const convertedData: ConvertedItem[] =
				dataFilterId.items.filters.map(convertItem)
			setRows?.(convertedData)
			setValue('name', dataListDinamic.name)
		}
	}, [dataListDinamic, dataFilterId, dataTags])

	const onCloseModal = (): void => {
		if (dataListDinamic && dataFilterId && resetFilter) {
			resetFilter()
		}
	}

	const editFilterDinamic = async (
		data: SaveListDinamic,
		id: number
	): Promise<void> => {
		try {
			const convertedData = rows && onConvertData?.(rows)

			const body = {
				name: data?.name ?? '',
				filters: convertedData as ListfilterDinamic[]
			}

			setOpen(!open)
			await editFiltersDynamicList(organizationId, id, body).then(response => {
				if (response) {
					showToast('Success', 'List saved successfully', 'success')
					if (mylistTableRefetch) {
						mylistTableRefetch()
					}
				}
			})
		} catch (error) {
			console.warn(error)
		}
	}

	const onSubmit: SubmitHandler<SaveListDinamic> = async data => {
		if (typeState && typeState === 'Create') {
			handleFilter()
		} else {
			editFilterDinamic(data, dataFilterId?.items.id as number)
		}
	}

	return (
		<DinamicListForm
			open={open}
			setOpen={setOpen}
			onCloseModal={onCloseModal}
			handleSubmit={handleSubmit}
			onSubmit={onSubmit}
			register={register}
			rules={rules}
			errors={errors}
			setValue={setValue}
			rows={rows}
			getValue={getValue}
			resetRow={resetRow}
			tags={dataTags?.items.map(item => {
				return {
					label: item.name,
					value: item.id
				}
			})}
			deleteRow={deleteRow}
			addRow={addRow}
			resetFilter={resetFilter}
			typeState={typeState}
			isValid={isValid}
			isvalidForm={nameWatch}
			isLoading={isLoading}
			loadingTags={loadingTags}
		/>
	)
}

export default FilterDinamicModal
