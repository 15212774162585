import { Button, Icon, TailwindModal, Typography } from 'components'
import { GenericModalProps, TypographyTypes } from 'interfaces'
import React from 'react'

export const ModalConfirm: React.FC<GenericModalProps<unknown>> = ({
	open,
	setOpen,
	onClickConfirm,
	title,
	descripcion,
	icon,
	buttonLabel = 'Continue'
}) => {
	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			showCloseButton
			className="w-[360px] min-h-[168px] p-4 rounded-[16px]"
		>
			<div className="flex flex-col gap-4 w-full">
				<div className="flex flex-col gap-3 w-full items-center">
					<div className="w-[30px] h-[30px]">
						{!!icon && (
							<Icon src={icon} fillPath className="text-blue-primary" />
						)}
					</div>
					{!!title && (
						<Typography
							title={title}
							fontLeading="15/18"
							type={TypographyTypes.h3}
							className="text-blue-primary text-center"
						/>
					)}
					{!!descripcion && (
						<Typography
							title={descripcion}
							fontLeading="15/18"
							type={TypographyTypes.h3}
							className="!font-normal text-black-1 text-center"
						/>
					)}
				</div>
				<div className="flex justify-center items-center gap-4 w-full">
					<Button
						label={buttonLabel}
						onClick={onClickConfirm}
						className="!min-w-[unset] !py-3 !px-9"
					/>
				</div>
			</div>
		</TailwindModal>
	)
}
