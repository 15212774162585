import { FollowerTagIndependent, Icons } from 'interfaces'
import { ValidState, stateTagsInterface } from './stateTagsUserType.type'

export const stateTags: stateTagsInterface = {
	pending: {
		state: '(Approval required)',
		icon: Icons.historyToggleOff
	},
	approved: {
		state: 'Approved',
		icon: Icons.checkCircleTags,
		className: 'text-green-tagAcceted'
	},
	rejected: {
		state: 'Rejected',
		icon: Icons.errorTags,
		className: 'text-red-tagRejected'
	}
}

export const getStateTags = (
	elementsTags: FollowerTagIndependent
): ValidState => {
	const {
		required_approval: requiredApproval,
		is_approved: isApprove,
		tagged
	} = elementsTags

	const isPending = requiredApproval && isApprove === null
	const isApproved = tagged && requiredApproval && isApprove
	const isReject = tagged && requiredApproval && isApprove === false

	let stateCurrent: ValidState = null
	if (isPending) {
		stateCurrent = 'pending'
	} else if (isApproved) {
		stateCurrent = 'approved'
	} else if (isReject) {
		stateCurrent = 'rejected'
	}
	return stateCurrent
}
