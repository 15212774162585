import { CircularProgress } from '@mui/material'
import { Input, InputCheck } from 'components'
import { DynamicList, DynamicListItem, Icons } from 'interfaces'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { configGenaral } from 'utils'

export const DinamycListContent: React.FC<DynamicList> = ({
	data,
	isLoading,
	totalList,
	resetInput,
	setTotalList,
	fetchNextPage,
	isFetchingNextPage,
	handleChangeDebounce,
	type
}): JSX.Element => {
	const { ref, inView } = useInView()
	const [search, setSearch] = useState('')
	const [listIdDynamic, setListIdDynamic] = useState<number[]>(
		totalList.dynamic_lists
	)
	const [listTagsParents, setListTagsParents] = useState<DynamicListItem[]>([])

	useEffect(() => {
		if (inView) {
			if (
				data &&
				data.pages[0] &&
				data.pages[0].pages > data.pageParams.length
			) {
				fetchNextPage()
			}
		}
	}, [inView])

	useEffect(() => {
		if (data?.pages) {
			let aux = [] as DynamicListItem[]
			data.pages.forEach(page => {
				if (page) {
					aux = [...aux, ...page.items]
				}
				setListTagsParents(aux)
			})
		}
	}, [data])

	useEffect(() => {
		setTotalList({
			...totalList,
			dynamic_lists: listIdDynamic
		})
	}, [listIdDynamic])

	return (
		<div>
			<Input
				name="search"
				placeholder="Search Dynamic Lists"
				className="w-full !p-[unset]"
				onChangeCustom={e => {
					setSearch(e.target.value)
					handleChangeDebounce(e)
				}}
				rightIcon={Icons.cancel}
				rightClick={() => {
					resetInput()
					setSearch('')
				}}
				value={search}
				maxLength={configGenaral.maxSearchValue}
				inputClassName="!p-[unset] !py-1 !px-2"
				inputFocus
				borderFull={false}
				search
			/>
			<div className="mt-2">
				{isLoading ? (
					<div className="flex items-center justify-center w-full h-[400px] py-2 px-1 ">
						<CircularProgress disableShrink />
					</div>
				) : (
					<div className="max-h-[370px] overflow-y-auto">
						{listTagsParents?.map(item => (
							<InputCheck
								list={listIdDynamic}
								title={item.name}
								setList={setListIdDynamic}
								reach={item.members}
								className="mb-4 w-full"
								id={item.id}
								key={item.id}
								name={`${item.name}`}
								type={type}
							/>
						))}
						<div ref={ref}>
							{isFetchingNextPage && <CircularProgress disableShrink />}
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
