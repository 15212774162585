import { Input, Message, Typography, handleValid } from 'components'
import { TypographyTypes } from 'interfaces'
import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import { configGenaral } from 'utils'
import { PropsComponentsMyOrganization } from './interfaceSlide.type'

export const SlideCustomText: React.FC<PropsComponentsMyOrganization> = ({
	active,
	pos,
	errors,
	register,
	setValue,
	watch,
	isValid,
	setFieldError
}): JSX.Element => {
	const [convertedText, setConvertedText] = useState('')

	const rules = {
		title: {
			required: { value: true, message: 'This field is required' }
		}
	}

	const watchTitle = watch(
		`tabs.${active}.components.${pos}.component_value.title`
	)

	const nameType = `simple_text${active}-${pos}`

	useEffect(() => {
		handleValid(nameType, isValid as boolean, setFieldError)
	}, [isValid])

	return (
		<div className="flex flex-col justify-between h-[95%] p-2">
			<div className="flex flex-col w-full">
				<Input
					name={`tabs.${active}.components.${pos}.component_value.title`}
					title="Title"
					register={register}
					rules={rules.title}
					error={
						errors.tabs?.[active]?.components?.[pos]?.component_value?.title
					}
					borderFull
					limit
					cantLimit={configGenaral.maxTitleLength}
					watchValue={watchTitle}
					setValueInput={setValue}
				/>
				<div className="mt-4">
					<div className="mb-3">
						<Typography
							title="Description"
							type={TypographyTypes.span}
							fontLeading="12/12"
						/>
					</div>
					<ReactQuill
						theme="snow"
						value={convertedText}
						onChange={(value: string) => {
							const valueReplace = value.replace(/color:\s?[^;]+;/gi, '')
							setConvertedText(valueReplace)
							setValue(
								`tabs.${active}.components.${pos}.component_value.description`,
								valueReplace
							)
						}}
					/>
					{convertedText.length > configGenaral.maxDescriptionLength && (
						<Message
							text={`Maximum ${configGenaral.maxDescriptionLength} characters exceeded`}
							type="error"
						/>
					)}
				</div>
			</div>
		</div>
	)
}
