import { CircularProgress } from '@mui/material'
import { getTagsApprovalDashboard } from 'api'
import clsx from 'clsx'
import { Box, Button, FollowersTagCell, TitleBox, Typography } from 'components'
import { BoxComponentProps, Icons, TypographyTypes } from 'interfaces'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'

export const NewTagRequests: React.FC<BoxComponentProps> = ({
	styles,
	setPeriod,
	setTagApprovalModal
}): JSX.Element => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const navigate = useNavigate()

	const { data, isLoading, refetch } = useQuery([userId, organizationId], () =>
		getTagsApprovalDashboard(organizationId)
	)

	return (
		<div className={styles}>
			<TitleBox title="New Tag Requests" icon={Icons.sell} className="mb-4" />
			<Box className="flex flex-col items-start w-full h-[274px]">
				<>
					<Typography
						title="Approve or Reject Tag Requests"
						fontLeading="15/15"
						type={TypographyTypes.h3}
						className="text-black-1 mb-1"
					/>
					<Typography
						title="These users have Tags awaiting approval."
						fontLeading="10/12"
						type={TypographyTypes.p}
						className="text-gray-8 !font-normal"
					/>
					<div
						className={clsx(
							'flex flex-col gap-2 w-full h-[calc(100%_-_43px)]',
							isLoading && 'items-center justify-center',
							data && 'mt-4'
						)}
					>
						{isLoading && <CircularProgress disableShrink />}
						{!isLoading && data && data.items.length === 0 && (
							<div className="w-full h-full flex justify-center items-center">
								<Typography
									title="You don't have any tag requests."
									type={TypographyTypes.h3}
									fontLeading="12/15"
									className="text-gray-7"
								/>
							</div>
						)}

						{data &&
							!isLoading &&
							data?.items.length > 0 &&
							data?.items.map(item => {
								return (
									<div className="flex w-full justify-between items-center">
										<Typography
											className="text-black-1"
											title={item.name}
											fontLeading="12/15"
											type={TypographyTypes.p}
										/>
										<FollowersTagCell
											followerTags={item.followers_tag}
											button
											refetch={refetch}
										/>
									</div>
								)
							})}
					</div>
					<Button
						label="SEE ALL REQUESTS"
						iconRight={Icons.navigateNext}
						color="Gray5"
						className="!min-w-[unset] !py-0 !px-0 !border-none"
						rightIconDivClassName="!w-5 !h-5"
						buttonTextClassName="!font-bold"
						onClick={() => {
							navigate('/people')
							if (setPeriod) {
								setPeriod('My Tags')
							}
							if (setTagApprovalModal) {
								setTagApprovalModal(true)
							}
						}}
					/>
				</>
			</Box>
		</div>
	)
}
