import { Avatar, Icon } from 'components'
import { Icons, PreviewOrganizationStepComponentProps } from 'interfaces'
import { changeTextColor } from 'lib'
import React from 'react'

export const PreviewOrganization: React.FC<
	PreviewOrganizationStepComponentProps
> = ({ accentColor, backgroundColor, supportColors, iconUrl }) => {
	return (
		// navigation preview
		<div
			style={{ background: backgroundColor }}
			className="h-full rounded-lg border font-Inter"
		>
			<div
				className="flex items-center justify-between p-3 w-full rounded-tl-lg rounded-tr-lg "
				style={{ backgroundColor: accentColor }}
			>
				<div className="flex items-center w-full">
					<Avatar src={iconUrl} className="h-8 w-8 rounded-full mr-2" />
					<span
						className="ml-0.5 text-sm"
						style={{ color: changeTextColor(accentColor) }}
					>
						Organization
					</span>
				</div>

				<div className="flex w-[10%]">
					<Icon
						className="py-[2px] max-h-[20px]"
						src={Icons.notificationsWhite}
					/>
				</div>
			</div>

			<div className="flex justify-center items-center mt-2 relative">
				<div className="w-11/12">
					<img
						className="rounded-2xl overflow-hidden w-full h-28"
						src="https://img.freepik.com/fotos-premium/fondo-borroso-negro_46073-407.jpg"
						alt="Imagen redonda"
					/>
					<button
						className="absolute bottom-4 left-6  px-4 rounded-2xl"
						style={{
							background: supportColors,
							color: changeTextColor(supportColors),
							paddingTop: '4px',
							paddingBottom: '4px'
						}}
						disabled
						type="button"
					>
						Oficial Web Site
					</button>
				</div>
			</div>
			{/* Tabs preview */}
			<div
				className="flex items-center justify-center  lg:w-[95%] lg:pt-[1%] mx-auto rounded-lg mt-2 "
				style={{
					background: supportColors
				}}
			>
				<div
					className="flex items-center text-xs "
					style={{
						borderBottom: `3px solid ${accentColor}`,
						borderRadius: '10px 0px 0px 0'
					}}
				>
					<div
						className="w-3 h-3 rounded-full "
						style={{ background: accentColor }}
					/>
					<span
						className="ml-2 mr-2 text-sm"
						style={{
							color: changeTextColor(supportColors)
						}}
					>
						All
					</span>
				</div>
				<div className="flex items-center ml-1.5">
					<Icon className="max-h-[20px] w-3.5 h-3.5" src={Icons.rectangle} />
					<span
						className="ml-2 text-sm mr-2"
						style={{
							color: changeTextColor(supportColors)
						}}
					>
						Info
					</span>
				</div>
				<div className="flex  items-center">
					<Icon className="max-h-[20px] w-3.5 h-3.5" src={Icons.rectangle} />
					<span
						className="ml-2 text-sm mr-2"
						style={{
							color: changeTextColor(supportColors)
						}}
					>
						Event
					</span>
				</div>
				<div className="flex items-center ">
					<Icon className="h-3.5 w-3.5 max-h-[20px]" src={Icons.rectangle} />
					<span
						className="ml-1"
						style={{
							color: changeTextColor(supportColors),
							fontSize: '0.9rem'
						}}
					>
						Take action
					</span>
				</div>
			</div>
			{/* Post Tittle preview */}
			<div className="flex items-center justify-between w-full px-6 py-2">
				<div className="flex items-center">
					<img
						className="h-8 w-8 rounded-full mr-2"
						src="https://via.placeholder.com/50"
						alt="Imagen"
					/>
					<div>
						<div
							className="text-sm font-medium text-gray-900"
							style={{
								color: changeTextColor(backgroundColor)
							}}
						>
							Post title
						</div>
						<div
							className="text-sm text-gray-500"
							style={{
								color: changeTextColor(backgroundColor)
							}}
						>
							Organization
						</div>
					</div>
				</div>
				<div
					className="text-xs text-gray-500 mt-6"
					style={{
						color: changeTextColor(backgroundColor)
					}}
				>
					13 hours ago
				</div>
			</div>

			<div className="flex justify-center items-center mt-2 relative">
				<div className="w-11/12">
					<img
						className="rounded-xl overflow-hidden w-full "
						src="https://img.freepik.com/fotos-premium/fondo-borroso-negro_46073-407.jpg"
						alt="Imagen redonda"
					/>
					<div className="flex justify-between items-center">
						<div className="flex items-center justify-center absolute bottom-4 left-3.5">
							<Icon
								className="ml-2.5 h-4 w-4 max-h-[20px]"
								src={Icons.WhiteShared}
							/>
						</div>
						<div
							className="flex items-center justify-center mt-2 absolute bottom-4 lg:left-[57%]  px-4 rounded-md "
							style={{
								paddingTop: '2.5%',
								paddingBottom: '2.5%',
								background: supportColors
							}}
						>
							<div className="flex items-center text-xs relative ">
								<Icon
									className="mr-1 max-h-[20px] w-4 h-4"
									src={Icons.rectangle}
								/>
							</div>
							<div className="flex items-center relative">
								<Icon
									className="ml-2 mr-1 max-h-[20px] w-4 h-4"
									src={Icons.rectangle}
								/>
								<div className="border-l-2 border-black h-full absolute transform -translate-x-1/2" />
							</div>
							<div className="flex  items-center relative ">
								<Icon
									className="ml-2 max-h-[20px] w-4 h-4"
									src={Icons.rectangle}
								/>
								<div className="border-l-2 border-black h-full absolute transform -translate-x-1/2" />
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* text view preview */}

			<div className="w-11/12 text-sm  ml-4 ">
				<p style={{ color: changeTextColor(backgroundColor) }}>
					It was popularised in the 19960 whith the realease of letraset....
				</p>
				<p style={{ color: changeTextColor(backgroundColor) }}>View more</p>
			</div>
			{/* final boton previwe */}
			<div className="flex justify-center mt-6 mb-6 w-full md:ml-4 ml-7">
				<div className="">
					<button
						className="md:h-5 md:w-5 lg:w-[54px] lg:h-[54px] rounded-2xl text-white flex justify-center items-center"
						style={{ background: accentColor }}
						type="button"
						disabled
					>
						<Icon className="h-[40%] w-[40%]" src={Icons.closeGenericWhite} />
					</button>
				</div>

				<div className="flex w-11/12 h-full ">
					<div
						className="flex items-center   ml-[2.5%] lg:w-[82%] pt-[3.6%] md:w-full md:ml-4  rounded-2xl pl-2"
						style={{
							background: supportColors
						}}
					>
						<div
							className="flex flex-col items-center text-xs mx-2 w-[50%]"
							style={{
								borderBottom: `3px solid ${accentColor}`,
								borderRadius: '10px 0px 0px 0px'
							}}
						>
							<div
								className="w-3.5 h-3.5 rounded-full "
								style={{ background: accentColor }}
							/>
							<span
								className="ml-2 mr-2 text-sm mt-1"
								style={{
									color: changeTextColor(supportColors)
								}}
							>
								Saves
							</span>
						</div>

						<div className="flex flex-col items-center text-xs mx-2 w-[50%]">
							<div
								className="w-3.5 h-3.5 rounded-full "
								style={{ background: '#fff' }}
							/>
							<span
								className="ml-2 mr-2 text-sm mt-1"
								style={{
									color: changeTextColor(supportColors)
								}}
							>
								Search
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
