import { CircularProgress } from '@mui/material'
import {
	GET_FM_PARTNER_LIST_FOLLOWERS_FOLLOWERS,
	getFMPartnerListFollowers
} from 'api'
import { BoxPartners, Button } from 'components'
import { FMPartnerListFollowersItem, ShareFMPartnerProps } from 'interfaces'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'

export const ShareFMPartner: React.FC<ShareFMPartnerProps> = ({
	totalData,
	onClickClose,
	setTotalData,
	setSlideAudience,
	setShareFMPartner
}): JSX.Element => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')

	const [FMPartnersSelected, setFMPartnersSelected] = useState<number[]>([])

	const { data, isLoading } = useQuery(
		[GET_FM_PARTNER_LIST_FOLLOWERS_FOLLOWERS, userId, organizationId],
		() => getFMPartnerListFollowers(organizationId, 1)
	)

	const handleClickPartner = (partner: FMPartnerListFollowersItem): void => {
		let newFMPartners = FMPartnersSelected
		if (FMPartnersSelected.includes(partner.id)) {
			newFMPartners = FMPartnersSelected.filter(item => item !== partner.id)
		} else {
			newFMPartners.push(partner.id)
		}
		setFMPartnersSelected([...newFMPartners])
	}

	const onSubmit = (): void => {
		setTotalData({
			...totalData,
			delivery_enhancements: {
				...totalData.delivery_enhancements,
				audience: {
					...totalData.delivery_enhancements.audience,
					partner_lists: FMPartnersSelected
				}
			}
		})
		setSlideAudience(true)
		setShareFMPartner(false)
	}

	useEffect(() => {
		const aux = [] as number[]
		totalData.delivery_enhancements.audience.partner_lists.forEach(item => {
			aux.push(item)
		})
		setFMPartnersSelected(aux)
	}, [totalData])

	return (
		<div className="flex flex-col justify-between h-full">
			<div className="mt-[46px]">
				{isLoading ? (
					<div className="flex items-center justify-center w-full h-[400px] py-2 px-1 ">
						<CircularProgress disableShrink />
					</div>
				) : (
					data?.items?.map(item => (
						<BoxPartners
							key={item.id}
							id={`${item.id}`}
							title={item.name}
							itemsSeleted={FMPartnersSelected}
							onClick={() => handleClickPartner(item)}
						/>
					))
				)}
			</div>

			<div className="flex items-center justify-between">
				<Button
					type="button"
					label="Cancel"
					color="Transparent"
					className="!min-w-[130px]"
					onClick={onClickClose}
				/>
				<Button
					label="Save"
					type="button"
					color="Primary"
					className="!min-w-[130px]"
					onClick={onSubmit}
				/>
			</div>
		</div>
	)
}
