import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { CircularProgress } from '@mui/material'
import { putResetPassword } from 'api'
import { Button, Input, InputPassword } from 'components'
import { useToast } from 'hooks'
import { ResetPasswordFormInputs } from 'interfaces'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Cookies from 'universal-cookie'

export const ResetPasswordForm = (): JSX.Element => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const [loading, setLoading] = useState(false)
	const [matchPassword, setMatchPassword] = useState(false)
	const { showToast } = useToast()
	const {
		register,
		watch,
		handleSubmit,
		setValue,
		formState: { errors, isValid }
	} = useForm<ResetPasswordFormInputs>({ mode: 'all' })

	const newPassword = watch('newPassword')
	const confirmNewPassword = watch('confirmNewPassword')

	useEffect(() => {
		if (newPassword !== confirmNewPassword) {
			setMatchPassword(true)
		} else {
			setMatchPassword(false)
		}
	}, [newPassword, confirmNewPassword])

	const onSubmit: SubmitHandler<ResetPasswordFormInputs> = async data => {
		setLoading(true)
		putResetPassword(userId, {
			current_pass: data.currentPassword,
			new_pass: data.newPassword
		}).then(response => {
			if (response) {
				setLoading(false)
				if (response.message) {
					if (response.detail) {
						showToast(response.detail, '', 'error')
					} else {
						showToast(response.message, '', 'success')
					}
				}
				setValue('confirmNewPassword', '')
				setValue('currentPassword', '')
				setValue('newPassword', '')
			}
		})
	}

	const rules = {
		currentPassword: {
			required: { value: true, message: 'This field is required' }
		},
		newPassword: {
			required: { value: true, message: 'This field is required' }
		},
		confirmNewPassword: {
			required: { value: true, message: 'This field is required' }
		}
	}
	return (
		<>
			{loading && (
				<div className="flex items-center justify-center w-full h-[400px] py-2 px-1 ">
					<CircularProgress disableShrink />
				</div>
			)}
			{!loading && (
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="pt-5 h-[95%] flex flex-col justify-between"
				>
					<div>
						<Input
							name="currentPassword"
							title="Confirm Current Password"
							register={register}
							rules={rules.currentPassword}
							placeholder="Type your Password"
							error={errors.currentPassword}
							className="mb-4"
						/>
						<InputPassword
							name="newPassword"
							title="Set New Password"
							register={register}
							rules={rules.newPassword}
							placeholder="Type a New Password"
							error={errors.newPassword}
							className="mb-4"
						/>
						<InputPassword
							name="confirmNewPassword"
							title="Confirm New Password "
							register={register}
							rules={rules.confirmNewPassword}
							placeholder="Type again the New Password"
							error={errors.confirmNewPassword}
							className="mb-4"
						/>
						{matchPassword && (
							<div className="flex items-center mt-2 text-red-500">
								<div className="mr-1 w-4 h-4">
									<ExclamationCircleIcon
										className="w-4 h-4 text-red-500"
										aria-hidden="true"
									/>
								</div>
								<h1 className="text-xs font-Inter font-semibold">{`Passwords don't match`}</h1>
							</div>
						)}
					</div>

					<div className="flex justify-center md:justify-end pt-4">
						<Button
							label="Confirm"
							type="submit"
							color="Primary"
							disabled={!isValid || loading || matchPassword}
						/>
					</div>
				</form>
			)}
		</>
	)
}
