import clsx from 'clsx'
import { Table } from 'components'
import {
	NewOutboundPartnerItem,
	NewOutboundPartnerResponse,
	ParentTableProps
} from 'interfaces'
import React, { useCallback, useMemo } from 'react'
import { Column, Row } from 'react-table'

export const NewPartnerRequestTable: React.FC<
	ParentTableProps<NewOutboundPartnerResponse>
> = ({
	data,
	page,
	setPage,
	className,
	isLoading,
	setDataSelected,
	dataSelected
}) => {
	const columns = useMemo(
		(): Column<NewOutboundPartnerItem>[] => [
			{ Header: 'Partner', accessor: 'name' },
			{ Header: 'Followers', accessor: 'followers' }
		],
		[]
	)

	const handleSelectRow = useCallback<
		(rows: Row<NewOutboundPartnerItem>[], selected: boolean) => void
	>((rows, selected) => {
		const ids = rows.map(row => {
			const partner = row.original as NewOutboundPartnerItem
			return partner.id
		})
		if (selected) {
			setDataSelected?.(prev => [...prev, ...ids])
		} else {
			setDataSelected?.(prev =>
				prev.filter(partnerId => !ids.includes(partnerId))
			)
		}
	}, [])

	return (
		<div className={clsx(className, 'flex flex-col w-full')}>
			<Table<NewOutboundPartnerItem>
				data={data?.items ?? []}
				page={page}
				columns={columns}
				isFetching={isLoading}
				selectionCustomWidth={5}
				customWidth={[50, 25]}
				justify="left"
				showPagination
				pages={data?.size}
				total={data?.total}
				onPageChange={setPage}
				onRowSelect={handleSelectRow}
				rowSelected={row => dataSelected?.includes(row.original.id) ?? false}
			/>
		</div>
	)
}
