export type TypeComponentsItemsArray =
	| 'link'
	| 'mail'
	| 'simple_text'
	| 'location'
	| 'tags'
	| 'phone'
	| 'title'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const optionsComponents: Record<TypeComponentsItemsArray, any> = {
	link: {
		component_order: 0,
		component_type: 'link',
		component_value: {
			links: [
				{
					text_link: '',
					url: ''
				}
			]
		}
	},
	mail: {
		component_order: 0,
		component_type: 'mail',
		component_value: {
			mails: [
				{
					title: '',
					email: ''
				}
			]
		}
	},
	simple_text: {
		component_order: 0,
		component_type: 'simple_text',
		component_value: {
			title: '',
			description: ''
		}
	},
	location: {
		component_order: 0,
		component_type: 'location',
		component_value: {
			title: '',
			address_line: '',
			zip_code: ''
		}
	},
	tags: {
		component_order: 0,
		component_type: 'tags',
		component_value: {
			tags: ['']
		}
	},
	phone: {
		component_order: 0,
		component_type: 'phone',
		component_value: {
			phones: [
				{
					title: '',
					phone_number: '',
					code: ''
				}
			]
		}
	},
	title: {
		component_order: 0,
		component_type: 'title',
		component_value: {
			title: ''
		}
	}
}
