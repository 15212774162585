import { Icon, Typography } from 'components'
import { BoxContentDeliveryProps, Icons, TypographyTypes } from 'interfaces'
import React from 'react'

export const BoxContentDelivery: React.FC<BoxContentDeliveryProps> = ({
	title,
	onClick
}): JSX.Element => {
	return (
		<button
			type="button"
			onClick={onClick}
			className="flex items-center justify-between w-full border-b border-gray-11 py-[11px]"
		>
			<Typography
				title={title}
				className="text-gray-9"
				type={TypographyTypes.span}
				fontLeading="15/18"
			/>
			<Icon src={Icons.add} className="w-[14px] h-[14px]" />
		</button>
	)
}
