import { API } from 'api/config'
import {
	EditMyFeedsRequest,
	EditPartnerListRequest,
	NetworkPartnerPatchResponse,
	NetworkStatus
} from 'interfaces'

export const patchNetworkPartner = async (
	organizationId: number,
	data: {
		organization_partner_ids: number[]
		status: NetworkStatus
	}
): Promise<NetworkPartnerPatchResponse | null> => {
	let res = null as NetworkPartnerPatchResponse | null
	await API()
		.patch(`/organization_partner/${organizationId}/bulk/`, data)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const patchNetworkSubscription = async (
	organizationId: number,
	feedId: number,
	data: {
		status: NetworkStatus
	}
): Promise<NetworkPartnerPatchResponse | null> => {
	let res = null as NetworkPartnerPatchResponse | null
	await API()
		.patch(`/organization/${organizationId}/follower_feed/${feedId}`, data)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const patchEditMyFeed = async (
	organizationId: number,
	feedId: number,
	body: EditMyFeedsRequest
): Promise<NetworkPartnerPatchResponse | null> => {
	let res = null as NetworkPartnerPatchResponse | null
	await API()
		.patch(`/organization/${organizationId}/feeds/${feedId}`, body)
		.then(async response => {
			res = response.data
		})
		.catch(error => {
			res = { detail: error.response.data.detail, message: '' }
		})
	return res
}

export const patchEditPartneList = async (
	organizationId: number,
	idList: number,
	body: EditPartnerListRequest
): Promise<NetworkPartnerPatchResponse | null> => {
	let res = null as NetworkPartnerPatchResponse | null
	await API()
		.patch(`/partner_list/${idList}/organization/${organizationId}`, body)
		.then(async response => {
			res = response.data
		})
		.catch(error => {
			res = { detail: error.response.data.detail, message: '' }
		})
	return res
}
