import { Disclosure } from '@headlessui/react'
import clsx from 'clsx'
import {
	AccordionAudience,
	Button,
	Icon,
	Input,
	Modal,
	NewComponentModal,
	SlideCustomText,
	SlideEmail,
	SlideLink,
	SlidePhones,
	SlideTags,
	SlideTitle,
	Typography,
	handleValid
} from 'components'
import { SlideLocation } from 'components/myOrganization/slideLocation'
import {
	DynamicTapContentProps,
	Icons,
	Reach,
	TypographyTypes
} from 'interfaces'
import { getNameComponentForType } from 'lib'
import React, { useEffect, useState } from 'react'
import { Controller, useFieldArray } from 'react-hook-form'
import { optionsComponents } from './optionsNewComponent'

export interface ropsTotalList {
	tags: number[] | undefined
}

export const DynamicTabContent: React.FC<DynamicTapContentProps> = ({
	data,
	active,
	deletedTab,
	control,
	setValue,
	register,
	watch,
	errors,
	isValid,
	saveData,
	errorForm,
	setErrorForm,
	fieldError,
	setFieldError
}): JSX.Element => {
	const [open, setOpen] = useState(false)
	const [openModalDelete, setOpenModalDelete] = useState(false)
	const [deleteComponent, setDeleteComponent] = useState<number | null>(null)
	const maxTabNumber = 12
	const nameModule = `tabs.${active}.name`
	const [publishMyPeope, setPublishMyPeope] = useState<string>('1')
	const [totalList, setTotalList] = useState<Reach>({
		tags: data?.audience?.tags ?? [],
		lists: [],
		dynamic_lists: [],
		individuals: [],
		organizations: [],
		feeds: [],
		partner_lists: [],
		organization_id: `${data?.organization_id}` ?? 1
	})

	const { fields, append, remove } = useFieldArray({
		control,
		name: `tabs.${active}.components`
	})

	const rules = {
		name: {
			required: { value: true, message: 'This field is required' }
		}
	}

	const validateTags = (): void => {
		if (totalList.tags.length === 0) {
			handleValid(nameModule, false, setFieldError)
		} else {
			handleValid(nameModule, true, setFieldError)
		}
	}

	useEffect(() => {
		setValue(`tabs.${active}.audience.tags`, totalList.tags)
	}, [totalList])

	useEffect(() => {
		if (publishMyPeope === '2') {
			validateTags()
		} else {
			handleValid(nameModule, true, setFieldError)
		}
	}, [publishMyPeope, totalList])

	useEffect(() => {
		if (fields?.length === 0) {
			handleValid(nameModule, false, setFieldError)
		} else {
			handleValid(nameModule, true, setFieldError)
		}
	}, [fields])

	const hasAudience = data && data.audience.tags.length > 0

	useEffect(() => {
		if (hasAudience) {
			setPublishMyPeope('2')
		}
	}, [data])

	return (
		<>
			<div className="md:mt-5 md:flex-row flex flex-col gap-4 pt-4">
				<div className="md:w-4/12 w-full">
					<div
						className="px-4 pb-6 rounded-lg bg-white border-[1px] border-blue-primary"
						style={{ boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.1)' }}
					>
						<div className="w-full py-3  flex justify-between items-center">
							<Typography
								title={`Tab ${active + 1}`}
								fontLeading="22/27"
								type={TypographyTypes.h2}
								className="text-gray-16"
							/>
						</div>
						<div className="flex items-center">
							<Input
								name={`tabs.${active}.name`}
								min={1}
								register={register}
								rules={rules.name}
								error={errors?.tabs?.[active]?.name}
								borderFull={false}
								inputClassName="rounded-lg border-[1px] border-gray-17"
								limit
								cantLimit={maxTabNumber}
								setValueInput={setValue}
								autoFocus
								watchValue={watch(`tabs.${active}.name`)}
							/>
							<div
								aria-hidden="true"
								className="cursor-pointer"
								onClick={() => deletedTab(active)}
							>
								<Icon
									src={Icons.delete}
									className="w-7  ml-3 text-gray-1 "
									fillPath
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="md:w-8/12 w-full">
					<div
						className="w-full px-4 pb-6 rounded-lg bg-white"
						style={{ boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.1)' }}
					>
						<div className="w-full py-3  mb-3 flex justify-between items-center">
							<Typography
								title="Define visivility:"
								fontLeading="12/12"
								type={TypographyTypes.p}
								className="text-gray-8"
							/>
						</div>

						<AccordionAudience
							active={active}
							publishMyPeope={publishMyPeope}
							setPublishMyPeope={setPublishMyPeope}
							totalList={totalList}
							setTotalList={setTotalList}
							defaultOpen={hasAudience}
							defaultOpenParent
						/>

						<div className="w-full py-3  mb-3 flex justify-between items-center">
							<Typography
								title="Tab components:"
								fontLeading="12/12"
								type={TypographyTypes.p}
								className="text-gray-8"
							/>
						</div>

						<div className="w-full grid grid-cols-1  max-h-[calc(100vh-360px)]  overflow-y-auto mr-2 ">
							{active > -1 &&
								fields.map((component, index) => {
									return (
										<Controller
											control={control}
											name={`tabs.${active}.components.${index}`}
											key={`components-${component.id}`}
											render={({ field }) => {
												const type = field.value.component_type
												const errorAccordion =
													fieldError &&
													fieldError.length > 0 &&
													fieldError.includes(`${type}${active}-${index}`)
												const isLastComponentOpen =
													fields?.length === 1 || fields?.length === index + 1
												return (
													<div className="mr-2">
														<Disclosure
															key={`component-tab-${field.value.id}`}
															defaultOpen={isLastComponentOpen}
														>
															{({ open: openDisclosure }) => (
																<>
																	<Disclosure.Button className="w-full flex justify-between items-center rounded-[8px] shadow-md cursor-pointer mt-4 mb-1">
																		<div className="w-full flex justify-between items-center p-2 ">
																			<div className="flex items-center ">
																				<Typography
																					title={`${getNameComponentForType(
																						field.value.component_type
																					)}`}
																					className="text-gray-9"
																					fontLeading="15/18"
																					type={TypographyTypes.span}
																				/>
																				{errorAccordion && !openDisclosure && (
																					<div className="mr-1 w-4 h-4">
																						<Icon
																							src={Icons.error}
																							className="w-7  ml-3 text-red-500"
																							fillPath
																						/>
																					</div>
																				)}
																			</div>
																			<div className="flex items-center">
																				{openDisclosure && (
																					<div
																						aria-hidden="true"
																						className="z-10 cursor-pointer"
																						onClick={() => {
																							setDeleteComponent(index)
																							setOpenModalDelete(true)
																						}}
																					>
																						<Icon
																							src={Icons.delete}
																							className="w-7  ml-3 text-gray-1 "
																							fillPath
																						/>
																					</div>
																				)}
																				<Icon
																					fillPath
																					src={Icons.expandMore}
																					className={clsx(
																						openDisclosure
																							? 'rotate-180 transform'
																							: '',
																						'w-7 text-blue-primary ml-3'
																					)}
																				/>
																			</div>
																		</div>
																	</Disclosure.Button>
																	<Disclosure.Panel className="text-gray-500 mb-4 p-4 border-x-[0.5px] border-b-[0.5px] border-gray-7 rounded-b-[16px]">
																		<div>
																			{type === 'title' && (
																				<SlideTitle
																					active={active}
																					pos={index}
																					setValue={setValue}
																					register={register}
																					watch={watch}
																					errors={errors}
																					isValid={isValid}
																					setFieldError={setFieldError}
																				/>
																			)}
																			{type === 'simple_text' && (
																				<SlideCustomText
																					active={active}
																					pos={index}
																					setValue={setValue}
																					register={register}
																					watch={watch}
																					errors={errors}
																					isValid={isValid}
																					setFieldError={setFieldError}
																				/>
																			)}
																			{type === 'location' && (
																				<SlideLocation
																					active={active}
																					pos={index}
																					setValue={setValue}
																					register={register}
																					watch={watch}
																					errors={errors}
																					isValid={isValid}
																					setFieldError={setFieldError}
																				/>
																			)}
																			{type === 'tags' && (
																				<SlideTags
																					active={active}
																					watch={watch}
																					pos={index}
																					setErrorForm={setErrorForm}
																					setValue={setValue}
																					register={register}
																					errors={errors}
																					setFieldError={setFieldError}
																				/>
																			)}

																			{type === 'link' && (
																				<SlideLink
																					active={active}
																					watch={watch}
																					pos={index}
																					setErrorForm={setErrorForm}
																					setValue={setValue}
																					register={register}
																					errors={errors}
																					setFieldError={setFieldError}
																				/>
																			)}

																			{type === 'phone' && (
																				<SlidePhones
																					active={active}
																					watch={watch}
																					pos={index}
																					setErrorForm={setErrorForm}
																					setValue={setValue}
																					register={register}
																					errors={errors}
																					setFieldError={setFieldError}
																				/>
																			)}

																			{type === 'mail' && (
																				<SlideEmail
																					active={active}
																					watch={watch}
																					pos={index}
																					setErrorForm={setErrorForm}
																					setValue={setValue}
																					register={register}
																					errors={errors}
																					setFieldError={setFieldError}
																				/>
																			)}
																			{type === '' && <div>...</div>}
																		</div>
																	</Disclosure.Panel>
																</>
															)}
														</Disclosure>
													</div>
												)
											}}
										/>
									)
								})}
						</div>
						{!fields.length && (
							<div className="min-w-[100px] flex justify-center items-center py-3">
								<Typography
									title="Create new content by adding a new component to your tab"
									className="text-gray-3"
									fontLeading="15/22"
									type={TypographyTypes.p}
								/>
							</div>
						)}
						<div className="md:mt-1 md:flex-row mt-2 w-full flex md:justify-between items-center flex-col gap-2">
							<Button
								label="New Component"
								onClick={() => setOpen(true)}
								className="w-full md:w-auto my-2"
								color="white"
							/>
							<Button
								label="Save"
								onClick={() => {
									setValue(
										`tabs.${active}.audience.tags`,
										publishMyPeope === '2' ? totalList.tags : []
									)
									saveData()
								}}
								disabled={errorForm || !isValid || fieldError?.length > 0}
								className="w-full md:w-auto"
							/>
						</div>
					</div>
				</div>
			</div>

			<Modal setOpen={setOpen} open={open} type="normal">
				<NewComponentModal
					setOpenModal={setOpen}
					createNewComponent={typeComponent =>
						append(optionsComponents[typeComponent])
					}
				/>
			</Modal>

			<Modal
				setOpen={state => {
					setOpenModalDelete(state)
					setDeleteComponent(null)
				}}
				open={openModalDelete}
				type="warning"
				handleClick={() => {
					if (deleteComponent !== null) {
						remove(deleteComponent)
						setOpenModalDelete(false)
						setDeleteComponent(null)
						saveData()
					}
				}}
			>
				<Typography
					title="Changes have been made. 
					Are you sure you want to delete this component?"
					className="text-gray-9"
					fontLeading="15/18"
					type={TypographyTypes.p}
				/>
			</Modal>
		</>
	)
}
