import { FmPartnerListActionCell, Table } from 'components'
import {
	FMPartnerListFollowersItem,
	FMPartnerListFollowersResponse,
	ParentTableProps
} from 'interfaces'
import React, { useMemo } from 'react'
import { Column } from 'react-table'

export const FmPartnerListTableFollowers: React.FC<
	ParentTableProps<FMPartnerListFollowersResponse> & {
		onDelete: (id: number) => void
		onEdit: (id: number) => void
	}
> = ({ data, page, setPage, className, isLoading, onDelete, onEdit }) => {
	const renderCell = (value: number): JSX.Element => {
		return (
			<FmPartnerListActionCell
				value={value}
				deleteHandler={onDelete}
				deleteEdit={onEdit}
			/>
		)
	}
	const columns = useMemo(
		(): Column<FMPartnerListFollowersItem>[] => [
			{ Header: 'Saved Lists', accessor: 'name', disableSortBy: true },
			{ Header: 'Followers', accessor: 'followers', disableSortBy: true },
			{
				Header: 'Action',
				accessor: 'id',
				disableSortBy: true,
				Cell: ({ value }) => renderCell(value)
			}
		],
		[]
	)

	return (
		<div className={className}>
			<Table
				data={data?.items ?? []}
				page={page}
				isFetching={isLoading}
				noDataMessage="No Result Found"
				columns={columns as Column[]}
				customWidth={[20, 20, 15]}
				bodyTableClassName="!h-[calc(100vh_-_598px)] overflow-y-auto"
				justify="left"
				onPageChange={setPage}
				customWidthOverride
			/>
		</div>
	)
}
