import { API } from 'api/config'
import {
	MyOrganizationResponse,
	OrganizationsByUserResponse,
	UpdateImageResponse
} from 'interfaces'

export const getMyOrganization = async (
	organizationId: number
): Promise<MyOrganizationResponse | null> => {
	let res = null as MyOrganizationResponse | null
	await API()
		.get(`/organizations/admin/${organizationId}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getUploadImageCover = async (
	organizationName: string,
	typeImg: string
): Promise<UpdateImageResponse | null> => {
	let res = null as UpdateImageResponse | null
	await API()
		.get(
			`/upload/profile_image?file_type=${
				typeImg || 'png'
			}&slug=${organizationName}`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)

	return res
}

export const getUploadImageIcon = async (
	organizationId: number,
	typeImg: string
): Promise<UpdateImageResponse | null> => {
	let res = null as UpdateImageResponse | null
	await API()
		.get(
			`/upload/icon_image?file_type=${typeImg || 'png'}&slug=${organizationId}`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)

	return res
}

export const getUploadImageBanner = async (
	organizationName: string,
	typeImg: string
): Promise<UpdateImageResponse | null> => {
	let res = null as UpdateImageResponse | null
	await API()
		.get(
			`/upload/banners?file_type=${typeImg || 'png'}&slug=${organizationName}`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)

	return res
}

export const getOrganizationsByUser = async (
	userEmail: string,
	token: string,
	inputSearch?: string
): Promise<OrganizationsByUserResponse | null> => {
	let res = null as OrganizationsByUserResponse | null
	await API({ customAuthorization: token })
		.get(
			`/user/organizations/permissions?community_id=${
				process.env.REACT_APP_COMMUNITY_ID
			}&email=${userEmail}&search=${inputSearch ?? ''}`
		)
		.then(response => {
			res = response.data
		})
		.catch(error => {
			console.warn(error)
		})
	return res
}
