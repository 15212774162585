import { Switch } from '@headlessui/react'
import clsx from 'clsx'
import { SwitchCustomProps } from 'interfaces'
import React from 'react'

export const SwitchCustom: React.FC<SwitchCustomProps> = ({
	enabled,
	setEnabled
}): JSX.Element => {
	return (
		<Switch
			checked={enabled}
			onChange={setEnabled}
			className={clsx(
				enabled ? 'bg-blue-primary' : 'bg-gray-9',
				'relative inline-flex h-[16px] w-[32px] shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out',
				'focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75'
			)}
		>
			<span className="sr-only">Use setting</span>
			<span
				aria-hidden="true"
				className={clsx(
					enabled ? 'translate-x-4' : 'translate-x-0',
					'pointer-events-none inline-block h-[15px] w-[15px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out'
				)}
			/>
		</Switch>
	)
}
