import { Button, Input, ToggleableSecretText, Typography } from 'components'
import {
	BranchKeysFormProps,
	BranchKeysFormValues,
	TypographyTypes
} from 'interfaces'
import React from 'react'
import { useForm } from 'react-hook-form'
import { encryptData } from 'utils'

export const BranchKeysForm = (props: BranchKeysFormProps): JSX.Element => {
	const { onClickSave, hideKeys, appBuild } = props
	const decryptBranchKey = encryptData(appBuild?.branch_key ?? '', 'decrypt')
	const decryptSecretKey = encryptData(
		appBuild?.branch_secret_key ?? '',
		'decrypt'
	)
	const hasKeyData = decryptBranchKey && decryptSecretKey
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors }
	} = useForm<BranchKeysFormValues>({
		defaultValues: {
			branchKey: decryptBranchKey ?? '',
			branchSecret: decryptSecretKey ?? ''
		}
	})
	const hasKeys = watch('branchKey') && watch('branchSecret')

	return (
		<div>
			<Typography
				title="Use keys"
				type={TypographyTypes.h3}
				fontLeading="18/21"
				className="text-gray-9 mb-4"
			/>
			{hideKeys || appBuild?.branch_url || hasKeyData ? (
				<div className="flex flex-col gap-4 mb-3">
					<ToggleableSecretText value={watch('branchKey')} />
					<ToggleableSecretText value={watch('branchSecret')} />
				</div>
			) : (
				<div className="flex flex-col gap-4">
					<Input
						name="branchKey"
						title={hideKeys ? '' : 'Branch key'}
						placeholder="Use example value : key_live_xxxxx"
						register={register}
						error={errors.branchKey}
						titleClassName="!mb-2"
						autoComplete="off"
					/>
					<Input
						name="branchSecret"
						title="Branch Secret"
						placeholder="Use example value : secret_live_xxxxx"
						register={register}
						error={errors.branchSecret}
						titleClassName="!mb-2"
						autoComplete="off"
					/>
					<div className="flex justify-end">
						<Button
							label="Save"
							className="!min-w-[unset] !w-fit !py-1.5 bg-blue-primary/90 hover:bg-blue-primary px-6 disabled:bg-gray-3"
							color="Primary"
							disabled={!hasKeys}
							onClick={handleSubmit(onClickSave)}
						/>
					</div>
				</div>
			)}
		</div>
	)
}
