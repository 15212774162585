export const justifyValues: {
	[key: string]: 'justify-center' | 'justify-left' | 'justify-right'
} = {
	center: 'justify-center',
	left: 'justify-left',
	right: 'justify-right'
}

export const justifyTextValues: {
	[key: string]: 'text-center' | 'text-left' | 'text-right'
} = {
	center: 'text-center',
	left: 'text-left',
	right: 'text-right'
}

interface CustomWidthArgs {
	// Index of customWidth
	index: number
	// Custom width of each column
	customWidth?: number[]
	// Total of elements
	total: number
}

/**
 * Calculates the width of an element in a flexible layout
 */
export const getCustomWidth = ({
	index,
	total,
	customWidth
}: CustomWidthArgs): React.CSSProperties | undefined => {
	return customWidth
		? { flexBasis: `${customWidth[index]}%` }
		: { flexBasis: `${100 / total}%` }
}
