import { Table } from 'components/table'
import { AdminTeamType, ChildrenTableProps, UserRole } from 'interfaces'
import React from 'react'
import AdminTeamCell from './AdminTeamCell'

export const AdminTeamTable: React.FC<
	ChildrenTableProps<AdminTeamType[]> & {
		roles: UserRole[]
		onDelete: (id: number) => void
		onEdit: (id: number) => void
	}
> = props => {
	const { data, roles, onDelete, onEdit } = props

	const renderAdminCell = (value: number): JSX.Element => (
		<AdminTeamCell
			onDelete={() => onDelete?.(value)}
			onEdit={() => onEdit?.(value)}
		/>
	)

	return (
		<Table
			{...props}
			data={
				!data
					? []
					: data.map(admin => ({
							...admin,
							roles: admin.roles
								.map(roleId => roles.find(role => role.id === roleId)?.name)
								.join(', ')
					  }))
			}
			noDataMessage=" "
			columns={[
				{
					Header: '',
					accessor: 'id',
					disableSortBy: true,
					// eslint-disable-next-line react/no-unstable-nested-components
					Cell: ({ value }: { value: number }) => renderAdminCell(value)
				},
				{ Header: 'Admin Name', accessor: 'fullName' },
				{ Header: 'Email', accessor: 'email' },
				{ Header: 'Roles', accessor: 'roles', disableSortBy: true }
			]}
			justify="left"
			customWidth={[5, 20, 30, 45]}
		/>
	)
}
