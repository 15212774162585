import clsx from 'clsx'
import { Box, Button, Icon } from 'components'
import { BoxComponentProps, Icons } from 'interfaces'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const CreateContentItem: React.FC<BoxComponentProps> = ({
	styles
}): JSX.Element => {
	const navigate = useNavigate()
	return (
		<div className={clsx(styles)}>
			<Box className="w-full h-[274px]">
				<>
					<div className="flex items-center justify-between mb-4">
						<h2 className="text-[15px] leading-[15px]  font-Inter font-[700]">
							Create Content Item
						</h2>
					</div>
					<div className="flex flex-col gap-8">
						<div className="flex items-center gap-2">
							<div className="w-[22px] h-[22px]">
								<Icon
									src={Icons.addToHomeScreen}
									fillPath
									className="text-gray-9 min-h-[24px] min-w-[24px] max-h-[24px] max-w-[24px]"
								/>
							</div>
							<h3 className="text-[12px] leading-[15px]  font-Inter font-[400]">
								{/* Set up and create a new post for your organization */}
								Create and publish new content posts in the app (with/without an
								associated push notification).
							</h3>
						</div>
						<button
							type="button"
							className="flex justify-center items-center gap-3 w-full py-3 px-4 bg-gray-11"
							onClick={() => navigate('/content/create')}
						>
							<p className="text-[12px] leading-[15px] font-Inter font-[400] text-[#929BB2]">
								Browse in your device Pictures, Videos or PDFs.
							</p>
							<Icon
								src={Icons.cloudUploads}
								fillPath
								className="text-lavender w-8 h-8"
							/>
						</button>
						<Button
							label="New Content Item"
							iconLeft={Icons.add}
							linkTo="/content/create"
							className=" !w-[100%]"
						/>
					</div>
				</>
			</Box>
		</div>
	)
}
