import { BuildAppsType } from 'interfaces'
import { createContext, useCallback, useState } from 'react'

const BuildAppsContextDefaultValue: BuildAppsType = {
	images: null,
	setImages: () => null,
	isChangeData: null,
	setIsChangeData: () => null
}
export const BuildAppsContext = createContext<BuildAppsType>(
	BuildAppsContextDefaultValue
)
export const useContextBuildApps = (): BuildAppsType => {
	const [isChangeData, setIsChangeDataValue] = useState(false)
	const [images, setImagesValue] = useState<{
		logo: string
		background: string
	} | null>(BuildAppsContextDefaultValue.images)
	const setImages = useCallback(
		(value: { logo: string; background: string } | null) => {
			setImagesValue(value)
		},
		[setImagesValue]
	)
	const setIsChangeData = useCallback(
		(value: boolean) => {
			setIsChangeDataValue(value)
		},
		[setIsChangeDataValue]
	)
	return {
		images,
		setImages,
		isChangeData,
		setIsChangeData
	}
}
