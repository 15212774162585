import { useEffect, useState } from 'react'

export const useDebouncedSearch = (
	searchValue: string | undefined
): { debouncedSearch: string; resetSearch: () => void } => {
	const [debouncedSearch, setDebouncedSearch] = useState('')

	const resetSearch = (): void => {
		setDebouncedSearch('')
	}
	useEffect(() => {
		if (searchValue === '') {
			setDebouncedSearch('')
		} else {
			const debounceTimer = setTimeout(() => {
				if (searchValue) {
					setDebouncedSearch(searchValue.replace(/^\s+|\s+$/g, ''))
				}
			}, 1000)
			return () => clearTimeout(debounceTimer)
		}
		return undefined
	}, [searchValue])

	return { debouncedSearch, resetSearch }
}
