import { API } from 'api/config'
import { PutResetPasswordData, PutResetPasswordResponse } from 'interfaces'

export const putResetPassword = async (
	userId: number,
	body: PutResetPasswordData
): Promise<PutResetPasswordResponse | null> => {
	let res = null as PutResetPasswordResponse | null
	await API()
		.put(`/user/${userId}/change_password`, body)
		.then(response => {
			res = response.data
		})
		.catch(error => {
			console.warn(error.response.data)
			res = error.response.data
		})
	return res
}
