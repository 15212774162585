/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * TODO:
 * Schedule appointment for review.
 */

import { TypeComponentsItemsArray } from 'components/dynamicTab/optionsNewComponent'
import { ItemRadios } from 'lib'
import { DebouncedFunc } from 'lodash'
import { Moment } from 'moment'
import React, {
	ChangeEvent,
	Dispatch,
	MouseEventHandler,
	SetStateAction
} from 'react'
import {
	Control,
	FieldError,
	FieldErrorsImpl,
	UseFormHandleSubmit,
	UseFormRegister,
	UseFormReset,
	UseFormSetValue,
	UseFormWatch
} from 'react-hook-form'
import {
	FetchNextPageOptions,
	InfiniteData,
	InfiniteQueryObserverResult,
	QueryObserverResult,
	RefetchOptions,
	RefetchQueryFilters
} from 'react-query'
import { Column, Row } from 'react-table'
import {
	AppNamesTableItem,
	BannerMyOrganizationItem,
	ContentListItem,
	ContentListResponse,
	DynamicListResponse,
	FollowerTagItems,
	ItemDataAssentsAll,
	MyOrganizationResponse,
	NetworkPartnerTableResponse,
	NetworkStatus,
	NewTagsResponse,
	OrganizationsByUserData,
	PeopleMyListsItem,
	PeopleMyListsResponse,
	PeopleMyPeopleItem,
	PeopleMyPeopleResponse,
	PeopleMyTagsChildrenItem,
	PeopleMyTagsParentItem,
	PeopleMyTagsParentResponse,
	StaticListResponse,
	TabComponentsMyOrganizationItem,
	TabComponentsMyOrganizationValueItem,
	TabMyOrganizationItem,
	TagItem,
	UserRole,
	UserSigninType
} from './api'
import { TablesType } from './context'
import {
	AdminForm,
	DocumentsInputs,
	OrganizationDataForm,
	OrganizationInformationForm,
	SaveListDinamic,
	TagForm
} from './forms'
import { Icons } from './media'
import {
	BodySharedContent,
	BodySharedContentCopy,
	DeliveryActiveData,
	DeliveryActivePush,
	ItemLocations,
	OrgStatus,
	Reach,
	TotalDataCreateContent,
	UserData,
	locationState
} from './states'

export type RefetchType = <TPageData>(
	options?: RefetchOptions & RefetchQueryFilters<TPageData>
) => Promise<QueryObserverResult<any, unknown>>

export type DropdownValue = string | number | DropdownFullItemProps
export interface MyPeopleProps {
	period: string
	setPeriod: React.Dispatch<React.SetStateAction<string>>
	optionsToRender: { name: string; value: string }[]
	setTagApprovalModal: React.Dispatch<React.SetStateAction<boolean>>
	tagApprovalModal: boolean
}

export interface DashboardProps {
	setPeriod: React.Dispatch<React.SetStateAction<string>>
	setNetworkPeriod: React.Dispatch<React.SetStateAction<string>>
	setTagApprovalModal: React.Dispatch<React.SetStateAction<boolean>>
}

export interface MyNetworkProps {
	period: string
	setPeriod: React.Dispatch<React.SetStateAction<string>>
	optionsToRender: { name: string; value: string }[]
}
export interface MyContentProps {
	setPeriod: React.Dispatch<React.SetStateAction<string>>
	period: string
	optionsToRender: { name: string; value: string }[]
	optionsToRenderTwo: { name: string; value: string }[]
	optionsToRenderContent: { name: string; value: string }[]
	periodMyContent: string
	setPeriodMyconten: React.Dispatch<React.SetStateAction<string>>
	setMessageDashboard: React.Dispatch<React.SetStateAction<string>>
}

export interface PropsModalAproval<props> {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	data: props
	setNumberAproval: React.Dispatch<React.SetStateAction<number>>
	refetchSharedTrue: <TPageData>(
		options?: RefetchOptions & RefetchQueryFilters<TPageData>
	) => Promise<QueryObserverResult<ContentListResponse | undefined, unknown>>
}

export interface PropsModalReview {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	idShared: number | undefined
	onActionsClick: (
		body: BodySharedContent,
		id: number,
		options?: { title: string }
	) => Promise<void>
}
export interface propsPreviewModal {
	data: any
	setAccepte?: React.Dispatch<React.SetStateAction<boolean>>
	accepte?: boolean
	textValue?: string
	setTextValue?: React.Dispatch<React.SetStateAction<string>>
}
export interface ModalPropsDecline {
	onClickConfirm: (body: BodySharedContent, id: number) => Promise<void>
	onClickSubmit?: (body: BodySharedContent) => Promise<void>
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	idSharedConten: number | undefined
	setOpenModalDecline?: React.Dispatch<React.SetStateAction<boolean>>
	openModalSendArray?: boolean
	setOpenModalSendArray?: React.Dispatch<React.SetStateAction<boolean>>
	dataSelected?: number[]
	setDataSelected?: React.Dispatch<React.SetStateAction<number[]>>
}

export interface ModalPropsCopy {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	onActionsCopy?: (body: BodySharedContentCopy, id: number) => Promise<void>
	onActionsCopyArray?: (body: BodySharedContent) => Promise<void>
	idCopy?: number
	setOpenReview?: React.Dispatch<React.SetStateAction<boolean>>
	textValue?: string
	organizationPartnerId?: number
	contentId?: number
	dataSelected?: number[]

	openModalCopyPrimary?: boolean
}
export interface LoadingProps {
	isSmall?: boolean
}

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	label?: string
	disabled?: boolean
	color?:
		| 'Primary'
		| 'Transparent'
		| 'Gray'
		| 'Gray2'
		| 'Gray3'
		| 'Gray4'
		| 'Gray5'
		| 'Lavender'
		| 'LavenderLight'
		| 'DangerTransparent'
		| 'PurpleTransparent'
		| 'white'
		| 'Black'
		| 'Bg-gray'
		| 'text-black'
		| 'Bg-gray-2'
	iconLeft?: string
	iconRight?: string
	textLeft?: string
	textRight?: string
	linkTo?: string
	className?: string
	leftIconDivClassName?: string
	rightIconDivClassName?: string
	buttonTextClassName?: string
	children?: JSX.Element
	small?: boolean
	helper?: string
	fillpat?: boolean
	classPanelHelper?: string
	classNameText?: string
	iconClassName?: string
}

export interface RadioButtonGroupProps {
	value?: string
	radioButtonOption: string
	items: { id: number; label: string; value: string }[]
	setRadioButtonOption: React.Dispatch<React.SetStateAction<string>>
	classStyle?: string
	classStyleRadio?: string
}

interface Button {
	id: number
	label: string
	value: string
}

export interface ButtonGroupProps {
	items: Button[]
	classStyle?: string
	classStyleRadio?: string
	setRadioButtonOption: React.Dispatch<React.SetStateAction<string>>
	radioFilter: string
}

export interface IconProps {
	src: string
	className?: string
	pointer?: boolean
	fillPath?: boolean
	fillLine?: boolean
	fillCircle?: boolean
	fillRect?: boolean
	onClick?: () => void
}

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	name: string
	title?: string
	limit?: boolean
	cantLimit?: number
	showEye?: boolean
	leftClick?: () => void
	rightClick?: () => void
	rightIcon?: string
	customClick?: () => void
	leftIcon?: string
	error?: FieldError
	register?: UseFormRegister<any>
	rules?: Record<string, unknown>
	inputClassName?: string
	inputFieldClassName?: string
	titleClassName?: string
	inputFocus?: boolean
	valueCode?: string
	borderFull?: boolean
	search?: boolean
	watchValue?: string
	setValueInput?: UseFormSetValue<any>
	validate?: boolean
	onChangeCustom?: (e: ChangeEvent<HTMLInputElement>) => void
	regexRestriction?: RegExp
	disabled?: boolean
	helper?: string
	fillpat?: boolean
	emoji?: boolean
	icon?: boolean
}

export interface InputFileProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	className?: string
	renderButton?: React.ReactElement<
		React.ButtonHTMLAttributes<HTMLButtonElement>
	>
	buttonColor?:
		| 'Primary'
		| 'Transparent'
		| 'Gray'
		| 'Lavender'
		| 'LavenderLight'
}

export interface InputFileProps2
	extends React.InputHTMLAttributes<HTMLInputElement> {
	className?: string
	setValue: React.Dispatch<React.SetStateAction<InputFileType | null>>
	icon: Icons
	icon2: Icons
	title: string
}

export interface InputTextAreaProps
	extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
	color?:
		| 'Primary'
		| 'Transparent'
		| 'Gray'
		| 'Gray3'
		| 'Lavender'
		| 'LavenderLight'
	name: string
	limit?: boolean
	disabled?: boolean
	cantLimit?: number
	error?: FieldError
	register?: UseFormRegister<any>
	rules?: Record<string, unknown>
	watchValue?: string
	setValueInput?: UseFormSetValue<any>
	className?: string
	textAreaClassName?: string
	textValue?: string
	setTextValue?: React.Dispatch<React.SetStateAction<string>>
	row?: number
	type?: string
}

export interface LinkProps {
	label: string
	linkTo: string
	className?: string
}

export interface ModalType {
	type: 'success' | 'danger' | 'warning' | 'normal'
	open: boolean
	setOpen: (val: boolean) => void
	handleClick?: () => void
	children: JSX.Element
	closeButton?: boolean
	title?: string
	colorIcon?: string
	borderIcon?: string
	sizeModal?: string
	marginL?: boolean
	closeOutside?: boolean
	className?: string
}

export interface LayoutProps {
	children: JSX.Element
	className?: string
	containerClassName?: string
}

export enum TypographyTypes {
	'h1' = 'h1',
	'h2' = 'h2',
	'h3' = 'h3',
	'p' = 'p',
	'span' = 'span',
	'legend' = 'legend',
	'label' = 'label'
}
export enum FontLeadingTypes {
	'22/27' = 'text-[22px] leading-[27px] font-bold',
	'15/18' = 'text-[15px] leading-[18px] font-bold',
	'14/24' = 'text-sm leading-6 font-semibold',
	'15/15' = 'text-[15px] leading-[15px] font-bold',
	'13/20' = 'text-[13px] leading-5 font-bold',
	'12/15' = 'text-xs leading-[15px] font-bold',
	'10/17' = 'text-[10px] leading-[17px] font-medium',
	'18/21' = 'text-lg leading-[21px] font-bold',
	'16/18' = 'text-base leading-[18px] font-bold',
	'16/22' = 'text-base leading-[22px] font-bold',
	'12/16' = 'text-xs leading-[16px] font-bold',
	'15/11' = 'text-[15px] leading-[11px] font-bold',
	'10/12' = 'text-[10px] leading-[12px] font-bold',
	'31/37' = 'text-[31px] leading-[37px] font-bold',
	'15/22' = 'text-[15px] leading-[22px]',
	'12/12' = 'text-[12px] leading-[12px] font-semibold',
	'24/29' = 'text-[24px] leading-[29px] font-semibold',
	'26/31' = 'text-[26px] leading-[31px] font-bold'
}

export interface TitleSendDirectPushProps {
	title: string
}
export interface TypographyProps {
	title: string
	type: TypographyTypes
	fontLeading?: keyof typeof FontLeadingTypes
	className?: string
	htmlFor?: string
}

export interface TitleProps {
	icon: string
	title: string
	subtitle?: string
	className?: string
}

export interface BoxsProps {
	children: JSX.Element
	className?: string
}

export interface TitleBoxProps {
	icon?: string
	title: string
	className?: string
}

export interface ItemDropdownOptionProps {
	id: number
	name: string
	option: string
}

export interface BoxComponentProps {
	styles?: string
	setPeriod?: React.Dispatch<React.SetStateAction<string>>
	setMessageDashboard?: React.Dispatch<React.SetStateAction<string>>
	setTagApprovalModal?: React.Dispatch<React.SetStateAction<boolean>>
}
export interface PushNotificationProps {
	messageDashboard?: string
	setMessageDashboard?: React.Dispatch<React.SetStateAction<string>>
}

export interface PerformanceBarProps {
	name?: string
	icon?: string
	showPercent?: boolean
	showValue?: boolean
	showComparison?: boolean
	value: number | null
	maxValue: number | null
}

export interface UsersPlatformItemProps {
	text: string
	icon: string
	percent: number
}

export interface ContentHighLightItemProps {
	name: string
	type: string
	createdAt: string
}

export interface MyPeopleListItemProps {
	name: string
	email: string
	imageUrl: string
}

export interface NewItemsTagsProps {
	id_tag: number
	name_tag: string
}

export interface NewTagRequestItemProps {
	id: number
	name: string
	image: string
	tags: NewItemsTagsProps[]
	refetch: <TPageData>(
		options?: RefetchOptions & RefetchQueryFilters<TPageData>
	) => Promise<QueryObserverResult<NewTagsResponse[] | null, unknown>>
}

export interface GroupButtonProps {
	className?: string
	buttonClassName?: string
	options: {
		name: string
		value: string
	}[]
	period: string
	setPeriod: React.Dispatch<React.SetStateAction<string>>
	big?: boolean
	huge?: boolean
	barBotton?: boolean
	renderedOption?: string
	numberAproval?: number
	buttomNormal?: string
	resetFilter?: () => void
	containerOptionsClassName?: string
}

export interface SlideProps {
	title: string
	open: boolean
	scroll?: boolean
	children?: JSX.Element
	classNameTitle?: string
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	helper?: boolean
	titleHelper?: string
	fillpat?: boolean
	wide?: boolean
	closeIconColor?: ButtonProps['color']
}

export interface NotificationsProps {
	onClick: () => void
	className?: string
}

export type ValueType = {
	newValue: number
	oldValue: number
}

export type DashboardPerformanceType = {
	followers: ValueType
	reach: ValueType
	savedPosts: ValueType
	sharedPosts: ValueType
	sends: ValueType
}

export type ContentPerformanceType = {
	contentCreated: ValueType
	reach: ValueType
	savedContent: ValueType
	sharedContent: ValueType
}

export type TagsOpenType = {
	id: number
	open: boolean
}

export interface DeleteTagsBody {
	tags_id: number[]
	tag_to_replace: number | number[]
}
export interface ParnetTableProps<T> {
	dataSelected?: number[]
	setDataSelected?: React.Dispatch<React.SetStateAction<number[]>>
	tagsSelected?: TagType[] | null
	setTagsSelected?: React.Dispatch<React.SetStateAction<TagType[] | null>>
	tagsOpen?: TagsOpenType[]
	setTagsOpen?: React.Dispatch<React.SetStateAction<TagsOpenType[] | null>>
	setSubTagId?: React.Dispatch<React.SetStateAction<number | null>>
	refetch?: RefetchType
	childRefetch?: RefetchType
	className?: string
	data: T | undefined
	setData?: React.Dispatch<React.SetStateAction<any>>
	page: number
	setPage: React.Dispatch<React.SetStateAction<number>>
	isLoading: boolean
	disabledDeleteButton?: boolean
	setOpen?: React.Dispatch<React.SetStateAction<boolean>>
	itemsShowing?: number
	onItemsShowingChange?: (count: number) => void
	dataNotification?: boolean
	setOpenModalDecline?: React.Dispatch<React.SetStateAction<boolean>>
	updateTags?: (id: number, value: boolean) => Promise<void>
	updateTagsChildrens?: (id: number, value: boolean) => Promise<void>
	updateTagsVisibilityChildrens?: (id: number, value: boolean) => Promise<void>
	setDataTags?: React.Dispatch<React.SetStateAction<any>>
	actionsButton?: JSX.Element
	deleteLoading?: boolean
	setDeleteLoading?: React.Dispatch<React.SetStateAction<boolean>>
	myPeopleRefetch?: RefetchType
	setLoadingCreate?: React.Dispatch<React.SetStateAction<boolean>>
	deleteTagsParents?: (DeleteTagsBody: DeleteTagsBody) => Promise<void>
	deleteTagsChildrens?: (DeleteTagsBody: DeleteTagsBody) => Promise<void>
}

export interface ParentTableProps<T> {
	dataSelected?: number[]
	setDataSelected?: React.Dispatch<React.SetStateAction<number[]>>
	dataSelectedIds?: NetworkPartnerTableItem[]
	setDataSelectedIds?: React.Dispatch<React.SetStateAction<any[]>>
	tagsSelected?: TagType[] | null
	setTagsSelected?: React.Dispatch<React.SetStateAction<TagType[] | null>>
	tagsOpen?: TagsOpenType[]
	setTagsOpen?: React.Dispatch<React.SetStateAction<TagsOpenType[] | null>>
	setSubTagId?: React.Dispatch<React.SetStateAction<number | null>>
	refetch?: RefetchType
	childRefetch?: RefetchType
	className?: string
	data: T | undefined
	setData?: React.Dispatch<React.SetStateAction<any>>
	page: number
	setPage: React.Dispatch<React.SetStateAction<number>>
	isLoading: boolean
	disabledDeleteButton?: boolean
	setOpen?: React.Dispatch<React.SetStateAction<boolean>>
	itemsShowing?: number
	onItemsShowingChange?: (count: number) => void
	dataNotification?: boolean
	contentOrPartnet?: number
	UpdateTags?: (id: number) => void
	updateSubtagsApproval?: (parentId: number, subtagId: number) => Promise<void>
	isOrder?: boolean
	setIsUserInitiated?: React.Dispatch<React.SetStateAction<boolean>>
	classNameLoading?: string
	dataTableLength?: boolean
	refetchPinned?: RefetchType
	setDataFilter?: React.Dispatch<React.SetStateAction<string>>
	rows?: {
		comparator: DropdownFullItemProps
		propierty: DropdownFullItemProps
		operator: DropdownFullItemProps
		value: DropdownValue
	}[]
	setRows?: React.Dispatch<
		React.SetStateAction<
			{
				comparator: DropdownFullItemProps
				propierty: DropdownFullItemProps
				operator: DropdownFullItemProps
				value: DropdownValue
			}[]
		>
	>
	onConvertData?: (
		originalData: {
			comparator: DropdownFullItemProps
			propierty: DropdownFullItemProps
			operator: DropdownFullItemProps
			value: DropdownValue
		}[]
	) => ConvertedDataItem[]
	propierty?: DropdownFullItemProps
	operator?: DropdownFullItemProps
	comparator?: DropdownFullItemProps
	isLocOrPush?: DropdownFullItemProps
	setOpenSaveList?: React.Dispatch<React.SetStateAction<boolean>>
	setIsSaveList?: React.Dispatch<React.SetStateAction<boolean>>
	myListsRefetch?: <TPageData>(
		options?: RefetchOptions & RefetchQueryFilters<TPageData>
	) => Promise<QueryObserverResult<PeopleMyListsResponse | null, unknown>>
	resetFilter?: () => void
	resetRow?: (index: number) => void
	openExport?: boolean
	listToEdit?: number
}

interface NetworkPartnerTableItem {
	id: number
	status: string
}
export interface ChildrenTableProps<T> {
	onSort?: HeaderTableItemProps['onSort']
	data: T | null
	setData?: React.Dispatch<React.SetStateAction<any>>
	backendPages?: number | null
	rows?: string[]
	headers?: string[]
	justify?: 'center' | 'left' | 'right'
	actionColumn?: 'left' | 'right'
	borderTable?: boolean
	actionColumnWidth?: number
	idName?: string
	bodyTableClassName?: string
	headerTableClassName?: string
	actions?: string[]
	hideActionsHeader?: boolean
	loading?: boolean
	page?: number
	setPage?: React.Dispatch<React.SetStateAction<number>>
	dataSelected?: number[]
	setDataSelected?: React.Dispatch<React.SetStateAction<number[]>>
	tagsSelected?: TagType[] | null
	setTagsSelected?: React.Dispatch<React.SetStateAction<TagType[] | null>>
	setSubTagId?: React.Dispatch<React.SetStateAction<number | null>>
	tagsOpen?: TagsOpenType[]
	setTagsOpen?: React.Dispatch<React.SetStateAction<TagsOpenType[] | null>>
	actionsButton?: JSX.Element
	listLoginType?: JSX.Element
	customWidth?: number[]
	setOpen?: React.Dispatch<React.SetStateAction<boolean>>
	setLoading?: React.Dispatch<React.SetStateAction<boolean>>
	refetch?: RefetchType
	childRefetch?: RefetchType
	table?: TablesType
	dataNotification?: boolean
	contentOrPartnet?: number
	UpdateTags?: (id: number) => void
	updateSubtagsApproval?: (parentId: number, subtagId: number) => Promise<void>
	isOrder?: boolean
	refetchPinned?: RefetchType
	setIsUserInitiated?: React.Dispatch<React.SetStateAction<boolean>>
	dataTableLength?: boolean
	listToDelete?: number | null
	setListToDelete?: React.Dispatch<React.SetStateAction<number | null>>
	openExport?: boolean
}

export interface HeaderTableItemProps {
	header: string
	style?: React.CSSProperties
	justify?: 'center' | 'left' | 'right'
	onSort?: (header: string, sortType: 'asc' | 'desc' | null) => void
	table?: TablesType
	column?: string
}

export interface GenericTableProps {
	className?: string
	data?: NetworkPartnerTableResponse | null
	error?: any
	isFetching?: boolean
	isLoading?: boolean
	isError?: boolean
	setCurrentPage?: React.Dispatch<React.SetStateAction<number>>
	setDataSelected?: React.Dispatch<React.SetStateAction<any[]>>
}
export type TableDataType<T> = T & {
	subRows?: T[]
}
// eslint-disable-next-line @typescript-eslint/ban-types
export interface TableProps<D extends object = {}> {
	noDataMessage?: string
	columns: Column<D>[]
	data: (D | TableDataType<D>)[]
	customWidth?: number[]
	selectionCustomWidth?: number
	bodyTableClassName?: string
	justify?: 'center' | 'left' | 'right'
	showPagination?: boolean
	page?: number
	pages?: number
	total?: number
	isFetching?: boolean
	borderTable?: boolean
	itemsShowing?: number
	onItemsShowingChange?: (items: number) => void
	onPageChange?: (page: number) => void
	onRowSelect?: (row: Row<D>[], selected: boolean) => void
	rowSelected?: (row: Row<D>) => boolean
	hideSelectionHeader?: boolean
	contentLarg?: boolean
	customWidthOverride?: boolean
	rowDisabled?: (row: Row<D>) => boolean
}

export interface NetworkActionCellProps {
	value?: number
	acceptHandler: (id: number) => void
	declineHandler: (id: number) => void
	disconnectHandler: (id: number) => void
}

export interface NetworkMyFeedsActionProps {
	open: boolean
	onActionClickEdit: () => void
	onActionClickDelete: () => void
	onClose: () => void
	isLoading?: boolean
}

export interface NetworkActionProps {
	open: boolean
	onActionClick: (status: NetworkStatus) => void
	onClose: () => void
	isLoading?: boolean
	acceptHidden?: boolean
	declineHidden?: boolean
	disconnectHidden?: boolean
}

export interface UnsuscribedPartnerModalProps {
	open: boolean
	onActionClick: (status: NetworkStatus) => void
	onClose: () => void
	isLoading?: boolean
	disconnectHidden?: boolean
}

export interface FmPartnerListActionCellProps {
	value?: number
	deleteHandler: (id: number) => void
	deleteEdit: (id: number) => void
}

export interface ButtonSharedCellProps {
	value?: number
	declineHandler: (id: number) => void
	acepteHandler: (id: number) => void
}
export interface PeopleMyPeopleActionCellProps {
	value?: number
	data?: PeopleMyPeopleItem
	refetch?: <TPageData>(
		options?: RefetchOptions & RefetchQueryFilters<TPageData>
	) => Promise<QueryObserverResult<PeopleMyPeopleResponse | null, unknown>>
	onSubmit?: () => void
}
export interface PeopleMyPeopleLoginListProps {
	data?: UserSigninType[]
}

export interface FollowersTagCellProps {
	item?: TagItem[]
	followerTags?: FollowerTagItems
	className?: string
	button?: boolean
	setOpen?: React.Dispatch<React.SetStateAction<boolean>>
	refetch?: RefetchType
}

export interface PeopleMyListsActionCellProps {
	data?: PeopleMyListsItem
	type?: 'Static' | 'Dynamic'
	refetch?: <TPageData>(
		options?: RefetchOptions & RefetchQueryFilters<TPageData>
	) => Promise<QueryObserverResult<PeopleMyListsResponse | null, unknown>>
	setDataFilter?: React.Dispatch<React.SetStateAction<string>>
	rows?: {
		comparator: DropdownFullItemProps
		propierty: DropdownFullItemProps
		operator: DropdownFullItemProps
		value: DropdownValue
	}[]
	setRows?: React.Dispatch<
		React.SetStateAction<
			{
				comparator: DropdownFullItemProps
				propierty: DropdownFullItemProps
				operator: DropdownFullItemProps
				value: DropdownValue
			}[]
		>
	>
	onConvertData?: (
		originalData: {
			comparator: DropdownFullItemProps
			propierty: DropdownFullItemProps
			operator: DropdownFullItemProps
			value: DropdownValue
		}[]
	) => ConvertedDataItem[]
	propierty?: DropdownFullItemProps
	operator?: DropdownFullItemProps
	comparator?: DropdownFullItemProps
	isLocOrPush?: DropdownFullItemProps
	setOpenSaveList?: React.Dispatch<React.SetStateAction<boolean>>
	setIsSaveList?: React.Dispatch<React.SetStateAction<boolean>>
	myListsRefetch?: <TPageData>(
		options?: RefetchOptions & RefetchQueryFilters<TPageData>
	) => Promise<QueryObserverResult<PeopleMyListsResponse | null, unknown>>
	resetFilter?: () => void
	resetRow?: (index: number) => void
}

export interface PeopleMyTagsActionCellProps {
	parentValue?: number
	value?: number
	allData: PeopleMyTagsProps
	data?: PeopleMyTagsChildrenItem | PeopleMyTagsItemProps
	disabledDeleteButton?: boolean
	mode?: string
	refetch?: RefetchType
	childRefetch?: RefetchType
	setData?: React.Dispatch<React.SetStateAction<PeopleMyTagsItemProps[] | null>>
	isChildren?: boolean
	deleteLoading?: boolean
	setDeleteLoading?: React.Dispatch<React.SetStateAction<boolean>>
	updateTagsVisibilityChildrens?: (id: number, value: boolean) => Promise<void>
	myPeopleRefetch?: RefetchType
	setLoadingCreate?: React.Dispatch<React.SetStateAction<boolean>>
	deleteTagsParents?: (DeleteTagsBody: DeleteTagsBody) => Promise<void>
	deleteTagsChildrens?: (DeleteTagsBody: DeleteTagsBody) => Promise<void>
}

export interface PeopleMyTagsProps {
	items: PeopleMyTagsItemProps[]
	size: number
	pages: number
	page: number
	total: number
}

export interface PeopleMyTagsItemProps {
	name: string
	organization_tag_parent_id: number
	count_son: number
	required_approval: boolean
	is_visible: boolean
	subRows?: PeopleMyTagsChildrenItem[]
}

export interface PeopleMyTagsChildrenItemProps {
	data: PeopleMyTagsItemProps[]
	setData: React.Dispatch<React.SetStateAction<any>>
	item: PeopleMyTagsItemProps
	actionsButton: JSX.Element
	justify?: 'center' | 'left' | 'right'
	actionColumn?: 'left' | 'right'
	customWidth?: number[]
	tagSelected: TagType
	itemIndex: number
	handleClickParent: (parentId: number) => void
	handleSelected: (parentId: number, subtagId: number) => void
	setSubTagId: React.Dispatch<React.SetStateAction<number | null>>
	tagsOpen: TagsOpenType[]
	clickHandler: (id: number) => void
	UpdateTags?: (id: number) => void
	updateSubtagsApproval?: (parentId: number, subtagId: number) => Promise<void>
}

export type TagType = {
	id: number
	selected: boolean
	hasChildren: boolean
	subtags: number[]
}

export interface ContentActionCellProps {
	value?: number
	data?: ContentListItem
	editHandler: (
		id: number,
		category: string | null,
		type: string | null
	) => void
	copyHandler: (id: number, category: string | null) => void
	deactivateHandler: () => void
	deleteHandler: () => void
	pinHandler: () => void
	pinHandlerOrder: (id: number, val: boolean, order: number | null) => void
	drag?: boolean
	dataNotification?: boolean
	isOrder?: boolean
	index: number
	setOrderOpen?: React.Dispatch<React.SetStateAction<boolean>>
	orderOpen?: boolean
	dataTableLength?: boolean
}
export interface LocationsActionCellProps {
	value?: number
	data?: ItemLocations
	editHandler: (id: number) => void
	deleteHandler: () => void
	pinHandler: () => void
	drag?: boolean
	dataNotification?: boolean
	isOrder?: boolean
	index: number
	setOrderOpen?: React.Dispatch<React.SetStateAction<boolean>>
	orderOpen?: boolean
	dataTableLength?: boolean
}

export interface OrderPositionModalProps {
	data?: ContentListItem
	className?: string
	classPanel?: string
	pinHandlerOrder: (id: number, val: boolean, order: number | null) => void
}

export interface RequiredApprovalCheckboxProps {
	defaultChecked: boolean
	id: number
	parentId?: number
	mode?: string
	setData?: React.Dispatch<React.SetStateAction<any>>
	updateTags?: (id: number, value: boolean) => Promise<void>
	updateTagsChildrens?: (id: number, value: boolean) => Promise<void>
	updateSubtagsApproval?: (parentId: number, subtagId: number) => Promise<void>
	refetch?: RefetchType
	isChildren: boolean
	data?: PeopleMyTagsProps
}

export interface TableCheckboxProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	indeterminate?: boolean
	className?: string
}
export interface DropdownCheckboxGroupProps extends ButtonProps {
	options: string[]
	names: string[]
	selectedOptions: string[]
	setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>
}
export interface ModalProps {
	className?: string
	children: JSX.Element
	title?: string
	titleClassName?: string
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	showCloseButton?: boolean
	titleHelper?: string
	helper?: boolean
	fillpat?: boolean
	onClose?: () => void
	isUpdate?: boolean
	handleCancel?: () => void
	closeOnOverlayTap?: boolean
	id?: string
}

export interface MaterialUiPopOverProps {
	className?: string
	children: JSX.Element
	title?: string
	titleClassName?: string
	handleClose: () => void
}

export interface GenericModalProps<T> {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	onSubmit?: () => void
	onClickConfirm?: MouseEventHandler<HTMLButtonElement>
	onClickHandler?: (...args: any[]) => Promise<void>
	refetch?: RefetchType
	childRefetch?: RefetchType
	data?: T
	value?: number
	parentValue?: number
	selected?: number[]
	mode?: string
	dataSelected?: number[]
	setDataSelected?: React.Dispatch<React.SetStateAction<number[]>>
	setTagsSelected?: React.Dispatch<React.SetStateAction<TagType[] | null>>
	openFilter?: boolean
	setOpenFilter?: React.Dispatch<React.SetStateAction<boolean>>
	setData?: React.Dispatch<React.SetStateAction<any>>
	setAnchorEl?: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>
	isChildren?: boolean
	setDataTags?: React.Dispatch<React.SetStateAction<any>>
	dataTags?: PeopleMyTagsProps
	setLoadingCreate?: React.Dispatch<React.SetStateAction<boolean>>
	deleteLoading?: boolean
	setDeleteLoading?: React.Dispatch<React.SetStateAction<boolean>>
	setDataFilter?: React.Dispatch<React.SetStateAction<string>>
	updateTagsVisibilityChildrens?: (id: number, value: boolean) => Promise<void>
	myPeopleRefetch?: RefetchType
	deleteTagsParents?: (DeleteTagsBody: DeleteTagsBody) => Promise<void>
	deleteTagsChildrens?: (DeleteTagsBody: DeleteTagsBody) => Promise<void>
	type?: 'Dynamic' | 'Static'
	title?: string
	descripcion?: string
	icon?: string
	handleCancel?: () => void
	isUpdate?: boolean
	buttonLabel?: string
}

export interface FilterDinamicModalProps<T> {
	open: boolean
	data?: T
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	openFilter?: boolean
	setOpenFilter?: React.Dispatch<React.SetStateAction<boolean>>
	setDataFilter?: React.Dispatch<React.SetStateAction<string>>
	rows?: {
		comparator: DropdownFullItemProps
		propierty: DropdownFullItemProps
		operator: DropdownFullItemProps
		value: DropdownValue
	}[]
	setRows?: React.Dispatch<
		React.SetStateAction<
			{
				comparator: DropdownFullItemProps
				propierty: DropdownFullItemProps
				operator: DropdownFullItemProps
				value: DropdownValue
			}[]
		>
	>
	onConvertData?: (
		originalData: {
			comparator: DropdownFullItemProps
			propierty: DropdownFullItemProps
			operator: DropdownFullItemProps
			value: DropdownValue
		}[]
	) => ConvertedDataItem[]
	propierty?: DropdownFullItemProps
	operator?: DropdownFullItemProps
	comparator?: DropdownFullItemProps
	isLocOrPush?: DropdownFullItemProps
	setOpenSaveList?: React.Dispatch<React.SetStateAction<boolean>>
	resetFilter?: () => void
	setIsSaveList?: React.Dispatch<React.SetStateAction<boolean>>
	myListsRefetch?: <TPageData>(
		options?: RefetchOptions & RefetchQueryFilters<TPageData>
	) => Promise<QueryObserverResult<PeopleMyListsResponse | null, unknown>>
	resetRow?: (index: number) => void
	typeState?: 'Create' | 'Edit'
	mylistTableRefetch?: RefetchType
	title?: string
	descripcion?: string
	icon?: string
	setPeopleMyPeopleTablePage?: React.Dispatch<React.SetStateAction<number>>
}
export interface ModalExportProps<T> {
	setOpenExportSucces: React.Dispatch<React.SetStateAction<boolean>>
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	data?: T
	setActivateExport: React.Dispatch<React.SetStateAction<boolean>>
}

export interface ModalExportSuccesProps {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	setActivateExport: React.Dispatch<React.SetStateAction<boolean>>
	setMyPeopleDataSelected: React.Dispatch<React.SetStateAction<number[]>>
}

export interface ListTags {
	value: number
	label: string
}

export interface DinamicListFormProps {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	onCloseModal: () => void
	handleSubmit: UseFormHandleSubmit<SaveListDinamic>
	onSubmit: (data: SaveListDinamic) => void
	register: UseFormRegister<any>
	rules: Record<string, { required: { value: boolean; message: string } }>
	errors: Partial<FieldErrorsImpl<{ name: string }>>
	setValue: UseFormSetValue<any>
	rows?: {
		comparator: DropdownFullItemProps
		propierty: DropdownFullItemProps
		operator: DropdownFullItemProps
		value: DropdownValue
	}[]
	getValue: (
		index: number,
		campo: string,
		valor: DropdownFullItemProps & DropdownValue
	) => void
	resetRow?: (index: number) => void
	tags: ListTags[] | undefined
	deleteRow: (index: number) => void
	addRow: () => void
	resetFilter: (() => void) | undefined
	typeState: 'Create' | 'Edit' | undefined
	isValid: boolean
	isvalidForm: string
	isLoading: boolean
	loadingTags?: boolean
}

export interface OriginalItem {
	property: string
	operator: string
	value: string
	comparator: string
	isLocOrPush?: string
}

export interface ConvertedItem {
	comparator: { name: string; value: string }
	propierty: { name: string; value: string }
	operator: { name: string; value: string }
	value: MyObject | MyObjectLoc | string
	isLocOrPush?: { name: string; value: string }
}
export interface MyObject {
	id: number
	name: string
	value: string
}

export interface MyObjectLoc {
	name: string
	value: string
}

export type NetworkSubscriptionTableType = {
	id: string
	direction: string
	status: string
	organizationName: string
	feedName: string
	contact: string
	requestDate: string
}

export type NetworkPartnerTableType = {
	id: string
	direction: string
	status: string
	organizationName: string
	contact: string
	requestDate: string
}

export type NetworkNewOutboundPartnerConnectionRequestTableType = {
	id: string
	partner: string
	followers: string
}

export type PaginationType = {
	currentPage: number
	totalPages: number
	pageLimit: number
	totalRecords: number
}

export interface PaginateProps {
	itemsShowing?: number
	totalRecords: number
	pageLimit?: number
	pageNeighbours?: number
	page?: number
	onPageChanged: (data: PaginationType) => void
	onShowingItemsChange?: (value: number) => void
	className?: string
	buttonClassName?: string
	arrowButtonClassName?: string
}

export interface DropdownProps {
	setPeriod: React.Dispatch<React.SetStateAction<string>>
}

export interface DataGraphicProps {
	labels: string[]
	datasets: [
		{
			label: string
			data: number[]
			backgroundColor: string
		}
	]
}

export interface DropdownFullItemProps {
	id?: number | string
	name: string
	value?: string
}

export interface DropdownFullItemPropsFilter {
	id: number | string
	name: string
	value?: string | number
}

export type ConvertedDataItemValue =
	| string
	| number
	| DropdownFullItemProps
	| null

export interface ConvertedDataItem {
	property: string
	operator: string | undefined
	value: ConvertedDataItemValue
	comparator: string
}

export interface DropdownFullProps {
	icon?: string
	color?: string
	rounded?: boolean
	buttonClassName?: string
	small?: boolean
	disabled?: boolean
	fillPath?: boolean
	className?: string
	iconClass?: string
	menuClassName?: string
	item: DropdownFullItemProps
	list: DropdownFullItemProps[] | DropdownFullItemPropsFilter[]
	setClean?: React.Dispatch<React.SetStateAction<boolean>>
	setItem: React.Dispatch<React.SetStateAction<DropdownFullItemProps>>
	error?: FieldError | undefined
	placeholder?: string
}

export interface BoxSwirchProps {
	icon?: string
	title: string
	bold?: boolean
	border?: boolean
	enabled: boolean
	className?: string
	setEnabled: React.Dispatch<React.SetStateAction<boolean>>
}

export interface UpdateInformationAdminProps {
	isModal?: boolean
	setOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

export interface SwitchCustomProps {
	enabled: boolean
	setEnabled: React.Dispatch<React.SetStateAction<boolean>>
}

export interface BoxContentDeliveryProps {
	title: string
	onClick: () => void
}

export interface GoodLinkProps {
	onClickClose: () => void
	totalData: TotalDataCreateContent
	deliveryActive: DeliveryActiveData
	setOpenSlide: React.Dispatch<React.SetStateAction<boolean>>
	setTotalData: React.Dispatch<React.SetStateAction<TotalDataCreateContent>>
	setDeliveryActive: React.Dispatch<React.SetStateAction<DeliveryActiveData>>
	mode?: 'edit' | 'create'
}

export interface ScheduleProps {
	itemRecurrence: string
	eventRecurrence: string
	onClickClose: () => void
	totalData: TotalDataCreateContent
	deliveryActive: DeliveryActiveData
	setOpenSlide: React.Dispatch<React.SetStateAction<boolean>>
	setItemRecurrence: React.Dispatch<React.SetStateAction<string>>
	setEventRecurrence: React.Dispatch<React.SetStateAction<string>>
	setTotalData: React.Dispatch<React.SetStateAction<TotalDataCreateContent>>
	setDeliveryActive: React.Dispatch<React.SetStateAction<DeliveryActiveData>>
	mode?: 'create' | 'edit'
	isLoading?: boolean
	isExpiratedView: boolean
	locationComponent?: string
}

export interface DateTimerProps {
	icon: string
	title?: string
	line?: boolean
	disabled?: boolean
	className?: string
	timeFormat?: string | boolean
	placeholderInput?: string
	validate: string | moment.Moment | Date
	initialValue: string | moment.Moment | Date | undefined
	setDate: React.Dispatch<React.SetStateAction<string | moment.Moment>>
	helper?: boolean
	textHelper?: string
	hasDatesBefore?: boolean
	value?: string | moment.Moment | Date
}

export interface HelperProps {
	text: string
	className?: string
	classPanel?: string
	filpath?: boolean
}

export interface BarMultiSelectData {
	id: number
	value: string
	label: string
}

export interface BarMultiSelectProps {
	list: BarMultiSelectData[]
	listSeleted: string[]
	setListSeleted: React.Dispatch<React.SetStateAction<string[]>>
}

export interface RadioGroupsDataProps {
	id: string
	title: string
	value: string
}

export interface RadioGroupsProps {
	name: string
	title: string
	endDate: string | Moment
	checked: RadioGroupsDataProps
	options: RadioGroupsDataProps[]
	validate: string | Moment | Date
	endDdateType: DropdownFullItemProps
	setChecked: React.Dispatch<React.SetStateAction<RadioGroupsDataProps>>
	setEndDate: React.Dispatch<React.SetStateAction<string | Moment>>
	setEndDateType: React.Dispatch<React.SetStateAction<DropdownFullItemProps>>
}

export interface CreateNewContentProps {
	listImage: ItemDataAssentsAll[]
	totalData: TotalDataCreateContent
	setListImage: React.Dispatch<React.SetStateAction<ItemDataAssentsAll[]>>
	setTotalData: React.Dispatch<React.SetStateAction<TotalDataCreateContent>>
	mode?: 'create' | 'edit'
	typeFunction?: TypeFunction
	videoId?: string
	setVideoId?: React.Dispatch<React.SetStateAction<string>>
	setValueInput?: React.Dispatch<React.SetStateAction<string>>
	valueInput?: string
	isFetching?: boolean
	setOpenDocument?: (value: boolean) => void
	documentsList?: DocumentsInputs[]
}

export interface ContentSetUpProps {
	item?: locationState
	messageDashboard?: string
	listImage?: ItemDataAssentsAll[]
	totalData: TotalDataCreateContent
	setItem?: React.Dispatch<React.SetStateAction<locationState>>
	setDontSend: React.Dispatch<React.SetStateAction<boolean>>
	setTotalData: React.Dispatch<React.SetStateAction<TotalDataCreateContent>>
	setMessageDashboard?: React.Dispatch<React.SetStateAction<string>>
	setplaceHolderInput?: React.Dispatch<React.SetStateAction<string>>
	radioFilter?: string
	setRadioFilter?: React.Dispatch<React.SetStateAction<string>>
	setDataContentType?: React.Dispatch<
		React.SetStateAction<DropdownFullItemProps[]>
	>
	datacontentType?: DropdownFullItemProps[]
	setContentType?: React.Dispatch<React.SetStateAction<DropdownFullItemProps>>
	contentType?: DropdownFullItemProps
	titleComponent?: TitleContent
}

type TitleContent = 'Content Set Up' | 'Create New Location'
type TypeFunction = 'Content' | 'Location'

export interface RadioButtonsSendDirectPushProps {
	item?: locationState
	radioFilter: string
	setRadioFilter: React.Dispatch<React.SetStateAction<string>>
	datacontentType: DropdownFullItemProps[]
	setContentType: React.Dispatch<React.SetStateAction<DropdownFullItemProps>>
	contentType: DropdownFullItemProps
	placeHolderInput: string
	setItem?: React.Dispatch<React.SetStateAction<locationState>>
	totalData: TotalDataCreateContent
	setTotalData: React.Dispatch<React.SetStateAction<TotalDataCreateContent>>
}
export interface ContentDeliveryProps {
	itemRecurrence: string
	publishMyPeope: string
	mode?: 'create' | 'edit'
	eventRecurrence: string
	documentsList: DocumentsInputs[]
	totalData: TotalDataCreateContent
	itemDatePushNotofication: string
	listImagePush: ItemDataAssentsAll | undefined
	deliveryActive: DeliveryActiveData
	setPublishMyPeope: React.Dispatch<React.SetStateAction<string>>
	setItemRecurrence: React.Dispatch<React.SetStateAction<string>>
	setEventRecurrence: React.Dispatch<React.SetStateAction<string>>
	setItemDatePushNotofication: React.Dispatch<React.SetStateAction<string>>
	setDocumentsList: React.Dispatch<React.SetStateAction<DocumentsInputs[]>>
	setTotalData: React.Dispatch<React.SetStateAction<TotalDataCreateContent>>
	setDeliveryActive: React.Dispatch<React.SetStateAction<DeliveryActiveData>>
	setListImagePush: React.Dispatch<
		React.SetStateAction<ItemDataAssentsAll | undefined>
	>
	isLoading: boolean
	typeFunction?: TypeFunction
	defineTypeLocation?: string
	setDefineTypeLocation?: React.Dispatch<React.SetStateAction<string>>
	openDocument?: boolean
	setOpenDocument?: (value: boolean) => void
}

export interface PushNotificationsProps {
	onClickClose: () => void
	itemDatePushNotofication: string
	totalData: TotalDataCreateContent
	deliveryActive: DeliveryActiveData
	listImagePush: ItemDataAssentsAll | undefined
	setOpenSlide: React.Dispatch<React.SetStateAction<boolean>>
	setTotalData: React.Dispatch<React.SetStateAction<TotalDataCreateContent>>
	setDeliveryActive: React.Dispatch<React.SetStateAction<DeliveryActiveData>>
	setItemDatePushNotofication: React.Dispatch<React.SetStateAction<string>>
	setListImagePush: React.Dispatch<
		React.SetStateAction<ItemDataAssentsAll | undefined>
	>
}

export type FilterType = {
	properties: string
	operator: string
	name: string
}

export interface OrganizationCardProps {
	logo: string
	name: string
	lastActive: string | null
	content: number | null
	users: number | null
}

export interface OrganizationTableActionsProps {
	onEditInformation?: () => void
	onEditAdminTeam?: () => void
	onEditAppBuild?: () => void
	onCustomize?: () => void
	onEditTags?: () => void
	onClose?: () => void
}

export enum AdminOrganizationSteps {
	Information,
	AdminTeam,
	AppBuild,
	Customize,
	Tags
}

export interface AdminOrganizationProps {
	initialStep?: AdminOrganizationSteps
	editing?: boolean
}

export interface AdminOrganizationStepComponentProps<T = unknown> {
	data?: T
	information: OrganizationInformationForm
	editing?: boolean
	stepper?: React.ReactElement<StepperProps>
	onBack?: (data: T) => void
	onNext?: (data: T) => void
	onSaveDraft?: (data: T) => void
	onSave?: (data: T) => void
	refetch?: RefetchType
}

export interface PreviewOrganizationStepComponentProps {
	accentColor: string
	backgroundColor: string
	supportColors: string
	colorTexts?: string
	iconUrl?: string
}
export interface IsDataForEditOrganization<T = unknown> {
	isDataOrganizationEdit?: T
}

export interface StepperProps {
	stepsCount: number
	step: number
}

export interface TabsOption {
	label: string
	value: number
}
export interface TabsProps {
	className?: string
	options: TabsOption[]
	value: number
	onChange?: (value: number) => void
}

export interface FileUploaderProps {
	files: File[]
	onChange?: React.ChangeEventHandler<HTMLInputElement>
	onDelete?: (file: File) => void
	accept?: string
}

export interface FileUploaderItemProps {
	name: string
	size: string
	icon: string
	onDelete: () => void
}

export interface AdminOrganizationStepFooterProps {
	className?: string
	onBack?: React.MouseEventHandler<HTMLButtonElement>
	onNext?: React.MouseEventHandler<HTMLButtonElement>
	onSaveDraft?: React.MouseEventHandler<HTMLButtonElement>
	onSave?: React.MouseEventHandler<HTMLButtonElement>
	save?: boolean
	saveDraft?: boolean
	disabledNext?: boolean
}

export interface ColorPickerProps {
	colors: string[]
	selected?: string
	allowCustomColor?: boolean
	onChange?: (color: string) => void
}

export type OrganizationTagType = {
	name: string
	parentTag: string | null
	requiredApproval: boolean
	visible: boolean
	selected: boolean
	expanded: boolean
}

export interface OrganizationTagRowProps {
	subTag?: boolean
	tag: OrganizationTagType
	childTagsCount?: number
	expanded?: boolean
	selected?: boolean
	onEdit?: () => void
	onHide?: () => void
	onDelete?: () => void
	onSelect?: () => void
	onExpand?: () => void
	onRequiredApproval?: () => void
}

export interface OrganizationTagTableProps {
	tags: OrganizationTagType[]
	onEdit?: (tag: OrganizationTagType) => void
	onHide?: (tag: OrganizationTagType, value: boolean) => void
	onDelete?: (tag: OrganizationTagType) => void
	onSelect?: (tag: OrganizationTagType, value: boolean) => void
	onExpand?: (tag: OrganizationTagType, value: boolean) => void
	onRequiredApproval?: (tag: OrganizationTagType, value: boolean) => void
}

export type AdminTeamType = {
	id: number
	fullName: string
	email: string
	password: string
	phoneNumberCode: string
	phoneNumber: string
	image: File | null
	roles: number[]
	newUser?: boolean
}

export interface AdminTeamFormProps {
	admin?: AdminForm
	roles: UserRole[]
	onSave: (data: AdminForm) => void
	onCancel: () => void
	registeredEmails: string[]
	onValidatingUser?: () => void
	setValidateEmail: React.Dispatch<React.SetStateAction<boolean>>
	validateEmail: boolean
	isCreatingAdmin: boolean
}

export type CompoenentCreateType = {
	pos: number
	values: TabComponentsMyOrganizationValueItem
}
export type DynamicTapHeaderProps = {
	active: number
	data: MyOrganizationResponse | undefined
	setData: React.Dispatch<
		React.SetStateAction<MyOrganizationResponse | undefined>
	>
	setTabActive: React.Dispatch<React.SetStateAction<number>>
	deletedTab: (pos: number) => void
	handleAddTap: () => void
}

export type DynamicTapContentProps = {
	active: number
	data?: TabMyOrganizationItem
	deletedTab: (pos: number) => void
	saveData: () => Promise<void>
	control: Control<
		{
			tabs: TabMyOrganizationItem[]
		},
		any
	>
	reset: UseFormReset<{
		tabs: TabMyOrganizationItem[]
	}>
	setValue: UseFormSetValue<{
		tabs: TabMyOrganizationItem[]
	}>
	register: UseFormRegister<{
		tabs: TabMyOrganizationItem[]
	}>
	watch: UseFormWatch<{
		tabs: TabMyOrganizationItem[]
	}>
	errors: any
	isValid: boolean
	errorForm: boolean
	setErrorForm: React.Dispatch<React.SetStateAction<boolean>>
	fieldError: string[]
	setFieldError: React.Dispatch<React.SetStateAction<string[]>>
}

export type NewComponentModalProps = {
	setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
	createNewComponent: (type: TypeComponentsItemsArray) => void
}

export type SlideFormProps = {
	active: number
	dataForm: CompoenentCreateType | undefined
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	data: MyOrganizationResponse | undefined
	setData: React.Dispatch<
		React.SetStateAction<MyOrganizationResponse | undefined>
	>
	setLoading?: React.Dispatch<React.SetStateAction<boolean>>
}

export interface SlideColors {
	data: MyOrganizationResponse | undefined
	slideOpen?: boolean
	setData: React.Dispatch<
		React.SetStateAction<MyOrganizationResponse | undefined>
	>
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
export type GroupComponentsLinkProps = {
	register: UseFormRegister<any>
	index: number
	errors: Partial<FieldErrorsImpl<any>>
	deleteComponent: (pos: number) => void
	setValue?: UseFormSetValue<any>
	handleMediaUploadBanner?: (
		file: File,
		index: number,
		title: string,
		url: string
	) => void
	groupBanner?: BannerMyOrganizationItem[]
	watch?: UseFormWatch<any>
	errorCustom?: any
}

export type EditComponetType = {
	pos: number
	component: TabComponentsMyOrganizationItem
}

export interface InputPhoneDobleProps {
	name: string
	name2: string
	title: string
	rules?: Record<string, unknown>
	rules2?: Record<string, unknown>
	error?: FieldError
	error2?: FieldError
	register?: UseFormRegister<any>
	setValue?: UseFormSetValue<any>
	onChangeCustom?: (e: any) => void
	onChangeCustom2?: (e: any) => void
	watchValue?: string
	watchValueCode?: string
}

export interface AudienceProps {
	publishMyPeope: string
	onClickClose: () => void
	totalData: TotalDataCreateContent
	setAddFeed: React.Dispatch<React.SetStateAction<boolean>>
	setOpenSlide: React.Dispatch<React.SetStateAction<boolean>>
	setSharePartner: React.Dispatch<React.SetStateAction<boolean>>
	setSlideAudience: React.Dispatch<React.SetStateAction<boolean>>
	setShareFMPartner: React.Dispatch<React.SetStateAction<boolean>>
	setPublishMyPeope: React.Dispatch<React.SetStateAction<string>>
	setTotalData: React.Dispatch<React.SetStateAction<TotalDataCreateContent>>
}
export interface ButtomProps {
	color: string
	colorCheck: string
	setColorCheck: React.Dispatch<React.SetStateAction<string>>
}

export interface SharePartnerProps {
	onClickClose: () => void
	totalData: TotalDataCreateContent
	setSharePartner: React.Dispatch<React.SetStateAction<boolean>>
	setSlideAudience: React.Dispatch<React.SetStateAction<boolean>>
	setTotalData: React.Dispatch<React.SetStateAction<TotalDataCreateContent>>
}
export interface ShareFMPartnerProps {
	onClickClose: () => void
	totalData: TotalDataCreateContent
	setSlideAudience: React.Dispatch<React.SetStateAction<boolean>>
	setShareFMPartner: React.Dispatch<React.SetStateAction<boolean>>
	setTotalData: React.Dispatch<React.SetStateAction<TotalDataCreateContent>>
}

export interface BoxPartnersProps {
	id: string
	icon?: string
	image?: string
	title: string
	onClick: () => void
	itemsSeleted: number[]
}

export interface AddFeedProps {
	onClickClose: () => void
	totalData: TotalDataCreateContent
	setAddFeed: React.Dispatch<React.SetStateAction<boolean>>
	setSlideAudience: React.Dispatch<React.SetStateAction<boolean>>
	setTotalData: React.Dispatch<React.SetStateAction<TotalDataCreateContent>>
}

export interface DocumentsProps {
	onClickClose: () => void
	documentsList: DocumentsInputs[]
	totalData: TotalDataCreateContent
	setDocumentsList: React.Dispatch<React.SetStateAction<DocumentsInputs[]>>
	mode?: 'create' | 'edit'
	isLoading: boolean
}

export interface BoxDocumentsProps {
	size: number
	title: string
	onClick: () => void
}

export interface BuildAppsListProps
	extends Omit<
		ChildrenTableProps<{ id: number; name: string }[]>,
		'headers' | 'setPage'
	> {
	onSelect?: (id: number) => void
}

export interface MessageProps {
	text: string
	type: 'error'
	marginL?: string
}

export type OrganizationType = {
	id: number
	name: string
	phoneNumber: string
	address: string
	lastContent: string | null
	email?: string
	users: number
	buildApp: string
	contents: number
	logo: null | string
	shareUrl?: string
	slug?: string
	organization_type?: OrgStatus | null
}

export interface OrganizationTagFormProps {
	tag?: OrganizationTagType
	parents?: OrganizationTagType[]
	onCancel: () => void
	onSubmit: (data: TagForm) => void
	createdTags?: string[]
	disabledNestUnder?: boolean
}

export interface LastOrganizationsListProps
	extends Pick<BoxComponentProps, 'styles'> {
	organizations: OrganizationType[]
}

export interface OrganizationsTableProps
	extends Omit<TableProps, 'data' | 'columns'> {
	organizations: OrganizationType[]
	refetchOrganizations?: () => void
}

export interface BuildAppsAppNamesActionCellProps {
	data?: AppNamesTableItem
	onClickBranch?: (data?: AppNamesTableItem) => void
}

export type InputFileType = {
	content: string
	name: string
}

export interface ImageSlideProps {
	listImage: ItemDataAssentsAll[]
	totalData: TotalDataCreateContent
	setOpenSlide: React.Dispatch<React.SetStateAction<boolean>>
	setSlideImages: React.Dispatch<React.SetStateAction<boolean>>
	setSlideLibraryImages: React.Dispatch<React.SetStateAction<boolean>>
	setListImage: React.Dispatch<React.SetStateAction<ItemDataAssentsAll[]>>
	setTotalData: React.Dispatch<React.SetStateAction<TotalDataCreateContent>>
	setIsFinishCropButtonVisible: React.Dispatch<React.SetStateAction<boolean>>
	isFinishCropButtonVisible: boolean
	setIsCropComplete: React.Dispatch<React.SetStateAction<boolean>>
	isCropComplete: boolean
	mode?: 'create' | 'edit'
	isCropSettingsVisible?: boolean
	setIsCropSettingsVisible?: React.Dispatch<React.SetStateAction<boolean>>
	sendImageCropped: ItemDataAssentsAll[]
	setSendImageCropped: React.Dispatch<
		React.SetStateAction<ItemDataAssentsAll[]>
	>
	videoId?: string
	setVideoId?: React.Dispatch<React.SetStateAction<string>>
	setValueInput?: React.Dispatch<React.SetStateAction<string>>
	valueInput?: string
}

export interface LibraryImageSlideProps {
	listImage: ItemDataAssentsAll[]
	setOpenSlide: React.Dispatch<React.SetStateAction<boolean>>
	setSlideImages: React.Dispatch<React.SetStateAction<boolean>>
	setSlideLibraryImages: React.Dispatch<React.SetStateAction<boolean>>
	setListImage: React.Dispatch<React.SetStateAction<ItemDataAssentsAll[]>>
	setSendImageCropped: React.Dispatch<
		React.SetStateAction<ItemDataAssentsAll[]>
	>
}

export interface InputRadioProsp {
	item: ItemRadios
	disabled?: boolean
	defaultChecked?: string
	setItemRecurrence: React.Dispatch<React.SetStateAction<string>>
}
export interface FormLoginProps {
	user: UserData
	setStep: React.Dispatch<React.SetStateAction<number>>
	setUser: React.Dispatch<React.SetStateAction<UserData>>
	setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export interface PropsModalPassword {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
export interface SelectedOrganizationProps {
	user: UserData
	setStep: React.Dispatch<React.SetStateAction<number>>
}
export interface InputRadioImageProps {
	defaultOptions: string
	option: OrganizationsByUserData
	optionSelected: React.Dispatch<
		React.SetStateAction<OrganizationsByUserData | undefined>
	>
}
export interface ResponseCreateAdmin {
	message: string
}

export interface ItemTotalTagsProps {
	title: string
}

export type TypeDynamicList = 'dynamic' | 'static' | 'individuals'

export interface InputCheckProps {
	id: number
	name: string
	title: string
	reach?: number
	list: number[]
	className?: string
	reachView?: boolean
	setList: React.Dispatch<React.SetStateAction<number[]>>
	isVisible?: boolean
	type?: TypeDynamicList
	isStrong?: boolean
	hasCheckVisible?: boolean
}

export interface TagsProps {
	totalList: Reach
	setTotalList: React.Dispatch<React.SetStateAction<Reach>>
	isLoading: boolean
	resetInput: () => void
	isFetchingNextPage: boolean
	handleChangeDebounce: DebouncedFunc<any>
	data: InfiniteData<PeopleMyTagsParentResponse | null> | undefined
	fetchNextPage: (
		options?: FetchNextPageOptions
	) => Promise<
		InfiniteQueryObserverResult<PeopleMyTagsParentResponse | null, unknown>
	>
	defaultOpenParent?: boolean
}

export interface StaticListProps {
	totalList: Reach
	setTotalList: React.Dispatch<React.SetStateAction<Reach>>
	isLoading: boolean
	resetInput: () => void
	isFetchingNextPage: boolean
	handleChangeDebounce: DebouncedFunc<any>
	data: InfiniteData<StaticListResponse | null> | undefined
	fetchNextPage: (
		options?: FetchNextPageOptions | undefined
	) => Promise<InfiniteQueryObserverResult<StaticListResponse | null, unknown>>
	type: TypeDynamicList
}

export interface IndividualsProps {
	totalList: Reach
	setTotalList: React.Dispatch<React.SetStateAction<Reach>>
	isLoading: boolean
	resetInput: () => void
	isFetchingNextPage: boolean
	handleChangeDebounce: DebouncedFunc<any>
	data: InfiniteData<PeopleMyPeopleResponse | null> | undefined
	fetchNextPage: (
		options?: FetchNextPageOptions | undefined
	) => Promise<
		InfiniteQueryObserverResult<PeopleMyPeopleResponse | null, unknown>
	>
	type: TypeDynamicList
}

export interface DynamicList {
	totalList: Reach
	setTotalList: React.Dispatch<React.SetStateAction<Reach>>
	isLoading: boolean
	resetInput: () => void
	isFetchingNextPage: boolean
	handleChangeDebounce: DebouncedFunc<any>
	data: InfiniteData<DynamicListResponse | null> | undefined
	fetchNextPage: (
		options?: FetchNextPageOptions
	) => Promise<InfiniteQueryObserverResult<DynamicListResponse | null, unknown>>
	type: 'dynamic' | 'static' | 'individuals'
}

export interface SubTagsProps {
	tag: PeopleMyTagsParentItem
	list: number[]
	setList: React.Dispatch<React.SetStateAction<number[]>>
	defaultOpenParent?: boolean
}

export interface CreateNewPushNotification {
	setListImage: React.Dispatch<React.SetStateAction<ItemDataAssentsAll[]>>
	listImage: ItemDataAssentsAll[]
	totalData: TotalDataCreateContent
}

export interface NotificationPushDelivery {
	totalData: TotalDataCreateContent
	deliveryActive: DeliveryActivePush | DeliveryActiveData
	itemRecurrence: string
	eventRecurrence: string
	publishMyPeope: string
	setTotalData: React.Dispatch<React.SetStateAction<TotalDataCreateContent>>
	setDeliveryActive: React.Dispatch<
		React.SetStateAction<DeliveryActivePush | DeliveryActiveData>
	>
	setItemRecurrence: React.Dispatch<React.SetStateAction<string>>
	setEventRecurrence: React.Dispatch<React.SetStateAction<string>>
	setPublishMyPeope: React.Dispatch<React.SetStateAction<string>>
}
export interface LocationResultProps {
	geometry: {
		location: {
			lat: () => number
			lng: () => number
		}
	}
	formatted_address: string
	name: string
	place_id: string
}

export interface InputAutoCompleteGoogleMapsProps {
	title?: boolean
	borderFull?: boolean
	location: locationState
	addressInput?: React.RefObject<HTMLInputElement>
	setLocation: React.Dispatch<React.SetStateAction<locationState>>
	classNameInput?: string
	marginContainer?: string
	key?: number
}

export interface MapProps {
	location: locationState
	addressInput: React.RefObject<HTMLInputElement>
	setLocation: React.Dispatch<React.SetStateAction<locationState>>
}

export interface MarkerCustomProps {
	lat: number
	lng: number
}

export interface InputRangeProps {
	value: locationState
	setValue: React.Dispatch<React.SetStateAction<locationState>>
}

export interface LocationProps {
	onClickClose: () => void
	totalData: TotalDataCreateContent
	deliveryActive: DeliveryActiveData
	setOpenSlide: React.Dispatch<React.SetStateAction<boolean>>
	setTotalData: React.Dispatch<React.SetStateAction<TotalDataCreateContent>>
	setDeliveryActive: React.Dispatch<React.SetStateAction<DeliveryActiveData>>
	isLoading?: boolean
	defineTypeLocation?: string
	setDefineTypeLocation?: React.Dispatch<React.SetStateAction<string>>
	mode?: 'create' | 'edit'
}

export interface LocationContentItemProps {
	item: locationState
	itemTitle: string
	totalData: TotalDataCreateContent
	setItemTitle: React.Dispatch<React.SetStateAction<string>>
	setItem: React.Dispatch<React.SetStateAction<locationState>>
	setNameLocation?: React.Dispatch<React.SetStateAction<string | undefined>>
	nameLocation?: string
	key?: number
}

export interface DeliveryRadiusProps {
	delivery: locationState
	deliveryTitle: string
	totalData: TotalDataCreateContent
	setDeliveryTitle: React.Dispatch<React.SetStateAction<string>>
	setDelivery: React.Dispatch<React.SetStateAction<locationState>>
	setNameLocationRadius?: React.Dispatch<
		React.SetStateAction<string | undefined>
	>
	nameLocationRadius?: string
}

export interface PeopleNamesLayoutProps {
	rol?: string
	roles?: number[]
	fullName: string
	sidebarOpen: boolean
}

export interface OrganizationNamesLayoutProps {
	sidebarOpen: boolean
	responsive?: boolean
	organizationName: string
	organizactionLogo?: string
}

export interface NavProps {
	sidebarOpen: boolean
	responsive?: boolean
	navigation: {
		name: string
		href: string
		active: string[]
		icon: Icons
		userView: boolean
	}[]
}

export interface BottonsProps {
	roles?: number[]
	sidebarOpen: boolean
	responsive?: boolean
	isSuperAdmin: boolean
	handleLogout: () => void
	setSidebarOpen: (value: boolean) => void
}

export type PeopleBatchesTableProps = {
	columns: PeopleBatchesColumns[]
	customWidth: number[]
	data: PeopleMyPeopleItem[]
	isLoading?: boolean
	selectAll?: boolean
	total?: number
	page: number
	onSelectAll?: (event: React.ChangeEvent<HTMLInputElement>) => void
	onPageChanged: PaginateProps['onPageChanged']
}

export interface PeopleBatchesColumns {
	accessor: keyof PeopleMyPeopleItem
	Header: string
	checkbox?: boolean
	list?: boolean
}

export type BatchesTagsProps = {
	data: PeopleMyTagsItemProps[]
	isLoading?: boolean
	selectedRowTags: number[]
	onClickParent?: (tag: PeopleMyTagsItemProps, value: boolean) => void
	onClickChildren?: (row: PeopleMyTagsChildrenItem, value: boolean) => void
	onRemove: () => void
	onAdd: () => void
	fetchNextPage: () => void
	disabledActions?: boolean
	hasNextPage?: boolean
	isFetchingNextPage?: boolean
}

export type BatchesTagItem = {
	tag: PeopleMyTagsItemProps
	selectedRowTags: number[]
	onClickParent?: (tag: PeopleMyTagsItemProps, value: boolean) => void
	onClickChildren?: (row: PeopleMyTagsChildrenItem, value: boolean) => void
}

export type PeopleBatchesConfirmModalProps = {
	open: PeopleBatchesModalType | null
	setOpen: (val: null) => void
	organizationId: number
	selectedTags: number[]
	onSuccess?: () => void
	onError?: () => void
}

export const enum PeopleBatchesModalType {
	back = 'back',
	addTag = 'addTag',
	removeTag = 'removeTag',
	block = 'block',
	unblock = 'unblock',
	removeSelected = 'removeSelected'
}

export type stringOrUndefined = string | undefined

export type OrganizationStatusActionsProps = {
	orgStatus?: OrgStatus | null
	onChageStatus?: (type: OrgStatus | null) => void
}
export interface ContentAssetButtonProps {
	label: string
	onClick: () => void
	className?: string
	direction?: 'row'
	icon: string
}

export interface ContentAssetSlotProps {
	emptyAssets: boolean
	hasImages: boolean
	hasPdf: boolean
	hasVideo: boolean
	onClickPdf?: () => void
	onClickMedia?: () => void
	setSlideImages: React.Dispatch<React.SetStateAction<boolean>>
	setOpenSlide: React.Dispatch<React.SetStateAction<boolean>>
	imageSrc?: string
	videoSrc?: string
}
export interface SecretTextProps {
	value?: string
}

export interface BranchSetupFormProps {
	appBuild?: Partial<AppNamesTableItem> | null
	onSuccessDeepLink?: (url: string) => void
	refetch?: RefetchType
}

export type ConfirmDeepLinkCreationProps = {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	defaultAlias: string
	onClick?: () => void
}

export type BranchKeysFormProps = {
	onClickSave: (data: BranchKeysFormValues) => void
	hideKeys: boolean
	appBuild?: Partial<AppNamesTableItem> | null
}

export interface BranchKeysFormValues {
	branchKey: string
	branchSecret: string
}

export type LinkFillerProps = {
	urlDomain?: string
	className?: string
	pathClassName?: string
	placeholder?: string
	dataOrganization?: OrganizationDataForm
	isError?: boolean
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
	setIsValidAlias: Dispatch<SetStateAction<boolean>>
}
