import { CircularProgress } from '@mui/material'
import {
	GET_PEOPLE_TAGS_ORG_EXCLUDE,
	editTag,
	getPeopleTagsEditExclude
} from 'api'
import clsx from 'clsx'
import {
	Button,
	DropdownFull,
	Input,
	TailwindModal,
	Typography
} from 'components'
import { useToast } from 'hooks'
import {
	DropdownFullItemProps,
	GenericModalProps,
	PeopleMyTagsChildrenItem,
	PeopleMyTagsItemProps,
	PeopleTagsOrgRequest,
	TypographyTypes
} from 'interfaces'
import { shallowCompare } from 'lib'
import React, { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'

export const TagsEditModal: React.FC<
	GenericModalProps<PeopleMyTagsChildrenItem | PeopleMyTagsItemProps>
> = ({ open, setOpen, data, value, refetch }) => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const [newTagValue, setNewTagValue] = useState(data?.name)
	const [selectedOption, setSelectedOption] = useState('no')
	const [isLoadingRequest, setIsLoadingRequest] = useState(false)
	const [parentTagType, setParentTagType] = useState<DropdownFullItemProps>({
		id: 'parentTag0',
		name: 'Please select a Parent Tag'
	})
	const {
		data: orgData,
		isLoading: orgIsLoading,
		isFetching
	} = useQuery(
		[GET_PEOPLE_TAGS_ORG_EXCLUDE, value as number, userId, organizationId],
		() => getPeopleTagsEditExclude(organizationId, value as number)
	)
	const { showToast } = useToast()

	const handleOptionChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>): void => {
			setSelectedOption(event.target.value)
		},
		[]
	)

	const editTagHandler = useCallback(
		async (body: PeopleTagsOrgRequest): Promise<void> => {
			setIsLoadingRequest(true)
			await editTag(value as number, {
				...body,
				organization_id: organizationId
			})
			showToast('Success', "Tag's info updated successfully.", 'success')
			if (refetch) {
				refetch()
			}
		},
		[refetch]
	)

	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			title="Edit Tag"
			className="w-[474px] h-[390px] p-4 rounded-[16px]"
		>
			<div
				className={clsx(
					'flex flex-col w-full items-center h-[calc(100%_-_45px)] mt-4',
					orgIsLoading || isLoadingRequest
						? 'justify-center'
						: 'justify-between'
				)}
			>
				{(orgIsLoading || isLoadingRequest || isFetching) && (
					<div className="w-full flex justify-center items-center h-full">
						<CircularProgress disableShrink />
					</div>
				)}
				{!(orgIsLoading || isLoadingRequest || isFetching) && (
					<>
						<div className="flex flex-col gap-4 w-full">
							<Input
								name="tagName"
								title="Please enter a new tag name:"
								placeholder="Add Tag Name"
								className="!w-full !p-[unset]"
								value={newTagValue}
								onChange={event => setNewTagValue(event.target.value)}
								inputClassName="!p-[unset] !py-1 !px-2"
							/>
							{orgData && orgData.items?.length > 0 && (
								<>
									<fieldset className="flex flex-col">
										<div className="flex gap-2">
											<input
												type="radio"
												id="yes"
												name="nestUnder"
												value="yes"
												checked={selectedOption === 'yes'}
												onChange={handleOptionChange}
												className="border-blue-primary text-blue-primary focus:text-blue-primary"
											/>

											<Typography
												title="Nest Under:"
												fontLeading="15/18"
												type={TypographyTypes.label}
												className="text-gray-9 !font-normal"
												htmlFor="yes"
											/>
										</div>
									</fieldset>

									<DropdownFull
										className="w-full"
										menuClassName="!max-h-[102px]"
										list={orgData.items.map(item => {
											return {
												id: item.id,
												name: item.name
											}
										})}
										item={parentTagType}
										setItem={setParentTagType}
										disabled={selectedOption === 'no'}
									/>
								</>
							)}
						</div>
						<div className=" flex-col sm:flex-row  gap-4 mt-4 flex justify-between items-center w-full">
							<Button
								label="Cancel"
								color="Transparent"
								onClick={() => setOpen(false)}
								className="w-full sm:!min-w-[unset] sm:!w-[48%]"
							/>
							<Button
								label="Save"
								className="w-full sm:!min-w-[unset] sm:!w-[48%]"
								onClick={() =>
									editTagHandler({
										name: newTagValue as string,
										organization_tag_parent_id:
											typeof parentTagType.id === 'string'
												? undefined
												: (parentTagType.id as number)
									})
								}
								disabled={
									newTagValue === '' ||
									(selectedOption === 'yes' &&
										shallowCompare(parentTagType, {
											id: 'parentTag0',
											name: 'Please select a Parent Tag'
										}))
								}
							/>
						</div>
					</>
				)}
			</div>
		</TailwindModal>
	)
}
