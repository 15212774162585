import { Popover } from '@mui/material'
import clsx from 'clsx'
import { Button, Icon, TailwindPopOver } from 'components'
import { OrderPositionModal } from 'components/modal/Content/OrderPositionModal'

import { ContentActionCellProps, Icons } from 'interfaces'
import React, { useState } from 'react'

export const ContentActionCell: React.FC<ContentActionCellProps> = ({
	// value,
	data,
	editHandler,
	copyHandler,
	deactivateHandler,
	deleteHandler,
	pinHandler,
	dataNotification,
	isOrder,
	pinHandlerOrder
	// setOrderOpen,
	// orderOpen
	// drag
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = (): void => {
		setAnchorEl(null)
	}

	const editpushAndContent = (): void => {
		if (data) {
			editHandler(
				data.id_content || data.content_id
					? data.id_content || data.content_id
					: data.id
					? data.id
					: data.partner_organization_content_id,
				data.content_category,
				data.type
			)
		}
	}

	const copyPush = (): void => {
		if (data) {
			const idToUse =
				data?.id_content ?? data?.id ?? data?.partner_organization_content_id

			const contentCategory = data?.content_category

			copyHandler(idToUse, contentCategory)
		}
	}

	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined

	return (
		<div className="flex gap-1 items-center">
			<Button
				iconLeft={Icons.pending}
				className="!w-max !min-w-[unset] !px-1 py-1 !border-none"
				color="Gray2"
				leftIconDivClassName="w-[20px] h-[20px]"
				onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
					handleClick(event)
				}}
			/>
			{!dataNotification && (
				<div className="flex">
					{isOrder && (
						<div
							aria-hidden="true"
							className="w-[20px] h-[20px] cursor-pointer"
							onClick={() => pinHandler()}
						>
							<Icon
								src={data?.is_pinned ? Icons.pushPin : Icons.pushPinActive}
								fillPath
								className={clsx(
									data?.is_pinned ? 'text-blue-primary' : 'text-gray-1'
								)}
							/>
						</div>
					)}
					{!isOrder && (
						<div
							aria-hidden="true"
							className="w-[20px] h-[20px] cursor-pointer"
							onClick={() => pinHandler()}
						>
							<Icon
								src={data?.is_pinned ? Icons.pushPin : Icons.pushPinActive}
								fillPath
								className={clsx(
									data?.is_pinned ? 'text-blue-primary' : 'text-gray-1'
								)}
							/>
						</div>
					)}
					{isOrder && (
						<div>
							<OrderPositionModal
								data={data}
								pinHandlerOrder={pinHandlerOrder}
							/>
						</div>
					)}
				</div>
			)}
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				PaperProps={{ style: { borderRadius: '16px' } }}
			>
				<TailwindPopOver
					handleClose={handleClose}
					title="Actions"
					className="gap-4 w-[278px] h-max"
				>
					<>
						<Button
							label="Edit"
							className="!min-w-[unset] !w-full !py-2"
							iconLeft={Icons.edit}
							color="Transparent"
							onClick={() => editpushAndContent()}
						/>
						<Button
							label="Copy"
							className="!min-w-[unset] !w-full !py-2"
							iconLeft={Icons.copy}
							color="Transparent"
							onClick={() => copyPush()}
						/>
						{data && !dataNotification && (
							<Button
								label={data.status === 'published' ? 'Draft' : 'Published'}
								className="!min-w-[unset] !w-full !py-2"
								iconLeft={
									data.status === 'published'
										? Icons.doNotDisturbOn
										: Icons.check
								}
								color="Gray3"
								onClick={() => {
									setAnchorEl(null)
									deactivateHandler()
								}}
							/>
						)}
						{data && data.status !== 'deleted' && (
							<Button
								label="Delete"
								className="!min-w-[unset] !w-full !py-2"
								iconLeft={Icons.delete}
								color="Gray3"
								onClick={() => deleteHandler()}
							/>
						)}
					</>
				</TailwindPopOver>
			</Popover>
		</div>
	)
}
