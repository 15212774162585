/* eslint-disable @typescript-eslint/no-explicit-any */
import { CircularProgress } from '@mui/material'
import clsx from 'clsx'
import { Checkbox, Pagination, Typography } from 'components'
import { useContextPeopleBatches } from 'context'
import {
	PeopleBatchesTableProps,
	PeopleMyPeopleItem,
	TypographyTypes
} from 'interfaces'
import React, { memo } from 'react'
import { getCustomWidth, PRIMARY_BLUE_COLOR } from 'utils'
import { HeaderTableItem } from '../headerTable'
import { FollowersTagCell } from '../myPeople'

export const PeopleBatchesTable = memo(
	(props: PeopleBatchesTableProps): JSX.Element => {
		const {
			columns,
			customWidth,
			isLoading,
			data,
			onSelectAll,
			total,
			page,
			onPageChanged
		} = props
		const { setSelectedUsers, selectedUsers, setOrganizationFollower } =
			useContextPeopleBatches()

		const onSelectUser = (user: PeopleMyPeopleItem, value: boolean): void => {
			if (value) {
				setSelectedUsers(prev => [...prev, user.user_id])
				setOrganizationFollower(prev => [
					...prev,
					user.organization_follower_id
				])
			} else {
				setSelectedUsers(prev =>
					prev.filter(current => current !== user.user_id)
				)
				setOrganizationFollower(prev =>
					prev.filter(current => current !== user.organization_follower_id)
				)
			}
		}

		const handleSelectAll = (
			event: React.ChangeEvent<HTMLInputElement>
		): void => {
			onSelectAll?.(event)
			const userIds = data?.map(user => user.user_id)
			const orgFollowerIds = data?.map(user => user.organization_follower_id)
			if (event.target.checked) {
				setSelectedUsers(prev => [...prev, ...userIds])
				setOrganizationFollower(prev => [...prev, ...orgFollowerIds])
			} else {
				setSelectedUsers(prev =>
					prev.filter(userId => !userIds.includes(userId))
				)
				setOrganizationFollower(prev =>
					prev.filter(userId => !orgFollowerIds.includes(userId))
				)
			}
		}
		return (
			<div className="flex flex-1 flex-col 2lg:h-[70vh] 2lg:max-h-[70vh] h-[68vh] max-h-[68vh]">
				<div className="flex flex-row items-center mb-3">
					{columns.map((colItem, idx) =>
						colItem.checkbox ? (
							<span
								key={colItem.accessor}
								style={getCustomWidth({
									index: idx,
									total: columns.length,
									customWidth
								})}
							>
								<Checkbox onChange={handleSelectAll} />
							</span>
						) : (
							<HeaderTableItem
								key={colItem.accessor}
								header={colItem.Header}
								table={
									colItem.Header === 'Follower tag'
										? undefined
										: 'peopleBatches'
								}
								column={colItem.accessor}
								justify="left"
								style={getCustomWidth({
									index: idx,
									total: columns.length,
									customWidth
								})}
							/>
						)
					)}
				</div>
				<div className="flex flex-col justify-between pb-2">
					{isLoading ? (
						<div className="flex items-center justify-center w-full flex-1 flex-row min-h-[50vh] 2lg:!min-h-[59vh]">
							<CircularProgress
								style={{ color: PRIMARY_BLUE_COLOR }}
								disableShrink
							/>
						</div>
					) : (
						<div className="flex flex-col gap-3 overflow-y-scroll pb-10 h-[50vh] 2lg:h-[59vh] scrollbar-simple scrollbar-stable  overflow-x-scroll">
							{data.map(user => (
								<div
									key={user.user_id}
									className="flex items-center min-w-[520px]"
								>
									{columns.map((col, idx) => (
										<span
											key={col.accessor}
											className="flex gap-2 line-clamp-1 min-h-[30px]"
											style={getCustomWidth({
												index: idx,
												total: columns.length,
												customWidth
											})}
										>
											{col.checkbox && (
												<Checkbox
													checked={selectedUsers.some(
														userId => userId === user.user_id
													)}
													onChange={event =>
														onSelectUser(user, event.target.checked)
													}
												/>
											)}
											{col.list && (
												<FollowersTagCell
													followerTags={user.followers_tag as any}
												/>
											)}
											{!col.checkbox && !col.list && (
												<Typography
													title={user?.[col.accessor] as string}
													fontLeading="15/18"
													type={TypographyTypes.h3}
													className={clsx(
														'!font-normal text-gray-18 text-ellipsis truncate w-11/12',
														{ capitalize: col.accessor === 'status' }
													)}
												/>
											)}
										</span>
									))}
								</div>
							))}
						</div>
					)}
					<div className="w-full flex items-center justify-end">
						<Pagination
							buttonClassName="!w-8 !h-8 flex items-center justify-center"
							arrowButtonClassName="!w-8 !h-8 flex items-center justify-center"
							totalRecords={total ?? 1}
							onPageChanged={onPageChanged}
							page={page}
							pageLimit={1}
						/>
					</div>
				</div>
			</div>
		)
	}
)
