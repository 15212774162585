import { Button, Table } from 'components'
import {
	Icons,
	NetworkMyFeedsTableItem,
	NetworkMyFeedsTableResponse,
	ParentTableProps
} from 'interfaces'
import React, { useMemo } from 'react'
import { Column } from 'react-table'
import { renderCellDateTime } from 'utils'

export const MyFeedsTable: React.FC<
	ParentTableProps<NetworkMyFeedsTableResponse> & {
		onActionsClick: (followerFeedId: number) => void
	}
> = ({
	data,
	page,
	setPage,
	isLoading,
	onActionsClick,
	onItemsShowingChange,
	itemsShowing
}) => {
	const renderCell = (value: number): JSX.Element => {
		return (
			<Button
				iconLeft={Icons.pending}
				className="!w-max !min-w-[unset] !px-1 py-1 !border-none"
				color="Gray2"
				leftIconDivClassName="w-[20px] h-[20px]"
				onClick={() => onActionsClick(value)}
			/>
		)
	}
	const columns = useMemo(
		(): Column<NetworkMyFeedsTableItem>[] => [
			{
				Header: 'Action',
				disableSortBy: true,
				accessor: 'id',
				Cell: ({ value }) => renderCell(value)
			},
			{ Header: 'Feed Name', accessor: 'name' },
			{
				Header: 'Creation Date',
				sortType: 'datetime',
				accessor: 'created_at',
				Cell: ({ value }) => renderCellDateTime(value)
			},
			{ Header: 'Subscribers', accessor: 'subscribers' },
			{ Header: 'Active Content', accessor: 'active_content' },
			{
				Header: 'Created By',
				sortType: 'datetime',
				accessor: 'created_by'
			}
		],
		[]
	)

	// Se comenta para cuando se vaya a exportar data
	// const handleRowSelect = useCallback<
	// 	(rows: Row<NetworkSubscriptionTableItem>[], selected: boolean) => void
	// >((rows, selected) => {
	// 	const ids = rows.map(row => row.original.follower_feed_id)
	// 	if (selected) {
	// 		setDataSelected?.(prev => [...prev, ...ids])
	// 	} else {
	// 		setDataSelected?.(prev => prev.filter(subId => !ids.includes(subId)))
	// 	}
	// }, [])

	return (
		<Table<NetworkMyFeedsTableItem>
			data={data?.items ?? []}
			page={page}
			isFetching={isLoading}
			noDataMessage="You don't have any feeds"
			onPageChange={setPage}
			columns={columns}
			// onRowSelect={handleRowSelect}
			customWidth={[100, 100, 100, 100, 100, 80]}
			bodyTableClassName="!h-[calc(100vh_-_28rem)]"
			justify="left"
			itemsShowing={itemsShowing}
			onItemsShowingChange={onItemsShowingChange}
			// rowSelected={row =>
			// 	dataSelected?.includes(row.original.follower_feed_id) ?? false
			// }
			pages={data?.size}
			total={data?.total}
			hideSelectionHeader // se coloco porque en diseno no estaba pero se deja por si mas adelante sea necesario
		/>
	)
}
