import clsx from 'clsx'
import { Table } from 'components/index'
import {
	NetworkMessagesItem,
	NetworkMessagesResponse,
	ParentTableProps
} from 'interfaces'
import React, { useMemo } from 'react'
import { Column } from 'react-table'
import { renderCellDateTime } from 'utils'

export const MessageCenterTable: React.FC<
	ParentTableProps<NetworkMessagesResponse>
> = ({ data, page, setPage, className, isLoading }) => {
	const columns = useMemo(
		(): Column<NetworkMessagesItem>[] => [
			{
				Header: '#',
				id: '#',
				accessor: 'id_organization',
				disableSortBy: true
			},
			{
				Header: 'Organization Name',
				accessor: 'organization_name',
				disableSortBy: true
			},
			{ Header: 'Email', accessor: 'email_organization', disableSortBy: true },
			{
				Header: 'Message Date',
				accessor: 'request_date',
				disableSortBy: true,
				Cell: ({ value }) => renderCellDateTime(value)
			},
			{
				Header: 'Message Sent By',
				accessor: 'created_by',
				disableSortBy: true
			},
			{ Header: 'Message', accessor: 'message', disableSortBy: true }
		],
		[data?.total]
	)

	return (
		<div className={clsx(className, 'flex flex-col w-full')}>
			<Table
				data={data?.items ?? []}
				page={page}
				columns={columns as Column[]}
				bodyTableClassName="!h-[calc(100vh_-_498px)]"
				justify="left"
				showPagination
				isFetching={isLoading}
				pages={data?.size}
				total={data?.total}
				customWidth={[2, 20, 10, 15, 15, 12]}
				onPageChange={setPage}
				contentLarg
			/>
		</div>
	)
}
