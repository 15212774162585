import { Popover } from '@mui/material'
import { Button } from 'components'
import { BuildAppsAppNamesActionCellProps, Icons } from 'interfaces'
import React, { useState } from 'react'

export const BuildAppsAppNamesActionCell: React.FC<
	BuildAppsAppNamesActionCellProps
> = ({ data, onClickBranch }) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = (): void => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	const id = open ? 'build-app_actions' : undefined

	return (
		<div className="flex gap-1">
			<Button
				iconLeft={Icons.mobileFriendly}
				className="!w-max !min-w-[unset] !px-1 py-1 !border-none"
				color="Gray3"
				leftIconDivClassName="w-[24px] h-[24px]"
				onClick={handleClick}
			/>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'left'
				}}
				PaperProps={{
					elevation: 2,
					style: {
						borderRadius: '16px',
						borderWidth: 1,
						borderColor: '#BCBCBC'
					}
				}}
			>
				<div className="py-3 px-2.5 flex flex-col gap-3">
					<Button
						label="App Versions"
						className="!min-w-[unset] !w-full !py-2"
						iconLeft={Icons.mobileFriendly}
						color="Transparent"
						linkTo={`/superAdmin/appBuilds/version/${data?.id.toString()}`}
					/>
					<Button
						label="Create Branch"
						className="!min-w-[unset] !w-full !py-2"
						iconLeft={Icons.plus}
						color="Transparent"
						onClick={() => {
							onClickBranch?.(data)
							handleClose()
						}}
					/>
				</div>
			</Popover>
		</div>
	)
}
