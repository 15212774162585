import {
	GET_MY_FEED,
	GET_MY_FEEDS_TABLE,
	GET_NETWORK_PARTNERS_FEED_TABLE,
	GET_NETWORK_PARTNERS_TABLE,
	deleteMyFeeds,
	getMyFeedById,
	getMyFeeds,
	getPartnerFeeds,
	getPartnerNetworkTable,
	patchNetworkPartner,
	patchNetworkSubscription
} from 'api'
import clsx from 'clsx'
import {
	Button,
	DeleteMyFeed,
	DropdownCheckboxGroup,
	FmPartnerListsModal,
	GroupButton,
	Input,
	Layout,
	Message,
	MessageCenterModal,
	MyFeedsTable,
	NetworkActionsModal,
	NetworkMyFeedsActionsModal,
	NewPartnerConfirmModal,
	NewPartnerRequestModal,
	NewSubscriptionRequestModal,
	PartnersNetworkTable,
	SubscriptionNetworkTable,
	Title,
	UnsubcribePartnerModal
} from 'components'

import { NewPartnerFeedsModal } from 'components/modal/myNetwork/newPartnerFeedsModal'
import { useSearchDebounce, useToast } from 'hooks'
import {
	Icons,
	MyNetworkProps,
	NetworkMyFeedsTableResponse,
	NetworkPartnerTableItem,
	NetworkPartnerTableResponse,
	NetworkStatus,
	PartnerFeedsTableResponse
} from 'interfaces'
import React, { useCallback, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'

const SEARCH_MAX_LENGTH = 100

export const MyNetwork: React.FC<MyNetworkProps> = ({
	period,
	setPeriod,
	optionsToRender
}): JSX.Element => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const [partnersSelected, setPartnersSelected] = useState<
		NetworkPartnerTableItem[]
	>([])
	const [partnersSelectedId, setPartnersSelectedId] = useState<number[]>([])

	const [subscriptionsSelected, setSubscriptionsSelected] = useState<number[]>(
		[]
	)
	const filterOptions = useMemo(() => ['accepted', 'declined', 'sent'], [])
	const [filterSelectedOptions, setFilterSelectedOptions] = useState<string[]>(
		[]
	)
	const [partnersTablePageSize, setPartnersTablePageSize] = useState(10)
	const [subscriptionsTablePageSize, setSubscriptionsTablePageSize] =
		useState(10)
	const [myFeedsTablePageSize, setMyFeedsTablePageSize] = useState(10)
	const [partnerActionsSelected, setPartnerActionsSelected] = useState<
		number | null
	>(null)
	const [subscriptionActionsSelected, setSubscriptionActionsSelected] =
		useState<number | null>(null)
	const [isUpdatingStatus, setIsUpdatingStatus] = useState(false)
	const [isUpdatingStatusPartner, setIsUpdatingStatusPartner] = useState(false)

	const { showToast } = useToast()
	const [partnerTablePage, setPartnerTablePage] = useState(1)
	const [subscriptionTablePage, setSubscriptionTablePage] = useState(1)
	const [myFeedsTablePage, setMyFeedsTablePage] = useState(1)
	const [search, setSearch] = useState('')
	const { inputSearch, handleChangeDebounce, resetInput } =
		useSearchDebounce('')
	const [newPartnerModal, setNewPartnerModal] = useState(false)
	const [newSubscriptionModal, setNewSubscriptionModal] = useState(false)
	const [newMyFeedsModal, setNewMyFeedsModal] = useState(false)
	const [messageCenter, setMessageCenter] = useState(false)
	const [fmPartnerLists, setFMPartnerLists] = useState(false)
	const [
		isConfirmingCloseNewPartnerModal,
		setIsConfirmingCloseNewPartnerModal
	] = useState(false)
	const [actionsMyFeeds, setActionsMyFeeds] = useState(false)
	const [confirDeleteFeed, setConfirDeleteFeed] = useState(false)
	const [myFeedsSelected, setMyFeedsSelected] = useState<number | null>(null)
	const isAccepte = partnersSelected.some(
		partner => partner.direction === 'inbound' && partner.status === 'sent'
	)
	const [isLoading, setisLoading] = useState(false)
	const isDisabled = partnersSelected.some(partner => {
		if (partner.direction === 'inbound') {
			return partner.status === 'declined' || partner.status === 'accepted'
		}
		if (partner.direction === 'outbound') {
			return (
				partner.status === 'sent' ||
				partner.status === 'accepted' ||
				partner.status === 'declined'
			)
		}
		return false
	})

	const {
		data: partnerData,
		isLoading: partnerIsLoading,
		refetch: partnersRefetch
	} = useQuery(
		[
			GET_NETWORK_PARTNERS_TABLE,
			{
				userId,
				organizationId,
				page: partnerTablePage,
				search: inputSearch.trim(),
				status: filterSelectedOptions,
				size: partnersTablePageSize
			}
		],
		() =>
			getPartnerNetworkTable(organizationId, {
				page: partnerTablePage,
				search: inputSearch.trim(),
				status: filterSelectedOptions,
				pageSize: partnersTablePageSize
			})
	)

	const {
		data: partnerFeedsData,
		isLoading: partnerFeedsIsLoading,
		refetch: partnerFeedsRefetch
	} = useQuery(
		[
			GET_NETWORK_PARTNERS_FEED_TABLE,
			{
				userId,
				organizationId,
				page: subscriptionTablePage,
				search: inputSearch.trim(),
				status: filterSelectedOptions,
				size: subscriptionsTablePageSize
			}
		],
		() =>
			getPartnerFeeds(organizationId, {
				page: subscriptionTablePage,
				search: inputSearch.trim(),
				status: filterSelectedOptions,
				pageSize: subscriptionsTablePageSize
			})
	)

	const {
		data: myFeedsData,
		refetch: myFeedsRefetch,
		isLoading: myFeedsIsLoading,
		isRefetching
	} = useQuery(
		[
			GET_MY_FEEDS_TABLE,
			{
				userId,
				organizationId,
				page: myFeedsTablePage,
				search: inputSearch.trim(),
				size: myFeedsTablePageSize
			}
		],
		() =>
			getMyFeeds(organizationId, {
				page: myFeedsTablePage,
				search: inputSearch.trim(),
				pageSize: myFeedsTablePageSize
			})
	)

	const { data: myFeedData } = useQuery(
		[
			GET_MY_FEED,
			{
				userId,
				organizationId,
				myFeedsSelected
			}
		],
		() => getMyFeedById(organizationId, myFeedsSelected as number)
	)

	const updatePartnerStatus = useCallback(
		async (partnersIds: number[], status: NetworkStatus) => {
			setisLoading(true)
			setPartnersSelected([])
			try {
				const bodyToSend = {
					organization_partner_ids: partnersIds,
					status
				}
				setIsUpdatingStatus(true)
				const responses = await patchNetworkPartner(organizationId, bodyToSend)
				if (responses?.message) {
					const allReponsesOk = responses?.message
					setPartnerActionsSelected(null)
					showToast(
						allReponsesOk ? 'Success' : 'Error',
						allReponsesOk
							? 'Partner status updated'
							: 'Error updating some partners status',
						allReponsesOk ? 'success' : 'error'
					)
					partnersRefetch()
				}
			} catch (error) {
				console.warn(error)
			} finally {
				setisLoading(false)
			}
		},
		[]
	)

	const updateStatusSubscriptionStatus = useCallback(
		async (followerFeedsIds: number[], status: NetworkStatus) => {
			setIsUpdatingStatusPartner(true)
			const responses = await Promise.all(
				followerFeedsIds.map(followerFeedId =>
					patchNetworkSubscription(organizationId, followerFeedId, {
						status
					})
				)
			)
			const allReponsesOk = responses.every(res => res !== null)
			setIsUpdatingStatusPartner(false)
			setSubscriptionActionsSelected(null)
			showToast(
				allReponsesOk ? 'Success' : 'Error',
				allReponsesOk
					? 'Subscription status updated'
					: 'Error updating some subscriptions status',
				allReponsesOk ? 'success' : 'error'
			)
			partnerFeedsRefetch()
		},
		[]
	)

	const actionsDisabled = (): boolean => {
		const selection = partnerData?.items.filter(partner =>
			partnersSelectedId.includes(partner.id)
		)
		return !(
			selection &&
			selection[0].direction === 'inbound' &&
			selection[0].status === 'sent'
		)
	}
	const disconnectDisabled = (): boolean => {
		const info = partnerData?.items?.filter?.(
			e => e.id === partnerActionsSelected
		)

		const isDisconnect =
			(info && info[0].status === 'disconnect') ||
			(info && info[0].direction === 'outbound' && info[0].status === 'sent') ||
			(info && info[0].direction === 'inbound' && info[0].status === 'sent') ||
			(info &&
				info[0].direction === 'inbound' &&
				info[0].status === 'declined') ||
			(info &&
				info[0].direction === 'outbound' &&
				info[0].status === 'declined')
		return Boolean(isDisconnect)
	}

	const onActionClickEdit = (): void => {
		setActionsMyFeeds(false)
		setNewMyFeedsModal(true)
	}

	const onActionClickDelete = (): void => {
		setActionsMyFeeds(false)
		setConfirDeleteFeed(true)
	}

	const handleDeleteFeed = (): void => {
		deleteMyFeeds(organizationId, myFeedsSelected as number).then(response => {
			if (response) {
				if (response.detail) {
					showToast('Error', response.detail, 'error')
				} else {
					showToast('Success', 'Feeds fed successfully', 'success')
					setConfirDeleteFeed(false)
					setMyFeedsSelected(null)
					myFeedsRefetch()
				}
			}
		})
	}

	const getButtonLabel = (periodType: string): string => {
		if (periodType === 'Partners') return 'New Partner'
		if (periodType === 'Partner Feeds') return 'Subscribe to Partner Feeds'

		return 'Create New Feed'
	}

	const onClickByPeriod = (periodType: string): void => {
		if (periodType === 'Partners') return setNewPartnerModal(true)
		if (periodType === 'Partner Feeds') return setNewSubscriptionModal(true)
		if (periodType === 'My Feeds') return setNewMyFeedsModal(true)

		return undefined
	}

	return (
		<Layout>
			<div className=" 3xl:mx-auto 3xl:my-auto 3xl:w-3/4 mx-auto">
				<div className="flex items-center justify-between rounded-lg p-3  w-full">
					<Title
						icon={Icons.accountTree}
						title="My Network"
						subtitle="Overall performance"
					/>
				</div>
				<div className="flex justify-between md:py-[10px] items-center md:mt-4 gap-4">
					<div
						className={clsx(
							period === 'Partner Feeds' ? 'w-[87%]' : 'md:w-full',
							'flex flex-col '
						)}
					>
						<Input
							name="search"
							placeholder="Search Partners or subscriptions"
							className="w-full !p-[unset] hidden md:block"
							onChange={e => {
								setSearch(e.target.value)
								handleChangeDebounce(e)
							}}
							rightIcon={Icons.cancel}
							rightClick={() => {
								resetInput()
								setSearch('')
							}}
							value={search}
							maxLength={SEARCH_MAX_LENGTH}
							inputClassName="!p-[unset] !py-1 !px-2 "
							inputFocus
							borderFull={false}
							search
						/>
						{search.length >= SEARCH_MAX_LENGTH && (
							<Message
								type="error"
								text={`Maximum ${SEARCH_MAX_LENGTH} characters`}
							/>
						)}
					</div>
					<Button
						label={getButtonLabel(period)}
						className={clsx(
							period === 'Partner Feeds' ? 'md:!w-[300px]' : '!w-[215px]',
							'!min-w-[unset] hidden md:block'
						)}
						onClick={() => onClickByPeriod(period)}
					/>
				</div>
				<div
					className={clsx(
						`flex 2lg:flex-wrap 2lg:flex-row flex-col  justify-between mt-4   `
					)}
				>
					<div>
						<GroupButton
							period={period}
							setPeriod={value => {
								resetInput()
								setSearch('')
								setPartnersSelected([])
								setPeriod(value)
							}}
							options={optionsToRender}
							big
						/>
					</div>

					<Button
						label={getButtonLabel(period)}
						className={clsx(
							period === 'Partner Feeds' ? 'md:!w-[280px]' : 'md:!w-[215px]',
							'md:hidden block mt-4 w-full min-w-[130px]'
						)}
						onClick={() => onClickByPeriod(period)}
					/>
					<div className="flex items-center overflow-x-auto hide-scroll-bar ">
						<div className="flex gap-4 items-center  mt-4">
							{partnersSelected.length !== 0 && isAccepte && (
								<>
									<Button
										label="Accept"
										className="!min-w-[unset]  !py-1 !px-10 w-[135px]"
										color="Primary"
										onClick={() =>
											updatePartnerStatus(
												partnersSelected.map(parnert => parnert.id),
												'accepted'
											)
										}
										disabled={isDisabled}
									/>
									<Button
										label="Decline"
										className="!min-w-[unset]  !py-1 !px-10 w-[135px]"
										color="DangerTransparent"
										onClick={() =>
											updatePartnerStatus(
												partnersSelected.map(parnert => parnert.id),
												'declined'
											)
										}
										disabled={isDisabled}
									/>
								</>
							)}
							{period !== 'Partner Feeds' && period !== 'My Feeds' && (
								<DropdownCheckboxGroup
									options={filterOptions}
									names={filterOptions}
									selectedOptions={filterSelectedOptions}
									setSelectedOptions={setFilterSelectedOptions}
									label="Filter"
									className="!min-w-[unset] !py-1 !px-10 !bg-white"
									color="Transparent"
									iconLeft={Icons.filterAltFill}
								/>
							)}

							<Button
								label="Export"
								className="!min-w-[unset] !py-1 !px-10 !bg-white"
								color="Transparent"
								hidden
							/>

							<Button
								label="Partner Lists"
								className="!min-w-[unset]  !py-1 !px-10 !bg-white w-[185px]"
								color="Transparent"
								onClick={() => setFMPartnerLists(true)}
							/>

							{fmPartnerLists && (
								<FmPartnerListsModal
									open={fmPartnerLists}
									setOpen={setFMPartnerLists}
								/>
							)}
							<Button
								label="All Messages"
								className="!min-w-[unset] !py-1 !px-10 !bg-white w-[182px]"
								color="Transparent"
								onClick={() => setMessageCenter(true)}
							/>
							{messageCenter && (
								<MessageCenterModal
									open={messageCenter}
									setOpen={setMessageCenter}
								/>
							)}
						</div>
					</div>
				</div>
				<div
					className={clsx(
						period === 'Partner Feeds' ? 'md:w-[87%]' : 'w-full',
						'flex flex-col pt-4 '
					)}
				>
					<Input
						name="search"
						placeholder="Search Partners or subscriptions"
						className="w-full !p-[unset] block md:hidden"
						onChange={e => {
							setSearch(e.target.value)
							handleChangeDebounce(e)
						}}
						rightIcon={Icons.cancel}
						rightClick={() => {
							resetInput()
							setSearch('')
						}}
						value={search}
						maxLength={SEARCH_MAX_LENGTH}
						inputClassName="!p-[unset] !py-1 !px-2"
						inputFocus
						borderFull={false}
						search
					/>
					{search.length >= SEARCH_MAX_LENGTH && (
						<Message
							type="error"
							text={`Maximum ${SEARCH_MAX_LENGTH} characters`}
						/>
					)}
				</div>

				<div className="flex gap-5 w-full mt-4 overflow-x-scroll hide-scroll-bar">
					{period === 'Partners' && (
						<PartnersNetworkTable
							data={partnerData as NetworkPartnerTableResponse | undefined}
							isLoading={partnerIsLoading || isLoading}
							page={partnerTablePage}
							setPage={setPartnerTablePage}
							dataSelectedIds={partnersSelected}
							setDataSelectedIds={setPartnersSelected}
							itemsShowing={partnersTablePageSize}
							onItemsShowingChange={setPartnersTablePageSize}
							onActionsClick={id => {
								setPartnerActionsSelected(id)
								// se comento porque en diseno no estaba pero se deja por si mas adelante sea necesario
								// if (!partnersSelected.includes(id))
								// 	setPartnersSelected(prev => [...prev, id])
								setPartnersSelectedId([id])
							}}
						/>
					)}
					{period === 'Partner Feeds' && (
						<SubscriptionNetworkTable
							data={partnerFeedsData as PartnerFeedsTableResponse | undefined}
							isLoading={partnerFeedsIsLoading}
							page={subscriptionTablePage}
							setPage={setSubscriptionTablePage}
							setDataSelected={setSubscriptionsSelected}
							dataSelected={subscriptionsSelected}
							itemsShowing={subscriptionsTablePageSize}
							onItemsShowingChange={setSubscriptionsTablePageSize}
							onActionsClick={id => {
								setSubscriptionActionsSelected(id)
								setSubscriptionsSelected([id])
							}}
						/>
					)}
					{period === 'My Feeds' && (
						<MyFeedsTable
							data={myFeedsData as NetworkMyFeedsTableResponse | undefined}
							isLoading={myFeedsIsLoading || isRefetching}
							page={myFeedsTablePage}
							setPage={setMyFeedsTablePage}
							itemsShowing={myFeedsTablePageSize}
							onItemsShowingChange={setMyFeedsTablePageSize}
							onActionsClick={id => {
								setActionsMyFeeds(true)
								setMyFeedsSelected(id)
							}}
						/>
					)}
				</div>
				{period === 'Partners' && newPartnerModal && (
					<>
						<NewPartnerRequestModal
							open={newPartnerModal}
							onCancel={message =>
								message.length > 0
									? setIsConfirmingCloseNewPartnerModal(true)
									: setNewPartnerModal(false)
							}
							onSubmit={() => {
								setNewPartnerModal(false)
								partnersRefetch()
							}}
						/>
						<NewPartnerConfirmModal
							open={isConfirmingCloseNewPartnerModal}
							setOpen={setIsConfirmingCloseNewPartnerModal}
							onConfirm={() => {
								setIsConfirmingCloseNewPartnerModal(false)
								setNewPartnerModal(false)
							}}
						/>
					</>
				)}
				{period === 'Partner Feeds' && newSubscriptionModal && (
					<NewSubscriptionRequestModal
						open={newSubscriptionModal}
						setOpen={setNewSubscriptionModal}
						onSubmit={() => partnerFeedsRefetch()}
					/>
				)}
				{period === 'Partners' && partnerActionsSelected && (
					<NetworkActionsModal
						open={Boolean(partnerActionsSelected)}
						onClose={() => setPartnerActionsSelected(null)}
						isLoading={isUpdatingStatus}
						onActionClick={status =>
							partnerActionsSelected &&
							updatePartnerStatus(partnersSelectedId, status)
						}
						acceptHidden={actionsDisabled()}
						declineHidden={actionsDisabled()}
						disconnectHidden={disconnectDisabled()}
					/>
				)}
				{period === 'My Feeds' && actionsMyFeeds && (
					<NetworkMyFeedsActionsModal
						open={actionsMyFeeds}
						onClose={() => {
							setActionsMyFeeds(false)
							setMyFeedsSelected(null)
						}}
						isLoading={isUpdatingStatus}
						onActionClickEdit={onActionClickEdit}
						onActionClickDelete={onActionClickDelete}
					/>
				)}
				{period === 'Partner Feeds' && subscriptionActionsSelected && (
					<UnsubcribePartnerModal
						open={Boolean(subscriptionActionsSelected)}
						onClose={() => setSubscriptionActionsSelected(null)}
						isLoading={isUpdatingStatusPartner}
						onActionClick={status =>
							subscriptionActionsSelected &&
							updateStatusSubscriptionStatus(subscriptionsSelected, status)
						}
					/>
				)}

				{period === 'My Feeds' && newMyFeedsModal && (
					<NewPartnerFeedsModal
						data={myFeedData}
						open={newMyFeedsModal}
						setOpen={() => {
							setNewMyFeedsModal(false)
							setMyFeedsSelected(null)
						}}
						refetch={myFeedsRefetch}
					/>
				)}

				{period === 'My Feeds' && confirDeleteFeed && (
					<DeleteMyFeed
						open={confirDeleteFeed}
						setOpen={setConfirDeleteFeed}
						onClickConfirm={handleDeleteFeed}
					/>
				)}
			</div>
		</Layout>
	)
}
