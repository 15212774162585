import { API } from 'api'
import {
	CreateContentResponse,
	DetailNotification,
	EditContentListResponse,
	PostCreateNotification,
	ResponseDuplicateNotification
} from 'interfaces'

export const postCreateNotification = async (
	organizationId: number,
	body: PostCreateNotification
): Promise<CreateContentResponse | null> => {
	let res = null as CreateContentResponse | null
	await API()
		.post(`/notifications/organizations/${organizationId}`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				if (error.response.data.errors) {
					res = { detail: error.response.data.errors[0].msg }
				} else {
					res = { detail: error.response.data.detail.error }
				}
			}
		)
	return res
}
export const postEditNotification = async (
	organizationId: number,
	body: PostCreateNotification,
	notificationId?: number
): Promise<CreateContentResponse | null> => {
	let res = null as CreateContentResponse | null
	await API()
		.patch(
			`/notifications/${notificationId}/organizations/${organizationId}`,
			body
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				if (error.response.data.errors) {
					res = { detail: error.response.data.errors[0].msg }
				} else {
					res = { detail: error.response.data.detail.error }
				}
			}
		)
	return res
}

export const getDetailNotification = async (
	organizationId: number,
	contentId: number | undefined
): Promise<DetailNotification | undefined> => {
	let res = undefined as DetailNotification | undefined

	if (contentId) {
		await API()
			.get(`/notifications/${contentId}/organizations/${organizationId}`)
			.then(
				async response => {
					res = response.data
				},
				error => {
					console.error(error)
					res = error.response.data
				}
			)
	}
	return res
}

export const deleteNotification = async (
	organizationId: number,
	contentID: number
): Promise<EditContentListResponse | undefined> => {
	let res = undefined as EditContentListResponse | undefined

	await API()
		.delete(`/notifications/${contentID}/organizations/${organizationId}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)

	return res
}

export const duplicateNotification = async (
	organizationId: number,
	contentID: number
): Promise<ResponseDuplicateNotification | null> => {
	let res = null as ResponseDuplicateNotification | null
	await API()
		.post(
			`/notifications/duplicate/${contentID}/organizations/${organizationId}`
		)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}
