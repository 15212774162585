import { CircularProgress } from '@mui/material'
import { GET_USER_TAGS, getUserTags, updateUserInfo, updateUserTags } from 'api'
import clsx from 'clsx'
import {
	Button,
	Icon,
	Input,
	InputPhoneDoble,
	MyPeopleParent,
	TailwindModal,
	Typography
} from 'components'
import { useToast } from 'hooks'
import {
	EditFollowerFormInputs,
	GenericModalProps,
	Icons,
	PeopleMyPeopleItem,
	TypographyTypes
} from 'interfaces'
import React, { useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'

export const MyPeopleEditModal: React.FC<
	GenericModalProps<PeopleMyPeopleItem>
> = ({ open, setOpen, data, refetch }) => {
	const cookies = new Cookies()
	const organizationId = cookies.get('organization_id')
	const userId = cookies.get('userId')
	const {
		register,
		handleSubmit,
		setValue,
		watch,
		formState: { isValid },
		trigger
	} = useForm<EditFollowerFormInputs>({
		mode: 'all'
	})
	const [requestIsLoading, setRequestIsLoading] = useState(false)
	const [myTagsDataSelected, setMyTagsDataSelected] = useState<number[]>([])
	const [selectAll, setSelectAll] = useState(false)
	const { showToast } = useToast()

	const phoneNumberCode = watch('phone_code')
	const phoneNumber = watch('phone_number')

	const rules = useMemo(() => {
		return {
			name: {
				required: { value: true }
			},
			email: {
				required: { value: true }
			},
			password: {
				required: false,
				minLength: {
					value: 8,
					message: 'Password must be at least 8 characters long'
				},
				pattern: {
					value: /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#<>+_{}.])/,
					message:
						'Password should include a combination of uppercase and lowercase letters, numbers, and symbols'
				}
			},
			member_id: {
				required: false,
				minLength: {
					value: 3,
					message: 'Minimum 3 characters'
				}
			},
			phone_code: {
				required: { value: false }
			},
			phone_number: {
				required: { value: false }
			}
		}
	}, [])
	const { data: tagsData, isLoading: tagsIsLoading } = useQuery(
		[GET_USER_TAGS, userId, organizationId, data?.user_id],
		() => getUserTags(organizationId, data?.user_id)
	)

	const handleSelectAll = (): void => {
		if (tagsData) {
			const aux: number[] = []
			tagsData?.organization_follower_parent_tags?.forEach(tagParent => {
				tagParent.tags_child.forEach(tagChild => {
					if (tagChild?.id) {
						aux.push(tagChild.id)
					}
				})
			})

			tagsData?.organization_follower_independent_tags?.forEach(
				tagIndependent => {
					if (tagIndependent?.id) {
						aux.push(tagIndependent.id)
					}
				}
			)

			setSelectAll(true)
			setMyTagsDataSelected(aux)
		}
	}

	const handleSelected = (itemID: number): void => {
		const newDataSelected = myTagsDataSelected.slice()
		const dataIndex = myTagsDataSelected.indexOf(itemID)
		if (dataIndex === -1) {
			newDataSelected.push(itemID)
		} else {
			newDataSelected.splice(dataIndex, 1)
		}
		setMyTagsDataSelected(newDataSelected)
	}
	const editUserInfoHandler: SubmitHandler<
		EditFollowerFormInputs
	> = async userInfo => {
		setRequestIsLoading(true)

		let phoneNumberTotal = ''
		if (
			userInfo.phone_code !== undefined &&
			userInfo.phone_number !== undefined
		) {
			phoneNumberTotal = `${userInfo.phone_code}-${userInfo.phone_number}`
		}

		await updateUserInfo(Number(data?.user_id), {
			email: userInfo.email,
			full_name: userInfo.name,
			phone_number: phoneNumberTotal,
			postal_code: userInfo.zip,
			new_password: userInfo.new_password,
			member_id: userInfo.member_id
		})
		updateUserTags(organizationId, Number(data?.user_id), {
			tags_id: myTagsDataSelected
		}).then(response => {
			if (response) {
				showToast('Success', "Follower's info updated successfully.", 'success')
				if (refetch) {
					refetch()
				}
				setOpen(false)
			}
		})
	}

	useEffect(() => {
		if (tagsData?.organization_follower_independent_tags) {
			let aux = 0
			tagsData?.organization_follower_parent_tags?.forEach(tagParent => {
				tagParent.tags_child.forEach(() => {
					aux += 1
				})
			})
			const total = aux + tagsData.organization_follower_independent_tags.length
			if (myTagsDataSelected.length === total) {
				setSelectAll(true)
			} else {
				setSelectAll(false)
			}
		}
	}, [myTagsDataSelected, tagsData])

	useEffect(() => {
		if (data) {
			const codePhoneNumberData = data?.phone_number?.split('-')[0] || ''
			const phoneNumberData = data?.phone_number?.split('-')[1] || ''
			const phoneNumberData2 = data?.phone_number?.split('-')[2] || ''

			setValue('name', data?.full_name)
			setValue('email', data?.email)
			setValue('phone_code', codePhoneNumberData)
			setValue(
				'phone_number',
				data.phone_number !== '' ? `${phoneNumberData} ${phoneNumberData2}` : ''
			)
			setValue('zip', data?.postal_code)
			setValue('new_password', data?.new_password)
			setValue('member_id', data?.member_id)
		}
	}, [data])

	useEffect(() => {
		if (tagsData) {
			const independent = tagsData.organization_follower_independent_tags
				?.filter(tag => tag.tagged === true)
				.map(item => item.id) as number[]
			const child = [] as number[]
			tagsData.organization_follower_parent_tags?.forEach(tagParent => {
				tagParent.tags_child.forEach(tagChild => {
					if (tagChild.tagged === true && tagChild.id) {
						child.push(tagChild.id)
					}
				})
			})
			setMyTagsDataSelected([...independent, ...child])
		}
	}, [tagsData])

	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			title="Edit Follower Information"
			className="w-[474px] h-[650px] sm:h-[650px] md:h-[680px] lg:h-[700px] p-4 rounded-[16px] overflow-y-auto"
		>
			{!(tagsIsLoading || requestIsLoading) ? (
				<form
					className="w-full mt-4"
					onSubmit={handleSubmit(editUserInfoHandler)}
					id="form-container"
				>
					<div className="border-blue-primary border-solid border-[1px] rounded-lg p-[16px] ">
						<div className="flex">
							<Icon src={Icons.accountCircle} className="w-[40px] h-[40px]" />
							<div className="flex flex-col pl-2">
								<Typography
									title="Edit or complete"
									fontLeading="15/18"
									type={TypographyTypes.p}
									className="text-blue-primary"
								/>
								<Typography
									title="Personal Information:"
									fontLeading="15/22"
									type={TypographyTypes.p}
								/>
							</div>
						</div>
						<div className="pl-1.5 pt-2">
							<Input
								name="name"
								title="Name:"
								placeholder="Follower Name"
								register={register}
								rules={rules.name}
								className="!w-full !p-[unset]"
								inputClassName="!p-[unset] !py-1"
								inputFocus
								titleClassName="pt-2"
								autoComplete="new-name"
							/>
							<Input
								name="email"
								title="Email:"
								placeholder="Follower Email"
								register={register}
								rules={rules.email}
								className="!w-full !p-[unset]"
								inputClassName="!p-[unset] !py-2"
								inputFocus
								titleClassName="pt-2"
								autoComplete="new-email"
							/>
							<Input
								name="member_id"
								title="Unique Id:"
								placeholder="ID# (e.g. Member or Badge)"
								register={register}
								rules={rules.member_id}
								className="!w-full !p-[unset]"
								inputClassName="!p-[unset] !py-2"
								inputFocus
								titleClassName="pt-2"
								autoComplete="new-member-id"
							/>
							<InputPhoneDoble
								name="phone_code"
								name2="phone_number"
								title="Phone Number:"
								watchValue={phoneNumber}
								watchValueCode={phoneNumberCode}
								setValue={setValue}
								register={register}
								rules={rules.phone_code}
								rules2={rules.phone_number}
							/>
							<Input
								name="zip"
								title="Zip Code:"
								placeholder="Follower Zip Code"
								register={register}
								className="!w-full !p-[unset]"
								inputClassName="!p-[unset] !py-2"
								inputFocus
								titleClassName="pt-2"
								maxLength={5}
								autoComplete="new-zip"
							/>
							<Input
								name="new_password"
								title="Update Password:"
								placeholder="Follower password"
								rules={rules.password}
								type="password"
								register={register}
								className="!w-full !p-[unset]"
								inputClassName="!p-[unset] !py-2"
								inputFocus
								titleClassName="pt-2"
								showEye
								minLength={8}
								autoComplete="new-new_password_old"
							/>
							<p className="text-gray-1 font-normal leading-normal text-xs pt-2">
								Ensure your password is at least 8 characters long, with at
								least 1 uppercase letter, 1 number, and 1 special character.
							</p>
						</div>
					</div>
					<div className="w-full flex flex-col gap-2 mt-4">
						<div className="flex flex-col w-full border-blue-primary border-solid border-[1px] rounded-lg p-[16px] min-h-max">
							<div className="flex border-b-[0.5px] border-gray-3 pb-3">
								<Icon
									src={Icons.secondaryPhone}
									className="w-[40px] h-[40px]"
								/>
								<div className="flex flex-col pl-2">
									<Typography
										title="Select"
										fontLeading="15/18"
										type={TypographyTypes.p}
										className="text-blue-primary"
									/>
									<Typography
										title="User´s tags in the platform:"
										fontLeading="15/22"
										type={TypographyTypes.p}
									/>
								</div>
							</div>
							{tagsData?.organization_follower_parent_tags?.length && (
								<div className="border-b-[0.5px] border-gray-3 pb-3">
									{tagsData?.organization_follower_parent_tags
										?.sort((a, b) =>
											a.name && b.name ? a.name.localeCompare(b.name) : 0
										)
										.map(tagParent => {
											return (
												<MyPeopleParent
													key={`${tagParent.id}_${tagParent.name}`}
													tagParent={tagParent}
													myTagsDataSelected={myTagsDataSelected}
													handleSelected={id => {
														handleSelected(id)
														trigger('roles')
													}}
												/>
											)
										})}
								</div>
							)}
							<div className="mt-[7px] flex flex-col w-full hide-scroll-bar min-h-max gap-2">
								{tagsData?.organization_follower_independent_tags
									?.slice()
									.sort((a, b) =>
										a.name && b.name ? a.name.localeCompare(b.name) : 0
									)
									.map(tagIndependent => {
										return (
											<div
												className="flex items-center justify-between gap-1 p-1 w-full "
												key={`${tagIndependent.id}_${tagIndependent.name}`}
											>
												<div className="flex w-full gap-2 items-center w-[50%]">
													<input
														type="checkbox"
														name="roles[]"
														className="h-4 w-4 border-gray-300 text-blue-primary focus:ring-transparent-default cursor-pointer "
														checked={myTagsDataSelected.includes(
															tagIndependent.id as number
														)}
														onChange={() => {
															handleSelected(tagIndependent.id as number)
															trigger('roles')
														}}
													/>
													<Typography
														className="text-black-1"
														title={
															tagIndependent.name ? tagIndependent.name : ''
														}
														fontLeading="15/18"
														type={TypographyTypes.p}
													/>
													{!tagIndependent.is_visible && (
														<Typography
															className="text-gray-1"
															title="(Hidden)"
															fontLeading="10/12"
															type={TypographyTypes.p}
														/>
													)}
												</div>
												<div className="w-[50%] text-xs flex-nowrap ">
													{tagIndependent.required_approval &&
														tagIndependent.is_approved === null && (
															<div className="flex items-center">
																<Typography
																	title="(Approval required)"
																	fontLeading="12/16"
																	type={TypographyTypes.p}
																/>
																{tagIndependent.tagged && (
																	<Icon
																		src={Icons.historyToggleOff}
																		fillPath
																		className="w-6 h-6 ml-2"
																	/>
																)}
															</div>
														)}
													{tagIndependent.tagged &&
														tagIndependent.required_approval &&
														tagIndependent.is_approved && (
															<div className="flex items-center">
																<Typography
																	title="Approved"
																	fontLeading="12/16"
																	type={TypographyTypes.p}
																/>
																<Icon
																	src={Icons.checkCircleTags}
																	fillPath
																	className="w-6 h-6 ml-2 text-green-tagAcceted"
																/>
															</div>
														)}
													{tagIndependent.tagged &&
														tagIndependent.required_approval &&
														tagIndependent.is_approved === false && (
															<div className="flex items-center">
																<Typography
																	title="Rejected"
																	fontLeading="12/16"
																	type={TypographyTypes.p}
																/>
																<Icon
																	src={Icons.errorTags}
																	fillPath
																	className="w-6 h-6 ml-2 text-red-tagRejected"
																/>
															</div>
														)}
												</div>
											</div>
										)
									})}
							</div>
						</div>
					</div>
					<div className="w-full flex flex-col justify-end gap-2 mt-10 sm:mt-32 sticky bottom-[-16px] bg-white z-10 p-4">
						{!selectAll && (
							<div className="w-[100%] ">
								<Button
									label="Select All"
									color="Transparent"
									onClick={() => handleSelectAll()}
									className="w-full sm:!min-w-[unset]"
								/>
							</div>
						)}
						<div
							className={clsx(
								'flex-col gap-2 flex justify-between sm:flex-row',
								{ 'mt-5': selectAll }
							)}
						>
							<Button
								label="Cancel"
								color="Transparent"
								onClick={() => setOpen(false)}
								className="w-full sm:!min-w-[unset] sm:!w-[48%]"
							/>
							<Button
								label="Save"
								type="submit"
								className="w-full sm:!min-w-[unset] sm:!w-[48%]"
								disabled={!isValid}
							/>
						</div>
					</div>
				</form>
			) : (
				<div className="flex items-center justify-center w-full h-[calc(100%_-_29px)]">
					<CircularProgress disableShrink />
				</div>
			)}
		</TailwindModal>
	)
}
