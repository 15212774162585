import { API } from 'api/config'
import { getUploadImageConten } from 'api/get'
import { AxiosResponse } from 'axios'
import { Document, UploadDocumentCreedentialsResponse } from 'interfaces'
import { v4 as uuidv4 } from 'uuid'
import { getFormData } from '../myOrganizations'

export const uploadFile = async (
	slug: string,
	file: File
): Promise<Document | null> => {
	try {
		const bucket = await getUploadImageConten(slug)
		const s3Url = bucket?.url
		const { data }: AxiosResponse<UploadDocumentCreedentialsResponse> =
			await API().get(`/upload/documents?slug=${slug}`)
		const fileExtension = file.name.split('.').pop()
		const fileName = `${uuidv4()}.${fileExtension}`
		// eslint-disable-next-line no-template-curly-in-string
		const filePath = data.fields.key.replace('${filename}', fileName)
		const body = {
			...data.fields,
			key: filePath,
			file
		}
		const formdataField = getFormData(body)
		await API({ customUrl: s3Url, authorization: true }).post(
			'',
			formdataField,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		)
		return {
			document_url: `${s3Url}${filePath}`,
			name: fileName,
			type_document: fileExtension ?? ''
		}
	} catch (error) {
		console.error(error)
		return null
	}
}
