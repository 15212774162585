import clsx from 'clsx'
import { Typography } from 'components'
import {
	BarMultiSelectData,
	BarMultiSelectProps,
	TypographyTypes
} from 'interfaces'
import React, { useEffect, useState } from 'react'

export const BarMultiSelect: React.FC<BarMultiSelectProps> = ({
	list,
	listSeleted,
	setListSeleted
}): JSX.Element => {
	const [itemsSelected, setItemsSelected] = useState<number[]>([])

	const handleClick = (itemList: BarMultiSelectData): void => {
		let newItems = itemsSelected
		let newValue = listSeleted
		if (itemsSelected.includes(itemList.id)) {
			newItems = itemsSelected.filter(item => item !== itemList.id)
			newValue = listSeleted.filter(item => item !== itemList.value)
		} else {
			newItems.push(itemList.id)
			newValue.push(itemList.value)
		}
		setItemsSelected([...newItems])
		setListSeleted([...newValue])
	}

	useEffect(() => {
		listSeleted.forEach(item => {
			const aux = list.find(itemList => itemList.value === item)
			if (aux) {
				const newItems = itemsSelected
				newItems.push(aux.id)
				setItemsSelected([...newItems])
			}
		})
	}, [listSeleted])

	return (
		<div
			key={itemsSelected.length}
			className="bg-gray-9 rounded-2xl px-4 py-2 flex items-center gap-4 mt-2"
		>
			{list.map(item => (
				<button
					key={item.id}
					type="button"
					className={clsx(
						'min-w-[40px] rounded-full py-2',
						itemsSelected.includes(item.id) && 'bg-blue-primary'
					)}
					onClick={() => handleClick(item)}
				>
					<Typography
						title={item.label}
						className="text-white !font-medium"
						type={TypographyTypes.span}
						fontLeading="16/22"
					/>
				</button>
			))}
		</div>
	)
}
