import {
	Button,
	Icon,
	Input,
	InputPhoneDoble,
	Typography,
	handleValid
} from 'components'
import {
	GroupComponentsLinkProps,
	Icons,
	PhoneNumbersItem,
	TypographyTypes
} from 'interfaces'
import React, { useEffect } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { configGenaral } from 'utils'
import {
	PropsComponentsMyOrganization,
	onChangeFieldProps,
	typeFieldPhone
} from './interfaceSlide.type'

const rules = {
	title: {
		required: { value: true, message: 'Is required' }
	},
	phone: {
		required: { value: true, message: 'Is required' }
	},
	phoneCode: {
		required: { value: true, message: 'code is required' }
	}
}

const GroupComponents: React.FC<
	GroupComponentsLinkProps & onChangeFieldProps
> = ({
	register,
	index,
	deleteComponent,
	setValue,
	watch,
	onChangeField,
	errorCustom
}): JSX.Element => {
	const watchTitle = watch?.(`phones.${index}.title`)
	const watchPhone = watch?.(`phones.${index}.phone_number`)
	const watchCode = watch?.(`phones.${index}.code`)

	return (
		<div className="flex flex-col w-full gap-6 mb-6">
			<Input
				name={`phones.${index}.title`}
				title="Title"
				register={register}
				rules={rules.title}
				error={errorCustom?.title}
				borderFull
				limit
				cantLimit={configGenaral.maxTitleLength}
				watchValue={watchTitle}
				setValueInput={setValue}
				onChangeCustom={e => {
					const { value } = e.target
					onChangeField({
						title: value,
						phone_number: watchPhone,
						code: watchCode
					})
				}}
			/>

			<InputPhoneDoble
				title="Phone"
				name={`phones.${index}.code`}
				name2={`phones.${index}.phone_number`}
				register={register}
				rules={rules.phoneCode}
				rules2={rules.phone}
				watchValueCode={watchCode}
				watchValue={watchPhone}
				error={errorCustom?.code}
				error2={errorCustom?.phone_number}
				setValue={(nameInput, value) => {
					setValue?.(nameInput, value)
				}}
				onChangeCustom={e => {
					const { value } = e.target
					onChangeField({
						title: watchTitle,
						phone_number: watchPhone,
						code: value
					})
				}}
				onChangeCustom2={e => {
					const { value } = e.target
					onChangeField({
						title: watchTitle,
						phone_number: value,
						code: watchCode
					})
				}}
			/>
			{index > 0 && (
				<div
					aria-hidden="true"
					className=" flex gap-2 cursor-pointer"
					onClick={() => deleteComponent(index)}
				>
					<Icon
						src={Icons.delete}
						className="w-[16px] h-[18px] text-gray-3"
						fillPath
					/>
					<Typography
						title="Remove Phone"
						className="text-gray-3"
						fontLeading="15/18"
						type={TypographyTypes.p}
					/>
				</div>
			)}
		</div>
	)
}

export const SlidePhones: React.FC<PropsComponentsMyOrganization> = ({
	setErrorForm,
	active,
	pos,
	watch: watchParent,
	setValue: setValueForm,
	setFieldError
}): JSX.Element => {
	const data = watchParent().tabs?.[active]?.components?.[pos].component_value

	const {
		control,
		register,
		watch,
		setValue,
		formState: { errors, isValid }
	} = useForm<typeFieldPhone>({
		mode: 'onChange',
		defaultValues: {
			phones: data?.phones ?? [
				{
					title: '',
					phone_number: '',
					code: ''
				}
			]
		}
	})

	const { fields, remove, append } = useFieldArray({
		control,
		name: 'phones'
	})

	const nameType = `phone${active}-${pos}`

	useEffect(() => {
		handleValid(nameType, isValid, setFieldError)
		setErrorForm?.(!isValid)
	}, [isValid])

	return (
		<div className="flex flex-col justify-between overflow-hidden p-2">
			<div className="flex flex-col w-full overflow-y-auto max-h-[calc(100vh-130px)]">
				<div className="mr-2">
					{fields.map((component, index) => {
						return (
							<Controller
								key={`phones-${component.id}`}
								control={control}
								name={`phones.${index}`}
								render={() => {
									return (
										<GroupComponents
											register={register}
											index={index}
											errors={errors}
											deleteComponent={remove}
											watch={watch}
											setValue={setValue}
											onChangeField={() => {
												const resultData = watch().phones?.[index]

												setValueForm(
													`tabs.${active}.components.${pos}.component_value.phones.${index}`,
													resultData as PhoneNumbersItem
												)
											}}
											errorCustom={errors?.phones?.[index]}
										/>
									)
								}}
							/>
						)
					})}
				</div>
				<div className="w-full mt-6">
					<Button
						type="button"
						label="Add"
						small
						onClick={() =>
							append({
								title: '',
								phone_number: '',
								code: ''
							})
						}
						iconLeft={Icons.plus}
					/>
				</div>
			</div>
		</div>
	)
}
