import { API } from 'api'
import { DeleteTagsRequest, MessageResponse } from 'interfaces'

export const deleteStaticList = async (
	id: number
): Promise<MessageResponse | null> => {
	let res = null
	await API()
		.delete(`/people/static_list/${id.toString()}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const deleteDinamicList = async (
	id: number
): Promise<MessageResponse | null> => {
	let res = null
	await API()
		.delete(`/people/dynamic_list/${id}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const deleteTags = async (
	id: number,
	body: DeleteTagsRequest
): Promise<MessageResponse | null> => {
	let res = null
	await API()
		.delete(`/people/tags/organization/${id.toString()}`, { data: body })
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}
