export interface ItemRadios {
	id: string
	title: string
	value: string
}

export const endShedules = [
	{ id: '1', title: 'Never', value: 'never' },
	{ id: '2', title: 'On', value: 'specific_day' },
	{ id: '3', title: 'After', value: 'after_ocurrence' }
] as ItemRadios[]

export const itemSchedule = [
	{ id: '4', title: 'Publish Now', value: 'itemSchedule' },
	{ id: '1', title: 'Schedule', value: 'itemSchedule' },
	{ id: '2', title: 'Recurrence', value: 'itemSchedule' }
] as ItemRadios[]

export const itemScheduleEdit = [
	{ id: '4', title: 'Publish Now', value: 'itemSchedule' },
	{ id: '3', title: 'Without recurrence or schedule', value: 'itemSchedule' },
	{ id: '1', title: 'Schedule', value: 'itemSchedule' },
	{ id: '2', title: 'Recurrence', value: 'itemSchedule' }
] as ItemRadios[]

export const itemRecurrecnceMoths = [
	{ id: '1', title: 'The 5 of each month', value: 'itemRecurrecnce' },
	{ id: '2', title: 'First day of the month', value: 'itemRecurrecnce' }
] as ItemRadios[]

export const itemScheduleEvent = [
	{ id: '1', title: 'Event Schedule', value: 'eventSchedule' },
	{ id: '2', title: 'Event Recurrence', value: 'eventSchedule' }
] as ItemRadios[]

export const itemRecurrecnceMothsEvent = [
	{ id: '1', title: 'The 5 of each month', value: 'eventRecurrecnce' },
	{ id: '2', title: 'First day of the month', value: 'eventRecurrecnce' }
] as ItemRadios[]

export const itemAudience = [
	{
		id: '1',
		title: 'Publish to all of My People',
		value: 'PublishAllMyPeople'
	},
	{
		id: '2',
		title: 'Publish to a subset of My People',
		value: 'PublishAllMyPeople'
	}
] as ItemRadios[]

export const itemPushNotification = [
	{ id: '3', title: 'Publish Now', value: 'publishNow' },
	{ id: '1', title: 'Push Schedule', value: 'pushSchedule' },
	{ id: '2', title: 'Push Recurrence', value: 'pushRecurrence' }
] as ItemRadios[]

export const itemDefineLocation = [
	{ id: '0', title: 'Without Any Location', value: 'withoutAnyLocation' },
	{
		id: '1',
		title: 'Add to a previously set location',
		value: 'AddToAPreviouslySetLocation'
	},
	{
		id: '2',
		title: 'Set an address to this content item',
		value: 'SetAnAddressToThisContentItem'
	}
] as ItemRadios[]

export const itemDefineLocationCreate = [
	{
		id: '1',
		title: 'Add to a previously set location',
		value: 'AddToAPreviouslySetLocation'
	},
	{
		id: '2',
		title: 'Set an address to this content item',
		value: 'SetAnAddressToThisContentItem'
	}
] as ItemRadios[]
