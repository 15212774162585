import { API } from 'api/config'
import { SingigResponse, SinginRequest } from 'interfaces'

export const singin = async (
	body: SinginRequest
): Promise<SingigResponse | null> => {
	let res = null as SingigResponse | null
	await API()
		.post(`/auth/signin`, body)
		.then(response => {
			res = response.data
		})
		.catch(error => {
			if (error.response.data.errors) {
				res = { detail: error.response.data.errors[0].msg }
			} else {
				res = { detail: error.response.data.detail.error }
			}
		})
	return res
}
