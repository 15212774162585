import { CircularProgress } from '@mui/material'
import {
	GET_PEOPLE_EDIT_STATIC_LIST_OPTIONS,
	editStaticList,
	getEditStaticListOptions
} from 'api'
import { Button, Input, TailwindModal, Typography } from 'components'
import { useDebouncedSearch, useToast } from 'hooks'
import {
	GenericModalProps,
	Icons,
	PeopleMyListsItem,
	StaticListRequest,
	TypographyTypes
} from 'interfaces'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'
import { configGenaral } from 'utils'

export const EditStaticListModal: React.FC<
	GenericModalProps<PeopleMyListsItem>
> = ({ data: parentData, open, setOpen, refetch }) => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const [newListValue, setNewListValue] = useState(parentData?.name)
	const [dataSelected, setDataSelected] = useState<number[]>([])
	const [dataServer, setDataServer] = useState<number[]>([])
	const [selectAll, setSelectAll] = useState(false)
	const [isDisabled, setIsDisabled] = useState(true)
	const [isLoadingPost, setIsLoadingPost] = useState(false)
	const { register, resetField, setValue, watch } = useForm<{
		search: string
	}>({
		mode: 'onChange'
	})
	const watchSearch = watch('search')
	const { debouncedSearch, resetSearch } = useDebouncedSearch(watchSearch)
	const { showToast } = useToast()
	const { data, isLoading, isRefetching } = useQuery(
		[
			GET_PEOPLE_EDIT_STATIC_LIST_OPTIONS,
			parentData?.id as number,
			userId,
			organizationId,
			debouncedSearch
		],
		() =>
			getEditStaticListOptions(
				organizationId,
				parentData?.id as number,
				debouncedSearch
			),
		{
			onSuccess: response => {
				if (!dataSelected.length) {
					const selected = response?.items.filter(item => item.present_list)
					const selectedIds =
						selected?.map(user => user.organization_follower_id) ?? []
					setDataServer(selectedIds)
					if (!debouncedSearch) {
						setDataSelected(selectedIds)
					}
				}
			},
			refetchOnWindowFocus: false
		}
	)

	const editStaticListHandler = useCallback(
		async (
			orgId: number,
			listId: number,
			body: StaticListRequest
		): Promise<void> => {
			setIsLoadingPost(true)
			editStaticList(orgId, listId, body)
				.then(response => {
					if (response) {
						showToast('Success', 'Static list updated successfully.', 'success')
						if (refetch) {
							refetch()
						}
						setDataSelected([])
						setSelectAll(false)
						setOpen(false)
					}
				})
				.catch(() => {
					showToast('Error', 'Error updating static list', 'error')
					setOpen(false)
					setIsLoadingPost(false)
					setDataSelected([])
					setSelectAll(false)
				})
		},
		[]
	)
	const handleSelectAll = (): void => {
		if (data) {
			if (selectAll) {
				setDataSelected([])
				setSelectAll(false)
			} else {
				setDataSelected(
					data.items.map(option => option.organization_follower_id)
				)
				setSelectAll(true)
			}
		}
	}

	const handleSelected = (itemID: number): void => {
		const newDataSelected = dataSelected.slice()
		const dataIndex = dataSelected.indexOf(itemID)
		if (dataIndex === -1) {
			newDataSelected.push(itemID)
		} else {
			newDataSelected.splice(dataIndex, 1)
		}
		setDataSelected(newDataSelected)
	}

	useEffect(() => {
		if (data) {
			if (dataSelected.length === data.items.length) {
				setSelectAll(true)
			} else {
				setSelectAll(false)
			}
		}
	}, [data, dataSelected])

	useEffect(() => {
		if (dataSelected) {
			dataSelected.sort()
			dataServer.sort()
			const isEqual =
				dataSelected.length === dataServer.length &&
				dataSelected.every((value, index) => value === dataServer[index])
			setIsDisabled(isEqual)
		}
	}, [dataSelected])
	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			closeOnOverlayTap={!isLoadingPost}
			title="Edit Static List"
			className="w-[474px] h-[400px] p-4 rounded-[16px]"
		>
			<>
				{(isLoading || isLoadingPost || isRefetching) && (
					<div className="flex items-center justify-center w-full h-[calc(100%_-_29px)]">
						<CircularProgress disableShrink />
					</div>
				)}
				{!isLoading && !isLoadingPost && !isRefetching && data && (
					<div className="flex flex-col gap-4 w-full mt-4">
						<Input
							name="listName"
							title="List Name"
							placeholder="Team Members"
							className="!w-full !p-[unset]"
							inputClassName="!p-[unset] !py-1 !px-2"
							inputFocus
							value={newListValue}
							onChange={event => {
								setNewListValue(event.target.value)
							}}
						/>
						<Input
							name="search"
							placeholder="Search List Members"
							className="!w-full !p-[unset]"
							rightIcon={Icons.cancel}
							rightClick={() => {
								resetSearch()
								resetField('search')
							}}
							inputClassName="!p-[unset] !py-1 !px-2"
							inputFocus
							borderFull={false}
							search
							register={register}
							cantLimit={configGenaral.maxSearchValue}
							limit
							watchValue={watchSearch}
							setValueInput={setValue}
						/>
						<div className="flex flex-col gap-2 w-full h-[136px] overflow-y-auto">
							{data.items.length === 0 && (
								<div className="flex items-center justify-center w-full h-full">
									<Typography
										title="No search results."
										type={TypographyTypes.h3}
										fontLeading="18/21"
										className="text-gray-7"
									/>
								</div>
							)}
							{data.items.length > 0 && (
								<>
									<div className="flex gap-2 w-full px-4">
										<input
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-blue-primary focus:ring-transparent-default cursor-pointer"
											checked={selectAll}
											onChange={handleSelectAll}
										/>
										<Typography
											title="List Members"
											fontLeading="15/18"
											type={TypographyTypes.p}
											className="text-gray-9"
										/>
									</div>
									{data.items.map(option => {
										return (
											<div
												className="flex gap-2 w-full px-4"
												key={option.organization_follower_id}
											>
												<input
													type="checkbox"
													className="h-4 w-4 rounded border-gray-300 text-blue-primary focus:ring-transparent-default cursor-pointer"
													checked={dataSelected.includes(
														option.organization_follower_id
													)}
													onChange={() =>
														handleSelected(option.organization_follower_id)
													}
												/>
												<Typography
													title={option.user_name}
													fontLeading="15/18"
													type={TypographyTypes.p}
													className="text-gray-9 !font-normal"
												/>
											</div>
										)
									})}
								</>
							)}
						</div>
						<div className="flex justify-between items-center w-full">
							<Button
								label="Cancel"
								color="Transparent"
								onClick={() => setOpen(false)}
								className="!min-w-[unset] !w-[48%]"
							/>
							<Button
								label="Save"
								onClick={() => {
									editStaticListHandler(
										organizationId,
										parentData?.id as number,
										{
											name: newListValue as string,
											organization_followers_id: dataSelected
										}
									).then()
								}}
								className="!min-w-[unset] !w-[48%]"
								disabled={isDisabled || newListValue === ''}
							/>
						</div>
					</div>
				)}
			</>
		</TailwindModal>
	)
}
