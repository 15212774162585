/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * TODO:
 * Schedule appointment for review.
 */

import { API } from 'api/config'
import axios from 'axios'
import {
	BannerMyOrganizationItem,
	PatchMyOrganizationResponse,
	ProfileImageMyOrganizationItem,
	TabMyOrganizationItem,
	UpdateImageBody
} from 'interfaces'

export const postMyOrganizationTab = async (
	organizationId: number,
	data: TabMyOrganizationItem[]
): Promise<PatchMyOrganizationResponse | null> => {
	let res = null as PatchMyOrganizationResponse | null
	const body = {
		organization_sections: data.map((tab, index) => {
			return {
				organization_id: organizationId,
				name: tab.name,
				section_order: index,
				components: tab.components.map(component => {
					return {
						...component,
						component_value: JSON.stringify(component.component_value)
					}
				}),
				audience: tab.audience
			}
		})
	}
	await API()
		.post(`/organizations/admin/${organizationId}`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const postMyOrganizationProfileImage = async (
	organizationID: number,
	data: ProfileImageMyOrganizationItem
): Promise<PatchMyOrganizationResponse | null> => {
	let res = null as PatchMyOrganizationResponse | null
	const profileImage = data.cover.split('?timestamp=')
	const body = {
		profile_image: `${profileImage[0]}?timestamp=${Date.now()}`,
		icon_image_url: data.icon,
		primary_color: data.primary_color,
		secondary_color: data.secondary_color,
		third_color: data.third_color,
		fourth_color: data.fourth_color
	}
	await API()
		.post(`/organizations/profile_image/${organizationID}`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const postMyOrganizationBanner = async (
	organizationID: string,
	data: BannerMyOrganizationItem[]
): Promise<PatchMyOrganizationResponse | null> => {
	let res = null as PatchMyOrganizationResponse | null
	const body = {
		banners: data
	}
	await API()
		.post(`/organizations/banners/${organizationID}`, body)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.error(error)
			}
		)
	return res
}

export const getFormData = (object: any): any =>
	Object.keys(object).reduce((formData, key) => {
		formData.append(key, object[key])
		return formData
	}, new FormData())

export const updateImage = async (
	url: string,
	body: UpdateImageBody
): Promise<void> => {
	const formdata = getFormData(body)
	await axios.post(url, formdata, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
}
