import { CircularProgress } from '@mui/material'
import {
	GET_PEOPLE_TOTAL_FOLLOWERS,
	getTotalFollowers,
	setFollowersGoal
} from 'api'
import clsx from 'clsx'
import { AnimatedProgressBar, Box, Button, Input, TitleBox } from 'components'
import { useToast } from 'hooks'
import { BoxComponentProps, Icons, UpdateFollowerForm } from 'interfaces'
import React, { useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'

export const TotalFollowers: React.FC<BoxComponentProps> = ({ styles }) => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const { data, isLoading, refetch } = useQuery(
		[GET_PEOPLE_TOTAL_FOLLOWERS, userId, organizationId],
		() => getTotalFollowers(organizationId)
	)
	const { showToast } = useToast()
	const {
		register,
		resetField,
		handleSubmit,
		formState: { errors }
	} = useForm<UpdateFollowerForm>({
		mode: 'all'
	})

	const rules = useMemo(() => {
		return {
			follower_goal: {
				required: { value: true, message: 'This field is required' },
				validate: (value: number) => {
					if (value <= 0) {
						return 'Value must be greater than 0'
					}
					if (!Number.isInteger(Number(value))) {
						return 'Value must not be a decimal number'
					}
					if (String(value).length > 9) {
						return 'The maximum value allowed is 9 digits'
					}
					return true
				}
			}
		}
	}, [])

	const submitHandler: SubmitHandler<UpdateFollowerForm> = async (
		body: UpdateFollowerForm
	): Promise<void> => {
		setFollowersGoal(organizationId, {
			follower_goal: Number(body.follower_goal)
		}).then(response => {
			if (response) {
				showToast('Success', "Follower's Goal updated successfully", 'success')
				refetch().then()
				resetField('follower_goal')
			}
		})
	}

	return (
		<div className={clsx(styles)}>
			<TitleBox title="Goals" icon={Icons.thumbUp} className="mb-4" />
			<Box className="w-full h-[274px] flex flex-col items-center">
				<>
					{isLoading && (
						<div className="flex items-center justify-center w-full h-full ">
							<CircularProgress disableShrink />
						</div>
					)}
					{data && (
						<>
							<AnimatedProgressBar
								icon={Icons.group}
								value={data.current_followers}
								showPercent
								showComparison
								maxValue={data.followers_goal}
								showValue={false}
							/>
							<form
								className={clsx(
									'w-full',
									errors.follower_goal?.message ? 'mt-4' : 'mt-7'
								)}
								onSubmit={handleSubmit(submitHandler)}
							>
								<div className="flex w-full">
									<Input
										name="follower_goal"
										placeholder="Type your Follower's Goal"
										type="number"
										step="any"
										borderFull={false}
										register={register}
										error={errors.follower_goal}
										rules={rules.follower_goal}
										inputClassName="!rounded-[8px] w-full !py-[7px]"
										inputFieldClassName="!p-[unset]"
									/>
								</div>
								<Button
									className="w-full mt-2 !py-[9px]"
									label="Update"
									type="submit"
								/>
							</form>
							{/* <Button
								// w-full !min-w-[unset] max-w-[308px] !py-1 !px-2 ml-2
								className="w-full"
								label="Download Followers"
							/> */}
						</>
					)}
				</>
			</Box>
		</div>
	)
}
