/* eslint-disable react/require-default-props */
import { Popover } from '@mui/material'
import { Button } from 'components/buttons'
import { Icon } from 'components/icon'
import { TailwindPopOver } from 'components/modal'
import { Icons } from 'interfaces'
import React, { useState } from 'react'

export const AdminTeamActionsCell: React.FC<{
	value?: number
	onEdit: (id: number) => void
	onDelete: (id: number) => void
}> = props => {
	const { onEdit, onDelete, value } = props
	const [button, setButton] = useState<HTMLButtonElement | null>(null)
	return (
		<>
			<button type="button" onClick={e => setButton(e.currentTarget)}>
				<Icon className="h-[18px]" fillPath src={Icons.pending} />
			</button>
			<Popover
				anchorEl={button}
				open={Boolean(button)}
				onClose={() => setButton(null)}
				PaperProps={{ className: '!shadow-none' }}
			>
				<TailwindPopOver
					handleClose={() => undefined}
					className="flex !p-3 flex-col gap-3 border-2"
				>
					<>
						<Button
							className="w-36"
							label="Edit"
							iconLeft={Icons.edit}
							onClick={() => {
								onEdit(value as number)
								setButton(null)
							}}
							color="Transparent"
						/>
						<Button
							className="w-36"
							label="Delete"
							iconLeft={Icons.delete}
							onClick={() => {
								onDelete(value as number)
								setButton(null)
							}}
							color="Transparent"
						/>
					</>
				</TailwindPopOver>
			</Popover>
		</>
	)
}
