/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { CircularProgress } from '@mui/material'
import {
	GET_PEOPLE_EXPORT,
	GET_PEOPLE_MYLISTS_TABLE,
	GET_PEOPLE_MYPEOPLE_TABLE,
	GET_PEOPLE_TAGS_TABLE,
	GET_TAGS_APPROVAL_TABLE,
	deleteTags,
	editTag,
	getExportMyPeople,
	getPeopleMyListsTable,
	getPeopleMyPeopleTable,
	getPeopleTagsTable,
	getTagsApprovalTable
} from 'api'
import {
	Button,
	DeleteTagsModal,
	GroupButton,
	Helper,
	Input,
	Layout,
	MyTagsTablet,
	NewTagModal,
	PeopleFollowers,
	PeopleMyListsTable,
	PeopleMyPeopleTable,
	SaveStaticListModal,
	TagsApprovalModal,
	Title,
	TotalFollowers
} from 'components'
import ExportDataMyPeopleModal from 'components/modal/myPeople/ExportDataMypeopleModal'
import ExportDataSuccesModal from 'components/modal/myPeople/ExportDataSuccesModal'
import FilterDinamicModal from 'components/modal/myPeople/FilterDinamicModal'
import SaveDinamicListModal from 'components/modal/myPeople/saveDinamicListModal'

import { PaginationContext } from 'context'
import { useDebouncedSearch } from 'hooks'
import {
	ConvertedDataItem,
	ConvertedDataItemValue,
	DeleteTagsBody,
	DropdownFullItemProps,
	Icons,
	MyPeopleProps,
	PeopleMyListsResponse,
	PeopleMyPeopleResponse,
	PeopleMyTagsChildrenItem,
	PeopleMyTagsItemProps,
	PeopleMyTagsProps,
	TagType,
	TagsOpenType
} from 'interfaces'
import { typeAndOR, typeOperator, typePropierty } from 'lib/dataMypeoplefilter'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { configGenaral } from 'utils'

export const MyPeople: React.FC<MyPeopleProps> = ({
	period,
	setPeriod,
	setTagApprovalModal,
	tagApprovalModal,
	optionsToRender
}) => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const navigate = useNavigate()
	const [auxTags, setAuxTags] = useState<PeopleMyTagsItemProps[] | null>(null)
	const [auxTagsSelected, setAuxTagsSelected] = useState<TagType[] | null>(null)
	const [, setAuxTagsOpen] = useState<TagsOpenType[] | null>(null)
	const [peopleMyPeopleTablePage, setPeopleMyPeopleTablePage] = useState(1)
	const [pagePeopleTableQuery, setPagePeopleTableQuery] = useState(1)
	const [peopleMyListsTablePage, setPeopleMyListsTablePage] = useState(1)
	const [peopleTagsTablePage, setPeopleTagsTablePage] = useState(1)
	const [myPeopleDataSelected, setMyPeopleDataSelected] = useState<number[]>([])
	const [dataTags, setDataTags] = useState<PeopleMyTagsProps | undefined>()
	const [tagsApprovalSelected, setTagsApprovalSelected] = useState<number[]>([])
	const [newStaticListModal, setNewStaticListModal] = useState(false)
	const [filterModal, setFilterModal] = useState(false)
	const [loadingCreate, setLoadingCreate] = useState(false)
	const [newTagModal, setNewTagModal] = useState(false)
	const [deleteTagsModal, setDeleteTagsModal] = useState(false)
	const [deleteLoading, setDeleteLoading] = useState(false)
	const { tables } = useContext(PaginationContext)
	const [openFilter, setOpenFilter] = useState(false)
	const [openSaveList, setOpenSaveList] = useState(false)
	const [isSaveList, setIsSaveList] = useState(false)
	const [openExport, setOpenExport] = useState(false)
	const [openExportSucces, setOpenExportSucces] = useState(false)
	const [dataFilter, setDataFilter] = useState('')
	const [activateExport, setActivateExport] = useState(false)
	const propierty = typePropierty[0]
	const operator = typeOperator[0]
	const comparator = typeAndOR[0]
	const multipleSelection = myPeopleDataSelected?.length > 1
	const [rows, setRows] = useState<
		Array<{
			comparator: DropdownFullItemProps
			propierty: DropdownFullItemProps
			operator: DropdownFullItemProps
			value: string | number | DropdownFullItemProps
		}>
	>([{ comparator, propierty, operator, value: '' }])

	const { register, resetField, setValue, watch } = useForm<{
		search: string
	}>({
		mode: 'onChange'
	})
	const watchSearch = watch('search')
	const { debouncedSearch, resetSearch } = useDebouncedSearch(watchSearch)
	const {
		data: myPeopleData,
		isLoading: myPeopleIsLoading,
		refetch: myPeopleRefetch,
		isRefetching: myPeopleRefetching
	} = useQuery({
		queryKey: [
			GET_PEOPLE_MYPEOPLE_TABLE,
			peopleMyPeopleTablePage,
			pagePeopleTableQuery,
			debouncedSearch,
			userId,
			organizationId,
			tables.myPeople.column,
			tables.myPeople.order,
			dataFilter
		],
		queryFn: () =>
			getPeopleMyPeopleTable(
				organizationId,
				debouncedSearch.length > 1
					? pagePeopleTableQuery
					: peopleMyPeopleTablePage,
				debouncedSearch,
				tables.myPeople.column,
				tables.myPeople.order,
				dataFilter
			),
		refetchOnWindowFocus: false
	})

	useQuery({
		queryKey: [
			GET_PEOPLE_EXPORT,
			peopleMyPeopleTablePage,
			debouncedSearch,
			userId,
			organizationId,
			tables.myPeople.column,
			tables.myPeople.order,
			dataFilter,
			myPeopleDataSelected
		],
		queryFn: () =>
			getExportMyPeople(
				organizationId,
				peopleMyPeopleTablePage,
				debouncedSearch,
				tables.myPeople.column,
				tables.myPeople.order,
				dataFilter,
				myPeopleDataSelected
			),
		enabled: activateExport
	})

	const {
		data: myListsData,
		isLoading: myListsIsLoading,
		refetch: myListsRefetch,
		isRefetching: isRefetchingLists
	} = useQuery({
		queryKey: [
			GET_PEOPLE_MYLISTS_TABLE,
			peopleMyListsTablePage,
			debouncedSearch,
			userId,
			organizationId,
			tables.myLists.column,
			tables.myLists.order
		],
		queryFn: () =>
			getPeopleMyListsTable(
				organizationId,
				peopleMyListsTablePage,
				debouncedSearch,
				tables.myLists.column,
				tables.myLists.order
			),
		refetchOnWindowFocus: false
	})
	const {
		data: tagDataQuery,
		isLoading: tagsIsLoading,
		isFetching: IsFetchingLoading,
		refetch: tagsRefetch,
		isRefetching: isRefetchingTagsData
	} = useQuery({
		queryKey: [
			GET_PEOPLE_TAGS_TABLE,
			peopleTagsTablePage,
			debouncedSearch,
			userId,
			organizationId,
			tables.tags.column,
			tables.tags.order
		],
		queryFn: () =>
			getPeopleTagsTable(
				organizationId,
				peopleTagsTablePage,
				debouncedSearch,
				tables.tags.column,
				tables.tags.order
			),
		refetchOnWindowFocus: false
	})

	useEffect(() => {
		if (tagDataQuery) {
			setDataTags(tagDataQuery)
		}
	}, [tagDataQuery])

	const {
		data: tagsApprovalData,
		isLoading: tagsApprovalIsLoading,
		refetch: tagsApprovalRefetch
	} = useQuery([GET_TAGS_APPROVAL_TABLE, userId, organizationId], () =>
		getTagsApprovalTable(organizationId)
	)

	const shouldRenderButton = auxTagsSelected?.every?.(
		tag => !tag.selected && tag.subtags.length === 0
	)

	const allSelectedNoChildrenNoSubtags =
		auxTagsSelected &&
		auxTagsSelected
			.filter(item => item.selected)
			.every(item => !item.hasChildren && item.subtags.length === 0) &&
		!auxTagsSelected.some(item => item.hasChildren && item.subtags.length > 0)
	const allSubtagsSelectedFalseAndHasChildrenTrue =
		auxTagsSelected &&
		auxTagsSelected
			.filter(item => item.subtags.length > 0)
			.every(tag => !tag.selected && tag.hasChildren) &&
		auxTagsSelected.some(item => !item.hasChildren) &&
		!auxTagsSelected.some(item => item.selected)

	useEffect(() => {
		if (tagDataQuery) {
			setAuxTags(prevState => {
				const aux = prevState || []
				const existingIds = aux.map(item => item.organization_tag_parent_id)
				aux.forEach(item => {
					const dataFound = tagDataQuery.items.find(
						tag =>
							tag.organization_tag_parent_id === item.organization_tag_parent_id
					)
					if (dataFound) {
						const {
							count_son: countSon,
							name,
							required_approval: requiredApproval,
							is_visible: isVisible
						} = dataFound
						Object.assign(item, {
							count_son: countSon ?? item.count_son,
							name: name ?? item.name,
							required_approval: requiredApproval ?? item.required_approval,
							is_visible: isVisible ?? item.is_visible
						})
					}
				})
				const filteredItems: PeopleMyTagsItemProps[] = tagDataQuery.items
					.filter(
						item => !existingIds.includes(item.organization_tag_parent_id)
					)
					.map(tag => {
						return {
							...tag,
							subtags: []
						}
					})
				return [...aux, ...filteredItems]
			})
			setAuxTagsSelected(prevState => {
				const aux = prevState || []
				const existingIds = aux.map(item => item.id)
				const filteredItems = tagDataQuery.items
					.map(tag => {
						return {
							...tag,
							subtags: []
						}
					})
					.map(item => {
						return {
							id: item.organization_tag_parent_id,
							selected: false,
							hasChildren: item.count_son > 0,
							subtags: []
						}
					})
					.filter(item => !existingIds.includes(item.id))
				return [...aux, ...filteredItems]
			})
			setAuxTagsOpen(prevState => {
				const aux = prevState || []
				const existingIds = aux.map(item => item.id)
				const filteredItems = tagDataQuery.items
					.map(item => {
						return {
							id: item.organization_tag_parent_id,
							open: false
						}
					})
					.filter(item => !existingIds.includes(item.id))
				return [...aux, ...filteredItems]
			})
		}
	}, [peopleMyPeopleTablePage, tagDataQuery])

	useEffect(() => {
		resetSearch()
		resetField('search')
	}, [period])

	const updateTags = async (idTag: number, value: boolean): Promise<void> => {
		setLoadingCreate(true)
		const data = dataTags

		if (data) {
			let id = null
			const childrenAux = [] as number[]
			const aux2 = data.items.map(tags => {
				if (idTag === tags.organization_tag_parent_id) {
					id = tags.organization_tag_parent_id
					let tagsChildren: PeopleMyTagsChildrenItem[] = []
					if (tags.subRows && tags.subRows.length > 0) {
						tagsChildren = tags.subRows.map(
							(childrenTag: PeopleMyTagsChildrenItem) => {
								childrenAux.push(childrenTag.organization_tag_id)
								return {
									...childrenTag,
									required_approval: value
								}
							}
						)
					}
					return {
						...tags,
						required_approval: value,
						subRows: tagsChildren
					}
				}
				return tags
			})
			const finalData = {
				...data,
				items: aux2
			}

			if (aux2) {
				setDataTags(finalData)
			}

			if (id !== null && value !== null) {
				await editTag(id, {
					required_approval: value
				})
			}
		}
		setLoadingCreate(false)
	}

	const updateTagsChildrens = async (
		idTag: number,
		value: boolean
	): Promise<void> => {
		if (dataTags) {
			const aux2 = dataTags.items.map(tags => {
				let tagsChildren: PeopleMyTagsChildrenItem[] = []
				if (tags.subRows && tags.subRows.length > 0) {
					tagsChildren = tags.subRows.map(
						(childrenTag: PeopleMyTagsChildrenItem) => {
							if (childrenTag.organization_tag_id === idTag) {
								return {
									...childrenTag,
									required_approval: value
								}
							}
							return childrenTag
						}
					)
				}
				return {
					...tags,
					subRows: tagsChildren
				}
			})
			const finalData = {
				...dataTags,
				items: aux2
			}

			if (aux2) {
				setDataTags(finalData)
				await editTag(idTag, {
					required_approval: value
				})
			}
		}
	}

	const updateTagsVisibilityChildrens = async (
		idTag: number,
		value: boolean
	): Promise<void> => {
		setLoadingCreate(true)
		const data = dataTags
		if (data) {
			let id = null
			const childrenAux = [] as number[]
			const aux2 = data.items.map(tags => {
				if (idTag === tags.organization_tag_parent_id) {
					id = tags.organization_tag_parent_id
					let tagsChildren: PeopleMyTagsChildrenItem[] = []
					if (tags.subRows && tags.subRows.length > 0) {
						tagsChildren = tags.subRows.map(
							(childrenTag: PeopleMyTagsChildrenItem) => {
								childrenAux.push(childrenTag.organization_tag_id)
								return {
									...childrenTag,
									is_visible: value
								}
							}
						)
					}
					return {
						...tags,
						is_visible: value,
						subRows: tagsChildren
					}
				}
				return tags
			})

			const finalData = {
				...data,
				items: aux2
			}

			if (aux2) {
				setDataTags(finalData)
			}

			if (id !== null && value !== null) {
				await editTag(id, {
					is_visible: value
				})
			}
		}
		setLoadingCreate(false)
	}

	const deleteTagsParents = async (body: DeleteTagsBody): Promise<void> => {
		setDeleteLoading(true)
		const data = dataTags
		if (data) {
			const aux2 = data.items.filter(tags => {
				if (body.tags_id.includes(tags.organization_tag_parent_id)) {
					return false
				}
				return true
			})

			const finalData = {
				...data,
				items: aux2
			}

			setDataTags(finalData)

			if (body.tags_id !== null) {
				await deleteTags(organizationId, body)
			}
		}
		setDeleteLoading(false)
	}

	const deleteTagsChildrens = async (body: DeleteTagsBody): Promise<void> => {
		setDeleteLoading(true)
		if (dataTags) {
			const aux2 = dataTags.items.map(tags => {
				let tagsChildren: PeopleMyTagsChildrenItem[] = []
				if (tags?.subRows?.length) {
					tagsChildren = tags.subRows.filter(
						(childrenTag: PeopleMyTagsChildrenItem) => {
							return !body.tags_id.includes(childrenTag.organization_tag_id)
						}
					)
				}

				return {
					...tags,
					subRows: tagsChildren
				}
			})

			const finalData = {
				...dataTags,
				items: aux2
			}

			if (aux2) {
				setDataTags(finalData)
				if (body.tags_id !== null) {
					await deleteTags(organizationId, body)
				}
			}
		}
		setDeleteLoading(false)
	}

	const hasValue = (value: ConvertedDataItemValue): ConvertedDataItemValue =>
		value === 'not_loged_in' ? null : value

	const convertData = (
		originalData: {
			comparator: DropdownFullItemProps
			propierty: DropdownFullItemProps
			operator: DropdownFullItemProps
			value: string | number | DropdownFullItemProps
		}[]
	): ConvertedDataItem[] => {
		return originalData.map(item => {
			const value =
				typeof item?.value === 'object' && 'value' in item.value
					? item?.value?.value
					: item?.value

			return {
				property: item?.propierty?.value as string,
				operator: item?.operator?.value || undefined,
				value: value !== undefined ? hasValue(value) : '',
				comparator: item?.comparator?.name.toLowerCase()
			}
		})
	}

	const resetRow = (index: number): void => {
		const newRows = [...rows]
		newRows[index] = {
			comparator,
			propierty,
			operator,
			value: ''
		}
		setRows(newRows)
	}

	const resetFilter = (): void => {
		if (rows.length > 1) {
			const nuevaFila = {
				comparator,
				propierty,
				operator,
				value: ''
			}
			setRows([nuevaFila])
			setIsSaveList(false)
			setDataFilter('')
			setPeopleMyPeopleTablePage(1)
		} else {
			setIsSaveList(false)
			setDataFilter('')
			setPeopleMyPeopleTablePage(1)
			resetRow(0)
		}
	}

	return (
		<Layout>
			<div className="3xl:mx-auto 3xl:my-auto 3xl:w-3/4 mx-auto">
				<div className="flex items-center justify-between">
					<Title
						icon={Icons.groups}
						title="My People"
						subtitle="Manage your Community"
					/>
				</div>
				<div className="flex flex-col sm:flex-row md:flex-row lg:flex-row gap-5 mt-4 items-center w-full">
					<div className="w-full sm:w-[37%] md:w-[30%]">
						<TotalFollowers styles="basis-[27.5%]" />
					</div>

					<div className="overflow-auto w-full">
						<PeopleFollowers styles="basis-[72.5%]" />
					</div>
				</div>

				<div className="flex flex-col-reverse sm:flex-col xl:flex-row justify-between mt-2 w-full flex-wrap">
					<div className="w-auto">
						<GroupButton
							period={period}
							setPeriod={setPeriod}
							options={optionsToRender}
							buttonClassName="!w-[95px]"
							big
							// className="!w-full sm:!w-[75%] md:!w-[100%] 2md:!w-[92%] 3md:!w-[82%] lg:!w-[90%] xl:!w-[84%] 2xl:!w-[66%] "
							resetFilter={resetFilter}
						/>
					</div>

					<div className="flex gap-4 items-center mb-2 w-auto mt-2 justify-start flex-wrap">
						{period === 'My People' && (
							<>
								<Button
									label="Edit"
									className="!min-w-[0.875rem] !py-1 !px-4 disabled:bg-white disabled:text-gray-3"
									color={multipleSelection ? 'Primary' : 'Transparent'}
									onClick={() => navigate('/people/edit')}
									iconLeft={Icons.myPeople}
									disabled={!multipleSelection}
									iconClassName={
										multipleSelection ? 'text-white' : 'text-gray-3'
									}
								/>
								<Button
									label={
										myPeopleDataSelected.length > 0
											? 'Export Selected'
											: 'Export'
									}
									className="!min-w-[0.875rem] !py-1 !px-4"
									color="Primary"
									onClick={() => setOpenExport(true)}
								/>
								{openExport && (
									<ExportDataMyPeopleModal
										setOpen={setOpenExport}
										open={openExport}
										setOpenExportSucces={setOpenExportSucces}
										setActivateExport={setActivateExport}
									/>
								)}
								{openExportSucces && (
									<ExportDataSuccesModal
										setOpen={setOpenExportSucces}
										open={openExportSucces}
										setActivateExport={setActivateExport}
										setMyPeopleDataSelected={setMyPeopleDataSelected}
									/>
								)}
							</>
						)}
						{(period === 'My People' ||
							(period === 'My Tags' && shouldRenderButton)) && (
							<>
								<Button
									label="Tag Approval"
									className="!min-w-[unset] !py-1 !px-4 !bg-white "
									color="Transparent"
									buttonTextClassName="text-[0.6em] sm:text-[9px] md:text-[0.8em] xl:text-[1em]"
									textLeft={
										!tagsApprovalIsLoading &&
										tagsApprovalData &&
										tagsApprovalData.total > 0
											? tagsApprovalData.total.toString()
											: undefined
									}
									disabled={
										!tagsApprovalIsLoading &&
										!!tagsApprovalData &&
										tagsApprovalData.total === 0
									}
									onClick={() => setTagApprovalModal(true)}
								/>
								{tagApprovalModal && (
									<TagsApprovalModal
										open={tagApprovalModal}
										setOpen={setTagApprovalModal}
										openFilter={filterModal}
										setOpenFilter={setFilterModal}
										dataSelected={tagsApprovalSelected}
										setDataSelected={setTagsApprovalSelected}
										refetch={tagsApprovalRefetch}
									/>
								)}
							</>
						)}
						{period === 'My People' && (
							<>
								<Button
									label="Save Selection as List"
									className="!min-w-[unset] !py-1 !px-4 !bg-white"
									color="Transparent"
									disabled={myPeopleDataSelected.length === 0}
									onClick={() => setNewStaticListModal(true)}
									buttonTextClassName="text-[0.7rem] sm:text-[9px] md:text-[9px] lg:text-[0.8em] xl:text-[1em]"
								/>
								{newStaticListModal && (
									<SaveStaticListModal
										open={newStaticListModal}
										setOpen={setNewStaticListModal}
										data={myPeopleDataSelected}
										onSubmit={() => {
											myListsRefetch().then()
											setMyPeopleDataSelected([])
										}}
									/>
								)}
							</>
						)}
						{period === 'My People' && (
							<Helper
								text="Select followers below. Name and save as a Static List."
								className="relative"
							/>
						)}
						{period === 'My People' && (
							<>
								{isSaveList && (
									<Button
										label="Save Dynamic list"
										className="!min-w-[0.875rem] !py-1 !px-4"
										color="Primary"
										onClick={() => setOpenSaveList(true)}
									/>
								)}
								<Button
									label="Filter"
									className="!min-w-[0.875rem] !py-1 !px-4"
									color="Primary"
									iconLeft={Icons.filterAltFill}
									onClick={() => setOpenFilter(true)}
								/>
								{openFilter && (
									<FilterDinamicModal
										setOpen={setOpenFilter}
										open={openFilter}
										setDataFilter={setDataFilter}
										setRows={setRows}
										rows={rows}
										setOpenSaveList={setOpenSaveList}
										onConvertData={convertData}
										propierty={propierty}
										operator={operator}
										comparator={comparator}
										setIsSaveList={setIsSaveList}
										resetFilter={resetFilter}
										resetRow={resetRow}
										typeState="Create"
										setPeopleMyPeopleTablePage={setPeopleMyPeopleTablePage}
									/>
								)}
								{openSaveList && (
									<SaveDinamicListModal
										setOpen={setOpenSaveList}
										open={openSaveList}
										setDataFilter={setDataFilter}
										setRows={setRows}
										rows={rows}
										onConvertData={convertData}
										myListsRefetch={myListsRefetch}
										setIsSaveList={setIsSaveList}
									/>
								)}
							</>
						)}

						{period === 'My Tags' && !shouldRenderButton && (
							<>
								<Button
									label="Delete"
									className="!min-w-[unset] !py-1 !px-4 !bg-white"
									color="Transparent"
									iconLeft={Icons.delete}
									onClick={() => setDeleteTagsModal(true)}
									disabled={
										!(
											allSelectedNoChildrenNoSubtags ||
											allSubtagsSelectedFalseAndHasChildrenTrue
										)
									}
								/>
								{deleteTagsModal && auxTags && (
									<DeleteTagsModal
										open={deleteTagsModal}
										setOpen={setDeleteTagsModal}
										data={(() => {
											return {
												...dataTags,
												items: auxTags
											} as PeopleMyTagsProps | undefined
										})()}
										selected={(() => {
											let tagsToReturn: number[] = []
											if (allSelectedNoChildrenNoSubtags) {
												tagsToReturn = auxTagsSelected
													.filter(
														item =>
															item.selected &&
															!item.hasChildren &&
															item.subtags.length === 0
													)
													.map(item => item.id)
											} else if (allSubtagsSelectedFalseAndHasChildrenTrue) {
												tagsToReturn = auxTagsSelected
													.filter(
														tag =>
															tag.subtags.length > 0 &&
															!tag.selected &&
															tag.hasChildren
													)
													.map(item => item.subtags)
													.flat()
											}
											return tagsToReturn
										})()}
										mode={(() => {
											let mode = ''
											if (allSelectedNoChildrenNoSubtags) {
												mode = 'parent'
											} else if (allSubtagsSelectedFalseAndHasChildrenTrue) {
												mode = 'children'
											}
											return mode
										})()}
										refetch={tagsRefetch}
										// childRefetch={subtagsRefetch}
										setTagsSelected={setAuxTagsSelected}
										setData={setAuxTags}
									/>
								)}
							</>
						)}
						{/* {(period !== 'Tags' ||
								(period === 'Tags' && shouldRenderButton)) && (
								<>
									<Button
										label="Filter"
										iconLeft={Icons.filterAltFill}
										className="!min-w-[unset] !py-1 !px-4 !bg-white"
										color="Transparent"
										onClick={() => setFilterModal(true)}
									/>
									{filterModal && (
										<FilterModal open={filterModal} setOpen={setFilterModal} />
									)}
								</>
							)} */}
						{period === 'My Tags' && (
							<>
								<Button
									label=" Add New Tag"
									className="!min-w-[unset] !py-1 !px-4"
									onClick={() => setNewTagModal(true)}
								/>
								{newTagModal && auxTags && (
									<NewTagModal
										open={newTagModal}
										setOpen={setNewTagModal}
										refetch={tagsRefetch}
										setData={setAuxTags}
										setDataTags={setDataTags}
										dataTags={dataTags}
										setLoadingCreate={setLoadingCreate}
									/>
								)}
							</>
						)}
					</div>
				</div>
				<div className="flex justify-between items-center mt-4 gap-6">
					<Input
						name="search"
						placeholder={
							period === 'My Tags' ? 'Search Tags' : 'Search Followers'
						}
						className="!p-[unset] font-Inter"
						register={register}
						rightIcon={Icons.cancel}
						rightClick={() => {
							resetSearch()
							resetField('search')
						}}
						cantLimit={configGenaral.maxSearchValue}
						limit
						search
						watchValue={watchSearch}
						setValueInput={setValue}
						inputClassName="!p-[unset] !py-1 !px-2 mb-3"
						inputFieldClassName="focus:ring-blue-primary !focus:border-blue-primary"
						inputFocus
						borderFull={false}
						onChangeCustom={() => {
							if (peopleMyPeopleTablePage !== 1) {
								setPagePeopleTableQuery(1)
							}
						}}
					/>
					{/* {period !== 'My People' && ( */}
					{/* 	<Button label="Download" className="!min-w-[unset] !w-[215px]" /> */}
					{/* )} */}
				</div>

				{period === 'My People' && (
					<PeopleMyPeopleTable
						data={myPeopleData as PeopleMyPeopleResponse | undefined}
						isLoading={myPeopleIsLoading || myPeopleRefetching}
						page={
							debouncedSearch?.length > 1
								? pagePeopleTableQuery
								: peopleMyPeopleTablePage
						}
						setPage={
							debouncedSearch?.length > 1
								? setPagePeopleTableQuery
								: setPeopleMyPeopleTablePage
						}
						dataSelected={myPeopleDataSelected}
						setDataSelected={setMyPeopleDataSelected}
						refetch={myPeopleRefetch}
						className="overflow-auto"
						openExport={openExport}
					/>
				)}
				{period === 'My Lists' && (
					<PeopleMyListsTable
						data={myListsData as PeopleMyListsResponse | undefined}
						isLoading={myListsIsLoading || isRefetchingLists}
						page={peopleMyListsTablePage}
						setPage={setPeopleMyListsTablePage}
						refetch={myListsRefetch}
						className="overflow-x-auto"
						setDataFilter={setDataFilter}
						setRows={setRows}
						rows={rows}
						setOpenSaveList={setOpenSaveList}
						onConvertData={convertData}
						propierty={propierty}
						operator={operator}
						comparator={comparator}
						setIsSaveList={setIsSaveList}
						resetFilter={resetFilter}
						resetRow={resetRow}
					/>
				)}

				{period === 'My Tags' && dataTags && (
					<div>
						{loadingCreate ? (
							<div className="w-full py-5 px-2">
								<div className="flex items-center justify-center w-full h-[calc(100vh_-_437px)] py-2 px-1 bg-white border-2 border-gray-7 rounded-lg">
									<CircularProgress disableShrink />
								</div>
							</div>
						) : tagsIsLoading ||
						  IsFetchingLoading ||
						  isRefetchingTagsData ||
						  loadingCreate ||
						  deleteLoading ||
						  dataTags.page !== peopleTagsTablePage ? (
							<div className="w-full py-5 px-2">
								<div className="flex items-center justify-center w-full h-[calc(100vh_-_437px)] py-2 px-1 bg-white border-2 border-gray-7 rounded-lg">
									<CircularProgress disableShrink />
								</div>
							</div>
						) : (
							<MyTagsTablet
								data={dataTags ?? []}
								page={peopleTagsTablePage}
								setPage={setPeopleTagsTablePage}
								isLoading={false}
								refetch={tagsRefetch}
								updateTags={updateTags}
								updateTagsChildrens={updateTagsChildrens}
								updateTagsVisibilityChildrens={updateTagsVisibilityChildrens}
								deleteLoading={deleteLoading}
								setDeleteLoading={setDeleteLoading}
								myPeopleRefetch={myPeopleRefetch}
								deleteTagsParents={deleteTagsParents}
								deleteTagsChildrens={deleteTagsChildrens}
								className="pt-2"
							/>
						)}
					</div>
				)}
			</div>
		</Layout>
	)
}
