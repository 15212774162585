import { Typography } from 'components'
import { InputRadioProsp, TypographyTypes } from 'interfaces'
import React from 'react'

export const InputRadio: React.FC<InputRadioProsp> = ({
	item,
	defaultChecked,
	disabled = false,
	setItemRecurrence
}): JSX.Element => {
	return (
		<div className="flex items-center mb-2">
			<input
				id={`${item.value}-${item.id}`}
				name={item.value}
				type="radio"
				checked={item.id === defaultChecked}
				onChange={() => setItemRecurrence(item.id)}
				className="h-4 w-4 border-gray-300 text-blue-primary focus:ring-white !bg-none"
				disabled={disabled}
			/>
			<label
				htmlFor={`${item.value}-${item.id}`}
				className="ml-3 block text-sm font-medium leading-6 text-gray-900"
			>
				<Typography
					title={item.title}
					type={TypographyTypes.h3}
					className="text-gray-9 !font-bold"
					fontLeading="15/18"
				/>
			</label>
		</div>
	)
}
