import { Collapse, ListItem } from '@mui/material'
import List from '@mui/material/List'
import clsx from 'clsx'
import { Checkbox, Icon, Typography } from 'components'
import { BatchesTagItem, Icons, TypographyTypes } from 'interfaces'
import React, { useState } from 'react'

export const TagBatchItem = (props: BatchesTagItem): JSX.Element => {
	const { tag, onClickParent, onClickChildren, selectedRowTags } = props
	const [open, setOpen] = useState(true)

	return (
		<ListItem className="flex hover:cursor-pointer flex-col w-full">
			<div className="flex w-full">
				<button
					type="button"
					onClick={() => setOpen(!open)}
					disabled={!tag?.count_son}
				>
					<Icon
						src={Icons.arrowForwardIos}
						fillPath
						className={clsx(
							'h-4 w-4 mr-3 text-gray-19 rotate-90 transition-all',
							{
								'rotate-[270deg]': open
							},
							{ 'opacity-0': !tag?.count_son }
						)}
					/>
				</button>
				<label
					className="flex hover:cursor-pointer items-center"
					htmlFor={`tag_id${tag.organization_tag_parent_id}`}
				>
					{!tag.count_son && (
						<Checkbox
							id={`tag_id${tag.organization_tag_parent_id}`}
							onChange={event => onClickParent?.(tag, event.target.checked)}
						/>
					)}
					<span className="flex items-center">
						<Typography
							title={tag.name}
							fontLeading="15/18"
							type={TypographyTypes.h3}
							className={clsx('text-gray-9 ', { 'ml-2': !tag.count_son })}
						/>
						{tag.required_approval && (
							<Typography
								title="(Approval required)"
								fontLeading="14/24"
								type={TypographyTypes.span}
								className="text-gray-9 ml-2"
							/>
						)}
					</span>
				</label>
			</div>
			<Collapse
				in={open}
				timeout="auto"
				unmountOnExit
				className={clsx('w-full pl-10', { 'mt-2': tag.count_son })}
			>
				<List component="div" disablePadding>
					{tag?.subRows?.map(row => (
						<ListItem key={row.organization_tag_id}>
							<label
								className="flex hover:cursor-pointer"
								htmlFor={`tag_id${row.organization_tag_id}`}
							>
								<Checkbox
									id={`tag_id${row.organization_tag_id}`}
									checked={selectedRowTags.some(
										selectedRow => selectedRow === row.organization_tag_id
									)}
									onChange={event =>
										onClickChildren?.(row, event.target.checked)
									}
								/>
								<span>
									<Typography
										title={row.name}
										fontLeading="15/18"
										type={TypographyTypes.h3}
										className="text-gray-9 ml-2"
									/>
								</span>
							</label>
						</ListItem>
					))}
				</List>
			</Collapse>
		</ListItem>
	)
}
