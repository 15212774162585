import { CircularProgress } from '@mui/material'
import { addNewTag, GET_PEOPLE_TAGS_ORG, getPeopleTags } from 'api'
import clsx from 'clsx'
import {
	Button,
	DropdownFull,
	Input,
	TailwindModal,
	Typography
} from 'components'
import { useToast } from 'hooks'
import {
	DropdownFullItemProps,
	GenericModalProps,
	PeopleMyTagsChildrenItem,
	PeopleTagsOrgRequest,
	TypographyTypes
} from 'interfaces'
import { shallowCompare } from 'lib'
import React, { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import Cookies from 'universal-cookie'

export const NewTagModal: React.FC<
	GenericModalProps<DropdownFullItemProps[]>
> = ({ open, setOpen, setDataTags, dataTags, setLoadingCreate }) => {
	const cookies = new Cookies()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')
	const [newTagValue, setNewTagValue] = useState('')
	const [selectedOption, setSelectedOption] = useState('no')
	const [isLoading, setIsLoading] = useState(false)
	const [parentTagType, setParentTagType] = useState<DropdownFullItemProps>({
		id: 'parentTag0',
		name: 'Please select a Parent Tag'
	})
	const { data: orgData, isLoading: orgIsLoading } = useQuery(
		[GET_PEOPLE_TAGS_ORG, userId, organizationId],
		() => getPeopleTags(organizationId)
	)
	const { showToast } = useToast()

	const handleOptionChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>): void => {
			setSelectedOption(event.target.value)
		},
		[]
	)

	const addFather = (id: number, nameNew: string): void => {
		const tiempoTranscurrido = Date.now()
		const dateSend = new Date(tiempoTranscurrido)
		if (dataTags && setDataTags) {
			const aux = {
				organization_tag_parent_id: id,
				count_son: 0,
				name: nameNew,
				required_approval: false,
				is_visible: true,
				created_at: dateSend.toISOString(),
				num_users: 0,
				subRows: []
			}

			setDataTags({ ...dataTags, items: [...dataTags.items, aux] })
		}
	}

	const addChildren = (
		id: number,
		nameChildren: string,
		idParent: number
	): void => {
		if (dataTags && setDataTags) {
			const newData = dataTags.items.map(tags => {
				if (idParent === tags.organization_tag_parent_id) {
					const auxChildren = {
						organization_tag_id: id,
						name: nameChildren,
						is_visible: true,
						required_approval: tags.required_approval,
						num_users: 0
					}
					let childrensAux = [] as PeopleMyTagsChildrenItem[]
					if (tags.subRows && tags.subRows.length > 0) {
						childrensAux = tags.subRows
					}
					childrensAux = [...childrensAux, auxChildren]

					return {
						...tags,
						count_son: tags.count_son + 1,
						subRows: childrensAux
					}
				}
				return tags
			})
			const newD = { ...dataTags, items: newData }
			setDataTags(newD)
		}
	}

	const addNewTagHandler = useCallback(
		async (body: PeopleTagsOrgRequest): Promise<void> => {
			setIsLoading(true)
			if (setLoadingCreate) setLoadingCreate(true)

			addNewTag(organizationId, body).then(async response => {
				if (response) {
					if (body.organization_tag_parent_id) {
						addChildren(
							response.id,
							body.name as string,
							body.organization_tag_parent_id
						)
					} else {
						addFather(response.id, body.name as string)
					}
					showToast('Success', 'Tag created successfully', 'success')
					setIsLoading(false)
					setOpen(false)
					if (setLoadingCreate) setLoadingCreate(false)
				} else {
					showToast('Error', 'Error creating tag', 'error')
					setIsLoading(false)
					setOpen(false)
					if (setLoadingCreate) setLoadingCreate(false)
				}
			})
		},
		[]
	)

	return (
		<TailwindModal
			open={open}
			setOpen={setOpen}
			title="New Tag"
			className="w-[474px] h-[390px] p-4 rounded-[16px]"
		>
			<div
				className={clsx(
					'flex flex-col w-full items-center h-[calc(100%_-_45px)] mt-4',
					orgIsLoading || isLoading ? 'justify-center' : 'justify-between'
				)}
			>
				{(orgIsLoading || isLoading) && <CircularProgress disableShrink />}
				{!(orgIsLoading || isLoading) && orgData && orgData.items && (
					<>
						<div className="flex flex-col gap-4 w-full">
							<Input
								name="tagName"
								title="Please enter a new tag name:"
								placeholder="Add Tag Name"
								className="!w-full !p-[unset]"
								value={newTagValue}
								onChange={event => setNewTagValue(event.target.value)}
								inputClassName="!p-[unset] !py-1 !px-2"
								inputFocus
							/>
							<fieldset className="flex flex-col">
								<div className="flex gap-2">
									<input
										type="radio"
										id="yes"
										name="nestUnder"
										value="yes"
										checked={selectedOption === 'yes'}
										onChange={handleOptionChange}
										className="border-blue-primary text-blue-primary focus:text-blue-primary"
									/>
									<Typography
										title="Nest Under:"
										fontLeading="15/18"
										type={TypographyTypes.label}
										className="text-gray-9 !font-normal"
										htmlFor="yes"
									/>
								</div>
							</fieldset>
							<DropdownFull
								className="w-full"
								menuClassName="!max-h-[102px]"
								list={orgData.items.map(item => {
									return {
										id: item.id,
										name: item.name
									}
								})}
								item={parentTagType}
								setItem={setParentTagType}
								disabled={selectedOption === 'no'}
							/>
						</div>
						<div className="flex justify-between items-center w-full">
							<Button
								label="Cancel"
								color="Transparent"
								onClick={() => setOpen(false)}
								className="!min-w-[unset] !w-[48%]"
							/>
							<Button
								label="Save"
								onClick={() =>
									addNewTagHandler({
										name: newTagValue,
										required_approval: false,
										organization_tag_parent_id:
											typeof parentTagType.id === 'string'
												? undefined
												: (parentTagType.id as number)
									})
								}
								className="!min-w-[unset] !w-[48%]"
								disabled={
									newTagValue === '' ||
									(selectedOption === 'yes' &&
										shallowCompare(parentTagType, {
											id: 'parentTag0',
											name: 'Please select a Parent Tag'
										}))
								}
							/>
						</div>
					</>
				)}
			</div>
		</TailwindModal>
	)
}
