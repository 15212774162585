import { CircularProgress } from '@mui/material'
import clsx from 'clsx'
import { Typography } from 'components'
import {
	ParentTableProps,
	PeopleMyPeopleResponse,
	TypographyTypes
} from 'interfaces'
import React from 'react'
import { MyPeopleTable } from './myPeopleTable'
import { PeopleMyListLoginType } from './peopleMyListLoginType'
import { PeopleMyPeopleActionCell } from './peopleMyPeopleActionCell'

export const PeopleMyPeopleTable: React.FC<
	ParentTableProps<PeopleMyPeopleResponse>
> = ({
	data,
	page,
	setPage,
	className,
	isLoading,
	dataSelected,
	setDataSelected,
	refetch,
	openExport
}) => {
	return (
		<div className={clsx(className, 'flex flex-col w-full')}>
			{isLoading && (
				<div className="flex items-center justify-center w-full h-[calc(100vh_-_437px)] py-2 px-1 bg-white border-2 border-gray-7 rounded-lg">
					<CircularProgress disableShrink />
				</div>
			)}
			{!isLoading && data && data?.items.length === 0 && (
				<div className="flex items-center justify-center w-full h-[calc(100vh_-_437px)] py-2 px-1 bg-white border-2 border-gray-7 rounded-lg min-h-min">
					<Typography
						title="You don't have any followers to track yet."
						type={TypographyTypes.h3}
						fontLeading="18/21"
						className="text-gray-7"
					/>
				</div>
			)}
			{!isLoading && data && data?.items.length > 0 && (
				<MyPeopleTable
					data={data.items}
					backendPages={data.pages}
					page={page}
					customWidth={[20, 15, 15, 23, 15, 15, 16, 12, 12, 15]}
					actionColumnWidth={8}
					actionsButton={<PeopleMyPeopleActionCell refetch={refetch} />}
					listLoginType={<PeopleMyListLoginType />}
					bodyTableClassName="!h-[calc(100vh_-_489px)]"
					justify="left"
					setPage={setPage}
					dataSelected={dataSelected}
					setDataSelected={setDataSelected}
					openExport={openExport}
				/>
			)}
		</div>
	)
}
