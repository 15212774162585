import { CircularProgress } from '@mui/material'
import clsx from 'clsx'
import { Typography } from 'components'
import {
	ParentTableProps,
	PeopleMyListsResponse,
	TypographyTypes
} from 'interfaces'
import React from 'react'
import { MyListsTable } from './myListsTable'
import { PeopleMyListActionCell } from './peopleMyListsActionCell'

export const PeopleMyListsTable: React.FC<
	ParentTableProps<PeopleMyListsResponse>
> = ({
	data,
	page,
	setPage,
	className,
	isLoading,
	refetch,
	setDataFilter,
	setRows,
	rows,
	setOpenSaveList,
	onConvertData,
	propierty,
	operator,
	comparator,
	setIsSaveList,
	resetFilter,
	resetRow
}) => {
	return (
		<div className={clsx(className, 'flex flex-col w-full')}>
			{isLoading && (
				<div className="flex items-center justify-center w-full h-[calc(100vh_-_437px)] py-2 px-1 bg-white border-2 border-gray-7 rounded-lg">
					<CircularProgress disableShrink />
				</div>
			)}
			{!isLoading && data && data?.items.length === 0 && (
				<div className="flex items-center justify-center w-full h-[calc(100vh_-_437px)] py-2 px-1 bg-white border-2 border-gray-7 rounded-lg min-w-min">
					<Typography
						title="You don't have any lists to track yet."
						type={TypographyTypes.h3}
						fontLeading="18/21"
						className="text-gray-7"
					/>
				</div>
			)}
			{!isLoading && data && data?.items.length > 0 && refetch && (
				<MyListsTable
					data={data.items}
					backendPages={data.pages}
					page={page}
					customWidth={[40, 40, 10]}
					actionColumnWidth={6}
					actionsButton={
						<PeopleMyListActionCell
							refetch={refetch}
							setDataFilter={setDataFilter}
							setRows={setRows}
							rows={rows}
							setOpenSaveList={setOpenSaveList}
							onConvertData={onConvertData}
							propierty={propierty}
							operator={operator}
							comparator={comparator}
							setIsSaveList={setIsSaveList}
							resetFilter={resetFilter}
							resetRow={resetRow}
						/>
					}
					bodyTableClassName="!h-[calc(100vh_-_489px)]"
					justify="left"
					setPage={setPage}
				/>
			)}
		</div>
	)
}
