import { CircularProgress } from '@mui/material'
import { GET_ASSETS_ALL_ORGANIZATIONS, getAssetsAll } from 'api'
import clsx from 'clsx'
import { Button } from 'components'
import { useToast } from 'hooks'
import { ItemDataAssentsAll, LibraryImageSlideProps } from 'interfaces'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useInfiniteQuery } from 'react-query'
import Cookies from 'universal-cookie'

export const LibraryImage: React.FC<LibraryImageSlideProps> = ({
	listImage,
	setOpenSlide,
	setListImage,
	setSlideImages,
	setSlideLibraryImages,
	setSendImageCropped
}): JSX.Element => {
	const cookies = new Cookies()
	const { showToast } = useToast()
	const { ref, inView } = useInView()
	const userId = cookies.get('userId')
	const organizationId = cookies.get('organization_id')

	const [listImageData, setListImageData] = useState<ItemDataAssentsAll[]>([])

	const { data, fetchNextPage, refetch, isFetchingNextPage, isLoading } =
		useInfiniteQuery(
			[GET_ASSETS_ALL_ORGANIZATIONS, userId, organizationId],
			({ pageParam = 1 }) => getAssetsAll(organizationId, pageParam),

			{
				getPreviousPageParam: firstPage =>
					firstPage?.page ? firstPage.page : undefined,
				getNextPageParam: lastPage =>
					lastPage?.page ? lastPage.page + 1 : undefined
			}
		)

	useEffect(() => {
		if (inView) {
			fetchNextPage()
		}
	}, [inView])

	useEffect(() => {
		if (data?.pages) {
			const allItems = data.pages.reduce((acc, page) => {
				if (page?.items) {
					return [...acc, ...page.items]
				}
				return acc
			}, [] as ItemDataAssentsAll[])

			setListImageData(allItems)
		}
	}, [data])

	const handleSaveImage = (image: ItemDataAssentsAll): void => {
		let newListImage = listImage
		if (listImage.includes(image)) {
			newListImage = listImage.filter(item => item !== image)
		} else if (listImage.length < 5) {
			newListImage.push(image)
		} else {
			showToast('Error', 'You can only select a maximum of 5 images', 'error')
		}
		setListImage([...newListImage])
		setSendImageCropped([...newListImage])
		refetch()
	}

	return (
		<div className="mt-4 h-full">
			{isLoading ? (
				<div className="flex items-center justify-center w-full h-[400px] py-2 px-1 ">
					<CircularProgress disableShrink />
				</div>
			) : (
				<div className="h-[93%]">
					<div className="grid grid-cols-2 gap-x-2 gap-y-4 max-h-[98%] overflow-y-scroll hide-scroll-bar ">
						{listImageData?.map(item => {
							return (
								<button
									key={item.asset_aspect}
									type="button"
									aria-label="button"
									onClick={() => handleSaveImage(item)}
									className={clsx(
										' w-full h-48 rounded-lg relative',
										listImage.find(list => list.asset_url === item.asset_url) &&
											'border-2 border-blue-primary'
									)}
								>
									<div
										className={clsx(
											listImage.find(
												list => list.asset_url === item.asset_url
											) && 'bg-blue-primary',
											'w-[14px] h-[14px] border border-blue-primary rounded-full absolute top-2 right-2'
										)}
									/>
									<img
										alt="content"
										src={item.asset_url}
										className="w-full h-full rounded-lg"
									/>
								</button>
							)
						})}

						<div
							ref={ref}
							className="flex items-center justify-center !w-[408px] yhonaiker pl-52 h-[100px] "
						>
							{isFetchingNextPage && <CircularProgress disableShrink />}
						</div>
					</div>
					<div className="flex items-center justify-between mt-4">
						<Button
							type="button"
							label="Cancel"
							color="Transparent"
							className="!min-w-[130px]"
							onClick={() => setOpenSlide(false)}
						/>
						<Button
							label="Save"
							type="submit"
							color="Primary"
							className="!min-w-[130px]"
							disabled={listImage.length === 0}
							onClick={() => {
								setSlideImages(true)
								setSlideLibraryImages(false)
							}}
						/>
					</div>
				</div>
			)}
		</div>
	)
}
