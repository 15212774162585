import { Table } from 'components'
import {
	FMPartnerListPartnersItem,
	FMPartnerListPartnersResponse,
	ParentTableProps
} from 'interfaces'
import React, { useCallback, useMemo } from 'react'
import { Column, Row } from 'react-table'

export const FmPartnerListTablePartners: React.FC<
	ParentTableProps<FMPartnerListPartnersResponse>
> = ({
	data,
	page,
	setPage,
	isLoading,
	setDataSelected,
	className,
	dataSelected
}) => {
	const columns = useMemo(
		(): Column<FMPartnerListPartnersItem>[] => [
			{ Header: 'Partner', accessor: 'partner_name', disableSortBy: true },
			{ Header: 'Followers', accessor: 'followers', disableSortBy: true }
		],
		[]
	)

	const handleRowSelect = useCallback<
		(rows: Row<FMPartnerListPartnersItem>[], selected: boolean) => void
	>((rows, selected) => {
		const ids = rows.map(row => row.original.organization_partner_id)
		if (selected) {
			setDataSelected?.(prev => [...prev, ...ids])
		} else {
			setDataSelected?.(prev =>
				prev.filter(partnerId => !ids.includes(partnerId))
			)
		}
	}, [])

	return (
		<div className={className}>
			<Table<FMPartnerListPartnersItem>
				data={data?.items ?? []}
				page={page}
				columns={columns}
				customWidth={[45, 32]}
				bodyTableClassName="!h-[calc(100vh_-_598px)] overflow-y-auto"
				noDataMessage="No Result Found"
				justify="left"
				isFetching={isLoading}
				onPageChange={setPage}
				selectionCustomWidth={0}
				onRowSelect={handleRowSelect}
				rowSelected={row =>
					dataSelected?.includes(row.original.organization_partner_id) ?? false
				}
				showPagination
				total={data?.total}
				pages={data?.size}
				hideSelectionHeader
				customWidthOverride
			/>
		</div>
	)
}
