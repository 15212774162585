import clsx from 'clsx'
import { Icon, Typography } from 'components'
import { BoxPartnersProps, Icons, TypographyTypes } from 'interfaces'
import React from 'react'

export const BoxPartners: React.FC<BoxPartnersProps> = ({
	id,
	icon,
	image,
	title,
	onClick,
	itemsSeleted
}): JSX.Element => {
	return (
		<div className="flex items-center justify-between border-b border-gray-11 pb-4 mb-4">
			<div className="flex items-center gap-4">
				{icon && (
					<div className="bg-gray-11 p-2 rounded-lg">
						<Icon src={icon} className="w-6" />
					</div>
				)}
				{image && <img src={image} alt={title} className="w-6 h-[22px]" />}
				<Typography
					title={title}
					type={TypographyTypes.span}
					fontLeading="18/21"
				/>
			</div>
			<button type="button" onClick={onClick}>
				<Icon
					src={
						itemsSeleted.includes(parseInt(id, 10)) ? Icons.check : Icons.plus
					}
					className={clsx(
						'w-6',
						itemsSeleted.includes(parseInt(id, 10))
							? 'text-blue-primary'
							: 'text-gray-9'
					)}
					fillPath
				/>
			</button>
		</div>
	)
}
