import moment from 'moment'
import React from 'react'
import { formatLocalTime } from 'utils'

interface CellCapitalizeOptions {
	replace?: boolean
}

export const renderCellDateTime = (
	value: Date | string,
	format?: string
): JSX.Element => {
	return <>{formatLocalTime(String(value), format ?? 'MM/DD/YYYY hh:mm A')}</>
}

export const renderCellDateTimeUTC = (
	value: Date | string,
	format?: string
): JSX.Element => {
	return (
		<span>
			{value ? moment.utc(value).format(format ?? 'MM/DD/YYYY hh:mm A') : ''}
		</span>
	)
}

export const renderCellSimpleValue = (value?: string | number): JSX.Element => {
	return <span>{value}</span>
}

export const renderCellCapitalize = (
	value: string,
	options?: CellCapitalizeOptions
): JSX.Element => {
	return (
		<span className="capitalize">
			{options?.replace ? value?.replace(/_/g, ' ') : value}
		</span>
	)
}
