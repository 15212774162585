import { API } from 'api/config'
import { MessageResponse, NetworkPartnerPatchResponse } from 'interfaces'

export const deleteLocation = async (
	locationsId: number,
	organizationId: number
): Promise<MessageResponse | null> => {
	let res = null
	await API()
		.delete(`/locations/${locationsId}/organizations/${organizationId}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}

export const deleteLocationsList = async (
	organizationId: number,
	locationsListId: number
): Promise<NetworkPartnerPatchResponse | null> => {
	let res = null
	await API()
		.delete(`/location_list/${locationsListId}/organizations/${organizationId}`)
		.then(
			async response => {
				res = response.data
			},
			error => {
				console.warn('Error:', error.message)
			}
		)
	return res
}
