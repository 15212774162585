import { Button } from 'components/buttons'
import { Icon } from 'components/icon'
import { Typography } from 'components/typography'
import {
	FileUploaderItemProps,
	FileUploaderProps,
	Icons,
	TypographyTypes
} from 'interfaces'
import React, { useCallback, useRef } from 'react'

const FileUploaderItem: React.FC<FileUploaderItemProps> = props => {
	const { icon, name, onDelete, size } = props
	return (
		<div className="flex items-center justify-between">
			<div className="flex gap-2">
				<Icon className="w-8 h-8" src={icon} />
				<div className="flex flex-col justify-between">
					<Typography
						type={TypographyTypes.span}
						fontLeading="12/12"
						className="text-black-1"
						title={name}
					/>
					<Typography
						type={TypographyTypes.span}
						fontLeading="10/12"
						className="text-gray-1"
						title={size}
					/>
				</div>
			</div>
			<button className="!p-[5px] !w-6 !h-6" onClick={onDelete} type="button">
				<Icon fillPath className="text-blue-primary" src={Icons.close} />
			</button>
		</div>
	)
}

export const FileUploader: React.FC<FileUploaderProps> = props => {
	const { files, onChange, onDelete, accept } = props

	const fileInput = useRef<HTMLInputElement>(null)

	const byteToMegaByte = useCallback(
		(bytes: number): number => bytes * 0.000001,
		[]
	)

	return (
		<div>
			<div className="mb-4 grid gap-3 grid-flow-row grid-cols-1 w-full">
				{files.map((file, idx) => (
					<FileUploaderItem
						key={idx}
						name={file.name}
						size={`${Math.round(byteToMegaByte(file.size))} MB`}
						icon={Icons.pdf}
						onDelete={() => onDelete?.(file)}
					/>
				))}
			</div>
			<hr />
			<div className="text-right">
				<input
					accept={accept}
					onChange={e => {
						onChange?.(e)
						if (fileInput.current) fileInput.current.value = ''
					}}
					type="file"
					ref={fileInput}
					hidden
				/>
				<Button
					onClick={() => fileInput.current?.click()}
					type="button"
					label="Upload New"
					color="Transparent"
					className="!border-gray-3 mt-4 !border !py-2"
				/>
			</div>
		</div>
	)
}
